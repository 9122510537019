import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { AuditFields } from "../../../components/AuditFields";
import BreadCrumb from "../../../components/BreadCrumb";
import CustomComponent from "../../../components/CustomComponent";
import { FormDatePicker, FormInput, FormInputSelect, FormRadioValues } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import Modal from "../../../components/Modal";
import Panel from "../../../components/Panel";
import TABLE from "../../../components/Table";
import { TOAST_TYPE, getHabilitation, getSession, sendToast } from "../../../helpers/helpers";
import { validateLaterOrToday, validateLaterThanDate } from "../../../helpers/validation";
import { initValidator } from "../../../validations/validations";

class CreateAbonnement extends CustomComponent {
    private _isMounted = false;
    private _session = getSession();
    private _today = moment.utc().startOf('day').toDate();
    private readonly validator;

    private _isDetailsPage = this.props.isDetailsPage;
    private _isUpdatePage = this.props.isUpdatePage;
    private _abonnementId = this.props.abonnementId;
    private _title = this.props.title ?? `Création d'un abonnement`;

    private defaultFilters = {
        orderBy: 'rap_type_destinataire',
        ordering: 'ASC',
        rap_nom: '',
        rap_description: '',
        rap_type_destinataire: '',
        rap_type_declenchement: '',
        rap_jour_declenchement: '',
    }

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            isSubmitted: false,
            filters: this.defaultFilters,
            options: {
                currentEntiteOnly: false,
                entites: [],
                groupes: [],
            },
            checked_rapports: [],
            rapports: [],
            abonnement: {
                proprietaire: +this._session.ent_id,
                libelle: null,
                media: this._abonnementId ? null : 'MAIL',
                groupe: null,
                date_debut: this._today,
                date_fin: null,
            },
            auditFields: null,
            deletingAbonnement: false,
            isDateDebutDisabled: false,
        }

        this.validator = initValidator();
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = `Infolabo | ${this._title}`;


        if (this._abonnementId) {
            this.getAbonnement(!this._isDetailsPage ? this.getRapports : undefined);
        } else {
            this.getEntiteOptions();
            this.getGroupeOptions();
            this.getRapports();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getAbonnement = async (callback?: any) => {
        this.setState({isLoading: true});

        const data = await this.request(`/exp_abonnement/${this._abonnementId}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200) {
            const {abonnement, audit} = data;
            const date_debut = abonnement.date_debut && moment.utc(abonnement.date_debut).toDate();
            const date_fin = abonnement.date_fin && moment.utc(abonnement.date_fin).toDate();
            this.setState((prev: any) => ({
                abonnement: {
                    proprietaire: abonnement.proprietaire.id,
                    libelle: abonnement.libelle,
                    media: abonnement.media,
                    groupe: abonnement.groupe.id,
                    date_debut: date_debut,
                    date_fin: date_fin,
                },
                checked_rapports: abonnement.rapports.map((rap: any) => rap.id),
                rapports: abonnement.rapports,
                options: {
                    ...prev.options,
                    entites: [...prev.options.entites, {
                        label: `${abonnement.proprietaire.siret} - ${abonnement.proprietaire.nom_usuel}`,
                        value: abonnement.proprietaire.id,
                    }],
                    groupes: [...prev.options.groupes, {
                        label: abonnement.groupe.nom,
                        value: abonnement.groupe.id,
                    }],
                },
                auditFields: audit,
                isDateDebutDisabled: (this._isDetailsPage || (this._isUpdatePage && date_debut <= this._today)),
            }), async () => callback && await callback());
        }
    }

    getRapports = async () => {
        this.setState({isLoading: true});

        const params = new URLSearchParams();

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (val !== '') params.append(key, val);
        });

        this._isDetailsPage && params.append('abo_id', this._abonnementId);
        params.append('media', this.state.abonnement.media);

        const data = await this.request(`/ref_rapport?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200) {
            this.setState((prev: any) => ({
                rapports: data.rapports,
            }));
        }
    }

    getEntiteOptions = async (e: any | null = null) => {
        let URL = '/ent_entite/autocomplete/hierarchy';

        if (e) {
            if (e.action.action !== 'input-change') return;
            URL += `?query=${e.data}`;
        }

        const data = await this.request(URL, 'GET');
        if (data && this._isMounted) {
            if (!e && data?.length === 1) {
                this.setState((prev: any) => ({
                    options: {
                        ...prev.options,
                        currentEntiteOnly: true,
                        entites: data.map((option: any) => ({label: option.label, value: option.id}))
                    }
                }));
            } else {
                const entitesOptions = data.map((option: any) => ({label: option.label, value: option.id}));

                if (!e) {
                    const currentEnt = getHabilitation().ent_id;
                    if (this.state.abonnement.proprietaire === currentEnt.id && !entitesOptions.find((option: any) => option.value === currentEnt.id)) {
                        entitesOptions.unshift({label: `${currentEnt.siret} - ${currentEnt.nom_usuel}`, value: currentEnt.id});
                    }
                }

                this.setState((prev: any) => ({
                    options: {
                        ...prev.options,
                        entites: entitesOptions,
                    }
                }));
            }
        }
    }

    getGroupeOptions = async (e: any | null = null) => {
        let URL = '/ent_groupe/autocomplete';
        const params = new URLSearchParams();
        params.append('type_groupe', 'P');
        params.append('owner_only', 'true');

        if (e) {
            if (e.action.action !== 'input-change') return;
            params.append('query', e.data);
        }

        const data = await this.request(`${URL}?${params.toString()}`, 'GET');
        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    groupes: data.groupes.map((option: any) => ({label: option.nom, value: option.id}))
                }
            }));
        }
    }

    handleRadio = (e: any) => {
        const value = e.target.value;

        if (value && value !== this.state.abonnement.media ) {
            this.setState((prev: any) => ({
                abonnement: {
                    ...prev.abonnement,
                    media: value,
                }
            }), this.getRapports);
        }
    }

    afterSubmission = async (event: React.FormEvent) => {
        event.preventDefault();
        const {t} = this.props;

        this._isMounted && this.setState({isSubmitted: true});

        if (this.validator.allValid()) {
            if (this.state.checked_rapports.length) {
                    await this.createUpdateAbonnement();
            } else {
                sendToast(<p>{t('error_no_rapport_checked')}</p>, TOAST_TYPE.ERROR);
            }
        } else {
            this.validator.showMessages();
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }
    }

    createUpdateAbonnement = async () => {
        this.setState({isLoading: true});

        const URL = this._isUpdatePage ? `/exp_abonnement/${this._abonnementId}` : `/exp_abonnement`;
        const METHOD = this._isUpdatePage ? `PATCH` : `POST`;

        const abonnement = this.state.abonnement;

        let BODY: any = {
            libelle: abonnement.libelle,
            date_debut: abonnement.date_debut,
            date_fin: abonnement.date_fin,
            rapports: this.state.checked_rapports,
        };

        if (!this._isUpdatePage) BODY = {
            ...BODY,
            ent_id: abonnement.proprietaire,
            grp_id: abonnement.groupe,
            media: abonnement.media,
        };

        const data = await this.request(URL, METHOD, BODY);
        this.setState({isLoading: false});

        if (data?.statusCode === 201) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            if (this._isUpdatePage) {
                this.props.history.push(`/flux/abonnements/${this._abonnementId}`);
            } else {
                this.props.history.push(`/flux/abonnements`);
            }
        }
    }

    deleteAbonnement = async () => {
        const data = await this.request(`/exp_abonnement/${this._abonnementId}`, 'DELETE');
        if (data?.statusCode === 200) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            this.props.history.push(`/flux/abonnements`);
        }
    }

    handleCheckAll = (e: any) => {
        const {checked} = e.target;
        const checkedRapports = checked ? this.state.rapports.map((rap: any) => rap.id) : [];
        this.setState({checked_rapports: checkedRapports});
    }

    handleCheck = (e: any, id: number) => {
        const {checked} = e.target;
        const checkedRapports = checked
            ? [...this.state.checked_rapports, id]
            : this.state.checked_rapports.filter((n: any) => n !== id);

        this.setState({checked_rapports: checkedRapports});
    }

    handleSort = (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC',
            }
        }), this.getRapports);
    }

    renderButtons = () => {
        if (this._isDetailsPage) return <>
            <Link to="/flux/abonnements" className="btn m-r-5">Annuler</Link>
            <button className="btn btn-danger m-r-5" type="button" onClick={() => this.setState({deletingAbonnement: true})}>Supprimer</button>
            <Link to={`/flux/abonnements/${this._abonnementId}/modifier`} className="btn btn-dark">Modifier</Link>
        </>

        if (this._isUpdatePage) return <>
            <Link to={`/flux/abonnements/${this._abonnementId}`} className="btn m-r-5">Annuler</Link>
            <button className="btn btn-danger m-r-5" type="button" onClick={() => this.setState({deletingAbonnement: true})}>Supprimer</button>
            <button type="submit" className="btn btn-secondary">Enregistrer</button>
        </>

        return <>
            <Link to="/flux/abonnements" className="btn m-r-5">Annuler</Link>
            <button type="submit" className="btn btn-secondary">Enregistrer</button>
        </>
    }

    render() {
        const { t } = this.props;
        return (
          <DefaultLayout loading={this.state.isLoading}>
              <div className="container m-b-20">
                  <BreadCrumb crumbs={[{name: 'Flux', path: '/flux'}, {
                      name: 'Abonnements',
                      path: '/flux/abonnements'
                  }, {name: `Création d'un abonnement`}]}/>
                  <form onSubmit={(e) => this.afterSubmission(e)}>
                      <div className="d-flex justify-content-between flex-wrap align-items-center">
                          <h1 className="main-title">{this._title}</h1>
                          <div className="m-b-30">
                              {this.renderButtons()}
                          </div>
                      </div>
                      <Panel title="Informations générales">
                          <div className="form-group form-group--inline m-b-30">
                              <FormInputSelect
                                options={this.state.options.entites}
                                label={t('abonnements.owner')}
                                name="proprietaire"
                                id="proprietaire"
                                handle={(e: any) => this.handleInputChange(e, null, 'abonnement')}
                                handleInput={(e: any) => this.getEntiteOptions(e)}
                                value={this.state.abonnement?.proprietaire || ''}
                                isSubmitted={this.state.isSubmitted}
                                isClearable
                                disabled={this.state.options.currentEntiteOnly || this._abonnementId}
                                required
                                simpleValidator={this.validator}
                                validator_type="numeric"
                              />
                          </div>
                          <div className="form-group form-group--inline m-b-30">
                              <FormInput
                                label={t('abonnements.label')}
                                id="libelle"
                                name="libelle"
                                type="text"
                                handle={(e: any) => this.handleInputChange(e, null, 'abonnement')}
                                value={this.state.abonnement?.libelle || ''}
                                isSubmitted={this.state.isSubmitted}
                                disabled={this._isDetailsPage}
                                isClearable
                                required
                                simpleValidator={this.validator}
                                maxLength={50}
                              />
                          </div>
                          <div className="form-group form-group--inline m-b-30">
                              <FormRadioValues
                                id="media"
                                name="media"
                                label={t('abonnements.media')}
                                value={this.state.abonnement?.media}
                                options={[{value: 'MAIL', label: 'Mail'}, {value: 'SMS', label: 'SMS'}]}
                                handle={(e: any) => this.handleRadio(e)}
                                isSubmitted={this.state.isSubmitted}
                                disabled={this._abonnementId}
                                required
                              />
                          </div>
                          <div className="form-group form-group--inline m-b-30">
                              <FormInputSelect
                                label={t('abonnements.recipient')}
                                id="groupe"
                                name="groupe"
                                options={this.state.options.groupes}
                                handle={(e: any) => this.handleInputChange(e, null, 'abonnement')}
                                handleInput={(e: any) => this.getGroupeOptions(e)}
                                value={this.state.abonnement?.groupe || ''}
                                isSubmitted={this.state.isSubmitted}
                                disabled={this._abonnementId}
                                isClearable
                                required
                                simpleValidator={this.validator}
                                validator_type="numeric"
                              />
                          </div>
                          <div className="form-group form-group--inline m-b-30">
                              <FormDatePicker
                                label={t('abonnements.date_start')}
                                name="date_debut"
                                type="text"
                                handle={(e: any) => this.handleInputChange(e, null, 'abonnement')}
                                value={this.state.abonnement?.date_debut}
                                isSubmitted={this.state.isSubmitted}
                                disabled={this.state.isDateDebutDisabled}
                                required
                                validation={validateLaterOrToday}

                              />
                          </div>
                          <div className="form-group form-group--inline m-b-30">
                              <FormDatePicker
                                label={t('abonnements.date_end')}
                                name="date_fin"
                                type="text"
                                handle={(e: any) => this.handleInputChange(e, null, 'abonnement')}
                                value={this.state.abonnement?.date_fin}
                                isSubmitted={this.state.isSubmitted}
                                validation={(e: Date) => validateLaterThanDate(e, this.state.abonnement.date_debut, 'de début')}
                                disabled={this._isDetailsPage}
                                isClearable={!this._isDetailsPage}
                              />
                          </div>
                      </Panel>
                  </form>

                  <Panel title="Liste des rapports">
                      <TABLE responsive="card">
                          <TABLE.THEAD>
                              <TABLE.TR>
                                  {!this._isDetailsPage && (
                                      <TABLE.CHECKBOX
                                        type="TH"
                                        checked={this.state.rapports.length === this.state.checked_rapports.length}
                                        onChange={(e: any) => this.handleCheckAll(e)}
                                      />
                                  )}
                                  <TABLE.TH
                                    name="rap_nom"
                                    input={{
                                        value: this.state.filters.rap_nom,
                                        placeholder: `Nom du rapport`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.getRapports)
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('rap_nom')
                                    }}
                                  />
                                  <TABLE.TH
                                    name="rap_description"
                                    input={{
                                        value: this.state.filters.rap_description,
                                        placeholder: `Description`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.getRapports)
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('rap_description')
                                    }}
                                  />
                                  <TABLE.TH
                                    name="rap_type_destinataire"
                                    input={{
                                        value: this.state.filters.rap_type_destinataire,
                                        placeholder: `Type de destinataire`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.getRapports)
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('rap_type_destinataire')
                                    }}
                                  />
                                  <TABLE.TH
                                    name="rap_type_declenchement"
                                    input={{
                                        value: this.state.filters.rap_type_declenchement,
                                        placeholder: `Déclenchement envoi`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.getRapports)
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('rap_type_declenchement')
                                    }}
                                  />
                                  <TABLE.TH
                                    name="rap_jour_declenchement"
                                    input={{
                                        value: this.state.filters.rap_jour_declenchement,
                                        placeholder: `Jour envoi`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.getRapports)
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('rap_jour_declenchement')
                                    }}
                                  />
                              </TABLE.TR>
                          </TABLE.THEAD>
                          <TABLE.TBODY>
                              {this.state.rapports?.map((rapport: any) => (
                                <TABLE.TR key={rapport.id}>
                                    {!this._isDetailsPage && (
                                        <TABLE.CHECKBOX
                                          type="TD"
                                          checked={this.state.checked_rapports.includes(rapport.id)}
                                          onChange={(e: any) => this.handleCheck(e, rapport.id)}
                                        />
                                    )}
                                    <TABLE.TD label="Nom">{rapport.nom}</TABLE.TD>
                                    <TABLE.TD label="Description" className="cell-line-wrap">{rapport.description}</TABLE.TD>
                                    <TABLE.TD label="Type de destinataire">{rapport.type_destinataire || 'Non précisé'}</TABLE.TD>
                                    <TABLE.TD label="Déclenchement evoi">{rapport.type_declenchement}</TABLE.TD>
                                    <TABLE.TD label="Jour envoi">{rapport.jour_declenchement || '-'}</TABLE.TD>
                                </TABLE.TR>
                              ))}
                          </TABLE.TBODY>
                      </TABLE>
                  </Panel>
              </div>
              <Modal open={this.state.deletingAbonnement}>
                  <div className="modal-body">
                      {this.state.deletingAbonnement && (
                        <p>Etes-vous sûr(e) de vouloir supprimer l'abonnement <b>{this.state.abonnement.libelle}</b> pour
                            le groupe <b>{this.state.options.groupes.find((grp: any) => grp.value === this.state.abonnement.groupe).label}</b> ?</p>
                      )}
                  </div>
                  <div className="modal-footer">
                      <button onClick={() => this.setState({ deletingAbonnement: false })} className="btn btn-white">Annuler</button>
                      <button onClick={() => this.deleteAbonnement()} className="btn btn-secondary">Confirmer</button>
                  </div>
              </Modal>
              {(this._abonnementId && this.state.auditFields) && <AuditFields audit={this.state.auditFields}/>}
          </DefaultLayout>
        );
    }
}

export default withTranslation()(withRouter(CreateAbonnement));