export enum TYPES_FLUX_ANNUAIRE {
    ANNU = 'ANNU',
    GROU = 'GROU',
}

export const TYPES_FLUX_ANNUAIRE_LABEL: {[key: string]: string} = {
    [TYPES_FLUX_ANNUAIRE.ANNU]: 'ANNU',
    [TYPES_FLUX_ANNUAIRE.GROU]: 'GROU',
}

export const TYPES_FLUX_ANNUAIRE_OPTIONS = Object.entries(TYPES_FLUX_ANNUAIRE_LABEL).map(([value, label]) => ({
    value, label
}))