export enum SUIVI_SMS_STATUTS {
    WAIT = 'WAIT',
    ON = 'ON',
    KO = 'KO',
    OK  ='OK',
    REP = 'REP',
    NO = 'NO',
    IN_PROGRESS = 'IN_PROGRESS',
    SCHEDULED = 'SCHEDULED',
}

export const SUIVI_SMS_STATUTS_LABEL: {[key: string]: string} = {
    [SUIVI_SMS_STATUTS.IN_PROGRESS]: 'En cours',
    [SUIVI_SMS_STATUTS.WAIT]: 'Planifié',
    [SUIVI_SMS_STATUTS.ON]: 'Envoyé',
    [SUIVI_SMS_STATUTS.KO]: 'Erreur',
    [SUIVI_SMS_STATUTS.OK]: 'Reçu',
    [SUIVI_SMS_STATUTS.REP]: 'Stop',
    [SUIVI_SMS_STATUTS.NO]: 'Inconnu',
}