import saveAs from "file-saver";
import moment from "moment/moment";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { FilterDropdown } from "../../../components/FilterDropdown";
import { FormDatePicker } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import Pagination from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { STATUTS_SUIVI_EXPORT_LABEL, STATUTS_SUIVI_EXPORT_OPTIONS_ANNU } from "../../../enum/statuts_suivi_export";
import { DebounceQueue } from "../../../helpers/debouncer";
import { TOAST_TYPE, dateFormat, datePickerFormat, getFileNameFromPath, sendToast } from "../../../helpers/helpers";
import { ImportExportAnnuaireHeader } from "./common/ImportExportAnnuaireHeader";

class ExportsFluxAnnuaire extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: 'date_fin',
        ordering: 'DESC',
        start_date_range_from: moment().utc().subtract(1, 'month').startOf('day').toDate(),
        start_date_range_to: moment().utc().startOf('day').toDate(),
        fichier: '',
        statut: '',
        date_fin: '',
        emetteur: '',
    }

    private options = {
        statut: STATUTS_SUIVI_EXPORT_OPTIONS_ANNU,
    }

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            filters: this.defaultFilters,
            options: this.options,
            exportsSuivisAnnu: [],
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            }
        }
    }

    componentDidMount() {
        document.title = 'Infolabo | Imports et exports annuaire';
        this._isMounted = true;

        this.getExportsSuivisAnnu();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getExportsSuivisAnnu = async (triggeredByPagination: boolean = false) => {
        this.setState({isLoading: true});

        const dateKeys = [
            'date_fin',
            'start_date_range_from',
            'start_date_range_to',
        ];

        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (dateKeys.includes(key) && typeof val !== 'string') {
                !!val && params.append(key, val.toISOString());
            }
            else if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/inf-suivi-export/annu?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                exportsSuivisAnnu: data.suivisExport,
                paginate: {...prev.paginate, ...data.paginate},
            }));
        }
    }

    handlePerPageChange = (newLimit: number) => {
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                limit: newLimit,
                page: 0,
            }
        }), () => this.queue.push(this.getExportsSuivisAnnu, [], true));
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getExportsSuivisAnnu, [true], true));
    }

    handleSort = (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getExportsSuivisAnnu, [], true));
    }

    fileDownload = async (id: number, filePath: string) => {
        if (!filePath) {
            sendToast(<p>Impossible de télécharger le fichier</p>, TOAST_TYPE.ERROR);
            return;
        }
        const fileName = getFileNameFromPath(filePath);

        const blob: Blob = await this.request(`/inf-suivi-export/download/${id}`, 'GET', undefined, false, false, true);

        if (!!blob && blob instanceof Blob) {
            saveAs(blob, fileName);
        }
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <ImportExportAnnuaireHeader
                        paginate={this.state.paginate}
                        onPerPageChange={this.handlePerPageChange}
                        handleRefresh={this.getExportsSuivisAnnu}
                    />

                    <FilterDropdown
                        parent={this}
                        filters={this.state.filters}
                        onSearchLoad={(filters: any) => this.setState((prev: any) => ({filters: {...prev.filters, ...filters}}), () => this.queue.push(this.getExportsSuivisAnnu, [], true))}
                        onFiltersReset={() => this.setState({filters: this.defaultFilters}, () => this.queue.push(this.getExportsSuivisAnnu, [], true))}
                        onFiltersSearch={() => this.queue.push(this.getExportsSuivisAnnu, [], true)}
                        onApplyFilters={() => this.queue.push(this.getExportsSuivisAnnu, [], true)}
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group-flex">
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="start_date_range_from"
                                            id="start_date_range_from"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.start_date_range_from) || ''}
                                            label="Date de début du"
                                            required
                                        />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="start_date_range_to"
                                            id="start_date_range_to"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.start_date_range_to) || ''}
                                            label="au"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FilterDropdown>

                    <TABLE fixed responsive="accordion">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH fixed={{left: 0, width: 58}}/>
                                <TABLE.TH name="fichier" fixed={{left: 58}}
                                    input={{placeholder: 'Nom du fichier', value: this.state.filters.fichier, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getExportsSuivisAnnu))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('fichier')}}
                                />
                                <TABLE.TH name="type_export" label="Type"/>
                                <TABLE.TH name="statut"
                                    select={{placeholder: 'Statut', value: this.state.filters.state, options: this.state.options.statut, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getExportsSuivisAnnu))}}
                                />
                                <TABLE.TH name="date_fin"
                                    datePicker={{value: this.state.filters.date_fin, placeholder: 'Transmission', onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getExportsSuivisAnnu))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('date_fin')}}
                                />
                                <TABLE.TH name="nb_lignes" label="Nb lignes"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_lignes')}}
                                />
                                <TABLE.TH name="emetteur"
                                    input={{placeholder: 'Emetteur', value: this.state.filters.emetteur, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getExportsSuivisAnnu))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('emetteur')}}
                                />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.exportsSuivisAnnu?.map((suivi_export: any) => (
                                <TABLE.TR title={getFileNameFromPath(suivi_export.fichier) || '-'} key={suivi_export.id}>
                                    <TABLE.TD fixed={{left: 0, width: 58}} hiddenMobile>
                                        {suivi_export.fichier && (
                                            <button title="Télécharger fichier CSV" type="button" className="action-btn" onClick={() => this.fileDownload(suivi_export.id, suivi_export.fichier)}>
                                                <i className="icon-file"></i>
                                                <span className="sr-only">Télécharger fichier CSV</span>
                                            </button>
                                        )}
                                    </TABLE.TD>
                                    <TABLE.TD fixed={{left: 58}} label="Nom du fichier"><strong>{getFileNameFromPath(suivi_export.fichier)}</strong></TABLE.TD>
                                    <TABLE.TD label="Type">{suivi_export.type_export || ''}</TABLE.TD>
                                    <TABLE.TD label="Statut">{STATUTS_SUIVI_EXPORT_LABEL[suivi_export.statut] || ''}</TABLE.TD>
                                    <TABLE.TD label="Transmission">{suivi_export.date_fin ? dateFormat(suivi_export.date_fin, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Nb lignes">{suivi_export.nb_lignes_fichier || 0}</TABLE.TD>
                                    <TABLE.TD label="Emetteur">{suivi_export.emetteur?.nom_usuel || ''}</TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>

                    {this.state.exportsSuivisAnnu?.length === 0 && (
                        <TABLE.NO_RESULT/>
                    )}

                    <Pagination
                        handleClick={this.paginationOnClick}
                        pageCurrent={+this.state.paginate.page + 1}
                        pageTotal={this.state.paginate.pageTotal}
                        pageDisplay={3}
                    />
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ExportsFluxAnnuaire))