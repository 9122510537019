import {useTranslation} from 'react-i18next';
import {isAndroid, isIOS} from 'react-device-detect';
import {useState} from "react";

const getDeviceURL = (): string | undefined => {
    const ANDROID_URL: string | undefined = process.env.REACT_APP_PLAYSTORE_URL;
    const IOS_URL: string | undefined = process.env.REACT_APP_APPSTORE_URL;

    if (isAndroid && ANDROID_URL) return ANDROID_URL;
    if (isIOS && IOS_URL) return IOS_URL;
    return undefined;
}

export const InformationBanner = () => {
    const {t} = useTranslation();
    const deviceURL: string | undefined = getDeviceURL();
    const closedBanner: boolean = sessionStorage.getItem('closedInfoBanner') === 'true';
    const [isOpen, setIsOpen] = useState<boolean>(!closedBanner);

    const handleClose = () => {
        sessionStorage.setItem('closedInfoBanner', 'true');
        setIsOpen(false);
    }

    if (!isOpen || !deviceURL) return <></>;
    return (
        <div className="info-banner">
            <strong className="text-center m-0">
                {t('info_banner.text')}
            </strong>
            <div className="d-flex justify-content-center">
                <button
                    className="btn btn-white"
                    onClick={handleClose}
                >
                    {t('info_banner.close')}
                </button>
                <a
                    className="btn btn-secondary m-l-20"
                    href={deviceURL}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t('info_banner.install')}
                </a>
            </div>
        </div>
    )
}