export const Todo = ({children, title="todo"}: any) => (
    <pre className="todo"><div className="todo__title">{title.toUpperCase()}: </div><>{children}</></pre>
)

export const InProgress = () => (
    <span style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 9999999999,
        backgroundColor: 'black',
        pointerEvents: 'none',
        padding: '18px 40px',
        opacity: 0.5,
        fontSize: 20,
        textTransform: 'uppercase'

    }}>Page en cours</span>
)