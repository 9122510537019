import { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';

interface Props {
    title?: string,
    togglableID?: string,
    open?: boolean
    children: JSX.Element | JSX.Element[],
    handleChange?: Function | undefined
}

const Panel = ({ title, togglableID = "", open = false, children, handleChange = undefined }: Props) => {
    const [thisOpen, setThisOpen] = useState(open);

    useEffect(() => {
        setThisOpen(open)
    }, [open]);

    return (
        togglableID ? (
            <section className="panel" >
                {title && (
                    <h2 className="panel__title panel__toggle" id={`${togglableID}__title`}>
                        <button
                            type="button"
                            data-toggle="collapse"
                            data-target={`#${togglableID}__content`}
                            aria-expanded={thisOpen}
                            onClick={() => { handleChange && handleChange(!thisOpen); setThisOpen(!thisOpen); }}
                        >
                            {title}
                        </button>
                    </h2>
                )}
                <Collapse in={thisOpen}>
                    <div className="panel__content" >
                        {children}
                    </div>
                </Collapse>
            </section >
        ) : (
            <section className="panel">
                <h2 className="panel__title">{title}</h2>
                <div className="panel__content">
                    {children}
                </div>
            </section>
        )
    )
}

export default Panel;