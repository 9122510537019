interface ToggleSwitchProps {
    active: boolean
    variant?: 'default'|'blue'|'white'
    labels?: {
        off?: string,
        on?: string
    },
    id?: string
    onChange: Function
}
export const ToggleSwitch = ({labels, active, variant = 'default', onChange, id}: ToggleSwitchProps) => {
    return (
        <div className={`toggle-switch toggle-switch--${variant}`}>
            {labels?.off && (<span className={`toggle-switch__label _off ${!active ? '_active': ''}`}>{labels.off}</span>)}
            <label className="toggle-switch__switch">
                <input id={id} type="checkbox" checked={active} onChange={() => onChange(!active)}/>
                <span className="toggle-switch__slider"></span>
            </label>
            {labels?.on && (<span className={`toggle-switch__label _on ${active ? '_active': ''}`}>{labels.on}</span>)}
        </div>
    )
}

interface ToggleSwitchAllProps {
    onToggle: Function
}
export const ToggleSwitchAll = ({onToggle} : ToggleSwitchAllProps) => {
    const handleClick = (e: any, value: boolean) => {
        e.preventDefault()
        onToggle(value)
    }

    return (
        <div className="toggle-switch-all">
            <div className="toggle-switch-all__content">
                <button onClick={e => handleClick(e, true)} type="button" className="toggle-switch-all__button" data-tip="Activer" data-type="info">
                    <span className="sr-only">Activer</span>
                </button>
                <button onClick={e => handleClick(e, false)} type="button" className="toggle-switch-all__button" data-tip="Désactiver" data-type="info">
                    <span className="sr-only">Désactiver</span>
                </button>
            </div>
        </div>
    )
}