import { useEffect, useState } from 'react'
import { Dropdown } from "react-bootstrap"
import { confirm } from "react-confirm-box"
import { Link, NavLink } from "react-router-dom"
import BreadCrumb from "../../../../components/BreadCrumb"
import Modal from '../../../../components/Modal'
import { LimitValuesSelect } from "../../../../components/TableComponents"
import { hasPermission, Request, sendToast, TOAST_TYPE } from "../../../../helpers/helpers"

interface ImportExportResultatsHeaderProps {
    paginate: { count: number, limit: number, page: number, pageTotal: number }
    onPerPageChange: Function
    handleRefresh: Function
}

export const ImportExportResultatsHeader = ({ paginate, onPerPageChange, handleRefresh }: ImportExportResultatsHeaderProps) => {
    const [isArretUrgenceModalOpen, setArretUrgenceModalOpen] = useState(false);
    const [arretUrgence, setArretUrgence]: any = useState(false);

    const getArretUrgenceStatus = async () => {
        const URL = '/inf_arret_urgence/resultats';

        const data = await Request(URL, 'GET');

        if (data.statusCode === 200) {
            setArretUrgence(data?.enabled);
        } else {
            setArretUrgence(false);
        }
    }

    const handleArretUrgence = async () => {
        // check import REF metier exist with EC status
        if (arretUrgence) {
            const isImportInEcStatus = await Request('/inf-suivi-flux-ref/is_metier_in_ec_status', 'GET');
            if (isImportInEcStatus.statusCode === 200 && isImportInEcStatus?.result) {
                sendToast(<p>Un import de référence métier est en cours d’exécution, veuillez patienter avant de lever l’arrêt d’urgence résultats</p>, TOAST_TYPE.ERROR);
                return;
            }
        }

        const URL = '/inf_arret_urgence/resultats/set/' + (arretUrgence ? 'false' : 'true');

        const data = await Request(URL, 'GET');

        if (data.statusCode === 200) {
            setArretUrgence(data?.enabled);
            if (data?.enabled) {
                sendToast(<p>Activation de l'arrêt d'urgence</p>, TOAST_TYPE.SUCCESS);
                setArretUrgenceModalOpen(false);
            }
            else {
                sendToast(<p>Fin de l'arrêt d'urgence</p>, TOAST_TYPE.SUCCESS);
            }
        }
    }

    const onCloseArretUrgence = () => {
        setArretUrgenceModalOpen(false);
    }

    const handleExportSIGAL = async (confirmed?: boolean) => {
        const URL = '/exports/sigal';

        const data = await Request(URL, 'POST', { confirm: confirmed });

        if (data.statusCode === 206) {
            const confirmed = await confirm(data.message, { labels: { confirmable: "Confirmer", cancellable: "Annuler" } });
            if (confirmed) handleExportSIGAL(true);
        }

        if (data.statusCode === 200) {
            sendToast(data.message, TOAST_TYPE.SUCCESS);
        }
    }

    useEffect(() => {
        getArretUrgenceStatus();
    }, []);

    return (<>
        <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }, { name: 'Imports et exports de résultats', path: '/import_export_resultat' }]} />

        <div className="d-flex justify-content-between align-items-start flex-wrap m-b-15">
            <h1 className="main-title m-b-15">Imports et exports de résultats</h1>
            <div className='d-flex flex-wrap m-b-5'>
                {hasPermission('DRT_FLUX_RES_ARRET_URGENCE') && (
                    <button type="button" onClick={() => arretUrgence ? handleArretUrgence() : setArretUrgenceModalOpen(true)} className="btn btn-alert m-l-10 m-b-10">
                        <i className="icon-block"></i>
                        {arretUrgence ? "Lever l'arrêt d’urgence résultats" : "Arrêt d'urgence résultats"}
                    </button>
                )}
                <button type="button" className="btn btn-dark m-l-10 m-b-10" onClick={() => handleRefresh()}>
                    <i className="icon-refresh"></i>
                    Réactualiser
                </button>
                {(hasPermission('DRT_FLUX_EXPORT_PONCTUEL') || hasPermission('DRT_FLUX_EXPORT_SIGAL')) && (
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle bsPrefix="btn tben-dark m-l-10 m-b-10">
                            <i className="icon-add"></i>
                            Créer
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {hasPermission('DRT_FLUX_EXPORT_PONCTUEL') && (
                                <Link
                                    className="dropdown-item"
                                    to="/flux/imports_exports_resultats/creer_export_ponctuel">
                                    Export ponctuel
                                </Link>
                            )}
                            {hasPermission('DRT_FLUX_EXPORT_SIGAL') && (
                                <button type="button" onClick={() => handleExportSIGAL()} className="dropdown-item">
                                    Export SIGAL
                                </button>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && (
                    <Link to="/flux/exports_laiterie" target="_blank" className="btn btn-dark m-l-10 m-b-10">
                        <i className="icon-list"></i>
                        Gérer les exports laiterie
                    </Link>
                )}
            </div>
        </div>

        <LimitValuesSelect
            default_value={paginate.limit}
            steps={[10, 20, 50]}
            onChange={(e: any) => onPerPageChange(+e?.target?.value)} />

        <ul className="nav nav-tabs">
            {hasPermission('DRT_FLUX_RES_IMPORT') && (
                <li className="nav-item">
                    <NavLink exact to={'/flux/imports_exports_resultats/imports'} className="nav-link"><i className='icon-import'></i>Imports</NavLink>
                </li>
            )}
            {hasPermission('DRT_FLUX_RES_EXPORT') && (
                <li className="nav-item">
                    <NavLink exact to={'/flux/imports_exports_resultats/exports'} className="nav-link"><i className='icon-import'></i>Exports</NavLink>
                </li>
            )}
        </ul>

        <Modal open={isArretUrgenceModalOpen}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                L’activation de l’arrêt d’urgence aura pour effet de mettre en standby les imports de résultats : tout nouveau fichier REJO/REME déposé sera mis en attente jusqu'à la levée de l’arrêt d’urgence.
            </div>
            <button onClick={onCloseArretUrgence} className="btn btn-white">Annuler</button>
            <button onClick={handleArretUrgence} className="btn btn-secondary">Confirmer</button>
        </Modal>
    </>
    )
}