import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import CustomComponent from "../../components/CustomComponent";
import Panel from "../../components/Panel";
import { getSession, hasPermission } from "../../helpers/helpers";

class EntitiesPanel extends CustomComponent {
    private _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {
            per_hab: [],
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getPerHabilitations();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getPerHabilitations = async () => {
        const per_id = getSession().per_id;
        const data = await this.request(`/per_habilitation/findByPerson/${per_id}`, 'GET');
        if (!!data && data?.length > 0 && this._isMounted) {
            this.setState({per_hab: data});
        }
    }

    render() {
        const {t} = this.props;

        return (
            <Panel
                title={t('mes_informations.entities_panel_title')}
                togglableID="panel-entfon"
                open={true}
            >
                <div className="row">
                    {this.state?.per_hab?.map((hab: any) => (
                        <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30" key={hab.id}>
                            <div className="info-card">
                                <h4 className="info-card__title">
                                    {hasPermission('DRT_ANNU_ENT_CONSULTER') ? (
                                        <Link target="_blank" to={`/annuaire/entite/${hab.ent_id?.id}`}>{hab.ent_id?.nom_usuel}</Link>
                                    ) : (hab.ent_id?.nom_usuel)}
                                </h4>
                                {hab.ent_id?.ent_pay_id?.code_pays}{hab.ent_id?.siren}{hab.ent_id?.nic}
                                <dl className="info-card__info">
                                    <dt>Habilitation :</dt>
                                    <dd>{hab.hab_code?.lib_hab}</dd>
                                </dl>
                            </div>
                        </div>
                    ))}
                </div>
            </Panel>
        )
    }
}

export default withTranslation()(withRouter(EntitiesPanel));