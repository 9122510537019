import { withRouter } from 'react-router-dom';
import CustomComponent from '../../components/CustomComponent';
import { LoginLayout } from '../../components/Layouts';
import { HOST } from '../../config';
import BreadCrumb from '../../components/BreadCrumb';

class MentionsLegales extends CustomComponent {
    componentDidMount() {
        document.title = 'Infolabo';
        //this.checkIsConnectedPWA();
    }

    render() {
        return (
            <LoginLayout>
                <div className="container page-login__wrapper">
                    <BreadCrumb crumbs={[{name: 'Mentions légales', path: '/mentions_legales'}]}/>
                    <h1 className="main-title text-center">Mentions légales</h1>
                    <div className="page-login__legal-notice">
                        <h2>Mentions légales Infolabo®</h2>
                        <section>
                            <h3>À propos d’Infolabo®</h3>
                            <p>Le site web disponible à l’adresse <a href="/">{HOST}</a> a pour objectif de permettre aux utilisateurs inscrits d’accéder au moyen d’un compte en ligne au système Infolabo® développé pour la filière laitière et destiné à faciliter l’échange et la diffusion des résultats d’analyses de lait auprès des acteurs concernés.</p>
                        </section>
                        <section>
                            <h3>Editeur</h3>
                            <h4>Ce site web est édité par :</h4>
                            <address>Le Centre National Interprofessionnel de l’Economie Laitière (CNIEL), Association loi 1901, déclarée à la Préfecture de Paris le 8 avril 1974, enregistrée sous le n°W751035614, 42, rue de Châteaudun, 75314 Paris Cedex 9</address>
                            <p>Directeur de la publication : Madame Caroline LE POULTIER.</p>
                            <address>Le Centre National Interprofessionnel de l’Economie Laitière (CNIEL), Association loi 1901, déclarée à la Préfecture de Paris le 8 avril 1974, enregistrée sous le n°W751035614, 42, rue de Châteaudun, 75314 Paris Cedex 9</address>
                            <p>
                                Pour toute question concernant ce site web, vous pouvez nous contacter à l’adresse suivante : <a href='mailto:infolabo@infolabo.com'>infolabo@infolabo.com</a>
                            </p>
                        </section>
                        <section>
                            <h3>Hébergement</h3>
                            <h4>Le site est hébergé par :</h4>
                            <address>
                                <p>SAS OVH, 2, rue Kellermann, 59100 Roubaix</p>
                                <p>Tel. 09 72 10 10 07– https://www.ovhcloud.com/fr/</p>
                                <p>Datacenter : Gravelines (59820) et Roubaix (59100)</p>
                            </address>
                        </section>
                        <section>
                            <h3>Protection des données personnelles</h3>
                            <p>
                                Pour accéder à Infolabo®, des informations nominatives sont indispensables et doivent être renseignées par l’utilisateur au moment de la création de son compte en ligne. <br/>
                                Les données des utilisateurs sont réservées à l’usage exclusif des organismes habilités. Elles ne sont pas utilisées pour d’autres finalités et ne sont ni communiquées ni cédées à des organismes tiers.
                            </p>
                            <p>En  savoir  plus  sur  les  données  personnelles,  vos  droits  et  les  moyens  de  les  exercer  en  consultant  notre  page « Données Personnelles ».</p>
                            <p>Pour toute demande sur les données personnelles, vous pouvez contacter à tout moment le délégué à la protection des données du CNIEL, selon les modalités suivantes :</p>
                            <ul>
                                <li>Par courrier électronique à l’adresse suivante : <a href="mailto:donneespersonnelles@cniel.com">donneespersonnelles@cniel.com</a>;</li>
                                <li>Par courrier postal : CNIEL – Service du DPO - 42 rue de Châteaudun – 75009 Paris.</li>
                            </ul>
                        </section>
                        <section>
                            <h3>Sécurité des comptes d’accès</h3>
                            <p>Afin d’assurer la confidentialité et la sécurité d’Infolabo®, l’accès au système et aux données est réservé :</p>
                            <ul>
                                <li>aux utilisateurs autorisés ayant un compte en ligne ;</li>
                                <li>à un nombre limité de personnes spécifiquement habilitées à accéder aux données du système et assujetties à un engagement de confidentialité. </li>
                            </ul>
                            <p>L’accès d’un utilisateur au système se fait au moyen de codes d’accès personnels et confidentiels (identifiant et mot de passe).  Ces  codes  d’accès,  qui  ne  peuvent  en  aucun  cas  être  communiqués  à  des  tiers,  permettent d’accéder uniquement aux données Infolabo® pour lesquelles l’utilisateur est habilité.</p>
                        </section>
                        <section>
                            <h3>Droits de propriété intellectuelle</h3>
                            <p>L’Editeur est propriétaire du site web. L'ensemble des contenus de ce site web est protégé par la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. La reproduction de tout ou partie du site web, par quelque procédé que ce soit et au-delà d’un usage strictement privé, est formellement interdite, y compris pour les fichiers téléchargeables, les logiciels, les visuels d’illustration, les textes et les représentations photographiques, sauf autorisation expresse et préalable de l’Editeur. Les  marques  et  logos,  ainsi  que  les  signes  distinctifs  figurant  sur  le  site  web,  sont  protégés  par  leurs  propriétaires respectifs et ne peuvent être utilisés à une fin quelconque, sans l’autorisation préalable de ces derniers. Les bases de données de ce site web sont protégées par les dispositions du Code de la propriété intellectuelle.</p>
                            <p>Sont notamment interdites l'extraction et la réutilisation, quantitativement ou qualitativement substantielles, du contenu des bases de données Infolabo®. Tout contrevenant s'expose aux sanctions visées aux articles L 343-1 et suivants du Code de la propriété intellectuelle.</p>
                        </section>
                        <section>
                            <h3>Garanties et responsabilités</h3>
                            <p>Les  informations  contenues  sur  le  site  web  engagent  la  responsabilité  de  leur  auteur  et  ne  sauraient  engager  la 
                            responsabilité de l’Editeur lorsque ce dernier n’est pas l’auteur des informations. 
                            L’Editeur ne donne  aucune  garantie  relative à l’utilisation du site web et ne  saurait être  responsable des dommages 
                            directs ou indirects découlant de son utilisation, notamment en cas d’interruption des accès, des services ou de pertes 
                            de données.</p>
                            <p>Les  informations  contenues  sur  le  site  web  engagent  la  responsabilité  de  leur  auteur  et  ne  sauraient  engager  la 
                            responsabilité de l’Editeur lorsque ce dernier n’est pas l’auteur des informations. 
                            L’Editeur ne donne  aucune  garantie  relative à l’utilisation du site web et ne  saurait être  responsable des dommages 
                            directs ou indirects découlant de son utilisation, notamment en cas d’interruption des accès, des services ou de pertes 
                            de données.</p>
                        </section>
                        <section>
                            <h3>Crédits</h3>
                            <p>Conception et réalisation du site web : TALAN Solutions</p>
                        </section>
                    </div>
                    </div>
            </LoginLayout>
        )
    }

}
export default withRouter(MentionsLegales)
