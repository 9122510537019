import LoadingOverlay from 'react-loading-overlay';
import {BeatLoader} from 'react-spinners'

// @ts-ignore: Bug fix with typescript definition of the Lib (causes React false positive Warning)
LoadingOverlay.propTypes = undefined

interface LoadingIndicatorProps {
    loading: boolean
}

export const LoadingIndicator = ({loading = false}: LoadingIndicatorProps) => {
    return (
        <LoadingOverlay
            className='loading-indicator'
            classNamePrefix='loading-indicator__'
            active={loading}
            spinner={<BeatLoader className='loading-indicator__spinner' size={24} color='#1D609D' speedMultiplier={0.5}/>}
            text="Chargement des données"/>
    )
}