import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../components/CustomComponent";
import { FormInput } from "../../components/FormComponents";
import Panel from "../../components/Panel";

class GeneralInformationsPanel extends CustomComponent {
    private _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { t, personne } = this.props;
        return (
            <Panel
                title={t('mes_informations.general_informations_panel_title')}
                togglableID="panel-perinfo"
                open={true}
            >
                <div className="form-group form-group--inline">
                    <FormInput
                        label={t('personne.identifiant')}
                        id="mail_identifiant"
                        name="mail_identifiant"
                        type="email"
                        value={personne?.mail_identifiant || ''}
                        disabled
                    />
                </div>
                <div className="row m-t-50">
                    <div className="col-lg-6">
                        <div className="form-group form-group--inline m-b-30">
                            <FormInput
                                disabled
                                label={t("personne.civility")}
                                name="civility"
                                id="civility"
                                type="text"
                                handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                value={personne?.civilite || ''}
                            />
                        </div>
                        <div className="form-group form-group--inline m-b-30">
                            <FormInput
                                disabled
                                label={t('personne.lastname')}
                                id="nom"
                                name="nom"
                                type="text"
                                value={personne?.nom || ''}
                            />
                        </div>
                        <div className="form-group form-group--inline m-b-30">
                            <FormInput
                                disabled
                                label={t('personne.firstname')}
                                id="prenom"
                                name="prenom"
                                type="text"
                                value={personne?.prenom || ''}
                            />
                        </div>
                        <div className="form-group form-group--inline m-b-30">
                            <FormInput
                                disabled
                                label={t('personne.email1')}
                                id="email_1"
                                name="email_1"
                                type="email"
                                handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                value={personne?.email_1 || ''}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group form-group--inline m-b-30">
                            <FormInput
                                disabled
                                label={t('personne.email2')}
                                id="email_2"
                                name="email_2"
                                type="email"
                                value={personne?.email_2 || ''}
                            />
                        </div>
                        <div className="form-group form-group--inline m-b-30">
                            <FormInput
                                disabled
                                label={t('personne.phone')}
                                id="num_tel"
                                name="num_tel"
                                type="tel"
                                value={personne?.num_tel || ''}
                                isPhone={true}
                                phoneName="ind_tel"
                                phoneValue={personne?.ind_tel || ''}
                                phoneOptions={[personne?.ind_tel || ''].map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                            />
                        </div>
                        <div className="form-group form-group--inline m-b-30">
                            <FormInput
                                disabled
                                label={t('personne.mobile1')}
                                id="num_portable_1"
                                name="num_portable_1"
                                type="tel"
                                value={personne?.num_portable_1 || ''}
                                isPhone={true}
                                phoneName="ind_portable_1"
                                phoneValue={personne?.ind_portable_1 || ''}
                                phoneOptions={[personne?.ind_portable_1 || ''].map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                            />
                        </div>
                        <div className="form-group form-group--inline m-b-30">
                            <FormInput
                                disabled
                                label={t('personne.mobile2')}
                                id="num_portable_2"
                                name="num_portable_2"
                                type="tel"
                                value={personne?.num_portable_2 || ''}
                                isPhone={true}
                                phoneName="ind_portable_2"
                                phoneValue={personne?.ind_portable_2 || ''}
                                phoneOptions={[personne?.ind_portable_2 || ''].map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                            />
                        </div>
                    </div>
                </div>
            </Panel>
        )
    }
}

export default withTranslation()(withRouter(GeneralInformationsPanel));