import CustomComponent from "../../../components/CustomComponent";
import CreateAbonnement from "./create";

class UpdateAbonnement extends CustomComponent {
    render () {
        const id = this.props.match.params.id;
        return(<CreateAbonnement abonnementId={id} isUpdatePage={true} title="Modification d'un abonnement"/>)
    }
}

export default UpdateAbonnement;