import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { LoginLayout } from '../../components/Layouts';

class ErrorBoundary extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <LoginLayout>
                    <main className='my-auto text-center'>
                        <h1>Quelque chose s'est mal passé.</h1>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                        <button
                            className="btn btn-primary mx-10"
                            onClick={() => {
                                localStorage.clear();
                                this.props.history.goBack();
                                window.location.reload();
                            }}>
                            Retour
                        </button>
                    </main>
                </LoginLayout>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);