export enum FORMAT_FICHIER {
    CSV = 'CSV',
    XML = 'XML',
    PLAT = 'PLAT'
}

export const FORMAT_FICHIER_LABEL: {[key: string]: string} = {
    [FORMAT_FICHIER.CSV]: 'CSV',
    [FORMAT_FICHIER.XML]: 'XML',
    [FORMAT_FICHIER.PLAT]: 'PLAT',
}

export const FORMAT_FICHIER_OPTIONS = Object.entries(FORMAT_FICHIER_LABEL).map(([value, label]) => ({
    value, label
}))