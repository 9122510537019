import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import CustomToggle from "../../../components/CustomToggle";
import { FormInputSelect } from "../../../components/FormComponents";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import Panel from "../../../components/Panel";
import TABLE from "../../../components/Table";
import { TOAST_TYPE, hasPermission, sendToast } from '../../../helpers/helpers';
import { initValidator } from "../../../validations/validations";
import AssociatePersonnesToEntites from './_associatePersonnesToEntite';
import CreatePersonnesFromEntite from './_createPersonnesFromEntite';

class PersonnesAssocieesPanel extends CustomComponent {
    private _isMounted = false
    private readonly validator;
    private default_filters = {
        orderBy: 'per_nom_prenom',
        ordering: 'ASC',
        per_nom_prenom: '',
        lib_hab: '',
        lib_fonction: '',
    }

    constructor(props: any) {
        super(props)

        this.state = {
            personnes_associees: [],
            filters: this.default_filters,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            deleteTarget: null,
            modifTarget: null,
            refHabilitations: [],
            fonctions: [],
            newHabilitation: null,
            newFonction: null,
        }

        this.validator = initValidator();
    }

    componentDidMount() {
        this._isMounted = true
        !!this.props.entityId && this.getPersonnesAssociees();
        this.getFonctions();
        this.getRefHabilitations();
    }

    componentWillUnmount(): void {
        this._isMounted = false
    }

    getPersonnesAssociees = async (triggeredByPagination: boolean = false) => {
        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach((key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        }));

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const habData = await this.request(`/per_habilitation/findByEntity/${this.props.entityId}?${params.toString()}`, 'GET');

        if (habData?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                personnes_associees: habData.personnes_associees,
                show_personne_table: habData.show_personne_table,
                paginate: {
                    ...prev.paginate,
                    ...habData.paginate
                }
            }));
        }
    }

    handleSort = (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), this.getPersonnesAssociees);
    }

    paginationOnClick = (e: any) => {
        e.preventDefault();
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.getPersonnesAssociees(true))
    }

    deletePerHabilitation = async () => {
        if (this.state.deleteTarget?.ent_count > 1) {
            const data = await this.request(`/per_habilitation/${this.state.deleteTarget.hab_id}`, 'DELETE');
            if (data && this._isMounted) {
                this.setState((prev: any) => ({
                    deleteTarget: null,
                    personnes_associees: [
                        ...prev.personnes_associees.filter((per: any) => per.per_id !== prev.deleteTarget?.per_id)
                    ]
                }))
            }
        } else {
            sendToast(<p>La seule association d'une personne vers une entité ne peut être supprimée.</p>, TOAST_TYPE.ERROR);
        }
    }

    updatePerHabilitation = async () => {
        if (
            this.state.modifTarget?.code_hab !== this.state.newHabilitation
            || this.state.modifTarget?.fon_id !== this.state.newFonction
        ) {
            const BODY = {
                ent_id: this.props.entite.id,
                per_id: this.state.modifTarget?.per_id,
                hab_code: this.state.newHabilitation,
                fon_id: this.state.newFonction,
            }
            const data = await this.request(`/per_habilitation/${this.state.modifTarget?.hab_id}`, 'PATCH', BODY)
            if (data?.statusCode === 201 && this._isMounted) {
                await this.getPersonnesAssociees();
                this.setState({
                    modifTarget: null,
                });
            }
        }
    }

    getFonctions = async () => {
        const data = await this.request('/ref_fonction', 'GET');

        if (data && this._isMounted) {
            this.setState({ fonctions: data });
        }
    }

    getRefHabilitations = async () => {
        const data = await this.request(`/ref_habilitation/findByClaID`, 'POST', { ids: [this.props.entityId] });
        if (data && this._isMounted) {
            this.setState({ refHabilitations: data });
        }
    }

    render() {
        return (
            <Panel title="Personnes associées" togglableID="panel-personnes_associees" open={true}><>
                {hasPermission('DRT_ANNU_ENTITE') && !this.state.show_personne_table ? (
                    <div className="text-center">Aucune personne n'est rattachée à cette entité</div>
                ) : (
                    <>
                        <TABLE responsive="card">
                            <TABLE.THEAD>
                                <TABLE.TR>
                                    <TABLE.TH
                                        name="per_nom_prenom"
                                        input={{
                                            value: this.state.filters.per_nom_prenom,
                                            placeholder: 'Nom et prénom',
                                            onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.getPersonnesAssociees)
                                        }}
                                        sort={{
                                            orderBy: this.state.filters.orderBy,
                                            ordering: this.state.filters.ordering,
                                            onSort: () => this.handleSort('per_nom_prenom')
                                        }}
                                    />
                                    <TABLE.TH
                                        name="lib_hab"
                                        input={{
                                            value: this.state.filters.lib_hab,
                                            placeholder: 'Habilitation',
                                            onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.getPersonnesAssociees)
                                        }}
                                        sort={{
                                            orderBy: this.state.filters.orderBy,
                                            ordering: this.state.filters.ordering,
                                            onSort: () => this.handleSort('lib_hab')
                                        }}
                                    />
                                    <TABLE.TH
                                        name="lib_fonction"
                                        input={{
                                            value: this.state.filters.lib_fonction,
                                            placeholder: 'Fonction',
                                            onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.getPersonnesAssociees)
                                        }}
                                        sort={{
                                            orderBy: this.state.filters.orderBy,
                                            ordering: this.state.filters.ordering,
                                            onSort: () => this.handleSort('lib_fonction')
                                        }}
                                    />
                                    {!this.props.isVisualization && (
                                        <TABLE.TH actions label="Actions" />
                                    )}
                                </TABLE.TR>
                            </TABLE.THEAD>
                            <TABLE.TBODY>
                                {this.state.personnes_associees.map((personne: any) => (
                                    <TABLE.TR key={personne.hab_id}>
                                        <TABLE.TD label="Nom et prénom">{personne.nom} {personne.prenom}</TABLE.TD>
                                        <TABLE.TD label="Habilitation">{personne.lib_hab}</TABLE.TD>
                                        <TABLE.TD label="Fonction">{personne.lib_fonction}</TABLE.TD>
                                        {!this.props.isVisualization && (
                                            <TABLE.TD actions>
                                                <div className="d-flex desktop-only">
                                                    {(hasPermission('DRT_ANNU_PER_CONSULTER') && this.props.isLinkedPerConsultable) && (
                                                        <Link className="action-btn" to={`/annuaire/personne/${personne.per_id}`} target="_blank"><i className="icon-eye"></i></Link>
                                                    )}
                                                    {(hasPermission('DRT_ANNU_ENT_PER_GERER') && this.props.isEntPerRelEditable) && (
                                                        <button className="action-btn" type="button" onClick={() => this.setState({ modifTarget: personne, newHabilitation: personne?.code_hab, newFonction: personne?.fon_id })}><i className="icon-pen" /></button>
                                                    )}
                                                    {(hasPermission('DRT_ANNU_ENT_PER_GERER') && personne?.ent_count > 1 && this.props.isEntPerRelEditable) && (
                                                        <button className="action-btn" type="button" onClick={() => this.setState({ deleteTarget: personne })}><i className="icon-trash" /></button>
                                                    )}
                                                </div>
                                                <Dropdown className="mobile-only">
                                                    <Dropdown.Toggle as={CustomToggle}>
                                                        <span className="sr-only">Ouvrir les actions</span>
                                                        <i className="icon-actions"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {(hasPermission('DRT_ANNU_PER_CONSULTER') && this.props.isLinkedPerConsultable) && (
                                                            <Link className="dropdown-item" to={`/annuaire/personne/${personne.per_id}`} target="_blank">Consulter</Link>
                                                        )}
                                                        {(hasPermission('DRT_ANNU_ENT_PER_GERER') && this.props.isEntPerRelEditable) && (
                                                            <button className="dropdown-item" type="button" onClick={() => this.setState({ modifTarget: personne, newHabilitation: personne?.code_hab, newFonction: personne?.fon_id })}>Modifier</button>
                                                        )}
                                                        {(hasPermission('DRT_ANNU_ENT_PER_GERER') && personne?.ent_count > 1 && this.props.isEntPerRelEditable) && (
                                                            <button className="dropdown-item" type="button" onClick={() => this.setState({ deleteTarget: personne })}>Supprimer</button>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </TABLE.TD>
                                        )}
                                    </TABLE.TR>
                                ))}
                            </TABLE.TBODY>
                        </TABLE>
                        <Pagination
                            handleClick={this.paginationOnClick}
                            pageCurrent={(this.state.paginate.page + 1)}
                            pageTotal={this.state.paginate.pageTotal}
                            pageDisplay={3}
                        />
                    </>
                )}
                {hasPermission('DRT_ANNU_ENT_PER_GERER') && !this.props.isVisualization && (
                    <div className="row m-t-30">
                        <div className={`col-md-${this.props.isProducteur ? '6' : '12'}`}>
                            <AssociatePersonnesToEntites
                                entite={this.props.entite}
                                exclude={this.state.personnes_associees.map((per: any) => per.per_id)}
                                fonctions={this.state.fonctions}
                                refHabilitations={this.state.refHabilitations}
                            />
                        </div>
                        {this.props.isProducteur && (
                            <div className="col-md-6">
                                <CreatePersonnesFromEntite
                                    disabled={!this.props.entityId}
                                    createProps={this.props.createProps}
                                    onCreate={this.getPersonnesAssociees}
                                />
                            </div>
                        )}
                    </div>
                )}
                <Modal open={this.state.deleteTarget}>
                    <div className="modal-body">
                        {this.state.deleteTarget && (
                            <>
                                <p>Confirmez-vous la suppression de la relation Entité - Personne suivante ?</p>
                                <ul className="li">
                                    <li><b>{this.props.entite.siret} - {this.props.entite.nom_usuel}</b></li>
                                    <li><b>{this.state.deleteTarget.nom} {this.state.deleteTarget.prenom}</b></li>
                                    <li><b>{this.state.deleteTarget.lib_hab}</b></li>
                                    <li><b>{this.state.deleteTarget.lib_fonction}</b></li>
                                </ul>
                            </>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => this.setState({ deleteTarget: null })} className="btn btn-white">Annuler</button>
                        <button onClick={() => this.deletePerHabilitation()} className="btn btn-secondary" disabled={this.state.deleteTarget?.ent_count < 2}>Confirmer</button>
                    </div>
                </Modal>
                <Modal open={this.state.modifTarget} size="lg">
                    <div className="modal-header">
                        <h2 className="modal-title">Modifier l'habilitation et la fonction</h2>
                        <div className="modal-step__actions">
                            <button
                                type="button"
                                data-dismiss="modal"
                                className="btn btn-white"
                                onClick={() => this.setState({ modifTarget: null, newHabilitation: null, newFonction: null })}>Annuler</button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                id="btn_next"
                                onClick={() => this.updatePerHabilitation()}>Modifier</button>
                        </div>
                    </div>
                    <div className="modal-body m-b-50">
                        <div className="relation-card">
                            <div className="relation-card__content">
                                <div className="row">
                                    <div className="col-md-2">
                                        <h4 className="label">{this.state.modifTarget?.nom} {this.state.modifTarget?.prenom}</h4>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor={`habilitation${this.state.modifTarget?.per_id}`}>Habilitation*</label>
                                            <FormInputSelect
                                                light
                                                label={`habilitation`}
                                                displayLabel={false}
                                                options={this.state.refHabilitations[this.props.entite.id]?.map((e: any) => ({ label: e.lib_hab, value: e.code_hab })).sort((a: any, b: any) => (a.label > b.label) ? 1 : -1) || []}
                                                name={`habilitation--${this.state.modifTarget?.per_id}`}
                                                id={`habilitation--${this.state.modifTarget?.per_id}`}
                                                handle={(e: any) => this.setState({ newHabilitation: e.data.value })}
                                                value={this.state.newHabilitation || ''}
                                                isSubmitted={this.state.isSubmitted}
                                                simpleValidator={this.validator}
                                                required={true}
                                                isClearable={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor={`fonction${this.state.modifTarget?.per_id}`}>Fonction*</label>
                                            <FormInputSelect
                                                light
                                                label={`fonction`}
                                                displayLabel={false}
                                                options={this.state.fonctions.map((fonc: any) => ({ value: fonc.id, label: fonc.lib_fonction }))}
                                                name={`fonction--${this.state.modifTarget?.per_id}`}
                                                id={`fonction--${this.state.modifTarget?.per_id}`}
                                                handle={(e: any) => this.setState({ newFonction: e.data.value })}
                                                value={this.state.newFonction || ''}
                                                isSubmitted={this.state.isSubmitted}
                                                simpleValidator={this.validator}
                                                required={true}
                                                isClearable={true}
                                                maxMenuHeight={150}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </></Panel>
        )
    }
}

export default withTranslation()(withRouter(PersonnesAssocieesPanel));