import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import CustomComponent from '../../../components/CustomComponent';
import { DefaultLayout } from '../../../components/Layouts';
import BreadCrumb from '../../../components/BreadCrumb';
import Panel from '../../../components/Panel';
import { FormCheckbox, FormInput } from '../../../components/FormComponents';
import { validateIsNotEmpty } from '../../../helpers/validation';
import { withTranslation } from 'react-i18next';
import { AuditFields } from '../../../components/AuditFields';
import { dateFormat, getSession, hasPermission, truncateString } from '../../../helpers/helpers';
import SubscriptionPanel from "../../mes_informations/_subscriptions_panel";


class DetailsPersonne extends CustomComponent {
    private _isMounted = false;
    private _id = this.props.match.params.id

    constructor(props: any) {
        super(props);
        this.state = {
            personne: null,
            nom_personne: '',
            persHab: [],
            isLoading: true,
            isAbonnementPanelVisible: false,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = `Infolabo | Consultation d'une fiche personne`;
        this.getPersonne()
    }

    async getPersonne() {
        const data = await this.request(`/per_personne/${this._id}`, 'GET');
        const persHab: any[] = await this.request(`/per_habilitation/findByPerson/${this._id}`, 'GET');
        const auditFields = await this.request(`/per_personne/auditFields/${this._id}`, 'GET')

        if (data && data.personne) {
            this._isMounted && this.setState({
                personne: data.personne,
                isEditable: data.isEditable,
                isAbonnementPanelVisible: data.isAbonnementPanelVisible,
                auditFields: auditFields,
                nom_personne: `${data.personne?.prenom || ""} ${data.personne?.nom}`,
                persHab,
            })
        }

        if (hasPermission('DRT_ANNU_GROUPE')) {
            const persGroupes: any = await this.request(`/ent_groupe/findByPerson/${this._id}`, 'GET');
            if (persGroupes?.statusCode === 200 && this._isMounted) {
                this.setState({
                    persGroupes: persGroupes.groupes,
                })
            }
        }

        this._isMounted && this.setState({ isLoading: false })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { t } = this.props;
        const session = getSession();

        return (
            <DefaultLayout>
                <div className="container">
                    {this.state.personne && (<>
                        <BreadCrumb crumbs={[{ name: 'Annuaire', path: '/annuaire' }, { name: this.state.nom_personne, path: '#' }]} />
                        <div className="d-flex justify-content-between flex-wrap align-items-center">
                            <h1 className="main-title">{this.state.nom_personne}</h1>
                            <div className="m-b-30">
                                <Link to={`/annuaire/personne`} className="btn m-r-5">Annuler</Link>
                                {(hasPermission('DRT_ANNU_PER_GERER') && this.state.isEditable) && <Link to={`/annuaire/personne/${this._id}/supprimer`} className="btn btn-danger m-r-5">Supprimer</Link>}
                                {(hasPermission('DRT_ANNU_PER_GERER') && this.state.isEditable) && <Link to={`/annuaire/personne/${this._id}/modifier`} className="btn btn-dark">Modifier</Link>}
                            </div>
                        </div>
                        <Panel title="Accès">
                            <div className="form-group form-group--inline">
                                <FormInput
                                    label={t('personne.identifiant')}
                                    id="mail_identifiant"
                                    name="mail_identifiant"
                                    type="email"
                                    value={this.state.personne?.mail_identifiant || ''}
                                    disabled
                                    helper={t('personne.info')}
                                />
                            </div>
                            <>
                                {session?.hab_code === 'ADMININF' &&
                                    <div className="form-group form-group--inline form-group--checkbox">
                                        <FormCheckbox
                                            disabled
                                            label={t('personne.is_user_api')}
                                            id="fl_api"
                                            name="fl_api"
                                            type="checkbox"
                                            value={this.state.personne?.fl_api || false}
                                        />
                                    </div>
                                }
                            </>

                            <>{!!this.state.personne?.date_derniere_connexion && !this.state.personne?.fl_api && (
                                <div className="m-l-5 m-t-30 form-info-line">
                                    <i className="icon-info" />
                                    <span>{t('personne.last_login')} {dateFormat(this.state.personne?.date_derniere_connexion, 'DD/MM/Y')}</span>
                                </div>
                            )}</>
                        </Panel>
                        <Panel title="Informations générales" togglableID="panel-2" open={true}>
                            <div className="large-gutters">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t("personne.civility")}
                                                name="civility"
                                                id="civility"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                value={this.state.personne?.civilite || ''}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.lastname')}
                                                id="nom"
                                                name="nom"
                                                type="text"
                                                value={this.state.personne?.nom || ''}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.firstname')}
                                                id="prenom"
                                                name="prenom"
                                                type="text"
                                                value={this.state.personne?.prenom || ''}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.email1')}
                                                id="email_1"
                                                name="email_1"
                                                type="email"
                                                handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                value={this.state.personne?.email_1 || ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.email2')}
                                                id="email_2"
                                                name="email_2"
                                                type="email"
                                                value={this.state.personne?.email_2 || ''}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.phone')}
                                                id="num_tel"
                                                name="num_tel"
                                                type="tel"
                                                value={this.state.personne?.num_tel || ''}
                                                isPhone={true}
                                                phoneName="ind_tel"
                                                phoneValue={this.state.personne?.ind_tel || ''}
                                                phoneOptions={[this.state.personne?.ind_tel || ''].map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.mobile1')}
                                                id="num_portable_1"
                                                name="num_portable_1"
                                                type="tel"
                                                value={this.state.personne?.num_portable_1 || ''}
                                                isPhone={true}
                                                phoneName="ind_portable_1"
                                                phoneValue={this.state.personne?.ind_portable_1 || ''}
                                                phoneOptions={[this.state.personne?.ind_portable_1 || ''].map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.mobile2')}
                                                id="num_portable_2"
                                                name="num_portable_2"
                                                type="tel"
                                                value={this.state.personne?.num_portable_2 || ''}
                                                isPhone={true}
                                                phoneName="ind_portable_2"
                                                phoneValue={this.state.personne?.ind_portable_2 || ''}
                                                phoneOptions={[this.state.personne?.ind_portable_2 || ''].map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h3 className="form-title">Adresse</h3>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.address1')}
                                                id="adr_lig_1"
                                                name="adr_lig_1"
                                                type="text"
                                                validation={validateIsNotEmpty}
                                                value={this.state.personne?.adr_lig_1 || ''}
                                                isSubmitted={this.state.isSubmitted}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.address2')}
                                                id="adr_lig_2"
                                                name="adr_lig_2"
                                                type="text"
                                                value={this.state.personne?.adr_lig_2 || ''}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.address3')}
                                                id="adr_lig_3"
                                                name="adr_lig_3"
                                                type="text"
                                                value={this.state.personne?.adr_lig_3 || ''}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.address4')}
                                                id="adr_lig_4"
                                                name="adr_lig_4"
                                                type="text"
                                                value={this.state.personne?.adr_lig_4 || ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.postalcode')}
                                                id="code_postal"
                                                name="code_postal"
                                                type="text"
                                                value={this.state.personne?.code_postal || ''}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.city')}
                                                id="ville"
                                                name="ville"
                                                type="text"
                                                value={this.state.personne?.ville || ''}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                disabled
                                                label={t('personne.country')}
                                                id="pay_id"
                                                name="pay_id"
                                                type="text"
                                                value={this.state.personne?.pays || ''}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h3 className="form-title">Contact en cas d'absence</h3>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline">
                                            <FormInput
                                                disabled
                                                label={t('personne.contact')}
                                                id="contact_abs"
                                                name="contact_abs"
                                                type="text"
                                                value={this.state.personne?.contact_abs || ''}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel title="Entités et fonctions associées" togglableID="panel-3" open={true}>
                            <div className="row">
                                {this.state.persHab.map((hab: any, index: number) => (
                                    <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30" key={index}>
                                        <div className="info-card">
                                            <h4 className="info-card__title"><Link target="_blank" to={'/annuaire/entite/' + hab.ent_id?.id}>{hab.ent_id?.nom_usuel}</Link></h4>
                                            {hab.ent_id?.ent_pay_id?.code_pays}{hab.ent_id?.siren}{hab.ent_id?.nic}
                                            <dl className="info-card__info">
                                                <dt>Habilitation :</dt>
                                                <dd>{hab.hab_code?.lib_hab}</dd>
                                                <dt>Fonction :</dt>
                                                <dd>{hab.fon_id?.lib_fonction}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Panel>
                        {hasPermission('DRT_ANNU_GROUPE') && (
                            <Panel title="Groupes associés" togglableID="panel-4" open={true}>
                                <div className="row">
                                    {!this.state.persGroupes?.length && (
                                        <div className="d-flex w-100 justify-content-center">
                                            <span>Cette fiche personne n’est associée à aucun groupe de votre périmètre</span>
                                        </div>
                                    )}
                                    {this.state.persGroupes?.map((groupe: any) => (
                                        <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30" key={groupe.id}>
                                            <div className="info-card">
                                                <h4 className="info-card__title"><Link target="_blank" to={`/annuaire/groupe/${groupe.id}`}>{groupe.nom}</Link></h4>
                                                {`${groupe.proprietaire.siret} - ${groupe.proprietaire.nom_usuel}`}
                                                <dl className="info-card__info">
                                                    <dt>Type de groupe :</dt>
                                                    <dd>Personne</dd>
                                                    <dt>Description :</dt>
                                                    <dd>{truncateString(groupe.description, 40, '...')}</dd>
                                                </dl>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Panel>
                        )}
                        {(hasPermission('DRT_ANNU_PER_CONSULTER') && this.state.isAbonnementPanelVisible) && (
                            <SubscriptionPanel personneId={this._id} />
                        )}
                    </>)}
                </div>
                {this.state.auditFields && (<AuditFields audit={this.state.auditFields} />)}
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(DetailsPersonne));