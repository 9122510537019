import { saveAs } from 'file-saver';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import BreadCrumb from '../../../components/BreadCrumb';
import CustomComponent from '../../../components/CustomComponent';
import { FileUploadModal } from '../../../components/FileUploadModal';
import { FormInputSelect } from '../../../components/FormComponents';
import { DefaultLayout } from '../../../components/Layouts';
import Pagination from "../../../components/Pagination";
import TABLE from '../../../components/Table';
import { LimitValuesSelect } from '../../../components/TableComponents';
import { ETATS_FLUX_REF_LABEL, ETATS_FLUX_REF_OPTIONS } from "../../../enum/etats_flux_ref";
import { TABLE_DE_REF_ANNUAIRE_OPTIONS, TABLE_DE_REF_METIER_OPTIONS } from "../../../enum/table_de_ref";
import { DebounceQueue } from '../../../helpers/debouncer';
import { TOAST_TYPE, dateFormat, sendToast } from '../../../helpers/helpers';
import { validateFile } from '../../../helpers/validation';

class TablesDeReference extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private default_filters = {
        orderBy: 'dt_deb',
        ordering: 'DESC',
        nom_fichier: '',
        table_ref: '',
        etat: '',
        dt_deb: '',
        dt_fin: '',
    }

    private options = {
        ref_annuaire: TABLE_DE_REF_ANNUAIRE_OPTIONS,
        ref_metier: TABLE_DE_REF_METIER_OPTIONS,
        etat: ETATS_FLUX_REF_OPTIONS,
    }

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            filters: this.default_filters,
            options: this.options,
            suivisRef: [],
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            modalOpen: false,
            values: {
                ref_annuaire: '',
                ref_metier: ''
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Tables de références';

        this.getSuivisRef();
    }

    getSuivisRef = async (triggeredByPagination: boolean = false) => {
        this.setState({isLoading: true});

        const dateKeys = ['dt_deb', 'dt_fin'];

        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        });

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (dateKeys.includes(key) && typeof val !== 'string') {
                !!val && params.append(key, val.toISOString());
            }
            else if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/inf-suivi-flux-ref?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                suivisRef: data.suivisRef,
                paginate: {...prev.paginate, ...data.paginate},
            }));
        }

    }

    exportToCSV = async (type: string, reference: any) => {
        const file_name = `INF00000000000000_PARA_S_${type}_${dateFormat(undefined, 'YYYYMMDD')}_01.csv`

        const blob = await this.request('/csv/export_reference', 'POST', { reference }, false, false, true)
        if (blob) {
            saveAs(blob, file_name)
        }
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getSuivisRef, [], true));
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getSuivisRef, [true], true))
    }


    importCSV = async (file: File) => {
        const formData = new FormData()
        formData.append('file', file)

        const data = await this.request('/csv/import_reference', 'POST', formData, true)

        if (data && data.statusCode === 201) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
        }

        this.setState({ modalOpen: false })
    }


    render() {
        return (
            <DefaultLayout>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }, { name: 'Tables de références', path: '/tables_de_references' }]} />
                    <div className="d-flex justify-content-between align-items-start">
                        <h1 className="main-title">Tables de références</h1>
                        <button onClick={() => this.setState({modalOpen: true})} type="button" className="btn btn-dark">Importer</button>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="card m-b-10">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="ref_annuaire">Références annuaire</label>
                                        <div className="row">
                                            <div className="col-lg-7 col-xl-9 m-b-30 m-b-xl-0">
                                                <FormInputSelect
                                                    id="ref_annuaire"
                                                    name="ref_annuaire"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'values')}
                                                    value={this.state.values.ref_annuaire}
                                                    options={this.state.options.ref_annuaire}/>
                                            </div>
                                            <div className="col-lg-5 col-xl-3">
                                                <div className="btn-container btn-container--center">
                                                    <button
                                                        disabled={!this.state.values.ref_annuaire}
                                                        onClick={() => this.exportToCSV('ANNUAIRE', this.state.values.ref_annuaire)}
                                                        type="button"
                                                        className="btn btn-dark">Exporter</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card m-b-10">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="ref_annuaire">Références métier</label>
                                        <div className="row">
                                            <div className="col-lg-7 col-xl-8 m-b-30 m-b-xl-0">
                                                <FormInputSelect
                                                    id="ref_metier"
                                                    name="ref_metier"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'values')}
                                                    value={this.state.values.ref_metier}
                                                    options={this.state.options.ref_metier}/>
                                            </div>
                                            <div className="col-lg-5 col-xl-4">
                                                <div className="btn-container btn-container--center">
                                                    <button
                                                        disabled={!this.state.values.ref_metier}
                                                        onClick={() => this.exportToCSV('METIER', this.state.values.ref_metier)}
                                                        type="button"
                                                        className="btn btn-dark">Exporter</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LimitValuesSelect
                        default_value={this.state.paginate.limit}
                        steps={[10, 20, 50]}
                        onChange={(e: any) => this.setState((prev: any) => ({ paginate: {...prev.paginate, limit: +e.target.value, page: 0 }}), this.getSuivisRef)}
                    />

                    <TABLE fixed responsive="accordion">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH name="nom_fichier" fixed={{left: 0}}
                                    input={{placeholder: 'Nom du fichier', value: this.state.filters.nom_fichier, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisRef))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nom_fichier')}}
                                />
                                <TABLE.TH name="table_ref"
                                    input={{placeholder: 'Type', value: this.state.filters.table_ref, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisRef))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('table_ref')}}
                                />
                                <TABLE.TH name="etat"
                                    select={{placeholder: 'Statut', value: this.state.filters.etat, options: this.state.options.etat, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisRef))}}
                                />
                                <TABLE.TH name="dt_deb"
                                    datePicker={{value: this.state.filters.dt_deb, placeholder: 'Début', onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisRef))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('dt_deb')}}
                                />
                                <TABLE.TH name="dt_fin"
                                    datePicker={{value: this.state.filters.dt_fin, placeholder: 'Fin', onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisRef))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('dt_fin')}}
                                />
                                <TABLE.TH name="nb_lignes" label="Nb lignes"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_lignes')}}
                                />
                                <TABLE.TH name="nb_erreurs" label="Nb erreurs"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_erreurs')}}
                                />
                                <TABLE.TH name="nb_desactivees" label="Nb de réf désactivées"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_desactivees')}}
                                />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.suivisRef?.map((suivi_ref: any) => (
                                <TABLE.TR title={suivi_ref.nom_fichier} key={suivi_ref.id}>
                                    <TABLE.TD fixed={{left: 0}} label="Nom du fichier"><strong>{suivi_ref.nom_fichier}</strong></TABLE.TD>
                                    <TABLE.TD label="Type">{suivi_ref.table_de_ref || ''}</TABLE.TD>
                                    <TABLE.TD label="Statut">{ETATS_FLUX_REF_LABEL[suivi_ref.etat] || ''}</TABLE.TD>
                                    <TABLE.TD label="Début">{suivi_ref.dt_deb ? dateFormat(suivi_ref.dt_deb, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Fin">{suivi_ref.dt_fin ? dateFormat(suivi_ref.dt_fin, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Nb lignes">{(+suivi_ref.nb_total_lignes_traitees || 0) + (+suivi_ref.nb_total_lignes_rejetees || 0)}</TABLE.TD>
                                    <TABLE.TD label="Nb erreurs">{suivi_ref.nb_total_erreurs || 0}</TABLE.TD>
                                    <TABLE.TD label="Nb de réf désactivées">{suivi_ref.nb_ref_desactivees || 0}</TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>

                    {this.state.suivisRef?.length === 0 && (
                        <TABLE.NO_RESULT/>
                    )}

                    <Pagination
                        handleClick={this.paginationOnClick}
                        pageCurrent={+this.state.paginate.page + 1}
                        pageTotal={this.state.paginate.pageTotal}
                        pageDisplay={3}
                    />
                </div>

                <FileUploadModal
                    title="Importer un fichier"
                    open={this.state.modalOpen}
                    handle={this.importCSV}
                    types={['CSV']}
                    maxSize={10}
                    validation={(e: any) => validateFile(e, ['csv'], 10)}
                    onClose={() => this.setState({ modalOpen: false })} />
            </DefaultLayout>
        )
    }
}


export default withTranslation()(withRouter(TablesDeReference));
