import saveAs from "file-saver";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { FilterDropdown } from "../../../components/FilterDropdown";
import { FormDatePicker } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import Pagination from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { ETATS_FLUX_ANNUAIRE_LABEL, ETATS_FLUX_ANNUAIRE_OPTIONS } from "../../../enum/etats_flux_annuaire";
import { TYPES_FLUX_ANNUAIRE_LABEL, TYPES_FLUX_ANNUAIRE_OPTIONS } from "../../../enum/types_flux_annuaire";
import { DebounceQueue } from "../../../helpers/debouncer";
import { TOAST_TYPE, dateFormat, datePickerFormat, hasPermission, sendToast } from "../../../helpers/helpers";
import { exportCSVFlux } from "../../../requests/exportCSVFlux";
import { ImportExportAnnuaireHeader } from "./common/ImportExportAnnuaireHeader";

class ImportsFluxAnnuaire extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: 'dt_deb',
        ordering: 'DESC',
        start_date_range_from: moment().utc().subtract(1, 'month').startOf('day').toDate(),
        start_date_range_to: moment().utc().startOf('day').toDate(),
        nom_fichier: '',
        type_flux: '',
        etat: '',
        dt_deb: '',
        dt_fin: '',
        emetteur: '',
    }

    private readonly dateKeys = [
        'dt_deb',
        'dt_fin',
        'start_date_range_from',
        'start_date_range_to',
    ];

    private options = {
        type_flux: TYPES_FLUX_ANNUAIRE_OPTIONS,
        etat: ETATS_FLUX_ANNUAIRE_OPTIONS,
    }

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            filters: this.defaultFilters,
            options: this.options,
            importsSuivisAnnu: [],
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            }
        }
    }

    componentDidMount() {
        document.title = 'Infolabo | Imports et exports annuaire';
        this._isMounted = true;

        this.getImportsSuivisAnnu();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getImportsSuivisAnnu = async (triggeredByPagination: boolean = false) => {
        this.setState({isLoading: true});

        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (this.dateKeys.includes(key) && typeof val !== 'string') {
                !!val && params.append(key, val.toISOString());
            }
            else if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/inf-suivi-flux-annu?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                importsSuivisAnnu: data.importsSuivisAnnu,
                paginate: {...prev.paginate, ...data.paginate},
            }))
        }
    }

    handlePerPageChange = (newLimit: number) => {
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                limit: newLimit,
                page: 0,
            }
        }), () => this.queue.push(this.getImportsSuivisAnnu, [], true));
    }

    handleSort = (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getImportsSuivisAnnu, [], true))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getImportsSuivisAnnu, [true], true))
    }

    fileDownload = async (id: number, filePath: string|null, fileName: string|null) => {
        if (!filePath || !fileName) {
            sendToast(<p>Impossible de télécharger le fichier</p>, TOAST_TYPE.ERROR);
            return;
        }

        const blob: Blob = await this.request(`/inf-suivi-flux-annu/download/${id}`, 'GET', undefined, false, false, true);

        if (!!blob && blob instanceof Blob) {
            saveAs(blob, fileName);
        }
    }

    handleExport = async () => {
        this.setState({isLoading: true});
        const allFilters = {...this.state.paginate, ...this.state.filters};
        await exportCSVFlux('IMPORTS_ANNUAIRE', allFilters, this.dateKeys);
        this.setState({isLoading: false});
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <ImportExportAnnuaireHeader
                        paginate={this.state.paginate}
                        onPerPageChange={this.handlePerPageChange}
                        handleRefresh={() => this.queue.push(this.getImportsSuivisAnnu, [], true)}
                    />

                    <FilterDropdown
                        parent={this}
                        filters={this.state.filters}
                        onSearchLoad={(filters: any) => this.setState((prev: any) => ({filters: {...prev.filters, ...filters}}), this.getImportsSuivisAnnu)}
                        onFiltersReset={() => this.setState({filters: this.defaultFilters}, () => this.queue.push(this.getImportsSuivisAnnu, [], true))}
                        onFiltersSearch={() => this.queue.push(this.getImportsSuivisAnnu, [], true)}
                        onApplyFilters={() => this.queue.push(this.getImportsSuivisAnnu, [], true)}
                        onExport={hasPermission('DRT_ANNU_EXPORTER') ? {csv: this.handleExport} : undefined}
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group-flex">
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="start_date_range_from"
                                            id="start_date_range_from"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.start_date_range_from) || ''}
                                            label="Date de début du"
                                            required
                                        />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="start_date_range_to"
                                            id="start_date_range_to"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.start_date_range_to) || ''}
                                            label="au"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FilterDropdown>

                    <TABLE fixed responsive="accordion">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH fixed={{left: 0, width: 58}}/>
                                <TABLE.TH name="nom_fichier" fixed={{left: 58}}
                                    input={{placeholder: 'Nom du fichier', value: this.state.filters.nom_fichier, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getImportsSuivisAnnu))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nom_fichier')}}
                                />
                                <TABLE.TH name="type_flux"
                                    select={{placeholder: 'Type', value: this.state.filters.type_flux, options: this.state.options.type_flux, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getImportsSuivisAnnu))}}
                                />
                                <TABLE.TH name="etat"
                                    select={{placeholder: 'Statut', value: this.state.filters.etat, options: this.state.options.etat, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getImportsSuivisAnnu))}}
                                />
                                <TABLE.TH name="dt_deb"
                                    datePicker={{value: this.state.filters.dt_deb, placeholder: 'Début', onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getImportsSuivisAnnu))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('dt_deb')}}
                                />
                                <TABLE.TH name="dt_fin"
                                    datePicker={{value: this.state.filters.dt_fin, placeholder: 'Fin', onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getImportsSuivisAnnu))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('dt_fin')}}
                                />
                                <TABLE.TH name="nb_lignes" label="Nb lignes"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_lignes')}}
                                />
                                <TABLE.TH name="nb_erreurs" label="Nb erreurs"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_erreurs')}}
                                />
                                <TABLE.TH name="nb_ent_crees" label="Nb entité(s) créée(s)"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_ent_crees')}}
                                />
                                <TABLE.TH name="nb_ent_maj" label="Nb entité(s) màj"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_ent_maj')}}
                                />
                                <TABLE.TH name="nb_per_crees" label="Nb personne(s) créée(s)"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_per_crees')}}
                                />
                                <TABLE.TH name="nb_per_maj" label="Nb personne(s) màj"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_per_maj')}}
                                />
                                <TABLE.TH name="nb_doublons" label="Nb doublon(s)"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_doublons')}}
                                />
                                <TABLE.TH name="nb_grp_crees" label="Nb groupe(s) créé(s)"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_grp_crees')}}
                                />
                                <TABLE.TH name="nb_grp_maj" label="Nb groupe(s) màj"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_grp_maj')}}
                                />
                                <TABLE.TH name="emetteur"
                                    input={{placeholder: 'Emetteur', value: this.state.filters.emetteur, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getImportsSuivisAnnu))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('emetteur')}}
                                />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.importsSuivisAnnu?.map((suivi_annu: any) => (
                                <TABLE.TR title={suivi_annu.nom_fichier} key={suivi_annu.id}>
                                    <TABLE.TD fixed={{left: 0, width: 58}} hiddenMobile>
                                        {(suivi_annu.chemin_fichier && suivi_annu.fichier_zip) && (
                                            <button title="Télécharger fichier ZIP" type="button" className="action-btn" onClick={() => this.fileDownload(suivi_annu.id, suivi_annu.chemin_fichier, suivi_annu.fichier_zip)}>
                                                <i className="icon-file"></i>
                                                <span className="sr-only">Télécharger fichier ZIP</span>
                                            </button>
                                        )}
                                    </TABLE.TD>
                                    <TABLE.TD fixed={{left: 58}} label="Nom du fichier"><strong>{suivi_annu.nom_fichier}</strong></TABLE.TD>
                                    <TABLE.TD label="Type">{TYPES_FLUX_ANNUAIRE_LABEL[suivi_annu.type_flux] || ''}</TABLE.TD>
                                    <TABLE.TD label="Statut">{ETATS_FLUX_ANNUAIRE_LABEL[suivi_annu.etat] || ''}</TABLE.TD>
                                    <TABLE.TD label="Début">{suivi_annu.dt_deb ? dateFormat(suivi_annu.dt_deb, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Fin">{suivi_annu.dt_fin ? dateFormat(suivi_annu.dt_fin, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Nb lignes">{(+suivi_annu.nb_total_lignes_traitees || 0) + (+suivi_annu.nb_total_lignes_rejetees || 0)}</TABLE.TD>
                                    <TABLE.TD label="Nb erreurs">{suivi_annu.nb_total_erreurs || 0}</TABLE.TD>
                                    <TABLE.TD label="Nb entité(s) créée(s)">{suivi_annu.type_flux === 'ANNU' ? suivi_annu.nb_ent_crees || 0 : 0}</TABLE.TD>
                                    <TABLE.TD label="Nb entité(s) màj">{suivi_annu.type_flux === 'ANNU' ? suivi_annu.nb_ent_maj || 0 : 0}</TABLE.TD>
                                    <TABLE.TD label="Nb personne(s) créée(s)">{suivi_annu.type_flux === 'ANNU' ? suivi_annu.nb_per_crees || 0 : 0}</TABLE.TD>
                                    <TABLE.TD label="Nb personne(s) màj">{suivi_annu.type_flux === 'ANNU' ? suivi_annu.nb_per_maj || 0 : 0}</TABLE.TD>
                                    <TABLE.TD label="Nb doublon(s)">{(+suivi_annu.nb_ent_doublons || 0) + (+suivi_annu.nb_per_doublons || 0)}</TABLE.TD>
                                    <TABLE.TD label="Nb groupe(s) créé(s)">{suivi_annu.type_flux === 'GROU' ? ((+suivi_annu.nb_ent_crees || 0) + (+suivi_annu.nb_per_crees || 0)) : 0}</TABLE.TD>
                                    <TABLE.TD label="Nb groupe(s) màj">{suivi_annu.type_flux === 'GROU' ? ((+suivi_annu.nb_ent_maj || 0) + (+suivi_annu.nb_per_maj || 0)) : 0}</TABLE.TD>
                                    <TABLE.TD label="Emetteur">{suivi_annu.emetteur?.nom_usuel || ''}</TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>

                    {this.state.importsSuivisAnnu?.length === 0 && (
                        <TABLE.NO_RESULT/>
                    )}

                    <Pagination
                        handleClick={this.paginationOnClick}
                        pageCurrent={+this.state.paginate.page + 1}
                        pageTotal={this.state.paginate.pageTotal}
                        pageDisplay={3}
                    />
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ImportsFluxAnnuaire));