import CustomComponent from "../../../components/CustomComponent";
import CreateGroupe from "./create";

class UpdateGroupe extends CustomComponent {
    render() {
        const id = this.props.match.params.id;
        return(<CreateGroupe groupeId={id} isUpdatePage={true} title="Modification d'un groupe" />);
    }
}

export default UpdateGroupe;