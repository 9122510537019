import moment from "moment"
import 'moment/locale/fr'
import { capitalize, numberFormat } from "../../../../helpers/helpers"
import { API_DEBUG } from "../../../../config"

const TICKVALUES_Y_STEPS = 10
const TICKVALUES_Y_GRANULARITY = 1 / TICKVALUES_Y_STEPS

export type IData = {
    is_valid: boolean
    label: string,
    legend: { name: string },
    seuil: { libelle: string, valeur: number },
    data: { x: any, y: number, label: string }[]
}[]

export interface IDomain {
    x?: [any, any],
    y?: [number, number]
}

interface GetChartsDomainResponse {
    errorMessages: string[]
    data: any
    seuils: any[]
    domain: IDomain
    zoomDomain: IDomain
    tickValuesX: any[]
    scrollable: boolean
    tickCount: number
    minMax: number[][]
}

const DEFAULT_RESPONSE = {
    errorMessages: [],
    data: [],
    seuils: [],
    domain: {},
    zoomDomain: {},
    tickValuesX: [],
    scrollable: false,
    tickCount: 0,
    minMax: []
}

export const getChartsData = (data: IData, width: number, mensuel?: boolean): GetChartsDomainResponse => {
    const DISPLAY_MAX = getDisplayMax(width, mensuel);
    const tickCount = DISPLAY_MAX;

    if (!data) return DEFAULT_RESPONSE;

    const { filteredData, errorMessages } = filterDataAndGenerateErrorMessages(data, mensuel);

    const xValues = getXValues(filteredData);

    const minX = Math.min(...xValues);
    const maxX = Math.max(...xValues);

    API_DEBUG && console.log({ xValues, minX, maxX });

    const { timestamps, rangeInMonths } = getTimeStampsAndRangeInMonth(minX, maxX, mensuel);

    const seuils: any[] = [];
    for (let index in filteredData) {
        const seuil = filteredData[index].seuil;

        if (seuil) {
            seuils.push(timestamps.map(timestamp => ({
                x: timestamp,
                y: seuil.valeur,
                label: seuil.libelle
            })));
        } else {
            seuils.push(null);
        }
    }

    const minMax = getMinMax(filteredData, seuils);
    const tickValuesX = getTickValuesX(timestamps, rangeInMonths, mensuel);
    const scrollable = tickValuesX.length > DISPLAY_MAX;
    const maxXZoom = scrollable ? tickValuesX[DISPLAY_MAX - 1] : maxX;

    API_DEBUG && console.log({ minMax, timestamps, rangeInMonths, mensuel, tickValuesX, scrollable, maxXZoom });

    return {
        errorMessages,
        data: filteredData,
        seuils,
        domain: { x: [minX, maxX], y: [0, 1] },
        zoomDomain: { x: [minX, maxXZoom], y: [0, 1] },
        tickValuesX,
        scrollable,
        tickCount,
        minMax
    };
}

const filterDataAndGenerateErrorMessages = (data: IData, mensuel?: boolean) => {
    const errorMessages: string[] = []
    const filteredData = data.filter(entry => {
        const is_valid = entry.is_valid;

        if (!is_valid) {
            if (mensuel) {
                errorMessages.push(`La courbe pour le critère ${entry.label} ne peut pas être affiché à cause de valeurs multiples pour un même mois de facturation`);
            } else {
                errorMessages.push(`La courbe pour le critère ${entry.label} ne peut pas être affiché à cause de valeurs multiples pour un même échantillon`);
            }
        }

        return is_valid;
    })

    return { filteredData, errorMessages };
}

const getXValues = (data: IData) => {
    let all_data: any[] = []
    if (data.length > 0 && data[0].data) all_data = [...all_data, ...data[0].data]
    if (data.length > 1 && data[1].data) all_data = [...all_data, ...data[1].data]

    return Array.from(new Set(all_data.map(data => data.x))).sort((a, b) => a - b)
}

const getMinMax = (data: IData, seuils: any) => {
    const minMax = data.map((entry, index) => {
        const seuilValues = (seuils[index] || []).map((seuil: any) => seuil.y)

        const yValues = entry.data.map(d => d.y)
        const min = Math.max(
            // On ne veut pas de valeurs négatives
            0,
            // On veut que le min soit au moins 0.2 en dessous du min des valeurs
            Math.min(...[...yValues, ...seuilValues]) - 0.2
        )
        // On veut que le max soit au moins 0.2 au dessus du max des valeurs
        const max = Math.max(...[...yValues, ...seuilValues]) + 0.2

        const offset = TICKVALUES_Y_GRANULARITY * (max - min)

        return [min - offset, max + offset]
    })

    return minMax
}

const getDisplayMax = (width: number, mensuel?: boolean) => {
    let DISPLAY_MAX = 10;

    if (mensuel) {
        if (width > 767) DISPLAY_MAX = 13;
    } else {
        if (width > 767) DISPLAY_MAX = 15;
        if (width > 991) DISPLAY_MAX = 15;
        if (width > 1200) DISPLAY_MAX = 31;
    }

    return DISPLAY_MAX;
}

const getTimeStampsAndRangeInMonth = (minX: number, maxX: number, mensuel?: boolean) => {
    const timestamps: number[] = [];

    if (mensuel) {
        for (let d = moment.unix(minX).toDate(); d <= moment.unix(maxX).toDate(); d.setMonth(d.getMonth() + 1)) {
            timestamps.push(moment.utc(d).unix());
        }
    } else {
        for (let d = moment.unix(minX).toDate(); d <= moment.unix(maxX).toDate(); d.setDate(d.getDate() + 1)) {
            timestamps.push(moment.utc(d).unix());
        }
    }
    const rangeInMonths = Math.abs(moment.unix(minX).diff(moment.unix(maxX), 'months', true));

    return { timestamps, rangeInMonths };
}

const getTickValuesX = (timeStamps: number[], rangeInMonths: number, mensuel?: boolean) => {
    if (mensuel) return timeStamps;

    return timeStamps.filter((timestamp) => {
        const day = moment.unix(timestamp).date();

        if (rangeInMonths > 3) return [1, 15].includes(day);
        if (rangeInMonths > 1) return [1, 5, 10, 15, 20, 25].includes(day);
        return true;
    });
}

export const formatDateTick = (value: any, mensuel?: boolean) => {
    const FORMAT = mensuel ? 'MMM YY' : 'DD/MM/YYYY';
    return capitalize(moment.unix(value).format(FORMAT));
}

export const getTickValuesY = () => {
    return Array(TICKVALUES_Y_STEPS + 3).fill('').map((_, i) => (i - 1) / TICKVALUES_Y_STEPS);
}

export const formatTickValuesY = (value: any, minMax: [number, number]) => {
    return numberFormat(minMax[0] + value * (minMax[1] - minMax[0]), 1);
}

export const normalizeTickValuesY = (datum: any, minMaxArray: [number, number][], index: number) => {
    // API_DEBUG && console.log({ datum, minMaxArray, index });
    const minMax = minMaxArray[index];
    return (datum.y - minMax[0]) / (minMax[1] - minMax[0]);
}