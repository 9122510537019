import moment from "moment";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { datePickerFormat, Request, sendToast, TOAST_TYPE } from "../helpers/helpers";
import { FormDatePicker } from './FormComponents';
import Modal from "./Modal";

export const DebugDropdown = () => {
    const [debugPath, setDebugPath] = useState(null);
    const [debugTitle, setDebugTitle] = useState("");

    const handleDebugButton = (e: any) => {
        e.preventDefault();
        Request(`/debug/${e.target.value}`, 'POST')
            .then((data) => {
                data?.statusCode === 200 && sendToast(data.message, TOAST_TYPE.SUCCESS);
            })
    }

    const openDebugModal = (e: any) => {
        e.preventDefault();
        setDebugPath(e.target.value);
        setDebugTitle(e.target.innerText);
    }

    return (<>
        <Dropdown>
            <Dropdown.Toggle className="btn btn-dark">Debug</Dropdown.Toggle>
            <Dropdown.Menu>
                {/* <button type="button" onClick={handleDebugButton} className="dropdown-item" value="export_infocentre">Export Infocentre</button> */}
                <button type="button" onClick={handleDebugButton} className="dropdown-item" value="sigal_export">Export SIGAL</button>
                <button type="button" onClick={handleDebugButton} className="dropdown-item" value="sigal_acquittement">Acquittement SIGAL</button>
                <button type="button" onClick={openDebugModal} className="dropdown-item" value="prepa_stnbrmy">Prépa StNbrMoy</button>
                <button type="button" onClick={openDebugModal} className="dropdown-item" value="prepa_stantobiolt">Prépa StAntibioLt</button>
                <button type="button" onClick={openDebugModal} className="dropdown-item" value="prepa_sttrcheslt">Prépa StTrchesLt</button>
            </Dropdown.Menu>
        </Dropdown>
        {debugPath && <DebugModal path={debugPath} title={debugTitle} onClose={() => setDebugPath(null)} />}
    </>)
}

interface debugModalProps {
    path: string,
    title: string,
    onClose: any
}
const DebugModal = ({path, title, onClose}: debugModalProps) => {
    const [value, setValue] = useState(moment.utc().toDate());

    const onSubmit = (e: any) => {
        e.preventDefault();

        const month = moment(value).format('YYYYMM');
        Request(`/debug/${path}/${month}`, 'POST').then(data => {
            data?.statusCode === 200 && sendToast(data.message, TOAST_TYPE.SUCCESS);
            onClose();
        })
    }

    return (
        <Modal open={true} customLayout>
            <form onSubmit={onSubmit}>
                <Modal.Header><h1 className="modal-title">{title}</h1></Modal.Header>
                <Modal.Body>
                    <div className="input-group">
                        <FormDatePicker value={datePickerFormat(value)} month handle={(e: any) => setValue(e.data.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-white" onClick={onClose}>Fermer</button>
                    <button type="button" className="btn btn-success" onClick={onSubmit}>Valider</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}