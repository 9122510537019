import React from "react";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import BreadCrumb from '../../../components/BreadCrumb';
import CustomComponent from '../../../components/CustomComponent';
import CustomToggle from "../../../components/CustomToggle";
import { DefaultLayout } from '../../../components/Layouts';
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { LimitValuesSelect, TableNested } from "../../../components/TableComponents";
import { DebounceQueue } from "../../../helpers/debouncer";
import { TOAST_TYPE, dateFormat, hasPermission, sendToast } from '../../../helpers/helpers';

class Abonnements extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue()

    private defaultFilters = {
        orderBy: 'libelle',
        ordering: 'ASC',
        libelle: '',
        media: '',
        groupe_nom: '',
        date_debut: null,
        date_fin: null,
        proprietaire: '',
    }

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            filters: this.defaultFilters,
            abonnements: [],
            deleteTarget: null,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Abonnements';

        this.getAbonnements();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getAbonnements = async (triggeredByPagination: boolean = false) => {
        this.setState({isLoading: true});

        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        });

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (key === 'date_debut' || key === 'date_fin') {
                !!val && params.append(key, val.toISOString());
            }
            else if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/exp_abonnement?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200) {
            this.setState((prev: any) => ({
                abonnements: data.abonnements,
                paginate: {...prev.paginate, ...data.paginate}
            }));
        }
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getAbonnements, [true], true));
    }

    handleSort = (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC',
            }
        }), () => this.queue.push(this.getAbonnements, [], true));
    }

    toggleChildren = (id: number) => {
        this.setState((prev: any) => ({
            ...prev,
            abonnements: prev.abonnements.map((abonnement: any) => {
                if (abonnement.id === id) return {...abonnement, showChildren: !abonnement.showChildren};
                return abonnement;
            })
        }));
    }

    deleteAbonnement = async () => {
        const data = await this.request(`/exp_abonnement/${this.state.deleteTarget.id}`, 'DELETE');
        if (data?.statusCode === 200) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            this.queue.push(this.getAbonnements, [], true)
            this.setState({deleteTarget: null});
        }
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }, { name: 'Abonnements'}]} />
                    <div className="d-flex justify-content-between align-items-start flex-wrap m-b-15">
                        <h1 className="main-title m-b-15">Abonnement</h1>
                        <div className="d-flex flex-wrap m-b-5">
                            <Link to="/flux/abonnements/creer" className="btn btn-dark m-r-10 m-b-10"><i className="icon-add"/>Créer un abonnement</Link>
                            <Link to="/flux/suivi_des_envois" className="btn btn-dark m-b-10"><i className="icon-list"/>Consulter le suivi des envois</Link>
                        </div>
                    </div>

                    <LimitValuesSelect
                        default_value={this.state.paginate.limit}
                        steps={[10,20,50]}
                        onChange={(e: any) => this.setState((prev: any) => ({ paginate: {...prev.paginate, limit: +e.target.value, page: 0 }}), () => this.queue.push(this.getAbonnements, [], true))}
                    />

                    <TABLE responsive="card">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH/>
                                <TABLE.TH
                                    name="libelle"
                                    input={{
                                        value: this.state.filters.libelle,
                                        placeholder: `Libellé de l'abonnement`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getAbonnements))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('libelle')
                                    }}
                                />
                                <TABLE.TH
                                    name="media"
                                    input={{
                                        value: this.state.filters.media,
                                        placeholder: `Média`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getAbonnements))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('media')
                                    }}
                                />
                                <TABLE.TH
                                    name="groupe_nom"
                                    input={{
                                        value: this.state.filters.groupe_nom,
                                        placeholder: `Groupe destinataire`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getAbonnements))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('groupe_nom')
                                    }}
                                />
                                <TABLE.TH
                                    name="date_debut"
                                    datePicker={{
                                        value: this.state.filters.date_debut,
                                        placeholder: `Début`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getAbonnements))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('date_debut')
                                    }}
                                />
                                <TABLE.TH
                                    name="date_fin"
                                    datePicker={{
                                        value: this.state.filters.date_fin,
                                        placeholder: `Fin`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getAbonnements))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('date_fin')
                                    }}
                                />
                                <TABLE.TH
                                    name="proprietaire"
                                    input={{
                                        value: this.state.filters.proprietaire,
                                        placeholder: `Propriétaire`,
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getAbonnements))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('proprietaire')
                                    }}
                                />
                                <TABLE.TH actions label="Action"/>
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.abonnements.map((abonnement: any) => (
                                <React.Fragment key={abonnement.id}>
                                    <TABLE.TR paddedLeft>
                                        <TABLE.TD icon>
                                            {!!abonnement?.rapports?.length && (
                                                <button
                                                    onClick={() => this.toggleChildren(abonnement.id)}
                                                    aria-expanded={!!abonnement?.showChildren}
                                                    className="action-btn action-btn--collapse"
                                                />
                                            )}
                                        </TABLE.TD>
                                        <TABLE.TD label="Libellé de l'abonnement">{abonnement.libelle}</TABLE.TD>
                                        <TABLE.TD label="Média">{abonnement.media}</TABLE.TD>
                                        <TABLE.TD label="Groupe destinataire">
                                            {hasPermission('DRT_ANNU_GROUPE') ? (
                                                <Link to={`/annuaire/groupe/${abonnement.groupe?.id}`} target="_blank">{abonnement.groupe?.nom}</Link>
                                            ) : abonnement.groupe?.nom}
                                        </TABLE.TD>
                                        <TABLE.TD label="Début">{abonnement.date_debut &&  dateFormat(abonnement.date_debut,'DD/MM/Y HH:mm:ss')}</TABLE.TD>
                                        <TABLE.TD label="Fin">{abonnement.date_fin ? dateFormat(abonnement.date_fin,'DD/MM/Y HH:mm:ss') : '-'}</TABLE.TD>
                                        <TABLE.TD label="Propriétaire">{abonnement.proprietaire?.siret} - {abonnement.proprietaire?.nom_usuel}</TABLE.TD>
                                        <TABLE.TD actions>
                                            <div className="d-flex desktop-only">
                                                <Link to={`/flux/abonnements/${abonnement.id}`} target="_blank" className="action-btn"><i className="icon-eye"/></Link>
                                                <Link to={`/flux/abonnements/${abonnement.id}/modifier`} target="_blank" className="action-btn"><i className="icon-pen"/></Link>
                                                <button type="button" className="action-btn" onClick={() => this.setState({deleteTarget: abonnement})}><i className="icon-trash"/></button>
                                            </div>
                                            <Dropdown className="mobile-only">
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    <span className="sr-only">Ouvrir les actions</span>
                                                    <i className="icon-actions"/>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Link to={`/flux/abonnements/${abonnement.id}`} target="_blank" className="dropdown-item">Consulter</Link>
                                                    <Link to={`/flux/abonnements/${abonnement.id}/modifier`} target="_blank" className="dropdown-item">Modifier</Link>
                                                    <button type="button" className="dropdown-item" onClick={() => this.setState({deleteTarget: abonnement})}>Supprimer</button>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </TABLE.TD>
                                    </TABLE.TR>
                                    {!!abonnement?.rapports?.length && (
                                        <TableNested
                                            colspan={8}
                                            limit={20}
                                            data={abonnement.rapports.map((rapport: any) => ({
                                                id: rapport.id,
                                                name: rapport.nom,
                                                description: rapport.description,
                                                declenchement: rapport.type_declenchement,
                                            }))}
                                            total={abonnement.nombreRapports}
                                            seeAllLink={`/flux/abonnements/${abonnement.id}`}
                                            table="rapport"
                                            show={abonnement?.showChildren}
                                            noActions
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>
                    {!this.state.abonnements.length && <TABLE.EMPTY_MESSAGE message="Aucun abonnement à afficher"/>}
                    <Pagination
                      handleClick={this.paginationOnClick}
                      pageCurrent={(this.state.paginate.page + 1)}
                      pageTotal={this.state.paginate.pageTotal}
                      pageDisplay={3}
                    />

                </div>
                <Modal open={this.state.deleteTarget}>
                    <div className="modal-body">
                        {this.state.deleteTarget && (
                          <p>Etes-vous sûr(e) de vouloir supprimer l'abonnement <b>{this.state.deleteTarget.libelle}</b> pour le groupe <b>{this.state.deleteTarget.groupe.nom}</b> ?</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => this.setState({ deleteTarget: null })} className="btn btn-white">Annuler</button>
                        <button onClick={() => this.deleteAbonnement()} className="btn btn-secondary">Confirmer</button>
                    </div>
                </Modal>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(Abonnements));
