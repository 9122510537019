import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { FormInput, FormInputSelect } from "../../../components/FormComponents";
import Modal from "../../../components/Modal";
import { TOAST_TYPE, sendToast } from "../../../helpers/helpers";
import { initValidator } from "../../../validations/validations";

class CreatePersonnesFromEntite extends CustomComponent {
    private _isMounted = false
    private validator

    constructor(props: any) {
        super(props)

        this.validator = initValidator()

        this.state = {
            personne: this.getPersonneDefaultValues(),
            options: {
                civ_id: [],
            },
            openModal: false,
            isSusmitted: false,
        }
    }

    getPersonneDefaultValues = () => {
        const { ind_tel_pays, entite } = this.props?.createProps
        const defaultPrefix = ind_tel_pays && ind_tel_pays.length > 0
            ? ind_tel_pays[0]
            : ''

        return {
            mail_identifiant: '',
            civ_id: '',
            nom: '',
            prenom: '',
            ind_tel: defaultPrefix,
            num_tel: '',
            ind_portable_1: defaultPrefix,
            num_portable_1: '',
            ind_portable_2: defaultPrefix,
            num_portable_2: '',
            email_1: '',
            email_2: '',
            contact_abs: '',
            adr_lig_1: entite?.adr_lig_1,
            adr_lig_2: entite?.adr_lig_2,
            adr_lig_3: entite?.adr_lig_3,
            adr_lig_4: entite?.adr_lig_4,
            code_postal: entite?.code_postal,
            ville: entite?.ville,
            pay_id: entite?.pay_id,
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.getOptionsData()
    }

    componentWillUnmount(): void {
        this._isMounted = false
    }

    openModal = () => {
        const { t } = this.props
        const parentValidator = this.props.createProps.validator

        if (parentValidator.allValid()) {
            this.setState({
                openModal: true,
                isSubmitted: false,
                personne: this.getPersonneDefaultValues()
            })
        } else {
            parentValidator.showMessages()
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }
    }

    getOptionsData = async () => {
        const civilites = await this.request("/ref_civilite", 'GET')

        if ((civilites) && this._isMounted) {
            this.setState((prev: any) => ({
                options: {
                    civ_id: civilites
                        .sort((a: any, b: any) => a.lib_civ.localeCompare(b.lib_civ))
                        .map((civilite: any) => ({ value: civilite.id, label: civilite.lib_civ })),
                }
            }))
        }
    }

    getIndicatifOptions = () => this.props.createProps.ind_tel_pays
        .map((prefix: any) => ({ value: prefix, label: "+" + prefix }))

    handleSubmit = () => {
        const { t } = this.props
        this.setState({ isSubmitted: true });
        if (this.validator.allValid()) {
            this.createPersonne()
        } else {
            this.validator.showMessages()
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }
    }

    createPersonne = async () => {
        this.setState({ isLoading: true })

        const PRODUCTEUR_FONCTION = await this.request('/ref_fonction/code/PROD', 'GET')
        if (!PRODUCTEUR_FONCTION) {
            sendToast(<p>Une erreur est survenue lors de la création, veuillez réessayer.</p>, TOAST_TYPE.ERROR);
            return
        }

        const URL = '/per_personne/fiche';
        const BODY = {
            ...this.state.personne,
            ent_pers_link: [{
                ent_id: this.props.createProps.entite.id,
                hab_code: 'PRODUCT',
                fon_id: PRODUCTEUR_FONCTION.id
            }]
        }

        const data = await this.request(URL, 'POST', BODY)
        if (data.statusCode === 201) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.setState({
                personne: this.getPersonneDefaultValues(),
                openModal: false,
                isSubmitted: false
            }, () => {
                this.validator.hideMessages();
                this.props.onCreate();
            })
        }

        this.setState({ isLoading: false })
    }

    render() {
        const { t } = this.props

        const { phoneMaxLength, ind_tel_pays } = this.props.createProps
        const indicatifOptions = ind_tel_pays.map((prefix: any) => ({ value: prefix, label: "+" + prefix }))

        return (<>
            <button disabled={this.props.disabled} type="button" className="info-card info-card--button" onClick={this.openModal}>Créer une fiche personne<i className="icon-add-circle"></i></button>
            <Modal open={this.state.openModal} customLayout size="lg">
                <Modal.Header>
                    <div className="w-100 d-flex justify-content-between align-items-center flex-wrap m-b-15">
                        <h4 className="modal-title m-b-15">Créer une fiche personne</h4>
                        <div className="ml-auto m-b-15">
                            <button type="button" className="btn btn-white m-r-10" onClick={() => this.setState({ openModal: false })}>Annuler</button>
                            <button type="button"
                                className="btn btn-secondary"
                                onClick={this.handleSubmit}
                            >Créer</button>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group form-group--inline form-group--white">
                                <FormInput light
                                    label="Identifiant"
                                    info={{ text: "TOTO" }}
                                    value={this.state.personne.mail_identifiant}
                                    id="mail_identifiant"
                                    name="mail_identifiant"
                                    simpleValidator={this.validator}
                                    helper={t('personne.info')}
                                    validator_type="email|max:255"
                                    isSubmitted={this.state.isSubmitted}
                                    handle={(e: any) => this.handleInputChange(e, null, "personne")} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-group--inline form-group--white">
                                <FormInputSelect light
                                    label="Civilité"
                                    required
                                    value={this.state.personne.civ_id}
                                    id="civ_id"
                                    name="civ_id"
                                    options={this.state.options.civ_id}
                                    simpleValidator={this.validator}
                                    isSubmitted={this.state.isSubmitted}
                                    handle={(e: any) => this.handleInputChange(e, null, "personne")} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-group--inline form-group--white">
                                <FormInput light
                                    label="Nom"
                                    required
                                    value={this.state.personne.nom}
                                    id="nom"
                                    name="nom"
                                    simpleValidator={this.validator}
                                    validator_type="string|max:35"
                                    isSubmitted={this.state.isSubmitted}
                                    handle={(e: any) => this.handleInputChange(e, null, "personne")} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-group--inline form-group--white">
                                <FormInput light
                                    label="Prénom"
                                    value={this.state.personne.prenom}
                                    id="prenom"
                                    name="prenom"
                                    simpleValidator={this.validator}
                                    validator_type="string|max:35"
                                    isSubmitted={this.state.isSubmitted}
                                    handle={(e: any) => this.handleInputChange(e, null, "personne")} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-group--inline form-group--white">
                                <FormInput light
                                    label="Mail 1"
                                    value={this.state.personne.email_1}
                                    id="email_1"
                                    name="email_1"
                                    simpleValidator={this.validator}
                                    validator_type="email|max:255"
                                    isSubmitted={this.state.isSubmitted}
                                    handle={(e: any) => this.handleInputChange(e, null, "personne")} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-group--inline form-group--white">
                                <FormInput light
                                    label="Mail 2"
                                    value={this.state.personne.email_2}
                                    id="email_2"
                                    name="email_2"
                                    simpleValidator={this.validator}
                                    validator_type="email|max:255"
                                    isSubmitted={this.state.isSubmitted}
                                    handle={(e: any) => this.handleInputChange(e, null, "personne")} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-group--inline form-group--white">
                                <FormInput light
                                    label="Téléphone"
                                    id="num_tel"
                                    name="num_tel"
                                    type="tel"
                                    isPhone={true}
                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                    value={this.state.personne?.num_tel}
                                    phoneHandle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                    phoneName="ind_tel"
                                    phoneValue={this.state.personne?.ind_tel}
                                    phonePlaceholder="+33"
                                    phoneOptions={indicatifOptions}
                                    maxLength={phoneMaxLength ? phoneMaxLength : 15}
                                    isSubmitted={this.state.isSubmitted}
                                    simpleValidator={this.validator}
                                    validator_type={phoneMaxLength ? "numeric" : "phone"}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-group--inline form-group--white">
                                <FormInput light
                                    label="Portable 1"
                                    id="num_portable_1"
                                    name="num_portable_1"
                                    type="tel"
                                    isPhone={true}
                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                    value={this.state.personne?.num_portable_1}
                                    phoneHandle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                    phoneName="ind_portable_1"
                                    phoneValue={this.state.personne?.ind_portable_1}
                                    phonePlaceholder="+33"
                                    phoneOptions={indicatifOptions}
                                    maxLength={phoneMaxLength ? phoneMaxLength : 15}
                                    isSubmitted={this.state.isSubmitted}
                                    simpleValidator={this.validator}
                                    validator_type={phoneMaxLength ? "numeric" : "phone"}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group form-group--inline form-group--white">
                                <FormInput light
                                    label="Portable 2"
                                    id="num_portable_2"
                                    name="num_portable_2"
                                    type="tel"
                                    isPhone={true}
                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                    value={this.state.personne?.num_portable_2}
                                    phoneHandle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                    phoneName="ind_portable_2"
                                    phoneValue={this.state.personne?.ind_portable_2}
                                    phonePlaceholder="+33"
                                    phoneOptions={indicatifOptions}
                                    maxLength={phoneMaxLength ? phoneMaxLength : 15}
                                    simpleValidator={this.validator}
                                    validator_type={phoneMaxLength ? "numeric" : "phone"}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>)
    }
}

export default withTranslation()(withRouter(CreatePersonnesFromEntite));
