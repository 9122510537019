import Modal from "../../../components/Modal"
import { Request, TOAST_TYPE, sendToast } from "../../../helpers/helpers"

export const DeleteModal = ({ regroupement, onClose, onDelete }: any) => {
    const deleteEntite = async (e: any) => {
        e.preventDefault()
        const url = '/ent_regroupement/' + regroupement.id;
        const method = "DELETE";
        const data = await Request(url, method);
        if (data && data.statusCode === 200) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            onDelete()
        }
    }

    return (
        <Modal open={regroupement}>
            <div className="modal-body">
                <p>Etes-vous sûr(e) de vouloir supprimer la fiche regroupement <b>{regroupement?.libelle}</b> ?</p>
            </div>
            <div className="modal-footer">
                <button onClick={onClose} className="btn btn-white">Annuler</button>
                <button onClick={deleteEntite} className="btn btn-secondary">Confirmer</button>
            </div>
        </Modal>
    )
}