import { useEffect, useState } from "react";
import { Modal as BootstrapModal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Request, TOAST_TYPE, sendToast } from "../../../helpers/helpers";

export const SupprimeEntityModal = ({ open, handleOpen, persEntites = [], supprimeEntity = undefined, handle, isUpdate = false }: any) => {
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();


    const thisHandleOpen = (value: boolean) => {
        setOpen(value);
        handleOpen && handleOpen(value);
    }

    const supprimePersonne = async () => {
        if (persEntites?.length === 1) {
            sendToast(<p>{t('error_need_one_per_entite_link')}</p>, TOAST_TYPE.ERROR);
        }
        else {
            if (isUpdate) {
                const data = await Request(`/per_habilitation/${supprimeEntity.id}`, 'DELETE');
                if (data?.affected > 0) {
                    handle && handle(persEntites.filter((element: any) => element !== supprimeEntity));
                    thisHandleOpen(false);
                }
            } else {
                handle && handle(persEntites.filter((element: any) => element !== supprimeEntity));
                thisHandleOpen(false);
            }

        }
    }
    useEffect(() => {
        setOpen(open);
    }, [open]);

    return (
        <BootstrapModal show={isOpen} className="modal--primary modal--lg" centered>
            <BootstrapModal.Body>
                <div className="modal-step">
                    <h2 className="modal-step__title">Êtes-vous sûr(e) de vouloir supprimer la relation Entité - Personne suivante:</h2>
                    <ul className="relation-card__info">
                        <li>Nom personne: {supprimeEntity?.per_id?.nom}</li>
                        <li>Prénom personne: {supprimeEntity?.per_id?.prenom}</li>
                        <li>Nom Usuel: {supprimeEntity?.ent_id?.nom_usuel}</li>
                        <li>Numéro de SIRET: {supprimeEntity?.ent_id?.ent_pay_id?.code_pays + supprimeEntity?.ent_id.siren + supprimeEntity?.ent_id.nic} </li>
                        <li>Ville: {supprimeEntity?.ent_id?.ville}</li>
                        <li>Habilitation: {supprimeEntity?.hab_code?.lib_hab}</li>
                        <li>Fonction: {supprimeEntity?.fon_id?.lib_fonction}</li>
                    </ul>

                    <div className="modal-step__actions">
                        <button type="button" data-dismiss="modal" className="btn btn-white" onClick={() => thisHandleOpen(false)}>Annuler</button>
                        <button
                            type="button"
                            className={persEntites?.length > 1 ? "btn btn-secondary" : "btn btn-secondary disabled"}
                            onClick={supprimePersonne}
                        >
                            Confirmer
                        </button>
                    </div>
                </div>
            </BootstrapModal.Body>
        </BootstrapModal>
    )
}