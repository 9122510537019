import moment from "moment";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import CustomComponent from "../../components/CustomComponent";
import { FilterDropdown } from "../../components/FilterDropdown";
import { FormDatePicker, FormInput } from "../../components/FormComponents";
import { DefaultLayout } from "../../components/Layouts";
import Modal from "../../components/Modal";
import TABLE from "../../components/Table";
import { DebounceQueue } from "../../helpers/debouncer";
import { TOAST_TYPE, datePickerFormat, hasPermission, sendToast } from "../../helpers/helpers";
import { AnnonceCard } from "./_card";

class AnnoncesConsulter extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        titre: '',
        creation_date_range_from: moment().utc().startOf('day').toDate(),
        creation_date_range_to: '',
    };

    private readonly dateKeys: string[] = [
        'creation_date_range_from',
        'creation_date_range_to',
    ]

    constructor(props: any) {
        super(props);
        this.state = {
            filters: this.defaultFilters,
            annonces: [],
            dateFiltersError: '',
            deleteTarget: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        const {t} = this.props;
        document.title = `Infolabo | ${t('annonces.title')}`;
        this._isMounted = true;

        void this.getAnnonces();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getAnnonces = async () => {
        this.setState({isLoading: true});
        const params = new URLSearchParams();

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (this.dateKeys.includes(key) && typeof val !== 'string') {
                !!val && params.append(key, val.toISOString());
            }
            else if (val !== '') params.append(key, val);
        });

        const data = await this.request(`/inf_annonce/admin?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState({annonces: data.annonces})
        }
    }

    validateDateFilters = (): void => {
        const {t} = this.props;
        const dateFrom = this.state.filters.creation_date_range_from;
        const dateTo = this.state.filters.creation_date_range_to;

        if (dateFrom && dateTo && dateFrom > dateTo) {
            this.setState({dateFiltersError: t('annonces.filter_date_error')});
        }
        else {
            this.setState({dateFiltersError: ''}, () => this.queue.push(this.getAnnonces, []));
        }
    }

    deleteAnnonce = async () => {
        const data = await this.request(`/inf_annonce/${this.state.deleteTarget.id}`, 'DELETE');
        if (data?.statusCode === 200 && this._isMounted) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.queue.push(this.getAnnonces, [], true);
            this.setState({deleteTarget: null});
        }
    }

    render() {
        const {t} = this.props;

        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: t('annonces.title') }]} />
                    <div className="d-flex justify-content-between flex-wrap">
                        <h1 className="main-title m-r-50">{t('annonces.title')}</h1>
                        <div className="m-b-20 d-flex flex-wrap justify-content-end align-items-start ml-auto">
                            {hasPermission('DRT_ANNONCE_GERER') && (
                              <Link to="/annonces/creer" className="btn btn-dark m-l-10 m-b-10">
                                  <i className="icon-add"></i>
                                  {t('annonces.create_btn')}
                              </Link>
                            )}
                        </div>
                    </div>

                    <FilterDropdown
                        startOpen
                        parent={this}
                        filters={this.state.filters}
                        onSearchLoad={(filters: any) => this.setState((prev: any) => ({filters: {...prev.filters, ...filters}}), () => this.queue.push(this.getAnnonces, [], true))}
                        onFiltersReset={() => this.setState({filters: this.defaultFilters}, () => this.queue.push(this.getAnnonces, [], true))}
                        onFiltersSearch={() => this.queue.push(this.getAnnonces, [], true)}
                    >
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column flex-lg-row">
                                <div className="form-group form-group--inline-simple">
                                    <FormInput
                                        label={t('annonces.filter_title')}
                                        name="titre"
                                        value={this.state.filters.titre}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getAnnonces, []))}
                                    />
                                </div>
                                <div className="form-group form-group--inline-simple">
                                    <FormDatePicker
                                        name="creation_date_range_from"
                                        id="creation_date_range_from"
                                        type="text"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', this.validateDateFilters)}
                                        value={datePickerFormat(this.state.filters.creation_date_range_from) ?? ''}
                                        label={t('annonces.filter_date_from')}
                                    />
                                </div>
                                <div className="form-group form-group--inline-simple">
                                    <FormDatePicker
                                        name="creation_date_range_to"
                                        id="creation_date_range_to"
                                        type="text"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', this.validateDateFilters)}
                                        value={datePickerFormat(this.state.filters.creation_date_range_to) ?? ''}
                                        label={t('annonces.filter_date_to')}
                                    />
                                </div>
                            </div>
                        </div>
                        {!!this.state.dateFiltersError && <span className="text-error--strong">{this.state.dateFiltersError}</span>}
                    </FilterDropdown>

                    {!!this.state.annonces?.length && (
                        <div className="m-b-30">
                            {this.state.annonces.map((annonce: any) => (
                                <AnnonceCard
                                    key={annonce.id}
                                    annonce={annonce}
                                    onDelete={(annonce: any) => this.setState({deleteTarget: annonce})}
                                />
                            ))}
                        </div>
                    )}
                    {!this.state.annonces?.length && <TABLE.EMPTY_MESSAGE message={t('annonces.no_result')}/>}

                </div>

                <Modal open={this.state.deleteTarget}>
                    <div className="modal-body">
                        {this.state.deleteTarget && (
                            <p>{t('annonces.confirm_delete')}<b>{this.state.deleteTarget.titre}</b> ?</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => this.setState({ deleteTarget: null })} className="btn btn-white">Annuler</button>
                        <button onClick={() => this.deleteAnnonce()} className="btn btn-secondary">Confirmer</button>
                    </div>
                </Modal>
            </DefaultLayout>
        );
    }
}

export default withTranslation()(withRouter(AnnoncesConsulter));
