import 'bootstrap/dist/css/bootstrap.min.css';
import { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import './assets/styles/styles.scss';
import { TooltipContainer } from './components/ToolTipContainer';
import './custom.css';
import Routes from './routes';

const mapStateToProps = (state: any) => {
  return {
    isServiceWorkerInitialized: state.serviceWorkerInitialized,
    isServiceWorkerUpdated: state.serviceWorkerUpdated,
    serviceWorkerRegistration: state.serviceWorkerRegistration,
  }
}

class App extends Component<any, any> {
  render() {
    if (this.props.isServiceWorkerUpdated) {
      const registrationWaiting = this.props.serviceWorkerRegistration?.waiting;

      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
        registrationWaiting.addEventListener('statechange', (e: any) => {
          if (e.target.state === 'activated') {
            // update app
            localStorage.clear();
            window.location.reload();
            window.location.href = '/';
          }
        });
      }
    }

    return (
      <>
        <TooltipContainer />
        <ToastContainer />
        <Routes />
      </>
    );
  }
}

export default (connect(mapStateToProps)(App));