import CustomComponent from "../../../components/CustomComponent";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import { SortbyColumnButton } from "../../../components/TableComponents";
import Pagination from "../../../components/Pagination";
import { labelizePersonneEntites } from "../../../helpers/helpers";
import { DebounceQueue } from "../../../helpers/debouncer";
import { TYPES_DOMAINES } from "../../../enum/types_domaines";

class AddMembreModal extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: 'nom_usuel',
        ordering: 'ASC',
        nom_usuel: '',
        siret: '',
        ville: '',
        nom: '',
        prenom: '',
        entite_associee: '',
    }

    constructor(props: any) {
        super(props);
        this.state = {
            entites: [],
            filters: this.defaultFilters,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            openModal: false,
            checked: [],
            selectedEntites: [],
            isPersonne: this.props.type === 'P'
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps: Readonly<any>) {
        if (prevProps.type !== this.props.type) {
            this.setState({ isPersonne: this.props.type === 'P' });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    openModal = () => {
        this.props.handleIsLoading && this.props.handleIsLoading(true);
        this.setState({
            openModal: true,
            filters: this.defaultFilters,
            selectedEntites: [],
            checked: [],
            entites: [],
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            }
        }, this.getEntites);
    }

    handleSort = (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getEntites, [], true));
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getEntites, [true], true));
    }

    getEntites = async (triggeredByPagination: boolean = false) => {
        let body: any = {};

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') body[key] = val;
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (val !== '') body[key] = val;
        })

        const exclude = this.props.membres.map((m: any) => m.id);
        body.exclude = exclude;

        if (this.state.isPersonne) body.domain = TYPES_DOMAINES.INTERNE_PRIVE;

        if (!triggeredByPagination) body.page = 0;

        const URL = this.state.isPersonne ? '/per_personne/filter' : '/ent_entite/filter';
        let data = await this.request(`${URL}`, 'POST', body);
        if (!this.state.isPersonne) {
            const references = await this.request(`/ent_entite/references`, 'GET');
            data.entites?.map((ent: any) => ent.classe = references.ref_classe_entites?.find((ref: any) => ref.id === ent.cla_id)?.lib_classe);
        }

        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                entites: data.entites ?? data.per_personnes,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }), () => { this.props.handleIsLoading && this.props.handleIsLoading(false); });
        }
    }

    addEntite = () => {
        this.props.handle && this.props.handle(this.state.selectedEntites);
        this.setState({ openModal: false });
    }

    handleCheck = (e: any) => {
        const { checked, value } = e.target;
        const checkedEntites = checked ?
            [...this.state.checked, +value]
            : this.state.checked.filter((val: any) => val !== +value);

        this.setState((prev: any) => ({
            checked: checkedEntites,
            selectedEntites: checked ?
                [...prev.selectedEntites, ...prev.entites.filter((ent: any) => ent.id === +value)]
                : prev.selectedEntites.filter((ent: any) => checkedEntites.includes(ent.id))
        }));
    }

    handleCheckAll = (e: any) => {
        const { checked } = e.target;
        const checkedEntites = checked ?
            [...this.state.checked, ...this.state.entites.map((ent: any) => ent.id)]
            : [...this.state.checked.filter((val: any) => !this.state.entites.map((ent: any) => ent.id).includes(val))];

        this.setState((prev: any) => ({
            checked: checkedEntites,
            selectedEntites: checked ?
                [...prev.selectedEntites, ...prev.entites.filter((ent: any) => checkedEntites.includes(ent.id))]
                : prev.selectedEntites.filter((ent: any) => checkedEntites.includes(ent.id))
        }))
    }

    isAllChecked = () => {
        const visibleEntiteIDs = this.state.entites.map((ent: any) => ent.id);
        const checkedIDs = this.state.checked;
        return !visibleEntiteIDs.filter((ent: any) => !checkedIDs.includes(ent)).length;
    }

    render() {
        return (<>
            <button
                onClick={this.openModal}
                type="button"
                className="info-card info-card--button m-t-30"
            >
                {`Ajouter une ou plusieurs ${this.state.isPersonne ? 'personnes' : 'entités'}`}
                <i className="icon-add-circle"></i>
            </button>
            <Modal open={this.state.openModal} size="lg">
                <div className="modal-header d-flex justify-content-between align-items-center flex-wrap m-b-15">
                    <h4 className="modal-title m-b-15">{`Ajouter une ou plusieurs ${this.state.isPersonne ? 'personnes' : 'entités'}`}</h4>
                    <div className="ml-auto m-b-15">
                        <button type="button" onClick={() => this.setState({ openModal: false })} className="btn btn-white">Annuler</button>
                        <button type="button" className="btn btn-secondary" disabled={this.state.checked.length <= 0} onClick={this.addEntite}>Ajouter</button>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="table-responsive">
                        <table className="table table-custom table-blue">
                            <thead>
                                <tr>
                                    <th className="table-check">
                                        <input type="checkbox" className="checkbox" checked={this.isAllChecked()} onChange={this.handleCheckAll} />
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            {this.state.isPersonne ? <>
                                                <span className="sr-only">Nom</span>
                                                <input
                                                    value={this.state.filters.nom}
                                                    name="nom"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='Nom'
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))} />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'nom'} direction={this.state.filters.ordering} onClick={() => this.handleSort('nom')} />
                                            </> : <>
                                                <span className="sr-only">Nom usuel</span>
                                                <input
                                                    value={this.state.filters.nom_usuel}
                                                    name="nom_usuel"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='Nom usuel'
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))} />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'nom_usuel'} direction={this.state.filters.ordering} onClick={() => this.handleSort('nom_usuel')} />
                                            </>}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            {this.state.isPersonne ? <>
                                                <span className="sr-only">Prénom</span>
                                                <input
                                                    value={this.state.filters.prenom}
                                                    name="prenom"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='Prénom'
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))} />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'prenom'} direction={this.state.filters.ordering} onClick={() => this.handleSort('prenom')} />
                                            </> : <>
                                                <span className="sr-only">N° SIRET</span>
                                                <input
                                                    value={this.state.filters.siret}
                                                    name="siret"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='N° SIRET'
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))} />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'siret'} direction={this.state.filters.ordering} onClick={() => this.handleSort('siret')} />
                                            </>}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            {this.state.isPersonne ? <>
                                                <span className="sr-only">Entité(s)</span>
                                                <input
                                                    value={this.state.filters.entite_associee}
                                                    name="entite_associee"
                                                    type="entite_associee"
                                                    className="table-input"
                                                    placeholder="Entité(s)"
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))} />
                                            </> : <>
                                                <span className="sr-only">Ville</span>
                                                <input
                                                    value={this.state.siret}
                                                    name="ville"
                                                    type="text"
                                                    className="table-input"
                                                    placeholder='Ville'
                                                    onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))} />
                                                <SortbyColumnButton active={this.state.filters.orderBy === 'ville'} direction={this.state.filters.ordering} onClick={() => this.handleSort('ville')} />
                                            </>}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.entites.map((entite: any) => (
                                    <tr key={entite.id}>
                                        <td className="table-check">
                                            <input type="checkbox" className="checkbox" checked={this.state.checked.includes(entite.id)} value={entite.id} id={`entite${entite.id}}`} onChange={this.handleCheck} />
                                        </td>
                                        <td>{this.state.isPersonne ? entite.nom : entite.nom_usuel}</td>
                                        <td>{this.state.isPersonne ? entite.prenom : entite.siret}</td>
                                        <td>{this.state.isPersonne ? labelizePersonneEntites(entite?.entites) : entite.ville}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} secondary />
                </div>
            </Modal>
        </>)
    }
}

export default withTranslation()(withRouter(AddMembreModal));