import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { confirm } from 'react-confirm-box';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import 'simple-react-validator/dist/locale/fr';
import { AuditFields } from '../../../components/AuditFields';
import BreadCrumb from '../../../components/BreadCrumb';
import CustomComponent from '../../../components/CustomComponent';
import { FormCheckbox, FormInput, FormInputSelect, FormInputSelectCreatable } from '../../../components/FormComponents';
import { DefaultLayout } from '../../../components/Layouts';
import Modal from "../../../components/Modal";
import Panel from '../../../components/Panel';
import { TOAST_TYPE, dateFormat, getSession, hasPermission, sendToast, truncateString } from '../../../helpers/helpers';
import { initValidator } from "../../../validations/validations";
import SubscriptionPanel from "../../mes_informations/_subscriptions_panel";
import AddGroupeModal from "../_common/_AddGroupeModal";
import AddEntiteModal from './_AddEntiteModal';
import { EditEntiteModal } from './_EditEntiteModal';
import { SupprimeEntityModal } from './_SupprimeEntityModal';

class CreatePersonne extends CustomComponent {
    private _isMounted = false;
    private _isUpdate = !!this.props.personneId;
    private validator;
    private _persHabPanel: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            personne: null,
            has_identifiant: false,
            has_identifiant_changed: false,
            personneName: '',
            auditFields: null,
            pays: [],
            default_ind_tel_pays: {},
            ind_tel_pays: [],
            civilites: [],
            isSubmitted: false,
            phoneMaxLength: 10,
            postalcodeMaxLength: 5,
            cities: [],
            ville: "",
            entites: [],
            persHab: [],
            groupes: [],
            fonctions: [],
            isAddEntityOpen: false,
            isEditEntityOpen: false,
            isSupprimeEntity: false,
            editEntity: null,
            generalPanel: true,
            persHabPanel: true,
            isLoading: this._isUpdate,
            removeFromGroup: null,
            addGroupeModalIsOpen: false,
            isGroupeEditable: false,
            isAbonnementPanelVisible: false,
        }
        this._persHabPanel = React.createRef();
        this.validator = new SimpleReactValidator({ locale: 'fr' });
        this.validator = initValidator();
    }

    componentDidMount() {
        this._isMounted = true;

        document.title = 'Infolabo | ' + (this._isUpdate ? "Modification d'une fiche personne" : "Création d'une fiche personne");
        this.getAllData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getAllData() {
        this.getRefPays()
        this.getCivilites()
        this.getFonctions()
        this._isUpdate && this.getPersonne()
        this._isUpdate && this.getPerHab()
    }

    async getPersonne() {
        const data = await this.request(`/per_personne/${this.props.personneId}`, 'GET');

        if (!data || !data?.personne) {
            this.props.history.push(`/annuaire/personne`);
        } else {
            if (!data.isEditable) {
                sendToast(<p>Vous n'avez pas les droits d'édition sur cette fiche personne</p>, TOAST_TYPE.ERROR);
                this.props.history.push(`/annuaire/personne/${this.props.personneId}`);
            }

            const { personne } = data;

            const cities = this.state.cities;
            if (personne.ville) {
                const ville = await this.getCitiesByLib({ data: personne.ville });
                if (!!ville && ville.length === 1) {
                    cities.push({ value: ville[0].value, label: ville[0].label, id: ville[0].id });
                    personne.ville = ville[0].value;
                } else
                    cities.push({ value: personne.ville, label: personne.ville });
            }

            const auditFields = await this.request(`/per_personne/auditFields/${this.props.personneId}`, 'GET');
            this._isMounted && this.setState((prevState: any) => ({
                ...prevState,
                personneName: `${personne.prenom || ""} ${personne.nom || ""}`,
                personne: personne ? { ...personne, is_user_api: personne.fl_api } : null,
                has_identifiant: !!personne.mail_identifiant,
                auditFields: auditFields,
                isGroupeEditable: data.isGroupeEditable,
                isAbonnementPanelVisible: data.isAbonnementPanelVisible,
                cities
            }), () => {
                this.loadPrefixOptionsByCountry(personne.pay_id, personne);
            })

            if (hasPermission('DRT_ANNU_GROUPE')) {
                const groupes: any = await this.request(`/ent_groupe/findByPerson/${this.props.personneId}`, 'GET');

                if (this._isMounted && groupes?.statusCode === 200) {
                    this.setState({ groupes: groupes.groupes });
                }
            }
        }

        this._isMounted && this.setState({ isLoading: false })
    }

    getPerHab = async () => {
        const data = await this.request(`/per_habilitation/findByPerson/${this.props.personneId}`, 'GET');
        if (data?.length && this._isMounted) {
            this.setState({ persHab: data });
        }
    }

    async getData(url: string) {
        let result = [];
        const method = "GET";
        const data = await this.request(url, method);
        if (data && data.length > 0) {
            result = data;
        }
        return result;
    }

    getRefPays = async () => {
        const data = await this.getData("/ref_pays");

        let pays: { value: string, label: string }[] = [];
        let default_ind_tel_pays: any = {};
        let ind_tel_pays: any[] = [];
        const default_country = data.find((p: any) => p.lib_pays.toLowerCase() === "france");
        data.forEach((element: any) => {
            pays.push({ label: element.lib_pays, value: element.id });
            default_ind_tel_pays[element.id] = [element.ind_tel_pays];
            if ('ind_tel_pays_2' in element && element.ind_tel_pays_2) {
                default_ind_tel_pays[element.id].push(element.ind_tel_pays_2);
            }
        });
        ind_tel_pays = default_country.ind_tel_pays_2
            ? [default_country.ind_tel_pays, default_country.ind_tel_pays_2]
            : [default_country.ind_tel_pays];

        this._isMounted && this.setState((prevState: any) => ({
            pays,
            default_ind_tel_pays,
            ind_tel_pays,
            personne: {
                ...prevState.personne,
                pay_id: default_country.id,
                ind_tel: default_country.ind_tel_pays,
                ind_portable_1: default_country.ind_tel_pays,
                ind_portable_2: default_country.ind_tel_pays
            }
        }));
    }

    getCivilites = async () => {
        const data = await this.getData("/ref_civilite");

        let civilites: { value: string, label: string }[] = [];
        data.forEach((element: any) => civilites.push({ label: element.lib_civ, value: element.id }));
        this.setState({ civilites });
    }

    getFonctions = async () => {
        const data = await this.getData("/ref_fonction")

        this._isMounted && this.setState({
            fonctions: data
        })
    }

    async getCitiesByLib(lib: any) {
        let cities_name: { value: string, label: string, id?: number }[] = [];
        if (lib.data.length > 1) {
            let cities = await this.getData('/ref_commune/findByLib/' + lib.data);
            cities.forEach((city: any) => {
                cities_name.push({ value: `${city.lib_commune} (${city.departement.code_departement})`, label: `${city.lib_commune} (${city.departement.code_departement})`, id: city?.id });
            });
            this.setState({ cities: cities_name });
            return cities_name;
        }
        return null;
    }

    async afterSubmission(event: React.FormEvent) {
        const { t } = this.props;
        this._isMounted && this.setState({ isLoading: true });
        event.preventDefault();
        this.setState({ isSubmitted: true });
        if (this.validator.allValid()) {
            this._isMounted && await this.createPersonne();
        }
        else {
            this._isMounted && this.setState({ generalPanel: true });
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }
        this._isMounted && this.setState({ isLoading: false });
    }

    async reinitialiserMdp(mail_identifiant: string) {
        const { t } = this.props;
        const url = `/per_personne/reset_password/${mail_identifiant}`;
        const method = "PATCH"
        await this.request(url, method);
        sendToast(<p>{t("mot_de_passe_reinitialiser")}</p>, TOAST_TYPE.SUCCESS);
    }

    async createPersonne(force_update: boolean = false) {
        let { personneId, t } = this.props

        if (this.state.persHab.length < 1) {
            this.setState({ persHabPanel: true });
            this._persHabPanel.current.focus();
            this._persHabPanel.current.scrollIntoView();
            sendToast(<p>{t('error_need_one_per_entite_link')}</p>, TOAST_TYPE.ERROR);
            return;
        }

        const url = this._isUpdate ? `/per_personne/fiche/${personneId}` : '/per_personne/fiche';
        const method = this._isUpdate ? "PATCH" : "POST";
        const { personne } = this.state;
        let body = {
            ...personne,
            fl_api: personne?.is_user_api,
            ville: personne?.ville?.lastIndexOf(" (") > 0 ? personne?.ville?.substr(0, personne?.ville?.lastIndexOf(" (")) : personne?.ville, // remove after last " (" if value containe code department
            force_update
        };

        if (!this._isUpdate) {
            Object.keys(body).forEach(key => {
                if (body[key] === '' || body[key] === null) {
                    delete body[key];
                }
            });

            let ent_pers_link = [];
            for (const element of this.state.persHab) {
                ent_pers_link.push({ id: element?.id, ent_id: element.ent_id.id, hab_code: element.hab_code.code_hab, fon_id: element.fon_id.id });
            }
            body.ent_pers_link = ent_pers_link;
        }

        const data = await this.request(url, method, body);

        if (data) {
            if (data.statusCode === 206) {
                this._isMounted && this.setState({ isLoading: false });
                const isConfirmed = await confirm(data.message, { labels: { confirmable: "Confirmer", cancellable: "Annuler" } })
                if (isConfirmed) this.createPersonne(true)
            }

            if (data.statusCode === 201) {
                sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
                this.props.history.push(`/annuaire/personne/${data.id}`);
            }
        }
    }

    renderButtons = () => {
        const { personneId } = this.props

        if (personneId) return <>
            <Link to={`/annuaire/personne/${personneId}`} className="btn m-r-5">Annuler</Link>
            {hasPermission('DRT_ANNU_PER_GERER') && <Link to={`/annuaire/personne/${this.props.personneId}/supprimer`} className="btn btn-danger m-r-5">Supprimer</Link>}
            {hasPermission('DRT_ANNU_PER_GERER') && <button className="btn btn-secondary">Enregistrer</button>}
        </>

        return <>
            <Link to={`/annuaire/personne`} className="btn m-r-5">Annuler</Link>
            <button type="submit" className='btn btn-secondary'>Enregistrer</button>
        </>
    }


    loadPrefixOptionsByCountry(country_id: number | null = null, personne: any = null) {
        const prefix_options: string[] = [];
        let phoneMaxLength: number | null = null;
        let postalcodeMaxLength: number | null = null;
        if (country_id) {
            let country = this.state.pays.find((p: any) => (p.value === country_id)).label;
            if (country === "FRANCE" || country === "PRV" || country === "TPR" || country === "ZZZ" || country === "INF") {
                phoneMaxLength = 10;
                if (country === "FRANCE")
                    postalcodeMaxLength = 5;
            }
            for (const prefix of this.state.default_ind_tel_pays[country_id]) {
                prefix_options.push(prefix);
            }
        }
        else {
            for (const [, value] of Object.entries(this.state.default_ind_tel_pays)) {
                for (const prefix of (value as string[])) {
                    prefix_options.push(prefix);
                }
            }
        }

        let default_prefix = prefix_options.length > 0 ? prefix_options[0] : "";
        this.setState((prevState: any) => ({
            ind_tel_pays: prefix_options,
            phoneMaxLength,
            postalcodeMaxLength,
            personne: {
                ...prevState.personne,
                ...(!personne && {
                    ind_tel: default_prefix,
                    ind_portable_1: default_prefix,
                    ind_portable_2: default_prefix
                })
            }
        }));
    }

    removeFromGroup = async () => {
        const groupe = this.state.removeFromGroup;

        this.setState({ removeFromGroup: null, isLoading: true });

        const BODY = { membre_personne: +this.props.personneId };
        const data = await this.request(`/ent_groupe/${groupe.id}/deletemember`, 'PATCH', BODY);

        if (data?.statusCode === 201) {
            this.setState((prev: any) => ({
                groupes: prev.groupes.filter((e: any) => e.id !== groupe.id),
                removeFromGroup: null,
                isLoading: false
            }));
        }
    }

    render() {
        const { t } = this.props;
        const session = getSession();
        const IS_ADMIN_INF = session?.hab_code === 'ADMININF';


        return (
            <>
                <DefaultLayout loading={this.state.isLoading}>
                    <div className="container">
                        <BreadCrumb crumbs={[{ name: 'Annuaire', path: '/annuaire/personne' }, { name: this._isUpdate ? this.state.personneName : 'Création d’une fiche personne', path: '' }]} />
                        <form onSubmit={(e) => this.afterSubmission(e)}>
                            <div className="d-flex justify-content-between flex-wrap align-items-center">
                                <h1 className="main-title">{this._isUpdate ? this.state.personneName : 'Création d’une fiche personne'}</h1>
                                <div className="m-b-30">
                                    {this.renderButtons()}
                                </div>
                            </div>
                            <Panel title="Accès">
                                <div className="form-group form-group--inline">
                                    <FormInput
                                        label={t('personne.identifiant')}
                                        id="mail_identifiant"
                                        name="mail_identifiant"
                                        type="email"
                                        handle={(e: any) => { this.handleInputChange(e, null, 'personne'); this.setState({ has_identifiant_changed: true }) }}
                                        value={this.state.personne?.mail_identifiant || ""}
                                        helper={t('personne.info')}
                                        isSubmitted={this.state.isSubmitted}
                                        validator_type="email"
                                        simpleValidator={this.validator}
                                        disabled={this.state.personne?.fl_api}
                                    />
                                </div>
                                {IS_ADMIN_INF &&
                                    <div className="form-group form-group--inline form-group--checkbox">
                                        <FormCheckbox
                                            label={t('personne.is_user_api')}
                                            id="is_user_api"
                                            name="is_user_api"
                                            type="checkbox"
                                            handle={(e: any) => { this.handleInputChange(e, null, 'personne'); }}
                                            value={this.state.personne?.is_user_api || false}
                                            disabled={this.state.personne?.fl_api}
                                        />
                                    </div>
                                }
                                {(this._isUpdate && this.state.personne?.mail_identifiant) && (
                                    <div className={`d-flex justify-content-${!!this.state.personne?.date_derniere_connexion && !this.state.personne?.fl_api ? 'between' : 'end'}`}>
                                        {!!this.state.personne?.date_derniere_connexion && !this.state.personne?.fl_api && (
                                            <div className="m-l-5 form-info-line">
                                                <i className="icon-info" />
                                                <span>{t('personne.last_login')} {dateFormat(this.state.personne?.date_derniere_connexion, 'DD/MM/Y')}</span>
                                            </div>
                                        )}
                                        <button
                                            type="button"
                                            className='btn btn-secondary'
                                            disabled={this.state.has_identifiant_changed || (this.state.personne?.fl_api && !IS_ADMIN_INF)}
                                            onClick={() => this.reinitialiserMdp(this.state?.personne?.mail_identifiant)}
                                        >Réinitialiser le mot de passe</button>
                                    </div>
                                )}
                            </Panel>
                            <Panel title="Informations générales" togglableID="panel-2" open={this.state.generalPanel} handleChange={() => { this.setState({ generalPanel: !this.state.generalPanel }); }}>
                                <div className="large-gutters">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInputSelect
                                                    options={this.state.civilites.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((civility: any) => ({ value: civility.value, label: civility.label }))}
                                                    label={t("personne.civility")}
                                                    name="civ_id"
                                                    id="civ_id"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.civ_id || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    validator_type="numeric"
                                                    required={true}
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.lastname')}
                                                    id="nom"
                                                    name="nom"
                                                    type="text"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.nom || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={35}
                                                    validator_type="string"
                                                    required={true}
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.firstname')}
                                                    id="prenom"
                                                    name="prenom"
                                                    type="text"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.prenom || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={35}
                                                    validator_type="string"
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.email1')}
                                                    id="email_1"
                                                    name="email_1"
                                                    type="email"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.email_1 || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={255}
                                                    validator_type="email"
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.email2')}
                                                    id="email_2"
                                                    name="email_2"
                                                    type="email"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.email_2 || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={255}
                                                    validator_type="email"
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.phone')}
                                                    id="num_tel"
                                                    name="num_tel"
                                                    type="tel"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.num_tel || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    isPhone={true}
                                                    phoneHandle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    phoneName="ind_tel"
                                                    phoneValue={this.state.personne?.ind_tel || ''}
                                                    phonePlaceholder="+33"
                                                    phoneOptions={this.state.ind_tel_pays.map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                                                    maxLength={this.state.phoneMaxLength ? this.state.phoneMaxLength : 15}
                                                    validator_type={this.state.phoneMaxLength ? "numeric" : "phone"}
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.mobile1')}
                                                    id="num_portable_1"
                                                    name="num_portable_1"
                                                    type="tel"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.num_portable_1 || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    isPhone={true}
                                                    phoneHandle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    phoneName="ind_portable_1"
                                                    phoneValue={this.state.personne?.ind_portable_1 || ''}
                                                    phonePlaceholder="+33"
                                                    phoneOptions={this.state.ind_tel_pays.map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                                                    maxLength={this.state.phoneMaxLength ? this.state.phoneMaxLength : 15}
                                                    validator_type={this.state.phoneMaxLength ? "numeric" : "phone"}
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.mobile2')}
                                                    id="num_portable_2"
                                                    name="num_portable_2"
                                                    type="tel"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.num_portable_2 || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    isPhone={true}
                                                    phoneHandle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    phoneName="ind_portable_2"
                                                    phoneValue={this.state.personne?.ind_portable_2 || ''}
                                                    phonePlaceholder="+33"
                                                    phoneOptions={this.state.ind_tel_pays.map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                                                    maxLength={this.state.phoneMaxLength ? this.state.phoneMaxLength : 15}
                                                    validator_type={this.state.phoneMaxLength ? "numeric" : "phone"}
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <h3 className="form-title">Adresse</h3>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.address1')}
                                                    id="adr_lig_1"
                                                    name="adr_lig_1"
                                                    type="text"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.adr_lig_1 || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={38}
                                                    validator_type="string"
                                                    required={true}
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.address2')}
                                                    id="adr_lig_2"
                                                    name="adr_lig_2"
                                                    type="text"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.adr_lig_2 || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={38}
                                                    validator_type="string"
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.address3')}
                                                    id="adr_lig_3"
                                                    name="adr_lig_3"
                                                    type="text"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.adr_lig_3 || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={38}
                                                    validator_type="string"
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.address4')}
                                                    id="adr_lig_4"
                                                    name="adr_lig_4"
                                                    type="text"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.adr_lig_4 || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={38}
                                                    validator_type="string"
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInput
                                                    label={t('personne.postalcode')}
                                                    id="code_postal"
                                                    name="code_postal"
                                                    type="text"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.code_postal || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={this.state.postalcodeMaxLength ? this.state.postalcodeMaxLength : 9}
                                                    validator_type="string"
                                                    simpleValidator={this.validator}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInputSelectCreatable
                                                    optionsName="cities"
                                                    options={this.state.cities.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((city: any) => ({ value: city.value, label: city.label, id: city?.id }))}
                                                    label={t('personne.city')}
                                                    name="ville"
                                                    id="ville"
                                                    displayValueNotLabel
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    handleCreateOption={(e: any) => {
                                                        const createOption = (label: string) => ({
                                                            label,
                                                            value: label,
                                                        });
                                                        this.setState((prevState: any) => ({ cities: [...prevState.cities, createOption(e.data.value)] }));
                                                        this.handleInputChange(e, null, 'personne');
                                                    }}
                                                    handleInput={(e: any) => {
                                                        this.setState({ ville: e.data.value });
                                                        this.getCitiesByLib(e);
                                                    }}
                                                    value={this.state.personne?.ville || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    placeholder={this.state.cities.length < 1 ? "" : "Sélectionner"}
                                                    isDropdownDisabled={
                                                        (this.state.personne?.ville == null || this.state.personne?.ville === "") &&
                                                        this.state.cities.length < 1 &&
                                                        this.state.ville === ""
                                                    }
                                                    maxLength={100}
                                                    validator_type="string"
                                                    simpleValidator={this.validator}
                                                    required={true}
                                                    isClearable={true}
                                                />
                                            </div>
                                            <div className="form-group form-group--inline m-b-30">
                                                <FormInputSelect
                                                    options={this.state.pays.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((country: any) => ({ value: country.value, label: country.label }))}
                                                    label={t("personne.country")}
                                                    name="pay_id"
                                                    id="pay_id"
                                                    handle={(e: any) => {
                                                        this.loadPrefixOptionsByCountry(e.data.value);
                                                        this.handleInputChange(e, null, 'personne');
                                                    }}
                                                    value={this.state.personne?.pay_id || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    validator_type="numeric"
                                                    simpleValidator={this.validator}
                                                    required={true}
                                                    isClearable={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <h3 className="form-title">Contact en cas d'absence</h3>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group form-group--inline">
                                                <FormInput
                                                    label={t('personne.contact')}
                                                    id="contact_abs"
                                                    name="contact_abs"
                                                    type="text"
                                                    handle={(e: any) => this.handleInputChange(e, null, 'personne')}
                                                    value={this.state.personne?.contact_abs || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    maxLength={255}
                                                    validator_type="string"
                                                    simpleValidator={this.validator}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                            <Panel title="Entités et fonctions associées" togglableID="panel-3" open={this.state.persHabPanel} handleChange={() => { this.setState({ persHabPanel: !this.state.persHabPanel }); }}>
                                <div className="row">
                                    {this.state.persHab.map((hab: any, index: number) => (
                                        <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30" key={index}>
                                            <div className="info-card">
                                                <h4 className="info-card__title"><Link to={'/annuaire/entite/' + hab.ent_id?.id} target="_blank">{hab.ent_id?.nom_usuel}</Link></h4>
                                                {hab.ent_id?.ent_pay_id?.code_pays}{hab.ent_id?.siren}{hab.ent_id?.nic}
                                                <dl className="info-card__info">
                                                    <dt>Habilitation :</dt>
                                                    <dd>{hab.hab_code?.lib_hab}</dd>
                                                    <dt>Fonction :</dt>
                                                    <dd>{hab.fon_id?.lib_fonction}</dd>
                                                </dl>
                                                <Dropdown>
                                                    <Dropdown.Toggle bsPrefix="btn-icon" childBsPrefix="btn-icon--primary ml-auto">
                                                        <i className="icon-actions"></i>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Link to={`/annuaire/entite/${hab.ent_id?.id}`} target="_blank" className="dropdown-item">Voir la fiche entité</Link>
                                                        <Dropdown.Item onClick={() => this.setState({ isEditEntityOpen: true, editEntity: hab })}>Modifier la fonction et l'habilitation</Dropdown.Item>
                                                        {this.state.persHab.length > 1 && <Dropdown.Item onClick={() => this.setState({ isSupprimeEntityOpen: true, supprimeEntity: hab })}>Supprimer</Dropdown.Item>}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30" ref={this._persHabPanel}>
                                        <AddEntiteModal
                                            personneId={this.props.personneId}
                                            open={this.state.isAddEntityOpen}
                                            persEntites={this.state.persHab.map((hab: any) => hab.ent_id)}
                                            fonctions={this.state.fonctions}
                                            handleOpen={(v: any) => this.setState({ isAddEntityOpen: v })}
                                            handle={(newHabs: any) => {
                                                this._isUpdate ? this.getPerHab()
                                                    : this.setState((prev: any) => ({ persHab: [...prev.persHab, ...newHabs] }))
                                            }}
                                        />
                                    </div>
                                </div>
                            </Panel>

                            {hasPermission('DRT_ANNU_GROUPE') && !!this.props.personneId && !this.state.personne?.is_user_api && (
                                <Panel title="Groupes associés" togglableID="panel-4" open={true}>
                                    <div className="row">
                                        {this.state.groupes.map((groupe: any) => (
                                            <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30" key={groupe.id}>
                                                <div className="info-card">
                                                    <h4 className="info-card__title"><Link target="_blank" to={`/annuaire/groupe/${groupe.id}`}>{groupe.nom}</Link></h4>
                                                    {`${groupe.proprietaire.siret} - ${groupe.proprietaire.nom_usuel}`}
                                                    <dl className="info-card__info">
                                                        <dt>Type de groupe :</dt>
                                                        <dd>Personne</dd>
                                                        <dt>Description :</dt>
                                                        <dd>{truncateString(groupe.description, 40, '...')}</dd>
                                                    </dl>
                                                    <Dropdown>
                                                        <Dropdown.Toggle bsPrefix="btn-icon" childBsPrefix="btn-icon--primary ml-auto">
                                                            <i className="icon-actions"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Link to={`/annuaire/groupe/${groupe.id}`} target="_blank" className="dropdown-item">Consulter la fiche groupe</Link>
                                                            <Dropdown.Item onClick={() => this.setState({ removeFromGroup: groupe })}>Supprimer la personne du groupe</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        ))}
                                        {this.state.isGroupeEditable && (
                                            <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30">
                                                <AddGroupeModal
                                                    open={this.state.addGroupeModalIsOpen}
                                                    groupes={this.state.groupes.map((groupe: any) => groupe.id)}
                                                    membreId={this.props.personneId}
                                                    groupeType="P"
                                                    handleOpen={(bool: any) => this.setState({ addGroupeModalIsOpen: bool })}
                                                    handle={(e: any) => this.setState((prevState: any) => ({ groupes: prevState.groupes.concat(e) }))}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Panel>
                            )}

                            {(hasPermission('DRT_ANNU_PER_CONSULTER') && !this.state.personne?.is_user_api && this.state.isAbonnementPanelVisible) && (
                                <SubscriptionPanel personneId={this.props.personneId} />
                            )}
                        </form>


                    </div>
                    {this.state.auditFields && (<AuditFields audit={this.state.auditFields} />)}
                </DefaultLayout>
                <EditEntiteModal
                    open={this.state.isEditEntityOpen}
                    persEntite={this.state.editEntity}
                    fonctions={this.state.fonctions}
                    parent={this}
                    handleOpen={(v: any) => this.setState({ isEditEntityOpen: v })}
                    handle={(e: any) => {
                        const index = this.state.persHab.findIndex((hab: any) => hab.ent_id?.id === e.ent_id?.id);
                        this.setState((prevState: any) => ({
                            persHab: prevState.persHab.map((hab: any, i: number) => (i === index ? e : hab)),
                            editEntity: null
                        }));
                    }}
                />
                <SupprimeEntityModal
                    open={this.state.isSupprimeEntityOpen}
                    persEntites={this.state.persHab}
                    supprimeEntity={this.state.supprimeEntity}
                    parent={this}
                    handleOpen={(v: any) => this.setState({ isSupprimeEntityOpen: v })}
                    handle={(e: any) => { this.setState({ persHab: e }) }}
                    isUpdate={!!this.props.personneId}
                />
                <Modal open={!!this.state.removeFromGroup}>
                    <div className="moda-body">
                        <p>Vous souhaitez supprimer l’association entre la fiche personne <b>{this.state.personne?.nom} {this.state.personne?.prenom}</b> et le groupe <b>{this.state.removeFromGroup?.nom}</b>. Confirmez-vous cette suppression ?</p>
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => this.setState({ removeFromGroup: null })} className="btn btn-white">Annuler</button>
                        <button onClick={() => this.removeFromGroup()} className="btn btn-secondary">Confirmer</button>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withTranslation()(withRouter(CreatePersonne));