import moment from "moment/moment";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { FilterDropdown } from "../../../components/FilterDropdown";
import { FormDatePicker } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import Pagination from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { DebounceQueue } from "../../../helpers/debouncer";
import { dateFormat, datePickerFormat } from "../../../helpers/helpers";
import { ImportExportAnnuaireHeader } from "./common/ImportExportAnnuaireHeader";
import Modal from "../../../components/Modal";
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import DATALIST from "../../../components/DataList";
import { Collapse } from "react-bootstrap";
import { useEffect, useState } from "react";

const STATUT_OPTIONS = [
    {value: 'OK', label: 'OK'},
    {value: 'KO', label: 'Erreur'},
];

const getStatutLabel = (statut: string) => {
    if (statut === 'OK') return 'OK';
    return 'Erreur';
}

const TYPE_DONNEES_OPTIONS = [
    {value: 'ENTITE', label: 'Entité'},
    {value: 'PERSONNE', label: 'Personne'},
];

const getTypeDonneeLabel = (type: string) => {
    if (type === 'ENTITE') return 'Entité';
    if (type === 'PERSONNE') return 'Personne';
    return '-';
}

class ExportsFluxApi extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: 'date_appel',
        ordering: 'DESC',
        date_debut: moment().utc().subtract(1, 'month').startOf('day').toDate(),
        date_fin: moment().utc().startOf('day').toDate(),
        appelant: '',
        type_donnee: '',
        statut: '',
        date_appel: '',
    }

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            filters: this.defaultFilters,
            suivis: [],
            open_suivi: null,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            }
        }
    }

    componentDidMount() {
        document.title = 'Infolabo | Imports et exports annuaire';
        this._isMounted = true;

        this.getSuivisApi();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getSuivisApi = async (triggeredByPagination: boolean = false) => {
        this.setState({isLoading: true});

        const dateKeys = [
            'date_debut',
            'date_fin',
            'date_appel'
        ];

        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (dateKeys.includes(key) && typeof val !== 'string') {
                !!val && params.append(key, val.toISOString());
            }
            else if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/inf-suivi-flux-api/?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState({
                suivis: data.suivis,
                paginate: data.paginate,
            });
        }
    }

    handlePerPageChange = (newLimit: number) => {
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                limit: newLimit,
                page: 0,
            }
        }), () => this.queue.push(this.getSuivisApi, [], true));
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getSuivisApi, [true], true));
    }

    handleSort = (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getSuivisApi, [], true));
    }

    getTitle = (suivi: any): string => {
        const title = [
            `${dateFormat(suivi.date_appel, 'DD/MM/Y HH:mm:ss')} - ${suivi.type_donnee}`,
            `${suivi.appelant.siret} - ${suivi.appelant.nom_usuel}`,
        ];

        return title.join('\n');
    };

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <ImportExportAnnuaireHeader
                        paginate={this.state.paginate}
                        onPerPageChange={this.handlePerPageChange}
                        handleRefresh={this.getSuivisApi}
                    />

                    <FilterDropdown
                        parent={this}
                        filters={this.state.filters}
                        onSearchLoad={(filters: any) => this.setState((prev: any) => ({filters: {...prev.filters, ...filters}}), () => this.queue.push(this.getSuivisApi, [], true))}
                        onFiltersReset={() => this.setState({filters: this.defaultFilters}, () => this.queue.push(this.getSuivisApi, [], true))}
                        onFiltersSearch={() => this.queue.push(this.getSuivisApi, [], true)}
                        onApplyFilters={() => this.queue.push(this.getSuivisApi, [], true)}
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group-flex">
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="date_debut"
                                            id="date_debut"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.date_debut) || ''}
                                            label="Date d'appel du"
                                            required
                                        />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="date_fin"
                                            id="date_fin"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.date_fin) || ''}
                                            label="au"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FilterDropdown>

                    <TABLE fixed responsive="accordion">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH
                                    label="Appel"
                                    fixed={{left: 0, width: 80}}
                                />
                                <TABLE.TH
                                    name="type_donnee"
                                    fixed={{left: 80, width: 120}}
                                    select={{placeholder: 'Type', value: this.state.filters.type_donnee, options: TYPE_DONNEES_OPTIONS, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisApi))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('type_donnee')}}
                                />
                                <TABLE.TH
                                    name="appelant"
                                    fixed={{left: 200}}
                                    input={{placeholder: 'Appelant', value: this.state.filters.appelant, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisApi))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('appelant')}}
                                />

                                <TABLE.TH
                                    name="statut"
                                    select={{placeholder: 'Statut', value: this.state.filters.statut, options: STATUT_OPTIONS, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisApi))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('statut')}}
                                />
                                <TABLE.TH
                                    name="date_appel"
                                    datePicker={{value: this.state.filters.date_appel, placeholder: "Date d'appel", onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisApi))}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('date_appel')}}
                                />
                                <TABLE.TH
                                    name="nb_crees"
                                    label="Nb créée(s)"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_crees')}}
                                />
                                <TABLE.TH
                                    name="nb_maj"
                                    label="Nb maj"
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_maj')}}
                                />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.suivis?.map((suivi: any) => (
                                <TABLE.TR title={this.getTitle(suivi)} key={suivi.id}>
                                    <TABLE.TD fixed={{left: 0, width: 80}}>
                                        <button title="Voir le détail de l'appel" type="button" className="action-btn" onClick={() => this.setState({open_suivi: suivi})}>
                                            <i className="icon-eye"></i>
                                            <span className="sr-only">Voir le détail de l'appel</span>
                                        </button>
                                    </TABLE.TD>
                                    <TABLE.TD fixed={{left: 80, width: 120}} label="Type">{getTypeDonneeLabel(suivi.type_donnee)}</TABLE.TD>
                                    <TABLE.TD fixed={{left: 200}} label="Appelant">{`${suivi.appelant.siret}-${suivi.appelant.nom_usuel}`}</TABLE.TD>
                                    <TABLE.TD label="Statut">{getStatutLabel(suivi.statut)}</TABLE.TD>
                                    <TABLE.TD label="Date d'appel">{dateFormat(suivi.date_appel, 'DD/MM/Y HH:mm:ss')}</TABLE.TD>
                                    <TABLE.TD label="Nb créée(s)">{suivi.nb_crees}</TABLE.TD>
                                    <TABLE.TD label="Nb maj">{suivi.nb_maj}</TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>

                    {this.state.suivis?.length === 0 && (
                        <TABLE.NO_RESULT/>
                    )}

                    <Pagination
                        handleClick={this.paginationOnClick}
                        pageCurrent={+this.state.paginate.page + 1}
                        pageTotal={this.state.paginate.pageTotal}
                        pageDisplay={3}
                    />
                </div>

                <ApiResponseModal suivi={this.state.open_suivi} onClose={() => this.setState({open_suivi: null})}/>
            </DefaultLayout>
        )
    }
}

interface ApiResponseModalProps {
    suivi: any | null,
    onClose: () => void,
}

const ApiResponseModal = ({suivi, onClose}: ApiResponseModalProps) => {
    const [requestOpen, setRequestOpen] = useState(false);
    const [responseOpen, setResponseOpen] = useState(true);

    useEffect(() => {
        setRequestOpen(false);
        setResponseOpen(true);
    }, [suivi]);

    if (!suivi) return <Modal open={false} onClose={onClose} text_button="Fermer"></Modal>;

    return (
        <Modal open={true} onClose={onClose} title="Détail de l'appel API" variant="data" size="lg" text_button="Fermer">
            <section>
                <DATALIST>
                    <DATALIST.ITEM title="Appelant" value={`${suivi.appelant.siret}-${suivi.appelant.nom_usuel}`}/>
                    <DATALIST.ITEM title="Date d'appel" value={dateFormat(suivi.date_appel, 'DD/MM/Y HH:mm:ss')}/>
                    <DATALIST.ITEM title="Type d'appel" value={getTypeDonneeLabel(suivi.type_donnee)}/>
                    <DATALIST.ITEM title="Statut" value={getStatutLabel(suivi.statut)}/>
                    <DATALIST.ITEM title="Nb créée(s)" value={suivi.nb_crees}/>
                    <DATALIST.ITEM title="Nb maj" value={suivi.nb_maj}/>
                </DATALIST>
            </section>

            <section>
                <h1 className="d-flex align-items-center cursor-pointer" onClick={() => setRequestOpen(!requestOpen)}>
                    <span>Appel</span>
                    <i className={requestOpen ? "icon-expand-less" : "icon-expand-more"}></i>
                </h1>
                <Collapse in={requestOpen}>
                    <JSONPretty id="json-pretty" data={suivi.requete}></JSONPretty>
                </Collapse>
            </section>
            
            <hr/>
            
            <section>
                <h1 className="d-flex align-items-center cursor-pointer" onClick={() => setResponseOpen(!responseOpen)}>
                    <span>Réponse</span>
                    <i className={responseOpen ? "icon-expand-less" : "icon-expand-more"}></i>
                </h1>
                <Collapse in={responseOpen}>
                    <JSONPretty id="json-pretty" data={suivi.response}></JSONPretty>
                </Collapse>
            </section>
        </Modal>
    );
}

export default withTranslation()(withRouter(ExportsFluxApi))