export enum TYPES_DESTINATAIRE_ANNONCE {
    CLA = 'CLA',
    GRP = 'GRP',
}

export const TYPES_DESTINATAIRE_ANNONCE_LABEL: {[key: string]: string} = {
    [TYPES_DESTINATAIRE_ANNONCE.CLA]: `Classe d'entité`,
    [TYPES_DESTINATAIRE_ANNONCE.GRP]: 'Groupe de personne',
}

export const TYPES_DESTINATAIRE_ANNONCE_OPTIONS = Object.entries(TYPES_DESTINATAIRE_ANNONCE_LABEL).map(([value, label]) => ({
    value, label
}))
