import { Link } from 'react-router-dom';
import { hasPermission } from '../../../helpers/helpers';
import {RES_TYPES_RESULTATS} from "../../../enum/res_types_resultats";

interface ResultatDetailsHeaderProps {
    type_resultats: RES_TYPES_RESULTATS.JOURNALIER | RES_TYPES_RESULTATS.MENSUEL
    onTypeChange: Function
    title: string
}

export const ResultatDetailsHeader = ({title, type_resultats, onTypeChange}: ResultatDetailsHeaderProps) => {
    return (
        <div className="row m-b-15">
            <div className="col-lg-7">
                <div className="d-flex flex-wrap align-items-center">
                <h1 className="main-title m-b-15 mr-auto m-r-lg-30">{title}</h1>
                <div className="select select--rounded m-b-15">
                    <select className="form-control" name="" onChange={(e) => onTypeChange(e.target.value)} defaultValue={type_resultats}>
                        <option value={RES_TYPES_RESULTATS.JOURNALIER}>Résultats journaliers</option>
                        <option value={RES_TYPES_RESULTATS.MENSUEL}>Résultats mensuels</option>
                    </select>
                </div>
                </div>
            </div>
            {hasPermission(['DRT_RES_PARAMETRER', 'DRT_RES_GERER_SEUILS']) && (
                <div className="col-lg-5">
                    <div className="d-flex">
                        <div className="ml-auto">
                            {hasPermission('DRT_RES_PARAMETRER') && (
                                <Link to='/parametres' className="btn btn-dark m-r-15 m-b-15">
                                    <i className="icon-settings"></i>
                                    Paramétrer
                                </Link>
                            )}
                            {hasPermission('DRT_RES_GERER_SEUILS') && (
                                <Link to='/notifications/gerer_seuils_alerte' className="btn btn-dark m-b-15">
                                    <i className="icon-tune"></i>
                                    Gérer mes seuils d'alerte
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}