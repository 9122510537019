import { dateFormat } from "../helpers/helpers"

interface AuditFieldsProps {
    audit: {
        per_creation?: any
        per_modif?: any
        date_creation?: string
        date_modif?: string
        date_suppr?: string
    }
}

export const AuditFields = ({audit} : AuditFieldsProps) => {
    const {
        per_creation,
        per_modif,
        date_creation,
        date_modif,
    } = audit


    let nom_creation
    if(per_creation){
        nom_creation = per_creation.date_suppr ? "Utilisateur supprimé" : `${per_creation.nom} ${per_creation.prenom}`
    }
    else{
        nom_creation = `l'import annuaire Infolabo`
    }

    let nom_modif
    if(per_modif){
        nom_modif = per_modif.date_suppr ? "Utilisateur supprimé" : `${per_modif.nom} ${per_modif.prenom}`
    }
    else{
        nom_modif = `l'import annuaire Infolabo`
    }

    return (date_creation || date_modif) ? (
        <div className="container mt-auto">
            <div className="audit-fields">
                <i className="icon-info"></i>
                <ul>
                    {date_creation && (
                        <li>Créé le {dateFormat(date_creation, "DD/MM/YYYY")} par {nom_creation}</li>
                    )}
                    {date_modif && (
                        <li>Dernière modification le {dateFormat(date_modif, 'DD/MM/YYYY')} par {nom_modif}</li>
                    )}
                </ul>
            </div>
        </div>
    ) : (<></>)
}