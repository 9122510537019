import i18n from 'i18next';
import moment from "moment";
import { API_DEBUG } from "../config";

export const REGEX = {
    email: /^\w(\.?[a-zA-Z0-9_+-])+\.?@\w(\.?[a-zA-Z0-9_-])*\w\.[a-zA-Z0-9]{2,}$/g,
    name: /^([^\x21-\x26\x28-\x2C\x2E-\x40\x5B-\x60\x7B-\xAC\xAE-\xBF\xF7\xFE]+)$/g,
    password_8_characters: /^.{8,}$/,
    password_uppercase: /([A-Z])/,
    password_lowercase: /([a-z])/,
    password_number: /([0-9])/,
    number: /^\d+$/,
    password_special_char: /([^A-Za-z0-9 ])/,
}

export const validateEmail = (value: string, empty_msg: string = "") => {
    value = value.trim();
    if (value === '') return { type: 'erreur', message: empty_msg ? empty_msg : i18n.t("empty_field") }

    return new RegExp(REGEX.email).test(value)
        ? null
        : { type: 'erreur', message: i18n.t("not_an_email") }
}

export const validateName = (value: string, empty_msg: string = "") => {
    value = value.trim();
    if (value === '') return { type: 'erreur', message: empty_msg ? empty_msg : i18n.t("empty_field") }

    return new RegExp(REGEX.name).test(value)
        ? null
        : { type: 'erreur', message: i18n.t("does_not_match") }
}

export const validatePasswordMatch = (value: string, targetValue: string) => {
    value = value.trim();
    if (value === '') return { type: 'erreur', message: i18n.t("new_pass_and_conf") };

    return value === targetValue
        ? null
        : { type: 'erreur', message: i18n.t("passwords_does_not_match") }
}

export const validatePassword = (value: string, currentPassword: string | null = null) => {
    value = value.trim();
    if (value === '') return { type: 'erreur', message: i18n.t("new_pass_and_conf") };

    let i = 0;
    let contains: string[] = [];

    const validations = [
        { regex: REGEX.password_uppercase, message: 'upper' },
        { regex: REGEX.password_lowercase, message: 'lower' },
        { regex: REGEX.password_number, message: 'number' },
        { regex: REGEX.password_special_char, message: 'special_char' },
    ];

    for (let validation of validations) {
        const { regex } = validation;
        if (new RegExp(regex).test(value)) {
            contains.push(validation.message)
            API_DEBUG && console.log(validation.message);
            i++;
        }
    }

    if (currentPassword && value !== currentPassword) {
        return { type: 'erreur', message: i18n.t("passwords_does_not_match"), contains: contains };
    }

    if (!new RegExp(REGEX.password_8_characters).test(value))
        return { type: 'erreur', message: i18n.t("password_8_characters"), contains: contains };

    if (value.length > 255) {
        return { type: 'erreur', message: i18n.t("password_max_characters"), contains: contains };
    }

    if (i < 3)
        return { type: 'erreur', message: i18n.t("password_does_not_match_security"), contains: contains };

    return { type: 'success', contains: contains }
}

export const validateNumber = (value: string | null) => {
    value = value ? value.trim() : null;
    if (value === '') return { type: 'erreur', message: i18n.t("empty_field") }

    return new RegExp(REGEX.number).test(value ?? "")
        ? null
        : { type: 'erreur', message: i18n.t("does_not_match_to_a_number") }
}

export const validateSiret = (value: string | null, empty_siret_msg: string = "", bad_siret_msg: string = "") => {
    value = value ? value.trim() : null;
    if (value === '')
        return { type: 'erreur', message: empty_siret_msg ? empty_siret_msg : i18n.t("empty_field") };

    if (value && value.length !== 14)
        return { type: 'erreur', message: bad_siret_msg ? bad_siret_msg : i18n.t("does_not_match_to_a_siret") };

    return new RegExp(REGEX.number).test(value ?? "")
        ? null
        : { type: 'erreur', message: bad_siret_msg ? bad_siret_msg : i18n.t("does_not_match_to_a_siret") }
}

export const validateIsNotEmpty = (value: string, empty_msg: string = "") => {
    value = value.toString().trim();
    if (value === '') return { type: 'erreur', message: empty_msg ? empty_msg : i18n.t("empty_field") };
    return null;
}

export const validateFile = (file: any, extensions: string[], maxSize: number, maxFileNumber?: number) => {
    const fileList = Array.isArray(file) ? file : [file];

    if (maxFileNumber !== undefined && fileList.length > maxFileNumber) {
        return `Un maximum de ${maxFileNumber} fichiers est autorisé`;
    }

    const fileNameList = fileList.map((f: File) => f.name);
    if (fileNameList.some((name: string, index: number) => fileNameList.indexOf(name) !== index)) {
        return `Un fichier ne peut être sélectionné plusieurs fois`;
    }

    for (const f of fileList) {
        const fileExtension = f.name.split('.').pop();

        if (!extensions.some((ext: string) => fileExtension.localeCompare(ext, undefined, {sensitivity: 'base'}) === 0)) {
            return `Seuls les fichiers de type ${extensions.join(', ')} sont autorisés`;
        }

        if (f.size > maxSize * 1000000) {
            const sizeFormat = maxSize >= 1 ? `${maxSize} Mo` : `${maxSize * 1000} Ko`;
            return `La taille maximum autorisée est de ${sizeFormat}`;
        }
    }
}

export const validateLaterOrToday = (date: Date) => {
    const today = moment.utc().startOf('day').toDate();
    if (date < today) return {type: 'erreur', message: `La date saisie ne peut être antérieure à la date du jour.`};
    return null;
}

export const validateLaterThanDate = (date: Date, referenceDate: Date, labelReference: string) => {
    if (!!date && date <= referenceDate) return {type: 'erreur', message: `La date saisie doit être ultérieure à la date ${labelReference}.`};
    return null;
}
