import {dateFormat, truncateString} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

interface AnnonceCardMenuProps {
    annonce: {
        id: number,
        titre: string,
        details: string,
        date_debut: string,
        is_read: boolean,
    };
    openAnnonce: (id: number | null) => void;
    isNewlyRead?: boolean;
}

export const AnnonceCardMenu = ({annonce, openAnnonce, isNewlyRead}: AnnonceCardMenuProps) => {
    const {t} = useTranslation();

    return (
        <li className='small-notification__item'>
            <div className="small-notification__top">
                <h4 className={classNames({
                    'small-notification__title': true,
                    'unread': !annonce.is_read && !isNewlyRead,
                })}>{annonce.titre}</h4>
                <span className='small-notification__date'>{dateFormat(annonce.date_debut, 'DD MMM.')}</span>
            </div>
            <div className="small-notification__text">
                <p dangerouslySetInnerHTML={{__html: truncateString(annonce.details, 100, '...', true, undefined, true)}}></p>
            </div>
            <button type="button" onClick={() => openAnnonce(annonce.id)} className='small-notification__btn'>{t('annonces.see_more')}</button>
        </li>
    )
}
