import { NavLink } from "react-router-dom"
import BreadCrumb from "../../../components/BreadCrumb"
import { hasPermission } from "../../../helpers/helpers"

const LINKS = [
    { name: 'Par production', slug: 'production', permissions: ['DRT_RES_PAR_PRODUCTION'] },
    { name: 'Par citerne', slug: 'citerne', permissions: ['DRT_RES_PAR_CITERNE'] },
    { name: 'Par tournée', slug: 'tournee', permissions: ['DRT_RES_PAR_TOURNEE'] },
    { name: 'Par critère', slug: 'critere', permissions: ['DRT_RES_PAR_CRITERE'] },
]

type Props = {
    onHome?: boolean
    homeTab?: string
};

export const ResultatsListHeader = ({onHome, homeTab}: Props) => {

    return (<>
        {!onHome && <BreadCrumb crumbs={[{name: 'Résultats'}]}/>}
        <h1 className="main-title">Résultats</h1>
        <ul className="nav nav-tabs m-t-10 m-t-md-0 m-r-md-15">
            {LINKS.map(tab =>
                !tab.permissions || (tab.permissions && hasPermission(tab.permissions)) ?
                    <li key={tab.slug} className="nav-item">
                        <NavLink className="nav-link" to={!!onHome && homeTab === tab.slug ? '' : `/resultats/${tab.slug}`}>
                            <img className="tab-icon__icon tab-icon__icon--small" src={`/icons/icon_${tab.slug}.svg`} alt="" />
                            {tab.name}
                        </NavLink>
                    </li>
                    :
                    <></>
            )}
        </ul>
    </>
    )
}