import * as qs from 'qs';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CustomComponent from '../../../components/CustomComponent';
import Modal from '../../../components/Modal';
import { TOAST_TYPE, sendToast } from '../../../helpers/helpers';
import CreatePersonne from "./create";
import ListPersonne from "./list";


class DeletePersonne extends CustomComponent {
    private _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {
            personne: "",
            previousPersonneId: "",
            backUrl: "",
            id: ""
        }
    }

    componentDidMount() {
        const id: number = Number(this.props.match.params.id);
        this._isMounted = true;
        document.title = 'Infolabo';
        const backUrl = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })?.backUrl || "";
        let previousPersonneId = backUrl && (backUrl as string).split("/").length > 3 ? Number((backUrl as string).split("/")[3]) : "";
        this.loadPersonne(id);
        this.setState({ backUrl, previousPersonneId, id });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadPersonne(id: number) {
        const url = '/per_personne/' + id;
        const method = "GET";
        const data = await this.request(url, method);
        if (data) {
            this.setState({ personne: data });
        }
    }

    async deletePersonne() {
        const id = this.state.id;
        const url = '/per_personne/' + id;
        const method = "DELETE";
        const data = await this.request(url, method);
        if (data) {
            if (data.statusCode === 201) {
                sendToast(data?.message, TOAST_TYPE.SUCCESS);
                this.props.history.push("/annuaire/personne");
            } else {
                this.state.backUrl ? this.props.history.push(this.state.backUrl) : this.props.history.goBack();
            }
        }
    }


    render() {
        return (
            <>
                {
                    this.state.backUrl && (this.state.backUrl as string)?.includes("modifier") ?
                        <CreatePersonne personneId={this.state.previousPersonneId} isVisualization={true} />
                        :
                        <ListPersonne />
                }
                <Modal
                    open={true}
                >
                    <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                        <p>Etes-vous sûr(e) de vouloir supprimer la personne <b>{this.state.personne?.personne?.civilite} {this.state.personne?.personne?.nom} {this.state.personne?.personne?.prenom}</b></p>
                        {this.state.personne?.personne?.per_utilisateur?.fl_api && <p><b>Cet utilisateur est un utilisateur API.</b></p>}
                    </div>
                    <button onClick={() => this.state.backUrl ? this.props.history.push(this.state.backUrl) : this.props.history.goBack()} className="btn btn-white">Annuler</button>
                    <button onClick={() => this.deletePersonne()} className="btn btn-secondary">Confirmer</button>
                </Modal>
            </>
        )
    }
}

export default withTranslation()(withRouter(DeletePersonne));