import ReactToolTip, {TooltipProps} from 'react-tooltip'

export const TooltipContainer = () => {
    const options: TooltipProps = {
        eventOff: 'click',
        bodyMode: true,
        place: 'top',
        type: 'success',
        className:'react-tooltip',
        effect: 'solid',
        html: true,
    }

    return <ReactToolTip {...options}/>
}