import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { CriteriaPanel, ICriteres } from "../../../components/CriteriaPanel";
import CustomComponent from "../../../components/CustomComponent";
import { FilterDropdown } from '../../../components/FilterDropdown';
import { FormDatePicker, FormInput, FormInputSelect } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import { SeeMoreButton } from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { checkIfCriteresAreFilled, dateFormat, datePickerFormat, displayValueByStatus, hasPermission, numberFormat, onExportFile, Request } from "../../../helpers/helpers";
import { ResultatsListHeader } from "../_common/ResultatsListHeader";
import { DailyDetailsModal } from "../_common/_DailyDetailsModal";
import { DetailsModal } from "../_common/_DetailsModal";
import { confirm } from 'react-confirm-box';

class ResultatsCiterneList extends CustomComponent {
    private _isMounted = false
    private filterSaveKey = 'RESULTAT_EXPLOITATION_CITERNE_LIST';

    private default_filters = {
        ent_id_cc: '',
        ent_id_ud: '',
        type_lait: '',
        ech_statut: '',
        criteres_analyse: '',
        tournee: '',
        tour: '',
        date_debut: moment().utc().subtract(10, 'day').startOf('day').toDate(),
        date_fin: moment().utc().endOf('day').toDate(),
        orderBy: 'ech.ent_id_cc',
        ordering: 'ASC',
        criteres: []
    }

    constructor(props: any) {
        super(props)
        this.state = {
            filters: this.default_filters,
            show_more_filters: false,
            options: {
                groupe: [],
                ent_id_cc: [],
                ent_id_ud: [],
                type_lait: [],
                ech_statut: [],
                criteres_analyse: [],
            },
            litrage_lib: 'L',
            recherches: [],
            criteres: [],
            echantillons: null,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
            },
            dailyDetailsModal: null,
            detailsModal: null,
            isLoading: false
        }
    }

    componentDidMount() {
        document.title = "Infolabo | Par citerne";
        this._isMounted = true;
        this.getCodeUnite();
        this.getTypeLait();
        this.getEchStatus();
        this.getEntiteCCOptions();
        this.getEntiteUDOptions();
        this.getCritereAnalyse();
        this.getRecherches();
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getTypeLait = async () => {
        const data = await this.request('/references/type_lait', 'GET')
        if (data?.statusCode === 200) {
            const type_lait: any[] = []
            for (let esp of data.especes_collectees) {
                for (let nat of data.natures_lait) {
                    type_lait.push({ label: `${esp.libelle} - ${nat.lib_court}`, value: `${esp.id}-${nat.id}` })
                }
            }
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    type_lait
                }
            }))
        }
    }

    getEchStatus = async () => {
        const data = await this.request('/res_echantillon/status', 'GET');
        if (data && data.length) {
            const ech_statut: any[] = [];
            for (const statut of data) {
                ech_statut.push({ label: `${statut.libelle}`, value: `${statut.id}` });
            }
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ech_statut
                }
            })
            );
        }
    }

    async getEntiteCCOptions(e: any | null = null) {
        let URL: string = '/ent_entite/autocomplete/CECOL'

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        }

        const data = await this.request(URL, 'GET')
        if (data && data.length) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ent_id_cc: data.map((option: any) => ({ label: option.label, value: option.id }))
                }
            }));
        }
    }

    async getEntiteUDOptions(e: any | null = null) {
        let URL: string = '/ent_entite/autocomplete/CEDEP'

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        }

        const data = await this.request(URL, 'GET')
        if (data && data.length) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ent_id_ud: data.map((option: any) => ({ label: option.label, value: option.id }))
                }
            }));
        }
    }

    getCritereAnalyse = async () => {
        const criteres_analyse = await this.request(`/references/critere_analyse`, 'GET');

        if (criteres_analyse && criteres_analyse.length && this._isMounted) {
            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    criteres_analyse: criteres_analyse.map((option: any) => ({ label: option.lib_court, value: option.id }))
                }
            }));
        }
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/${this.filterSaveKey}`, 'GET')

        if (recherches && this._isMounted) {
            this.setState({ recherches })
        }
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.getEchantillons(false, true));
    }

    getParams = (triggeredBySeemore: boolean = false) => {
        const params = new URLSearchParams();
        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        });

        Object.keys(this.state.filters).forEach(key => {
            let val = this.state.filters[key];

            if (key === 'criteres') val = JSON.stringify(val.filter((crit: any) => !!crit.cri_id && !!crit.operator && (!!crit.value || !!crit.app_id)));
            if (val instanceof Date) val = (val as Date).toISOString();
            if (val !== '' && val !== null) params.append(key, val.toString());
        });

        params.set('page', triggeredBySeemore ? this.state.paginate.page + 1 : 0);
        return params;
    }

    getEchantillons = async (triggeredBySeemore: boolean, confirmAction: boolean) => {
        if (!checkIfCriteresAreFilled(this.state.filters.criteres)) return;

        this.setState({ isLoading: true });

        const params = this.getParams(triggeredBySeemore);
        params.append('confirm', confirmAction.toString());

        const data = await Request(`/resultats/citerne?${params.toString()}`, 'GET');
        this.setState({ isLoading: false });

        // Demande de confirmation en cas de filtres ralentissant la requête
        if (data?.statusCode === 206) {
            const isConfirmed: boolean = await confirm(data.message, { labels: { confirmable: "Confirmer", cancellable: "Annuler" } });

            if (isConfirmed) {
                this.getEchantillons(triggeredBySeemore, true);
            }
        }

        if (data?.statusCode === 200) {
            let criteres = data.criteres;
            if (triggeredBySeemore) {
                for (const critere of this.state.criteres) {
                    let exist = false
                    for (const crit of data.criteres) {
                        if (JSON.stringify(crit) === JSON.stringify(critere)) {
                            exist = true
                        }
                    }

                    if (!exist) {
                        criteres.push(critere);
                    }
                }
                criteres.sort((a: any, b: any) => {
                    if (a.length > 1 && b.length > 1 && a[0].ordre_affichage > b[0].ordre_affichage)
                        return 1;
                    else
                        return -1
                });
            }

            this.setState((prev: any) => ({
                echantillons: triggeredBySeemore ? [...prev.echantillons, ...data.echantillons] : data.echantillons,
                criteres: criteres,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }));
        }
    }

    async getCodeUnite() {
        let URL: string = `/ref_unite_mesure/LTR`

        const data = await this.request(URL, 'GET')
        if (data) {
            this._isMounted && this.setState((prev: any) => ({
                litrage_lib: data.lib_court || prev.litrage_lib
            }));
        }
    }

    updateThresholds = (criteres: ICriteres[]) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                criteres
            }
        }));
    }

    onDateClick = async (id: any) => {
        this.setState({ isLoading: true })
        this.request(`/res_echantillon/resEchantillionDetail/${id}`, 'GET').then((data) => {
            this.setState({ isLoading: false })
            if (data.statusCode === 200) {
                this.setState({
                    detailsModal: data.data,
                })
            }
        })
    }

    onDailyDetailClick = async (id: any) => {
        this.setState({ isLoading: true })
        this.request(`/res_resultat_journalier/resResultatJournalierDetail/${id}`, 'GET').then((data) => {
            this.setState({ isLoading: false })
            if (data.statusCode === 200) {
                this.setState({
                    dailyDetailsModal: data.data,
                })
            }
        })
    }

    paramsFromEch = (ech: any, isTour: boolean = false) => {
        const params = new URLSearchParams();
        params.append('cc', ech.ent_id_cc?.id);
        params.append('date', ech.date_collecte);
        params.append('trn', ech.trn_id?.code);
        params.append('type_lait', `${ech.esp_id?.id}-${ech.nat_id?.id}`)
        params.append('referer', 'res_citerne');
        if (isTour) params.append('tou', ech.tou_id?.numero);
        return params.toString();
    }

    onSearchLoad = (filters: any) => {
        this.setState({
            filters,
            show_more_filters: filters.criteres.length > 0
        }, () => this.getEchantillons(false, false))
    }

    render() {
        if (this.props.noLayout) return this.renderContent()

        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    {this.renderContent()}
                </div>
            </DefaultLayout>
        )
    }

    onExportPdf = () => console.log('Export format pdf')

    onExportCsv = () => {
        const session = this.getLocalStorageSession() as any;
        const fileName: string = `${session.ent_siret}_LISTE_CITERNES_R_${dateFormat(undefined, 'YYYYMMDD')}.csv`;
        const params = this.getParams();
        const url = `/exports/citerne?${params.toString()}`;

        onExportFile(this, fileName, url, 'GET');
    }

    renderContent() {
        return (
            <>
                <ResultatsListHeader onHome={this.props.noLayout} homeTab="citerne" />
                <FilterDropdown
                    startOpen={true}
                    onExport={{ csv: this.onExportCsv }}
                    parent={this}
                    type_recherche={this.filterSaveKey}
                    filters={this.state.filters}
                    recherches={this.state.recherches}
                    onUpdate={this.getRecherches}
                    onSearchLoad={this.onSearchLoad}
                    onApplyFilters={() => this.getEchantillons(false, false)}
                    onFiltersSearch={() => this.getEchantillons(false, false)}
                    onFiltersReset={() => {
                        this.setState({ filters: this.default_filters }, () => {
                            this.getEntiteCCOptions();
                            this.getEntiteUDOptions();
                        })
                    }}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group form-group--inline">
                                <FormInputSelect isClearable label="Centre de collecte" name={`ent_id_cc`} value={this.state.filters.ent_id_cc} handle={(e: any) => this.handleInputChange(e, null, 'filters')} handleInput={(e: any) => this.getEntiteCCOptions(e)} options={this.state.options.ent_id_cc} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInputSelect isClearable label="Usine de dépotage" name={`ent_id_ud`} value={this.state.filters.ent_id_ud} handle={(e: any) => this.handleInputChange(e, null, 'filters')} handleInput={(e: any) => this.getEntiteUDOptions(e)} options={this.state.options.ent_id_ud} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInputSelect isClearable label="Type de lait" name={`type_lait`} value={this.state.filters.type_lait} handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.type_lait} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInputSelect isClearable label="Statut de l'échantillon" name={`ech_statut`} value={this.state.filters.ech_statut} handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.ech_statut} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group form-group--inline align-items-center">
                                <FormInputSelect info={{ placement: 'top', text: "Permet de sélectionner uniquement les échantillons analysés pour le critère sélectionné" }} isClearable label="Critère analysé" name={`criteres_analyse`} value={this.state.filters.criteres_analyse} handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.criteres_analyse} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInput label="Tournée" name={`tournee`} value={this.state.filters.tournee} handle={(e: any) => this.handleInputChange(e, null, 'filters')} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInput label="Tour" name={`tour`} value={this.state.filters.tour} handle={(e: any) => this.handleInputChange(e, null, 'filters')} />
                            </div>
                            <div className="form-group-flex">
                                <div className="form-group form-group--inline">
                                    <FormDatePicker
                                        name="date_debut"
                                        type="text"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        value={datePickerFormat(this.state.filters.date_debut)}
                                        label="Période du" />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormDatePicker
                                        name="date_fin"
                                        type="text"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        value={datePickerFormat(this.state.filters.date_fin)}
                                        label="au" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <CriteriaPanel
                                criteres={this.state.filters.criteres}
                                onChange={this.updateThresholds}
                                min={1}
                                togglable
                                onToggle={(open: boolean) => this.setState({ show_more_filters: open })}
                                open={this.state.show_more_filters}
                            ></CriteriaPanel>
                        </div>
                    </div>
                </FilterDropdown>

                {!this.state.echantillons && <TABLE.NO_REQUEST />}
                {this.state.echantillons && (<>
                    {this.state.echantillons.length <= 0 ? <TABLE.NO_RESULT /> : (<>
                        {/* <TABLE responsive="card" fixed theadSticky> */}
                        <TABLE responsive="card" fixed bordered>
                            <TABLE.THEAD>
                                <TABLE.TR>
                                    <TABLE.TH name='ech.ent_id_cc' fixed={{ left: 0, width: 200 }}
                                        label="Centre de collecte"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('ech.ent_id_cc') }} />
                                    <TABLE.TH name='ech.ent_id_ud' fixed={{ left: 200, width: 200 }}
                                        label="Usine de dépotage"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('ech.ent_id_ud') }} />
                                    <TABLE.TH name='trn.code' fixed={{ left: 400, width: 110 }}
                                        label="Tournée"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('trn.code') }} />
                                    <TABLE.TH name='tou.numero' fixed={{ left: 510, width: 85 }}
                                        label="Tour"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('tou.numero') }} />
                                    <TABLE.TH name='date_prelevement' fixed={{ left: 595, width: 100 }}
                                        label="Date prél."
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('ech.date_prelevement') }} />
                                    <TABLE.TH name='nat.libelle'
                                        label="Type de lait"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nat.libelle') }} />
                                    <TABLE.TH name='status_ech.lib_long'
                                        label="Statut"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('status_ech.lib_long') }} />
                                    <TABLE.TH name='ech.volume_collecte'
                                        label="Litrage"
                                        sublabel="(L)"
                                        className="text-center"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('ech.volume_collecte') }} />
                                    {
                                        this.state.criteres.map((el: any, index: number) =>
                                            <TABLE.TH key={index} name=''
                                                label={el[0]?.lib_court || ''}
                                                sublabel={el[1]?.lib_court ? `(${el[1]?.lib_court})` : ''}
                                                className="text-center"
                                            // sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('ech.volume_collecte') }}
                                            />
                                        )
                                    }
                                </TABLE.TR>
                            </TABLE.THEAD>
                            <TABLE.TBODY>
                                {
                                    this.state.echantillons.map((echantillon: any) => (
                                        <TABLE.TR key={echantillon.id} title={echantillon.ent_id_cc && echantillon.ent_id_cc?.siret + " - " + echantillon.ent_id_cc?.nom_usuel}>
                                            <TABLE.TD fixed={{ left: 0, width: 200 }} hiddenMobile><strong>{echantillon.ent_id_cc && echantillon.ent_id_cc?.siret}<br />{echantillon.ent_id_cc?.nom_usuel}</strong></TABLE.TD>
                                            <TABLE.TD fixed={{ left: 200, width: 200 }} label="Usine de dépotage">{echantillon.ent_id_ud && echantillon.ent_id_ud?.siret}<br />{echantillon.ent_id_ud?.nom_usuel}</TABLE.TD>
                                            <TABLE.TD fixed={{ left: 400, width: 110 }} label="Tournée" className="text-right">
                                                {hasPermission('DRT_RES_PAR_TOURNEE') ? (
                                                    <Link to={{ pathname: `/resultats/tournee`, search: this.paramsFromEch(echantillon) }} target="_blank">{echantillon.trn_id && echantillon.trn_id?.code}</Link>
                                                ) : (
                                                    <>{echantillon.trn_id && echantillon.trn_id?.code}</>
                                                )}
                                            </TABLE.TD>
                                            <TABLE.TD fixed={{ left: 510, width: 85 }} label="Tour" className="text-right">
                                                {hasPermission('DRT_RES_PAR_TOURNEE') ? (
                                                    <Link to={{ pathname: `/resultats/tournee`, search: this.paramsFromEch(echantillon, true) }} target="_blank">{echantillon.tou_id && echantillon.tou_id?.numero}</Link>
                                                ) : (
                                                    <>{echantillon.tou_id && echantillon.tou_id?.numero}</>
                                                )}
                                            </TABLE.TD>
                                            <TABLE.TD fixed={{ left: 595, width: 100 }} label="Date prél.">
                                                <button onClick={() => this.onDateClick(echantillon.id)} className="link link--blue link--strong" type="button">
                                                    {echantillon.date_prelevement && dateFormat(echantillon.date_prelevement, 'DD/MM/YYYY')}
                                                </button>
                                            </TABLE.TD>
                                            <TABLE.TD label="Type de lait">
                                                {echantillon.esp_id && echantillon.esp_id?.libelle}
                                                <br />
                                                {echantillon.nat_id && echantillon.nat_id?.lib_court}
                                            </TABLE.TD>
                                            <TABLE.TD label="Statut">{echantillon.ste_id && echantillon.ste_id?.lib_long}</TABLE.TD>
                                            <TABLE.TD label="Litrage" className="text-right">{numberFormat(echantillon.volume_collecte)}</TABLE.TD>
                                            {
                                                this.state.criteres.map((el: any, index: number) =>
                                                    <TABLE.TD key={index} label={(el[0]?.lib_court || "") + " " + (el[1]?.lib_court ? "(" + el[1]?.lib_court + ")" : "")}>
                                                        {
                                                            echantillon.resultats.map((resultat: any) => <React.Fragment key={resultat.id}>
                                                                {
                                                                    (resultat.cri_id?.id === el[0]?.id && resultat.uni_id?.id === el[1]?.id) ?
                                                                        <>
                                                                            {
                                                                                ['ANN', 'SUS'].includes(resultat.str_id?.code) ?
                                                                                    <>
                                                                                        <span>{resultat?.str_id?.libelle}</span>
                                                                                    </>
                                                                                    :
                                                                                    <div {...(resultat.id && { className: 'table-clickable-cell', onClick: () => this.onDailyDetailClick(resultat.id) })} className="text-right">
                                                                                        {displayValueByStatus(echantillon.ste_id, resultat.apr_id ? resultat.apr_id?.libelle : `${resultat?.signe || ''}${numberFormat(resultat.valeur, resultat.cri_id?.nb_decimales)}`)}
                                                                                    </div>
                                                                            }
                                                                        </>
                                                                        : ""
                                                                }
                                                            </React.Fragment>)
                                                        }
                                                    </TABLE.TD>
                                                )
                                            }
                                        </TABLE.TR>
                                    ))
                                }
                            </TABLE.TBODY>
                        </TABLE>
                        <SeeMoreButton behaviour="scroll-mobile" paginate={this.state.paginate} onClick={() => this.getEchantillons(true, true)} disabled={this.state.isLoading} />
                    </>)}
                </>)}
                {this.state.detailsModal && (
                    <DetailsModal echDetails={this.state.detailsModal} onClose={() => this.setState({ detailsModal: null })} />
                )}
                {this.state.dailyDetailsModal && (
                    <DailyDetailsModal dailyDetails={this.state.dailyDetailsModal} onClose={() => this.setState({ dailyDetailsModal: null })} />
                )}
            </>
        )
    }
}

export default withTranslation()(withRouter(ResultatsCiterneList));

