import classNames from 'classnames';
import fr from 'date-fns/locale/fr';
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useCallback, useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import { confirm } from 'react-confirm-box';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import fileuploadIcon from '../assets/images/file_upload.svg';
import fileUploadIconDark from '../assets/images/file_upload_dark.svg';

registerLocale("fr", fr);

const onChange = (e: any, setMsg: any, validation: any, handle: any) => {
    const val = e.target.value
    setMsg(validation ? validation(val) : null)
    handle(e)
};

const onChangeInputSelect = (e: any, setMsg: any, validation: any, handle: any) => {
    const val = e.data.value;
    setMsg(validation ? validation(val) : null);
    handle && handle(e);
};

export const FormCheckbox = ({
    id,
    name,
    value = "",
    label,
    handle,
    disabled = false
}: any) => {
    return (
        <>
            <label>
                {label}
            </label>
            <input id={id} name={name} type="checkbox" className="checkbox" checked={value || false} onClick={handle} disabled={disabled} />
        </>
    );
}

export const FormInput = ({
    id,
    name,
    value = "",
    label,
    hideLabel = false,
    handle,
    type,
    helper,
    disabled = false,
    className = "",
    validation = undefined,
    error_message = undefined,
    isSubmitted = false,
    minLength,
    maxLength,
    isPhone = false,
    phoneOptions,
    phoneHandle,
    phoneName,
    phoneValue,
    phonePlaceholder,
    required = false,
    onClear = null,
    validator_type = "string",
    simpleValidator = null,
    light = false,
    info = null,
    isTextArea = false,
    allowTextAreaVerticalResize = false,
    children
}: any) => {

    const [thisType, setThisType] = useState(type);
    const [isFocus, setIsFocus] = useState(false);
    const [isPristine, setIsPristine] = useState(isSubmitted ? false : value.length === 0);
    const [isBlur, setIsBlur] = useState(isSubmitted ? true : false);
    const [msg, setMsg]: any = useState(null);
    const [isDirty, setIsDirty] = useState(value.length > 0);

    if (disabled)
        validation = undefined;

    let validator_msg = "";
    let isFieldValid = true;
    let validator_name = "";
    let validatorIndicatif_msg = "";
    let isFieldValidIndicatif = true;
    if (!validation && simpleValidator) {
        validator_name = label.toLowerCase().replace('*', '');

        if (required) validator_type += `|required`;
        if (minLength && maxLength && minLength === maxLength) {
            validator_type += `|size:${minLength}`;
        } else {
            if (minLength) validator_type += `|min:${minLength}`;
            if (maxLength) validator_type += `|max:${maxLength}`;
        }


        if (isPhone) {
            validatorIndicatif_msg = simpleValidator.message(validator_name + " indicatif", phoneValue + "|" + value, "indicatif", { className: classNames({ 'invalid-feedback': true, 'light': light }) })
            isFieldValidIndicatif = simpleValidator.fieldValid(validator_name + " indicatif");
        }

        validator_msg = simpleValidator.message(validator_name, value, validator_type, { className: classNames({ 'invalid-feedback': true, 'light': light }) })
        isFieldValid = simpleValidator.fieldValid(validator_name);
    }


    const onInput = (e: any) => {
        onChange(e, setMsg, validation, handle);
    }

    const onBlur = () => {
        setIsFocus(false);
        setIsPristine(false);
        setIsBlur(true);
        if (simpleValidator) simpleValidator.showMessageFor(validator_name);
        if (simpleValidator && isPhone) simpleValidator.showMessageFor(validator_name + " indicatif");
    }

    const showPassword = () => {
        setThisType(thisType === 'password' ? 'text' : 'password')
    }

    useEffect(() => {
        setIsDirty(value.length > 0)
    }, [value])

    useEffect(() => {
        if (isSubmitted && simpleValidator) {
            simpleValidator.showMessageFor(validator_name);
        }
        if (isSubmitted && simpleValidator && isPhone) {
            simpleValidator.showMessageFor(validator_name + " indicatif");
        }
        setIsPristine(isSubmitted ? false : value.length === 0);
        setIsBlur(isSubmitted ? true : false);
        setMsg(validation ? validation(value) : null);
    }, [validation, isSubmitted, value, simpleValidator, validator_name, isPhone])

    const customStyles = {
        indicatorSeparator: (): any => { },
        dropdownIndicator: (defaultStyles: any) => ({
            ...defaultStyles,
            '& svg': { display: 'none' }
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            width: state.selectProps.width,
            borderBottom: '1px dotted pink',
            color: state.selectProps.menuColor,
        }),
        input: (defaultStyles: any) => ({
            ...defaultStyles,
            color: '#0C7FB8',
            marginTop: '5px',
        }),
        placeholder: (defaultStyles: any) => ({
            ...defaultStyles,
            marginTop: '5px'
        }),
        control: (_: any, { selectProps: { width } }: any) => ({
            width: width,
            minWidth: '42px',
            maxWidth: '60px',
            height: 40,
            backgroundColor: light ? '#FFFFFF' : '#C7F5FC',
            ...(disabled && { backgroundColor: '#efefee' }),
            border: '2px solid transparent',
            borderRadius: '3px',
            marginRight: '10px',
            marginBottom: 2,
        }),
        singleValue: (provided: any, state: any) =>
        ({
            ...provided,
            opacity: state.isDisabled ? 0.5 : 1,
            transition: 'opacity 300ms',
            color: '#0C7FB8',
            marginTop: '5px',
            marginLeft: 0,
            marginRight: 0,
            fontWeight: 700
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.isSelected ? '#FFF' : '#0C7FB8',
            fontWeight: 700
        })
    }

    let display_as_error = false;
    if (error_message) display_as_error = true;
    if (!light) {
        if (isBlur && !isPristine && (!isFieldValid || !isFieldValidIndicatif)) display_as_error = true;
        if (!isPristine && msg && msg.type === "erreur") display_as_error = true;
    }

    return (
        <>
            <label
                className={classNames({
                    error: display_as_error,
                    'sr-only': hideLabel,
                })}
                htmlFor={id}
            >
                {label}{required && ' *'}
                {info && <i className="icon-info" data-place={info.placement} data-type="dark" data-tip={info.text}></i>}
            </label>
            {isPhone && (
                <Select
                    isDisabled={disabled}
                    options={phoneOptions}
                    placeholder={disabled ? '' : phonePlaceholder}
                    onChange={(value: any, action: any) => phoneHandle({ data: value, action: action })}
                    value={phoneOptions.filter((obj: { label: string, value: string }) => obj.value === phoneValue)}
                    styles={customStyles}
                    name={phoneName}
                />
            )}
            <div className={classNames({
                'input-group': true,
                valid: !isPristine && msg && msg.type === "succes",
                error: (isBlur && !isPristine && (!isFieldValid || !isFieldValidIndicatif)) || (!isPristine && msg && msg.type === "erreur") || error_message,
                ...(className && { [className]: true }),
                focus: isFocus,
                dirty: isDirty
            })}>

                {!isTextArea ? (
                    <input
                        type={thisType}
                        id={id}
                        name={name}
                        className="form-control"
                        disabled={disabled}
                        value={value}
                        onInput={onInput}
                        onBlur={onBlur}
                        {...(maxLength && { maxLength: maxLength })}
                    />
                ) : (
                    <textarea
                        id={id}
                        name={name}
                        className={classNames({
                            'form-control': true,
                            'form-control__textarea': true,
                            'vertical-resize': allowTextAreaVerticalResize,
                            'is-invalid': (isBlur && !isPristine && (!isFieldValid || !isFieldValidIndicatif)) || (!isPristine && msg && msg.type === "erreur") || error_message,
                        })}
                        disabled={disabled}
                        value={value}
                        onInput={onInput}
                        onBlur={onBlur}
                        {...(maxLength && { maxLength: maxLength })}
                    >
                    </textarea>
                )}

                {type === 'password' && (
                    <div className="input-group-append">
                        <button type="button" onClick={showPassword}>
                            <span className="sr-only">Voir le mot de passe</span>
                            {thisType === 'password' ? <i className="icon-eye"></i> : <i className="icon-eye-close"></i>}
                        </button>
                    </div>
                )}

                {onClear && value.length > 0 && (
                    <div className="input-group-append">
                        <button type="button" value="" onClick={onClear}>
                            <span className="sr-only">Effacer</span>
                            <i className="icon-trash"></i>
                        </button>
                    </div>
                )}

                {helper &&
                    <div className="input-group-append">
                        <button type="button" data-tip={helper}>
                            <span className="sr-only">Information</span>
                            <i className="icon-info"></i>
                        </button>
                    </div>
                }
            </div>
            {children}
            {(!isPristine && msg && msg.type === "erreur") && <div className="invalid-feedback">{msg.message}</div>}
            {error_message && <div className="invalid-feedback">{error_message}</div>}
            {simpleValidator && !disabled && validator_msg}
            {simpleValidator && !disabled && isPhone && validatorIndicatif_msg}
            {/*{info && <div className="infos-feedback">{info}</div>}*/}
        </>
    );
}

export const FormInputWithSelect = ({
    id,
    name,
    value = "",
    label,
    handle,
    type,
    helper,
    disabled = false,
    className = "",
    validation,
    isSubmitted = false,
    minLength,
    maxLength,
    selectDisabled = false,
    selectOptions,
    selectHandle,
    selectName,
    selectValue,
    required = false,
    validator_type = "alpha_num_dash_space",
    simpleValidator = null,
    info = null,
    style = { small: false, large: false },
    selectPlaceholder }: any) => {
    const [thisType, setThisType] = useState(type);
    const [isFocus, setIsFocus] = useState(false);
    const [isPristine, setIsPristine] = useState(isSubmitted ? false : value.length === 0);
    const [msg, setMsg]: any = useState(null);
    const [isBlur, setIsBlur] = useState(isSubmitted ? true : false);
    const [isDirty, setIsDirty] = useState(value.length > 0);

    if (disabled)
        validation = undefined;

    let validator_msg = "";
    let isFieldValid = true;
    let validator_name = "";
    if (!validation && simpleValidator) {
        validator_name = label.toLowerCase().replace('*', '');
        validator_msg = simpleValidator.message(
            validator_name,
            value,
            validator_type +
            (required ? '|required' : "") +
            (minLength ? '|min:' + minLength : "") +
            (maxLength ? '|max:' + maxLength : ""),
            { className: 'invalid-feedback' }
        );
        isFieldValid = simpleValidator.fieldValid(validator_name);
    }
    const onInput = (e: any) => {
        onChange(e, setMsg, validation, handle);
    }

    const onBlur = () => {
        setIsBlur(true);
        setIsFocus(false);
        setIsPristine(false);
        if (simpleValidator) simpleValidator.showMessageFor(validator_name)
    }

    const showPassword = () => {
        setThisType(thisType === 'password' ? 'text' : 'password')
    }

    useEffect(() => {
        setIsDirty(value.length > 0)
    }, [value])

    useEffect(() => {
        if (isSubmitted && simpleValidator) {
            simpleValidator.showMessageFor(validator_name);
        }
        setIsPristine(isSubmitted ? false : value.length === 0)
        setMsg(validation ? validation(value) : null)
        setIsBlur(isSubmitted ? true : false);
    }, [validation, isSubmitted, value, simpleValidator, validator_name])

    const customStyles = {
        container: (defaultStyles: any) => ({
            ...defaultStyles,
            padding: 0,
            marginRight: '5px',
        }),
        indicatorSeparator: (): any => { },
        dropdownIndicator: (defaultStyles: any) => ({
            ...defaultStyles,
            padding: 0
        }),
        input: (defaultStyles: any) => ({
            ...defaultStyles,
            padding: 0,
            margin: 0,
        }),
        valueContainer: (defaultStyles: any) => ({
            ...defaultStyles,
            padding: 0,
            margin: 0
        }),
        singleValue: (defaultStyles: any) => ({
            ...defaultStyles,
            margin: 0,
            fontSize: '14px',
            color: 'inherit'
        }),
        placeholder: (defaultStyles: any) => ({ ...defaultStyles, fontSize: 14 }),
        control: (defaultStyles: any) => ({
            ...defaultStyles,
            borderBottom: '2px solid #0C7FB8',
            borderRadius: '0',
            width: style.large ? 'max-content' : '45px',
            height: style.small ? '32px' : '40px',
            minHeight: 'unset',
            backgroundColor: 'white',
            opacity: (disabled || selectDisabled) ? 0.5 : 1,
            borderWidth: 0,
            borderColor: 'transparent',
            boxShadow: '0px 0px 0px transparent',
            padding: '0 10px',
            "&:hover": {
                borderWidth: 0,
                borderColor: 'transparent',
                boxShadow: '0px 0px 0px transparent',
                borderBottom: '2px solid #0C7FB8',
                borderRadius: '0',
            }
        }),
    }

    return (
        <>
            <label
                className={classNames({
                    error: (isBlur && !isPristine && !isFieldValid) || (!isPristine && msg && msg.type === "erreur"),
                })}
                htmlFor={id}
            >
                {label}{required && ' *'}
            </label>

            <div className="form-input-select">

                <Select
                    isDisabled={disabled || selectDisabled}
                    options={selectOptions}
                    placeholder={selectPlaceholder}
                    onChange={(value: any, action: any) => selectHandle({ data: value, action: action })}
                    value={selectOptions.filter((obj: { label: string, value: string }) => obj.value === selectValue)}
                    styles={customStyles}
                    name={selectName}
                />

                <div className={classNames({
                    'input-group': true,
                    valid: !isPristine && msg && msg.type === "succes",
                    error: !isPristine && msg && msg.type === "erreur",
                    ...(className && { [className]: true }),
                    focus: isFocus,
                    dirty: isDirty
                })}>
                    <input
                        type={thisType}
                        id={id}
                        name={name}
                        className="form-control"
                        disabled={disabled}
                        value={value}
                        onInput={onInput}
                        onBlur={onBlur}
                    />

                    {type === 'password' && (
                        <div className="input-group-append">
                            <button type="button" onClick={showPassword}>
                                <span className="sr-only">Voir le mot de passe</span>
                                {thisType === 'password' ? <i className="icon-eye"></i> : <i className="icon-eye-close"></i>}
                            </button>
                        </div>
                    )}


                    {helper &&
                        <div className="input-group-append">
                            <button type="button" data-tip={helper}>
                                <span className="sr-only">Information</span>
                                <i className="icon-info"></i>
                            </button>
                        </div>
                    }
                </div>
            </div>
            {(!isPristine && msg && msg.type === "erreur") && <div className="invalid-feedback">{msg.message}</div>}
            {simpleValidator && !disabled && validator_msg}
            {info && <div className="infos-feedback">{info}</div>}
        </>
    );
}

const MenuListWithFooter = (props: any) => {
    return (
        <components.MenuList {...props}>
            <div className='form-input-select__footer'>Précisez votre recherche ...</div>
            {props.children}
        </components.MenuList>
    );
}

export const FormInputSelect = ({
    label,
    displayLabel = true,
    name,
    id,
    light,
    value = "",
    handle,
    handleInput,
    options,
    validation,
    isSubmitted = false,
    isMulti = false,
    placeholder = 'Sélectionner',
    noOptionsMessage = "Aucune option",
    disabled = false,
    required = false,
    confirmation = null,
    validator_type = "alpha_num_dash_space",
    simpleValidator = null,
    hideValidatorMsg = false,
    isClearable = false,
    disabledDropdownWhenEmpty = false,
    displayValueNotLabel = false,
    maxMenuHeight,
    info = null,
    title = null,
    selectedId = null }: any) => {

    const [isPristine, setIsPristine] = useState(isSubmitted ? false : value.length === 0);
    const [msg, setMsg]: any = useState(null);
    const [isFocus, setIsFocus] = useState(false);
    const [isBlur, setIsBlur] = useState(isSubmitted ? true : false);

    if (disabled)
        validation = undefined;

    let validator_msg = "";
    let isFieldValid = true;
    let validator_name = "";
    if (!validation && !disabled && simpleValidator) {
        validator_name = label.toLowerCase().replace('*', '');
        validator_msg = simpleValidator.message(
            validator_name,
            value,
            validator_type +
            (required ? '|required' : ""),
            { className: light ? 'invalid-feedback light' : 'invalid-feedback' }
        );
        isFieldValid = simpleValidator.fieldValid(validator_name);
    }

    const handleChange = async (e: any) => {
        if (confirmation) {
            const isConfirmed: boolean = await confirm(confirmation, { labels: { confirmable: "Continuer", cancellable: "Annuler" } })
            if (!isConfirmed) return
        }

        onChangeInputSelect(e, setMsg, validation, handle);
    }

    useEffect(() => {
        if (isSubmitted && simpleValidator) {
            simpleValidator.showMessageFor(validator_name);
        }
        setIsPristine(isSubmitted ? false : value.length === 0)
        setIsBlur(isSubmitted ? true : false);
        setMsg(validation ? validation(value) : null)
    }, [validation, isSubmitted, value, simpleValidator, validator_name, selectedId])

    const customStyles = {
        container: (defaultStyles: any) => ({
            ...defaultStyles,
            width: '100%'
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            color: 'hsl(0, 0%, 20%)',
            zIndex: '5'
        }),
        control: (defaultStyles: any) => ({
            ...defaultStyles,
            width: '100%',
            backgroundColor: disabled ? '#efefee' : 'white',
            borderWidth: 0,
            borderColor: 'transparent',
            boxShadow: '0px 0px 0px transparent',
            "&:hover": {
                borderWidth: 0,
                borderColor: 'transparent',
                boxShadow: '0px 0px 0px transparent'
            }
        }),
    }

    const renderSelectComponents = () => {
        if (disabledDropdownWhenEmpty && !options.length && !handleInput) {
            return {
                Menu: () => null,
                MenuList: () => null,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
            };
        }

        return {
            ...(handleInput && { MenuList: MenuListWithFooter })
        };
    }

    const onChange = (val: any, action: any,) => {
        if (action.action === "clear") {
            handleInput && handleInput({ data: { value: "" }, action });
        }
        handleChange({ data: val || { value: "" }, action });
    }

    const onInputChange = (val: any, action: any) => {
        if (action.action === 'input-blur' && !value) {
            handleChange({ data: { value: "" }, action });
        } else if (action.action === 'input-change') {
            handleInput && handleInput({ data: val || { value: "" }, action });
        }
    }

    let display_as_error = false;
    if (!light) {
        if (isBlur && !isPristine && !isFieldValid) display_as_error = true;
        if (!isPristine && msg && msg.type === "erreur") display_as_error = true;
    }

    return (
        <>
            {label && displayLabel && (<>
                <label
                    className={classNames({
                        error: display_as_error,
                    })}
                    htmlFor={id}
                >
                    {label}{required && ' *'}
                    {info && <i className="icon-info" data-place={info.placement} data-type="dark" data-tip={info.text}></i>}
                </label>
            </>)}
            <div className={classNames({
                'input-group': true,
                valid: !isPristine && msg && msg.type === "succes",
                error: (isBlur && !isPristine && !isFieldValid) || (!isPristine && msg && msg.type === "erreur"),
            })}
                {...(title && { title: title })}
            >
                <Select
                    maxMenuHeight={maxMenuHeight}
                    id={id}
                    isMulti={isMulti}
                    options={options}
                    placeholder={placeholder}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    value={options.filter((obj: { label: string, value: string, id?: number }) => isMulti ? value.includes(obj.value) : !!selectedId ? selectedId === obj.id : obj.value === value)}
                    styles={customStyles}
                    name={name}
                    noOptionsMessage={() => noOptionsMessage}
                    onBlur={() => {
                        setIsFocus(false);
                        setIsPristine(false);
                        setIsBlur(true);
                        if (simpleValidator)
                            simpleValidator.showMessageFor(validator_name);
                    }}
                    className={classNames({
                        focus: isFocus
                    })}
                    formatOptionLabel={displayValueNotLabel ? ((option: any, { context }) => context === 'value' ? option.value : option.label) : undefined}
                    isDisabled={disabled}
                    isClearable={isClearable}
                    components={renderSelectComponents()}
                />
            </div>
            {(required && !isPristine && msg && msg.type === "erreur") && <div className={classNames({ "invalid-feedback": true, "light": light })}>Champs obligatoire</div>}
            {!hideValidatorMsg && simpleValidator && validator_msg}
        </>
    )
}

export const FormInputSelectCreatable = ({
    label,
    name,
    id,
    light,
    value = "",
    handle,
    handleCreateOption,
    handleInput,
    optionsName,
    options,
    validation,
    isMulti = false,
    isSubmitted = false,
    placeholder = 'Sélectionner',
    noOptionsMessage = "pas d'option",
    createMessage = "Sélectionner",
    isDropdownDisabled = false,
    disabled = false,
    required = false,
    isClearable = false,
    validator_type = "alpha_num_dash_space",
    simpleValidator = null,
    displayValueNotLabel = false,
    disabledDropdownWhenEmpty = false }: any) => {

    const [isPristine, setIsPristine] = useState(isSubmitted ? false : value.length === 0);
    const [msg, setMsg]: any = useState(null);
    const [isFocus, setIsFocus] = useState(false);
    const [isBlur, setIsBlur] = useState(isSubmitted ? true : false);

    if (disabled)
        validation = undefined;

    let validator_msg = "";
    let isFieldValid = true;
    let validator_name = "";
    if (!validation && !disabled && simpleValidator) {
        validator_name = label.toLowerCase().replace('*', '');
        validator_msg = simpleValidator.message(
            validator_name,
            value,
            validator_type +
            (required ? '|required' : ""),
            { className: light ? 'invalid-feedback light' : 'invalid-feedback' }
        );
        isFieldValid = simpleValidator.fieldValid(validator_name);
    }

    const handleChange = (e: any) => {
        onChangeInputSelect(e, setMsg, validation, handle);
    }

    const handleCreate = (e: any) => {
        onChangeInputSelect(e, setMsg, validation, handleCreateOption);
    }

    useEffect(() => {
        if (isSubmitted && simpleValidator) {
            simpleValidator.showMessageFor(validator_name);
        }
        setIsPristine(isSubmitted ? false : value.length === 0)
        setIsBlur(isSubmitted ? true : false);
        setMsg(validation ? validation(value) : null)
    }, [validation, isSubmitted, value, simpleValidator, validator_name])

    const customStyles = {
        container: (defaultStyles: any) => ({
            ...defaultStyles,
            width: '100%'
        }),
        control: (defaultStyles: any) => ({
            ...defaultStyles,
            width: '100%',
            backgroundColor: disabled ? '#efefee' : 'white',
            borderWidth: 0,
            borderColor: 'transparent',
            boxShadow: '0px 0px 0px transparent',
            "&:hover": {
                borderWidth: 0,
                borderColor: 'transparent',
                boxShadow: '0px 0px 0px transparent'
            }
        }),
    }

    const renderSelectComponents = () => {
        if (isDropdownDisabled || (disabledDropdownWhenEmpty && !options.length && !handleInput)) {
            return {
                Menu: () => null,
                MenuList: () => null,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null
            };
        }

        return {
            ...(handleInput && { MenuList: MenuListWithFooter })
        };
    }

    const onChange = (val: any, action: any,) => {
        if (action.action === "clear") {
            handleInput && handleInput({ data: { value: "" }, action });
        }
        handleChange({ data: val || { value: "" }, action });
    }

    const onInputChange = (val: any, action: any) => {
        if (action.action === 'input-blur' && !value) {
            handleChange({ data: { value: "" }, action });
        }

        if (action.action === 'input-change') {
            handleInput && handleInput({ data: val || { value: "" }, action });
        }
    }

    let display_as_error = false;
    if (!light) {
        if (isBlur && !isPristine && !isFieldValid) display_as_error = true;
        if (!isPristine && msg && msg.type === "erreur") display_as_error = true;
    }

    return (
        <>
            <label
                className={classNames({
                    error: display_as_error,
                })}
                htmlFor={id}
            >
                {label}{required && ' *'}
            </label>
            <div className={classNames({
                'input-group': true,
                valid: !isPristine && msg && msg.type === "succes",
                error: (isBlur && !isPristine && !isFieldValid) || (!isPristine && msg && msg.type === "erreur"),
            })}>
                <CreatableSelect
                    id={id}
                    options={options}
                    isMulti={isMulti}
                    placeholder={placeholder}
                    onChange={onChange}
                    onCreateOption={(value: any) => handleCreate({ data: { value: value ? value.toUpperCase() : { value: "" } }, action: { optionsName: optionsName, name: name } })}
                    onInputChange={onInputChange}
                    formatCreateLabel={(e: string) => `${createMessage} ${e.toUpperCase()}`}
                    formatOptionLabel={displayValueNotLabel ? ((option: any, { context }) => context === 'value' ? option.value : option.label) : undefined}
                    value={options.filter((obj: { label: string, value: string }) => obj.value === value) || value}
                    styles={customStyles}
                    name={name}
                    noOptionsMessage={() => noOptionsMessage}
                    onBlur={(e) => {
                        const value = e?.target?.value || '';
                        if (!!value && !options.find((option: any) => option.value.localeCompare(value, undefined, { sensitivity: 'base' }) === 0)) {
                            handleCreate({ data: { value: value.toUpperCase() }, action: { optionsName: optionsName, name: name } });
                        }
                        setIsFocus(false);
                        setIsPristine(false);
                        setIsBlur(true);
                        if (simpleValidator)
                            simpleValidator.showMessageFor(validator_name);
                    }}
                    className={classNames({
                        focus: isFocus
                    })}
                    components={renderSelectComponents()}
                    isDisabled={disabled}
                    isClearable={isClearable}
                />
            </div>
            {(required && !isPristine && msg && msg.type === "erreur") && <div className={classNames({ "invalid-feedback": true, "light": light })}>Champs obligatoire</div>}
            {simpleValidator && validator_msg}
        </>
    )
}


export const FormSelect = ({
    label,
    name,
    id,
    value = "",
    handle,
    options,
    required,
    validation,
    isSubmitted = false,
    disabled = false,
    validator_type = "string",
    simpleValidator = null,
    light = false,
}: any) => {

    const [isPristine, setIsPristine] = useState(isSubmitted ? false : value.length === 0);
    const [msg, setMsg]: any = useState(null);
    const [isFocus, setIsFocus] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    options = options.map((option: any) => typeof option === 'string' ? { value: option, name: option } : option)

    if (disabled)
        validation = undefined;

    let isFieldValid = true;
    let validator_name = '';
    if (!validation && !disabled && simpleValidator) {
        validator_name = label.toLowerCase().replace('*', '');
        simpleValidator.message(
            validator_name,
            value,
            validator_type +
            (required ? '|required' : ''),
            { className: 'invalid-feedback' }
        );
        isFieldValid = simpleValidator.fieldValid(validator_name);
    }

    const handleChange = (e: any) => {
        onChange(e, setMsg, validation, handle);
    }

    useEffect(() => {
        setIsPristine(isSubmitted ? false : value.length === 0)
        setMsg(validation ? validation(value) : null)
    }, [validation, isSubmitted, value, simpleValidator, validator_name])

    const onBlur = () => {
        setIsFocus(false);
        setIsPristine(false);
        if (simpleValidator) setErrorMessage(simpleValidator.getErrorMessages()[validator_name])
    }

    return (
        <>
            <label
                className={classNames({
                    error: !light && ((!isPristine && msg && msg.type === "erreur") || (!isPristine && !isFieldValid)),
                })}
                htmlFor={id}
            >
                {label}{required && ' *'}
            </label>
            <div className={classNames({
                'input-group': true,
                valid: !isPristine && msg && msg.type === "succes",
                error: !isPristine && msg && msg.type === "erreur",
            })}>
                <select
                    name={name}
                    id={id}
                    value={value}
                    onChange={handleChange}
                    className={classNames({
                        'form-control': true,
                        focus: isFocus
                    })}
                    onBlur={onBlur}
                    disabled={disabled}
                >
                    <option value="">Sélectionner</option>
                    {options.map((option: any) => (
                        <option key={`${option.name}-${option.value}`} value={option.value}>{option.name}</option>
                    ))}
                </select>
            </div>
            {(required && !isPristine && msg && msg.type === "erreur") && <div className="invalid-feedback">Champs obligatoire</div>}
            {!!errorMessage && <div className={classNames({ 'invalid-feedback': true, 'light': light })}>{errorMessage}</div>}
        </>
    )
}

interface FileUploadProps {
    id: string
    name: string
    handle: Function
    validation: Function
    types: string[]
    maxSize: number
    disabled?: boolean
    variant?: 'light' | 'dark'
    maxFileNumber?: number
    initialData?: File[]
}

export const FileUpload = ({ id, name, handle, validation, types, maxSize, disabled = false, variant = 'light', maxFileNumber = 1, initialData }: FileUploadProps) => {
    const [files, setFiles] = useState<any[]>([])
    const [error, setError] = useState('')

    const uploadIconVariants = { 'light': fileuploadIcon, 'dark': fileUploadIconDark }
    const multipleFiles = maxFileNumber > 1;
    const sizeFormat = maxSize >= 1 ? `${maxSize} Mo` : `${maxSize * 1000} Ko`;

    useEffect(() => {
        if (initialData && initialData?.length > 0) setFiles(initialData);
    }, [initialData]);

    useEffect(() => {
        if (error && handle) handle(null)
    }, [error, handle])

    const onFileChange = (e: any) => {
        const newFiles = [...(e?.target.files || [])]
        const updatedFileList = multipleFiles ? [...files, ...newFiles] : newFiles;
        // Do not update when cancel button is triggered
        if (!newFiles?.length) return

        if (!disabled && validation) {
            const validation_error = validation(updatedFileList)
            setError(validation_error)

            if (validation_error) {
                return false
            }
        }
        setFiles(updatedFileList)
        if (handle) handle(multipleFiles ? updatedFileList : updatedFileList[0])
    }

    const onRemoveFile = (fileIndex: number) => {
        const updatedFileList = files.filter((file: File, index: number) => index !== fileIndex)
        setFiles(updatedFileList)
        if (handle) handle(multipleFiles ? updatedFileList : updatedFileList[0] || null)
    }

    return (
        <div className={classNames({
            'file-upload': true,
            'file-upload--white': variant === 'light',
            'file-upload--dark': variant === 'dark',
        })}>
            <div className={classNames({ 'file-upload__dropzone': true, 'disabled': disabled })}>
                <label className="file-upload__title" htmlFor={id}>Déposez votre fichier ici</label>
                <img className="file-upload__icon" src={uploadIconVariants[variant]} alt="" />
                <span>ou</span>
                <span className="btn btn-secondary file-upload__btn">Sélectionnez un fichier</span>
                <div className="file-upload__helper">
                    {types && (<span>Format(s) autorisé(s): {types.join(', ')}</span>)}
                    {types && (<span>Maximum: {sizeFormat}</span>)}
                </div>
                {maxFileNumber > 1 && (<span>Nombre maximum de fichiers: {maxFileNumber}</span>)}
                <input
                    multiple={multipleFiles}
                    id={id}
                    name={name}
                    onChange={onFileChange}
                    type="file"
                    disabled={disabled}
                    className="file-upload__input"
                />
            </div>
            {error && (
                <div className="file-upload__error">{error}</div>
            )}
            {files.length > 0 && (
                <ul>
                    {files.map((file: File, index: number) => (
                        <li key={file.name} className="file-upload__file">
                            <span>{file.name}</span>
                            <button type="button" title="Supprimer le fichier" onClick={() => onRemoveFile(index)}>
                                <span className="sr-only">Supprimer</span>
                                <i className="icon-trash align-middle"></i>
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export const FormMultiCheckBox = ({ label, name, options, id, values, handle, isSubmitted, disabled, simpleValidator, validator_type, required }: any) => {
    const [dirty, setDirty] = useState(false)

    const handleChange = (e: any) => {
        handle && handle(e)
        setDirty(true)
    }

    useEffect(() => {
        if (isSubmitted) setDirty(true);
    }, [isSubmitted])

    let errorMessage = ''
    if (!disabled && simpleValidator) {
        const validator_name = label.toLowerCase().replace('*', '');
        const rules = [
            ...(validator_type ? [validator_type] : []),
            ...(required ? ['required'] : [])
        ].join('|')

        if (!!rules) {
            simpleValidator.message(validator_name, values, rules)
            errorMessage = simpleValidator.getErrorMessages()[validator_name]
        }
    }

    return (<>
        <label htmlFor={id}>{label}{required && ' *'}</label>
        <div className="input-group unstyled">
            {options.map((option: any, index: number) => (
                <div className="form-check form-check-inline" key={index}>
                    <input className="form-check-input checkbox" checked={values.includes(option.value)} type="checkbox" id={`${id}-${index}`} name={name} onChange={handleChange} disabled={disabled || option.disabled} value={option.value} />
                    <label className="form-check-label" htmlFor={`${id}-${index}`}>{option.label}</label>
                </div>
            ))}
        </div>
        {!!dirty && !!errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
    </>)
}

export const FormRadioValues = ({ label, name, options, id, value = "", handle, info, isSubmitted = false, disabled = false, required = false, simpleValidator = null }: any) => {
    const [hasError, setHasError] = useState<boolean>(false);

    let validatorName: string = '';
    let validatorMessage;
    if (simpleValidator) {
        validatorName = label.toLowerCase();
        validatorMessage = simpleValidator.message(validatorName, value, 'required', { className: 'invalid-feedback' });
    }

    const handleChange = (e: any) => {
        handle && handle(e)
    }

    useEffect(() => {
        if (isSubmitted && required && simpleValidator) {
            simpleValidator.showMessageFor(validatorName);
            setHasError(!simpleValidator.fieldValid(validatorName));
        }
    }, [isSubmitted, required, simpleValidator, validatorName, value]);

    return (<>
        <label htmlFor={id} className={classNames({ 'error': hasError })}>
            {label}{required && ' *'}
            {info && <i className="icon-info" data-place={info.placement} data-type="dark" data-tip={info.text}></i>}
        </label>
        <div className="input-group unstyled">
            {options.map((option: any, index: number) => (
                <div className="form-check form-check-inline" key={index}>
                    <input className="form-check-input radio" checked={value === option.value} type="radio" id={`${id}-${index}`} name={name} onChange={handleChange} disabled={disabled || option?.disabled} value={option.value} />
                    <label className="form-check-label" htmlFor={`${id}-${index}`}>{option.label}</label>
                </div>
            ))}
        </div>
        {simpleValidator && !disabled && validatorMessage}
    </>)
}

export const FormRadioBoolean = ({ label, name, id, value = "", handle, isSubmitted = false, trueLabel = "Oui", falseLabel = "Non", disabled = false, required = false }: any) => {
    const handleChange = (e: any) => {
        handle && handle(e)
    }

    return (<>
        <label htmlFor={id}>{label}{required && ' *'}</label>
        <div className="input-group unstyled">
            <div className="form-check form-check-inline">
                <input className="form-check-input radio" checked={value === '1'} type="radio" id={id} name={name} onChange={handleChange} disabled={disabled} value="1" />
                <label className="form-check-label" htmlFor={id}>{trueLabel}</label>
            </div>
            <div className="form-check form-check-inline m-l-20">
                <input className="form-check-input radio" checked={value === '0'} type="radio" id={id} name={name} onChange={handleChange} disabled={disabled} value="0" />
                <label className="form-check-label" htmlFor={id}>{falseLabel}</label>
            </div>
        </div>
    </>)
}


export const FormDatePicker = ({ label, name, id, value = null, handle, required, validation, isSubmitted = false, disabled = false, isClearable, month, isTH = false, placeholder }: any) => {
    const [isPristine, setIsPristine] = useState(isSubmitted ? false : !value || (value && value.length === 0));
    const [msg, setMsg]: any = useState(null);
    const [startDate, setStartDate] = useState(value);

    const convertLocalToUTCDate = (date: Date) => {
        if (!date) return date
        date = new Date(date)
        date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        return date
    }

    if (disabled)
        validation = undefined;

    const handleChange = (date: Date) => {
        const utc_date = convertLocalToUTCDate(date)

        handle({ data: { value: utc_date }, action: { name: name } })
        setStartDate(utc_date)
    }

    useEffect(() => {
        const utc_date = convertLocalToUTCDate(value)

        setIsPristine(isSubmitted ? false : !utc_date)
        setMsg(validation ? validation(utc_date) : null)
        setStartDate(utc_date)
    }, [validation, isSubmitted, value])

    return (
        <>
            {!isTH && (
                <label
                    className={classNames({
                        error: !isPristine && msg && msg.type === "erreur",
                    })}
                    htmlFor={id}
                >
                    {label}{required && ' *'}
                </label>
            )}
            <div className={classNames({
                'input-group': true,
                'date': true,
                valid: !isPristine && msg && msg.type === "succes",
                error: !isPristine && msg && msg.type === "erreur",
            })}>
                <DatePicker
                    isClearable={isClearable}
                    required={required}
                    locale="fr"
                    className={classNames({
                        'form-control': !isTH,
                        'table-input': isTH,
                    })}
                    clearButtonClassName={isTH ? 'clear-button-no-background' : ''}
                    name={name}
                    id={id}
                    selected={startDate}
                    onChange={handleChange}
                    disabled={disabled}
                    showMonthYearPicker={month}
                    placeholderText={placeholder || ''}
                    dateFormat={month ? 'MM/yyyy' : "dd/MM/yyyy"}
                    popperProps={{ strategy: 'fixed' }}
                />
            </div>
            {(!isPristine && msg && msg?.type === "erreur") && <div className="invalid-feedback">{msg?.message || (required ? 'Champ obligatoire' : 'Champ invalide')}</div>}
        </>
    )
}


export const FormDropdownCheckbox = ({
    label,
    name,
    value = "",
    handle,
    options,
    validation,
    isSubmitted = false,
    disabled = false,
    required = false,
    validator_type = "alpha_num_dash_space",
    confirmation = null,
    simpleValidator = null,
    validator_name = "" }: any) => {
    const [isPristine, setIsPristine] = useState(isSubmitted ? false : value.length === 0);
    const [isBlur, setIsBlur] = useState(isSubmitted ? true : false);
    const [msg, setMsg]: any = useState(null);
    const [isFocus, setIsFocus] = useState(false);

    const [checked, setChecked] = useState(value ? [...value] : []);

    let validator_msg = "";
    let isFieldValid = true;
    if (!validation && !disabled && simpleValidator) {
        validator_name = validator_name || label.toLowerCase().replace('*', '');
        validator_msg = simpleValidator.message(
            validator_name,
            value,
            validator_type +
            (required ? '|required' : ""),
            { className: 'dropdown-invalid-feedback' }
        );
        isFieldValid = simpleValidator.fieldValid(validator_name);
    }

    if (isSubmitted && simpleValidator) {
        simpleValidator.showMessageFor(validator_name);
    }

    const handleCheck = async (event: any) => {
        let updatedList = [...checked];
        let val = event.target.value;
        // eslint-disable-next-line eqeqeq
        let data = options.filter((obj: any) => obj.value == val);
        if (updatedList.length === 1) {
            // eslint-disable-next-line eqeqeq
            let last_value = options.filter((obj: any) => obj.value == updatedList[0]);
            if (last_value.length > 0 && 'comb_possible' in last_value[0] && !last_value[0].comb_possible) {
                updatedList = [];
            }
        }

        if (data.length > 0 && 'comb_possible' in data[0] && !data[0].comb_possible) {
            updatedList = [];
        }

        if (event.target.checked) {
            updatedList.push(val);
        } else {
            updatedList.splice(checked.indexOf(val), 1);
        }

        if (confirmation) {
            const isConfirmed: boolean = await confirm(confirmation, { labels: { confirmable: "Continuer", cancellable: "Annuler" } })
            if (!isConfirmed) return
        }

        setChecked(updatedList);
        handle && handle({ data: { value: updatedList }, action: { name: name } });
    };

    useEffect(() => {
        setIsPristine(isSubmitted ? false : value.length === 0)
        setIsBlur(isSubmitted ? true : false);
        setMsg(validation ? validation(value) : null)
    }, [validation, isSubmitted, value]);

    return (
        <>
            <Dropdown bsPrefix="dropdown-checkbox mr-auto m-b-20" autoClose="outside"
                onBlur={() => {
                    setIsFocus(false);
                    setIsPristine(false);
                    setIsBlur(true);
                    if (simpleValidator)
                        simpleValidator.showMessageFor(validator_name);
                }}>
                <Dropdown.Toggle bsPrefix="dropdown-checkbox__toggle" variant=""
                    className={classNames({
                        error: (isBlur && !isPristine && !isFieldValid) || (!isPristine && msg && msg.type === "erreur"),
                        focus: isFocus
                    })}>
                    {label || 'Sélectionner'}
                </Dropdown.Toggle>
                <Dropdown.Menu as='ul' bsPrefix="dropdown-checkbox__list dropdown-menu">
                    {options.map((value: any, index: number) => (
                        <Dropdown.Item key={index} as='li'>
                            <input type="checkbox" className="checkbox" name={name} checked={checked.includes(typeof value.value === 'string' ? value.value : value.value.toString())} id={`type_entite_${index}`} value={value.value} onChange={handleCheck} />
                            <label htmlFor={`type_entite_${index}`}>{value.label}</label>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
                {validator_msg}
            </Dropdown>
        </>
    )
}

interface TextEditorProps {
    onChange: (value: string) => void;
    initialValue?: string;
    isSubmitted?: boolean;
    required?: boolean;
}
export const TextEditor = ({ onChange, initialValue, isSubmitted = false, required = false }: TextEditorProps) => {
    const { t } = useTranslation();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (!initialValue) return;

        const content = htmlToDraft(initialValue);

        if (content) {
            const contentState = ContentState.createFromBlockArray(content.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    }, [initialValue]);

    const validateRequired = useCallback((editorState: EditorState | null) => {
        if (editorState && !error) {
            const plainText = editorState.getCurrentContent().getPlainText();
            if (!plainText) {
                setError(t('error_empty', { field: (t('annonces.create.details')).toLowerCase() }));
            }
        }
    }, [error, t]);

    useEffect(() => {
        if (required && isSubmitted) {
            validateRequired(editorState);
        }

    }, [editorState, isSubmitted, required, validateRequired]);

    const onEditorStateChange = (editorState: EditorState) => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (error) setError('');

        onChange(html);
        setEditorState(editorState);
    }
    const toolbarConfig = {
        options: [
            'history',
            'blockType',
            'inline',
            'list',
            'link',
        ],
        inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3'],
        },
        list: {
            inDropdown: true,
            options: ['unordered', 'ordered', 'indent', 'outdent'],
        },
        link: {
            inDropdown: false,
            options: ['link', 'unlink'],
        },
        history: {
            inDropdown: false,
            dropdownClassName: undefined,
            options: ['undo', 'redo'],
        },
    };

    return (
        <div className="rte-container">
            <Editor
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                onBlur={(event: any, editorState?: EditorState) => {
                    required && validateRequired(editorState ?? null)
                }}
                locale="fr"
                wrapperClassName={classNames({
                    'rte-container-wrapper': true,
                    'error': !!error,
                })}
                editorClassName="rte-editor"
                toolbar={toolbarConfig}
                stripPastedStyles
            />
            {!!error && (<div className="invalid-feedback">{error}</div>)}
        </div>
    )
}
