import List from './list'
import Create from './create'
import Details from './details'
import Update from './update'

const Abonnements = {
    Create,
    List,
    Details,
    Update,
}

export default Abonnements