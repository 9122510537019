import { createBrowserHistory } from 'history'
import { Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { AnimatePresence } from "framer-motion";
import { isLoggedIn, hasPermission } from './helpers/helpers';
import Home from './views/home/home';
import Login from './views/login/login';
import ResetPassword from './views/reset_password/reset_password';
import Logout from './views/logout/logout';
import Habilitations from './views/habilitations/habilitations';
import Register from './views/register/register';
import DonneesPersonnelles from './views/donnees_personnelles/donnees_personnelles';
import MentionsLegales from './views/mentions_legales/mentions_legales';
import SimulateProfil from './views/simulate_profil/simuler_profil';
import ChangerEntite from './views/change_entite/change_entite';
import LogoutSimulated from './views/logout_simulated/logout_simulated';
import Personne from './views/annuaire/personne';
import Entite from './views/annuaire/entite';
import { NotFound, Forbidden } from './views/_error_pages';
import { connect } from 'react-redux';
import Regroupement from './views/annuaire/regroupement';
import { ResultatsCiterneList, ResultatsProductionList, ResultatsCritereList, ResultatsTourneeList, ResultatsProductionDetails } from './views/resultats';
import { Parametres } from './views/parametres';
import {
    Abonnements,
    AccueilFlux,
    ExportLaiterie,
    ImportExportResultat,
    SuiviEnvois,
    TablesDeReferences,
    ImportExportAnnuaire
} from './views/flux';
import { GererSeuilsAlerte } from './views/notifications/gerer_seuils_alerte';
import AccueilResultats from './views/resultats/AccueilResultats';
import Groupe from "./views/annuaire/groupe";
import Contacts from "./views/contacts/mes_contacts";
import UserInformations from "./views/mes_informations/user_informations";
import ChangePassword from "./views/mes_informations/change_password";
import UpdateCredentials from "./views/login/update_credentials";
import AccueilAide from './views/aide/AccueilAide';
import Aide from './views/aide/Aide';
import Annonces from "./views/annonces";
import ErrorBoundary from './views/_error_pages/ErrorBoundary';

const PUBLIC_ROUTES = [
    { path: "/", exact: true, component: Home },
    { path: '/login', component: Login },
    { path: '/logout', component: Logout },
    { path: '/account/activate/:token', component: ResetPassword },
    { path: '/password/reset/:token', component: ResetPassword },
    { path: '/password/reset', component: ResetPassword },
    { path: '/register', component: Register },
    { path: '/donnees_personnelles', component: DonneesPersonnelles },
    { path: '/mentions_legales', component: MentionsLegales },
    { path: '/habilitations', component: Habilitations },
    { path: '/update_credentials/:token', component: UpdateCredentials },
    { path: '/update_credentials', component: UpdateCredentials },
]

const PRIVATE_ROUTES = [
    { path: '/home', component: Home },
    { path: '/simulate_profil/:id', permissions: ['DRT_SIMULER_PROFIL'], component: SimulateProfil },
    { path: '/simulate_profil', permissions: ['DRT_SIMULER_PROFIL'], component: SimulateProfil },
    { path: '/logout_simulated', component: LogoutSimulated },
    { path: '/flux/imports_exports_resultats/exports', exact: true, permissions: ['DRT_FLUX_RES_EXPORT'], component: ImportExportResultat.Exports },
    { path: '/flux/imports_exports_resultats/imports', exact: true, permissions: ['DRT_FLUX_RES_IMPORT'], component: ImportExportResultat.Imports },
    { path: '/flux/imports_exports_resultats/creer_export_ponctuel', exact: true, permissions: ['DRT_FLUX_EXPORT_PONCTUEL'], component: ImportExportResultat.CreateExportPonctuel },
    { path: '/flux/imports_exports_resultats', exact: true, permissions: ['DRT_FLUX_RESULTATS'], component: ImportExportResultat.Accueil },
    { path: '/flux/exports_laiterie/creer', exact: true, permissions: ['DRT_FLUX_EXPORT_LAITERIE_GERER'], component: ExportLaiterie.Create },
    { path: '/flux/exports_laiterie/:id/modifier', exact: true, permissions: ['DRT_FLUX_EXPORT_LAITERIE_GERER'], component: ExportLaiterie.Update },
    { path: '/flux/exports_laiterie/:id', exact: true, permissions: ['DRT_FLUX_EXPORT_LAITERIE'], component: ExportLaiterie.Details },
    { path: '/flux/exports_laiterie', exact: true, permissions: ['DRT_FLUX_EXPORT_LAITERIE'], component: ExportLaiterie.List },
    { path: '/flux/suivi_des_envois', exact: true, permissions: ['DRT_FLUX_PUSH'], component: SuiviEnvois },
    { path: '/flux/abonnements/creer', exact: true, permissions: ['DRT_FLUX_ABONNEMENT'], component: Abonnements.Create },
    { path: '/flux/abonnements', exact: true, permissions: ['DRT_FLUX_ABONNEMENT'], component: Abonnements.List },
    { path: '/flux/abonnements/:id/modifier', exact: true, permissions: ['DRT_FLUX_ABONNEMENT'], component: Abonnements.Update },
    { path: '/flux/abonnements/:id', permissions: ['DRT_FLUX_ABONNEMENT'], component: Abonnements.Details },
    { path: '/flux/imports_exports_annuaire/imports', exact: true, permissions: ['DRT_FLUX_ANNUAIRE'], component: ImportExportAnnuaire.Imports },
    { path: '/flux/imports_exports_annuaire/exports', exact: true, permissions: ['DRT_FLUX_ANNUAIRE'], component: ImportExportAnnuaire.Exports },
    { path: '/flux/imports_exports_annuaire/api', exact: true, permissions: ['DRT_FLUX_ANNUAIRE'], component: ImportExportAnnuaire.Api },
    { path: '/flux/imports_exports_annuaire', exact: true, permissions: ['DRT_FLUX_ANNUAIRE'], component: ImportExportAnnuaire.Accueil },
    { path: '/flux/tables_de_references', exact: true, permissions: ['DRT_FLUX_REFERENCES'], component: TablesDeReferences },
    { path: '/flux', exact: true, permissions: ['DRT_FLUX'], component: AccueilFlux },
    { path: '/annuaire/personne/creer', permissions: ['DRT_ANNU_PER_GERER'], component: Personne.Create },
    { path: '/annuaire/personne/:id/modifier', permissions: ['DRT_ANNU_PER_GERER'], component: Personne.Update },
    { path: '/annuaire/personne/:id/supprimer', permissions: ['DRT_ANNU_PER_GERER'], component: Personne.Delete },
    { path: '/annuaire/personne/:id', permissions: ['DRT_ANNU_PER_CONSULTER'], component: Personne.Details },
    { path: '/annuaire/personne/', permissions: ['DRT_ANNU_PERSONNE'], component: Personne.List },
    { path: '/annuaire/entite/creer', permissions: ['DRT_ANNU_ENT_EXPLOIT_CREER', 'DRT_ANNU_ENT_LAITERIE_CREER', 'DRT_ANNU_ENT_ORG_INT_CREER', 'DRT_ANNU_ENT_ORG_HORS_CREER', 'DRT_ANNU_ENT_LABO_CREER', 'DRT_ANNU_ENT_CNIEL_CREER'], component: Entite.Create },
    { path: '/annuaire/entite/:id/modifier', permissions: ['DRT_ANNU_ENT_GERER'], component: Entite.Update },
    { path: '/annuaire/entite/:id/supprimer', permissions: ['DRT_ANNU_ENT_GERER'], component: Entite.Delete },
    { path: '/annuaire/entite/:id', permissions: ['DRT_ANNU_ENT_CONSULTER'], component: Entite.Details },
    { path: '/annuaire/entite', permissions: ['DRT_ANNU_ENTITE'], component: Entite.List },
    { path: '/annuaire/regroupement/creer', permissions: ['DRT_ANNU_REG_GERER'], component: Regroupement.Create },
    { path: '/annuaire/regroupement/:id/modifier', permissions: ['DRT_ANNU_REG_GERER'], component: Regroupement.Update },
    { path: '/annuaire/regroupement/:id/supprimer', permissions: ['DRT_ANNU_REG_GERER'], component: Regroupement.Delete },
    { path: '/annuaire/regroupement/:id', permissions: ['DRT_ANNU_REG_CONSULTER'], component: Regroupement.Details },
    { path: '/annuaire/regroupement', permissions: ['DRT_ANNU_REGROUPEMENT'], component: Regroupement.List },
    { path: '/annuaire/groupe/creer', permissions: ['DRT_ANNU_GROUPE'], component: Groupe.Create },
    { path: '/annuaire/groupe/:id/modifier', permissions: ['DRT_ANNU_GROUPE'], component: Groupe.Update },
    { path: '/annuaire/groupe/:id', permissions: ['DRT_ANNU_GROUPE'], component: Groupe.Details },
    { path: '/annuaire/groupe', permissions: ['DRT_ANNU_GROUPE'], component: Groupe.List },
    { path: '/annuaire', exact: true, redirect: "/annuaire/entite" },
    { path: '/change_entite', component: ChangerEntite },
    { path: '/resultats/production/:ent_id/:prn_id', permissions: ['DRT_RES_PRODUCTION'], component: ResultatsProductionDetails },
    { path: '/resultats/production/:ent_id', permissions: ['DRT_RES_PRODUCTION'], component: ResultatsProductionDetails },
    { path: '/resultats/production', permissions: ['DRT_RES_PAR_PRODUCTION'], exact: true, component: ResultatsProductionList },
    { path: '/resultats/citerne', permissions: ['DRT_RES_PAR_CITERNE'], exact: true, component: ResultatsCiterneList },
    { path: '/resultats/tournee', permissions: ['DRT_RES_PAR_TOURNEE'], exact: true, component: ResultatsTourneeList },
    { path: '/resultats/critere', permissions: ['DRT_RES_PAR_CRITERE'], exact: true, component: ResultatsCritereList },
    { path: '/resultats', exact: true, permissions: ['DRT_RESULTATS'], component: AccueilResultats },
    { path: '/notifications/gerer_seuils_alerte', permissions: ['DRT_RES_GERER_SEUILS'], component: GererSeuilsAlerte },
    { path: '/parametres', permissions: ['DRT_RES_PARAMETRER'], exact: true, component: Parametres },
    { path: '/contacts', permissions: ['DRT_CONTACTS'], exact: true, component: Contacts },
    { path: '/profil', exact: true, component: UserInformations },
    { path: '/profil/changer_mot_de_passe', exact: true, component: ChangePassword },
    { path: '/aide', exact: true, component: AccueilAide },
    { path: '/aide/cniel', exact: true, component: Aide },
    { path: '/aide/laboratoire', exact: true, component: Aide },
    { path: '/aide/laiterie', exact: true, component: Aide },
    { path: '/aide/producteur', exact: true, component: Aide },
    { path: '/aide/opnc', exact: true, component: Aide },
    { path: '/annonces', exact: true, permissions: ['DRT_ANNONCE_GERER'], component: Annonces.List },
    { path: '/annonces/creer', exact: true, permissions: ['DRT_ANNONCE_GERER'], component: Annonces.Create },
    { path: '/annonces/:id/modifier', exact: true, permissions: ['DRT_ANNONCE_GERER'], component: Annonces.Update },
]

// Route render engine
const renderRoute = (route: any, isPrivate = false) => {
    const { redirect, permissions, component: TargetComponent, ...rest } = route

    // Redirect
    if (redirect) return <Route key={route.path} {...rest}><Redirect to={redirect} /></Route>

    // Login and Permission guarded routes
    if (isPrivate) {
        if (permissions) return <Route key={route.path} {...rest} component={hasPermission(permissions) ? TargetComponent : Forbidden} />
        return <Route key={route.path} {...rest} component={isLoggedIn() ? TargetComponent : () => <Redirect to="/" />} />
    }

    // Public route
    return <Route key={route.path} {...rest} component={TargetComponent} />
}

// REACT ROUTER DOM FIX: React router Dom keeps scroll position on page change / Check if no anchor is passed to URL and scroll to top
const ScrollTopFix = () => {
    const { pathname, hash } = useLocation()

    useEffect(() => {
        !hash && setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }), 100)
    }, [pathname, hash]);

    return <></>
}

export const app_history = createBrowserHistory()

// ROUTES
const Routes = () => {
    return (
        <Router history={app_history}>
            <AnimatePresence>
                <ErrorBoundary key="1">
                    <Switch key="1">
                        {PUBLIC_ROUTES.map((route: any) => renderRoute(route))}
                        {PRIVATE_ROUTES.map((route: any) => renderRoute(route, true))}
                        <Route component={NotFound} />
                    </Switch>
                </ErrorBoundary>
                <ScrollTopFix />
            </AnimatePresence>
        </Router>
    )
}

export default (connect((state: any) => ({ sessionUpdatedAt: state.sessionUpdatedAt }))(Routes));