import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { Forbidden } from "../../_error_pages";
import {hasPermission} from "../../../helpers/helpers";

const REDIRECTIONS = [
    {permissions: ['DRT_FLUX_ANNUAIRE'], path: '/flux/imports_exports_annuaire/imports'},
    {permissions: ['DRT_FLUX_ANNUAIRE'], path: '/flux/imports_exports_annuaire/exports'},
    {permissions: ['DRT_FLUX_ANNUAIRE'], path: '/flux/imports_exports_annuaire/api'},
]

class AccueilFluxAnnuaire extends CustomComponent {
    componentDidMount() {
        for (let redirection of REDIRECTIONS) {
            if (hasPermission(redirection.permissions)) return this.props.history.push(redirection.path)
        }
    }

    render () {
        return <Forbidden />
    }
}

export default withRouter(AccueilFluxAnnuaire);