export enum MEDIA_PUSH {
    MAIL = 'MAIL',
    SMS = 'SMS',
}

export const MEDIA_PUSH_LABEL: {[key: string]: string} = {
    [MEDIA_PUSH.MAIL]: 'E-MAIL',
    [MEDIA_PUSH.SMS]: 'SMS',
}

export const MEDIA_PUSH_OPTIONS = Object.entries(MEDIA_PUSH_LABEL).map(([value, label]) => ({
    value, label
}))