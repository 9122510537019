import saveAs from "file-saver";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import BreadCrumb from '../../../components/BreadCrumb';
import CustomComponent from '../../../components/CustomComponent';
import { FilterDropdown } from "../../../components/FilterDropdown";
import { FormDatePicker } from "../../../components/FormComponents";
import { DefaultLayout } from '../../../components/Layouts';
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { LimitValuesSelect } from "../../../components/TableComponents";
import {
    DETAILS_SUIVI_PUSH_DETAIL,
    DETAILS_SUIVI_PUSH_DETAIL_LABEL,
    DETAILS_SUIVI_PUSH_DETAIL_OPTIONS
} from "../../../enum/details_suivi_push_detail";
import { MEDIA_PUSH, MEDIA_PUSH_LABEL, MEDIA_PUSH_OPTIONS } from "../../../enum/media_push";
import {
    STATUTS_SUIVI_PUSH_DETAIL,
    STATUTS_SUIVI_PUSH_DETAIL_LABEL,
    STATUTS_SUIVI_PUSH_DETAIL_OPTIONS
} from "../../../enum/statuts_suivi_push_detail";
import { SUIVI_MAIL_STATUTS, SUIVI_MAIL_STATUTS_LABEL, SUIVI_MAIL_STATUTS_OK } from "../../../enum/suivi_mail_statuts";
import { SUIVI_SMS_STATUTS } from "../../../enum/suivi_sms_statuts";
import { DebounceQueue } from '../../../helpers/debouncer';
import {
    TOAST_TYPE,
    dateFormat,
    datePickerFormat,
    getFileNameFromPath,
    hasPermission,
    labelizeEntite,
    sendToast
} from "../../../helpers/helpers";
import { exportCSVFlux } from "../../../requests/exportCSVFlux";

class SuiviEnvois extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: 'date_fin',
        ordering: 'DESC',
        start_date_range_from: moment().utc().subtract(7, 'days').startOf('day').toDate(),
        start_date_range_to: moment().utc().startOf('day').toDate(),
        destinataire: '',
        entite_destinataire: '',
        contact: '',
        media: '',
        date_fin: '',
        statut: '',
        statut_detail: '',
        rapport: '',
        emetteur: '',
    }

    private readonly dateKeys: string[] = [
        'start_date_range_from',
        'start_date_range_to',
        'date_fin',
    ];

    private options = {
        media: MEDIA_PUSH_OPTIONS,
        statut: STATUTS_SUIVI_PUSH_DETAIL_OPTIONS,
        detail: DETAILS_SUIVI_PUSH_DETAIL_OPTIONS,
    }

    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            filters: this.defaultFilters,
            options: this.options,
            suivisPushDetail: [],
            smsModal: null,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
        }
    }

    componentDidMount() {
        document.title = 'Infolabo | Suivi des envois';
        this._isMounted = true;

        this.getSuivisPushDetail();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getSuivisPushDetail = async (triggeredByPagination: boolean = false) => {
        this.setState({ isLoading: true });

        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        });

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (this.dateKeys.includes(key) && typeof val !== 'string') {
                !!val && params.append(key, val.toISOString());
            }
            else if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/inf-suivi-push-detail?${params.toString()}`, 'GET');
        this.setState({ isLoading: false });

        if (data?.statusCode === 200 && this._isMounted) {
            const suivisPushDetail = data.suivisPushDetail.map(this.mapSuiviPushDetail);

            this.setState((prev: any) => ({
                suivisPushDetail: suivisPushDetail,
                paginate: { ...prev.paginate, ...data.paginate },
            }));
        }
    }

    mapSuiviPushDetail = (suiviPushDetail: any) => {
        const media = suiviPushDetail.rapport.fl_envoi_mail ? MEDIA_PUSH.MAIL : MEDIA_PUSH.SMS;

        let contact: String = '';
        if (media === MEDIA_PUSH.MAIL) contact = suiviPushDetail.mail.map((mail: any) => mail.email).sort().join(', ');
        if (media === MEDIA_PUSH.SMS) contact = suiviPushDetail.sms.map((sms: any) => sms.numero).sort().join(', ');

        let contact_tooltip = '';
        if (media === MEDIA_PUSH.MAIL) contact_tooltip = suiviPushDetail.mail
            .map((mail: any) => {
                const not_delivered_by_provider = mail.statut === SUIVI_MAIL_STATUTS.REQUEST && !mail?.message_id;
                const status = (not_delivered_by_provider)
                    ? DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.NOT_SENT]
                    : SUIVI_MAIL_STATUTS_LABEL[mail.statut];

                return `${mail.email} - ${status}`
            })
            .join('<br>');

        const [statut_display, detail_display] = (media === MEDIA_PUSH.MAIL)
            ? this.getStatutAndDetailForMail(suiviPushDetail)
            : this.getStatutAndDetailForSms(suiviPushDetail);

        return {
            ...suiviPushDetail,
            statut_display,
            detail_display,
            contact,
            contact_tooltip,
        }
    }

    getStatutAndDetailForMail = (suiviPushDetail: any): [string, string] => {
        const statut_EC = STATUTS_SUIVI_PUSH_DETAIL_LABEL[STATUTS_SUIVI_PUSH_DETAIL.EC];
        const statut_KO = STATUTS_SUIVI_PUSH_DETAIL_LABEL[STATUTS_SUIVI_PUSH_DETAIL.KO];
        const statut_OK = STATUTS_SUIVI_PUSH_DETAIL_LABEL[STATUTS_SUIVI_PUSH_DETAIL.TRANSMIS];

        let statut_display: string = '';
        let detail_display: string[] = [];

        switch (suiviPushDetail.statut) {
            case STATUTS_SUIVI_PUSH_DETAIL.EC:
                statut_display = statut_EC;
                detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.EC]];
                break;
            case STATUTS_SUIVI_PUSH_DETAIL.GENERE:
                statut_display = statut_EC;
                detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.GENERE]];
                break;
            case STATUTS_SUIVI_PUSH_DETAIL.KO:
                statut_display = statut_KO;
                detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.KO]];
                break;
            case STATUTS_SUIVI_PUSH_DETAIL.KO_TRANSMISSION:
                statut_display = statut_KO;
                detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.KO_TRANSMISSION]];
                break;
            case STATUTS_SUIVI_PUSH_DETAIL.TRANSMIS:
                statut_display = statut_OK;

                if (suiviPushDetail.mail.length === 0) {
                    detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.REQUEST]];
                    break;
                }

                const displayAsOK = suiviPushDetail.mail.some((mail: any) => {
                    const known_provider_status = mail.statut !== SUIVI_MAIL_STATUTS.REQUEST && SUIVI_MAIL_STATUTS_OK.includes(mail.statut);
                    const delivered_by_provider = mail.statut === SUIVI_MAIL_STATUTS.REQUEST && !!mail?.message_id;

                    return known_provider_status || delivered_by_provider;
                });

                statut_display = displayAsOK ? statut_OK : statut_KO;

                for (const mail of suiviPushDetail.mail) {
                    switch (mail.statut) {
                        case SUIVI_MAIL_STATUTS.REQUEST:
                            if (!!mail?.message_id)
                                detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.REQUEST]);
                            else
                                detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.NOT_SENT]);
                            break;
                        case SUIVI_MAIL_STATUTS.DELIVERED:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.DELIVERED]);
                            break;
                        case SUIVI_MAIL_STATUTS.OPENED:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.OPENED]);
                            break;
                        case SUIVI_MAIL_STATUTS.CLICKED:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.CLICKED]);
                            break;
                        case SUIVI_MAIL_STATUTS.UNSUBSCRIBED:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.UNSUBSCRIBED]);
                            break;
                        case SUIVI_MAIL_STATUTS.SOFT_BOUNCE:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.SOFT_BOUNCE]);
                            break;
                        case SUIVI_MAIL_STATUTS.HARD_BOUNCE:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.HARD_BOUNCE]);
                            break;
                        case SUIVI_MAIL_STATUTS.INVALID_EMAIL:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.INVALID_EMAIL]);
                            break;
                        case SUIVI_MAIL_STATUTS.DEFERRED:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.DEFERRED]);
                            break;
                        case SUIVI_MAIL_STATUTS.COMPLAINT:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.COMPLAINT]);
                            break;
                        case SUIVI_MAIL_STATUTS.BLOCKED:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.BLOCKED]);
                            break;
                        case SUIVI_MAIL_STATUTS.ERROR:
                            detail_display.push(`${DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.ERR_MSG]}: ${suiviPushDetail.mail.detail_erreur}`);
                            break;
                        default:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.UNKNOWN]);
                            break;
                    }
                }
                break;
            default:
                break;
        }

        return [statut_display, detail_display.join(', ')];
    }

    getStatutAndDetailForSms = (suiviPushDetail: any): [string, string] => {
        const statut_EC = STATUTS_SUIVI_PUSH_DETAIL_LABEL[STATUTS_SUIVI_PUSH_DETAIL.EC];
        const statut_KO = STATUTS_SUIVI_PUSH_DETAIL_LABEL[STATUTS_SUIVI_PUSH_DETAIL.KO];
        const statut_OK = STATUTS_SUIVI_PUSH_DETAIL_LABEL[STATUTS_SUIVI_PUSH_DETAIL.TRANSMIS];

        let statut_display: string = '';
        let detail_display: string[] = [];

        switch (suiviPushDetail.statut) {
            case STATUTS_SUIVI_PUSH_DETAIL.EC:
                statut_display = statut_EC;
                detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.EC]];
                break;
            case STATUTS_SUIVI_PUSH_DETAIL.GENERE:
                statut_display = statut_EC;
                detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.GENERE]];
                break;
            case STATUTS_SUIVI_PUSH_DETAIL.KO:
                statut_display = statut_KO;
                detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.KO]];
                break;
            case STATUTS_SUIVI_PUSH_DETAIL.KO_TRANSMISSION:
                statut_display = statut_KO;
                detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.KO_TRANSMISSION]];
                break;
            case STATUTS_SUIVI_PUSH_DETAIL.TRANSMIS:
                statut_display = statut_OK;

                if (suiviPushDetail.sms.length === 0) {
                    detail_display = [DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.REQUEST]];
                    break;
                }

                for (let sms of suiviPushDetail.sms) {
                    switch (sms.statut) {
                        case SUIVI_SMS_STATUTS.IN_PROGRESS:
                            statut_display = statut_EC;
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.IN_PROGRESS]);
                            break;
                        case SUIVI_SMS_STATUTS.WAIT:
                        case SUIVI_SMS_STATUTS.SCHEDULED:
                            statut_display = statut_EC;
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.WAIT]);
                            break;
                        case SUIVI_SMS_STATUTS.ON:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.REQUEST]);
                            break;
                        case SUIVI_SMS_STATUTS.OK:
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.DELIVERED]);
                            break;
                        case SUIVI_SMS_STATUTS.KO:
                            statut_display = statut_KO;
                            detail_display.push(`${DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.ERR_MSG]}: ${suiviPushDetail.sms.detail_erreur}`);
                            break;
                        case SUIVI_SMS_STATUTS.REP:
                            statut_display = statut_KO;
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.UNSUBSCRIBED]);
                            break;
                        case SUIVI_SMS_STATUTS.NO:
                        default:
                            statut_display = statut_KO;
                            detail_display.push(DETAILS_SUIVI_PUSH_DETAIL_LABEL[DETAILS_SUIVI_PUSH_DETAIL.UNKNOWN]);
                            break;
                    }
                }
                break;
            default:
                break;
        }

        return [statut_display, detail_display.join(', ')];
    }

    handleExport = async () => {
        this.setState({ isLoading: true });
        const allFilters = { ...this.state.paginate, ...this.state.filters };
        await exportCSVFlux('SUIVI_PUSH', allFilters, this.dateKeys);
        this.setState({ isLoading: false });
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getSuivisPushDetail, [], true))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getSuivisPushDetail, [true], true))
    }

    fileDownload = async (id: number, filePath: string | null) => {
        if (!filePath) {
            sendToast(<p>Impossible de télécharger le fichier</p>, TOAST_TYPE.ERROR);
            return;
        }

        const fileName: string = getFileNameFromPath(filePath);

        const blob: Blob = await this.request(`/inf-suivi-push-detail/download/${id}`, 'GET', undefined, false, false, true);

        if (!!blob && blob instanceof Blob) {
            saveAs(blob, fileName);
        }
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }, { name: 'Suivi des envois' }]} />
                    <div className="d-flex justify-content-between align-items-start flex-wrap m-b-15">
                        <h1 className="main-title m-b-15">Suivi des envois</h1>
                        <div className="d-flex flex-wrap m-b-5">
                            {hasPermission('DRT_FLUX_ABONNEMENT') && (
                                <Link to="/flux/abonnements" className="btn btn-dark m-r-10 m-b-10" target="_blank">
                                    <i className="icon-list" />
                                    Gérer les abonnements
                                </Link>
                            )}
                        </div>
                    </div>

                    <LimitValuesSelect
                        default_value={this.state.paginate.limit}
                        steps={[10, 20, 50]}
                        onChange={(e: any) => this.setState((prev: any) => ({ paginate: { ...prev.paginate, limit: +e.target.value, page: 0 } }), () => this.queue.push(this.getSuivisPushDetail, [], true))}
                    />

                    <FilterDropdown
                        parent={this}
                        filters={this.state.filters}
                        onSearchLoad={(filters: any) => this.setState((prev: any) => ({ filters: { ...prev.filters, ...filters } }), () => this.queue.push(this.getSuivisPushDetail, [], true))}
                        onFiltersReset={() => this.setState({ filters: this.defaultFilters }, () => this.queue.push(this.getSuivisPushDetail, [], true))}
                        onFiltersSearch={() => this.queue.push(this.getSuivisPushDetail, [], true)}
                        onApplyFilters={() => this.queue.push(this.getSuivisPushDetail, [], true)}
                        onExport={{ csv: this.handleExport }}
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group-flex">
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="start_date_range_from"
                                            id="start_date_range_from"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.start_date_range_from) || ''}
                                            label="Date d'envoi du"
                                            required
                                        />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="start_date_range_to"
                                            id="start_date_range_to"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.start_date_range_to) || ''}
                                            label="au"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FilterDropdown>

                    <TABLE fixed responsive="accordion">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH fixed={{ left: 0, width: 58 }} />
                                <TABLE.TH name="destinataire" fixed={{ left: 58 }}
                                    input={{ placeholder: 'Destinataire', value: this.state.filters.destinataire, onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getSuivisPushDetail)) }}
                                    sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('destinataire') }}
                                />
                                <TABLE.TH name="entite_destinataire"
                                    input={{ placeholder: 'Entité destinataire', value: this.state.filters.entite_destinataire, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getSuivisPushDetail)) }}
                                    sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('entite_destinataire') }}
                                />
                                <TABLE.TH name="contact"
                                    input={{ placeholder: 'Contact', value: this.state.filters.contact, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getSuivisPushDetail)) }}
                                />
                                <TABLE.TH name="media"
                                    select={{ placeholder: 'Média', value: this.state.filters.media, options: this.state.options.media, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getSuivisPushDetail)) }}
                                />
                                <TABLE.TH name="date_fin"
                                    datePicker={{ value: this.state.filters.date_fin, placeholder: `Date et heure d'envoi`, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getSuivisPushDetail)) }}
                                    sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('date_fin') }}
                                />
                                <TABLE.TH name="statut"
                                    select={{ placeholder: 'Statut', value: this.state.filters.statut, options: this.state.options.statut, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getSuivisPushDetail)) }}
                                />
                                <TABLE.TH name="statut_detail"
                                    select={{ placeholder: 'Détail statut', value: this.state.filters.statut_detail, options: this.state.options.detail, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getSuivisPushDetail)) }}
                                />
                                <TABLE.TH name="rapport"
                                    input={{ placeholder: 'Rapport', value: this.state.filters.rapport, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getSuivisPushDetail)) }}
                                    sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('rapport') }}
                                />
                                <TABLE.TH name="emetteur"
                                    input={{ placeholder: 'Emetteur', value: this.state.filters.emetteur, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getSuivisPushDetail)) }}
                                    sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('emetteur') }}
                                />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.suivisPushDetail.map((suivi_push_detail: any) => (
                                <TABLE.TR title={[suivi_push_detail.destinataire?.nom, suivi_push_detail.destinataire?.prenom].filter((n: any) => !!n).join(' ')} key={suivi_push_detail.id}>
                                    <TABLE.TD fixed={{ left: 0, width: 58 }} hiddenMobile>
                                        {(suivi_push_detail.rapport.fl_envoi_mail && !!suivi_push_detail.fichier) && (
                                            <button type="button" className="action-btn" title="Télécharger l'e-mail"
                                                onClick={() => this.fileDownload(suivi_push_detail.id, suivi_push_detail.fichier)}
                                            >
                                                <i className="icon-file"></i>
                                                <span className="sr-only">Télécharger l'e-mail</span>
                                            </button>
                                        )}
                                        {suivi_push_detail.rapport.fl_envoi_sms && (
                                            <button type="button" className="action-btn" title="Voir le contenu du SMS"
                                                onClick={() => this.setState({ smsModal: suivi_push_detail.message || '-' })}
                                            >
                                                <i className="icon-eye"></i>
                                                <span className="sr-only">Voir le contenu du SMS</span>
                                            </button>
                                        )}
                                    </TABLE.TD>
                                    <TABLE.TD fixed={{ left: 58 }} label="Destinataire">
                                        <Link to={`/annuaire/personne/${suivi_push_detail.per_id_destinataire}`} target="_blank">
                                            <strong>
                                                {[suivi_push_detail.destinataire?.nom, suivi_push_detail.destinataire?.prenom].filter((n: any) => !!n).join(' ')}
                                            </strong>
                                        </Link>
                                    </TABLE.TD>
                                    <TABLE.TD label="Entité destinataire">{labelizeEntite(suivi_push_detail.entite_destinataire?.siret, suivi_push_detail.entite_destinataire?.nom_usuel)}</TABLE.TD>
                                    <TABLE.TD label="Contact"><span data-tip={suivi_push_detail.contact_tooltip} data-type="dark">{suivi_push_detail.contact || ''}</span></TABLE.TD>
                                    <TABLE.TD label="Média">{suivi_push_detail.rapport.fl_envoi_mail ? MEDIA_PUSH_LABEL[MEDIA_PUSH.MAIL] : MEDIA_PUSH_LABEL[MEDIA_PUSH.SMS]}</TABLE.TD>
                                    <TABLE.TD label="Date et heure d'envoi">{!!suivi_push_detail.date_fin ? dateFormat(suivi_push_detail.date_fin, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Statut"><span data-tip={suivi_push_detail.contact_tooltip} data-type="dark">{suivi_push_detail.statut_display || ''}</span></TABLE.TD>
                                    <TABLE.TD label="Détail statut">{suivi_push_detail.detail_display || ''}</TABLE.TD>
                                    <TABLE.TD label="Rapport">{suivi_push_detail.rapport?.nom || ''}</TABLE.TD>
                                    <TABLE.TD label="Emetteur">{suivi_push_detail.emetteur?.nom_usuel || ''}</TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>

                    {this.state.suivisPushDetail?.length === 0 && (
                        <TABLE.NO_RESULT />
                    )}

                    <Pagination
                        handleClick={this.paginationOnClick}
                        pageCurrent={+this.state.paginate.page + 1}
                        pageTotal={this.state.paginate.pageTotal}
                        pageDisplay={3}
                    />

                    <Modal open={!!this.state.smsModal} size="sm" customLayout>
                        <div className="modal-header m-b-15">
                            <h4 className="modal-title">
                                Message SMS transmis
                            </h4>
                        </div>
                        <div className="modal-body m-x-30">
                            <p className="text-left">{this.state.smsModal}</p>
                        </div>
                        <div className="modal-footer p-t-0">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => this.setState({ smsModal: null })}
                            >
                                Fermer
                            </button>
                        </div>
                    </Modal>
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(SuiviEnvois));
