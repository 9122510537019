export enum ETATS_FLUX_ANNUAIRE {
    OK = 'OK',
    ER = 'ER',
    EC = 'EC',
    KO = 'KO',
    ATTENTE = 'ATTENTE',
}

export const ETATS_FLUX_ANNUAIRE_LABEL: {[key: string]: string} = {
    [ETATS_FLUX_ANNUAIRE.OK]: 'OK',
    [ETATS_FLUX_ANNUAIRE.ER]: 'Erreur',
    [ETATS_FLUX_ANNUAIRE.EC]: 'En cours',
    [ETATS_FLUX_ANNUAIRE.KO]: 'KO',
    [ETATS_FLUX_ANNUAIRE.ATTENTE]: 'Attente',
}

export const ETATS_FLUX_ANNUAIRE_OPTIONS = Object.entries(ETATS_FLUX_ANNUAIRE_LABEL).map(([value, label]) => ({
    value, label
}))