export enum TYPES_FICHIER_SUIVI_EXPORT_RES {
    SIGAL = 'SIGAL',
    SINAPS = 'SINAPS',
    GC = 'GC',
    EXPJ = 'EXPJ',
    EXPM = 'EXPM',
    RJC = 'RJC',
    RJP = 'RJP',
    RM = 'RM',
    LAIT = 'LAIT',
    PONC = 'PONC',
    OPNC = 'OPNC',
}

export const TYPES_FICHIER_SUIVI_EXPORT_RES_LABEL: {[key: string]: string} = {
    [TYPES_FICHIER_SUIVI_EXPORT_RES.SIGAL]: 'SIGAL',
    [TYPES_FICHIER_SUIVI_EXPORT_RES.SINAPS]: 'Infocentre',
    [TYPES_FICHIER_SUIVI_EXPORT_RES.GC]: 'Germes et Cellules',
    [TYPES_FICHIER_SUIVI_EXPORT_RES.EXPJ]: 'Export Ponctuel - Journaliers',
    [TYPES_FICHIER_SUIVI_EXPORT_RES.EXPM]: 'Export Ponctuel - Mensuels',
    [TYPES_FICHIER_SUIVI_EXPORT_RES.RJC]: 'Export Laiterie RJ Citerne',
    [TYPES_FICHIER_SUIVI_EXPORT_RES.RJP]: 'Export Laiterie RJ Producteurs',
    [TYPES_FICHIER_SUIVI_EXPORT_RES.RM]: 'Export Laiterie RM',
    [TYPES_FICHIER_SUIVI_EXPORT_RES.OPNC]: 'Export OPNC RM',
}

export const TYPES_FICHIER_SUIVI_EXPORT_RES_OPTIONS = Object.entries(TYPES_FICHIER_SUIVI_EXPORT_RES_LABEL).map(([value, label]) => ({
    value, label
}))