import Modal from "../../../components/Modal"
import { Request, TOAST_TYPE, sendToast } from "../../../helpers/helpers"

export const DeleteModal = ({ exportLaiterie, onClose, onDelete }: any) => {
    const { id, destinataire } = exportLaiterie

    const deleteExport = async () => {
        const URL = `/exp_export_laiterie/${id}`
        const data = await Request(URL, 'DELETE')

        if (data?.statusCode === 201) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
        }
        onDelete()
    }

    return (
        <Modal open={exportLaiterie}>
            <p className="lead m-b-30">
                <div>Êtes-vous sûr(e) de vouloir supprimer le routage à destination de </div>
                <div className="m-t-10">{destinataire.siret} - {destinataire.nom_usuel}</div>
            </p>
            <button onClick={onClose} className="btn btn-white m-r-10">Annuler</button>
            <button onClick={deleteExport} className="btn btn-secondary">Confirmer</button>
        </Modal>
    )
}