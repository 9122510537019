import CustomComponent from "../../../components/CustomComponent"
import CreateRegroupement from './create'

class UpdateRegroupement extends CustomComponent {
    render () {
        const id = this.props.match.params.id;
        return(<CreateRegroupement regroupementId={id} isUpdatePage={true} />);
    }
}

export default UpdateRegroupement