import { useEffect, useRef, useState } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SimpleReactValidator from "simple-react-validator";
import { FormInputSelect } from "../../../components/FormComponents";
import { Request, TOAST_TYPE, sendToast } from '../../../helpers/helpers';


export const EditEntiteModal = ({ open, handleOpen, handle, persEntite = {}, fonctions = [], habilitations = [] }: any) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const [thisPersEntite, setThisPersEntite] = useState({}) as any;
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [habFonc, setHabFonc]: any = useState({ habilitation: persEntite?.hab_code?.code_hab, fonction: persEntite?.fon_id?.id });
    const [thisHabilitations, setHabilitations]: any = useState(habilitations);
    const [thisFonctions, setFonctions]: any = useState([]);
    const validator = useRef(new SimpleReactValidator({ locale: 'fr' }));

    const onClick = async () => {
        setIsSubmitted(true);
        if (validator.current.allValid()) {
            let editPersEntite = {
                ...thisPersEntite,
                fon_id: thisFonctions.find((fonction: any) => fonction.id === (habFonc as any).fonction),
                hab_code: thisHabilitations[thisPersEntite?.ent_id?.id].find((habilitation: any) => habilitation.code_hab === (habFonc as any).habilitation)
            };

            let BODY = {
                ent_id: persEntite.ent_id.id,
                per_id: persEntite.per_id.id,
                hab_code: habFonc.habilitation,
                fon_id: habFonc.fonction,
            }

            const data = await Request(`/per_habilitation/${persEntite.id}`, 'PATCH', BODY);

            if (data?.statusCode === 201) {
                handle && handle(editPersEntite);
                setHabFonc({});
                setOpen(false);
                thisHandleOpen(false);
            }
        }
        else {
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }
    }

    const thisHandleOpen = (value: boolean) => {
        setOpen(value);
        handleOpen && handleOpen(value);
    }

    useEffect(() => {
        setOpen(open);
        setThisPersEntite({ ...persEntite, ent_id: { ...persEntite?.ent_id, siret: persEntite?.ent_id?.ent_pay_id?.code_pays + persEntite?.ent_id?.siren + persEntite?.ent_id?.nic } });
        setHabFonc({ habilitation: persEntite?.hab_code?.code_hab, fonction: persEntite?.fon_id?.id });
        setFonctions(fonctions.map((fonction: any) => ({ ...fonction, label: fonction.lib_fonction, value: fonction.id })));
    }, [fonctions, open, persEntite]);

    if (isOpen && thisPersEntite && !(thisPersEntite?.ent_id?.id in thisHabilitations)) {
        const body = { ids: [thisPersEntite?.ent_id?.id] };
        Request(`/ref_habilitation/findByClaID`, 'POST', body).then((data: any) => {
            setHabilitations({ ...thisHabilitations, [thisPersEntite?.ent_id?.id]: data[thisPersEntite?.ent_id?.id] });
        });
    }

    return (
        <BootstrapModal show={isOpen} className="modal--primary modal--lg" centered>
            <BootstrapModal.Body>
                <div className="modal-step">
                    <h2 className="modal-step__title">Modifier une association Personne - Entité</h2>
                    <div className="modal-step__container">
                        <div className="modal-step__actions">
                            <button type="button" className="btn btn-white" id="btn_prev" onClick={() => thisHandleOpen(false)}>Annuler</button>
                            <button type="button" className="btn btn-secondary" onClick={onClick}>Confirmer</button>
                        </div>
                    </div>
                </div>
                <div className="relation-card m-b-50">
                    <div className="relation-card__content">
                        <div className="row">
                            <div className="col-md-2">
                                <h4 className="label">{thisPersEntite?.ent_id?.nom_usuel || ""}</h4>
                                <ul className="relation-card__info">
                                    <li>{thisPersEntite?.ent_id?.siret || ""}</li>
                                    <li>{thisPersEntite?.ent_id?.ville || ""}</li>
                                </ul>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label htmlFor={`habilitation`}>Habilitation*</label>
                                    <FormInputSelect
                                        label={`habilitation`}
                                        displayLabel={false}
                                        options={thisHabilitations[thisPersEntite?.ent_id?.id]?.map((e: any) => ({ label: e.lib_hab, value: e.code_hab })).sort((a: any, b: any) => (a.label > b.label) ? 1 : -1) || []}
                                        name={`habilitation`}
                                        id={`habilitation`}
                                        handle={(e: any) => {
                                            setHabFonc({ ...habFonc, habilitation: e.data.value });
                                        }}
                                        value={habFonc.habilitation || ""}
                                        isSubmitted={isSubmitted}
                                        simpleValidator={validator.current}
                                        required={true}
                                        isClearable={true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label htmlFor={`fonction`}>Fonction*</label>
                                    <FormInputSelect
                                        label={`fonction`}
                                        displayLabel={false}
                                        options={thisFonctions.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1)}
                                        name={`fonction`}
                                        id={`fonction`}
                                        handle={(e: any) => {
                                            setHabFonc({ ...habFonc, fonction: e.data.value });
                                        }}
                                        value={habFonc.fonction || ""}
                                        isSubmitted={isSubmitted}
                                        simpleValidator={validator.current}
                                        required={true}
                                        isClearable={true}
                                        maxMenuHeight={150}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BootstrapModal.Body>
        </BootstrapModal>
    )
}