import Modal from "../../../components/Modal"
import { Request, TOAST_TYPE, sendToast } from "../../../helpers/helpers"

export const DeleteModal = ({entite, onClose, onDelete}: any) => {
    const deleteEntite = async (e: any) => {
        e.preventDefault()
        const url = '/ent_entite/' + entite.id;
        const method = "DELETE";
        const data = await Request(url, method);
        if (data && data.affected) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            onDelete()
        }
    }

    return (
        <Modal open={entite}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                Etes-vous sûr(e) de vouloir supprimer l’entité <b>{entite?.siret}-{entite?.nom_usuel}</b>
            </div>
            <button onClick={onClose} className="btn btn-white">Annuler</button>
            <button onClick={deleteEntite} className="btn btn-secondary">Confirmer</button>
        </Modal>
    )
}