export enum SUIVI_MAIL_STATUTS {
    REQUEST = 'REQUEST',
    DELIVERED = 'DELIVERED',
    OPENED = 'OPENED',
    CLICKED = 'CLICKED',
    SOFT_BOUNCE = 'SOFT_BOUNCE',
    HARD_BOUNCE = 'HARD_BOUNCE',
    INVALID_EMAIL = 'INVALID_EMAIL',
    DEFERRED = 'DEFERRED',
    COMPLAINT = 'COMPLAINT',
    UNSUBSCRIBED = 'UNSUBSCRIBED',
    BLOCKED = 'BLOCKED',
    ERROR = 'ERROR',
}

export const SUIVI_MAIL_STATUTS_OK = [
    SUIVI_MAIL_STATUTS.REQUEST,
    SUIVI_MAIL_STATUTS.DELIVERED,
    SUIVI_MAIL_STATUTS.OPENED,
    SUIVI_MAIL_STATUTS.CLICKED
]

export enum SUIVI_MAIL_STATUTS_DISPLAY {
    ENVOYE = 'Envoyé',
    NON_ENVOYE = 'Non envoyé',
    INCONNU = 'Inconnu',
    RECU = 'Reçu',
    OUVERT = 'Ouvert',
    CLIQUE = 'Cliqué',
    REFUS_TEMPORAIRE = 'Refus temporaire',
    ERREUR_DEFINITIVE = 'Erreur définitive',
    ADRESSE_ERRONEE = 'Adresse erronée',
    ERREUR_TEMPORAIRE = 'Erreur temporaire',
    DETECTE_SPAM = 'Détecté spam',
    DESABONNE = 'Désabonné',
    BLOQUE = 'Bloqué',
    ERREUR_TECHNIQUE = 'Erreur technique',
    EN_COURS_GENERATION= 'En cours de génération',
    FICHIER_GENERE = 'Fichier généré à envoyer',
    ERREUR_GENERATION = 'Erreur génération',
    ERREUR_TRANSMISSION = 'Erreur transmission',
    FICHIER_DISPOSITION = 'Fichier mis à disposition',
    FICHIER_ACQUITTE_OK = 'Fichier acquitté OK',
    FICHIER_ACQUITTE_KO = 'Fichier acquitté KO',
    FICHIER_NON_ACQUITTE = 'Fichier non acquitté',
}

export const SUIVI_MAIL_STATUTS_LABEL: {[key: string]: string} = {
    [SUIVI_MAIL_STATUTS.REQUEST]: SUIVI_MAIL_STATUTS_DISPLAY.ENVOYE,
    [SUIVI_MAIL_STATUTS.DELIVERED]: SUIVI_MAIL_STATUTS_DISPLAY.RECU,
    [SUIVI_MAIL_STATUTS.OPENED]: SUIVI_MAIL_STATUTS_DISPLAY.OUVERT,
    [SUIVI_MAIL_STATUTS.CLICKED]: SUIVI_MAIL_STATUTS_DISPLAY.CLIQUE,
    [SUIVI_MAIL_STATUTS.SOFT_BOUNCE]: SUIVI_MAIL_STATUTS_DISPLAY.REFUS_TEMPORAIRE,
    [SUIVI_MAIL_STATUTS.HARD_BOUNCE]: SUIVI_MAIL_STATUTS_DISPLAY.ERREUR_DEFINITIVE,
    [SUIVI_MAIL_STATUTS.INVALID_EMAIL]: SUIVI_MAIL_STATUTS_DISPLAY.ADRESSE_ERRONEE,
    [SUIVI_MAIL_STATUTS.DEFERRED]: SUIVI_MAIL_STATUTS_DISPLAY.ERREUR_TEMPORAIRE,
    [SUIVI_MAIL_STATUTS.COMPLAINT]: SUIVI_MAIL_STATUTS_DISPLAY.DETECTE_SPAM,
    [SUIVI_MAIL_STATUTS.UNSUBSCRIBED]: SUIVI_MAIL_STATUTS_DISPLAY.DESABONNE,
    [SUIVI_MAIL_STATUTS.BLOCKED]: SUIVI_MAIL_STATUTS_DISPLAY.BLOQUE,
    [SUIVI_MAIL_STATUTS.ERROR]: SUIVI_MAIL_STATUTS_DISPLAY.ERREUR_TECHNIQUE,
}