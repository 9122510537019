import classNames from 'classnames'
import moment from "moment"
import React from "react"
import DATALIST from "../../../components/DataList"
import Modal from "../../../components/Modal"
import TABLE from "../../../components/Table"
import { dateFormat, exists, formatSiretNomUsuel, numberFormat } from "../../../helpers/helpers"

interface DetailsModalProps {
    mensuelDetail?: any,
    onExportPdf?: Function,
    onClose: Function
}

export const DetailsModalMensuel = ({ mensuelDetail, onExportPdf, onClose }: DetailsModalProps) => {

    let { production, resultats_mensuel, echantillons } = mensuelDetail;

    resultats_mensuel.sort((a: any, b: any) => a?.cri_id?.ordre_affichage - b?.cri_id?.ordre_affichage);

    const resultats_mensuels_pour_paiement = resultats_mensuel.filter((rem: any) => rem.fl_pour_paiement === true);
    const autres_resultats_mensuels = resultats_mensuel.filter((rem: any) => rem.fl_pour_paiement === false);

    let latest = null;
    for (let rem of resultats_mensuel) {
        if (latest === null) latest = rem;
        if (moment.utc(rem.date_modif).isAfter(latest.date_modif)) latest = rem;
    }

    const references = {
        prn_id: latest?.prn_id?.id,
        mois_facturation: latest?.mois_facturation,
        prd_siret: `${latest?.prd_id?.ent_id.siret}`,
        producteur: formatSiretNomUsuel(latest?.prd_id?.ent_id.siret, latest?.prd_id?.ent_id.nom_usuel),
        type_lait: `${latest?.esp_id?.lib_court} ${latest?.nat_id?.libelle}`,
        centre_appartenance: formatSiretNomUsuel(latest?.ent_id_ca?.siret, latest?.ent_id_ca?.nom_usuel),
        centre_collecte: formatSiretNomUsuel(latest?.ent_id_cc?.siret, latest?.ent_id_cc?.nom_usuel),
        laboratoire: formatSiretNomUsuel(latest?.lab_id?.ent_id?.siret, latest?.lab_id?.ent_id?.nom_usuel),
        signataire: `${latest?.sig_id?.nom} - ${latest?.sig_id?.fonction}`,
        tank: production?.tank_code ? ` (Tank #${production?.tank_code})` : ''
    };

    const chunk_size = 8;
    const sliced_criteres: any[] = [];
    const sliced_data: any[] = [];
    let all_criteres: any[] = [];

    for (let ech of echantillons) {
        for (let res of ech.resultats) {
            const existing = all_criteres.find(cri => cri.name === res.critere_court)

            if (!existing) all_criteres.push({ name: res.critere_court, unite_mesure: res.unite_mesure, ordre_affichage: res.ordre_affichage, displayBold: true })
        }
    }
    all_criteres.sort((a, b) => a.ordre_affichage - b.ordre_affichage)

    for (let i = 0; i < all_criteres.length; i += chunk_size) {
        const criteres = all_criteres.slice(i, i + chunk_size)
        sliced_criteres.push(criteres)

        let echs = []
        for (let ech of echantillons) {
            const entry: any = {
                id: ech.id,
                date_prelevement: ech.date_prelevement,
                litrage: ech.litrage,
                statut: ech.statut,
                criteres: []
            }

            for (let cri of criteres) {
                const res = ech.resultats.find((r: any) => r.critere_court === cri.name)
                entry.criteres.push(res
                    ? { ...res, displayBold: ['N', 'P'].includes(ech.visibilite) || ['N', 'P'].includes(res.visibilite) }
                    : { valeur: '' }
                )
            }

            echs.push(entry)
        }
        sliced_data.push(echs)
    }

    return (
        <Modal customLayout variant="data" open size="xl" centered={false} text_button="Fermer" onClose={onClose}>
            <Modal.Header>
                <div className="d-flex align-items-center m-b-30">
                    <div className="text-left m-r-30">
                        <span className="lead"><strong>{dateFormat(references.mois_facturation, 'MMMM YYYY', true)}</strong></span>
                    </div>
                    {onExportPdf && (<button className="btn btn-success" type="button" onClick={() => onExportPdf(references.mois_facturation, references.prd_siret, references.prn_id)}>Exporter</button>)}
                </div>
            </Modal.Header>
            <Modal.Body>
                <section>
                    <h4 className="modal-subtitle">Informations générales</h4>
                    <DATALIST>
                        <DATALIST.ITEM title="Producteur" value={references.producteur} />
                        <DATALIST.ITEM title="Nature et espèce de lait" value={references.type_lait + " " + references.tank} />
                        <DATALIST.ITEM title="Centre d'appartenance" value={references.centre_appartenance} />
                        <DATALIST.ITEM title="Centre de collecte" value={references.centre_collecte} />
                        <DATALIST.ITEM title="Laboratoire" value={references.laboratoire} />
                        <DATALIST.ITEM title="Signataire" value={references.signataire} />
                    </DATALIST>
                </section>
                <section>
                    <h4 className="modal-subtitle">Résultats des échantillons</h4>
                    {sliced_criteres.map((criteres, index) => (
                        <TABLE variant="blue" responsive="accordion" bordered key={index}>
                            <TABLE.THEAD>
                                <TABLE.TR>
                                    <TABLE.TH>Date prélev.</TABLE.TH>
                                    <TABLE.TH>Litrage</TABLE.TH>
                                    <TABLE.TH>Statut</TABLE.TH>
                                    {criteres.map((critere: any, index: number) => (<React.Fragment key={index}>
                                        <TABLE.TH>
                                            <div>{critere.name}</div>
                                            {critere.unite_mesure && <div><small>({critere.unite_mesure})</small></div>}
                                        </TABLE.TH>
                                        <TABLE.TH><small>Note</small></TABLE.TH>
                                    </React.Fragment>
                                    ))}
                                </TABLE.TR>
                            </TABLE.THEAD>
                            <TABLE.TBODY>
                                {sliced_data[index].map((ech: any) => (
                                    <TABLE.TR key={ech.id} title={dateFormat(ech.date_prelevement, 'DD/MM/YYYY')}>
                                        <TABLE.TD hiddenMobile><strong>{dateFormat(ech.date_prelevement, 'DD/MM/YYYY')}</strong></TABLE.TD>
                                        <TABLE.TD label="Litrage" rightAlign>{ech.litrage || '-'}</TABLE.TD>
                                        <TABLE.TD label="Statut">{ech.statut || '-'}</TABLE.TD>
                                        {ech.criteres.map((critere: any, index: number) => (<React.Fragment key={index}>
                                            <TABLE.TD
                                                label={`${critere?.critere || '-'}${critere?.unite_mesure ? ' (' + critere?.unite_mesure + ')' : ''}`}
                                                hiddenMobile={!critere?.valeur}
                                                rightAlign={!['ANN', 'SUS'].includes(critere?.statut?.code) && critere.valeur}
                                            >
                                                {['ANN', 'SUS'].includes(critere?.statut?.code) ? (
                                                    <>{critere?.statut?.libelle}</>
                                                ) : (<span className={classNames({ "font-weight-bold": critere.displayBold })}>
                                                    {!exists(critere.valeur) && !exists(critere.appreciation) && <>-</>}
                                                    {exists(critere.valeur) && (<>
                                                        {critere.hors_paiement ? (<span data-tip="Résultat hors paiement">({critere?.signe}{numberFormat(critere.valeur, critere?.nb_decimales)})</span>) : (<>{critere?.signe}{numberFormat(critere.valeur, critere?.nb_decimales)}</>)}
                                                    </>)}
                                                    {exists(critere.appreciation) && (<>
                                                        {critere.hors_paiement ? (<span data-tip="Résultat hors paiement">({critere.appreciation})</span>) : (<>{critere.appreciation}</>)}
                                                    </>)}
                                                </span>)}
                                            </TABLE.TD>
                                            <TABLE.TD
                                                label="Note"
                                                accordionLabelSize="medium"
                                                hiddenMobile={!critere?.valeur}
                                            >
                                                {['ANN', 'SUS'].includes(critere?.statut?.code) ? (
                                                    <>-</>
                                                ) : (
                                                    <>{critere.note || '-'}</>
                                                )}
                                            </TABLE.TD>
                                        </React.Fragment>
                                        ))}
                                    </TABLE.TR>
                                ))}
                            </TABLE.TBODY>
                        </TABLE>
                    ))}
                </section>
                {resultats_mensuels_pour_paiement.length > 0 && (
                    <section>
                        <h4 className="modal-subtitle">Résultats mensuels pour paiement</h4>
                        <TABLE variant="blue" responsive="accordion">
                            <TABLE.THEAD>
                                <TABLE.TR>
                                    <TABLE.TH>Critère</TABLE.TH>
                                    <TABLE.TH className="table-min-content p-r-50">Résultat mensuel</TABLE.TH>
                                    <TABLE.TH>Classe</TABLE.TH>
                                    <TABLE.TH>Majoration / Pénalité</TABLE.TH>
                                    <TABLE.TH>Prime</TABLE.TH>
                                </TABLE.TR>
                            </TABLE.THEAD>
                            <TABLE.TBODY>
                                {resultats_mensuels_pour_paiement.map((rem: any) => (
                                    <TABLE.TR key={rem.id} title={`${rem?.cri_id?.lib_court} ${rem?.uni_id?.lib_long ? '(' + rem.uni_id.lib_long + ')' : ''}`}>
                                        <TABLE.TD hiddenMobile><strong>{rem?.cri_id?.lib_court}</strong> {rem?.uni_id?.lib_long ? '(' + rem.uni_id.lib_long + ')' : ''}</TABLE.TD>
                                        <TABLE.TD label="Résultat mensuel" rightAlign={exists(rem.valeur) && !exists(rem.apr_id)} className="p-r-50">
                                            <span className={classNames({ "font-weight-bold": ['N', 'P'].includes(rem.visibilite) })}>
                                                {!exists(rem.valeur) && !exists(rem.apr_id) && <>-</>}
                                                {exists(rem.valeur) && <span>{numberFormat(rem.valeur, rem?.cri_id?.nb_decimales)}</span>}
                                                {exists(rem.apr_id) && <span>{rem?.apr_id?.libelle}</span>}
                                            </span>
                                        </TABLE.TD>
                                        <TABLE.TD label="Classe">{rem?.classe_mensuelle_paie || '-'}</TABLE.TD>
                                        <TABLE.TD label="Majoration / Pénalité">{rem?.majoration_penalite ? `${rem?.signe_majoration_penalite ?? ''}${rem?.majoration_penalite}` : '-'}</TABLE.TD>
                                        <TABLE.TD label="Prime">
                                            {!exists(rem.tpr_id_1) && !exists(rem.tpr_id_2) && rem.primes_supplementaires.length === 0 && <>-</>}
                                            {exists(rem?.tpr_id_1) && <div>{rem?.tpr_id_1?.lib_long}</div>}
                                            {exists(rem?.tpr_id_2) && <div>{rem?.tpr_id_2?.lib_long}</div>}
                                            {rem?.primes_supplementaires.map(((pri: any) => (
                                                <div key={pri.id}>{pri?.tpr_id?.lib_long}</div>
                                            )))}
                                        </TABLE.TD>
                                    </TABLE.TR>
                                ))}
                            </TABLE.TBODY>
                        </TABLE>
                    </section>
                )}

                {autres_resultats_mensuels.length > 0 && (
                    <section>
                        <h4 className="modal-subtitle">Autres résultats mensuels</h4>
                        <TABLE variant="blue" responsive="accordion">
                            <TABLE.THEAD>
                                <TABLE.TR>
                                    <TABLE.TH>Critère</TABLE.TH>
                                    <TABLE.TH>Type de résultat</TABLE.TH>
                                    <TABLE.TH>Nb. mois</TABLE.TH>
                                    <TABLE.TH>Résultat</TABLE.TH>
                                </TABLE.TR>
                            </TABLE.THEAD>
                            <TABLE.TBODY>
                                {autres_resultats_mensuels.map((rem: any) => (
                                    <TABLE.TR key={rem.id} title={`${rem?.cri_id?.lib_court} ${rem?.uni_id?.lib_long ? '(' + rem.uni_id.lib_long + ')' : ''}`}>
                                        <TABLE.TD hiddenMobile><strong>{rem?.cri_id?.lib_court}</strong> {rem?.uni_id?.lib_long ? '(' + rem.uni_id.lib_long + ')' : ''}</TABLE.TD>
                                        <TABLE.TD label="Type de résultat">{rem?.trm_id?.lib_long}</TABLE.TD>
                                        <TABLE.TD label="Nb. mois">{rem?.nb_mois || '-'}</TABLE.TD>
                                        <TABLE.TD label="Résultat">
                                            <span className={classNames({ "font-weight-bold": ['N', 'P'].includes(rem.visibilite) })}>
                                                {!exists(rem.valeur) && !exists(rem.apr_id) && <>-</>}
                                                {exists(rem.valeur) && <span>{numberFormat(rem.valeur, rem?.cri_id?.nb_decimales)}</span>}
                                                {exists(rem.apr_id) && <span>{rem?.apr_id?.libelle}</span>}
                                            </span>
                                        </TABLE.TD>
                                    </TABLE.TR>
                                ))}
                            </TABLE.TBODY>
                        </TABLE>
                    </section>
                )}
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    )
}