import { withRouter } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import CustomComponent from '../../components/CustomComponent';
import { DefaultLayout } from "../../components/Layouts";
import Panel from '../../components/Panel';
import TABLE from '../../components/Table';
import { TOAST_TYPE, sendToast, slugify } from '../../helpers/helpers';

const UNSORTABLE_CRITERES_COUNT = 8

class Parametres extends CustomComponent {
    private _isMounted = false;
    private prd_id
    private per_id

    constructor(props: any) {
        super(props);

        const session = this.getLocalStorageSession() as any
        this.prd_id = session?.prd_id
        this.per_id = session?.per_id

        this.state = {
            nom_usuel: '',
            productions: [],
            production: null
        }
    }

    componentDidMount(): void {
        this._isMounted = true;

        this.getProductions()
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    getProductions = async () => {
        const URL = `/resultats/producteur/${this.prd_id}`
        const data = await this.request(URL, 'GET')

        if (data.statusCode === 200) {
            let productions = data.producteur.productions.map((p: any) => {
                const criteres = p.criteres.map((critere: any) => ({
                    id: critere.id,
                    cri_id: critere?.cri_id?.id,
                    libelle: critere?.cri_id?.lib_court,
                    unite_mesure: critere?.uni_id?.lib_court,
                    ordre_affichage: critere?.cri_id?.ordre_affichage
                }))

                return {
                    id: p?.id,
                    espece_collectee: p?.esp_id?.lib_court,
                    nature_lait: p?.nat_id?.libelle,
                    tank_code: p?.tank_code || '',
                    centre_appartenance: {
                        nom_usuel: p?.ent_id_ca?.nom_usuel,
                        siret: p?.ent_id_ca?.siret
                    },
                    laboratoire: {
                        nom_usuel: p?.laboratoire.nom_usuel,
                        siret: p?.laboratoire.siret
                    },
                    unsortable_criteres: criteres.filter((cri: any) => cri.ordre_affichage <= UNSORTABLE_CRITERES_COUNT),
                    sortable_criteres: criteres.filter((cri: any) => cri.ordre_affichage > UNSORTABLE_CRITERES_COUNT),
                    criteres: []
                }
            })

            // TODO: Sorti pro

            let production = productions[0] || null

            this._isMounted && this.setState({
                productions,
                production,
                nom_usuel: data?.ent_id?.nom_usuel
            })
        }
    }

    handleSortProduction = (updated: any) => {
        this._isMounted && this.setState({productions: updated})
    }

    handleSortCriteres = (updated: any) => {
        const updatedProduction = {
            ...this.state.production,
            sortable_criteres: updated
        }

        this._isMounted && this.setState((prev: any) => ({
            productions: prev.productions.map((production: any) => {
                if (production.id === updatedProduction.id) return updatedProduction
                return production
            }),
            production: updatedProduction
        }))
    }

    handleSave = async () => {
        const URL = `/parametrage/ordre_affichage`

        const BODY = {
            per_parametrage_producteur: {
                per_id: this.per_id,
                prd_id: this.prd_id,
                ordre_affichage_prn: JSON.stringify(this.state.productions.map((production: any) => production.id))
            },
            per_parametrage_productions: this.state.productions.map((production: any) => ({
                per_id: this.per_id,
                prn_id: production.id,
                ordre_affichage_cri: JSON.stringify(production.sortable_criteres.map(((cri_uni: any) => cri_uni.cri_id)))
            }))
        }

        this.setState({isLoading: true})
        const data = await this.request(URL, 'POST', BODY)
        this.setState({isLoading: false})

        if (data && data.statusCode === 201) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
        }
    }

    render () {
        return (
            <DefaultLayout>
                <div className="container">
                    <BreadCrumb crumbs={[{name: 'Mon paramétrage'}]} />
                    <div className="d-flex justify-content-between align-items-center m-b-40">
                        <h1 className="main-title m-b-0">Mon paramétrage - {this.state.nom_usuel}</h1>
                        <button type="button" onClick={this.handleSave} className="btn btn-secondary">Enregistrer</button>
                    </div>

                    <Panel open title="Définir l'ordre d'affichage des productions" togglableID="parameter-panel-1">
                        <TABLE responsive='card'>
                            <TABLE.THEAD>
                                <TABLE.TR>
                                    <TABLE.TD>Ordre</TABLE.TD>
                                    <TABLE.TD>Espèce</TABLE.TD>
                                    <TABLE.TD>Nature de lait</TABLE.TD>
                                    <TABLE.TD>N° tank</TABLE.TD>
                                    <TABLE.TD>Centre d'appartenance</TABLE.TD>
                                    <TABLE.TD>Laboratoire</TABLE.TD>
                                </TABLE.TR>
                            </TABLE.THEAD>
                            <TABLE.TBODY sortableList={this.state.productions} setSortableList={this.handleSortProduction}>
                                {this.state.productions.map((production: any, index: number) => (
                                    <TABLE.TR key={production.id}>
                                        <TABLE.TD sortableHandle label="Ordre">{index + 1}</TABLE.TD>
                                        <TABLE.TD label="Espèce"><b>{production.espece_collectee}</b></TABLE.TD>
                                        <TABLE.TD label="Nature de lait"><b>{production.nature_lait}</b></TABLE.TD>
                                        <TABLE.TD label="N° tank">{production.tank_code}</TABLE.TD>
                                        <TABLE.TD label="Centre d'appartenance">{production.centre_appartenance.siret}<br/>{production.centre_appartenance.nom_usuel}</TABLE.TD>
                                        <TABLE.TD label="Laboratoire">{production.laboratoire.siret}<br/>{production.laboratoire.nom_usuel}</TABLE.TD>
                                    </TABLE.TR>
                                ))}
                            </TABLE.TBODY>
                        </TABLE>
                    </Panel>

                    <Panel open title="Définir l'ordre d'affichage des critères" togglableID="parameter-panel-2">
                    <ul className="nav nav-tabs m-t-10 m-t-md-0 m-r-md-15">
                        {this.state.productions.map((production: any) => (
                            <li key={production.id} className="nav-item">
                                <button
                                    className={`nav-link tab-icon ${production.id === this.state?.production?.id ? 'active': ''}`}
                                    onClick={() => this.setState({production})}>
                                        <img className="tab-icon__icon" src={`/icons/icon_${slugify(production.nature_lait)}.svg`} alt=""/>
                                        <img className="tab-icon__icon" src={`/icons/icon_${slugify(production.espece_collectee)}.svg`} alt=""/>
                                        <div className="tab-icon__content">
                                            <div className="tab-icon__type">
                                                <strong>{production.espece_collectee}</strong>
                                                <span> {production.nature_lait}</span>
                                                {production.tank_code && (<span> (Tank #{production.tank_code})</span>)}
                                            </div>
                                            <div className="tab-icon__info">{production.centre_appartenance.nom_usuel} - {production.centre_appartenance.siret}</div>
                                        </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                    {this.state.production && (
                        <TABLE responsive='card'>
                            <TABLE.THEAD>
                                <TABLE.TR>
                                        <TABLE.TD>Ordre</TABLE.TD>
                                        <TABLE.TD>Critère d'analyse</TABLE.TD>
                                        <TABLE.TD>Unité de mesure</TABLE.TD>
                                    </TABLE.TR>
                                </TABLE.THEAD>
                                <TABLE.TBODY>
                                    {this.state.production.unsortable_criteres.map((critere: any, index: number) => (
                                        <TABLE.TR key={critere.id}>
                                            <TABLE.TD sortableHandle sortableDisabled label="Ordre">{index + 1}</TABLE.TD>
                                            <TABLE.TD label="Critère"><b>{critere.libelle || ''}</b></TABLE.TD>
                                            <TABLE.TD label="Unité de mesure">{critere.unite_mesure || ''}</TABLE.TD>
                                        </TABLE.TR>
                                    ))}
                                </TABLE.TBODY>
                                <TABLE.TBODY sortableList={this.state.production.sortable_criteres} setSortableList={this.handleSortCriteres}>
                                    {this.state.production.sortable_criteres.map((critere: any, index: number) => (
                                        <TABLE.TR key={critere.id}>
                                            <TABLE.TD sortableHandle label="Ordre">{index + this.state.production.unsortable_criteres.length + 1}</TABLE.TD>
                                            <TABLE.TD label="Critère"><b>{critere.libelle || ''}</b></TABLE.TD>
                                            <TABLE.TD label="Unité de mesure">{critere.unite_mesure || ''}</TABLE.TD>
                                        </TABLE.TR>
                                    ))}
                                </TABLE.TBODY>
                        </TABLE>
                    )}
                    </Panel>
                </div>
            </DefaultLayout>
        )
    }
}

export default withRouter(Parametres)
