import { Props } from "framer-motion/types/types";
import React from "react";
import { Collapse, Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { hasPermission } from '../helpers/helpers';

export const SortbyColumnButton = ({ onClick, active, direction }: any) => {
    return (
        <button className="table-sort" onClick={onClick} type="button">
            {!active && <i className="icon-sort"></i>}
            {(active && direction === 'ASC') && <i className="icon-sort-asc"></i>}
            {(active && direction === 'DESC') && <i className="icon-sort-desc"></i>}
        </button>
    )
};

export const LimitValuesSelect = ({ default_value, steps, onChange }: any) => {

    return (
        <div className="select">
            <label htmlFor="select">Afficher par</label>
            <select className="form-control" name="select" id="select" onChange={onChange} value={default_value}>
                {
                    steps.map((i: number, index: number) =>
                        <option key={index} value={i}>{i}</option>
                    )
                }
            </select>
        </div>
    )
};

export const TableNestedToggle = () => {
    return (
        <button className="action-btn action-btn--collapse"></button>
    )
}

interface TableNestedData {
    id: any,
    [key: string]: any
}

interface TableNestedProps extends Props {
    colspan: number,
    data: TableNestedData[],
    seeAllLink: string,
    table: 'per_personne'|'ent_entite'|'group'|'regroupement'|'rapport',
    show: boolean,
    limit?: number,
    canShow?: boolean,
    canUpdate?: boolean,
    canDelete?: boolean,
    total: number,
    id?: any,
    noActions?: boolean,
}

export const TableNested = ({colspan, data, table, seeAllLink, show, limit = 5, canShow = false, canUpdate = false, canDelete = false, total, id, noActions = false}: TableNestedProps) => {
    const VALUES: {[key: string]: any} = {
        'per_personne': {icon: 'person', label: 'personnes', basePath: '/annuaire/personne', permissions: {
            show: 'DRT_ANNU_PER_CONSULTER', update: 'DRT_ANNU_PER_GERER', delete: 'DRT_ANNU_PER_GERER'
        }},
        'ent_entite': {icon: 'city', label: 'entités', basePath: '/annuaire/entite', permissions: {
            show: 'DRT_ANNU_ENT_CONSULTER', update: 'DRT_ANNU_ENT_GERER', delete: 'DRT_ANNU_ENT_GERER'
        }},
        'group': {icon: 'group', label: 'groupes', basePath: '/annuaire/groupe', permissions: {
            show: 'DRT_ANNU_GROUPE', update: 'DRT_ANNU_GROUPE', delete: 'DRT_ANNU_GROUPE'
        }},
        'ent_regroupement': {icon: 'workspace', label: 'regroupements', basePath: '/annuaire/regroupement', permissions: {
            show: 'TODO', update: 'TODO', delete: 'TODO'
        }},
        'rapport': {icon: null, label: 'rapports', basePath: '/flux/abonnements', permissions: {
            show: '', update: '', delete: ''
        }},
    }

    const filterKeys: string[] = ['id', 'canShow', 'canUpdate', 'canDelete'];

    const {icon, label, basePath, permissions} = VALUES[table]

    const columns = Object.values(data[0]).length + 1

    return (<>
        <tr className='table-nested'>
            <td className="table-nested-wrap" colSpan={colspan}>
                <Collapse in={show}>
                    <div>
                        <table className="table table-custom">
                            <thead>
                                <tr className="table-nested-header">
                                    <th colSpan={columns}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <strong>({data.length}/{total}) {label}</strong>
                                            {(data.length > limit || data.length < total) && (
                                                <Link className='btn btn-small btn-dark' to={`${seeAllLink}`}>Voir plus</Link>
                                            )}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((entry: any) => (
                                    <tr key={entry.id}>
                                        {icon && <td className="table-icon"><i className={`icon-${icon}`}></i></td>}
                                        {Object.values(entry).filter((_, i) => !filterKeys.includes(Object.keys(entry)[i])).map((data: any, i: number) => (
                                            (!data || ['string', 'number'].includes(typeof data)) ? (
                                                <td key={i}>{data || ''}</td>
                                            ) : (
                                                <td key={i}>
                                                    <div className="mobile-only table-card__label">{data.label || ''}</div>
                                                    <div className="mobile-only table-card__value">{data.value || ''}</div>
                                                    <div className="desktop-only">{data.value || ''}</div>
                                                </td>
                                            )
                                        ))}
                                        {!noActions && (
                                            <td className="table-actions">
                                                <div className="d-flex desktop-only">
                                                    {canShow && hasPermission(permissions.show) && (!('canShow' in entry && entry.canShow !== undefined) || entry.canShow) && (
                                                        <Link className="action-btn" to={`${basePath}/${entry.id}`}><i className="icon-eye"></i></Link>
                                                    )}
                                                    {canUpdate && hasPermission(permissions.update) && (!('canUpdate' in entry && entry.canUpdate !== undefined) || entry.canUpdate) && (
                                                        <Link className="action-btn" to={`${basePath}/${entry.id}/modifier`}><i className="icon-pen"></i></Link>
                                                    )}
                                                    {canDelete && hasPermission(permissions.delete) && (!('canDelete' in entry && entry.canDelete !== undefined) || entry.canDelete) && (
                                                        <Link className="action-btn" to={`${basePath}/${entry.id}/supprimer`}><i className="icon-trash"></i></Link>
                                                    )}
                                                </div>
                                                <Dropdown className="mobile-only">
                                                    <Dropdown.Toggle as={CustomToggle}>
                                                        <span className="sr-only">Ouvrir les actions</span>
                                                        <i className="icon-actions"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {canShow && hasPermission(permissions.show) && (!('canShow' in entry && entry.canShow !== undefined) || entry.canShow) && (
                                                            <Link className="dropdown-item" to={`${basePath}/${entry.id}`}>Consulter</Link>
                                                        )}
                                                        {canUpdate && hasPermission(permissions.update) && (!('canUpdate' in entry && entry.canUpdate !== undefined) || entry.canUpdate) && (
                                                            <Link className="dropdown-item" to={`${basePath}/${entry.id}/modifier`}>Modifier</Link>
                                                        )}
                                                        {canDelete && hasPermission(permissions.delete) && (!('canDelete' in entry && entry.canDelete !== undefined) || entry.canDelete) && (
                                                            <Link className="dropdown-item" to={`${basePath}/${entry.id}/supprimer`}>Supprimer</Link>
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Collapse>
            </td>
        </tr>
        <tr className='table-nested-fix'></tr>
    </>)
}

const CustomToggle = React.forwardRef<HTMLButtonElement, any>(({ children, onClick }: any, ref) => (
    <button
        className="btn-icon btn-icon--white"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));
