export enum TYPES_RESULTATS {
    RJC = 'RJC',
    RJP = 'RJP',
    RM = 'RM'
}

export const TYPES_RESULTATS_LABEL: {[key: string]: string} = {
    [TYPES_RESULTATS.RJC]: 'Résultats Journaliers Citerne',
    [TYPES_RESULTATS.RJP]: 'Résultats Journaliers Producteur',
    [TYPES_RESULTATS.RM]: 'Résultats Mensuels',
};

export const TYPES_RESULTATS_OPTIONS = Object.entries(TYPES_RESULTATS_LABEL).map(([value, label]) => ({
    value, label
}))