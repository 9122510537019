import React from "react";

const CustomToggle = React.forwardRef<HTMLButtonElement, any>(({ children, onClick }: any, ref) => (
    <button
        className="btn-icon btn-icon--white"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
))

export default CustomToggle