import { SW_INIT, SW_UPDATE, UPDATE_SESSION } from './types';

function rootReducer(state: any, action: any) {
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    case UPDATE_SESSION:
      console.log('update session')
      return {
        ...state,
        sessionUpdatedAt: new Date()
      }
    default:
      return state;
  }
}

export default rootReducer;
