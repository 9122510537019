import saveAs from "file-saver";
import { dateFormat, getSession, getUrlParams, Request } from "../helpers/helpers";

type IExportCSVAnnuaire = (
    type: 'LISTE_PERSONNES'|'LISTE_ENTITES'|'GROU'|'LISTE_REGROUPEMENTS'|'EXAN',
    filters: {[key: string]: any},

) => Promise<void>;

const URL_MAP = {
    'LISTE_PERSONNES': '/exports/personnes',
    'LISTE_ENTITES': '/exports/entites',
    'GROU': '/exports/groupes',
    'LISTE_REGROUPEMENTS': '/exports/regroupements',
    'EXAN': '/exports/annuaire',
}

export const exportCSVAnnuaire: IExportCSVAnnuaire = async (type, filters) => {
    const URL = URL_MAP[type]
    const params = getUrlParams(filters, {});
    const SIRET = getSession().ent_siret;
    const DATE = dateFormat(undefined, 'YYYYMMDD');

    const FILENAME = `${SIRET}_${type}_R_${DATE}.csv`;

    const blob = await Request(`${URL}?${params}`, 'POST', {}, false, false, true);
    if (blob) saveAs(blob, FILENAME);
}

export const exportAnnuaire = async () => {
    const URL = '/exports/annuaire';
    const data = await Request(URL, 'POST');
    return data;
}