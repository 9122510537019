import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomComponent from '../../components/CustomComponent';
import { TOAST_TYPE, sendToast } from '../../helpers/helpers';
import { UPDATE_SESSION } from '../../redux/types';

class Logout extends CustomComponent {

    componentDidMount() {
        // if (this.checkIsConnectedPWA()) {
        this.logout();
        // }
    }

    async logout() {
        const logoutChannel = new BroadcastChannel('logout');

        let url = "/auth/logout";
        let method = "POST";
        this.request(url, method).then((data: any) => {
            data.message && sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
        });

        localStorage.clear();
        this.props.dispatch({ type: UPDATE_SESSION })
        this.props.history.push('/login');
        logoutChannel.postMessage({ type: 'logout' });
    }

    render() {
        return (
            <>
            </>
        )
    }
}

export default (connect((state: any) => ({ sessionUpdatedAt: state.sessionUpdatedAt }))(withTranslation()(withRouter(Logout))));
