import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb";
import CustomComponent from "../../../components/CustomComponent";
import { DefaultLayout } from "../../../components/Layouts";
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import TABLE from "../../../components/Table";
import { ToggleSwitch } from "../../../components/ToggleSwitch";
import { slugify } from "../../../helpers/helpers";
import { ResultatDetailsHeader } from "../_common/ResultatsDetailsHeader";
import ResultatJournalier from "./Journalier/ResultatJournalier";
import ResultatMensuel from "./Mensuel/ResultatMensuel";
import { RES_TYPES_RESULTATS } from "../../../enum/res_types_resultats";

class ResultatsProductionDetails extends CustomComponent {
    private _isMounted = false;
    private ent_id = this.props?.ent_id || this.props?.match?.params?.ent_id
    private prn_id = this.props?.prn_id || this.props?.match?.params?.prn_id

    constructor(props: any) {
        super(props);
        this.state = {
            exploitation_name: "",
            lab_gestion: null,
            productions: [],
            production: null,
            type_resultats: this.getResultsType(),
            displayAsCharts: false,
            paginate: {
                count: 0,
                limit: 15,
                page: 0,
                pageTotal: 0,
            },
            isLoading: true
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Liste des entités';
        this.handleViewSwitch()
        this.getProductions()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getResultsType = () => {
        const params = new URLSearchParams(this.props.location.search);
        if (!params.get('typeRes')) return RES_TYPES_RESULTATS.JOURNALIER;
        return params.get('typeRes');
    }

    async getProductions () {
        const URL = `/resultats/producteur/${this.ent_id}`
        const data = await this.request(URL, 'GET')

        this.setState({isLoading: false})

        if (data?.statusCode === 200 && data.producteur) {
            let productions = data.producteur.productions.map((p: any) => ({
                id: p.id,
                espece_collectee: p.esp_id.lib_court,
                nature_lait: p.nat_id.libelle,
                tank_code: p.tank_code || '',
                nom_usuel: p.ent_id_ca.nom_usuel,
                siret: p.ent_id_ca.siret,
                lab_id: p.lab_id,
                lab_name: p.laboratoire.nom_usuel,
                pdf_siret_producteur: data.producteur.ent_id.siret,
                pdf_nom_production: `${data.producteur.ent_id.nom_usuel} - ${p.esp_id.lib_long} ${p.nat_id.lib_long} ${p.tank_code ? `(${p.tank_code})` : ''}`,
                pdf_centre_appartenance: `${p.ent_id_ca.siret} - ${p.ent_id_ca.nom_usuel}`,
            }))

            let production = productions[0] || null

            if (this.prn_id) {
                productions = [productions.find((prn: any) => prn.id === +this.prn_id) || null]
                production = productions.find((prn: any) => prn.id === +this.prn_id) || null
            }

            this._isMounted && this.setState({
                exploitation_name: `${data.producteur.ent_id.nom_usuel}`,
                lab_gestion: data.producteur.lab_gestion_id,
                productions,
                production
            })
        }
    }

    handleViewSwitch = (checked: boolean|null = null) => {
        const displayAsCharts = checked !== null
            ? checked
            : localStorage.getItem('displayResultsAsCharts') === 'true'

        this._isMounted && this.setState({displayAsCharts})
        localStorage.setItem('displayResultsAsCharts', displayAsCharts ? 'true': 'false')
    }

    render () {
        if (this.props.noLayout) return this.renderContent()

        return (
            <DefaultLayout>
                <div className="container">
                    <BreadCrumb crumbs={[{name: 'Résultats', path: '/resultats'}, {name: this.state.exploitation_name}]}/>
                    {this.renderContent()}
                </div>
            </DefaultLayout>
        )
    }

    renderResultats = () => {
        if (!this.state.production) return null

        switch (this.state.type_resultats) {
            case RES_TYPES_RESULTATS.JOURNALIER:
                return <ResultatJournalier displayAsCharts={this.state.displayAsCharts} production={this.state.production}/>
            case RES_TYPES_RESULTATS.MENSUEL:
                return <ResultatMensuel displayAsCharts={this.state.displayAsCharts} production={this.state.production}/>
            default: return null
        }
    }

    renderContent () {
        return (<>
            <LoadingIndicator loading={this.state.isLoading}/>
            {this.state.productions.length > 0 && (<>
                <ResultatDetailsHeader
                    type_resultats={this.state.type_resultats}
                    onTypeChange={(type: string) => this.setState({type_resultats: type})}
                    title={this.state.exploitation_name}/>
                <div className="d-md-flex flex-row-reverse align-items-center justify-content-between">
                    <ToggleSwitch
                        active={this.state.displayAsCharts}
                        labels={{off: 'Liste', on: 'Graphique'}}
                        onChange={this.handleViewSwitch}/>
                    <ul className="nav nav-tabs m-t-10 m-t-md-0 m-r-md-15">
                        {this.state.productions.map((production: any) => (
                            <li key={production.id} className="nav-item">
                                <button
                                    className={`nav-link tab-icon ${production.id === this.state?.production?.id ? 'active': ''}`}
                                    onClick={() => this.setState({production})}>
                                        <img className="tab-icon__icon" src={`/icons/icon_${slugify(production.nature_lait)}.svg`} alt=""/>
                                        <img className="tab-icon__icon" src={`/icons/icon_${slugify(production.espece_collectee)}.svg`} alt=""/>
                                        <div className="tab-icon__content">
                                            <div className="tab-icon__type">
                                                <strong>{production.espece_collectee}</strong>
                                                <span> {production.nature_lait}</span>
                                                {production.tank_code && (<span> (Tank #{production.tank_code})</span>)}
                                            </div>
                                            <div className="tab-icon__info">{production.nom_usuel} - {production.siret}</div>
                                        </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </>)}
            {this.state.productions.length === 0 && (
                <TABLE.EMPTY_MESSAGE message="Aucune production enregistrée pour cette exploitation" />
            )}
            {this.renderResultats()}
        </>)
    }
}



export default withTranslation()(withRouter(ResultatsProductionDetails));