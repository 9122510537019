import { PropsWithChildren } from "react";
import { Collapse } from "react-bootstrap";

interface TableDropdownProps extends PropsWithChildren {
    open?: boolean
    onToggle: Function,
    tourneeNb?: string|number
    collectionCenter?: string
    milkType?: string
    collectionDate?: string
}

export const TableDropdown = ({children, open = false, onToggle, tourneeNb, collectionCenter, milkType, collectionDate}: TableDropdownProps) => {
    const handleOpen = () => {
        onToggle && onToggle(!open)
    }

    return (
        <section className="tourneeDropdown">
            <button
                className="tourneeDropdown__btn"
                type="button"
                onClick={handleOpen}
                aria-expanded={open}
            >
                <h1 className="tourneeDropdown__title">Tournée {tourneeNb}</h1>
                <div className="tourneeDropdown__info">
                    <div className="tourneeDropdown__item">
                        <strong>Centre de collecte: </strong>
                        <span>{collectionCenter}</span>
                    </div>
                    <div className="tourneeDropdown__item">
                        <strong>Type de lait: </strong>
                        <span>{milkType}</span>
                    </div>
                    <div className="tourneeDropdown__item">
                        <strong>Date de collecte: </strong>
                        <span>{collectionDate}</span>
                    </div>
                </div>
            </button>
            <Collapse in={open}>
                <div>
                    {children}
                </div>
            </Collapse>
        </section>
    )
}
