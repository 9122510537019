import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { AnnuaireHeader } from '../_common/AnnuaireHeader';
import { AnnuaireTabs } from '../_common/AnnuaireTabs';
import CustomComponent from '../../../components/CustomComponent';
import { FilterDropdown } from '../../../components/FilterDropdown';
import { FormInput, FormInputSelect } from '../../../components/FormComponents';
import { DefaultLayout } from '../../../components/Layouts';
import Pagination from '../../../components/Pagination';
import { LimitValuesSelect, SortbyColumnButton, TableNested } from '../../../components/TableComponents';
import { hasPermission } from '../../../helpers/helpers';
import { exportCSVAnnuaire } from '../../../requests/exportCSVAnnuaire';
import { DebounceQueue } from '../../../helpers/debouncer';
import { TYPES_DOMAINES } from "../../../enum/types_domaines";

class ListPersonne extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private default_filters = {
        orderBy: 'nom',
        ordering: 'ASC',
        nom: '',
        prenom: '',
        ville: '',
        code_postal: '',
        email: '',
        telephone: '',
        entite: '',
        groupe: '',
        habilitation: []
    }

    constructor(props: any) {
        super(props);
        this.state = {
            personnes: [],
            filters: this.default_filters,
            recherches: [],
            options: {
                habilitations: [],
                groupes: [],
                entites: []
            },
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Liste des personnes';

        this.getPersonnes()
        this.getRecherches()
        this.getHabilitationOptions()
        this.getEntiteOptions()
        hasPermission('DRT_ANNU_GROUPE') && this.getGroupeOptions();
    }

    getHabilitationOptions = async () => {
        const habilitations = await this.request('/ref_habilitation/autocomplete', 'GET')
        if (habilitations && this._isMounted) {
            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    habilitations: habilitations
                }
            }))
        }
    }

    getEntiteOptions = async (e: any | null = null) => {
        let URL: string = '/ent_entite/autocomplete'

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        }

        const data = await this.request(URL, 'GET')
        if (data) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    entites: data.map((option: any) => ({ label: option.label, value: option.id }))
                }
            }));
        }
    }

    getGroupeOptions = async (e: any | null = null) => {
        let URL: string = '/ent_groupe/autocomplete';
        const params = new URLSearchParams()
        params.append('type_groupe', 'P')

        if (e) {
            if (e.action.action !== 'input-change') return;
            params.append('query', e.data)
        }

        const data = await this.request(`${URL}?${params.toString()}`, 'GET');
        if (data && data.statusCode === 200) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    groupes: data.groupes.map((option: any) => ({ label: option.nom, value: option.id }))
                }
            }));
        }
    }

    getPersonnes = async (triggeredByPagination: boolean = false) => {
        const params = new URLSearchParams()

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key]
            if (val !== '') params.append(key, val)
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key]
            if (val !== '') params.append(key, val)
        })

        params.append('domain', TYPES_DOMAINES.INTERNE_PRIVE);
        if (!triggeredByPagination) params.set('page', '0')

        const data = await this.request(`/per_personne?${params.toString()}`, 'GET')

        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                personnes: data.per_personnes,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }))
        }
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/ANNUAIRE_LISTE_PERSONNE`, 'GET')

        if (recherches) {
            this.setState({ recherches })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getPersonnes, [], true))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getPersonnes, [true], true))
    }

    toggleChildren = (id: number) => {
        this.setState((prevState: any) => ({
            ...prevState,
            personnes: prevState.personnes.map((per: any) => {
                if (per.id === id) {
                    return {
                        ...per,
                        showChildren: !per.showChildren
                    }
                }
                return per
            })
        }))
    }

    exportToCSV = async () => {
        this.setState({ isLoading: true })
        await exportCSVAnnuaire('LISTE_PERSONNES', this.state.filters)
        this.setState({ isLoading: false })
    }

    render() {
        return (
            <DefaultLayout>
                <div className="container">
                    <AnnuaireHeader />
                    <div className="row align-items-end">
                        <div className="col-lg-9 order-1 order-lg-0">
                            <AnnuaireTabs />
                        </div>
                        <div className="col-lg-3 order-0 order-lg-1">
                            <LimitValuesSelect
                                default_value={this.state.paginate.limit}
                                steps={[10, 20, 50]}
                                onChange={(e: any) => this.setState((prev: any) => ({ paginate: { ...prev.paginate, limit: +e.target.value, page: 0 } }), () => this.queue.push(this.getPersonnes, [], true))}
                            />
                        </div>
                    </div>

                    <FilterDropdown
                        parent={this}
                        type_recherche='ANNUAIRE_LISTE_PERSONNE'
                        onExport={hasPermission('DRT_ANNU_ENT_EXPORTER') ? { csv: this.exportToCSV } : undefined}
                        filters={this.state.filters}
                        recherches={this.state.recherches}
                        onUpdate={this.getRecherches}
                        onSearchLoad={(filters: any) => this.setState({ filters: filters }, () => this.queue.push(this.getPersonnes, [], true))}
                        onFiltersReset={() => {
                            this.setState({ filters: this.default_filters }, () => {
                                this.queue.push(this.getPersonnes, [], true);
                                this.getEntiteOptions();
                                this.getGroupeOptions();
                            })
                        }}
                        onFiltersSearch={() => this.queue.push(this.getPersonnes, [], true)}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormInput
                                        id="nom"
                                        name="nom"
                                        type="text"
                                        label="Nom"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                        value={this.state.filters.nom} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInput
                                        id="prenom"
                                        name="prenom"
                                        type="text"
                                        label="Prénom"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                        value={this.state.filters.prenom} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect
                                        options={this.state.options.entites}
                                        label="Entité de rattachement"
                                        name="entite"
                                        id="entite"
                                        handleInput={(e: any) => this.queue.push(this.getEntiteOptions, [e])}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                        value={this.state.filters.entite}
                                        isClearable={true} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect
                                        options={this.state.options.habilitations}
                                        label="Habilitation"
                                        name="habilitation"
                                        isMulti={true}
                                        id="habilitation"
                                        handle={(e: any) => this.setState((prev: any) => ({ filters: { ...prev.filters, habilitation: e.data.map((obj: any) => obj.value) } }), () => this.queue.push(this.getPersonnes))}
                                        value={this.state.filters.habilitation}
                                        isClearable={true} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormInput
                                        id="email"
                                        name="email"
                                        type="text"
                                        label="Email"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                        value={this.state.filters.email} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInput
                                        id="telephone"
                                        name="telephone"
                                        type="text"
                                        label="Téléphone"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                        value={this.state.filters.telephone} />
                                </div>
                                {hasPermission('DRT_ANNU_GROUPE') && (
                                    <div className="form-group form-group--inline">
                                        <FormInputSelect
                                            options={this.state.options.groupes}
                                            label="Groupe"
                                            name="groupe"
                                            id="groupe"
                                            handleInput={(e: any) => this.queue.push(this.getGroupeOptions, [e])}
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                            value={this.state.filters.groupe}
                                            isClearable={true} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </FilterDropdown>

                    <div className="table-responsive table-card-mobile">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Nom</span>
                                            <input
                                                value={this.state.filters.nom}
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                                name="nom"
                                                type="text"
                                                className="table-input"
                                                placeholder='Nom' />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'nom'} direction={this.state.filters.ordering} onClick={() => this.handleSort('nom')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Prénom</span>
                                            <input
                                                value={this.state.filters.prenom}
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                                name="prenom"
                                                type="text"
                                                className="table-input"
                                                placeholder='Prénom' />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'prenom'} direction={this.state.filters.ordering} onClick={() => this.handleSort('prenom')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Ville</span>
                                            <input
                                                value={this.state.filters.ville}
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                                name="ville"
                                                type="text"
                                                className="table-input"
                                                placeholder='Ville' />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'ville'} direction={this.state.filters.ordering} onClick={() => this.handleSort('ville')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Code postal</span>
                                            <input
                                                value={this.state.filters.code_postal}
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                                name="code_postal"
                                                type="text"
                                                className="table-input"
                                                placeholder='Code postal' />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'code_postal'} direction={this.state.filters.ordering} onClick={() => this.handleSort('code_postal')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Email</span>
                                            <input
                                                value={this.state.filters.email}
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))}
                                                name="email"
                                                type="text"
                                                className="table-input"
                                                placeholder='Email' />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'email'} direction={this.state.filters.ordering} onClick={() => this.handleSort('mail_identifiant')} />
                                        </div>
                                    </th>
                                    <th className="table-actions">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.personnes.map((personne: any) => (<React.Fragment key={personne.id}>
                                    <tr>
                                        <td className='table-icon'>
                                            {(personne.entites.length > 0 || personne.groupes?.length > 0) ? (
                                                <button
                                                    onClick={() => this.toggleChildren(+personne.id)}
                                                    aria-expanded={personne.showChildren}
                                                    className="action-btn action-btn--collapse">
                                                    <i className="icon-person"></i>
                                                </button>
                                            ) : (
                                                <span className="action-btn"><i className="icon-person"></i></span>
                                            )}
                                        </td>
                                        <td data-label="Nom">{personne.nom}</td>
                                        <td data-label="Prénom">{personne.prenom}</td>
                                        <td data-label="Ville">{personne.ville}</td>
                                        <td data-label="Code Postal">{personne.code_postal}</td>
                                        <td data-label="Email">{personne.mail_identifiant}</td>
                                        <td className='table-actions'>
                                            <div className="d-flex desktop-only">
                                                {(hasPermission('DRT_ANNU_PER_CONSULTER') && personne.isViewable) && <Link className='action-btn' to={`/annuaire/personne/${personne.id}`}><i className="icon-eye"></i></Link>}
                                                {(hasPermission('DRT_ANNU_PER_GERER') && personne.isEditable) && <Link className='action-btn' to={`/annuaire/personne/${personne.id}/modifier`}><i className="icon-pen"></i></Link>}
                                                {(hasPermission('DRT_ANNU_PER_GERER') && personne.isEditable) && <Link className='action-btn' to={`/annuaire/personne/${personne.id}/supprimer`}><i className="icon-trash"></i></Link>}
                                            </div>
                                            <Dropdown className="mobile-only">
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    <span className="sr-only">Ouvrir les actions</span>
                                                    <i className="icon-actions"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {(hasPermission('DRT_ANNU_PER_CONSULTER') && personne.isViewable) && <Link className='dropdown-item' to={`/annuaire/personne/${personne.id}`}>Consulter</Link>}
                                                    {(hasPermission('DRT_ANNU_PER_GERER') && personne.isEditable) && <Link className='dropdown-item' to={`/annuaire/personne/${personne.id}/modifier`}>Editer</Link>}
                                                    {(hasPermission('DRT_ANNU_PER_GERER') && personne.isEditable) && <Link className='dropdown-item' to={`/annuaire/personne/${personne.id}/supprimer`}>Supprimer</Link>}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                    {personne.entites.length > 0 && (
                                        <TableNested
                                            colspan={8}
                                            limit={personne.limitNested}
                                            total={personne.nombreEntite}
                                            data={personne.entites}
                                            seeAllLink={`/annuaire/personne/${personne.id}`}
                                            id={personne.id}
                                            table="ent_entite"
                                            show={personne.showChildren}
                                            canShow
                                            canUpdate
                                        />
                                    )}
                                    {!!personne.groupes?.length && (
                                        <TableNested
                                            colspan={8}
                                            limit={personne.limitNested}
                                            total={personne.nombreGroupes}
                                            data={personne.groupes.map((e: any) => ({ nom: e.nom, description: e.description, proprietaire: `${e.proprietaire?.siret} - ${e.proprietaire?.nom_usuel}`, id: e.id }))}
                                            seeAllLink={`/annuaire/personne/${personne.id}`}
                                            id={personne.id}
                                            table="group"
                                            show={personne.showChildren}
                                            canShow
                                            canUpdate
                                        />
                                    )}
                                </React.Fragment>))}
                            </tbody>
                        </table>
                        {this.state.personnes.length === 0 && <>Aucune donnée annuaire à afficher</>}
                    </div>

                    <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} />
                </div>
            </DefaultLayout>
        )
    }
}

const CustomToggle = React.forwardRef<HTMLButtonElement, any>(({ children, onClick }: any, ref) => (
    <button
        className="btn-icon btn-icon--white"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

export default withTranslation()(withRouter(ListPersonne));