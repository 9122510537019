import {useState} from 'react'
import { Alert } from "react-bootstrap"

const validVariants = ['success', 'danger', 'warning', 'info']

export interface INotification {
    variant?: typeof validVariants[number]
    title?: string
    text?: string
    link?: string
}

interface NotificationProps {
    notification: INotification
}

export const Notification = ({notification}: NotificationProps) => {
    const [show, setShow] = useState(true)

    const variant = validVariants.includes(notification.variant || '')
        ? notification.variant
        : 'info'

    const onClose = () => {
        setShow(false)
    }

    if (show) return (
        <Alert bsPrefix='notification' variant={variant} onClose={onClose}>
            <div className="notification__flex">
                <div className='notification__content'>
                    {notification.title && (<Alert.Heading className='notification__title'>{notification.title}</Alert.Heading>)}
                    {notification.text  && (<p className='notification__text'>{notification.text}</p>)}
                </div>
                {notification.link && (<a className='notification__link btn btn-dark' href={notification.link}>Voir plus</a>)}
                <button type='button' className='notification__close' onClick={onClose}>
                    <i className="icon-close"></i>
                    <span className='sr-only'>Fermer</span>
                </button>
            </div>
        </Alert>
    )
    return null
}