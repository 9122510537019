import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from "react";
import { Modal as BootstrapModal } from 'react-bootstrap';

const ModalContext = createContext<any>({})

interface ModalProps extends PropsWithChildren {
    title?: string
    customLayout?: boolean
    variant?: 'data'|'default'
    open?: boolean
    centered?: boolean
    link?: string
    closeButton?: boolean
    onClose?: any
    handle?: any
    text_button?: string
    size?: 'sm'|'md'|'lg'|'xl'
}
const Modal = (props: ModalProps) => {
    const {customLayout, variant = "default", open, centered = true, title = "", link, onClose, handle, text_button = "", children, closeButton, size = "sm"}  = props
    const [isOpen, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(false);
        handle && handle(false);
        onClose && onClose();
    }

    useEffect(() => setOpen(open || false), [open]);

    return (
        <ModalContext.Provider value={{...props, handleClick}}>
            <BootstrapModal show={isOpen} onHide={handle} className={`modal--notification modal--${size} ${centered ? '' : 'text-left'} modal--${variant}`} centered>
                {!customLayout && (<>
                    <BootstrapModal.Header closeButton={closeButton}>
                        {title && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
                    </BootstrapModal.Header>
                    <BootstrapModal.Body>
                        {children}
                    </BootstrapModal.Body>
                    <BootstrapModal.Footer>
                        {text_button && (<button onClick={handleClick} className="btn btn-secondary" data-dismiss={link ? '' : 'modal'}>{text_button}</button>)}
                    </BootstrapModal.Footer>
                </>)}
                {customLayout && <>{children}</>}
            </BootstrapModal>
        </ModalContext.Provider>
    );
}

interface HeaderProps extends PropsWithChildren {
    title?: string
}
const Header = ({children, title: headerTitle} : HeaderProps) => {
    const {title, closeButton} = useContext(ModalContext)

    return (
        <BootstrapModal.Header closeButton={closeButton}>
            {headerTitle && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
            {(!headerTitle && title) && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
            {children}
        </BootstrapModal.Header>
    )
}

interface BodyProps extends PropsWithChildren {}
const Body = ({children} : BodyProps) => {
    return (
        <BootstrapModal.Body>
            {children}
        </BootstrapModal.Body>
    )
}

interface FooterProps extends PropsWithChildren {}
const Footer = ({children} : FooterProps) => {
    const {text_button, link, handleClick} = useContext(ModalContext)

    return (
        <BootstrapModal.Footer>
            {text_button && (<button onClick={handleClick} className="btn btn-secondary" data-dismiss={link ? '' : 'modal'}>{text_button}</button>)}
            {children}
        </BootstrapModal.Footer>
    )
}

Modal.Header = Header
Modal.Body = Body
Modal.Footer = Footer
export default Modal
