import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import CustomComponent from '../../../components/CustomComponent';
import { FilterDropdown } from '../../../components/FilterDropdown';
import { FormInputSelect, FormRadioBoolean } from '../../../components/FormComponents';
import { DefaultLayout } from '../../../components/Layouts';
import Modal from '../../../components/Modal';
import Pagination from '../../../components/Pagination';
import { LimitValuesSelect, SortbyColumnButton, TableNested } from '../../../components/TableComponents';
import { DebounceQueue } from '../../../helpers/debouncer';
import { TOAST_TYPE, hasPermission, sendToast } from '../../../helpers/helpers';
import { exportCSVAnnuaire } from '../../../requests/exportCSVAnnuaire';
import { AnnuaireHeader } from '../_common/AnnuaireHeader';
import { AnnuaireTabs } from '../_common/AnnuaireTabs';

class ListRegroupement extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private default_filters = {
        orderBy: 'libelle',
        ordering: 'ASC',
        libelle: '',
        entite_maitre: '',
        entite_membre: '',
        siret: '',
        donneur_ordre: '',
        fl_actif: '1'
    }

    constructor(props: any) {
        super(props);
        this.state = {
            regroupements: [],
            deleteTarget: null,
            filters: this.default_filters,
            recherches: [],
            options: {
                entite_maitre: [],
                entite_membre: []
            },
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Liste des regroupements';

        this.getRecherches()
        this.getEntiteOptions()
        this.getRegroupements()
    }

    getRegroupements = async (triggeredByPagination: boolean = false) => {
        const params = new URLSearchParams()

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key]
            if (val !== '') params.append(key, val)
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key]
            if (val !== '') params.append(key, val)
        })

        if (!triggeredByPagination) params.set('page', '0')

        const data = await this.request(`/ent_regroupement?${params.toString()}`, 'GET')

        if (data && this._isMounted && !(data?.statusCode < 200 || data?.statusCode > 299)) {
            this.setState((prev: any) => ({
                regroupements: data.regroupements?.map((regroupement: any) => ({
                    ...regroupement,
                    fl_actif: regroupement.fl_actif === true ? '1' : '0'
                })),
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }))
        }
    }

    getEntiteOptions = async (e: any | null = null, attribute: string | null = null) => {
        let URL: string = '/ent_entite/autocomplete'
        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        }

        const data = await this.request(URL, 'GET')
        if (data && this._isMounted) {
            const options = data.map((option: any) => ({ label: option.label, value: option.siret }))

            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ...(attribute && { [attribute]: options }),
                    ...(!attribute && {
                        entite_maitre: options,
                        entite_membre: options
                    }),
                }
            }))
        }
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/ANNUAIRE_LISTE_REGROUPEMENT`, 'GET')

        if (recherches) {
            this.setState({ recherches })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getRegroupements, [], true))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getRegroupements, [true], true))
    }

    deleteRegroupement = async () => {
        const data = await this.request(`/ent_regroupement/${this.state.deleteTarget.id}`, 'DELETE')

        if (data && data.statusCode === 200) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            this.queue.push(this.getRegroupements, [], true)
            this.setState({ deleteTarget: null })
        }
    }

    toggleChildren = (id: number) => {
        this.setState((prevState: any) => ({
            ...prevState,
            regroupements: prevState.regroupements.map((per: any) => {
                if (per.id === id) {
                    return {
                        ...per,
                        showChildren: !per.showChildren
                    }
                }
                return per
            })
        }))
    }

    onFiltersSearch() {
        return
    }

    exportToCSV = async () => {
        this.setState({ isLoading: true })
        await exportCSVAnnuaire('LISTE_REGROUPEMENTS', this.state.filters)
        this.setState({ isLoading: false })
    }

    render() {
        return (
            <DefaultLayout>
                <div className="container">
                    <AnnuaireHeader />
                    <div className="row align-items-end">
                        <div className="col-lg-9 order-1 order-lg-0">
                            <AnnuaireTabs />
                        </div>
                        <div className="col-lg-3 order-0 order-lg-1">
                            <LimitValuesSelect
                                default_value={this.state.limit}
                                steps={[10, 20, 50]}
                                onChange={(e: any) => this.setState((prev: any) => ({ paginate: { ...prev.paginate, limit: +e.target.value, page: 0 } }), () => this.queue.push(this.getRegroupements, [], true))}
                            />
                        </div>
                    </div>

                    <FilterDropdown
                        parent={this}
                        type_recherche='ANNUAIRE_LISTE_REGROUPEMENT'
                        onExport={hasPermission('DRT_ANNU_REG_EXPORTER') ? { csv: this.exportToCSV } : undefined}
                        filters={this.state.filters}
                        recherches={this.state.recherches}
                        onUpdate={this.getRecherches}
                        onSearchLoad={(filters: any) => this.setState({ filters: filters }, () => this.queue.push(this.getRegroupements, [], true))}
                        onFiltersReset={() => {
                            this.setState({ filters: this.default_filters }, () => {
                                this.queue.push(this.getRegroupements, [], true);
                                this.getEntiteOptions(null);
                            })
                        }}
                        onFiltersSearch={this.onFiltersSearch}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormInputSelect
                                        id="siret"
                                        name="siret"
                                        value={this.state.filters.siret}
                                        label="Entité maître"
                                        options={this.state.options.entite_maitre}
                                        isClearable
                                        handleInput={(e: any) => this.queue.push(this.getEntiteOptions, [e, 'entite_maitre'])}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect
                                        id="entite_membre"
                                        name="entite_membre"
                                        value={this.state.filters.entite_membre}
                                        label="Entité membre"
                                        options={this.state.options.entite_membre}
                                        isClearable
                                        handleInput={(e: any) => this.queue.push(this.getEntiteOptions, [e, 'entite_membre'])}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormRadioBoolean
                                        label="Actif"
                                        name="fl_actif"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))}
                                        value={this.state.filters.fl_actif} />
                                </div>
                            </div>
                        </div>
                    </FilterDropdown>

                    <div className="table-responsive desktop-only">
                        <table className="table table-custom">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Libellé regroupement</span>
                                            <input
                                                value={this.state.filters.libelle}
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))}
                                                name="libelle"
                                                type="text"
                                                className="table-input"
                                                placeholder='Libellé regroupement' />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'libelle'} direction={this.state.filters.ordering} onClick={() => this.handleSort('libelle')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Nom de l'entité maître</span>
                                            <input
                                                value={this.state.filters.entite_maitre}
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))}
                                                name="entite_maitre"
                                                type="text"
                                                className="table-input"
                                                placeholder="Nom de l'entité maître" />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'entite_maitre'} direction={this.state.filters.ordering} onClick={() => this.handleSort('entite_maitre')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">N° SIRET de l'entité maître</span>
                                            <input
                                                value={this.state.filters.siret}
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))}
                                                name="siret"
                                                type="text"
                                                className="table-input"
                                                placeholder="N° SIRET de l'entité maître" />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'siret'} direction={this.state.filters.ordering} onClick={() => this.handleSort('siret')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Donneur d'ordre</span>
                                            <input
                                                value={this.state.filters.donneur_ordre}
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))}
                                                name="donneur_ordre"
                                                type="text"
                                                className="table-input"
                                                placeholder="Donneur d'ordre" />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'donneur_ordre'} direction={this.state.filters.ordering} onClick={() => this.handleSort('siret')} />
                                        </div>
                                    </th>
                                    <th className="table-actions">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.regroupements?.map((regroupement: any) => (<React.Fragment key={regroupement.id}>
                                    <tr>
                                        <td>
                                            {regroupement.membres.length > 0 ? (
                                                <button
                                                    onClick={() => this.toggleChildren(+regroupement.id)}
                                                    aria-expanded={regroupement.showChildren}
                                                    className="action-btn action-btn--collapse">
                                                    <i className="icon-workspace"></i>
                                                </button>
                                            ) : (
                                                <span className="action-btn"><i className="icon-workspace"></i></span>
                                            )}
                                        </td>
                                        <td>{regroupement.libelle}</td>
                                        <td>{regroupement.entite_maitre}</td>
                                        <td>{regroupement.siret}</td>
                                        <td>{regroupement.donneur_ordre}</td>
                                        <td>
                                            <div className="d-flex">
                                                {hasPermission('DRT_ANNU_REG_CONSULTER') && <Link className='action-btn' to={`/annuaire/regroupement/${regroupement.id}`}><i className="icon-eye"></i></Link>}
                                                {hasPermission('DRT_ANNU_REG_GERER') && <Link className='action-btn' to={`/annuaire/regroupement/${regroupement.id}/modifier`}><i className="icon-pen"></i></Link>}
                                                {hasPermission('DRT_ANNU_REG_GERER') && <button className='action-btn' type='button' onClick={() => this.setState({ deleteTarget: regroupement })}><i className="icon-trash"></i></button>}
                                            </div>
                                        </td>
                                    </tr>
                                    {regroupement.membres.length > 0 && (
                                        <TableNested
                                            colspan={8}
                                            limit={regroupement.limitMembres}
                                            data={regroupement.membres}
                                            total={regroupement.nombreMembres}
                                            seeAllLink={`/annuaire/regroupement/${regroupement.id}`}
                                            table="ent_entite"
                                            label="personnes"
                                            show={regroupement.showChildren}
                                            canShow
                                            canUpdate />
                                    )}
                                </React.Fragment>))}
                            </tbody>
                        </table>
                    </div>

                    <div className="table-card mobile-only">
                        {this.state.regroupements?.map((regroupement: any) => (
                            <div className="table-card-item" key={regroupement.id}>
                                <div className="table-card-wrap">
                                    <Dropdown className="table-card-dropdown">
                                        <Dropdown.Toggle as={CustomToggle}>
                                            <span className="sr-only">Ouvrir les actions</span>
                                            <i className="icon-actions"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {hasPermission('DRT_ANNU_REG_CONSULTER') && <Link className='dropdown-item' to={`/annuaire/regroupement/${regroupement.id}`}>Consulter</Link>}
                                            {hasPermission('DRT_ANNU_REG_GERER') && <Link className='dropdown-item' to={`/annuaire/regroupement/${regroupement.id}/modifier`}>Editer</Link>}
                                            {hasPermission('DRT_ANNU_REG_GERER') && <Link className='dropdown-item' to={`/annuaire/regroupement/${regroupement.id}/modifier`}>Supprimer</Link>}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <dl className="table-card-data">
                                        <dt>Libellé regroupement</dt>
                                        <dd>{regroupement.libelle}</dd>
                                        <dt>Nom de l'entité maître</dt>
                                        <dd>{regroupement.entite_maitre}</dd>
                                        <dt>N° SIRET</dt>
                                        <dd>{regroupement.siret}</dd>
                                        <dt>Donneur d'ordre</dt>
                                        <dd>{regroupement.donneur_ordre}</dd>
                                    </dl>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} />
                </div>
                <Modal title="Suppression du regroupement" open={this.state.deleteTarget}>
                    <div className="modal-body">
                        {this.state.deleteTarget && (
                            <p>Etes-vous sûr(e) de vouloir supprimer la fiche regroupement <b>{this.state.deleteTarget.libelle}</b> ?</p>

                        )}
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => this.setState({ deleteTarget: null })} className="btn btn-white">Annuler</button>
                        <button onClick={() => this.deleteRegroupement()} className="btn btn-secondary">Confirmer</button>
                    </div>
                </Modal>
            </DefaultLayout>
        )
    }
}

const CustomToggle = React.forwardRef<HTMLButtonElement, any>(({ children, onClick }: any, ref) => (
    <button
        className="btn-icon btn-icon--white"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

export default withTranslation()(withRouter(ListRegroupement));