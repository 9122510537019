import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { AuditFields } from "../../../components/AuditFields";
import BreadCrumb from "../../../components/BreadCrumb";
import CustomComponent from "../../../components/CustomComponent";
import { FormInput, FormInputSelect, FormRadioBoolean } from '../../../components/FormComponents';
import { DefaultLayout } from "../../../components/Layouts";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import Panel from "../../../components/Panel";
import { SortbyColumnButton } from "../../../components/TableComponents";
import { TOAST_TYPE, hasPermission, sendToast, sortByField } from "../../../helpers/helpers";
import AddMembreModal from "./_addMembreModal";
import { DeleteModal } from "./_deleteModal";

class CreateRegroupement extends CustomComponent {
    private _isMounted = false;
    private _regroupementId = this.props.regroupementId
    private _isDetailsPage = !!this.props.isDetailsPage
    private _isDeletePage = !!this.props.isDeletePage
    private _isUpdatePage = !!this.props.regroupementId
    private validator

    private defaultFilters = {
        orderBy: '',
        ordering: '',
        nom_usuel: '',
        siret: '',
        classe_entite: '',
    }

    constructor(props: any) {
        super(props);
        this.state = {
            regroupement: {
                libelle: '',
                lib_court: '',
                ent_id_donneur_ordre: '',
                ent_id_maitre: '',
                fl_actif: '1',
                membres: []
            },
            entites: [],
            filteredMembres: [],
            filters: this.defaultFilters,
            options: {
                ent_id_donneur_ordre: [],
                ent_id_maitre: [],
            },
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            regroupementName: '',
            isSubmitted: false,
            addMembreModalOpen: false,
            isDeleteModalOpen: false,
            deletingEntity: null,
            deleteTarget: null,
            isLoading: false,
        }
        this.validator = new SimpleReactValidator({ locale: 'fr', autoForceUpdate: this });
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | ' + (this._isUpdatePage ? "Modification d'une fiche regroupement" : "Création d'une fiche regroupement");
        this.getAllData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getAllData() {

        if (this._regroupementId) {
            await this.getRegroupement();
        } else {
            await this.getEntiteOptions();
        }
        await this.getEntites()
    }

    async getEntites() {
        const params = new URLSearchParams()
        params.append('skip_relations', 'true');

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key]
            if (val !== '') params.append(key, val)
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key]
            if (val !== '') params.append(key, val)
        })


        let entites = [];
        const data = await this.request(`/ent_entite?${params.toString()}`, 'GET');
        if (data && data.length > 0) {
            entites = data;
        }

        this._isMounted && this.setState({entites})
    }

    async getRegroupement() {
        this.setState({isLoading: true});
        const data = await this.request(`/ent_regroupement/${this._regroupementId}`, 'GET');

        if (data) {
            const membres = data.membres.map((membre: any) => ({
                id: membre.id,
                nom_usuel: membre.nom_usuel,
                classe_entite: membre.cla_id.lib_classe,
                siret: membre.siret
            })) || []

            this._isMounted && this.setState((prev: any) => ({
                regroupementName: data.libelle,
                regroupement: {
                    ...prev.regroupement,
                    libelle: data.libelle || '',
                    lib_court: data.lib_court || '',
                    ent_id_donneur_ordre: data.ent_id_donneur_ordre || '',
                    ent_id_maitre: data.ent_id_maitre || '',
                    fl_actif: data.fl_actif ? '1' : '0',
                    membres,
                },
            }), this.updatePaginationAndFilters)
        }

        if ((data.ent_id_maitre === data.ent_id_donneur_ordre) || !data.ent_id_donneur_ordre) {
            await this.getEntiteOptions(null, data.ent_id_maitre);
        } else {
            await this.getEntiteOptions(null, data.ent_id_maitre, 'ent_id_maitre');
            await this.getEntiteOptions(null, data.ent_id_donneur_ordre, 'ent_id_donneur_ordre');
        }

        this.getAuditFields();
        this.setState({isLoading: false});
    }

    async getAuditFields () {
        const auditFields =  await this.request(`/ent_regroupement/auditFields/${this.props.regroupementId}`, 'GET')
        this._isMounted && this.setState({
            auditFields
        })
    }

    async getEntiteOptions(e: any | null = null, id: number | null = null, option_name: string | null = null) {
        let URL: string = '/ent_entite/autocomplete'

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        } else if (id) {
            URL += `?id=${id}`
        }

        const data = await this.request(URL, 'GET')
        if (data && this._isMounted) {
            const options = data.map((option: any) => ({ label: option.label, value: option.id }))

            if (option_name) {
                this.setState((prev: any) => ({
                    options: {
                        ...prev.options,
                        [option_name]: options
                    }
                }), () => console.log(this.state))
            } else {
                this.setState((prev: any) => ({
                    options: {
                        ...prev.options,
                        ent_id_maitre: options,
                        ent_id_donneur_ordre: options
                    }
                }))
            }
        }
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== this.state.filters.orderBy || this.state.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), this.updatePaginationAndFilters)
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: value - 1
            }
        }), () => this.updatePaginationAndFilters(false))
    }

    updatePaginationAndFilters = (resetPagination = true, membres = this.state.regroupement.membres) => {
        const {orderBy, ordering, ...filters} = this.state.filters
        const { limit, page } = this.state.paginate

        let filteredMembres = membres.map((per: any) => ({ ...per, showChildren: false }))
        Object.keys(filters).forEach(key => {
            filteredMembres = filteredMembres.filter((per: any) => filters[key] ? per[key] && per[key].toLowerCase().includes(filters[key].toLowerCase()) : true)
        })

        if (orderBy && ordering) {
            filteredMembres = sortByField(filteredMembres, orderBy, ordering)
        }

        this.setState((prev: any) => ({
            filteredMembres: filteredMembres.slice(page * limit, page * limit + limit),
            paginate: {
                ...prev.paginate,
                pageTotal: Math.ceil(filteredMembres.length / limit),
                page: resetPagination ? 0 : page
            }
        }))
    }

    afterSubmission = async (e: any) => {
        e.preventDefault()
        const { t } = this.props;

        this._isMounted && this.setState({ isLoading: true, isSubmitted: true });

        if (this.validator.allValid()) {
            await this.createOrUpdateRegroupement()
        } else {
            this.validator.showMessages()
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }

        this._isMounted && this.setState({ isLoading: false });
    }

    deleteRegroupement = async () => {
        const data = await this.request(`/ent_regroupement/${this._regroupementId}`, 'DELETE')

        if (data && data.statusCode === 200) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            this.props.history.push('/annuaire/regroupement')
        }
    }

    async createOrUpdateRegroupement() {
        const URL = this._regroupementId ? `/ent_regroupement/${this._regroupementId}` : `/ent_regroupement`
        const METHOD = this._regroupementId ? 'PATCH' : 'POST'

        const { regroupement } = this.state;

        regroupement.membres = regroupement.membres.map((membre: any) => membre.id);

        const BODY = {
            ...regroupement,
            ent_id_donneur_ordre: !!regroupement.ent_id_donneur_ordre ? +regroupement.ent_id_donneur_ordre : null,
            ent_id_maitre: !!regroupement.ent_id_maitre ? +regroupement.ent_id_maitre : null,
            fl_actif: regroupement.fl_actif === '1'
        }

        const data = await this.request(URL, METHOD, BODY)

        if (data && data.statusCode === 201) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.props.history.push(`/annuaire/regroupement/${data.id}`);
        }
    }

    removeEntite() {
        const membres = this.state.regroupement.membres.filter((membre: any) => membre.id !== this.state.deletingEntity.id);
        this.setState((prevState: any) => ({ regroupement: { ...prevState.regroupement, membres: membres }, isDeleteModalOpen: false, deletingEntity: null }), () => this.updatePaginationAndFilters(true, membres));
    }

    renderButtons = () => {
        if (this._isDetailsPage) return <>
            <Link to={`/annuaire/regroupement`} className="btn m-r-5">Annuler</Link>
            {hasPermission('DRT_ANNU_REG_GERER') && <button className='btn btn-danger m-r-5' type='button' onClick={() => this.setState({deleteTarget: ({...this.state.regroupement, id: this._regroupementId})})}>Supprimer</button>}
            {hasPermission('DRT_ANNU_REG_GERER') && <Link to={`/annuaire/regroupement/${this._regroupementId}/modifier`} className="btn btn-dark">Modifier</Link>}
        </>

        if (this._isUpdatePage) return <>
            <Link to={`/annuaire/regroupement/${this._regroupementId}`} className="btn m-r-5">Annuler</Link>
            {hasPermission('DRT_ANNU_REG_GERER') && <button className='btn btn-danger m-r-5' type='button' onClick={() => this.setState({deleteTarget: ({...this.state.regroupement, id: this._regroupementId})})}>Supprimer</button>}
            {hasPermission('DRT_ANNU_REG_GERER') && <button type="submit" className="btn btn-secondary">Enregistrer</button>}
        </>

        return <>
            <Link to={`/annuaire/regroupement`} className="btn m-r-5">Annuler</Link>
            {hasPermission('DRT_ANNU_REG_GERER') &&<button type="submit" className='btn btn-secondary'>Enregistrer</button>}
        </>
    }

    render() {
        return <>
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Annuaire', path: '/annuaire/personne' }, { name: this._isUpdatePage ? this.state.regroupementName : 'Création d’une fiche personne', path: '' }]} />
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="d-flex justify-content-between flex-wrap align-items-center">
                            <h1 className="main-title">{this._isUpdatePage ? this.state.regroupementName : 'Création d’une fiche regroupement'}</h1>
                            <div className="m-b-30">
                                {this.renderButtons()}
                            </div>
                        </div>
                        <Panel>
                            <div className="large-gutters">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                id="libelle"
                                                name="libelle"
                                                label="Libellé"
                                                type="text"
                                                value={this.state.regroupement.libelle}
                                                handle={(e: any) => this.handleInputChange(e, null, 'regroupement')}
                                                maxLength={50}
                                                simpleValidator={this.validator}
                                                isSubmitted={this.state.isSubmitted}
                                                required={true}
                                                disabled={this._isDetailsPage} />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                id="lib_court"
                                                name="lib_court"
                                                label="Libellé court"
                                                type="text"
                                                value={this.state.regroupement.lib_court}
                                                handle={(e: any) => this.handleInputChange(e, null, 'regroupement')}
                                                maxLength={20}
                                                simpleValidator={this.validator}
                                                isSubmitted={this.state.isSubmitted}
                                                required={true}
                                                disabled={this._isDetailsPage} />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputSelect
                                                id="ent_id_donneur_ordre"
                                                name="ent_id_donneur_ordre"
                                                value={this.state.regroupement.ent_id_donneur_ordre}
                                                label="Donneur d'ordre"
                                                options={this.state.options.ent_id_donneur_ordre}
                                                simpleValidator={this.validator}
                                                isSubmitted={this.state.isSubmitted}
                                                isClearable
                                                handleInput={(e: any) => this.getEntiteOptions(e, null, 'ent_id_donneur_ordre')}
                                                handle={(e: any) => this.handleInputChange(e, null, 'regroupement')}
                                                required={false}
                                                disabled={this._isDetailsPage} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputSelect
                                                id="ent_id_maitre"
                                                name="ent_id_maitre"
                                                value={this.state.regroupement.ent_id_maitre}
                                                label="Entité maître"
                                                options={this.state.options.ent_id_maitre}
                                                simpleValidator={this.validator}
                                                isSubmitted={this.state.isSubmitted}
                                                isClearable
                                                handleInput={(e: any) => this.getEntiteOptions(e, null, 'ent_id_maitre')}
                                                handle={(e: any) => this.handleInputChange(e, null, 'regroupement')}
                                                required={true}
                                                disabled={this._isDetailsPage} />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormRadioBoolean
                                                id="fl_actif"
                                                name="fl_actif"
                                                value={this.state.regroupement.fl_actif}
                                                label='Regroupement actif'
                                                handle={(e: any) => this.handleInputChange(e, null, 'regroupement')}
                                                disabled={this._isDetailsPage} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </form>
                    {/* Ajouter une entité */}
                    {!this._isDetailsPage && hasPermission('DRT_ANNU_REG_GERER') && (
                        <AddMembreModal
                            ent_id_maitre={this.state.regroupement.ent_id_maitre}
                            membres={this.state.regroupement.membres}
                            handle={(e: any) => this.setState((prevState: any) => ({
                                regroupement: {
                                    ...prevState.regroupement,
                                    membres: prevState.regroupement.membres.concat(e)
                                }
                            }),this.updatePaginationAndFilters)} />
                    )}

                    {this.state.regroupement.membres.length > 0 && (
                        <div className="m-t-30">
                            <div className="table-responsive desktop-only">
                                <table className="table table-custom">
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex">
                                                    <span className="sr-only">Nom usuel</span>
                                                    <input
                                                        value={this.state.filters.nom_usuel}
                                                        onChange={(e: any) => this.handleInputChange(e, null, 'filters', this.updatePaginationAndFilters)}
                                                        name="nom_usuel"
                                                        type="text"
                                                        className="table-input"
                                                        placeholder="Nom usuel" />
                                                    <SortbyColumnButton active={this.state.filters.orderBy === 'nom_usuel'} direction={this.state.filters.ordering} onClick={() => this.handleSort('nom_usuel')} />
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex">
                                                    <span className="sr-only">N° SIRET</span>
                                                    <input
                                                        value={this.state.filters.siret}
                                                        onChange={(e: any) => this.handleInputChange(e, null, 'filters', this.updatePaginationAndFilters)}
                                                        name="siret"
                                                        type="text"
                                                        className="table-input"
                                                        placeholder="N° SIRET" />
                                                    <SortbyColumnButton active={this.state.filters.orderBy === 'siret'} direction={this.state.filters.ordering} onClick={() => this.handleSort('siret')} />
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex">
                                                    <span className="sr-only">Classe de l'entité</span>
                                                    <input
                                                        value={this.state.filters.classe_entite}
                                                        onChange={(e: any) => this.handleInputChange(e, null, 'filters', this.updatePaginationAndFilters)}
                                                        name="classe_entite"
                                                        type="text"
                                                        className="table-input"
                                                        placeholder="Classe de l'entité" />
                                                    <SortbyColumnButton active={this.state.filters.orderBy === 'classe_entite'} direction={this.state.filters.ordering} onClick={() => this.handleSort('classe_entite')} />
                                                </div>
                                            </th>
                                            <th className="table-actions">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.filteredMembres.map((membre: any) => (
                                            <tr key={membre.id}>
                                                <td>{membre.nom_usuel}</td>
                                                <td>{membre.siret}</td>
                                                <td>{membre.classe_entite}</td>
                                                <td className="table-actions">
                                                    <div className="d-flex">
                                                        {hasPermission('DRT_ANNU_ENT_CONSULTER') && <Link className='action-btn' to={`/annuaire/entite/${membre.id}`}><i className="icon-eye"></i></Link>}
                                                        {!this._isDetailsPage && hasPermission('DRT_ANNU_ENT_GERER') && <button className='action-btn' onClick={() => this.setState({ isDeleteModalOpen: true, deletingEntity: membre })}><i className="icon-trash"></i></button>}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} />

                        </div>
                    )}
                </div>
                <Modal
                    open={this.state.isDeleteModalOpen}
                    >
                    <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                        Etes-vous sûr(e) de vouloir retirer l’entité <b>{this.state.deletingEntity?.siret} - {this.state.deletingEntity?.nom_usuel}</b> du regroupement ?
                    </div>
                    <button onClick={() => this.setState({ isDeleteModalOpen: false, deletingEntity: null })} className="btn btn-white">Annuler</button>
                    <button onClick={() => this.removeEntite()} className="btn btn-secondary">Confirmer</button>
                </Modal>
                <DeleteModal
                    regroupement={this.state.deleteTarget}
                    onClose={() => this.setState({deleteTarget: null})}
                    onDelete={() => this.props.history.push('/annuaire/regroupement')}/>
                {this.state.auditFields && (<AuditFields audit={this.state.auditFields} />)}
            </DefaultLayout>
        </>
    }
}

export default withTranslation()(withRouter(CreateRegroupement));