export enum DETAILS_SUIVI_PUSH_DETAIL {
    EC = 'EC',
    GENERE = 'GENERE',
    WAIT = 'WAIT',
    REQUEST = 'REQUEST',
    DELIVERED = 'DELIVERED',
    OPENED = 'OPENED',
    CLICKED = 'CLICKED',
    KO = 'KO',
    KO_TRANSMISSION = 'KO_TRANSMISSION',
    ERR_MSG = 'ERR_MSG',
    UNSUBSCRIBED = 'UNSUBSCRIBED',
    UNKNOWN = 'UNKNOWN',
    SOFT_BOUNCE = 'SOFT_BOUNCE',
    HARD_BOUNCE = 'HARD_BOUNCE',
    INVALID_EMAIL = 'INVALID_EMAIL',
    DEFERRED = 'DEFERRED',
    COMPLAINT = 'COMPLAINT',
    BLOCKED = 'BLOCKED',
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_SENT = 'NOT_SENT',
}

export const DETAILS_SUIVI_PUSH_DETAIL_LABEL: { [key: string]: string } = {
    [DETAILS_SUIVI_PUSH_DETAIL.EC]: `En cours de génération`,
    [DETAILS_SUIVI_PUSH_DETAIL.GENERE]: `Fichier généré à envoyer`,
    [DETAILS_SUIVI_PUSH_DETAIL.WAIT]: `Planifié par l'opérateur`,
    [DETAILS_SUIVI_PUSH_DETAIL.IN_PROGRESS]: `Attente retour opérateur`,
    [DETAILS_SUIVI_PUSH_DETAIL.REQUEST]: `Envoyé`,
    [DETAILS_SUIVI_PUSH_DETAIL.DELIVERED]: `Reçu`,
    [DETAILS_SUIVI_PUSH_DETAIL.OPENED]: `Ouvert`,
    [DETAILS_SUIVI_PUSH_DETAIL.CLICKED]: `Lien cliqué`,
    [DETAILS_SUIVI_PUSH_DETAIL.KO]: `Erreur génération`,
    [DETAILS_SUIVI_PUSH_DETAIL.KO_TRANSMISSION]: `Erreur transmission opérateur`,
    [DETAILS_SUIVI_PUSH_DETAIL.ERR_MSG]: `Erreur inattendue`,
    [DETAILS_SUIVI_PUSH_DETAIL.UNSUBSCRIBED]: `Utilisateur désinscrit`,
    [DETAILS_SUIVI_PUSH_DETAIL.UNKNOWN]: `Inconnu`,
    [DETAILS_SUIVI_PUSH_DETAIL.SOFT_BOUNCE]: `Refus temporaire`,
    [DETAILS_SUIVI_PUSH_DETAIL.HARD_BOUNCE]: `Erreur définitive`,
    [DETAILS_SUIVI_PUSH_DETAIL.INVALID_EMAIL]: `Adresse erronée`,
    [DETAILS_SUIVI_PUSH_DETAIL.DEFERRED]: `Erreur temporaire`,
    [DETAILS_SUIVI_PUSH_DETAIL.COMPLAINT]: `Détecté SPAM`,
    [DETAILS_SUIVI_PUSH_DETAIL.BLOCKED]: `Bloqué`,
    [DETAILS_SUIVI_PUSH_DETAIL.ERROR]: `Erreur technique`,
    [DETAILS_SUIVI_PUSH_DETAIL.NOT_SENT]: `Non Envoyé`,
}

export const DETAILS_SUIVI_PUSH_DETAIL_OPTIONS = Object.entries(DETAILS_SUIVI_PUSH_DETAIL_LABEL).map(([value, label]) => ({
    value, label
}))