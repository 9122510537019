export enum STATUTS_FLUX_RES {
    OK = 'OK',
    AL = 'AL',
    ER = 'ER',
    EC = 'EC',
    KO = 'KO',
    ATTENTE = 'ATTENTE',
    CRASH = 'CRASH',
    PRET = 'PRET',
    RESOLU = 'RESOLU',
}

export const STATUTS_FLUX_RES_LABEL: {[key: string]: string} = {
    [STATUTS_FLUX_RES.OK]: 'OK',
    [STATUTS_FLUX_RES.AL]: 'Alerte',
    [STATUTS_FLUX_RES.ER]: 'Erreur',
    [STATUTS_FLUX_RES.EC]: 'En cours',
    [STATUTS_FLUX_RES.KO]: 'KO',
    [STATUTS_FLUX_RES.ATTENTE]: 'Attente',
    [STATUTS_FLUX_RES.CRASH]: 'Crash',
    [STATUTS_FLUX_RES.PRET]: 'Prêt',
    [STATUTS_FLUX_RES.RESOLU]: 'Résolu',
}

export const STATUTS_FLUX_RES_OPTIONS = Object.entries(STATUTS_FLUX_RES_LABEL).map(([value, label]) => ({
    value, label
}))