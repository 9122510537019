import { default as jwt_decode } from 'jwt-decode';
import { useEffect, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { TOAST_TYPE, sendToast } from '../helpers/helpers';

export const LoginChecker = () => {
    const loggedOut = useRef<boolean>(false);
    const checkTimeout = useRef<any>(null);
    const isChecking = useRef<boolean>(false);
    const logoutChannel = new BroadcastChannel('logout');
    const history = useHistory();

    logoutChannel.onmessage = (event: MessageEvent<any>) => {
        if (loggedOut.current) return;
        loggedOut.current = true;

        if (event?.data?.type === 'connection_expired') {
            sendToast("Vous êtes déconnecté.", TOAST_TYPE.ERROR);
        }

        localStorage.clear();
        history.push('/login');
    };

    useEffect(() => {
        const checkRefreshTokenValidity = () => {
            if (isChecking.current) return;
            isChecking.current = true;

            const refresh_token = localStorage.getItem('refresh_token');
            if (!refresh_token) return;

            const {exp}: any = jwt_decode(refresh_token);
            const timestamp_now = Math.round(Date.now() / 1000);

            if (timestamp_now > exp) {
                logoutChannel.postMessage({type: 'connection_expired'});
            } else {
                const timeout = (exp - timestamp_now) * 1000;
                checkTimeout.current = setTimeout(checkRefreshTokenValidity, timeout);
            }

            isChecking.current = false;
        }

        checkRefreshTokenValidity();
        return () => clearTimeout(checkTimeout.current);
    });

    return null;
}
