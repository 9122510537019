import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import Modal from "../../../components/Modal";
import TABLE from "../../../components/Table";
import { TOAST_TYPE, sendToast } from "../../../helpers/helpers";


class ManageGroupesFavorisModal extends CustomComponent {
    private _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {
            modal_open: false,
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    deleteGroupeFavoris = async (gfa_id: number) => {
        const data = await this.request(`/per_groupe_favori/${gfa_id}`, 'DELETE');
        if (data?.statusCode === 200 && this._isMounted) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.props.callback(gfa_id);
        }
    }

    render() {
        return <>
            <button
                type="button"
                className="m-b-15 additional-action"
                onClick={() => this.setState({ modal_open: true })}
            >
                Gérer mes groupes de favoris
            </button>

            <Modal open={this.state.modal_open} size="lg" customLayout>
                <div className="modal-header m-b-40 justify-content-between align-items-center">
                    <h4 className="modal-title m-0">
                        Gérer mes groupes de favoris
                    </h4>
                    <button
                        type="button"
                        className="btn btn-white"
                        onClick={() => this.setState({ modal_open: false })}
                    >
                        Annuler
                    </button>
                </div>
                <div className="modal-body">
                    <TABLE variant="blue">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH label="Nom du groupe de favoris" />
                                <TABLE.TH label="Nombre de membres" />
                                <TABLE.TH actions label="Action" />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.props.groupes_favoris?.map((gfa: any) => (
                                <TABLE.TR key={gfa.id}>
                                    <TABLE.TD>{gfa.nom}</TABLE.TD>
                                    <TABLE.TD>{gfa.nombre_membres}</TABLE.TD>
                                    <TABLE.TD actions>
                                        <button
                                            type="button"
                                            className="action-btn m-auto"
                                            onClick={() => this.deleteGroupeFavoris(gfa.id)}
                                        >
                                            <i className="icon-trash" />
                                        </button>
                                    </TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>
                </div>
            </Modal>
        </>
    }
}

export default withTranslation()(withRouter(ManageGroupesFavorisModal));