import { NavLink } from "react-router-dom"
import { hasPermission } from '../../../helpers/helpers';

const AnnuaireTabs = () => {
    return (
        <ul className="nav nav-tabs">
            {hasPermission(['DRT_ANNU_PERSONNE']) && (
                <li className="nav-item"><NavLink to={'/annuaire/entite'} className='nav-link'><i className="icon-city"></i>Entités</NavLink></li>
            )}
            {hasPermission(['DRT_ANNU_ENTITE']) && (
                <li className="nav-item"><NavLink to={'/annuaire/personne'} className='nav-link'><i className="icon-person"></i>Personnes</NavLink></li>
            )}
            {hasPermission(['DRT_ANNU_GROUPE']) && (
                <li className="nav-item"><NavLink to={'/annuaire/groupe'} className='nav-link'><i className="icon-group"></i>Groupes</NavLink></li>
            )}
            {hasPermission(['DRT_ANNU_REGROUPEMENT']) && (
                <li className="nav-item"><NavLink to={'/annuaire/regroupement'} className='nav-link'><i className="icon-workspace"></i>Regroupements</NavLink></li>
            )}
        </ul>
    )
}

export {AnnuaireTabs}