import Create from './create'
import Update from './update'
import List from './list'
import Delete from './delete'
import Details from './details'

const Regroupement = {
    Create,
    Update,
    List,
    Delete,
    Details
}

export default Regroupement