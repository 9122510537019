import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import { SortbyColumnButton } from "../../../components/TableComponents";
import { DebounceQueue } from "../../../helpers/debouncer";


class AddMembreModal extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: '',
        ordering: '',
        nom_usuel: '',
        siret: '',
        ville: ''
    }

    constructor(props: any) {
        super(props)
        this.state = {
            entites: [],
            filters: this.defaultFilters,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            openModal: false,
            checked: [],
            selectedEntites: [],
        }
    }

    componentDidMount () {
        this._isMounted = true
    }

    componentWillUnmount () {
        this._isMounted = false
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== this.state.filters.orderBy || this.state.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getEntites, [], true))
    }

    openModal = () => {
        this.setState({
            openModal: true,
            filters: this.defaultFilters,
            selectedEntites: []
        }, this.getEntites)
    }

    getEntites = async (triggeredByPagination: boolean = false) => {
        const params = new URLSearchParams()

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key]
            if (val !== '') params.append(key, val)
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key]
            if (val !== '') params.append(key, val)
        })

        const exclude = this.props.membres.map((m:any) => m.id)
        if (this.props.ent_id_maitre) exclude.push(this.props.ent_id_maitre)

        params.append('exclude', exclude)

        if (!triggeredByPagination) params.set('page', '0')

        let data = await this.request(`/ent_entite?${params.toString()}`, 'GET');
        const references = await this.request(`/ent_entite/references`, 'GET');

        data.entites?.map((e : any) => e.classe_entite = references.ref_classe_entites?.find((ref : any) => ref.id === e.cla_id)?.lib_classe)
        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                entites: data.entites,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }))
        }

    }

    addEntite = () => {
        this.props.handle && this.props.handle(this.state.selectedEntites);
        this.setState({
            checked: [],
            openModal: false
        })
    }

    handleCheck = (event: any) => {
        const {checked, value} = event.target
        const checkedEntites = checked
        ? [...this.state.checked, +value]
        : this.state.checked.filter((n: any) => n !== +value)

        this.setState((prev: any) => ({
            checked: checkedEntites,
            selectedEntites: checked
                ? [...prev.selectedEntites, ...prev.entites.filter((entite: any) => entite.id === +value)]
                : prev.selectedEntites.filter((entite: any) => checkedEntites.includes(entite.id))
        }))
    }

    handleCheckAll = (e: any) => {
        const {checked} = e.target
        const checkedEntites = checked
            ? [...this.state.checked, ...this.state.entites.map((entite: any) => entite.id)]
            : [...this.state.checked.filter((n: any) => !this.state.entites.map((entite: any) => entite.id).includes(n) )]

        this.setState((prev: any) => ({
            checked: checkedEntites,
            selectedEntites: checked
                ? [...prev.selectedEntites, ...prev.entites.filter((entite: any) => checkedEntites.includes(entite.id))]
                : prev.selectedEntites.filter((entite: any) => checkedEntites.includes(entite.id))
        }))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getEntites, [true], true))
    }

    isAllChecked = () => {
        const visibleEntiteIDs = this.state.entites.map((e: any) => e.id)
        const checkedIDs = this.state.checked
        return visibleEntiteIDs.filter((e: any) => checkedIDs.includes(e)).length === visibleEntiteIDs.length
    }

    render () {
        return (<>
            <button
                onClick={this.openModal}
                type="button"
                className="info-card info-card--button m-t-30">
                    Ajouter une ou plusieurs entités
                <i className="icon-add-circle"></i>
            </button>
            <Modal open={this.state.openModal} size="lg">
                <div className="modal-header d-flex justify-content-between align-items-center flex-wrap m-b-15">
                    <h4 className="modal-title m-b-15">Ajouter une ou plusieurs entité(s)</h4>
                    <div className="ml-auto m-b-15">
                        <button type="button" onClick={() => this.setState({openModal: false, checked: []})} className="btn btn-white">Annuler</button>
                        <button type="button" className="btn btn-secondary" disabled={this.state.checked.length <= 0} onClick={this.addEntite}>Ajouter</button>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="table-responsive">
                        <table className="table table-custom table-blue">
                            <thead>
                                <tr>
                                    <th className="table-check">
                                        <input type="checkbox" className="checkbox" checked={this.isAllChecked()} onChange={this.handleCheckAll} />
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Nom usuel</span>
                                            <input
                                                value={this.state.filters.nom_usuel}
                                                name="nom_usuel"
                                                type="text"
                                                className="table-input"
                                                placeholder='Nom usuel'
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}/>
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'nom_usuel'} direction={this.state.filters.ordering} onClick={() => this.handleSort('nom_usuel')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">N° SIRET</span>
                                            <input
                                                value={this.state.filters.siret}
                                                name="siret"
                                                type="text"
                                                className="table-input"
                                                placeholder='N° SIRET'
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}/>
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'siret'} direction={this.state.filters.ordering} onClick={() => this.handleSort('siret')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Ville</span>
                                            <input
                                                value={this.state.siret}
                                                name="ville"
                                                type="text"
                                                className="table-input"
                                                placeholder='Ville'
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))}/>
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'ville'} direction={this.state.filters.ordering} onClick={() => this.handleSort('ville')} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.entites.map((entite: any) => (
                                    <tr key={entite.id}>
                                        <td className="table-check">
                                            <input type="checkbox" className="checkbox" checked={this.state.checked.includes(entite.id)} value={entite.id} id={`entite${entite.id}}`} onChange={this.handleCheck} />
                                        </td>
                                        <td>{entite.nom_usuel}</td>
                                        <td>{entite.siret}</td>
                                        <td>{entite.ville}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} secondary/>
                </div>
            </Modal>
        </>)
    }
}

export default withTranslation()(withRouter(AddMembreModal));