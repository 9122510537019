import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomComponent from '../../components/CustomComponent';
import { HabilitationLayout } from '../../components/Layouts';
import { getHabilitations } from '../../helpers/helpers';
import { UPDATE_SESSION } from '../../redux/types';


class Habilitations extends CustomComponent {
    habilitations = getHabilitations() || [];

    componentDidMount() {
        document.title = 'Infolabo';
        this.checkIsConnectedPWA();
    }

    onHabilitationCHange = async (id: any) => {
        await this.select_habilitation(id)
        this.props.dispatch({type: UPDATE_SESSION})
    }

    render() {

        return (
            <HabilitationLayout>
                <main>
                    <div className="container">
                        <h1 className="main-title text-center">Sélectionnez une de vos entités</h1>
                        <div className="row">
                            {this.habilitations.map((habilitation: any) => (
                                <div key={habilitation.id} className="col-md-4 m-b-25">
                                    <div className="card entity-card">
                                        <h4 className="entity-card__title">{habilitation.ent_id.nom_usuel}</h4>
                                        <ul className="entity-card__info">
                                            <li>{habilitation.ent_id.code_pays}{habilitation.ent_id.siret}</li>
                                            <li>{habilitation.ent_id.raison_soc}</li>
                                            {habilitation.ent_id.types_entite.map((type_entite: any) => (
                                                <li key={type_entite.typ_id.id}>{type_entite.typ_id.lib_type}</li>
                                            ))}
                                        </ul>
                                        <span className="btn btn-primary entity-card__btn">Accéder</span>
                                        <button onClick={() => this.onHabilitationCHange(habilitation.id)} className="link-wrapper">Accéder</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </main>
            </HabilitationLayout>
        )
    }
}

export default withRouter(connect()(Habilitations))