import { Fragment } from "react"
import { Accordion } from "react-bootstrap"
import classNames from 'classnames';

const AccordionTree = ({data, codes_pays, onDelete, activeKey="0", root = true, parentItem = null}: any) => {
    return (
        <div className={root ? 'overflow-auto d-flex' : ''}>
            <Accordion bsPrefix="accordion-tree" defaultActiveKey={activeKey}>
                {data.map((item: any) => (<Fragment key={item.id}>
                    <AccordionTreeItem item={item} parentItem={parentItem} codes_pays={codes_pays} eventKey={activeKey} onDelete={onDelete} root={root}/>
                </Fragment>))}
            </Accordion>
        </div>
    )
}

const AccordionTreeItem = ({item, parentItem = null, codes_pays, onDelete, eventKey}: any) => {
    item.siret = `${codes_pays.find((code:any) => code.value === item.ent_pay_id).label}${item.siren}${item.nic}`

    const hasChildren = item.children && item.children.length > 0
    const hasParent = !!parentItem
    const isParent = item?.hierarchy === 'PARENT'
    const isCurrent = item?.hierarchy === 'CURRENT'
    const isChild = item?.hierarchy === 'CHILD'
    const canBeDeleted = (item.isCurrent && hasChildren) || (isCurrent && hasParent)

    return (
        <Accordion.Item eventKey={eventKey} bsPrefix="accordion-item">
            <div className={classNames({
                'accordion-header': true,
                'has-children': !!hasChildren,
                'is-current': isCurrent,
                'is-parent': isParent,
                'is-child': isChild
            })}>
                <Accordion.Button as='div'>
                    <div className="d-flex w-100 align-items-center">
                        <div className="accordion-title">
                            <strong>{item.nom_usuel}</strong>
                            <span>{item.siret}</span>
                        </div>
                        {canBeDeleted && onDelete && (
                            <button
                                type="button"
                                onClick={(e) => onDelete(e, {...item, parent: parentItem, children: null})}
                                value={item.id}
                                className="btn-icon accordion-delete"><i className="icon-trash"></i></button>
                            )}
                    </div>
                </Accordion.Button>
            </div>
            {hasChildren && (
                <Accordion.Body bsPrefix="accordion-body">
                    <AccordionTree data={item.children} parentItem={item} codes_pays={codes_pays} activeKey={eventKey} root={false} onDelete={onDelete}/>
                </Accordion.Body>
            )}
        </Accordion.Item>
    )

}

export {AccordionTree}