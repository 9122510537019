import { useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Request } from '../helpers/helpers';
import { FormInput, FormSelect } from './FormComponents';

export interface ICriteres {
    cri_id: string
    quali: boolean
    operator: string
    app_id: string
    value: string
}

interface ThresholdPanelProps extends React.PropsWithChildren {
    criteres: ICriteres[],
    onChange: Function,
    open?: boolean
    togglable?: boolean
    onToggle?: Function
    min?: number | null
    displayLibLong?: boolean
}

export const criteriaFilterIsValid = (targetFilter: ICriteres) => {
    const {cri_id, quali, operator, app_id, value} = targetFilter
    return (!!cri_id && !!operator && ((!!quali && !!app_id) || (!quali && !!value)))
}

export const criteriaFilterIsResetable = (targetFilter: ICriteres) => {
    const {cri_id, operator, app_id, value} = targetFilter
    return (!!cri_id || !!operator || !!app_id || !!value)
}

export const CriteriaPanel = ({ criteres, onChange, children, togglable, onToggle, open, min = 1, displayLibLong }: ThresholdPanelProps) => {
    const defaultFilterValue = { cri_id: '', quali: true, operator: '', app_id: '', value: '' }

    if (min && !criteres.length) {
        for (let i = 0; i < min; i++) {
            criteres.push(defaultFilterValue);
        }
    }

    const [canReset, setCanReset] = useState(false)
    const [filters, setFilters] = useState(criteres)
    const qualiOperators = ['=', '<>']
    const quantiOperators = ['=', '>', '>=', '<', '<=', '<>']

    const [criteriaOptions, setCriteriaOptions] = useState<any[]>([])
    const [appreciationOptions, setAppreciationOptions] = useState<any[]>([])

    useEffect(() => {
        Request('/references/critere_analyse', 'GET').then((data) => data.length > 0 && setCriteriaOptions(data))
        Request('/references/appreciation', 'GET').then((data) => data.length > 0 && setAppreciationOptions(data))
    }, [])

    const addThreshold = () => {
        setFilters([...filters, defaultFilterValue])
    }

    const removeThreshold = (index: number) => {
        setFilters([
            ...filters.slice(0, index),
            ...filters.slice(index + 1)
        ])
    }

    const resetThreshold = () => {
        setFilters(filters.map((filter, index) => {
            if (index === filters.length -1) return defaultFilterValue

            return filter
        }))
    }

    const handleFieldChange = (e: any) => {
        const value = e?.target?.value
        const name = e?.target?.name

        const splitted = name.split('-')
        const index = splitted[1]
        const field = splitted[2]

        const quali = field === 'cri_id'
            ? criteriaOptions.find((o: any) => o.id === +value)?.quali_quanti === 'A'
            : filters[index].quali

        let operator = filters[index].operator;
        if (quali && !qualiOperators.includes(operator)) operator = '';

        const updatedFilters = [...filters]
        updatedFilters[index] = {
            ...filters[index],
            quali,
            operator,
            ...(quali && { value: '' }),
            ...(!quali && { app_id: '' }),
            [field]: value,
        }
        setFilters([...updatedFilters])
    }

    useEffect(() => {
        setFilters(criteres)
    }, [criteres])

    useEffect(() => {
        const lastFilter = filters[filters.length - 1]
        setCanReset(criteriaFilterIsResetable(lastFilter))
        onChange(filters)
    }, [filters, onChange])

    return (<>
        {(togglable && onToggle) && (
            <button className='criteria__toggle' type="button" onClick={() => onToggle(!open)}>{open ? '- Masquer les filtres' : '+ Afficher plus de filtres'}</button>
        )}
        <Collapse in={!togglable || (togglable && open)}>
            <div className="criteria">
                <div className="criteria__title">
                    Requête
                    <i className="icon-info" data-place="right" data-type="dark" data-tip="Les résultats affichés valident l’ensemble des critères"></i>
                </div>
                {children && <div className="criteria__content">{children}</div>}
                {filters.map((filter, index) => (
                    <div className="row" key={index}>
                        <div className="col-lg-10">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <FormSelect label="Critère" name={`criteria-${index}-cri_id`} handle={handleFieldChange} value={filter.cri_id} options={criteriaOptions.map((o: any) => ({ value: o.id, name: displayLibLong ? o.libelle : o.lib_court }))} />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <FormSelect label="Opérateur" name={`criteria-${index}-operator`} handle={handleFieldChange} value={filter.operator} options={filter.quali ? qualiOperators : quantiOperators} />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        {filter.quali ? (
                                            <FormSelect label="Appréciation" name={`criteria-${index}-app_id`} handle={handleFieldChange} value={filter.app_id} options={appreciationOptions.map((o: any) => ({ value: o.id, name: o.lib_court }))} />
                                        ) : (
                                            <FormInput label="Valeur" name={`criteria-${index}-value`} handle={handleFieldChange} value={filter.value} />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="criteria__actions">
                                {index < filters.length - 1 && (
                                    <button type='button' data-tip="Supprimer le critère" className="criteria__remove" onClick={() => removeThreshold(index)}>
                                        <i className="icon-trash"></i>
                                    </button>
                                )}
                                {index === filters.length -1 && canReset && (
                                    <button type='button' data-tip="Supprimer le critère" className="criteria__remove" onClick={() => resetThreshold()}>
                                        <i className="icon-trash"></i>
                                    </button>
                                )}
                                {index === filters.length - 1 && (
                                    <button type='button' data-tip="Ajouter un critère" onClick={addThreshold} className="criteria__add">
                                        <i className="icon-add"></i>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Collapse>
    </>)
}