import jwt_decode from "jwt-decode";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import logo_infolabo from "../../assets/images/logo_infolabo.svg";
import CustomComponent from "../../components/CustomComponent";
import { LoginLayout } from "../../components/Layouts";
import { LoginChecker } from "../../components/LoginChecker";
import { TOAST_TYPE, sendToast } from "../../helpers/helpers";
import { UPDATE_SESSION } from "../../redux/types";

class UpdateCredentials extends CustomComponent {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            token: this.props.match.params.token || '',
            tokenInvalid: false,
        }
    }

    componentDidMount() {
        if (!this.state.token) {
            this.props.history.push('/login');
        }
        this.validateUpdate();
    }

    validateUpdate = async () => {
        const {t} = this.props;

        if (!this.isTokenValid()) {
            sendToast(<p>{t('update_credentials.invalid_token')}</p>, TOAST_TYPE.ERROR);
            this.props.history.push('/login');
            return;
        }

        const BODY: {update_token: string} = {update_token: this.state.token};
        const data = await this.request(`/auth/update_credentials_request/confirm`, 'POST', BODY);
        this.setState({isLoading: false});

        if (data?.statusCode === 200) {
            sendToast(<p>{data.message}</p>, TOAST_TYPE.SUCCESS);

            localStorage.clear();
            const session: any = jwt_decode(data.access_token);
            localStorage.setItem('session', JSON.stringify(session));
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('per_habilitations', JSON.stringify(data.per_habilitations));
            localStorage.setItem('per_personne', JSON.stringify(data.per_personne));

            if (data.per_habilitations?.length > 1) {
                this.props.history.push('/habilitations');
            }
            else if (data.per_habilitations?.length === 1) {
                await this.select_habilitation(data.per_habilitations[0].id);
                this.props.dispatch({type: UPDATE_SESSION})
            }
            else {
                sendToast(<p>{t('login.empty_habilitations')}</p>, TOAST_TYPE.ERROR);
                localStorage.clear();
            }
        } else {
            this.props.history.push('/login');
        }
    }

    isTokenValid = (): boolean => {
        const token: string = this.state.token;
        let decoded_token: any = {};

        try {
            decoded_token = jwt_decode(token);
        } catch (error) {
            return false;
        }

        const timestamp_now: number = Math.round(Date.now() / 1000);

        return (
            'exp' in decoded_token
            && timestamp_now < decoded_token.exp
        );
    }

    render () {
        return <LoginLayout loading={this.state.isLoading}>
            <LoginChecker />
            <main className="page-login__form">
                <h1 className="page-login__title">
                    <span className="sr-only">InfoLabo</span>
                    <img src={logo_infolabo} alt="" />
                </h1>
                <h2 className="page-login__action-title m-b-30">Veuillez patienter</h2>
            </main>
        </LoginLayout>
    }
}

export default withTranslation()(withRouter(UpdateCredentials));