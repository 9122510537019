import saveAs from "file-saver";
import moment from "moment/moment";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { FilterDropdown } from "../../../components/FilterDropdown";
import { FormDatePicker } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import Pagination from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { STATUTS_FLUX_RES_LABEL, STATUTS_FLUX_RES_OPTIONS } from "../../../enum/statuts_flux_res";
import { TYPES_FICHIER_FLUX_RES_LABEL, TYPES_FICHIER_FLUX_RES_OPTIONS } from "../../../enum/types_fichier_flux_res";
import { DebounceQueue } from "../../../helpers/debouncer";
import { TOAST_TYPE, cleanImportResultatFileName, dateFormat, datePickerFormat, sendToast } from "../../../helpers/helpers";
import { exportCSVFlux } from "../../../requests/exportCSVFlux";
import { ImportExportResultatsHeader } from './common/ImportExportResultatsHeader';

class ImportsFluxResultats extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: 'date_prise_en_charge',
        ordering: 'DESC',
        drop_date_range_from: moment().utc().subtract(1, 'month').startOf('day').toDate(),
        drop_date_range_to: moment().utc().startOf('day').toDate(),
        nom_fichier: '',
        type_fichier: '',
        statut: '',
        date_prise_en_charge: '',
        date_debut: '',
        date_fin: '',
        emetteur: '',
    }

    private readonly dateKeys: string[] = [
        'drop_date_range_from',
        'drop_date_range_to',
        'date_prise_en_charge',
        'date_debut',
        'date_fin',
    ];

    private options = {
        type_fichier: TYPES_FICHIER_FLUX_RES_OPTIONS,
        statut: STATUTS_FLUX_RES_OPTIONS,
    }

    constructor(props: any) {
        super(props);
        this.state = {
            filters: this.defaultFilters,
            options: this.options,
            importsSuivisRes: [],
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            isLoading: false
        }
    }

    componentDidMount() {
        document.title = 'Infolabo | Imports et exports résultats';
        this._isMounted = true;

        this.getImportsSuivisRes();
    }

    getImportsSuivisRes = async (triggeredByPagination: boolean = false) => {
        this.setState({isLoading: true});

        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (this.dateKeys.includes(key) && typeof val !== 'string') {
                !!val && params.append(key, val.toISOString());
            }
            else if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/inf-suivi-flux-res?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                importsSuivisRes: data.importsSuivisRes,
                paginate: {...prev.paginate, ...data.paginate},
            }));
        }
    }

    handlePerPageChange = (newLimit: number) => {
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                limit: newLimit,
                page: 0,
            }
        }), this.getImportsSuivisRes);
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getImportsSuivisRes, [], true))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getImportsSuivisRes, [true], true))
    }

    fileDownload = async (id: number, filePath: string|null, fileName: string|null) => {
        if (!filePath || !fileName) {
            sendToast(<p>Impossible de télécharger le fichier</p>, TOAST_TYPE.ERROR);
            return;
        }

        const blob: Blob = await this.request(`/inf-suivi-flux-res/download/${id}`, 'GET', undefined, false, false, true);

        if (!!blob && blob instanceof Blob) {
            saveAs(blob, fileName);
        }
    }

    handleExport = async () => {
        this.setState({isLoading: true});
        const allFilters = {...this.state.paginate, ...this.state.filters};
        await exportCSVFlux('IMPORTS_RESULTATS', allFilters, this.dateKeys);
        this.setState({isLoading: false});
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <ImportExportResultatsHeader
                        paginate={this.state.paginate}
                        onPerPageChange={this.handlePerPageChange}
                        handleRefresh={() => this.queue.push(this.getImportsSuivisRes)}
                    />

                    <FilterDropdown
                        parent={this}
                        filters={this.state.filters}
                        onSearchLoad={(filters: any) => this.setState((prev: any) => ({filters: {...prev.filters, ...filters}}), () => this.queue.push(this.getImportsSuivisRes, [], true))}
                        onFiltersReset={() => this.setState({filters: this.defaultFilters}, () => this.queue.push(this.getImportsSuivisRes, [], true))}
                        onFiltersSearch={() => this.queue.push(this.getImportsSuivisRes, [], true)}
                        onApplyFilters={() => this.queue.push(this.getImportsSuivisRes, [], true)}
                        onExport={{csv: this.handleExport}}
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group-flex">
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="drop_date_range_from"
                                            id="drop_date_range_from"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.drop_date_range_from) || ''}
                                            label="Date de dépôt du"
                                            required
                                        />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="drop_date_range_to"
                                            id="drop_date_range_to"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.drop_date_range_to) || ''}
                                            label="au"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FilterDropdown>

                    <TABLE fixed responsive="accordion">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH fixed={{left: 0, width: 58}}/>
                                <TABLE.TH name="nom_fichier" fixed={{left: 58}}
                                          input={{placeholder: 'Nom du fichier', value: this.state.filters.nom_fichier, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getImportsSuivisRes))}}
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nom_fichier')}}
                                />
                                <TABLE.TH name="type_fichier"
                                          select={{placeholder: 'Type', value: this.state.filters.type_fichier, options: this.state.options.type_fichier, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getImportsSuivisRes))}}
                                />
                                <TABLE.TH name="statut"
                                          select={{placeholder: 'Statut', value: this.state.filters.statut, options: this.state.options.statut, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getImportsSuivisRes))}}
                                />
                                <TABLE.TH name="date_prise_en_charge"
                                          datePicker={{value: this.state.filters.date_prise_en_charge, placeholder: 'Dépôt', onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getImportsSuivisRes))}}
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('date_prise_en_charge')}}
                                />
                                <TABLE.TH name="date_debut"
                                          datePicker={{value: this.state.filters.date_debut, placeholder: 'Début', onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getImportsSuivisRes))}}
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('date_debut')}}
                                />
                                <TABLE.TH name="date_fin"
                                          datePicker={{value: this.state.filters.date_fin, placeholder: 'Fin', onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getImportsSuivisRes))}}
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('date_fin')}}
                                />
                                <TABLE.TH name="nb_lignes" label="Nb lignes"
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_lignes')}}
                                />
                                <TABLE.TH name="nb_erreurs" label="Nb erreurs"
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_erreurs')}}
                                />
                                <TABLE.TH name="nb_alertes" label="Nb alertes"
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_alertes')}}
                                />
                                <TABLE.TH name="emetteur"
                                          input={{placeholder: 'Emetteur', value: this.state.filters.emetteur, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getImportsSuivisRes))}}
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('emetteur')}}
                                />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.importsSuivisRes.map((suivi_res: any) => (
                                <TABLE.TR title={suivi_res.nom_fichier} key={suivi_res.id}>
                                    <TABLE.TD fixed={{left: 0, width: 58}} hiddenMobile>
                                        {(suivi_res.chemin_fichier && suivi_res.fichier_zip) && (
                                            <button title="Télécharger fichier ZIP" type="button" className="action-btn" onClick={() => this.fileDownload(suivi_res.id, suivi_res.chemin_fichier, suivi_res.fichier_zip)}>
                                                <i className="icon-file"></i>
                                                <span className="sr-only">Télécharger fichier ZIP</span>
                                            </button>
                                        )}
                                    </TABLE.TD>
                                    <TABLE.TD fixed={{left: 58}} label="Nom du fichier"><strong>{cleanImportResultatFileName(suivi_res.nom_fichier)}</strong></TABLE.TD>
                                    <TABLE.TD label="Type">{TYPES_FICHIER_FLUX_RES_LABEL[suivi_res.type_fichier]}</TABLE.TD>
                                    <TABLE.TD label="Statut">{STATUTS_FLUX_RES_LABEL[suivi_res.statut]}</TABLE.TD>
                                    <TABLE.TD label="Dépôt">{suivi_res.date_prise_en_charge ? dateFormat(suivi_res.date_prise_en_charge, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Début">{suivi_res.date_debut_traitement ? dateFormat(suivi_res.date_debut_traitement, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Fin">{suivi_res.date_fin_traitement ? dateFormat(suivi_res.date_fin_traitement, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Nb lignes">{suivi_res.nb_lignes_fichier || 0}</TABLE.TD>
                                    <TABLE.TD label="Nb erreurs">{suivi_res.nb_erreurs || 0}</TABLE.TD>
                                    <TABLE.TD label="Nb alertes">{suivi_res.nb_alertes || 0}</TABLE.TD>
                                    <TABLE.TD label="Emetteur">{suivi_res.emetteur?.nom_usuel || ''}</TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>

                    {this.state.importsSuivisRes?.length === 0 && (
                        <TABLE.NO_RESULT/>
                    )}

                    <Pagination
                        handleClick={this.paginationOnClick}
                        pageCurrent={+this.state.paginate.page + 1}
                        pageTotal={this.state.paginate.pageTotal}
                        pageDisplay={3}
                    />
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ImportsFluxResultats));

