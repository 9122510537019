import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import CreateEntite from "./create";

interface RouteParams { id?: string };

class UpdateEntite extends Component<RouteComponentProps<RouteParams>, any> {
    render() {
        const id = this.props.match.params.id;
        return(<CreateEntite entityId={id} />);
    }
}

export default UpdateEntite;