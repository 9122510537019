import Create from './create';
import Update from './update';
import List from './list';
import Details from './details';

const ExportLaiterie = {
    Create,
    Update,
    List,
    Details
}

export default ExportLaiterie