import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import BreadCrumb from "../../../../components/BreadCrumb";
import { FileUploadModal } from "../../../../components/FileUploadModal";
import Modal from '../../../../components/Modal';
import { LimitValuesSelect } from "../../../../components/TableComponents";
import { Request, TOAST_TYPE, hasPermission, sendToast } from "../../../../helpers/helpers";
import { validateFile } from "../../../../helpers/validation";

interface ImportExportAnnuaireHeaderProps {
    paginate: { count: number, limit: number, page: number, pageTotal: number }
    onPerPageChange: Function
    handleRefresh: Function
}

export const ImportExportAnnuaireHeader = ({ paginate, onPerPageChange, handleRefresh }: ImportExportAnnuaireHeaderProps) => {
    const [isImportModalOpen, setImportModalOpen] = useState(false);
    const [isArretUrgenceModalOpen, setArretUrgenceModalOpen] = useState(false);
    const [fileNumber, setFileNumber] = useState(-1);
    const [arretUrgence, setArretUrgence]: any = useState(false);

    const getArretUrgenceStatus = async () => {
        const URL = '/inf_arret_urgence/annuaire';

        const data = await Request(URL, 'GET');

        if (data.statusCode === 200) {
            setArretUrgence(data?.enabled);
        } else {
            setArretUrgence(false);
        }
    }

    const handleArretUrgence = async () => {
        // check import REF annuaire exist with EC status
        if (arretUrgence) {
            const isImportInEcStatus = await Request('/inf-suivi-flux-ref/is_annuaire_in_ec_status', 'GET');
            if (isImportInEcStatus.statusCode === 200 && isImportInEcStatus?.result) {
                sendToast(<p>Un import de référence annuaire est en cours d’exécution, veuillez patienter avant de lever l’arrêt d’urgence annuaire</p>, TOAST_TYPE.ERROR);
                return;
            }
        }
        const URL = '/inf_arret_urgence/annuaire/set/' + (arretUrgence ? 'false' : 'true');

        const data = await Request(URL, 'GET');

        if (data.statusCode === 200) {
            setArretUrgence(data?.enabled);
            if (data?.enabled) {
                sendToast("Activation de l'arrêt d'urgence", TOAST_TYPE.SUCCESS);
                setArretUrgenceModalOpen(false);
            }
            else {
                sendToast("Fin de l'arrêt d'urgence", TOAST_TYPE.SUCCESS);
            }
        }
    }

    const onCloseArretUrgence = () => {
        setArretUrgenceModalOpen(false);
    }

    const importCSV = async (file: File) => {
        // check arret urgence
        await getArretUrgenceStatus();
        if (arretUrgence) {
            sendToast(<p>Veuillez attendre la fin de l'arrêt d'urgence en cours pour pouvoir importer</p>, TOAST_TYPE.ERROR);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const data = await Request(`/csv/import_annuaire_ihm`, 'POST', formData, true);

        if (!!data && data?.statusCode === 201) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
        }

        setImportModalOpen(false);
    }

    useEffect(() => {
        getArretUrgenceStatus();
        if (isImportModalOpen) {
            Request(`/inf-suivi-flux-annu/findMaxImportByUserEntite`, 'GET').then((data: any) => {
                setFileNumber(data.max || 0);
            })
        }
    }, [isImportModalOpen])

    return (<>
        <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }, { name: 'Imports et exports annuaire' }]} />

        <div className="d-flex justify-content-between flex-wrap">
            <h1 className="main-title m-r-50">Imports et exports annuaire</h1>
            <div className='m-b-20 d-flex flex-wrap justify-content-end align-items-start ml-auto'>
                {hasPermission('DRT_FLUX_ANNU_ARRET_URGENCE') && (
                    <button type="button" onClick={() => arretUrgence ? handleArretUrgence() : setArretUrgenceModalOpen(true)} className="btn btn-danger btn-alert m-l-10 m-b-10">
                        <i className="icon-cancel"></i>
                        {arretUrgence ? "Lever l'arrêt d’urgence annuaire" : "Arrêt d'urgence annuaire"}
                    </button>
                )}
                <button type="button" onClick={() => handleRefresh()} className="m-l-10 m-b-10 btn btn-dark">
                    <i className="icon-refresh"></i>
                    Réactualiser
                </button>
                {hasPermission('DRT_ANNU_IMPORTER') && (
                    <button type="button" onClick={() => setImportModalOpen(true)} className="m-l-10 m-b-10 btn btn-dark">
                        <i className="icon-import"></i>
                        Importer
                    </button>
                )}
            </div>
        </div>

        <LimitValuesSelect
            default_value={paginate.limit}
            steps={[10, 20, 50]}
            onChange={(e: any) => onPerPageChange(+e?.target?.value)}
        />

        <ul className="nav nav-tabs">
            <li className="nav-item">
                <NavLink exact to={'/flux/imports_exports_annuaire/imports'} className="nav-link"><i className='icon-import'></i>Imports</NavLink>
            </li>
            <li className="nav-item">
                <NavLink exact to={'/flux/imports_exports_annuaire/exports'} className="nav-link"><i className='icon-export'></i>Exports</NavLink>
            </li>
            <li className="nav-item">
                <NavLink exact to={'/flux/imports_exports_annuaire/api'} className="nav-link"><i className='icon-api'></i>API</NavLink>
            </li>
        </ul>

        <FileUploadModal
            title="Importer un fichier Annuaire ou Groupe"
            info={`Numéro attendu : ${fileNumber + 1}`}
            open={isImportModalOpen}
            handle={importCSV}
            types={['CSV']}
            maxSize={60}
            validation={(e: any) => validateFile(e, ['csv'], 60)}
            onClose={() => setImportModalOpen(false)}
        />

        <Modal open={isArretUrgenceModalOpen}>
            <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                L’activation de l’arrêt d’urgence aura pour effet de mettre en standby les imports annuaire : tout nouveau fichier ANNU/GROU déposé sera refusé par IHM / mis en standby par FTP en attendant la levée de l’arrêt d’urgence.
            </div>
            <button onClick={onCloseArretUrgence} className="btn btn-white">Annuler</button>
            <button onClick={handleArretUrgence} className="btn btn-secondary">Confirmer</button>
        </Modal>
    </>)
}