import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { FormInput, FormInputSelect } from "../../../components/FormComponents";
import Modal from "../../../components/Modal";
import { TOAST_TYPE, sendToast } from "../../../helpers/helpers";
import { initValidator } from "../../../validations/validations";

class GroupeFavorisActions extends CustomComponent {
    private _isMounted = false;
    private readonly validator;

    constructor(props: any) {
        super(props);
        this.state = {
            gfa_nom: '',
            gfa_id: '',
            new_groupe_modal: false,
            update_groupe_modal: false,
            isCreateSubmitted: false,
            isUpdateSubmitted: false,
        }

        this.validator = initValidator();
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    saveGroupeFavoris = async () => {
        this.setState({ isCreateSubmitted: true });
        if (!this.validator.fieldValid('nom du groupe de favoris')) {
            sendToast(<p>Le champ <b>nom du groupe de favoris</b> est requis</p>, TOAST_TYPE.ERROR);
            return;
        }

        const BODY = {
            nom: this.state.gfa_nom,
            membres_entite: this.props.checked,
        }

        const data = await this.request(`/per_groupe_favori`, 'POST', BODY);

        if (data?.statusCode === 201 && this._isMounted) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.props.callback();
        }
    }

    updateGroupeFavoris = async () => {
        this.setState({ isUpdateSubmitted: true });
        if (!this.validator.fieldValid('groupe de favoris')) {
            sendToast(<p>Le champ <b>groupe de favoris</b> est requis</p>, TOAST_TYPE.ERROR);
            return;
        }

        const BODY = {
            membres_entite: this.props.checked,
        }

        const data = await this.request(`/per_groupe_favori/${this.state.gfa_id}`, 'PATCH', BODY);

        if (data?.statusCode === 201 && this._isMounted) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.props.callback();
        }
    }

    openModalCreate = () => {
        if (this.props.gfaOptions.length < 10) {
            this.setState({ new_groupe_modal: true });
        } else {
            sendToast(<p>Nombre maximum de groupe de favoris atteint. Veuillez en supprimer un pour pouvoir en créer un nouveau.</p>, TOAST_TYPE.ERROR);
        }
    }

    openModalAssociate = () => {
        if (this.props.gfaOptions.length > 0) {
            this.setState({ update_groupe_modal: true });
        } else {
            sendToast(<p>Au moins un groupe de favoris doit être créé avant de pouvoir associer des entités à un groupe existant.</p>, TOAST_TYPE.ERROR);
        }
    }

    render() {
        return <>
            <div className="groupe-favoris-actions">
                <div className="groupe-favoris-actions__content">

                    <button
                        type="button"
                        className="groupe-favoris-actions__button icon-people"
                        data-tip="Créer un groupe de favoris"
                        data-type="info"
                        data-place="right"
                        onClick={this.openModalCreate}
                    >
                    </button>
                    <button
                        type="button"
                        className="groupe-favoris-actions__button icon-tree"
                        data-tip="Associer à un groupe de favoris"
                        data-type="info"
                        data-place="right"
                        onClick={this.openModalAssociate}
                    >
                    </button>
                </div>
                {/* Modal create group */}
                <Modal open={this.state.new_groupe_modal}>
                    <div className="modal-header justify-content-start">
                        <h4 className="modal-title m-b-15">
                            Créer un groupe de favoris
                        </h4>
                    </div>
                    <div className="modal-body">
                        <div className="form-group form-group--inline-modal form-group--white">
                            <FormInput
                                light
                                label="Nom du groupe de favoris"
                                name="gfa_nom"
                                id="gfa_nom"
                                type="text"
                                value={this.state.gfa_nom}
                                handle={(e: any) => this.setState({ gfa_nom: e.target.value })}
                                isSubmitted={this.state.isCreateSubmitted}
                                simpleValidator={this.validator}
                                maxLength={80}
                                required
                            />
                        </div>
                    </div>
                    <div className="modal-footer justify-content-end p-t-0">
                        <button
                            type="button"
                            className="btn btn-white"
                            onClick={() => this.setState({ new_groupe_modal: false })}
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => this.saveGroupeFavoris()}
                        >
                            Enregistrer
                        </button>
                    </div>
                </Modal>

                {/* Modal associate to group */}
                <Modal open={this.state.update_groupe_modal}>
                    <div className="modal-header justify-content-start">
                        <h4 className="modal-title m-b-15">
                            Associer à un groupe de favoris
                        </h4>
                    </div>
                    <div className="modal-body">
                        <div className="form-group form-group--inline-modal form-group--white">
                            <FormInputSelect
                                light
                                label="Groupe de favoris"
                                name="gfa_id"
                                id="gfa_id"
                                options={this.props.gfaOptions}
                                maxMenuHeight={140}
                                handle={(e: any) => this.setState({ gfa_id: e.data.value })}
                                isSubmitted={this.state.isUpdateSubmitted}
                                value={this.state.gfa_id}
                                simpleValidator={this.validator}
                                required
                            />
                        </div>
                    </div>
                    <div className="modal-footer justify-content-end p-t-0">
                        <button
                            type="button"
                            className="btn btn-white"
                            onClick={() => this.setState({ update_groupe_modal: false })}
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => this.updateGroupeFavoris()}
                        >
                            Enregistrer
                        </button>
                    </div>
                </Modal>
            </div>
        </>
    }
}

export default withTranslation()(withRouter(GroupeFavorisActions));