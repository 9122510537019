import List from './list';
import Create from './create';
import Update from './update';

const Annonces = {
    List,
    Create,
    Update,
}

export default Annonces;
