import classNames from 'classnames'
import moment from "moment"
import React from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { CriteriaPanel, ICriteres } from "../../../../components/CriteriaPanel"
import CustomComponent from "../../../../components/CustomComponent"
import { FilterDropdown } from "../../../../components/FilterDropdown"
import { FormDatePicker, FormSelect } from "../../../../components/FormComponents"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"
import { SeeMoreButton } from '../../../../components/Pagination'
import TABLE from "../../../../components/Table"
import {
    TOAST_TYPE,
    checkIfCriteresAreFilled, dateFormat, datePickerFormat,
    generateURLSearchParams,
    getUrlParams,
    numberFormat,
    onExportFile,
    sendToast,
    getLaboratoireLogo
} from "../../../../helpers/helpers"
import { DailyDetailsModal } from "../../_common/_DailyDetailsModal"
import { DetailsModal } from '../../_common/_DetailsModal'
import { transformJournalierData } from "../helpers/dataTransform"
import { JournalierCharts } from "./_JournalierCharts"

const SAVE_SEARCH_KEY = "RESULTAT_EXPLOITATION_PRODUCTION_JOURNALIER"

class ResultatJournalier extends CustomComponent {
    private _isMounted = false;

    private default_filters = {
        date_debut: moment.utc().subtract(3, 'month').toDate(),
        date_fin: moment.utc().toDate(),
        cri_id_1: '',
        cri_id_2: '',
        orderBy: 'nom_usuel',
        ordering: 'ASC',
        criteres: []
    }

    constructor(props: any) {
        super(props);
        this.state = {
            logo_path: '',
            echantillons: [],
            cardData: null,
            tableData: null,
            chartsData: [],
            filters: { ...this.default_filters, ...this.getParamsAsFilters() },
            options: {
                criteres: []
            },
            recherches: [],
            displayAsCharts: false,
            paginate: {
                count: 0,
                limit: 15,
                page: 0,
                pageTotal: 0,
            },
            isLoading: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getLogoPath();
        this.getRecherches();
        this.getCriteresOptions().then(() => {
            this.getResultats()
        })
    }

    componentDidUpdate(prevProps: Readonly<any>): void {
        const productionIDChanged = this.props?.production?.id !== prevProps?.production.id
        const displayAsChartsChanged = this.props?.displayAsCharts !== prevProps?.displayAsCharts

        if (productionIDChanged || displayAsChartsChanged) {
            this.getCriteresOptions().then(() => {
                this.getResultats()
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getParamsAsFilters = () => {
        const params = new URLSearchParams(this.props.location.search);
        let filters: any = {};

        if (params.has('date_d')) Object.assign(filters, { date_debut: params.get('date_d') });
        if (params.has('date_f')) Object.assign(filters, { date_fin: params.get('date_f') });

        return filters;
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.getResultatsForTable(true))
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), this.getResultatsForTable)
    }

    getCriteresOptions = async () => {
        const URL = `/references/critere_analyse/${this.props?.production?.id}`
        const data = await this.request(URL, 'GET')
        const criteres_unites = data.filter((cri_uni: any) => cri_uni.cri_id.quali_quanti === "Q")

        const critere1 = criteres_unites.find((cri: any) => cri.cri_id.code_critere === 'MAGR')
        const critere2 = criteres_unites.find((cri: any) => cri.cri_id.code_critere === 'MAPR')

        const criteres_options = criteres_unites.map((option: any) => {
            if (option.uni_id) {
                return { name: `${option.cri_id.lib_court} (${option.uni_id.lib_long})`, value: option.cri_id.id }
            }
            return { name: `${option.cri_id.lib_court}`, value: option.cri_id.id }
        })

        this.setState((prev: any) => ({
            options: {
                ...prev.options,
                criteres: criteres_options
            },
            filters: {
                ...prev.filters,
                cri_id_1: prev.filters.cri_id_1 || critere1?.cri_id?.id,
                cri_id_2: prev.filters.cri_id_2 || critere2?.cri_id?.id,
            }
        }));
    }

    getResultats = () => {
        if (this.props.displayAsCharts) {
            this.getResultatsForCharts()
        } else {
            this.getResultatsForTable()
        }
    }

    getResultatsForTable = async (triggeredByLoadMore: boolean = false) => {
        if (!this.state.filters.date_debut || !this.state.filters.date_fin) {
            sendToast(<p>Les champs périodes doivent être renseignés pour lancer une recherche.</p>, TOAST_TYPE.ERROR);
            return;
        }

        const URL = `/resultats/production/${this.props.production.id}/journalier`

        if (!checkIfCriteresAreFilled(this.state.filters.criteres)) return;

        const params = generateURLSearchParams(this.state.paginate, this.state.filters)

        const page = triggeredByLoadMore ? this.state.paginate.page + 1 : 0
        params.set('page', page)

        this.setState({ isLoading: true })
        const data = await this.request(`${URL}?${params.toString()}`, 'GET')
        this.setState({ isLoading: false })

        if (data && data.echantillons) {
            const echantillons = triggeredByLoadMore ? [...this.state.echantillons, ...data.echantillons] : data.echantillons
            const { tableData, cardData } = data.echantillons.length > 0
                ? transformJournalierData(echantillons)
                : { tableData: null, cardData: null };

            this.setState({
                tableData,
                cardData,
                echantillons,
                paginate: data.paginate
            })
        }
    }

    getResultatsForCharts = async () => {
        const URL = `/resultats/production/${this.props.production.id}/journalier/charts`
        const params = generateURLSearchParams({}, this.state.filters)

        this.setState({ isLoading: true })
        const data = await this.request(`${URL}?${params.toString()}`, 'GET')
        this.setState({ isLoading: false })

        if (data && data.statusCode === 200) {
            this.setState({ chartsData: data.data })
        }
    }

    onDateClick = async (id: any) => {
        this.setState({ isLoading: true })
        this.request(`/res_echantillon/resEchantillionDetail/${id}`, 'GET').then((data) => {
            this.setState({ isLoading: false })
            if (data.statusCode === 200) {
                this.setState({
                    detailsModal: data.data,
                })
            }
        })
    }

    onDailyDetailClick = async (id: any) => {
        this.setState({ isLoading: true })
        this.request(`/res_resultat_journalier/resResultatJournalierDetail/${id}`, 'GET').then((data) => {
            this.setState({ isLoading: false })
            if (data.statusCode === 200) {
                this.setState({
                    dailyDetailsModal: data.data,
                })
            }
        })
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/${SAVE_SEARCH_KEY}`, 'GET')

        if (recherches && this._isMounted) {
            this.setState({ recherches })
        }
    }

    updateThresholds = (criteres: ICriteres[]) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                criteres
            }
        }))
    }

    onExportCsv = () => {
        const fileName: string = `${this.props.production.pdf_siret_producteur}_ECHANTILLONS_R_${dateFormat(undefined, 'YYYYMMDD')}.csv`;
        const params: string = getUrlParams(this.state.filters, {});
        const url = `/exports/production/${this.props.production.id}/journalier?${params}`;

        onExportFile(this, fileName, url, 'GET');
    }

    onExportPdf = () => {
        const fileName: string = `${this.props.production.pdf_siret_producteur}_JOURNALIERS_Du_${dateFormat(this.state.filters.date_debut, 'YYYYMMDD')}_Au_${dateFormat(this.state.filters.date_fin, 'YYYYMMDD')}_${dateFormat(undefined, 'YYYYMMDD')}.pdf`;
        const params: string = getUrlParams(this.state.filters, {});
        const url = `/exports/production/${this.props.production.id}/journalier/pdf?${params}`;

        onExportFile(this, fileName, url, 'GET');
    }

    async getLogoPath() {
        const { lab_id } = this.props.production
        const logo_path = await getLaboratoireLogo(lab_id);
        this.setState({ logo_path });
    }

    render() {
        return !this.props.displayAsCharts ? (<>
            <LoadingIndicator loading={this.state.isLoading} />
            <FilterDropdown
                startOpen={true}
                onExport={{ csv: this.onExportCsv, pdf: this.onExportPdf }}
                parent={this}
                type_recherche={SAVE_SEARCH_KEY}
                filters={this.state.filters}
                recherches={this.state.recherches}
                onUpdate={this.getRecherches}
                onSearchLoad={(filters: any) => this.setState({ filters }, this.getResultatsForTable)}
                onApplyFilters={this.getResultatsForTable}
                onFiltersSearch={this.getResultatsForTable}
                onFiltersReset={() => { this.setState({ filters: this.default_filters }) }}>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group form-group--inline">
                                    <FormDatePicker
                                        name="date_debut"
                                        type="text"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        value={datePickerFormat(this.state.filters.date_debut)}
                                        label="Période du" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group form-group--inline">
                                    <FormDatePicker
                                        name="date_fin"
                                        type="text"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        value={datePickerFormat(this.state.filters.date_fin)}
                                        label="au" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <CriteriaPanel criteres={this.state.filters.criteres} onChange={this.updateThresholds} displayLibLong />
                    </div>
                </div>
            </FilterDropdown>
            {!this.state.tableData ? (
                <TABLE.NO_RESULT />
            ) : (<>
                <div className="desktop-only">
                    <TABLE fixed bordered>
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH fixed={{ left: 0 }} logo>
                                    {this.props.logo_path ? <img className="table-logo__logo" alt={this.props.production.lab_name} src={this.props.logo_path} /> : <div className="table-logo__label">{this.props.production.lab_name}</div>}
                                </TABLE.TH>
                                {this.state.tableData.dates.map((ech: any) => (<React.Fragment key={ech.id}>
                                    <TABLE.TH>
                                        <div className="d-flex flex-column align-items-center">
                                            <button onClick={() => this.onDateClick(ech.id)} className="link link--white link--strong" type="button">{ech.date}</button>
                                        </div>
                                    </TABLE.TH>
                                </React.Fragment>))}
                                {this.state.paginate.page + 1 < this.state.paginate.pageTotal && (
                                    <TABLE.TH transparent>
                                        <SeeMoreButton paginate={this.state.paginate} onClick={() => this.getResultatsForTable(true)} disabled={this.state.isLoading} variant="link" />
                                    </TABLE.TH>
                                )}
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.tableData.criteres.map((critere: any) => (
                                <TABLE.TR key={critere.name} dark={false}>
                                    <TABLE.TD fixed={{ left: 0 }} light>
                                        <div className="d-flex justify-content-between">
                                            <strong>{critere.name}</strong>
                                            <div className="text-right">
                                                <div className="m-b-5">{critere?.unite_mesure || '\xa0'}</div>
                                                {critere.double && <div className="small">Note</div>}
                                            </div>
                                        </div>
                                    </TABLE.TD>
                                    {critere.entries.map((entry: any, i: number) => (<React.Fragment key={`${entry.id}-${critere.name}-${i}`}>
                                        <TABLE.TD horsSeuil={entry.hors_seuil}>
                                            <div {...(entry.rej_id && { className: 'table-clickable-cell', onClick: () => this.onDailyDetailClick(entry.rej_id) })}>
                                                {critere.double ? (<>
                                                    {['ANN', 'SUS'].includes(entry.statut?.code) ? (
                                                        <div className="m-b-5 text-center">{entry?.statut?.libelle}</div>
                                                    ) : (<>
                                                        <div className={classNames({
                                                            'm-b-5 text-center': true,
                                                            'font-weight-bold': entry.displayBold
                                                        })}>
                                                            {!!entry.value && <>
                                                                {!!entry.hors_paiement ? (<span data-tip="Résultat hors paiement">({entry?.signe}{numberFormat(entry.value, critere?.nb_decimales)})</span>) : (<>{entry?.signe}{numberFormat(entry.value, critere?.nb_decimales)}</>)}
                                                            </>}
                                                            {!!entry.appreciation && <>
                                                                {!!entry.hors_paiement ? (<span data-tip="Résultat hors paiement">({entry.appreciation})</span>) : (<>{entry.appreciation}</>)}
                                                            </>}
                                                            {(!entry.value && !entry.appreciation) && <>-</>}
                                                        </div>
                                                        <div className="text-center small">{entry.note || '-'}</div>
                                                    </>)}
                                                </>) : (
                                                    <div className="text-center">{entry?.signe}{numberFormat(entry.value, critere?.nb_decimales) || '-'}</div>
                                                )}
                                            </div>
                                        </TABLE.TD>
                                    </React.Fragment>))}
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>
                </div>
                <div className="mobile-only">
                    <TABLE responsive="accordion">
                        <TABLE.TBODY>
                            {this.state.cardData.map((ech: any) => (
                                <TABLE.TR key={ech.id} title={ech.date} onTitleClick={() => this.onDateClick(ech.id)}>
                                    {ech.criteres.map((critere: any) => (
                                        <TABLE.TD key={`${ech.id}-${critere.name}`} label={critere.name} double horsSeuil={critere.hors_seuil}>
                                            <div {...(critere.rej_id && { className: 'table-clickable-cell', onClick: () => this.onDailyDetailClick(critere.rej_id) })}>
                                                <div className="table-accordion__double">
                                                    {['ANN', 'SUS'].includes(critere.statut?.code) ? (<>
                                                        <span>{critere?.statut?.libelle}</span>
                                                    </>) : (<>
                                                        <span className={classNames({ 'font-weight-bold': critere.displayBold })}>
                                                            {critere.unite_mesure && <strong>{critere.unite_mesure}</strong>}
                                                            {!!critere.value && <>
                                                                {critere.hors_paiement ? (<span data-tip="Résultat hors paiement">({critere?.signe}{numberFormat(critere.value, critere?.nb_decimales)})</span>) : (<>{critere?.signe}{numberFormat(critere.value, critere?.nb_decimales)}</>)}
                                                            </>}
                                                            {!!critere.appreciation && <>
                                                                {critere.hors_paiement ? (<span data-tip="Résultat hors paiement">({critere.appreciation})</span>) : (<>{critere.appreciation}</>)}
                                                            </>}
                                                        </span>
                                                        {critere.double && <span><strong>Note</strong><span>{critere.note || '-'}</span></span>}
                                                    </>)}
                                                </div>
                                            </div>
                                        </TABLE.TD>
                                    ))}
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>
                    <SeeMoreButton paginate={this.state.paginate} onClick={() => this.getResultatsForTable(true)} disabled={this.state.isLoading} />
                </div>
            </>)}
            {this.state.detailsModal && (
                <DetailsModal echDetails={this.state.detailsModal} onClose={() => this.setState({ detailsModal: null })} />
            )}
            {this.state.dailyDetailsModal && (
                <DailyDetailsModal dailyDetails={this.state.dailyDetailsModal} onClose={() => this.setState({ dailyDetailsModal: null })} />
            )}
        </>) : (<>
            <div className="charts-filters">
                <div className="charts-filters__section">
                    <label className="charts-filters__label">Sélectionner un ou deux critère(s) :</label>
                    <div className="charts-filters__input">
                        <FormSelect value={this.state.filters.cri_id_1} name="cri_id_1" handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.criteres} />
                    </div>
                    <div className="charts-filters__input">
                        <FormSelect value={this.state.filters.cri_id_2} name="cri_id_2" handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.criteres} />
                    </div>
                </div>
                <div className="charts-filters__section charts-filters__dates">
                    <label className="charts-filters__label" htmlFor="date_debut--charts">Période du</label>
                    <div className="charts-filters__input">
                        <FormDatePicker
                            id="date_debut--charts"
                            name="date_debut"
                            type="text"
                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                            value={datePickerFormat(this.state?.filters?.date_debut)}
                            isClearable />
                    </div>
                    <label className="charts-filters__label" htmlFor="date_fin--charts">au</label>
                    <div className="charts-filters__input">
                        <FormDatePicker
                            id="date_fin--charts"
                            name="date_fin"
                            type="text"
                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                            value={datePickerFormat(this.state?.filters?.date_fin)}
                            isClearable />
                    </div>
                </div>
                <div className="charts-filters__section charts-filters__submit">
                    <button type="button" onClick={this.getResultatsForCharts} className="btn btn-success">Appliquer</button>
                </div>
            </div>
            {this.state.chartsData.length > 0 ? (
                <JournalierCharts
                    data={this.state.chartsData}
                    production={this.props.production}
                    logo_path={this.state.logo_path}
                    date_debut={this.state.filters.date_debut}
                    date_fin={this.state.filters.date_fin}
                />
            ) : (
                <TABLE.NO_RESULT />
            )}
        </>)
    }
}


export default withTranslation()(withRouter(ResultatJournalier));