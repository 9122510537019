import moment from "moment/moment";
import DATALIST from "../../../components/DataList";
import Modal from "../../../components/Modal";
import { numberFormat } from '../../../helpers/helpers';
import { useEffect } from 'react';
import ReactToolTip from 'react-tooltip';
import { Infoblock } from '../../../components/InfoBlock';


interface DailyDetailsModalProps {
    dailyDetails?: any,
    onClose: Function
}

export const DailyDetailsModal = ({dailyDetails, onClose}: DailyDetailsModalProps) => {
    let infoText: string|null = null;
    if (!dailyDetails.fl_pour_paiement) {
        infoText = 'Les résultats entre parenthèses concernent des informations hors paiement. Ces résultats ne sont pas pris en compte dans le cadre du paiement du lait.';
    } else if (dailyDetails.fl_inclus_mensuel) {
        infoText = 'Ce résultat est pris en compte dans le calcul du résultat mensuel utilisable dans le cadre du paiement du lait.';
    }

    const checkIfEmptyTwoValues = (valueA: any, valueB?: any, separator: string = ' ') => {
        return valueA && valueB ? valueA + separator + valueB : '-'
    }

    const checkIfEmptyOneValue = (value: any) => {
        return value ? value : '-';
    }

    useEffect(() => {
        ReactToolTip.rebuild()
    },[])

    return(
        <Modal customLayout variant="data" open size="sm" centered={false} text_button="Fermer" onClose={onClose}>
            <Modal.Header>
                <div className="d-flex align-items-center m-b-30">
                    <div className="text-left m-r-30">
                        <div className="modal-title m-b-0 text-left">{dailyDetails.cri_id?.libelle} ({dailyDetails.uni_id?.lib_long})</div>
                        <span className="lead">{moment.utc(dailyDetails.date_analyse).format('DD/MM/YYYY')}</span>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                {infoText && <Infoblock>{infoText}</Infoblock>}
                <section>
                    <DATALIST>
                        <DATALIST.ITEM title="Statut" value={checkIfEmptyOneValue(dailyDetails.str_id?.libelle)}/>
                        {dailyDetails?.apr_id?.libelle && <DATALIST.ITEM title="Résultat" value={dailyDetails?.apr_id?.libelle} hors_paiement={!dailyDetails.fl_pour_paiement} bold={['N', 'P'].includes(dailyDetails.visibilite) || ['N', 'P'].includes(dailyDetails.ech_id?.visibilite)}/>}
                        {dailyDetails?.valeur && <DATALIST.ITEM title="Résultat" value={`${dailyDetails?.signe || ''}${numberFormat(dailyDetails?.valeur, dailyDetails?.cri_id?.nb_decimales)}`} hors_paiement={!dailyDetails.fl_pour_paiement} bold={['N', 'P'].includes(dailyDetails.visibilite)  || ['N', 'P'].includes(dailyDetails.ech_id?.visibilite)}/>}
                        <DATALIST.ITEM title="Note du résultat" value={checkIfEmptyOneValue(dailyDetails.note)}/>
                        <DATALIST.ITEM title="Laboratoire sous-traitant" value={checkIfEmptyTwoValues(dailyDetails.lab_id_sous_traitant?.ent_id?.siret, dailyDetails.lab_id_sous_traitant?.ent_id?.nom_usuel, ' - ')}/>
                    </DATALIST>
                </section>
            </Modal.Body>
            <Modal.Footer/>
        </Modal>
    )
}