import { withRouter } from 'react-router-dom';
import CustomComponent from '../../../components/CustomComponent';
import ExportLaiterieUpdate from './update';

class ExportLaiterieDetails extends CustomComponent {
    render() {
        return <ExportLaiterieUpdate id={this.props.match.id} screen_type={'DETAILS'}/>
    }
}

export default withRouter(ExportLaiterieDetails);
