import saveAs from 'file-saver';
import isOnline from 'is-online';
import moment from 'moment-timezone';
import ReactDOMServer from 'react-dom/server';
import { toast } from 'react-toastify';
import { API_DEBUG, API_URL, ASSETS_PATH, DISABLE_PERMISSIONS } from '../config';
import i18n from '../i18n';
import { app_history } from '../routes';
import fallback_logo from '../assets/images/pdf-logo-header.png';
import clip from "text-clipper";

export const test = "";
let lastToastMsg: string | JSX.Element = "";
let lastToastMsgTs: number = 0;

export enum TOAST_TYPE { INFO, SUCCESS, WARNING, ERROR, DEFAULT }

export const Request = async (url: string, method: string, body: any = null, isUploadForm = false, first_request = false, expect_download = false): Promise<any> => {
    const { t } = i18n as any

    let headers: any = {};
    let hasResponse = false;
    let requestBody = null;

    // use application/json except for upload file
    if (!isUploadForm) {
        headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }

    // Add JWT to request if exist (connected user)
    const jwt = getJWT();
    if (jwt && (!body || (body && !('refresh_token' in body)))) {
        headers['Authorization'] = 'Bearer ' + jwt;
    }

    // Add body
    if (body) {
        requestBody = isUploadForm ? body : JSON.stringify(body)
    }

    const settings: RequestInit = {
        method: method,
        headers: headers,
        credentials: 'include',
        ...(requestBody && { body: requestBody })
    };

    try {
        const response = await fetch(API_URL + url, settings);

        const data = (expect_download && response.status >= 200 && response.status < 300)
            ? await response.blob()
            : await response.json();

        API_DEBUG && console.log('response.status: ', response.status);

        // if response 401 & I have a refreshJWT & it's not a refresh token request then try to refresh token
        if (response.status === 401 && (!body || (body && !('refresh_token' in body))) && getRefreshJWT()) {
            // try to refreshJWT
            if (await RefreshJWT()) {
                // re-try request & return value
                return await Request(url, method, body, isUploadForm, first_request);
            }
            return null;
        }
        // if refresh JWT expired -> deconnexion
        else if (response.status === 401 && body && 'refresh_token' in body && 'message' in data && data.message === "jwt expired") {
            sendToast(<p>{t("disconnected")}</p>, TOAST_TYPE.ERROR);
            return data;
        }
        // Display back error
        else if (response.status >= 400 && response.status <= 500) {
            // ! Warning : unused variable
            hasResponse = data ? true : false;
            API_DEBUG && console.log(data);

            try {
                const messages = await extractMessagesFromResponse(data)
                sendToast(<>{messages.map((m: string) => <p key={`toaster-message-${m}`}>{m}</p>)}</>, TOAST_TYPE.ERROR);
            } catch (e) {
                const message = e instanceof Error
                    ? e.message
                    : 'Unknown error'
                sendToast(<p>{message}</p>, TOAST_TYPE.ERROR)
            }

            return data;
        }

        if (!response.ok)
            throw Error(response.statusText);

        // success 
        API_DEBUG && console.log(data);

        return data;
    } catch (e) {

        console.log("e", e);

        if (!hasResponse) {
            if (await isOnline({ timeout: 1000 })) {
                // client is online but API is unavailable
                sendToast(<p>{t("api_unavailable")}</p>, TOAST_TYPE.ERROR);
            }
            else {
                // client is offline
                sendToast(<p>{t("offline")}</p>, TOAST_TYPE.ERROR);
            }
        }
        else {
            // other error
            console.error((e as Error).toString());
            sendToast(<p>{(e as Error).toString()}</p>, TOAST_TYPE.ERROR);
        }
        return null;
    }
}

export const sendToast = (msg: string | JSX.Element, type: TOAST_TYPE = TOAST_TYPE.DEFAULT) => {
    let msg_str: string = "";
    if (msg as JSX.Element) {
        msg_str = ReactDOMServer.renderToString(msg as JSX.Element);
    } else {
        msg_str = msg as string;
    }

    if (msg_str.includes('jwt expired')) { return; }

    // remove toast spam
    if (msg_str !== lastToastMsg || (Date.now() - lastToastMsgTs > 3000)) {
        switch (type) {
            case TOAST_TYPE.INFO:
                toast.info(msg);
                break;
            case TOAST_TYPE.SUCCESS:
                toast.success(msg);
                break;
            case TOAST_TYPE.WARNING:
                toast.warning(msg);
                break;
            case TOAST_TYPE.ERROR:
                toast.error(msg);
                break
            default:
                toast(msg);
        }
        lastToastMsg = msg_str;
        lastToastMsgTs = Date.now();
    }
}

export const getJWT = () => {
    return localStorage.getItem('access_token') || null;
}

export const getRefreshJWT = () => {
    return localStorage.getItem('refresh_token') || null;
}

export const RefreshJWT = async () => {
    const refresh_token: string = getRefreshJWT() || "";
    let url = "/auth/refresh";
    let method = "POST";
    let body = { refresh_token: refresh_token };
    let data = await Request(url, method, body);
    if (data) {
        if ('access_token' in data) {
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            return true;
        }
        else if ('expiredAt' in data && 'message' in data && (data.message === "jwt expired" || data.message === "Le jeton de rafraichissement est invalide")) {
            // token expired -> auto logout
            localStorage.clear();
            app_history.push("/login");
        }
    }
    return false;
}

export const getUser = () => {
    let user_str: string | null = localStorage.getItem('per_personne');
    if (user_str !== null) {
        let user = JSON.parse(user_str) || {};
        if (user) {
            return user;
        }
    }
    return null;
};

export const getHabilitation = () => {
    let habilitations_str: string | null = localStorage.getItem('per_habilitations');
    let hab_id: number = parseInt(localStorage.getItem('habilitation_id') || "");
    if (habilitations_str !== null && hab_id !== null) {
        let habilitations = JSON.parse(habilitations_str) || {};
        if (habilitations.length > 0) {
            for (const habilitation of habilitations) {
                if (habilitation.id === hab_id) {
                    return habilitation;
                }
            }
        }
    }
    return null;
};

export const getHabilitations = () => {
    const per_habilitations: string | null = localStorage.getItem('per_habilitations') || null;
    let habilitations: [] = [];
    if (per_habilitations && checkIsJSON(per_habilitations)) {
        habilitations = JSON.parse(per_habilitations) || [];
        if (habilitations.length > 0) {
            return habilitations.sort((a: any, b: any) => a.ent_id.nom_usuel.localeCompare(b.ent_id.nom_usuel));
        }
        return habilitations;
    }
    return null;
};

export const getSession = (): any => {
    const session_str: string | null = localStorage.getItem('session') || null;
    let session: {} = {};
    if (session_str && checkIsJSON(session_str)) {
        session = JSON.parse(session_str);
    }
    return session;
};

export const isLoggedIn = (): boolean => {
    const session = getSession() as Object
    return session.hasOwnProperty('id') && session.hasOwnProperty('per_id') && session.hasOwnProperty('mail_identifiant')
}

export const checker = (value: any, arr: any) => {
    return !arr.every(function (v: any) {
        return value?.indexOf(v) === -1;
    });
};

export const checkIsJSON = (text: string) => {
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
}

export const getLocalStorageSession = () => {
    let session_str: string | null = localStorage.getItem('session');
    if (session_str && checkIsJSON(session_str)) {
        let session: {} = JSON.parse(session_str) || {};
        return session;
    }
    return null;
}

export const hasPermission = (permissions: string | string[]) => {
    if (DISABLE_PERMISSIONS) return true
    const habDroit: string | null = localStorage.getItem('habDroit')
    if (!habDroit) return false
    const user_permissions: string[] = JSON.parse(habDroit).map((per: any) => per.code_droit)

    return typeof permissions === 'string'
        ? user_permissions.includes(permissions)
        : permissions.length === 0 || permissions.filter(perm => user_permissions.includes(perm)).length > 0
}

export const hasOneHabilitation = (habilitations: string[]) => {
    return checker(getHabilitation()?.hab_code.code_hab, habilitations)
}

export const sortByField = (data: any, sortField: string, sortDirection: 'ASC' | 'DESC') => {
    return data.sort((a: any, b: any) => {
        const A = (a[sortField] || '').toString().toLowerCase()
        const B = (b[sortField] || '').toString().toLowerCase()

        if (A === B) return 0
        if (sortDirection === 'DESC') return A < B ? -1 : 1
        if (sortDirection === 'ASC') return B < A ? -1 : 1
        return 0
    })
}

export const slugify = (str: string): string => str
    .normalize("NFD")
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '_')
    .replace(/^-+|-+$/g, '');

export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

export const numberUnformat = (value: any) => {
    if (!value) return value;
    return value.toString()
        .replace(',', '.')
        .replace(' ', '');

};

export const numberFormat = (value: any, decimals?: number) => {
    if (!value) return '-';
    if (!value || !/\d+[.,]?\d*/.test(value.toString())) return value;
    value = value.toString();

    if (Number.isInteger(decimals)) value = Number(value).toFixed(decimals);

    const splitVal = value.split(/[.,]/);
    let int = splitVal[0];

    if (int.length < 4) return value.replace('.', ',');

    let parts = [];
    for (let i = 0; i < int.length; i += 3) {
        parts.push(int.substring(int.length - i, int.length - i - 3));
    }
    int = parts.reverse().join(' ');

    if (splitVal.length === 1) return int;
    return [int, splitVal[1]].join(',');
};

export const datePickerFormat = (date?: Date | string | null): Date | null => {
    if (!date) return null;
    if (date instanceof Date) return date;
    return moment.utc(date).toDate();
}

export const dateFormat = (date_source: Date | string | undefined, format: string, disableUtcOffset?: boolean): string => {
    let date = disableUtcOffset ? moment.utc(date_source) : moment.tz(date_source, 'Europe/Paris');
    return capitalize(date.format(format));
}

export const displayValueByStatus = (status: any, value: string | number, replace: string | null = null): string | number => {
    if (status?.code === "ANN" || status?.code === "SUS")
        return replace ? replace : status.lib_long;
    return value;
}

export const checkIfCriteresAreFilled = (criteres: any) => {
    const { t } = i18n as any;
    for (const key of Object.keys(criteres)) {
        const val = criteres[key];
        for (let [index, value] of Object.entries(val)) {
            if (index !== 'quali') {
                value = (value as string).trim();
                if (value !== '') {
                    // if field is filled, check all fields 
                    if (val['cri_id'].trim() === 'Sélectionner' || val['operator'].trim() === 'Sélectionner' || (val['app_id'].trim() === '' && val['value'].trim() === '')) {
                        sendToast(<p>{t('error_empty_critere_operator_appr_field')}</p>, TOAST_TYPE.ERROR);
                        return false;
                    }
                }
            }
        }
    }
    return true;
}

export const getUrlParams = (filters: any, paginate: any): string => {
    let f = { ...filters };
    let params = new URLSearchParams()

    // format Date object to string
    for (const [key, value] of Object.entries(f)) {
        if (value instanceof Date)
            f[key] = (value as Date).toISOString();
    }

    Object.keys(paginate).forEach(key => {
        const val = paginate[key];
        if (val !== '') params.append(key, val);
    });

    Object.keys(f).forEach(key => {
        let val = f[key];
        if (key === 'criteres') val = JSON.stringify(val.filter((crit: any) => !!crit.cri_id && !!crit.operator && (!!crit.value || !!crit.app_id)));
        if (val instanceof Date) val = (val as Date).toISOString();
        if (val !== '' && val !== null && val !== undefined) params.append(key, val.toString());
    });

    return params.toString()
}

export const generateURLSearchParams = (paginate: any, filters: any): URLSearchParams => {
    let f = { ...filters };
    const params = new URLSearchParams()

    // format Date object to string
    for (const [key, value] of Object.entries(f)) {
        if (value instanceof Date)
            f[key] = (value as Date).toISOString();
    }

    Object.keys(paginate).forEach(key => {
        const val = paginate[key]
        if (val !== '') params.append(key, val)
    })

    Object.keys(f).forEach(key => {
        let val = f[key]

        if (key === 'criteres') {
            val = val.map((item: any) => ({ ...item, value: numberUnformat(item.value) }))
            val = JSON.stringify(val.filter((crit: any) => !!crit.cri_id && !!crit.operator && (!!crit.value || !!crit.app_id)))
        }
        if (val !== '') params.append(key, val)
    })

    return params
}

export const labelizePersonneEntites = (entArr: any) => {
    if (!!entArr && entArr.length) {
        const labels = entArr.map((e: any) => `${e.siret} - ${e.nom_usuel}`);
        return labels.join('\n');
    }
    return '-';
}

export const labelizeEntite = (siret: string | null, nom_usuel: string | null) => {
    if (!!siret && !!nom_usuel) {
        return `${siret} - ${nom_usuel}`;
    }
    return '';
}

export const getFileNameFromPath = (path: string | null) => {
    if (!path) return '';
    const splitPath = path.split('/');
    return splitPath[splitPath.length - 1];
}

export const truncateString = (
    string: string,
    charLimit: number,
    ellipsis: string = '',
    isHtml: boolean = false,
    maxHtmlLines: number | undefined = undefined,
    stripHtmlTags: true | string[] | undefined = undefined,
) => {
    if (isHtml) return clip(string, charLimit, { breakWords: true, html: true, indicator: ellipsis, maxLines: maxHtmlLines, stripTags: stripHtmlTags });
    if (string.length > charLimit) return string.slice(0, (charLimit - ellipsis.length)) + ellipsis;
    return string;
}

export const isHtmlEmpty = (htmlString: string | null): boolean => {
    if (!htmlString) return true;
    const strippedAndClipped = clip(htmlString, 3, { html: true, stripTags: true }).replace('\n', '');
    return !strippedAndClipped;
}

/**
 * Parses given data to extract a "message" field and returns it in an array
 * 
 * This function can handle [Object] and [Blob]. If the message is not an array
 * it is pushed into one.
 * 
 * @param data 
 * @returns 
 */
const extractMessagesFromResponse = async (data: unknown) => {
    if (data instanceof Blob) {
        try {
            const parsed = JSON.parse(await readBlob(data))

            if (!isWithMessage(parsed)) {
                throw new Error("Message is missing")
            }

            return Array.isArray(parsed.message) ? parsed.message : [parsed.message]

        } catch (e) {
            throw new Error("Message is missing")
        }
    } else if (isWithMessage(data)) {
        return Array.isArray(data.message) ? data.message : [data.message]
    }

    throw new Error("Message is missing")
}

type WithMessage = { message: unknown | unknown[] }

/**
 * TypeGuard for checking if given value is a [WithMessage].
 * 
 * @param value 
 * @returns 
 */
const isWithMessage = (value: unknown | WithMessage): value is WithMessage => value !== null
    && value !== undefined
    && typeof value === 'object'
    && 'message' in value

/**
 * Returns a promise of FileReader. Only onload and onerror are used, respectively for the resolve
 * and reject callback functions.
 * 
 * @param blob 
 * @returns 
 */
const readBlob = async (blob: Blob): Promise<string> => new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
        const content = fileReader.result?.toString()
        content ? resolve(content) : reject(new Error())
    }
    fileReader.onerror = reject
    fileReader.readAsText(blob)
})

/**
 * Returns true if the given value is defined and not null
 * Returns true even if valeur is a number and equals 0
 *
 * @param value?: unknown
 * @returns
*/
export const exists = (value?: unknown): boolean => value !== undefined && value !== null;

/**
 * Takes an array of strings and converts it to an array of numbers
 */
export const strArrayToNumArray = (arr: string[]) => arr.map(el => Number(el));

/**
 * Returns laboratoire logo if exists, otherwise returns fallback logo
 */
export const getLaboratoireLogo = async (id: number): Promise<string> => {
    const logo_path = `${ASSETS_PATH?.replace(/\/$/, '')}/logo-labo-${id.toString().padStart(2, '0')}-pdf.png`;

    const headers = { "Access-Control-Allow-Origin": "*" };
    const { status } = await fetch(logo_path, { method: 'GET', headers });

    return status === 200 ? logo_path : fallback_logo;
}

export const onExportFile = async (self: any, filename: string, url: string, method: string, body: any = null) => {
    try {
        self.setState({ isLoading: true });

        const blob: Blob = await Request(url, method, body, false, false, true);

        if (!!blob && blob instanceof Blob) {
            saveAs(blob, filename);
        }

        self.setState({ isLoading: false });

    } catch (err: any) {
        console.log(err);
        self.setState({ isLoading: false });
    }
}

/**
 * Updates unread_annonces in localStorage.
 * Can indicate a value to update to instead of doing a request.
 */
export const updateUnreadAnnonces = async (value?: number[]): Promise<void> => {
    let newValue: number[] = [];

    if (value !== undefined) {
        newValue = value;
    }
    else {
        const data: { statusCode: number, unreadAnnonces: number[] } = await Request('/inf_annonce/check_unread', 'GET');
        if (data?.statusCode === 200) {
            newValue = data.unreadAnnonces;
        }
    }

    localStorage.setItem('unread_annonces', JSON.stringify(newValue));
}

export const formatSiretNomUsuel = (siret?: string, nom_usuel?: string) => {
    if (!siret || !nom_usuel) return '-';

    return `${siret} - ${nom_usuel}`;
}

export const cleanImportResultatFileName = (input?: string): string => {
    try {
        if (!input) return '';
    
        const [filename, extension] = input.split('.');
        if (!filename || !extension) return input;
    
        const originalFilename = filename.split('-').shift();
        return `${originalFilename}.${extension}`;
    } catch (_) {
        return input || '';
    }
}