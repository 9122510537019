import moment from "moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import CustomComponent from "../../components/CustomComponent";
import { FileUploadModal } from "../../components/FileUploadModal";
import { FileUpload, FormDatePicker, FormInput, FormInputSelect, FormRadioValues, TextEditor } from "../../components/FormComponents";
import { DefaultLayout } from "../../components/Layouts";
import Modal from "../../components/Modal";
import Panel from "../../components/Panel";
import { TYPES_DESTINATAIRE_ANNONCE, TYPES_DESTINATAIRE_ANNONCE_OPTIONS } from "../../enum/types_destinataire_annonce";
import { TOAST_TYPE, sendToast } from "../../helpers/helpers";
import { validateFile, validateLaterOrToday, validateLaterThanDate } from "../../helpers/validation";
import { initValidator } from "../../validations/validations";
import { isHtmlEmpty, updateUnreadAnnonces } from "../../helpers/helpers";
import { AnnoncePreviewModal } from "./_preview_modal";

class AnnonceCreate extends CustomComponent {
    private _isMounted = false;
    private _imageUploadMaxSize = 0.5;
    private _fileUploadMaxSize = 2;
    private _maxFileNumber = 5;
    private _imageAcceptedTypes = ['JPG', 'JPEG'];
    private _fileAcceptedTypes = ['PDF', 'DOCX', 'XLSX', 'PNG'];
    private _today = moment.utc().startOf('day').toDate();
    private _title = '';
    private _annonceId: string | undefined = this.props.annonceId;
    private readonly validator;


    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: false,
            isSubmitted: false,
            isImageUploadOpen: false,
            options_type_destinataire: TYPES_DESTINATAIRE_ANNONCE_OPTIONS,
            options_destinataire: [],
            deletingAnnonce: false,
            initialAttachments: [],
            initialDetails: '',
            isPrevisuOpen: false,
            isStartDateDisabled: false,
            annonce: {
                titre: null,
                type_destinataire: TYPES_DESTINATAIRE_ANNONCE.CLA,
                destinataires: null,
                date_debut: this._today,
                date_fin: null,
                image: null,
                details: null,
                attachments: null,
                nom_image: null,
                src_image: null,
            },
        }

        this.validator = initValidator();
    }

    componentDidMount() {
        const { t } = this.props;
        this._title = this._annonceId ? t('annonces.create.title_update') : t('annonces.create.title');
        this._isMounted = true;
        document.title = `Infolabo | ${this._title}`;

        if (this._annonceId) {
            void this.getAnnonce();
        }
        else {
            void this.getDestinataireOptions();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getAnnonce = async () => {
        this.setState({ isLoading: true });

        const data = await this.request(`/inf_annonce/admin/${this._annonceId}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200 && this._isMounted) {
            const { annonce } = data;
            const date_debut: Date = moment.utc(annonce.date_debut).toDate();
            const date_fin: Date | null = annonce?.date_fin && moment.utc(annonce.date_fin).toDate();
            const destinataires = annonce.type_destinataire === TYPES_DESTINATAIRE_ANNONCE.CLA ? annonce.destinataires_cla : annonce.destinataires_grp;
            const initialAttachments = annonce.attachments?.map((attachment: string) => new File([], attachment)) ?? [];
            const isStartDateDisabled: boolean = moment.utc(annonce.date_debut).isSameOrBefore(this._today);

            this.setState({
                annonce: {
                    titre: annonce.titre,
                    type_destinataire: annonce.type_destinataire,
                    destinataires,
                    date_debut,
                    date_fin,
                    image: (annonce.nom_image ? new File([], annonce.nom_image) : null),
                    details: annonce.details,
                    attachments: initialAttachments,
                    nom_image: annonce.nom_image ?? null,
                    src_image: annonce.src_image,
                },
                initialDetails: annonce.details,
                initialAttachments,
                isStartDateDisabled,
            }, () => void this.getDestinataireOptions(undefined, true));

        }
    }

    getDestinataireOptions = async (e: any = null, initEdit: boolean = false) => {
        const params = new URLSearchParams();

        if (this.state.annonce.type_destinataire === TYPES_DESTINATAIRE_ANNONCE.GRP) {
            params.append('type_groupe', 'P');
            params.append('owner_only', 'true');

            if (e) {
                if (e.action.action !== 'input-change') return;
                params.append('query', e.data);
            }

            const data = await this.request(`/ent_groupe/autocomplete?${params.toString()}`, 'GET');

            if (data?.statusCode === 200 && this._isMounted) {
                this.setState((prev: any) => ({
                    options_destinataire: data.groupes.map((option: any) => ({ label: option.nom, value: option.id })),
                    ...(!initEdit && {
                        annonce: {
                            ...prev.annonce,
                            destinataires: null,
                        }
                    }),
                }));
            }
        }
        else if (this.state.annonce.type_destinataire === 'CLA') {
            params.append('restricted', 'true');
            const dataClasses = await this.request(`/ref_classe_entite/autocomplete?${params.toString()}`, 'GET');

            if (!!dataClasses && !('error' in dataClasses) && this._isMounted) {
                this.setState((prev: any) => ({
                    options_destinataire: dataClasses,
                    ...(!initEdit && {
                        annonce: {
                            ...prev.annonce,
                            destinataires: null,
                        }
                    }),
                }));
            }
        }
    }

    handleRadio = (e: any) => {
        const value = e.target.value;

        if (value && value !== this.state.annonce.type_destinataire) {
            this.setState((prev: any) => ({
                annonce: {
                    ...prev.annonce,
                    type_destinataire: value,
                }
            }), this.getDestinataireOptions);
        }
    }

    handleDestinatairesSelect = (e: any) => {
        const newDestinataires = e.data.map((option: any) => option.value);

        this.setState((prev: any) => ({
            annonce: {
                ...prev.annonce,
                destinataires: newDestinataires,
            }
        }));
    }

    imageUpload = (file: File) => {
        this.setState((prev: any) => ({
            isImageUploadOpen: false,
            annonce: {
                ...prev.annonce,
                image: file,
            }
        }));
    }

    attachedFilesUpload = (file: File[] | null) => {
        if (file) {
            this.setState((prev: any) => ({
                annonce: {
                    ...prev.annonce,
                    attachments: Array.isArray(file) ? file : [file],
                }
            }))
        }
    }

    handleTextEditor = (value: string) => {
        this.setState((prev: any) => ({
            annonce: {
                ...prev.annonce,
                details: value,
            }
        }));
    }

    afterSubmission = async (event: React.FormEvent) => {
        event.preventDefault();
        const { t } = this.props;

        this._isMounted && this.setState({ isSubmitted: true });


        // details and date fields are not handled by validator
        const detailsValid: boolean = !isHtmlEmpty(this.state.annonce.details);
        const datesValid: boolean = (
            (this.state.isStartDateDisabled || !validateLaterOrToday(this.state.annonce.date_debut)) &&
            !validateLaterThanDate(this.state.annonce.date_fin, this.state.annonce.date_debut, '')
        );

        if (this.validator.allValid() && datesValid && detailsValid) {
            await this.createUpdateAnnonce();
        } else {
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }
    }

    createUpdateAnnonce = async () => {
        this.setState({ isLoading: true });
        const annonce = this.state.annonce;

        const URL = this._annonceId ? `/inf_annonce/${this._annonceId}` : `/inf_annonce`;
        const METHOD = this._annonceId ? 'PATCH' : 'POST';

        const formData = new FormData();
        formData.append('titre', annonce.titre);
        formData.append('type_destinataire', annonce.type_destinataire);
        formData.append('destinataires', JSON.stringify(annonce.destinataires));
        formData.append('date_debut', moment.utc(annonce.date_debut).toISOString());
        annonce.date_fin && formData.append('date_fin', moment.utc(annonce.date_fin).toISOString());
        formData.append('details', annonce.details);
        annonce.image && formData.append('image', annonce.image);
        annonce?.attachments?.forEach((attachment: File) => formData.append('attachment', attachment));

        const data = await this.request(URL, METHOD, formData, true);
        this.setState({ isLoading: false });

        if (data?.statusCode === 201) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            await updateUnreadAnnonces();
            this.props.history.push('/annonces');
        }
    }

    deleteAnnonce = async () => {
        const data = await this.request(`/inf_annonce/${this._annonceId}`, 'DELETE');
        if (data?.statusCode === 200 && this._isMounted) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.props.history.push(`/annonces`);
        }
    }

    render() {
        const { t } = this.props;

        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Annonces', path: '/annonces' }, { name: this._title }]} />
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="d-flex justify-content-between align-items-start flex-wrap m-b-15">
                            <h1 className="main-title m-b-15">{this._title}</h1>
                            <div className="d-flex flex-wrap m-b-5">
                                <Link to="/annonces" className="btn m-r-5">{t('annonces.create.cancel_btn')}</Link>
                                <button type="button" className="btn btn-dark m-r-5" onClick={() => this.setState({isPrevisuOpen: true})}>{t('annonces.create.preview_btn')}</button>
                                <button type="submit" className="btn btn-secondary">{t('annonces.create.save_btn')}</button>
                                {this._annonceId && (
                                    <button type="button" className="btn btn-danger m-l-5" onClick={() => this.setState({ deletingAnnonce: true })}>
                                        {t('annonces.create.delete_btn')}
                                    </button>
                                )}
                            </div>
                        </div>

                        <Panel title={t('annonces.create.panel_title')}>
                            <div>
                                <h3 className="form-title">{t('annonces.create.general_info')}</h3>
                                <div className="form-group form-group--inline m-b-30">
                                    <FormInput
                                        id="title"
                                        name="titre"
                                        label={t('annonces.create.field_titre')}
                                        type="text"
                                        handle={(e: any) => this.handleInputChange(e, null, 'annonce')}
                                        value={this.state.annonce.titre || ''}
                                        isSubmitted={this.state.isSubmitted}
                                        required
                                        simpleValidator={this.validator}
                                        maxLength={100}
                                    />
                                </div>
                                <div className="form-group form-group--inline m-b-30">
                                    <FormRadioValues
                                        id="type_destinataire"
                                        name="type_destinataire"
                                        label={t('annonces.create.field_type_destinataire')}
                                        value={this.state.annonce.type_destinataire}
                                        options={this.state.options_type_destinataire}
                                        handle={(e: any) => this.handleRadio(e)}
                                        isSubmitted={this.state.isSubmitted}
                                        required
                                        simpleValidator={this.validator}
                                    />
                                </div>
                                <div className="form-group form-group--inline m-b-30">
                                    <FormInputSelect
                                        id="destinataires"
                                        name="destinataires"
                                        label={t('annonces.create.field_destinataires')}
                                        value={this.state.annonce.destinataires || []}
                                        options={this.state.options_destinataire}
                                        handle={this.handleDestinatairesSelect}
                                        handleInput={
                                            this.state.annonce.type_destinataire === 'GRP' ?
                                                (e: any) => this.getDestinataireOptions(e)
                                                : undefined
                                        }
                                        disabled={!this.state.annonce.type_destinataire}
                                        isSubmitted={this.state.isSubmitted}
                                        isMulti
                                        isClearable
                                        required
                                        simpleValidator={this.validator}
                                        validator_type="array"
                                    />
                                </div>
                                <div className="form-group form-group--inline m-b-30">
                                    <FormDatePicker
                                        id="date_debut"
                                        name="date_debut"
                                        label={t('annonces.create.field_date_debut')}
                                        type="text"
                                        value={this.state.annonce.date_debut || ''}
                                        handle={(e: any) => this.handleInputChange(e, null, 'annonce')}
                                        isSubmitted={this.state.isSubmitted}
                                        required
                                        validation={validateLaterOrToday}
                                        disabled={this.state.isStartDateDisabled}
                                    />
                                </div>
                                <div className="form-group form-group--inline m-b-30">
                                    <FormDatePicker
                                        id="date_fin"
                                        name="date_fin"
                                        label={t('annonces.create.field_date_fin')}
                                        type="text"
                                        value={this.state.annonce.date_fin || ''}
                                        handle={(e: any) => this.handleInputChange(e, null, 'annonce')}
                                        isSubmitted={this.state.isSubmitted}
                                        validation={(e: Date) => validateLaterThanDate(e, this.state.annonce.date_debut, 'de début de diffusion')}
                                        isClearable
                                    />
                                </div>
                                <div className="form-group form-group--inline m-b-30">
                                    <label className="label">{t('annonces.create.field_image')}</label>
                                    <button
                                        type="button"
                                        className="btn btn-lh-1 btn-dark"
                                        onClick={() => this.setState({ isImageUploadOpen: true })}
                                    >
                                        {t('annonces.create.field_image_add')}
                                    </button>
                                    {this.state.annonce.image?.name && (
                                        <div className="m-l-15 my-auto text-blue-80">
                                            {this.state.annonce.image?.name}
                                            <button
                                                type="button"
                                                title={t('annonces.create.field_image_delete')}
                                                className="m-l-5 p-0 border-0 bg-transparent text-reset"
                                                onClick={() => this.setState((prev: any) => ({
                                                    annonce: { ...prev.annonce, image: null }
                                                }))}
                                            >
                                                <span className="sr-only">{t('annonces.create.field_image_delete')}</span>
                                                <i className="icon-trash align-middle"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <h3 className="form-title">{t('annonces.create.details')} *</h3>
                                <div className="form-group m-b-30">
                                    <TextEditor
                                        initialValue={this.state.initialDetails}
                                        onChange={this.handleTextEditor}
                                        isSubmitted={this.state.isSubmitted}
                                        required
                                    />
                                </div>
                            </div>

                            <div>
                                <h3 className="form-title">{t('annonces.create.attachments')}</h3>
                                <FileUpload
                                    id="attachments"
                                    name="attachments"
                                    variant="dark"
                                    handle={this.attachedFilesUpload}
                                    maxSize={this._fileUploadMaxSize}
                                    disabled={this.state.annonce.attachments?.length >= this._maxFileNumber}
                                    types={this._fileAcceptedTypes}
                                    validation={(e: any) => validateFile(e, this._fileAcceptedTypes, this._fileUploadMaxSize, this._maxFileNumber)}
                                    maxFileNumber={this._maxFileNumber}
                                    initialData={this._annonceId ? this.state.initialAttachments : undefined}
                                />
                            </div>
                        </Panel>
                    </form>
                </div>

                <FileUploadModal
                    title={t('annonces.create.field_image_modal')}
                    info={
                        this.state.annonce.image ?
                            `Une seule image peut être associée par annonce, en importer une nouvelle remplacera la précédente`
                            : undefined
                    }
                    open={this.state.isImageUploadOpen}
                    handle={this.imageUpload}
                    types={this._imageAcceptedTypes}
                    maxSize={this._imageUploadMaxSize}
                    validation={(e: any) => validateFile(e, this._imageAcceptedTypes, this._imageUploadMaxSize)}
                    onClose={() => this.setState({isImageUploadOpen: false})}
                />

                <Modal open={this.state.deletingAnnonce}>
                    <div className="modal-body">
                        <p>{t('annonces.confirm_delete')}<b>{this.state.annonce?.titre}</b> ?</p>
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => this.setState({ deletingAnnonce: false })} className="btn btn-white">Annuler</button>
                        <button onClick={() => this.deleteAnnonce()} className="btn btn-secondary">Confirmer</button>
                    </div>
                </Modal>

                <AnnoncePreviewModal
                    isOpen={this.state.isPrevisuOpen}
                    onClose={(e: React.MouseEvent): void => this.setState({isPrevisuOpen: false})}
                    annonce={this.state.annonce}
                    annonceId={this._annonceId}
                />
            </DefaultLayout>
        );
    }
}

export default withTranslation()(withRouter(AnnonceCreate));
