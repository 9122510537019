import CustomComponent from "../../../components/CustomComponent";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import Modal from "../../../components/Modal";
import TABLE from "../../../components/Table";
import React from "react";
import Pagination from "../../../components/Pagination";
import { DebounceQueue } from "../../../helpers/debouncer";

class AddRegroupementModal extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: 'libelle',
        ordering: 'ASC',
        lib_court: '',
        libelle: '',
        entite_maitre: '',
    }

    constructor(props: any) {
        super(props);

        this.state = {
            filters: this.defaultFilters,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            openModal: false,
            regroupements: [],
            checkedRegroupements: [],
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    openModal = () => {
        this.setState({
            openModal: true,
            checkedRegroupements: [],
        }, this.getRegroupements);
    }

    getRegroupements = async (triggeredByPagination: boolean = false) => {
        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        });

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (val !== '') params.append(key, val);
        });

        params.append('exclude_ent_id', this.props.entityId);
        params.append('fl_actif', '1');

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/ent_regroupement?${params.toString()}`, 'GET');

        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                paginate: {
                    ...prev.paginate,
                    ...data.paginate,
                },
                regroupements: data.regroupements,
            }));
        }
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value -1),
            }
        }), () => this.queue.push(this.getRegroupements, [true], true));
    }

    handleSort = async (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC',
            }
        }), () => this.queue.push(this.getRegroupements, [], true));
    }

    addToRegroupements = async () => {
        const BODY = this.state.checkedRegroupements.map((regroupement: any) => ({
            id: regroupement.id,
            membres: [+this.props.entityId],
        }));

        const data = await this.request(`/ent_regroupement/addmember`, 'PATCH', BODY);

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState({openModal: false});

            const newCards = this.state.checkedRegroupements.map((rgp:any) => ({
                id: rgp.id,
                libelle: rgp.libelle,
                maitre: {
                    id: rgp.ent_id_maitre,
                    siret: rgp.siret,
                    nom_usuel: rgp.entite_maitre,
                }
            }));
            this.props.handle && this.props.handle(newCards);
        }
    }

    handleCheckAll = (e: any) => {
        const {checked} = e.target;
        const regroupementsId = this.state.regroupements.map((rgp: any) => rgp.id);
        const checkedRegroupementsId = this.state.checkedRegroupements.map((rgp: any) => rgp.id);

        const checkedRegroupements = checked
            ? [...this.state.checkedRegroupements, ...this.state.regroupements.filter((rgp: any) => !checkedRegroupementsId.includes(rgp.id))]
            : this.state.checkedRegroupements.filter((rgp: any) => !regroupementsId.includes(rgp.id));

        this.setState({checkedRegroupements: checkedRegroupements});
    }

    handleCheck = (e: any, regroupement: any) => {
        const {checked} = e.target;

        const checkedRegroupements = checked
            ? [...this.state.checkedRegroupements, regroupement]
            : this.state.checkedRegroupements.filter((rgp: any) => regroupement.id !== rgp.id);

        this.setState({checkedRegroupements: checkedRegroupements});
    }

    render() {
        return <>
            <button
                type="button"
                className="info-card info-card--button"
                onClick={this.openModal}
            >
                Ajouter<i className="icon-add-circle"/>
            </button>
            <Modal open={this.state.openModal} size="lg" customLayout>
                <div className="modal-header d-flex justify-content-between align-items-center flex-wrap m-b-15">
                    <h4 className="modal-title m-b-15">Associer à un ou plusieurs regroupement(s)</h4>
                    <div className="ml-auto m-b-15">
                        <button
                            type="button"
                            className="btn btn-white"
                            onClick={() => this.setState({openModal: false})}
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            disabled={this.state.checkedRegroupements.length < 1}
                            onClick={this.addToRegroupements}
                        >
                            Sélectionner
                        </button>
                    </div>
                </div>
                <div className="modal-body">
                    <TABLE variant="blue">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.CHECKBOX
                                    type="TH"
                                    checked={!this.state.regroupements.find((rgp: any) => !this.state.checkedRegroupements.map((rgp: any) => rgp.id).includes(rgp.id))}
                                    onChange={(e: any) => this.handleCheckAll(e)}
                                />
                                <TABLE.TH
                                    name="lib_court"
                                    input={{
                                        value: this.state.filters.lib_court,
                                        placeholder: 'Libellé court',
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('lib_court')
                                    }}
                                />
                                <TABLE.TH
                                    name="libelle"
                                    input={{
                                        value: this.state.filters.libelle,
                                        placeholder: 'Libellé',
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('libelle')
                                    }}
                                />
                                <TABLE.TH
                                    name="entite_maitre"
                                    input={{
                                        value: this.state.filters.entite_maitre,
                                        placeholder: 'Entité maître',
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getRegroupements))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('entite_maitre')
                                    }}
                                />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.regroupements.map((regroupement: any) => (
                                <TABLE.TR key={regroupement.id}>
                                    <TABLE.CHECKBOX
                                        type="TD"
                                        checked={this.state.checkedRegroupements.map((rgp: any) => rgp.id).includes(regroupement.id)}
                                        onChange={(e: any) => this.handleCheck(e, regroupement)}
                                    />
                                    <TABLE.TD label="Libellé court">{regroupement.lib_court}</TABLE.TD>
                                    <TABLE.TD label="Libellé">{regroupement.libelle}</TABLE.TD>
                                    <TABLE.TD label="Entité maître">{regroupement.siret} - {regroupement.entite_maitre}</TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>

                    </TABLE>
                    <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} secondary />
                </div>
            </Modal>
        </>
    }
}

export default withTranslation()(withRouter(AddRegroupementModal));