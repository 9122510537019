import luhn from 'luhn';
import React from 'react';
import { Dropdown } from "react-bootstrap";
import { confirm } from 'react-confirm-box';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { AuditFields } from '../../../components/AuditFields';
import BreadCrumb from '../../../components/BreadCrumb';
import CustomComponent from '../../../components/CustomComponent';
import { FormDatePicker, FormDropdownCheckbox, FormInput, FormInputSelect, FormInputSelectCreatable, FormInputWithSelect, FormRadioBoolean } from '../../../components/FormComponents';
import { DefaultLayout } from '../../../components/Layouts';
import Modal from '../../../components/Modal';
import Panel from '../../../components/Panel';
import { AccordionTree } from '../../../components/TogglableTree';
import { TOAST_TYPE, getSession, hasPermission, sendToast, strArrayToNumArray, truncateString } from '../../../helpers/helpers';
import { initValidator } from '../../../validations/validations';
import AddGroupeModal from "../_common/_AddGroupeModal";
import { DeleteModal } from './_deleteModal';
import PersonnesAssocieesPanel from './_personnesAssocieesPanel';
import RegroupementsAssociesPanel from "./_regroupementsAssociesPanel";

class CreateEntite extends CustomComponent {
    private _isMounted = false;
    private validator;

    constructor(props: any) {
        super(props);
        this.state = {
            entite: {
                fl_nic_valide: '1'
            },
            filiations: null,
            filiationToBeDeleted: null,
            addFiliationModalOpen: false,
            filationToAdd: '',
            filiationCandidates: [],
            code_commune: "",
            id_commune: null,
            pays: [],
            default_codes_pays: [],
            codes_pays: [],
            codes_pays_court: [],
            labos: [],
            default_ind_tel_pays: {},
            ind_tel_pays: [],
            types_entite: [],
            classes_entites: [],
            isSubmitted: false,
            general_panel: true,
            labo_panel: true,
            producteur_panel: true,
            isDisabledNicValide: false,
            isDisabledNic: false,
            isDisabledSiren: false,
            phoneMaxLength: 10,
            postalcodeMaxLength: 5,
            postalcodeFacMaxLength: 5,
            cities: [],
            prdCities: [],
            ville: "",
            ville_fac: "",
            code_entite: null,
            isRequiredSiren: true,
            nicIsChecked: true,
            sirenIsChecked: true,
            edeIsChecked: true,
            deleteTarget: null,
            isLoading: true,
            isSubmitting: false,
            groupes: [],
            removeFromGroup: null,
            isGroupeEditable: false,
            siretIsValid: true,
        }

        this.validator = initValidator();
    }

    async getData(url: string) {
        let result = [];
        const method = "GET";
        const data = await this.request(url, method);
        if (data) {
            result = data;
        }
        return result;
    }

    async verifyCityByLib(lib: string | null) {
        if (!lib) return null;
        const data = await this.request(`/ref_commune/findByExactLib/${lib}`, 'GET');
        if (data) return data;
        return null;
    }

    async getCitiesByLib(lib: any) {
        let cities_name: { value: string, label: string, code_insee_commune: string, id: number }[] = [];
        let cities = await this.getData('/ref_commune/findByLib/' + lib.data);
        cities.forEach((city: any) => {
            cities_name.push({ value: `${city.lib_commune} (${city.departement.code_departement})`, label: `${city.lib_commune} (${city.departement.code_departement})`, code_insee_commune: city.code_insee_commune, id: city?.id });
        });
        return cities_name;
    }

    async loadCitiesByLib(lib: any) {
        let cities_name: { value: string, label: string, code_insee_commune: string, id: number }[] = await this.getCitiesByLib(lib);
        this.setState((prevState: any) => ({
            entite: { ...prevState.entite },
            cities: Array.from(new Set(this.state.cities.concat(cities_name).map((e: any) => JSON.stringify(e)))).map((s: any) => JSON.parse(s)),
            prdCities: Array.from(new Set(this.state.prdCities.concat(cities_name).map((e: any) => JSON.stringify(e)))).map((s: any) => JSON.parse(s))
        }));
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.entityId) {
            document.title = `Infolabo | ${this.props.isVisualization ? 'Consultation' : 'Modification'} d'une entité`;
        } else {
            document.title = `Infolabo | Création d'une entité`;
        }
        this.getAllData()
    }

    async getAllData() {
        const data = await this.getData('/ent_entite/references')

        if (data) {
            const default_country = data.ref_pays.find((p: any) => p.lib_pays.toLowerCase() === "france");
            this.setState((prevState: any) => ({
                pays: data.ref_pays.map((p: any) => ({ label: p.lib_pays, value: p.id })),
                default_codes_pays: data.ref_pays.map((p: any) => ({ label: p.code_pays, value: p.id })),
                codes_pays: data.ref_pays.map((p: any) => ({ label: p.code_pays, value: p.id })),
                codes_pays_court: data.ref_pays.map((p: any) => ({ label: p.code_pays_alpha_2, value: p.id })),
                labos: data.laboratoires_gestion.map((l: any) => ({ label: `${l.siret} - ${l.nom_usuel}`, value: l.id })),
                default_ind_tel_pays: Object.assign({}, ...data.ref_pays.map((p: any) => ({ [p.id]: p.ind_tel_pays_2 ? [p.ind_tel_pays, p.ind_tel_pays_2] : [p.ind_tel_pays] }))),
                classes_entites: data.ref_classe_entites,
                types_entite: data.ref_type_entites.map((e: any) => ({ code_type: e.code_type, label: e.lib_type, value: e.id, comb_possible: e.fl_comb_possible, cla_id: e.cla_id, disabled_in_creation: e.disabled_in_creation })),
                ind_tel_pays: default_country.ind_tel_pays_2 ? [default_country.ind_tel_pays, default_country.ind_tel_pays_2] : [default_country.ind_tel_pays],
                entite: { ...prevState.entite, pay_id: default_country.id, pay_id_fac: default_country.id, ind_tel: default_country.ind_tel_pays, ind_tel_2: default_country.ind_tel_pays },
            }), () => {
                if (this.props.entityId) {
                    this._isMounted && this.loadEntity();
                } else {
                    this.setState({ isLoading: false });
                }
            });
        }
    }

    async getFiliations() {
        if (this.isFiliationsPanelVisible()) {
            this.request(`/ent_entite/filiations/${this.props.entityId}`, 'GET').then(data => {
                if (data && data.statusCode === 200) {
                    this._isMounted && this.setState({ filiations: data.filiations })
                }
            })

            if (hasPermission('DRT_ANNU_ENT_FILIATION_GERER')) this.getFilationCandidates()
        }
    }

    async getGroupes() {
        if (hasPermission('DRT_ANNU_GROUPE')) {
            this.request(`/ent_groupe/findByEntity/${this.props.entityId}`, 'GET').then(data => {
                if (data && data.statusCode === 200) {
                    this._isMounted && this.setState({ groupes: data.groupes })
                }
            })
        }
    }

    async getFilationCandidates(search?: string) {
        const session = getSession();
        if (+this.props.entityId === +this.getHabilitation()?.ent_id?.id || session?.hab_code === 'ADMININF') {
            let url = `/ent_entite/filiations/${this.props.entityId}/parent`
            if (search && typeof search === 'string') url += `?q=${search}`

            const data = await this.request(url, 'GET')
            if (data && data.entites) {
                this._isMounted && this.setState({
                    filiationCandidates: data.entites.map((ent: any) => ({
                        label: `${ent.siret}-${ent.nom_usuel}`,
                        value: ent.id
                    }))
                })
            }
        }
    }

    onFiliationOpenDeleteModal = (e: any, item: any) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({ filiationToBeDeleted: item })
    }

    onFiliationDeleteConfirm = async (e: any) => {
        e.preventDefault()
        const data = await this.request(`/ent_entite/filiations/${this.state.entite.id}/remove_parent`, 'DELETE')

        if (data && data.statusCode === 201) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            this.getFiliations()
            this._isMounted && this.setState({ filiationToBeDeleted: null })
        }
    }

    onFiliationAdd = async (e: any) => {
        e.preventDefault()
        const data = await this.request(`/ent_entite/filiations/${this.props.entityId}/parent`,
            'POST',
            { ent_id_mere: +this.state.filiationToAdd }
        )

        if (data && data.statusCode === 201) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            this.getFiliations()
            this._isMounted && this.setState({
                addFiliationModalOpen: false,
                filiationToAdd: '',
            })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async afterSubmission(event: React.FormEvent | null) {
        const { t } = this.props;
        this._isMounted && this.setState({ isSubmitting: true });
        event?.preventDefault();
        this.setState({ isSubmitted: true });
        const siren = this.state.entite?.siren?.trim() || '';

        if (!this.validator.allValid() || !this.state.siretIsValid) {
            this._isMounted && this.setState({ general_panel: true, producteur_panel: true, labo_panel: true });
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
            return
        }

        if (siren === '') {
            const isConfirmed = await confirm("Aucun n° SIREN n’ayant été renseigné, il va être généré par Infolabo.\nSouhaitez-vous poursuivre ?", {
                labels: { confirmable: "Continuer", cancellable: "Annuler" }
            })
            if (isConfirmed) this.createUpdateEntity()
        } else {
            this.createUpdateEntity();
        }
        this._isMounted && this.setState({ isSubmitting: false });
    }


    get_selected_types_entites(arr: any[] | null = null) {
        let entities = [];
        let types_entite = strArrayToNumArray(arr || this.state.entite?.types_entite || []);

        if (types_entite.length) {
            entities = this.state.types_entite.filter((obj: any) => obj.value === types_entite[0]);
        }

        return entities;
    }

    async loadEntity() {
        const entity = await this.getData('/ent_entite/' + this.props.entityId);

        let types_entite = entity.types_entite?.map((element: any) => (element.typ_id)) || [];
        let code_commune = entity.ent_producteur?.com_id?.code_insee_commune || '';
        let prdVille: any = null;
        if (!!code_commune) {
            prdVille = (await this.getCitiesByLib({ data: entity.ville })).find((e => e.code_insee_commune === code_commune && e.label.includes(entity.ville)));
        }

        let cities_name: any[] = [];
        let prd_cities_name: any[] = [];
        if (entity.ville) {
            if (!!prdVille) {
                prd_cities_name.push(prdVille);
                cities_name.push(prdVille);
            } else {
                prd_cities_name.push({ value: entity.ville, label: entity.ville });
                cities_name.push({ value: entity.ville, label: entity.ville });
            }
        }
        if (entity.ville && entity?.ville_fac !== entity?.ville) {
            cities_name.push({ value: entity.ville_fac, label: entity.ville_fac });
            prd_cities_name.push({ value: entity.ville_fac, label: entity.ville_fac });
        } else {
            entity.ville_fac = prdVille?.value || '';
        }

        entity['types_entite'] = types_entite;
        entity['ent_pay_id'] = entity.ent_pay_id?.id;
        entity['fl_nic_valide'] = entity.fl_nic_valide ? '1' : '0';
        entity['ent_producteur__com_id'] = entity.ent_producteur?.com_id?.id || '';
        entity['ent_producteur__lab_gestion_id'] = entity.ent_producteur?.lab_gestion_id || "";
        entity['ent_producteur__num_interne_labo'] = entity.ent_producteur?.num_interne_labo || "";
        entity['ent_producteur__code_pays_ede'] = entity.ent_producteur?.code_pays_ede || "";
        entity['ent_producteur__num_ede'] = entity.ent_producteur?.num_ede || "";
        entity['ent_laboratoire__num_agrement'] = entity.ent_laboratoire?.num_agrement || "";
        entity['ent_laboratoire__num_accredit_cofrac'] = entity.ent_laboratoire?.num_accredit_cofrac || "";
        entity['ent_laboratoire__id_sigal'] = entity.ent_laboratoire?.id_sigal || "";
        entity['ent_laboratoire__email_retour_flux'] = entity.ent_laboratoire?.email_retour_flux || "";
        entity['ent_laboratoire__email_retour_flux_annuaire'] = entity.ent_laboratoire?.email_retour_flux_annuaire || "";
        entity['ent_laboratoire__date_agrement'] = entity.ent_laboratoire?.date_agrement ? new Date(entity.ent_laboratoire?.date_agrement) : '';
        entity['ent_laboratoire__date_accredit_cofrac'] = entity.ent_laboratoire?.date_accredit_cofrac ? new Date(entity.ent_laboratoire?.date_accredit_cofrac) : '';
        entity['ent_laboratoire__acro_sigal'] = entity.ent_laboratoire?.acro_sigal || "";
        entity['ent_laboratoire__email_1'] = entity.ent_laboratoire?.email_1 || "";
        entity['ent_laboratoire__email_2'] = entity.ent_laboratoire?.email_2 || "";
        delete entity['ent_laboratoire'];
        delete entity['ent_producteur'];

        const auditFields = await this.request(`/ent_entite/auditFields/${this.props.entityId}`, 'GET')

        this._isMounted && this.setState((prevState: any) => ({
            ...prevState,
            entite: entity,
            auditFields: auditFields,
            code_commune: code_commune,
            cities: cities_name,
            prdCities: prd_cities_name,
            isGroupeEditable: entity.isGroupeEditable,
            isEntPerRelEditable: entity.isEntPerRelEditable,
            isLinkedPerConsultable: entity.isLinkedPerConsultable,
            isRegroupementShownInConsultation: entity.isRegroupementShownInConsultation,
            isRegroupementShownInEdition: entity.isRegroupementShownInEdition,
            ...(!!prdVille && { id_commune: prdVille?.id })
        }), () => {
            this.loadPrefixOptionsByCountry(entity.pay_id);
            this.updateSirenNicChecks()
            if (hasPermission('DRT_ANNU_GROUPE') && this.state.isGroupeEditable) this.getGroupes();
            this.getFiliations();
        });

        this.setState({ isLoading: false })
    }

    async createUpdateEntity(force_update: boolean = false) {
        const { entityId } = this.props;

        const { entite, cities, code_commune } = this.state;

        let url = entityId ? `/ent_entite/${entityId}` : `/ent_entite`
        let method = entityId ? 'PATCH' : "POST";

        const ent_producteur__com_id = cities.find((obj: any) => obj.code_insee_commune === code_commune)?.id || entite?.ent_producteur__com_id
        const selected_type_entites = this.get_selected_types_entites();

        const body = {
            ...entite,
            ville: entite?.ville?.lastIndexOf(" (") > 0 ? entite?.ville?.substr(0, entite?.ville?.lastIndexOf(" (")) : entite?.ville, // remove after last " (" if value containe code department
            ville_fac: entite?.ville_fac?.lastIndexOf(" (") > 0 ? entite?.ville_fac?.substr(0, entite?.ville_fac?.lastIndexOf(" (")) : entite?.ville_fac,
            types_entite: strArrayToNumArray(entite?.types_entite),
            cla_id: selected_type_entites.length > 0 ? selected_type_entites[0].cla_id : "",
            fl_nic_valide: entite?.fl_nic_valide === '0' ? false : true,
            ...(this.has_classe_entite("PRO") && { ent_producteur__com_id: ent_producteur__com_id }),
            pay_id_fac: entite?.pay_id_fac || null,
            ent_producteur__code_pays_ede: entite?.ent_producteur__code_pays_ede || null,
            code_pays: this.state.codes_pays.find((c: any) => c.value === entite.ent_pay_id).label,
            force_update
        }
        if (body.siren === '') delete body.siren

        const data = await this.request(url, method, body);

        if (data) {
            if (data.statusCode === 206) {
                const isConfirmed: boolean = await confirm(data.message, { labels: { confirmable: "Confirmer", cancellable: "Annuler" } })
                if (isConfirmed) this.createUpdateEntity(true)
            }

            if (data.statusCode === 201) {
                sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
                this.props.history.push(`/annuaire/entite/${data.id}`);
            }
        }
    }

    cla_id_to_code_classe = (cla_id: number): string => {
        return this.state.classes_entites.find((c: any) => c.id === cla_id)?.code_classe || "";
    }

    has_classe_entite(target_code_classe: string, types_entite: any[] | null = null) {
        let selected_types_entites = this.get_selected_types_entites(types_entite)
        let matches = selected_types_entites.filter((e: any) => {
            const code_classe = this.cla_id_to_code_classe(e.cla_id);
            return code_classe === target_code_classe;
        });

        return matches.length > 0;
    }

    renderButtons = () => {
        const { entityId, isVisualization } = this.props

        if (isVisualization) return <>
            <Link to={`/annuaire/entite`} className="btn m-r-5">Annuler</Link>
            {(hasPermission('DRT_ANNU_ENT_GERER') && this.state?.entite?.isEditable) && <button type="button" className="btn btn-danger m-r-5" onClick={() => this.setState({ deleteTarget: this.state.entite })}>Supprimer</button>}
            {(hasPermission('DRT_ANNU_ENT_GERER') && this.state?.entite?.isEditable) && <Link to={`/annuaire/entite/${entityId}/modifier`} className="btn btn-dark">Modifier</Link>}
        </>

        if (entityId) return <>
            <Link to={`/annuaire/entite/${entityId}`} className="btn m-r-5">Annuler</Link>
            {(hasPermission('DRT_ANNU_ENT_GERER') && this.state?.entite?.isEditable) && <button type="button" className="btn btn-danger m-r-5" onClick={() => this.setState({ deleteTarget: this.state.entite })}>Supprimer</button>}
            {(hasPermission('DRT_ANNU_ENT_GERER') && this.state?.entite?.isEditable) && <button type="submit" className='btn btn-secondary'>Enregistrer</button>}
        </>

        return <>
            <Link to={`/annuaire/entite`} className="btn m-r-5">Annuler</Link>
            <button type="submit" className='btn btn-secondary'>Enregistrer</button>
        </>
    }

    loadPrefixOptionsByCountry(country_id: number | null = null, isCountryFac: boolean = false) {
        const prefix_options: string[] = isCountryFac ? this.state.ind_tel_pays : [];
        let phoneMaxLength: number | null = this.state.phoneMaxLength;
        let postalcodeMaxLength: number | null = this.state.postalcodeMaxLength;
        let postalcodeFacMaxLength: number | null = this.state.postalcodeFacMaxLength;

        if (country_id) {
            const country = this.state.pays.find((p: any) => (p.value === country_id)).label;

            if (isCountryFac) {
                postalcodeFacMaxLength = (country === "FRANCE") ? 5 : null;
            } else {
                phoneMaxLength = ["FRANCE", "PRV", "TPR", "ZZZ", "INF"].includes(country) ? 10 : null
                postalcodeMaxLength = country === "FRANCE" ? 5 : null

                for (const prefix of this.state.default_ind_tel_pays[country_id]) {
                    prefix_options.push(prefix);
                }
            }
        } else {
            if (isCountryFac) {
                postalcodeFacMaxLength = null;
            } else {
                phoneMaxLength = null;
                postalcodeMaxLength = null;

                for (const [, value] of Object.entries(this.state.default_ind_tel_pays)) {
                    for (const prefix of (value as string[])) {
                        prefix_options.push(prefix);
                    }
                }
            }
        }

        let default_prefix = prefix_options.length > 0 ? prefix_options[0] : "";
        this.setState((prevState: any) => ({ ind_tel_pays: prefix_options, phoneMaxLength, postalcodeMaxLength, postalcodeFacMaxLength, entite: { ...prevState.entite, ind_tel: default_prefix, ind_tel_2: default_prefix } }));
    }

    handleTypeEntiteChange = async (e: any) => {
        let selectedEntities = this.get_selected_types_entites(e.data.value);
        const code_entite = selectedEntities.length > 0
            ? selectedEntities[0].code_type.trim()
            : null

        for (let element of selectedEntities) {
            let codes_pays = this.state.default_codes_pays;
            const code_classe = this.cla_id_to_code_classe(element.cla_id);

            if (code_classe === "PRO") {
                const isTPR = codes_pays.find((cp: any) => cp.value === this.state.entite?.ent_pay_id && cp.label.toLowerCase() === "tpr");
                codes_pays = codes_pays.filter((cp: any) => cp.label.toLowerCase() !== "tpr");
                this.setState((prevState: any) => ({
                    entite: {
                        ...prevState.entite,
                        ville: '',
                        ent_pay_id: isTPR ? "" : prevState.entite.ent_pay_id,
                    },
                    code_entite,
                    cities: [],
                    prdCities: [],
                    codes_pays,
                    isDisabledNicValide: isTPR ? false : prevState.isDisabledNicValide,
                }));
            }
            else {
                codes_pays = codes_pays.filter((cp: any) => cp.label.toLowerCase() !== "zzz" && cp.label.toLowerCase() !== "prv");
                const isPRVorZZZ = codes_pays.find((cp: any) => cp.value === this.state.entite?.ent_pay_id && (cp.label.toLowerCase() === "zzz" || cp.label.toLowerCase() === "prv"));
                this.setState((prevState: any) => ({
                    entite: {
                        ...prevState.entite,
                        ent_pay_id: isPRVorZZZ ? "" : prevState.entite.ent_pay_id
                    },
                    code_entite,
                    codes_pays,
                    isDisabledNicValide: isPRVorZZZ ? false : prevState.isDisabledNicValide
                }));
            }
        };
        if (selectedEntities.length === 0) {
            this.setState({ codes_pays: this.state.default_codes_pays });
        }
        this.handleInputChange(e, null, 'entite');
    }

    updateSirenNicChecks = (emptyFields?: boolean) => {
        const ent_pay_id = this.state.entite?.ent_pay_id
        if (!ent_pay_id) return

        let siren = this.state.entite?.siren;
        let nic = this.state.entite?.nic;
        let fl_nic_valide = this.state.entite.fl_nic_valide
        let isDisabledNicValide = this.state.isDisabledNicValide;
        let isDisabledNic = this.state.isDisabledNic;
        let isDisabledSiren = this.state.isDisabledSiren;
        let isRequiredSiren = this.state.isRequiredSiren;
        let sirenIsChecked = this.state.sirenIsChecked;
        let code_pays_str = this.state.codes_pays.find((p: any) => +p.value === +ent_pay_id).label;
        let isAdminInf = this.getHabilitation().hab_code.code_hab === "ADMININF";

        switch (code_pays_str) {
            case "FRA":
                isDisabledNicValide = false;
                isDisabledSiren = false;
                isDisabledNic = false;
                isRequiredSiren = true;
                sirenIsChecked = true;
                break;
            case "PRV":
            case "ZZZ":
                fl_nic_valide = '0';
                isDisabledNicValide = true;
                isDisabledSiren = true;
                isDisabledNic = true;
                isRequiredSiren = false;
                sirenIsChecked = false;
                siren = emptyFields ? '' : siren;
                nic = emptyFields ? '' : nic;
                break;
            case "TPR":
                fl_nic_valide = '0';
                isDisabledNicValide = !isAdminInf;
                isDisabledSiren = !isAdminInf;
                isDisabledNic = !isAdminInf || this.props.entityId || !siren;
                isRequiredSiren = false;
                sirenIsChecked = !isAdminInf;
                siren = !isAdminInf && !this.props.entityId ? '' : siren
                nic = (emptyFields && isAdminInf) ? '' : nic
                break;
            case "INF":
                fl_nic_valide = '0';
                isDisabledNic = false;
                isDisabledNicValide = false;
                isRequiredSiren = false;
                isDisabledSiren = false;
                sirenIsChecked = false;
                break;
            default:
                fl_nic_valide = '0';
                isDisabledNicValide = false;
                isDisabledSiren = false;
                isDisabledNic = !siren;
                isRequiredSiren = false;
                sirenIsChecked = false;
        }

        this.setState((prevState: any) => ({
            isDisabledNicValide,
            isDisabledNic,
            isDisabledSiren,
            isRequiredSiren,
            sirenIsChecked,
            entite: {
                ...prevState.entite,
                fl_nic_valide,
                siren,
                nic
            }
        }));
    }

    checkSIRET = () => {
        const should_be_validated = this.state.sirenIsChecked && this.state?.entite?.fl_nic_valide
        let siretIsValid = true

        if (should_be_validated) {
            const { siren, nic } = this.state.entite
            const sirenIsValid = siren ? luhn.validate(siren) : false
            const nicIsValid = nic ? nic.length === 5 && nic[0] === '0' : false

            if (this.state.sirenIsChecked && sirenIsValid && nicIsValid) {
                siretIsValid = luhn.validate(siren + nic)
            }
        }

        this._isMounted && this.setState({ siretIsValid })
    }

    getRenderData() {
        const { t, entityId, isVisualization } = this.props;
        const code_hab = this.getHabilitation()?.hab_code?.code_hab;
        const cla_id = this.getHabilitation()?.ent_id?.cla_id;

        let isProducteur = this.has_classe_entite("PRO");
        let isLabo = this.has_classe_entite("LAB");
        let isAdminLabo = code_hab === "ADMINLAB" || (code_hab === "GESTICON" && this.cla_id_to_code_classe(cla_id) === "LAB");

        let defaultLabo = this.getHabilitation()?.ent_id

        if (isProducteur && isAdminLabo && !this.state.entite?.ent_producteur__lab_gestion_id) {
            this.state.entite.ent_producteur__lab_gestion_id = defaultLabo?.id;
        }

        let afficheAjoutTypeEntite = (
            !entityId ||
            (this.state.types_entite.find((e: any) => this.state.entite?.types_entite?.[0] === e.value)?.comb_possible && !isVisualization)
        );

        let typesEntiteCompatible = (!entityId)
            ? this.state.types_entite
                .filter((e: any) => !e.disabled_in_creation)
                .sort((a: any, b: any) => (a.label > b.label) ? 1 : -1)
            : this.state.types_entite
                .filter((e: any) => e.comb_possible && !this.state.entite?.types_entite?.includes(e.value))
                .sort((a: any, b: any) => (a.label > b.label) ? 1 : -1)


        let entities_types_label = "";
        let label_arr = [];
        const types_entite = this.state.entite?.types_entite || [];
        if (types_entite.length > 0) {
            for (const id of types_entite) {
                label_arr.push(this.state.types_entite.find((obj: any) => obj.value === Number(id))?.label);
            }
            entities_types_label = label_arr.join(', ');
        }

        const displayProducteurPanel = isProducteur && (!entityId || this.state?.entite?.displayProdInformations)
        const displayLaboratoirePanel = isLabo && (!entityId || this.state?.entite?.displayFiliations)

        return { t, entityId, isVisualization, isProducteur, displayLaboratoirePanel, displayProducteurPanel, isAdminLabo, defaultLabo, afficheAjoutTypeEntite, typesEntiteCompatible, entities_types_label }
    }

    removeFromGroup = async () => {
        const groupe = this.state.removeFromGroup;

        this.setState({ removeFromGroup: null, isLoading: true });

        const BODY = { membre_entite: +this.props.entityId };
        const data = await this.request(`/ent_groupe/${groupe.id}/deletemember`, 'PATCH', BODY);

        if (data?.statusCode === 201) {
            this.setState((prev: any) => ({
                groupes: prev.groupes.filter((e: any) => e.id !== groupe.id),
                removeFromGroup: null,
                isLoading: false
            }));
        }
    }

    isRegroupementsPanelVisible = () => {
        if (this.props.entityId) {
            if (this.props.isVisualization) {
                if (hasPermission('DRT_ANNU_ENT_CONSULTER') && this.state.isRegroupementShownInConsultation) return true;
            } else {
                if (hasPermission('DRT_ANNU_ENT_REGROUP_CONSULTER') && this.state.isRegroupementShownInEdition) return true;
            }
        }
        return false;
    }

    isFiliationsPanelVisible = (): boolean => {
        if (this.props?.entityId) {
            if (!['LAI', 'LAB'].includes(this.state?.entite?.cla_id?.code_classe)) {
                return false;
            }
            if (this.props?.isVisualization) {
                // Consultation
                return (hasPermission('DRT_ANNU_ENT_CONSULTER') && this.state?.entite?.displayFiliations);
            }
            // Edition
            return (hasPermission('DRT_ANNU_ENT_FILIATION_CONSULTER') && this.state?.entite?.displayFiliations);
        }
        return false;
    }

    render() {
        // remove producteur & labo fields validator when entity_type select change
        // this.validator.purgeFields();
        const {
            t,
            entityId,
            isVisualization,
            isProducteur,
            displayLaboratoirePanel,
            displayProducteurPanel,
            isAdminLabo,
            defaultLabo,
            afficheAjoutTypeEntite,
            typesEntiteCompatible,
            entities_types_label
        } = this.getRenderData()

        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Annuaire', path: '/annuaire/entite' }, { name: (entityId ? isVisualization ? this.state.entite?.nom_usuel : "Modification d’une fiche entité" : "Création d’une fiche entité"), path: '' }]} />
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="d-flex justify-content-between flex-wrap">
                            <h1 className="main-title m-r-50">{entityId ? isVisualization ? this.state.entite?.nom_usuel : "Modification d’une fiche entité" : "Création d’une fiche entité"}</h1>
                            {afficheAjoutTypeEntite &&
                                <FormDropdownCheckbox
                                    label={entities_types_label}
                                    name="types_entite"
                                    options={typesEntiteCompatible}
                                    value={this.state.entite?.types_entite}
                                    handle={this.handleTypeEntiteChange}
                                    validator_type="array"
                                    validator_name="Type d'entité"
                                    required={true}
                                    //{... ['LABO', 'PRODU'].includes(this.state.code_entite) && ({confirmation: "Le changement de type d'entité entrainera la perte de données de saisies"})}
                                    simpleValidator={this.validator}
                                    isSubmitted={this.state.isSubmitted}
                                />
                            }
                            <div className="m-b-30">
                                {this.renderButtons()}
                            </div>
                        </div>
                        <Panel title="Informations générales" togglableID="panel-1" open={this.state.general_panel} handleChange={() => { this.setState({ general_panel: !this.state.general_panel }); }}>
                            <div className="large-gutters">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group form-group--list m-b-30">
                                            <div className="label">{t('entite.type')} *</div>
                                            <span className='form-list'>{entities_types_label}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputSelect
                                                options={this.state.codes_pays.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((country: any) => ({ value: country.value, label: country.label }))}
                                                label={t("entite.country_code")}
                                                name="ent_pay_id"
                                                id="ent_pay_id"
                                                simpleValidator={this.validator}
                                                validator_type="numeric"
                                                required={true}
                                                isClearable={true}
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite', () => this.updateSirenNicChecks(true))}
                                                value={this.state.entite?.ent_pay_id || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.siren')}
                                                id="siren"
                                                name="siren"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite', () => { this.checkSIRET(); this.updateSirenNicChecks() })}
                                                value={this.state.entite?.siren || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type={this.state.sirenIsChecked ? "numeric|siren" : "numeric"}
                                                minLength={9}
                                                maxLength={9}
                                                required={this.state.isRequiredSiren ? true : false}
                                                simpleValidator={this.validator}
                                                {...((isVisualization || this.state.isDisabledSiren) && { disabled: isVisualization | this.state.isDisabledSiren })}
                                                {...(!isVisualization && this.state.isDisabledSiren && { info: { text: "Le n° SIREN sera attribué lors de l'enregistrement de la fiche entité." } })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.nic')}
                                                id="nic"
                                                name="nic"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite', () => { this.checkSIRET(); this.updateSirenNicChecks() })}
                                                value={this.state.entite?.nic || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type={(this.state.isDisabledNicValide || this.state.entite?.fl_nic_valide === '0')
                                                    ? 'numeric'
                                                    : 'numeric|nic'
                                                }

                                                // validator_type={(!this.state.isDisabledNicValide || this.state?.entite?.fl_nic_valid === '1') ? "numeric|nic" : "numeric"}
                                                minLength={5}
                                                maxLength={5}
                                                required={this.state.isDisabledNic ? false : true}
                                                simpleValidator={this.validator}
                                                {...((isVisualization || this.state.isDisabledNic) && { disabled: isVisualization | this.state.isDisabledNic })}
                                                {...(!isVisualization && this.state.isDisabledNic && { info: { text: "Le n° NIC sera attribué lors de l'enregistrement de la fiche entité." } })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                info={{ text: "SIREN + NIC<br/>(Généré automatiquement)" }}
                                                label={t('entite.siret')}
                                                id="siret"
                                                name="siret"
                                                onChange={() => console.log('change')}
                                                error_message={!this.state.siretIsValid ? "Le siret est invalide" : null}
                                                value={(this.state.entite?.siren || '') + (this.state.entite?.nic || '')}
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormRadioBoolean
                                                label={t('entite.nicValide')}
                                                id="fl_nic_valide"
                                                name="fl_nic_valide"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.fl_nic_valide || "1"}
                                                isSubmitted={this.state.isSubmitted}
                                                disabled={isVisualization || this.state?.isDisabledNic}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.social_reason')}
                                                id="raison_soc"
                                                name="raison_soc"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.raison_soc || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={100}
                                                required={true}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.abbreviated_social_reason')}
                                                id="raison_soc_court"
                                                name="raison_soc_court"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.raison_soc_court || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={50}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.usual_name')}
                                                id="nom_usuel"
                                                name="nom_usuel"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.nom_usuel || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={70}
                                                required={true}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h3 className="form-title">Adresse</h3>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.address1')}
                                                id="adr_lig_1"
                                                name="adr_lig_1"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.adr_lig_1 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={38}
                                                required={true}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.address2')}
                                                id="adr_lig_2"
                                                name="adr_lig_2"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.adr_lig_2 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={38}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.address3')}
                                                id="adr_lig_3"
                                                name="adr_lig_3"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.adr_lig_3 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={38}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.address4')}
                                                id="adr_lig_4"
                                                name="adr_lig_4"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.adr_lig_4 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={38}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.postalcode')}
                                                id="code_postal"
                                                name="code_postal"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.code_postal || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={this.state.postalcodeMaxLength ? this.state.postalcodeMaxLength : 9}
                                                required={true}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            {isProducteur ?
                                                <FormInputSelect
                                                    options={this.state.prdCities.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((city: any) => ({ value: city.value, label: city.label, id: city?.id }))}
                                                    label={t("entite.city")}
                                                    name="ville"
                                                    id="ville"
                                                    displayValueNotLabel
                                                    handle={(e: any) => {
                                                        if (e.data.value) {
                                                            this.setState({
                                                                code_commune: this.state.cities.find((obj: any) => obj.value === e.data.value && obj.id === e.data.id)?.code_insee_commune,
                                                                id_commune: e.data?.id,
                                                            });
                                                        }
                                                        this.handleInputChange(e, null, 'entite');
                                                    }}
                                                    handleInput={(e: any) => this.loadCitiesByLib(e)}
                                                    value={this.state.entite?.ville || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    validator_type="string"
                                                    required={true}
                                                    maxLength={100}
                                                    simpleValidator={this.validator}
                                                    isClearable={true}
                                                    {...(isVisualization && { disabled: isVisualization })}
                                                    {...(this.state.id_commune && { selectedId: this.state.id_commune })}
                                                />
                                                :
                                                <FormInputSelectCreatable
                                                    optionsName="cities"
                                                    options={this.state.cities.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((city: any) => ({ value: city.value, label: city.label, id: city?.id }))}
                                                    label={t('entite.city')}
                                                    name="ville"
                                                    id="ville"
                                                    displayValueNotLabel
                                                    handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                    handleCreateOption={(e: any) => {
                                                        const createOption = (label: string) => ({
                                                            label,
                                                            value: label,
                                                        });
                                                        this.setState((prevState: any) => ({ cities: [...prevState.cities, createOption(e.data.value)] }));
                                                        this.handleInputChange(e, null, 'entite');
                                                    }}
                                                    handleInput={(e: any) => {
                                                        this.setState({ ville: e.data.value });
                                                        this.loadCitiesByLib(e);
                                                    }}
                                                    value={this.state.entite?.ville || ""}
                                                    isSubmitted={this.state.isSubmitted}
                                                    placeholder={this.state.cities.length < 1 ? "" : "Sélectionner"}
                                                    isDropdownDisabled={
                                                        (this.state.entite?.ville == null || this.state.entite?.ville === "") &&
                                                        this.state.cities.length < 1 &&
                                                        this.state.ville === ""
                                                    }
                                                    validator_type="string"
                                                    required={true}
                                                    maxLength={100}
                                                    simpleValidator={this.validator}
                                                    isClearable={true}
                                                    {...(isVisualization && { disabled: isVisualization })}
                                                />
                                            }

                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputSelect
                                                options={this.state.pays.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((country: any) => ({ value: country.value, label: country.label }))}
                                                label={t("entite.country")}
                                                name="pay_id"
                                                id="pay_id"
                                                handle={(e: any) => {
                                                    this.loadPrefixOptionsByCountry(e.data.value);
                                                    this.handleInputChange(e, null, 'entite');
                                                }}
                                                value={this.state.entite?.pay_id || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="numeric"
                                                required={true}
                                                simpleValidator={this.validator}
                                                isClearable={true}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h3 className="form-title">Contact</h3>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.phone')}
                                                id="num_tel"
                                                name="num_tel"
                                                type="tel"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.num_tel || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                isPhone={true}
                                                phoneHandle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                phoneName="ind_tel"
                                                phoneValue={this.state.entite?.ind_tel || ""}
                                                phonePlaceholder="+33"
                                                phoneOptions={this.state.ind_tel_pays.map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                                                maxLength={this.state.phoneMaxLength ? this.state.phoneMaxLength : 15}
                                                validator_type={this.state.phoneMaxLength ? "phone" : "numeric"}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.phone2')}
                                                id="num_tel_2"
                                                name="num_tel_2"
                                                type="tel"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.num_tel_2 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                isPhone={true}
                                                phoneHandle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                phoneName="ind_tel_2"
                                                phoneValue={this.state.entite?.ind_tel_2 || ""}
                                                phonePlaceholder="+33"
                                                phoneOptions={this.state.ind_tel_pays.map((prefix: any) => ({ value: prefix, label: "+" + prefix }))}
                                                maxLength={this.state.phoneMaxLength ? this.state.phoneMaxLength : 15}
                                                validator_type={this.state.phoneMaxLength ? "phone" : "numeric"}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline">
                                            <FormInput
                                                label={t('entite.email1')}
                                                id="mail_entite"
                                                name="mail_entite"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.mail_entite || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                maxLength={70}
                                                validator_type="email"
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h3 className="form-title">Adresse de facturation</h3>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.address1_fac')}
                                                id="adr_fac_lig_1"
                                                name="adr_fac_lig_1"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.adr_fac_lig_1 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={38}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.address2_fac')}
                                                id="adr_fac_lig_2"
                                                name="adr_fac_lig_2"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.adr_fac_lig_2 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={38}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.address3_fac')}
                                                id="adr_fac_lig_3"
                                                name="adr_fac_lig_3"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.adr_fac_lig_3 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={38}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.address4_fac')}
                                                id="adr_fac_lig_4"
                                                name="adr_fac_lig_4"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.adr_fac_lig_4 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={38}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.postalcode_fac')}
                                                id="code_postal_fac"
                                                name="code_postal_fac"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.code_postal_fac || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={this.state.postalcodeFacMaxLength ? this.state.postalcodeFacMaxLength : 9}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputSelectCreatable
                                                optionsName="cities"
                                                options={this.state.cities.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((city: any) => ({ value: city.value, label: city.label, id: city?.id }))}
                                                label={t('entite.city_fac')}
                                                name="ville_fac"
                                                id="ville_fac"
                                                displayValueNotLabel
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                handleCreateOption={(e: any) => {
                                                    const createOption = (label: string) => ({
                                                        label,
                                                        value: label,
                                                    });
                                                    this.setState((prevState: any) => ({ cities: [...prevState.cities, createOption(e.data.value)] }));
                                                    this.handleInputChange(e, null, 'entite');
                                                }}
                                                handleInput={(e: any) => {
                                                    this.setState({ ville: e.data.value });
                                                    this.loadCitiesByLib(e);
                                                }}
                                                value={this.state.entite?.ville_fac || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                placeholder={this.state.cities.length < 1 ? "" : "Sélectionner"}
                                                isDropdownDisabled={
                                                    (this.state.entite?.ville_fac == null || this.state.entite?.ville_fac === "") &&
                                                    this.state.cities.length < 1 &&
                                                    this.state.ville === ""
                                                }
                                                validator_type="string"
                                                maxLength={100}
                                                simpleValidator={this.validator}
                                                isClearable={true}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputSelect
                                                options={this.state.pays.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((country: any) => ({ value: country.value, label: country.label }))}
                                                label={t("entite.country_fac")}
                                                name="pay_id_fac"
                                                id="pay_id_fac"
                                                handle={(e: any) => {
                                                    this.loadPrefixOptionsByCountry(e.data.value, true);
                                                    this.handleInputChange(e, null, 'entite');
                                                }}
                                                value={this.state.entite?.pay_id_fac || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="numeric"
                                                simpleValidator={this.validator}
                                                isClearable={true}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        {displayProducteurPanel && <Panel title="Informations relatives au producteur" togglableID="panel-producteur" open={this.state.producteur_panel} handleChange={() => { this.setState({ producteur_panel: !this.state.producteur_panel }); }}>
                            <div className="large-gutters">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputSelect
                                                options={this.state.labos.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((labo: any) => ({ value: labo.value, label: labo.label }))}
                                                label={t("entite.labo_gestion")}
                                                name="ent_producteur__lab_gestion_id"
                                                id="ent_producteur__lab_gestion_id"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_producteur__lab_gestion_id || defaultLabo?.id}
                                                isSubmitted={this.state.isSubmitted}
                                                required={true}
                                                isClearable={true}
                                                validator_type="numeric"
                                                simpleValidator={this.validator}
                                                {...(isAdminLabo && this.state.entite?.ent_producteur__lab_gestion_id === defaultLabo?.id) && ({ confirmation: t('entite.modification_labo_responsable') })}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.num_labo')}
                                                id="ent_producteur__num_interne_labo"
                                                name="ent_producteur__num_interne_labo"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_producteur__num_interne_labo || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={20}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputWithSelect
                                                label={t('entite.cpNEDE')}
                                                id="ent_producteur__num_ede"
                                                name="ent_producteur__num_ede"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_producteur__num_ede || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                selectHandle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                selectName="ent_producteur__code_pays_ede"
                                                selectValue={this.state.entite?.ent_producteur__code_pays_ede || ""}
                                                selectPlaceholder="FR"
                                                selectOptions={this.state.codes_pays_court.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((country: any) => ({ value: country.label, label: country.label }))}
                                                validator_type={this.state.edeIsChecked ? "string|ede" : "string"}
                                                maxLength={8}
                                                minLength={8}
                                                style={{ large: true }}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <label htmlFor="type">{t('entite.code_commune')}</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" disabled value={this.state.code_commune || ""} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>}
                        {displayLaboratoirePanel && <Panel title="Informations relatives au laboratoire" togglableID="panel-labo" open={this.state.labo_panel} handleChange={() => { this.setState({ labo_panel: !this.state.labo_panel }); }}>
                            <div className="large-gutters">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.approval_cniel_num')}
                                                id="ent_laboratoire__num_agrement"
                                                name="ent_laboratoire__num_agrement"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__num_agrement || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={6}
                                                required={true}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.accreditation_cofrac')}
                                                id="ent_laboratoire__num_accredit_cofrac"
                                                name="ent_laboratoire__num_accredit_cofrac"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__num_accredit_cofrac || ''}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={6}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.sigal_id')}
                                                id="ent_laboratoire__id_sigal"
                                                name="ent_laboratoire__id_sigal"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__id_sigal || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={25}
                                                required={true}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.flux_email')}
                                                id="ent_laboratoire__email_retour_flux"
                                                name="ent_laboratoire__email_retour_flux"
                                                type="email"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__email_retour_flux || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="email"
                                                maxLength={70}
                                                simpleValidator={this.validator}
                                                required
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.flux_email_annuaire')}
                                                id="ent_laboratoire__email_retour_flux_annuaire"
                                                name="ent_laboratoire__email_retour_flux_annuaire"
                                                type="email"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__email_retour_flux_annuaire || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="email"
                                                maxLength={70}
                                                simpleValidator={this.validator}
                                                required
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormDatePicker
                                                label={t('entite.approval_cniel_date')}
                                                id="ent_laboratoire__date_agrement"
                                                name="ent_laboratoire__date_agrement"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__date_agrement || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormDatePicker
                                                label={t('entite.accreditation_cofrac_date')}
                                                id="ent_laboratoire__date_accredit_cofrac"
                                                name="ent_laboratoire__date_accredit_cofrac"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__date_accredit_cofrac || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.sigal_acronyme')}
                                                id="ent_laboratoire__acro_sigal"
                                                name="ent_laboratoire__acro_sigal"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__acro_sigal || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                validator_type="string"
                                                maxLength={20}
                                                required={true}
                                                simpleValidator={this.validator}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <h3 className="form-title">Contact du laboratoire</h3>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.labo_email1')}
                                                id="ent_laboratoire__email_1"
                                                name="ent_laboratoire__email_1"
                                                type="text"
                                                validator_type="email"
                                                required={true}
                                                simpleValidator={this.validator}
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__email_1 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                label={t('entite.labo_email2')}
                                                id="ent_laboratoire__email_2"
                                                name="ent_laboratoire__email_2"
                                                type="text"
                                                validator_type="email"
                                                simpleValidator={this.validator}
                                                handle={(e: any) => this.handleInputChange(e, null, 'entite')}
                                                value={this.state.entite?.ent_laboratoire__email_2 || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                {...(isVisualization && { disabled: isVisualization })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>}

                        {this.isFiliationsPanelVisible() && (
                            <Panel title="Relations de filiation" togglableID='panel-filiations' open={true}>
                                {this.state.filiations && (
                                    <AccordionTree
                                        onDelete={hasPermission('DRT_ANNU_ENT_FILIATION_GERER') && !this.props.isVisualization ? this.onFiliationOpenDeleteModal : null}
                                        data={[this.state.filiations]}
                                        codes_pays={this.state.codes_pays} />
                                )}

                                {hasPermission('DRT_ANNU_ENT_FILIATION_GERER') && !this.props.isVisualization && this.state.filiationCandidates && this.state.filiationCandidates.length > 0 && (
                                    <button
                                        onClick={() => this.setState({ addFiliationModalOpen: true })}
                                        type="button"
                                        className='info-card info-card--button'>Ajouter<i className="icon-add-circle"></i></button>
                                )}
                            </Panel>
                        )}

                        {/* <Panel title="Entités et fonctions associées" togglableID="panel-3">
                            <div className="row">
                                <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30">
                                    <button type="button" className="info-card info-card--button" data-toggle="modal" data-target="#entity-modal">Ajouter<i className="icon-add-circle"></i></button>
                                </div>
                            </div>
                        </Panel> */}

                        {this.isRegroupementsPanelVisible() && (
                            <RegroupementsAssociesPanel
                                isVisualization={this.props.isVisualization}
                                isRegroupementConsultable={this.state.isRegroupementConsultable}
                                entityId={this.props.entityId}
                            />
                        )}

                        {(hasPermission('DRT_ANNU_GROUPE') && this.state.isGroupeEditable && this.props.entityId) && (
                            <Panel title="Groupes associés" togglableID="panel-4" open={true}>
                                <div className="row">
                                    {(this.props.isVisualization && !this.state.groupes?.length) && (
                                        <div className="d-flex w-100 justify-content-center">
                                            <span>Cette entité n’est associée à aucun groupe de votre périmètre</span>
                                        </div>
                                    )}
                                    {this.state.groupes?.map((groupe: any) => (
                                        <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30" key={groupe.id}>
                                            <div className="info-card">
                                                <h4 className="info-card__title"><Link target="_blank" to={`/annuaire/groupe/${groupe.id}`}>{groupe.nom}</Link></h4>
                                                {`${groupe.proprietaire.siret} - ${groupe.proprietaire.nom_usuel}`}
                                                <dl className="info-card__info">
                                                    <dt>Type de groupe :</dt>
                                                    <dd>Entité</dd>
                                                    <dt>Description :</dt>
                                                    <dd>{truncateString(groupe.description, 40, '...')}</dd>
                                                </dl>
                                                {(!this.props.isVisualization && hasPermission('DRT_ANNU_GROUPE') && this.state.isGroupeEditable) && (
                                                    <Dropdown>
                                                        <Dropdown.Toggle bsPrefix="btn-icon" childBsPrefix="btn-icon--primary ml-auto">
                                                            <i className="icon-actions"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Link to={`/annuaire/groupe/${groupe.id}`} target="_blank" className="dropdown-item">Consulter la fiche groupe</Link>
                                                            <Dropdown.Item onClick={() => this.setState({ removeFromGroup: groupe })}>Supprimer l'entité du groupe</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    {!this.props.isVisualization && this.state.isGroupeEditable && (
                                        <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30">
                                            <AddGroupeModal
                                                open={this.state.isAddToGroupOpen}
                                                groupes={this.state.groupes.map((groupe: any) => groupe.id)}
                                                membreId={this.props.entityId}
                                                groupeType="E"
                                                handleOpen={(bool: any) => this.setState({ isAddToGroupOpen: bool })}
                                                handle={(e: any) => this.setState((prevState: any) => ({ groupes: prevState.groupes.concat(e) }))}
                                            />
                                        </div>
                                    )}
                                </div>
                            </Panel>
                        )}
                        {(this.props.entityId && hasPermission('DRT_ANNU_ENT_PER_CONSULTER')) && (
                            <PersonnesAssocieesPanel
                                createProps={{
                                    entite: this.state.entite,
                                    validator: this.validator,
                                    ind_tel_pays: this.state.ind_tel_pays,
                                    phoneMaxLength: this.state.phoneMaxLength,
                                    postalcodeMaxLength: this.state.postalcodeMaxLength
                                }}
                                entite={this.state.entite}
                                isVisualization={this.props.isVisualization}
                                entityId={this.props.entityId}
                                isEntPerRelEditable={this.state.isEntPerRelEditable}
                                isLinkedPerConsultable={this.state.isLinkedPerConsultable}
                                isProducteur={isProducteur}
                            />
                        )}
                    </form>

                    <DeleteModal
                        entite={this.state.deleteTarget}
                        onDelete={() => this.props.history.push('/annuaire/entite')}
                        onClose={() => this.setState({ deleteTarget: null })} />

                    {hasPermission('DRT_ANNU_ENT_FILIATION_GERER') && (<>
                        <Modal title="Suppression de la relation de filiation" open={this.state.filiationToBeDeleted}>
                            <div className="modal-body">
                                {this.state.entite && this.state.filiationToBeDeleted && (
                                    <p>{`Vous souhaitez supprimer la relation de filiation entre ${this.state.filiationToBeDeleted.raison_soc} et ${this.state.filiationToBeDeleted.parent.raison_soc}.`}</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => this.setState({ filiationToBeDeleted: null })} className="btn btn-white">Annuler</button>
                                <button type="button" onClick={this.onFiliationDeleteConfirm} className="btn btn-secondary">Confirmer</button>
                            </div>
                        </Modal>
                        <Modal title="Demande de création de relation mère-fille" open={this.state.addFiliationModalOpen} size="md">
                            <div className="row text-left">
                                <div className="col-md-8 offset-md-2 m-t-30">
                                    <div className="modal-body">
                                        <div className="form-group form-group--white">
                                            <FormInputSelect
                                                options={this.state.filiationCandidates}
                                                label={"Choisir une entité mère"}
                                                name="filiationToAdd"
                                                id="filiationToAdd"
                                                handleInput={(e: any) => (e?.action?.action === "input-change") && this.getFilationCandidates(e.data)}
                                                handle={(e: any) => this.setState({ filiationToAdd: e.data.value })}
                                                value={this.state.filiationToAdd}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-end">
                                        <button type="button" onClick={() => this.setState({ addFiliationModalOpen: false })} className="btn btn-white">Annuler</button>
                                        <button type="button" onClick={this.onFiliationAdd} className="btn btn-secondary">Ajouter</button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <Modal open={!!this.state.removeFromGroup}>
                            <div className="moda-body">
                                <p>Vous souhaitez supprimer l’association entre la fiche entité <b>{this.state.entite?.nom_usuel}</b> et le groupe <b>{this.state.removeFromGroup?.nom}</b>. Confirmez-vous cette suppression ?</p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={() => this.setState({ removeFromGroup: null })} className="btn btn-white">Annuler</button>
                                <button onClick={() => this.removeFromGroup()} className="btn btn-secondary">Confirmer</button>
                            </div>
                        </Modal>
                    </>)}
                </div>
                {this.state.auditFields && (<AuditFields audit={this.state.auditFields} />)}
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(CreateEntite));