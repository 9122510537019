import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { SortbyColumnButton } from '../../../components/TableComponents';
import Pagination from "../../../components/Pagination";
import Modal from "../../../components/Modal";
import { DebounceQueue } from "../../../helpers/debouncer";

class AddGroupeModal extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue()

    private defaultFilters = {
        orderBy: 'grp_nom',
        ordering: 'ASC',
        grp_nom: '',
        grp_description: '',
        grp_proprietaire: '',
    }

    constructor(props: any) {
        super(props);
        this.state = {
            filters: this.defaultFilters,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            groupes: [],
            selectedGroupes: [],
            checked: [],
            submitted: false,
            openModal: false
        }
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== this.state.filters.orderBy || this.state.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getGroupes, [], true));
    }

    getGroupes = async (triggeredByPagination: boolean = false) => {
        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        });

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (val !== '') params.append(key, val);
        });

        params.append('grp_type', this.props.groupeType);
        params.append('grp_exclude', this.props.groupes);

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/ent_groupe?${params.toString()}`, 'GET');

        if (this._isMounted && data?.statusCode === 200) {
            this.setState((prev: any) => ({
                groupes: data.groupes,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }))
        }
    }

    addToGroup = async () => {
        let membres_key: string = this.props.groupeType === 'P'
            ? 'membre_personne'
            : 'membre_entite';

        const BODY = [...this.state.selectedGroupes.map((grp: any) => {
            return ({
                id: grp.id,
                [membres_key]: +this.props.membreId
            });
        })];

        const data = await this.request(`/ent_groupe/addmember`, 'PATCH', BODY);

        if (data?.statusCode === 200) {
            this.props.handle(this.state.selectedGroupes);
            this.setState({ openModal: false });
        }
    }

    handleCheck = (event: any) => {
        const { checked, value } = event.target;
        const checkedGroupes = checked
            ? [...this.state.checked, +value]
            : this.state.checked.filter((n: any) => n !== +value);

        this.setState((prev: any) => ({
            checked: checkedGroupes,
            selectedGroupes: checked
                ? [...prev.selectedGroupes, ...prev.groupes.filter((groupe: any) => groupe.id === +value)]
                : prev.selectedGroupes.filter((groupe: any) => checkedGroupes.includes(groupe.id))
        }));
    }

    handleCheckAll = (e: any) => {
        const { checked } = e.target;
        const checkedGroupes = checked
            ? [...this.state.checked, ...this.state.groupes.filter((e: any) => !this.state.checked.includes(e.id)).map((groupe: any) => groupe.id)]
            : [...this.state.checked.filter((n: any) => !this.state.groupes.map((groupe: any) => groupe.id).includes(n))];

        this.setState((prev: any) => ({
            checked: checkedGroupes,
            selectedGroupes: checked
                ? [...prev.selectedGroupes, ...prev.groupes.filter((groupe: any) => checkedGroupes.includes(groupe.id))]
                : prev.selectedGroupes.filter((groupe: any) => checkedGroupes.includes(groupe.id))
        }));
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getGroupes, [true], true));
    }

    openModal = () => {
        this.setState({
            openModal: true,
            filters: this.defaultFilters,
            selectedGroupes: [],
            checked: [],
        }, this.getGroupes)
    }

    isAllChecked = () => {
        const visibleGroupeIDs = this.state.groupes.map((e: any) => e.id);
        const checkedIDs = this.state.checked;
        return visibleGroupeIDs.filter((e: any) => checkedIDs.includes(e)).length === visibleGroupeIDs.length;
    }

    render() {
        return <>
            <button
                type="button"
                className="info-card info-card--button"
                onClick={this.openModal}
            >
                Ajouter<i className="icon-add-circle"></i>
            </button>
            <Modal open={this.state.openModal} size="lg">
                <div className="modal-header d-flex justify-content-between align-items-center flex-wrap m-b-15">
                    <h4 className="modal-title m-b-15">Associer un ou plusieurs groupe(s)</h4>
                    <div className="ml-auto m-b-15">
                        <button
                            type="button"
                            className="btn btn-white"
                            onClick={() => this.setState({ openModal: false })}
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            disabled={this.state.selectedGroupes.length < 1}
                            onClick={this.addToGroup}
                        >
                            Sélectionner
                        </button>
                    </div>
                </div>
                <div className="modal-body">
                    <div className="table-responsive">
                        <table className="table table-custom table-blue">
                            <thead>
                                <tr>
                                    <th className="table-check">
                                        <input className="checkbox checkbox--white" type="checkbox" checked={this.isAllChecked()} onChange={this.handleCheckAll} />
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Nom du groupe</span>
                                            <input
                                                value={this.state.filters.grp_nom}
                                                name="grp_nom"
                                                type="text"
                                                className="table-input"
                                                placeholder="Nom du groupe"
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))} />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'grp_nom'} direction={this.state.filters.ordering} onClick={() => this.handleSort('grp_nom')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Description</span>
                                            <input
                                                value={this.state.filters.grp_description}
                                                name="grp_description"
                                                type="text"
                                                className="table-input"
                                                placeholder="Description"
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))} />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'grp_description'} direction={this.state.filters.ordering} onClick={() => this.handleSort('grp_description')} />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <div className="table-label">Type de groupe</div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="d-flex">
                                            <span className="sr-only">Propriétaire du groupe</span>
                                            <input
                                                value={this.state.grp_proprietaire}
                                                name="grp_proprietaire"
                                                type="text"
                                                className="table-input"
                                                placeholder="Propriétaire du groupe"
                                                onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))} />
                                            <SortbyColumnButton active={this.state.filters.orderBy === 'grp_proprietaire'} direction={this.state.filters.ordering} onClick={() => this.handleSort('grp_proprietaire')} />
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.groupes.map((groupe: any, index: number) => (
                                    <tr key={groupe.id}>
                                        <td className="table-check">
                                            <input className="checkbox" type="checkbox" checked={this.state.checked.includes(groupe.id)} value={groupe.id} id={`groupe${groupe.id}}`} onChange={this.handleCheck} />
                                        </td>
                                        <td>{groupe.nom}</td>
                                        <td>{groupe.description}</td>
                                        <td>
                                            {this.props.groupeType === 'P' && 'Personne'}
                                            {this.props.groupeType === 'E' && 'Entité'}
                                        </td>
                                        <td>{`${groupe.proprietaire.siret} - ${groupe.proprietaire.nom_usuel}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} secondary />
            </Modal>
        </>
    }
}

export default withTranslation()(withRouter(AddGroupeModal));
