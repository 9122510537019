import Accueil from './Accueil';
import Imports from "./Imports";
import Exports from "./Exports";
import Api from "./Api";

const ImportExportAnnuaire = {
    Accueil,
    Imports,
    Exports,
    Api
}

export default ImportExportAnnuaire;