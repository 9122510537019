import {useState} from "react";
import lightPdfIcon from '../../assets/images/icons/media_pdf_light.svg';
import darkPdfIcon from '../../assets/images/icons/media_pdf_dark.svg';
import lightVideoIcon from '../../assets/images/icons/media_video_light.svg';
import darkVideoIcon from '../../assets/images/icons/media_video_dark.svg';
import {onExportFile} from "../../helpers/helpers";

interface AideCardProps {
    media: {
        id: number
        fichier: string
        media: string
        texte_lien: string
    }
    context: any
}

export const AideCard = ({media, context}: AideCardProps) => {
    const isPDF = media.media === 'PDF';
    const [iconSrc, setIconSrc] = useState(isPDF ? darkPdfIcon : darkVideoIcon);

    const getFile = async () => {
        const url = `/inf_aide/${media.id}`;
        await onExportFile(context, media.fichier, url, 'GET');
    }

    return (
        <button
            className="aide-card"
            onMouseEnter={() => setIconSrc(isPDF ? lightPdfIcon : lightVideoIcon)}
            onMouseLeave={() => setIconSrc(isPDF ? darkPdfIcon : darkVideoIcon)}
            onClick={getFile}
        >
            <img className="aide-card__icon" src={iconSrc} alt="" />
            <span>{media.texte_lien}</span>
            <div className="btn btn-success">Consulter {isPDF ? 'le support' : 'la vidéo'}</div>
        </button>
    )
}