export enum ETATS_FLUX_REF {
    OK = 'OK',
    EC = 'EC',
    KO = 'KO',
}

export const ETATS_FLUX_REF_LABEL: {[key: string]: string} = {
    [ETATS_FLUX_REF.OK]: 'OK',
    [ETATS_FLUX_REF.EC]: 'En cours',
    [ETATS_FLUX_REF.KO]: 'Erreur',
}

export const ETATS_FLUX_REF_OPTIONS = Object.entries(ETATS_FLUX_REF_LABEL).map(([value, label]) => ({
    value, label
}))