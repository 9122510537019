import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { FormInputSelect } from "../../../components/FormComponents";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import { SortbyColumnButton } from "../../../components/TableComponents";
import { TYPES_DOMAINES } from "../../../enum/types_domaines";
import { DebounceQueue } from "../../../helpers/debouncer";
import { TOAST_TYPE, labelizePersonneEntites, sendToast } from "../../../helpers/helpers";
import { initValidator } from "../../../validations/validations";

class AssociatePersonnesToEntites extends CustomComponent {
    private _isMounted = false
    private queue = new DebounceQueue();

    private readonly validator;
    private default_filters = {
        orderBy: 'nom_prenom',
        ordering: 'ASC',
        nom_prenom: '',
        ville: '',
    };

    constructor(props: any) {
        super(props)

        this.state = {
            personnes: [],
            filters: { ...this.default_filters, exclude: this.props.exclude || [] },
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            step: 1,
            selectedPersonnes: [],
            checked: [],
            refHabilitations: null,
            habFonc: {},
            fonctions: this.props.fonctions || [],
        }

        this.validator = initValidator();
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentDidUpdate(prevProps: Readonly<any>) {
        if (prevProps.exclude?.length !== this.props.exclude?.length) {
            this.setState((prev: any) => ({
                filters: {
                    ...prev.filters,
                    exclude: this.props.exclude
                }
            }));
        }
        if (prevProps.fonctions?.length !== this.props.fonctions?.length) {
            this.setState({ fonctions: this.props.fonctions });
        }
        if (prevProps.refHabilitations?.length !== this.props.refHabilitations?.length) {
            this.setState({ refHabilitations: this.props.refHabilitations });
        }
    }

    componentWillUnmount(): void {
        this._isMounted = false
    }

    openModal = () => {
        this.setState({
            openModal: true,
            checked: [],
            selectedPersonnes: [],
            habFonc: {}
        }, this.getPersonnes)
    }

    getPersonnes = async (triggeredByPagination: boolean = false) => {
        const body: any = {};

        Object.keys(this.state.paginate).forEach((key => {
            const val = this.state.paginate[key];
            if (val !== '') body[key] = val;
        }));

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (key === 'orderBy' && val === 'nom_prenom') {
                body[key] = 'nom';
            } else if (val !== '') {
                body[key] = val;
            }
        });

        body.domain = TYPES_DOMAINES.INTERNE;
        body.exclude_entite = this.props.entite.id;

        if (!triggeredByPagination) body.page = '0';

        const data = await this.request(`/per_personne/filter`, 'POST', body);

        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                personnes: data.per_personnes,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }));
        }
    }

    onClickStep2 = async () => {
        if (!!this.state.selectedPersonnes?.length) {
            const BODY = { ids: [this.props.entite.id] };
            const data = await this.request(`/ref_habilitation/findByClaID`, 'POST', BODY);
            if (data && this._isMounted) {
                this.setState({
                    refHabilitations: data,
                    step: 2
                });
            }
        }
    }

    isAllChecked = () => {
        const visibleEntiteIDs = this.state.personnes.map((per: any) => per.id)
        const checkedIDs = this.state.checked
        return visibleEntiteIDs.filter((e: any) => checkedIDs.includes(e)).length === visibleEntiteIDs.length
    }

    handleCheckAll = (e: any) => {
        const { checked } = e.target
        const checkedPersonnes = checked
            ? [...this.state.checked, ...this.state.personnes.map((per: any) => per.id)]
            : [...this.state.checked.filter((n: any) => !this.state.personnes.map((per: any) => per.id).includes(n))]

        this.setState((prev: any) => ({
            checked: checkedPersonnes,
            selectedPersonnes: checked
                ? [...prev.selectedPersonnes, ...prev.personnes.filter((per: any) => checkedPersonnes.includes(per.id))]
                : prev.selectedPersonnes.filter((per: any) => checkedPersonnes.includes(per.id))
        }))
    }

    handleCheck = (event: any) => {
        const { checked, value } = event.target;
        const checkedPersonnes = checked
            ? [...this.state.checked, +value]
            : this.state.checked.filter((n: any) => n !== +value);

        this.setState((prev: any) => ({
            checked: checkedPersonnes,
            selectedPersonnes: checked
                ? [...prev.selectedPersonnes, ...prev.personnes.filter((per: any) => per.id === +value)]
                : prev.selectedPersonnes.filter((per: any) => checkedPersonnes.includes(per.id))
        }))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getPersonnes, [true], true))
    }

    handleSort = (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== this.state.filters.orderBy || this.state.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getPersonnes, [], true))

    }

    deletePersonne = (id: number) => {
        this.setState((prev: any) => ({
            checked: prev.checked.filter((val: any) => val !== id),
            step: prev.checked.length === 1 ? 1 : prev.step,
            selectedPersonnes: prev.selectedPersonnes.filter((per: any) => per.id !== id)
        }));
    }

    associatePersonnes = async () => {
        const { t } = this.props;

        if (this.validator.allValid()) {
            const BODY = {
                ent_id: this.props.entite.id,
                pers_ent_link: this.state.selectedPersonnes.map((per: any) => ({
                    per_id: per.id,
                    hab_code: this.state.habFonc[per.id]?.habilitation,
                    fon_id: this.state.habFonc[per.id]?.fonction
                }))
            }
            const data = await this.request(`/per_habilitation/createPersonneEntLink`, 'POST', BODY);
            if (data?.statusCode === 201 && this._isMounted) {
                this.props.history.go(0);
            }
        } else {
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
            this.validator.showMessages();
        }

    }

    render() {
        return (<>
            <button type="button" className="info-card info-card--button" onClick={this.openModal}>Ajouter<i className="icon-add-circle"></i></button>
            <Modal open={this.state.openModal} customLayout size="lg">
                <Modal.Header>
                    <div className="modal-step">
                        <h2 className="modal-step__title">Associer une ou plusieurs personne(s)</h2>
                        <div className="modal-step__container">
                            <ol className="modal-step__steps">
                                {this.state.step === 1 ? (
                                    <li className="active">Sélectionner les personnes à associer</li>
                                ) : (
                                    <li><button type="button" onClick={() => this.setState({ step: 1 })}>Sélectionner les personnes à associer</button></li>
                                )}
                                <li className={this.state.step === 2 ? 'active' : ''}>Définir l’habilitation et la fonction</li>
                            </ol>
                            <div className="modal-step__actions">
                                {this.state.step === 1 && (
                                    <>
                                        <button
                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-white"
                                            onClick={() => this.setState({ openModal: false })}>Annuler</button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            id="btn_next"
                                            disabled={this.state.selectedPersonnes.length < 1}
                                            onClick={this.onClickStep2}>Suivant</button>
                                    </>
                                )}
                                {this.state.step === 2 && (
                                    <>
                                        <button
                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-white" onClick={() => this.setState({ step: 1 })}>Annuler</button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            id="btn_next"
                                            onClick={this.associatePersonnes}>Associer</button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {this.state.step === 1 && (
                        <>
                            <div className="table-responsive desktop-only">
                                <table className="table table-custom table-blue">
                                    <thead>
                                        <tr>
                                            <th className="table-check">
                                                <input className="checkbox checkbox--white" type="checkbox" checked={this.isAllChecked()} onChange={this.handleCheckAll} />
                                            </th>
                                            <th>
                                                <div className="d-flex">
                                                    <span className="sr-only">Nom et prénom</span>
                                                    <input
                                                        value={this.state.filters.nom_prenom}
                                                        name="nom_prenom"
                                                        type="text"
                                                        className="table-input"
                                                        placeholder='Nom et prénom'
                                                        onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))} />
                                                    <SortbyColumnButton active={this.state.filters.orderBy === 'nom_prenom'} direction={this.state.filters.ordering} onClick={() => this.handleSort('nom_prenom')} />
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex">
                                                    <span className="table-label">Entité(s) de rattachement</span>
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex">
                                                    <span className="sr-only">Ville</span>
                                                    <input
                                                        value={this.state.filters.ville}
                                                        name="ville"
                                                        type="text"
                                                        className="table-input"
                                                        placeholder='Ville'
                                                        onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getPersonnes))} />
                                                    <SortbyColumnButton active={this.state.filters.orderBy === 'ville'} direction={this.state.filters.ordering} onClick={() => this.handleSort('ville')} />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.personnes?.map((per: any) => (
                                            <tr key={per.id}>
                                                <td className="table-check">
                                                    <input className="checkbox" type="checkbox" checked={this.state.checked?.includes(per.id)} value={per.id} id={`personne${per.id}}`} onChange={this.handleCheck} />
                                                </td>
                                                <td>{per.nom} {per.prenom}</td>
                                                <td>{labelizePersonneEntites(per.entites)}</td>
                                                <td>{per.ville}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} secondary />
                        </>
                    )}
                    {this.state.step === 2 && this.state.selectedPersonnes.map((per: any) => (
                        <div className="relation-card" key={per.id}>
                            <div className="relation-card__content">
                                <div className="row">
                                    <div className="col-md-2">
                                        <h4 className="label">{per.nom} {per.prenom}</h4>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor={`habilitation${per.id}`}>Habilitation*</label>
                                            <FormInputSelect
                                                label={`habilitation`}
                                                displayLabel={false}
                                                options={this.state.refHabilitations[this.props.entite.id]?.map((e: any) => ({ label: e.lib_hab, value: e.code_hab })).sort((a: any, b: any) => (a.label > b.label) ? 1 : -1) || []}
                                                name={`habilitation--${per.id}`}
                                                id={`habilitation--${per.id}`}
                                                handle={(e: any) => this.setState((prev: any) => ({
                                                    habFonc: {
                                                        ...prev.habFonc,
                                                        [per.id]: {
                                                            ...prev.habFonc[per.id],
                                                            habilitation: e.data.value
                                                        }
                                                    }
                                                }))}
                                                value={this.state.habFonc[per.id]?.habilitation || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                simpleValidator={this.validator}
                                                required={true}
                                                isClearable={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor={`fonction${per.id}`}>Fonction*</label>
                                            <FormInputSelect
                                                label={`fonction`}
                                                displayLabel={false}
                                                options={this.state.fonctions.map((fonc: any) => ({ value: fonc.id, label: fonc.lib_fonction }))}
                                                name={`fonction--${per.id}`}
                                                id={`fonction--${per.id}`}
                                                handle={(e: any) => this.setState((prev: any) => ({
                                                    habFonc: {
                                                        ...prev.habFonc,
                                                        [per.id]: {
                                                            ...prev.habFonc[per.id],
                                                            fonction: e.data.value
                                                        }
                                                    }
                                                }))}
                                                value={this.state.habFonc[per.id]?.fonction || ""}
                                                isSubmitted={this.state.isSubmitted}
                                                simpleValidator={this.validator}
                                                required={true}
                                                isClearable={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relation-card__action">
                                <button className="btn-icon btn-icon--secondary btn-icon--sm" onClick={() => this.deletePersonne(per.id)}>
                                    <span className="sr-only">Supprimer</span>
                                    <i className="icon-trash"></i>
                                </button>
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </>)
    }
}

export default withTranslation()(withRouter(AssociatePersonnesToEntites));
