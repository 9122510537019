import React from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import CustomComponent from "../../components/CustomComponent";
import { FormInput } from "../../components/FormComponents";
import { DefaultLayout } from "../../components/Layouts";
import { TOAST_TYPE, sendToast } from "../../helpers/helpers";
import { validateIsNotEmpty, validatePassword, validatePasswordMatch } from "../../helpers/validation";
import { initValidator } from "../../validations/validations";

class ChangePassword extends CustomComponent {
    private _isMounted = false;
    private readonly validator;

    constructor(props: any) {
        super(props);
        this.state = {
            isSubmitted: false,
            password_form: {
                old_password: '',
                new_password: '',
                confirm_password: '',
            },
        }

        this.validator = initValidator();
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
        this._isMounted = true;

        document.title = 'Infolabo | Changement de mot de passe';
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    afterSubmission = async (event: React.FormEvent) => {
        event.preventDefault();
        const {t} = this.props;
        this.setState({isSubmitted: true});

        const allValid = (
            this.state.password_form.old_password.length > 0
            && validatePassword(this.state.password_form.new_password, this.state.password_form.confirm_password)?.type !== 'erreur'
            && validatePasswordMatch(this.state.password_form.confirm_password, this.state.password_form.new_password)?.type !== 'erreur'
        )

        if (!allValid) {
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
            return;
        }

        const BODY = {
            old_password: this.state.password_form.old_password,
            new_password: this.state.password_form.new_password,
        };

        const data = await this.request(`/auth/change_password`, 'POST', BODY);

        if (data?.statusCode === 200 && this._isMounted) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            this.props.history.push('/profil');
        }
    }

    render() {
        const {t} = this.props;
        let validate_new_password = validatePassword(this.state.password_form.new_password, this.state.password_form.confirm_password || '');
        return (
            <DefaultLayout>
                <div className="container m-b-20">
                    <BreadCrumb crumbs={[
                        {name: 'Mes informations', path: '/profil'},
                        {name: 'Changement de mot de passe', path: '/profil/changer_mot_de_passe'}
                    ]} />
                    <form onSubmit={this.afterSubmission}>
                        <div className="d-flex justify-content-between flex-wrap align-items-center">
                            <h1 className="main-title">Changement de mot de passe</h1>
                            <div className="m-b-30">
                                <Link to="/profil" className="btn m-r-5">Annuler</Link>
                                <button type="submit" className="btn btn-dark">Modifier le mot de passe</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group m-b-30">
                                            <FormInput
                                                label="Ancien mot de passe"
                                                id="old_password"
                                                name="old_password"
                                                type="password"
                                                handle={(e: any) => this.handleInputChange(e, null, 'password_form', undefined, true)}
                                                value={this.state.password_form.old_password || ''}
                                                className="input-group--password"
                                                maxLength={255}
                                                required
                                                isSubmitted={this.state.isSubmitted}
                                                validation={validateIsNotEmpty}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group m-b-30">
                                            <FormInput
                                                label="Nouveau mot de passe"
                                                id="new_password"
                                                name="new_password"
                                                type="password"
                                                handle={(e: any) => this.handleInputChange(e, null, 'password_form', undefined, true)}
                                                value={this.state.password_form.new_password || ''}
                                                className="input-group--password"
                                                maxLength={255}
                                                required
                                                isSubmitted={this.state.isSubmitted}
                                                validation={() => validate_new_password}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group m-b-30">
                                            <FormInput
                                                label="Confirmation mot de passe"
                                                id="confirm_password"
                                                name="confirm_password"
                                                type="password"
                                                handle={(e: any) => this.handleInputChange(e, null, 'password_form', undefined, true)}
                                                value={this.state.password_form.confirm_password || ''}
                                                className="input-group--password"
                                                maxLength={255}
                                                required
                                                isSubmitted={this.state.isSubmitted}
                                                validation={(e: string) => validatePasswordMatch(e, this.state.password_form.new_password)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="d-flex form-text form-text__blue-80 m-x-30 m-y-25">
                                    <i className="icon-info m-r-10"></i>
                                    <div>
                                        <p className="m-b-5">{t("reset_password.security_text")}</p>
                                        <ul>
                                            <li className={(validate_new_password?.contains?.includes("lower")) ? "text-success" : ""}>{t("reset_password.lower")}</li>
                                            <li className={(validate_new_password?.contains?.includes("upper")) ? "text-success" : ""}>{t("reset_password.upper")}</li>
                                            <li className={(validate_new_password?.contains?.includes("special_char")) ? "text-success" : ""}>{t("reset_password.special_char")}</li>
                                            <li className={(validate_new_password?.contains?.includes("number")) ? "text-success" : ""}>{t("reset_password.number")}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ChangePassword));