import Create from './create';
import Update from './update';
import List from './list';
import Details from './details';
import Delete from './delete';

const Entite = {
    Create,
    Update,
    List,
    Details,
    Delete
}

export default Entite;