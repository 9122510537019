import { Link } from "react-router-dom"

interface Props {
    crumbs?: {name: string, path?: string}[]
}

const BreadCrumb = ({crumbs}: Props) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-icon"><i className="icon-home"></i></li>
                <li className={`breadcrumb-item ${crumbs ? '' : 'active'}`}
                    aria-current={crumbs ? 'false': 'page'} >
                    {crumbs ? (<Link to='/'>Accueil</Link>) : (<>Accueil</>)}
                </li>
                {crumbs?.map((crumb, index) => (
                    <li key={index}
                        className={`breadcrumb-item ${index !== crumbs.length -1 ? '' : 'active'}`}
                        aria-current={index !== crumbs.length -1 ? 'false': 'page'} >
                        {index !== crumbs.length -1 && crumb.path ? (<Link to={crumb.path}>{crumb.name}</Link>) : (<>{crumb.name}</>)}
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export default BreadCrumb