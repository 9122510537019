import { useEffect, useRef } from "react";
import useWindowSize from "../hooks/useWindowSize";

// interface Paginate {
//     count: 0,
//     limit: 10,
//     page: 0,
//     pageTotal: 0,
// }

interface LazyPaginate {
    count: 0,
    limit: 10,
    page: 0,
}

const Pagination = ({ handleClick, pageCurrent, pageTotal, pageDisplay = 5, secondary = false }: any) => {
    let items = Array.from({ length: pageTotal }, (_, i) => i + 1);
    let pageStart = Math.max(parseInt(pageCurrent) - Math.floor(pageDisplay / 2), 1);
    items = items.splice(pageStart - 1, pageDisplay);

    if (pageTotal < 2) return <></>

    const pageLinkCSS = secondary ? "page-link secondary" : "page-link";
    return (
        <nav>
            <ul className="pagination">
                {pageCurrent > 1 && <li className={`page-item page-item-first`}><button onClick={handleClick} className={pageLinkCSS} value={1} title="première page"><i className="icon-first"></i></button></li>}
                {pageTotal > 1 && <li className={`page-item page-item-prev ${pageCurrent < 2 ? 'disabled' : ''}`}><button onClick={handleClick} disabled={pageCurrent < 2} className={pageLinkCSS} value={parseInt(pageCurrent) - 1}>Précédent</button></li>}
                {items.map(item => (
                    <li key={item} className={`page-item ${item === parseInt(pageCurrent) ? 'active' : ''}`}><button onClick={handleClick} className={pageLinkCSS} value={item}>{item}</button></li>
                ))}
                {pageTotal > 1 && <li className={`page-item page-item-next ${pageCurrent >= pageTotal ? 'disabled' : ''}`}><button onClick={handleClick} disabled={pageCurrent >= pageTotal} className={pageLinkCSS} value={parseInt(pageCurrent) + 1}>Suivant</button></li>}
                {pageCurrent <= pageTotal - 1 && <li className={`page-item page-item-last`}><button onClick={handleClick} className={pageLinkCSS} title="dernière page" value={parseInt(pageTotal)}><i className="icon-last"></i></button></li>}
            </ul>
        </nav>
    )
}

interface SeeMoreButtonProps {
    paginate: LazyPaginate,
    onClick: Function,
    disabled: boolean,
    behaviour?: 'button' | 'scroll' | 'scroll-mobile'
    variant?: 'link'
}
export const SeeMoreButton = ({ behaviour = 'button', paginate, onClick, disabled, variant }: SeeMoreButtonProps) => {
    const observer = useRef<any>(null)
    const { isMobile } = useWindowSize()

    useEffect(() => {
        if (behaviour === 'button' || (behaviour === 'scroll-mobile' && !isMobile)) return

        const handleScroll = () => {
            if (disabled) return
            if (window.scrollY + window.innerHeight > observer?.current?.offsetTop) {
                onClick()
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [isMobile, behaviour, onClick, disabled])

    // No more data to load
    if (paginate.count < paginate.limit) return <></>

    if (behaviour === 'scroll' || (behaviour === 'scroll-mobile' && isMobile)) return (
        <div ref={observer} style={{ height: 60 }}></div>
    )

    if (variant === 'link') return (
        <button onClick={() => onClick()} type="button" className="link link--strong m-b-10 m-t-5">Afficher plus</button>
    )

    return (
        <div className="text-center m-y-30">
            <button onClick={() => onClick()} type="button" className="btn btn-dark">Afficher plus</button>
        </div>
    )
}

export default Pagination
