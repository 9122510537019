// ANNUAIRE

export enum TABLE_DE_REF_ANNUAIRE {
    REF_BASSIN_LAITIER = 'REF_BASSIN_LAITIER',
    REF_REGION = 'REF_REGION',
    REF_DEPARTEMENT = 'REF_DEPARTEMENT',
    REF_COMMUNE = 'REF_COMMUNE',
}

export const TABLE_DE_REF_ANNUAIRE_LABEL: {[key: string]: string} = {
    [TABLE_DE_REF_ANNUAIRE.REF_BASSIN_LAITIER]: 'Bassins Laitiers',
    [TABLE_DE_REF_ANNUAIRE.REF_REGION]: 'Régions administratives',
    [TABLE_DE_REF_ANNUAIRE.REF_DEPARTEMENT]: 'Départements',
    [TABLE_DE_REF_ANNUAIRE.REF_COMMUNE]: 'Communes',
}

export const TABLE_DE_REF_ANNUAIRE_OPTIONS = Object.entries(TABLE_DE_REF_ANNUAIRE_LABEL).map(([value, label]) => ({
    value, label
}))

// METIER

export enum TABLE_DE_REF_METIER {
    REF_AIP = 'REF_AIP',
    REF_SIGNATAIRE = 'REF_SIGNATAIRE',
    REF_UNITE_MESURE = 'REF_UNITE_MESURE',
    REF_CRITERE_ANALYSE = 'REF_CRITERE_ANALYSE',
    REF_METHODE_ANALYSE = 'REF_METHODE_ANALYSE',
    REF_METHODE_ANALYSE_CRITERE_ANALYSE = 'REF_METHODE_ANALYSE_CRITERE_ANALYSE',
    REF_UNITE_MESURE_CRITERE_ANALYSE = 'REF_UNITE_MESURE_CRITERE_ANALYSE',
}

export const TABLE_DE_REF_METIER_LABEL: {[key: string]: string} = {
    [TABLE_DE_REF_METIER.REF_AIP]: `Accords Interprofessionels`,
    [TABLE_DE_REF_METIER.REF_SIGNATAIRE]: `Signataires`,
    [TABLE_DE_REF_METIER.REF_UNITE_MESURE]: `Unités de mesure`,
    [TABLE_DE_REF_METIER.REF_CRITERE_ANALYSE]: `Critères d'analyse`,
    [TABLE_DE_REF_METIER.REF_METHODE_ANALYSE]: `Méthodes d'analyse`,
    [TABLE_DE_REF_METIER.REF_METHODE_ANALYSE_CRITERE_ANALYSE]: `Méthodes d'analyse par critère`,
    [TABLE_DE_REF_METIER.REF_UNITE_MESURE_CRITERE_ANALYSE]: `Unités de mesure par critère d'analyse`,
}

export const TABLE_DE_REF_METIER_OPTIONS = Object.entries(TABLE_DE_REF_METIER_LABEL).map(([value, label]) => ({
    value, label
}))