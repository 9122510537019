import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb";
import { FileUploadModal } from "../../../components/FileUploadModal";
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import { Request, TOAST_TYPE, hasPermission, sendToast } from "../../../helpers/helpers";
import { validateFile } from '../../../helpers/validation';
import { exportAnnuaire } from "../../../requests/exportCSVAnnuaire";

const AnnuaireHeader = () => {
    const [loading, setLoading] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [fileNumber, setFileNumber] = useState(-1);
    const [arretUrgence, setArretUrgence]: any = useState(false);

    const getArretUrgenceStatus = async () => {
        const URL = '/inf_arret_urgence/annuaire';

        const data = await Request(URL, 'GET');

        if (data.statusCode === 200) {
            setArretUrgence(data?.enabled);
        } else {
            setArretUrgence(false);
        }
    }

    const importCSV = async (file: File) => {
        // check arret urgence
        await getArretUrgenceStatus();
        if (arretUrgence) {
            sendToast(<p>Veuillez attendre la fin de l'arrêt d'urgence en cours pour pouvoir importer</p>, TOAST_TYPE.ERROR);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const data = await Request('/csv/import_annuaire_ihm', 'POST', formData, true);

        if (!!data && data?.statusCode === 201) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
        }

        setOpen(false);
    }

    useEffect(() => {
        getArretUrgenceStatus();
        if (isOpen) {
            Request('/inf-suivi-flux-annu/findMaxImportByUserEntite', 'GET').then((data: any) => {
                setFileNumber(data.max || 0);
            });
        }
    }, [isOpen]);

    const onExportAnnuaire = async () => {
        setLoading(true);
        const data = await exportAnnuaire();
        if (data && data.statusCode === 200) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
        }
        setLoading(false);
    }

    return (<>
        <LoadingIndicator loading={loading} />
        <BreadCrumb crumbs={[{ name: 'Annuaire', path: '/' }]} />
        <div className="d-flex justify-content-between flex-wrap align-items-center">
            <h1 className="main-title">Annuaire</h1>
            <div className="m-b-20 d-flex flex-wrap ml-auto align-items-start justify-content-end">
                {hasPermission([
                    'DRT_ANNU_ENT_GERER',
                    'DRT_ANNU_PER_GERER',
                    'DRT_ANNU_GROUPE',
                    'DRT_ANNU_REGROUPEMENT_CREER'
                ]) && (
                        <Dropdown autoClose="outside">
                            <Dropdown.Toggle bsPrefix="btn btn-dark m-b-10">Créer</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {hasPermission('DRT_ANNU_ENT_GERER') && (
                                    <Link to="/annuaire/entite/creer" className="dropdown-item">Fiche Entité</Link>
                                )}
                                {hasPermission(['DRT_ANNU_PER_GERER']) && (
                                    <Link to="/annuaire/personne/creer" className="dropdown-item">Fiche Personne</Link>
                                )}
                                {hasPermission('DRT_ANNU_GROUPE') && (
                                    <Link to="/annuaire/groupe/creer" className="dropdown-item">Groupe</Link>
                                )}
                                {hasPermission('DRT_ANNU_REGROUPEMENT_CREER') && (
                                    <Link to="/annuaire/regroupement/creer" className="dropdown-item">Regroupement</Link>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                {hasPermission('DRT_ANNU_IMPORTER') && (
                    <button className='btn btn-dark m-l-10 m-b-10' onClick={() => setOpen(true)}><i className="icon-import"></i>Importer un fichier</button>
                )}
                {hasPermission('DRT_ANNU_EXPORTER') && (
                    <button type="button" onClick={onExportAnnuaire} className="m-l-10 m-b-10 btn btn-success">
                        <i className="icon-export"></i>
                        Exporter l'annuaire
                    </button>
                )}
            </div>
        </div>
        <FileUploadModal
            title="Importer un fichier Annuaire ou Groupe"
            info={"Numéro attendu : " + (fileNumber + 1)}
            open={isOpen}
            handle={importCSV}
            types={['CSV']}
            maxSize={60}
            validation={(e: any) => validateFile(e, ['csv'], 60)}
            onClose={() => setOpen(false)}
        />
    </>)
}

export { AnnuaireHeader };

