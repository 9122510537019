import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import CustomComponent from "../../components/CustomComponent";
import { DefaultLayout } from "../../components/Layouts";
import EntitiesPanel from "./_entities_panel";
import GeneralInformationsPanel from "./_general_informations_panel";
import PasswordPanel from "./_password_panel";
import SubscriptionPanel from "./_subscriptions_panel";

class UserInformations extends CustomComponent {
    private _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {
            per_id: null,
            personne: null
        }
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
        this._isMounted = true;
        document.title = 'Infolabo | Mes informations';
        this._isMounted && this.getPersonne();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getPersonne = async () => {
        const data = await this.request(`/per_personne/user`, 'GET');
        if (!!data?.personne && this._isMounted) {
            this.setState({personne: data.personne});
        }
    }

    render() {
        const {t} = this.props;

        return (
            <DefaultLayout>
                <div className="container m-b-20">
                    <BreadCrumb crumbs={[{name: 'Mes informations', path: '/profil'}]}/>
                    <div>
                        <h1 className="main-title">{t('mes_informations.title')}</h1>
                    </div>
                    <PasswordPanel personne={this.state.personne} />
                    <GeneralInformationsPanel personne={this.state.personne} />
                    <EntitiesPanel />
                    <SubscriptionPanel isUserInformations />
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(UserInformations));