import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { AuditFields } from "../../../components/AuditFields";
import BreadCrumb from "../../../components/BreadCrumb";
import CustomComponent from "../../../components/CustomComponent";
import { FormInput, FormRadioValues } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import Panel from "../../../components/Panel";
import TABLE from "../../../components/Table";
import {
    TOAST_TYPE,
    getHabilitation,
    getSession, hasPermission, labelizePersonneEntites,
    sendToast,
    sortByField
} from "../../../helpers/helpers";
import { initValidator } from "../../../validations/validations";
import AddMembreModal from "./_addMembreModal";

class CreateGroupe extends CustomComponent {
    private _isMounted = false;
    private validator;
    private userHabilitation = getHabilitation();

    private _isDetailsPage = this.props.isDetailsPage;
    private _isUpdatePage = this.props.isUpdatePage;
    private _groupeId = this.props.groupeId;
    private _title = this.props.title ?? `Création d'un groupe`;

    private defaultFilters = {
        orderBy: '',
        ordering: '',
        nom_usuel: '',
        siret: '',
        classe: '',
        ville: '',
        nom: '',
        prenom: ''
    }

    constructor(props: any) {
        super(props);
        this.state = {
            groupe: {
                membres: [],
                proprietaire: `${this.userHabilitation.ent_id.siret} - ${this.userHabilitation.ent_id.nom_usuel}`,
                description: '',
                nom: '',
                type: 'E',
            },
            filters: this.defaultFilters,
            entites: [],
            filteredMembres: [],
            paginate: {
                count: 0,
                limit: 20,
                page: 0,
                pageTotal: 0,
            },
            auditFields: null,
            isSubmitted: false,
            isLoading: false,
            isChangeTypeModalOpen: false,
            isDeleteModalOpen: false,
            deletingMember: null,
            deletingGroupe: false,
        };
        this.validator = initValidator();
    }

    componentDidMount() {
        this._isMounted = true;

        document.title = `Infolabo | ${this._title}`;

        if (this._groupeId) {
            this.setState({ isLoading: true });
            this.getGroupe();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getGroupe = async () => {

        const { groupe, audit } = await this.request(`/ent_groupe/${this._groupeId}`, 'GET');

        if (groupe && audit) {
            this.setState((prev: any) => ({
                groupe: {
                    ...prev.groupe,
                    ...groupe,
                    proprietaire: `${groupe.proprietaire.siret} - ${groupe.proprietaire.nom_usuel}`,
                },
                auditFields: audit
            }), this.updatePaginationAndFilters);
        }
        this.setState({ isLoading: false });
    }

    removeMember = async () => {
        const groupe = { ...this.state.groupe };
        const membres = this.state.groupe.membres.filter((ent: any) => ent.id !== +this.state.deletingMember.id);

        if (!!this._groupeId) {
            // delete member in database when update group
            const BODY = {
                ...(groupe.type === 'E' && { membre_entite: +this.state.deletingMember.id }),
                ...(groupe.type === 'P' && { membre_personne: +this.state.deletingMember.id })
            };
            const data = await this.request(`/ent_groupe/${this._groupeId}/deletemember`, 'PATCH', BODY);

            if (data?.statusCode === 201) {
                sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
                this.setState((prev: any) => ({ groupe: { ...prev.groupe, membres: membres }, isDeleteModalOpen: false, deletingMember: null }), () => {
                    this.updatePaginationAndFilters();
                    this.getGroupe();
                });
            }
        } else
            this.setState((prev: any) => ({ groupe: { ...prev.groupe, membres: membres }, isDeleteModalOpen: false, deletingMember: null }), this.updatePaginationAndFilters);
    }

    addMembers = async (members: any[]) => {
        const groupe = { ...this.state.groupe };
        const membres = this.state.groupe.membres.concat(members);

        if (!!this._groupeId) {
            // add members in database when update group
            const BODY = {
                ...(groupe.type === 'E' && { membres_entite: members.map((e) => +e.id) }),
                ...(groupe.type === 'P' && { membres_personne: members.map((e) => +e.id) })
            };
            const data = await this.request(`/ent_groupe/${this._groupeId}/addmembers`, 'PATCH', BODY);

            if (data?.statusCode === 201) {
                sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
                this.setState((prev: any) => ({ groupe: { ...prev.groupe, membres: membres } }), () => {
                    this.updatePaginationAndFilters();
                    this.getGroupe();
                });
            }
        } else
            this.setState((prev: any) => ({ groupe: { ...prev.groupe, membres: membres } }), this.updatePaginationAndFilters);
    }

    afterSubmission = async (e: any) => {
        e.preventDefault();
        const { t } = this.props;

        this._isMounted && this.setState({ isLoading: true, isSubmitted: true });

        if (this.validator.allValid()) {
            await this.createGroupe();
        } else {
            this.validator.showMessages();
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }

        this._isMounted && this.setState({ isLoading: false });
    }

    createGroupe = async () => {
        const URL = this._groupeId ? `/ent_groupe/${this._groupeId}` : `/ent_groupe`;
        const METHOD = this._groupeId ? `PATCH` : `POST`;

        const groupe = { ...this.state.groupe };

        let membres_entite = [];
        let membres_personne = [];

        if (!this._groupeId) {
            membres_entite = groupe.type === 'E' ? groupe.membres.map((membre: any) => membre.id) : [];
            membres_personne = groupe.type === 'P' ? groupe.membres.map((membre: any) => membre.id) : [];
        }

        delete groupe.membres;

        const BODY = {
            ...groupe,
            ...(!this._groupeId && { membres_entite: membres_entite }),
            ...(!this._groupeId && { membres_personne: membres_personne }),
            ent_id_proprietaire: this.userHabilitation.ent_id.id
        }

        const data = await this.request(URL, METHOD, BODY);

        if (data && data.statusCode === 201) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.props.history.push(`/annuaire/groupe/${data.id}`);
        }
    }

    deleteGroupe = async () => {
        const data = await this.request(`/ent_groupe/${this._groupeId}`, 'DELETE');
        if (data && data.statusCode === 200) {
            this.setState({ deletingGroupe: false });
            this.props.history.push('/annuaire/groupe');
        }
    }

    handleSort = (field: string) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== this.state.filters.orderBy || this.state.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), this.updatePaginationAndFilters);
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: value - 1
            }
        }), () => this.updatePaginationAndFilters(false))
    }

    updatePaginationAndFilters = (resetPagination = true, membres = this.state.groupe.membres) => {
        const { orderBy, ordering, ...filters } = this.state.filters;
        const { limit, page } = this.state.paginate;

        let filteredMembres = membres.map((per: any) => ({ ...per, showChildren: false }))
        Object.keys(filters).forEach(key => {
            filteredMembres = filteredMembres.filter((per: any) => filters[key] ? per[key] && per[key].toLowerCase().includes(filters[key].toLowerCase()) : true)
        })

        if (orderBy && ordering) {
            filteredMembres = sortByField(filteredMembres, orderBy, ordering)
        }

        this.setState((prev: any) => ({
            filteredMembres: filteredMembres.slice(page * limit, page * limit + limit),
            paginate: {
                ...prev.paginate,
                pageTotal: Math.ceil(filteredMembres.length / limit),
                page: resetPagination ? 0 : page
            }
        }));
    }

    renderButtons = () => {
        if (this._isDetailsPage) return <>
            <Link to={`/annuaire/groupe`} className="btn m-r-5">Annuler</Link>
            {hasPermission('DRT_ANNU_GROUPE') && (
                <>
                    <button className="btn btn-danger m-r-5" type="button" onClick={() => this.setState({ deletingGroupe: true })}>Supprimer</button>
                    <Link to={`/annuaire/groupe/${this._groupeId}/modifier`} className="btn btn-dark">Modifier</Link>
                </>
            )}
        </>

        if (this._isUpdatePage) return <>
            <Link to={`/annuaire/groupe/${this._groupeId}`} className="btn m-r-5">Annuler</Link>
            {hasPermission('DRT_ANNU_GROUPE') && (
                <>
                    <button className="btn btn-danger m-r-5" type="button" onClick={() => this.setState({ deletingGroupe: true })}>Supprimer</button>
                    <button type="submit" className="btn btn-secondary">Enregistrer</button>
                </>
            )}
        </>

        return <>
            <Link to={`/annuaire/groupe`} className="btn m-r-5">Annuler</Link>
            <button type="submit" className="btn btn-secondary">Enregistrer</button>
        </>
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Annuaire', path: '/annuaire/groupe' }, { name: this._title, path: '' }]} />
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="d-flex justify-content-between flex-wrap align-items-center">
                            <h1 className="main-title">{this._title}</h1>
                            <div className="m-b-30">
                                {this.renderButtons()}
                            </div>
                        </div>
                        <Panel>
                            <div className="large-gutters">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                id="proprietaire"
                                                name="proprietaire"
                                                label="Propriétaire"
                                                type="text"
                                                value={this.state.groupe.proprietaire}
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                id="nom"
                                                name="nom"
                                                label="Nom du groupe"
                                                type="text"
                                                value={this.state.groupe.nom}
                                                handle={(e: any) => this.handleInputChange(e, null, 'groupe')}
                                                maxLength={80}
                                                simpleValidator={this.validator}
                                                isSubmitted={this.state.isSubmitted}
                                                isClearable
                                                required
                                                disabled={this._isDetailsPage}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInput
                                                id="description"
                                                name="description"
                                                label="Description du groupe"
                                                type="text"
                                                value={this.state.groupe.description}
                                                handle={(e: any) => this.handleInputChange(e, null, 'groupe')}
                                                maxLength={255}
                                                simpleValidator={this.validator}
                                                isSubmitted={this.state.isSubmitted}
                                                isClearable
                                                required
                                                disabled={this._isDetailsPage}
                                            />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            {['CNIEL', 'LABO'].includes(getSession()?.type_ent) &&
                                                <FormRadioValues
                                                    id="type"
                                                    name="type"
                                                    label="Type de groupe"
                                                    value={this.state.groupe.type}
                                                    options={[{ value: 'P', label: 'Personne' }, { value: 'E', label: 'Entité' }]}
                                                    handle={(e: any) => this.setState({ isChangeTypeModalOpen: true })}
                                                    required
                                                    disabled={this._isDetailsPage || this._isUpdatePage}
                                                    info={{
                                                        placement: 'top',
                                                        text: 'Les groupes de Personnes sont utilisés pour la mise en place des abonnements (liste de diffusion push).<br />Les groupes d’Entités sont utilisés pour le paramétrage des exports laiterie. '
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </form>
                    {/* Ajouter une entité ou personne */}
                    {!this._isDetailsPage &&
                        <AddMembreModal
                            type={this.state.groupe.type}
                            membres={this.state.groupe.membres}
                            handleIsLoading={(e: any) => this.setState({ isLoading: e })}
                            handle={(e: any) => this.addMembers(e)}
                        />
                    }
                    {/* Liste membres */}
                    {this.state.groupe.membres.length > 0 && (
                        <div className="m-t-30">
                            <div className="table-responsive desktop-only">
                                <TABLE>
                                    <TABLE.THEAD>
                                        <TABLE.TR>
                                            {this.state.groupe.type === 'E' ? <>
                                                <TABLE.TH
                                                    name="nom_usuel"
                                                    sort={{
                                                        orderBy: this.state.filters.orderBy,
                                                        ordering: this.state.filters.ordering,
                                                        onSort: () => this.handleSort('nom_usuel')
                                                    }}
                                                    input={{
                                                        value: this.state.filters.nom_usuel,
                                                        placeholder: 'Nom usuel',
                                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.updatePaginationAndFilters)
                                                    }}
                                                />
                                                <TABLE.TH
                                                    name="siret"
                                                    sort={{
                                                        orderBy: this.state.filters.orderBy,
                                                        ordering: this.state.filters.ordering,
                                                        onSort: () => this.handleSort('siret')
                                                    }}
                                                    input={{
                                                        value: this.state.filters.siret,
                                                        placeholder: 'N° SIRET',
                                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.updatePaginationAndFilters)
                                                    }}
                                                />
                                                <TABLE.TH
                                                    name="classe"
                                                    sort={{
                                                        orderBy: this.state.filters.orderBy,
                                                        ordering: this.state.filters.ordering,
                                                        onSort: () => this.handleSort('classe')
                                                    }}
                                                    input={{
                                                        value: this.state.filters.classe,
                                                        placeholder: 'Classe entité',
                                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.updatePaginationAndFilters)
                                                    }}
                                                />
                                                <TABLE.TH
                                                    name="ville"
                                                    sort={{
                                                        orderBy: this.state.filters.orderBy,
                                                        ordering: this.state.filters.ordering,
                                                        onSort: () => this.handleSort('ville')
                                                    }}
                                                    input={{
                                                        value: this.state.filters.ville,
                                                        placeholder: 'Ville',
                                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.updatePaginationAndFilters)
                                                    }}
                                                />
                                            </> : <>
                                                <TABLE.TH
                                                    name="nom"
                                                    sort={{
                                                        orderBy: this.state.filters.orderBy,
                                                        ordering: this.state.filters.ordering,
                                                        onSort: () => this.handleSort('nom')
                                                    }}
                                                    input={{
                                                        value: this.state.filters.nom,
                                                        placeholder: 'Nom',
                                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.updatePaginationAndFilters)
                                                    }}
                                                />
                                                <TABLE.TH
                                                    name="prenom"
                                                    sort={{
                                                        orderBy: this.state.filters.orderBy,
                                                        ordering: this.state.filters.ordering,
                                                        onSort: () => this.handleSort('prenom')
                                                    }}
                                                    input={{
                                                        value: this.state.filters.prenom,
                                                        placeholder: 'Prénom',
                                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.updatePaginationAndFilters)
                                                    }}
                                                />
                                                <TABLE.TH
                                                    label="Entité(s)"
                                                />
                                            </>}
                                            <TABLE.TH actions label="Actions" />
                                        </TABLE.TR>
                                    </TABLE.THEAD>
                                    <TABLE.TBODY>
                                        {this.state.filteredMembres.map((membre: any) => (
                                            <TABLE.TR key={membre.id}>
                                                <TABLE.TD>{this.state.groupe.type === 'E' ? membre.nom_usuel : membre.nom}</TABLE.TD>
                                                <TABLE.TD>{this.state.groupe.type === 'E' ? membre.siret : membre.prenom}</TABLE.TD>
                                                <TABLE.TD>{this.state.groupe.type === 'E' ? membre.classe : labelizePersonneEntites(membre.entites)}</TABLE.TD>
                                                {this.state.groupe.type === 'E' && <TABLE.TD>{membre.ville}</TABLE.TD>}
                                                <TABLE.TD actions>
                                                    <div className="d-flex">
                                                        {(this.state.groupe.type === 'E' && hasPermission('DRT_ANNU_ENT_CONSULTER')) &&
                                                            <Link className="action-btn" to={`/annuaire/entite/${membre.id}`} target="_blank">
                                                                <i className="icon-eye"></i>
                                                            </Link>
                                                        }
                                                        {(this.state.groupe.type === 'P' && hasPermission('DRT_ANNU_PER_CONSULTER')) &&
                                                            <Link className="action-btn" to={`/annuaire/personne/${membre.id}`} target="_blank">
                                                                <i className="icon-eye"></i>
                                                            </Link>
                                                        }
                                                        {(!this._isDetailsPage && hasPermission('DRT_ANNU_GROUPE')) &&
                                                            <button
                                                                className="action-btn"
                                                                onClick={() => this.setState({ isDeleteModalOpen: true, deletingMember: membre })}
                                                            >
                                                                <i className="icon-trash"></i>
                                                            </button>}
                                                    </div>
                                                </TABLE.TD>
                                            </TABLE.TR>
                                        ))}
                                    </TABLE.TBODY>
                                </TABLE>
                            </div>
                            <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDIsplay={3} />
                        </div>
                    )}
                </div>
                <Modal open={this.state.isDeleteModalOpen} customLayout>
                    <div className="modal-body">
                        {this.state.groupe.type === 'E' ? (
                            <p>Etes-vous sûr(e) de vouloir retirer l’entité <b className="text-nowrap">{this.state.deletingMember?.siret} - {this.state.deletingMember?.nom_usuel}</b> du groupe ?</p>
                        ) : (
                            <p>Etes-vous sûr(e) de vouloir retirer la personne <b className="text-nowrap">{[this.state.deletingMember?.nom, this.state.deletingMember?.prenom].filter((n: any) => !!n).join(' ')}</b> du groupe ?</p>
                        )}
                    </div>
                    <div className="modal-footer p-t-0">
                        <button onClick={() => this.setState({ isDeleteModalOpen: false, deletingMember: null })} className="btn btn-white m-r-10">Annuler</button>
                        <button onClick={() => this.removeMember()} className="btn btn-secondary">Confirmer</button>
                    </div>
                </Modal>
                <Modal open={this.state.isChangeTypeModalOpen}>
                    <div style={{ fontSize: '16px', marginBottom: '30px' }}>
                        Le changement de type de groupe réinitialisera la liste des membres associés. Souhaitez-vous continuer ?
                    </div>
                    <button onClick={() => this.setState({ isChangeTypeModalOpen: false })} className="btn btn-white">Annuler</button>
                    <button
                        onClick={() => this.setState((prev: any) => ({
                            isChangeTypeModalOpen: false,
                            groupe: {
                                ...prev.groupe,
                                type: (prev.groupe.type === 'E' ? 'P' : 'E'),
                                membres: []
                            },
                            filteredMembres: []
                        }))}
                        className="btn btn-secondary"
                    >
                        Confirmer
                    </button>
                </Modal>
                {this._groupeId && (
                    <Modal open={this.state.deletingGroupe}>
                        <div className="modal-body">
                            {this.state.deletingGroupe && (
                                <p>Etes-vous sûr(e) de vouloir supprimer le groupe <b>{this.state.groupe.nom}</b> ?</p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => this.setState({ deletingGroupe: false })} className="btn btn-white">Annuler</button>
                            <button onClick={() => this.deleteGroupe()} className="btn btn-secondary">Confirmer</button>
                        </div>
                    </Modal>
                )}
                {this.state.auditFields && <AuditFields audit={this.state.auditFields} />}
            </DefaultLayout>
        );
    }
}

export default withTranslation()(withRouter(CreateGroupe));