import CustomComponent from '../../components/CustomComponent';
import { LoginLayout } from '../../components/Layouts';
import { withRouter } from 'react-router-dom';


class NotFound extends CustomComponent {
    render() {
        return (
            <LoginLayout>
                <main className='my-auto text-center'>
                    <h1>404</h1>
                    <p>Cette page n'existe pas</p>
                    <button className="btn btn-primary mx-10" onClick={this.props.history.goBack}>Retour</button>
                </main>
            </LoginLayout>
        );
    }
}

export default withRouter(NotFound);
