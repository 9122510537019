import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CustomComponent from '../../../components/CustomComponent';
import { withTranslation } from 'react-i18next';
import CreatePersonne from './create'

interface RouteParams {id?: string}

class UpdatePersonne extends CustomComponent<RouteComponentProps<RouteParams>, any> {
    render() {
        const id = this.props.match.params.id

        return (<CreatePersonne personneId={id} />)

    }
}

export default withTranslation()(withRouter(UpdatePersonne));