import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CustomComponent from '../../components/CustomComponent';
import { DefaultLayout } from '../../components/Layouts';
import BreadCrumb from '../../components/BreadCrumb';
import { Accordion, Dropdown } from 'react-bootstrap';
import jwt_decode from 'jwt-decode'
import {updateUnreadAnnonces} from "../../helpers/helpers";


class ChangerEntite extends CustomComponent {
    private _isMounted = false;
    private ent_id = this.getHabilitation()?.ent_id.id;

    constructor(props: any) {
        super(props);
        this.state = {
            entite: []
        }
    }

    componentDidMount() {
        this.checkIsConnectedPWA();
        this._isMounted = true;
        document.title = 'Infolabo | Changer d\'entite';
        this._isMounted && this.getProfils();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getProfils() {
        const url = "/ent_entite/changeEntite/list";
        const method = "GET";

        const data = await this.request(url, method);
        if (!(data?.statusCode < 200 || data?.statusCode >= 300)) {
            this._isMounted && this.setState({
                entite: data
            });
        }
    }

    handleChangeEntite = async (event: any, simulate_entite_id: number, mother_entite_id: number, type_changement: string) => {
        event.preventDefault()
        event.stopPropagation()

        const data = await this.request('/auth/change_entity', 'POST', {
            simulate_entite_id,
            mother_entite_id,
            type_changement
        })

        if (data && 'access_token' in data) {
            const session: any = jwt_decode(data.access_token);

            localStorage.setItem('session', JSON.stringify(session));
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('per_habilitations', JSON.stringify(data.per_habilitations));
            localStorage.setItem('per_personne', JSON.stringify(data.per_personne));
            localStorage.setItem('habilitation_id', JSON.stringify(data.habilitation_id));
            localStorage.setItem('habDroit', JSON.stringify(data.habDroit));
            await updateUnreadAnnonces();
            this.props.history.go(0)
        }
    }

    render() {
        return (
            <DefaultLayout>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Changer d\'entité', path: '#' }]} />
                    <h1 className="main-title">Changer d'entité</h1>
                    {this.state.entite.map((entite: any) => (
                        entite.filliation ? (
                            <React.Fragment key={entite.id}>
                                <AccordionTree lib_hab={entite.lib_hab} data={[entite.filliation]} ent_id={this.ent_id} onSelectEntite={this.handleChangeEntite} root_id={entite.filliation.id} regroupements={entite.regroupements} />
                            </React.Fragment>
                        ) : null
                    ))}
                </div>
            </DefaultLayout>
        )
    }
}



const AccordionTree = ({ data, activeKey = "0", root = true, parentItem = null, ent_id, onSelectEntite, root_id, regroupements, lib_hab, level = 0, type='default' }: any) => {
    return (
        <div className={root ? 'overflow-auto d-flex' : ''}>
            <Accordion bsPrefix="accordion-tree" defaultActiveKey={activeKey}>
                {data.map((item: any) => (<Fragment key={item.id}>
                    <AccordionTreeItem lib_hab={lib_hab} item={item} parentItem={parentItem} eventKey={activeKey} ent_id={ent_id} onSelectEntite={onSelectEntite} root_id={root_id} regroupements={regroupements} root={root} level ={level} type={type} />
                </Fragment>))}
            </Accordion>
        </div>
    )
}

const AccordionTreeItem = ({lib_hab, item, eventKey, ent_id, onSelectEntite, root_id, regroupements, root, level, type='default' }: any) => {
    const hasChildren = item.children && item.children.length > 0
    const hasRegroupements = regroupements && regroupements.length >0
    const isCurrent = ent_id === item.id
    const isSelectable = !isCurrent

    return (
        <Accordion.Item eventKey={eventKey} bsPrefix="accordion-item">
            <div className={`accordion-header ${!!hasChildren ? 'has-children' : ''} ${isSelectable ? 'has-action-button' : ''} accordion-header--white level-${level}`}>
                <Accordion.Button as='div'>
                    <div className="d-flex w-100 align-items-center">
                        <div className="accordion-title">
                            <strong>{item.nom_usuel}</strong>
                            <span>{item.siret}</span>
                            {root && (<span><small>({lib_hab})</small></span>)}
                            {isCurrent && <span className='ml-lg-auto m-r-15'><small>Vous consultez Infolabo sous cette entité</small></span>}
                            {isSelectable && <button type="button" className='desktop-only accordion-action-button' onClick={(e: any) => onSelectEntite(e, item.id, root_id, type)}>Consulter sous cette entité</button>}
                        </div>
                        {isSelectable && (
                            <Dropdown className="mobile-only">
                                <Dropdown.Toggle as={CustomToggle}>
                                    <span className="sr-only">Ouvrir les actions</span>
                                    <i className="icon-actions"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <button type="button" className='dropdown-item' onClick={(e: any) => onSelectEntite(e, item.id, root_id)}>Consulter sous cette entité</button>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                    </div>
                </Accordion.Button>
            </div>
            {hasChildren && (<>
                <Accordion.Body bsPrefix="accordion-body">
                    <AccordionTree data={item.children} parentItem={item} activeKey={eventKey} root={false} ent_id={ent_id} isSelectable={isSelectable || isCurrent} onSelectEntite={onSelectEntite} root_id={root_id} level={level + 1} type='filiation'/>
                </Accordion.Body>
            </>)}
            {hasRegroupements && (<>
                <Accordion.Body bsPrefix="accordion-body">
                    {regroupements.map((regroupement: any) => (
                        <React.Fragment key={regroupement.id}>
                            <AccordionTreeRegroupement data={regroupement} parentItem={item} activeKey={eventKey} root={false} ent_id={ent_id} isSelectable={isSelectable || isCurrent} onSelectEntite={onSelectEntite} root_id={root_id} level={level + 1 } />
                        </React.Fragment>
                    ))}
                </Accordion.Body>
            </>)}
        </Accordion.Item>
    )
}

const AccordionTreeRegroupement = ({ data, activeKey = "0", root = true,  ent_id, onSelectEntite, root_id, level}: any) => {
    return (
        <div className={root ? 'overflow-auto d-flex' : ''}>
            <Accordion alwaysOpen bsPrefix="accordion-tree" defaultActiveKey={activeKey}>
                <Accordion.Item eventKey={activeKey} bsPrefix="accordion-item">
                    <div className="accordion-header has-children is-current">
                        <Accordion.Button as='div'>
                            <div className="d-flex w-100 align-items-center">
                                <div className="accordion-title">
                                    <i className="icon-workspace m-r-5"></i>
                                    <strong>{data.libelle}</strong>
                                </div>
                            </div>
                        </Accordion.Button>
                    </div>
                    <Accordion.Body bsPrefix="accordion-body">
                        <AccordionTree data={data.membres} parentItem={data} activeKey={activeKey} root={false} ent_id={ent_id} isSelectable={true} onSelectEntite={onSelectEntite} root_id={root_id} level={level + 1} type='regroupement'/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}


const CustomToggle = React.forwardRef<HTMLButtonElement, any>(({ children, onClick }: any, ref) => (
    <button
        className="btn-icon btn-icon--sm btn-icon--white"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
        }}
    >
        {children}
    </button>
));

export default withRouter(ChangerEntite);
