import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { CriteriaPanel, ICriteres } from "../../../components/CriteriaPanel";
import CustomComponent from "../../../components/CustomComponent";
import { FilterDropdown } from '../../../components/FilterDropdown';
import { FormDatePicker, FormInput, FormInputSelect, FormInputSelectCreatable } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import { SeeMoreButton } from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { checkIfCriteresAreFilled, datePickerFormat, hasPermission, Request, sendToast, TOAST_TYPE } from "../../../helpers/helpers";
import { ResultatsListHeader } from '../_common/ResultatsListHeader';

class ResultatsProductionList extends CustomComponent {
    private _isMounted = false

    private default_filters = {
        nom_exploitant: '',
        siret: '',
        num_ede: '',
        groupe_fav: '',
        ent_id_ca: '',
        type_lait: '',
        code_postal: '',
        ville: '',
        num_tank: '',
        laboratoire: '',
        date_debut: null,
        date_fin: null,
        orderBy: 'nom_exploitant',
        ordering: 'ASC',
        criteres: []
    }

    constructor(props: any) {
        super(props)
        this.state = {
            filters: this.default_filters,
            show_more_filters: false,
            options: {
                groupe_fav: [],
                ent_id_ca: [],
                type_lait: [],
                villes: []
            },
            recherches: [],
            productions: null,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
            },
            ville: '',
            isLoading: false
        }
    }

    componentDidMount() {
        document.title = "Infolabo | Par production";
        this._isMounted = true
        this.getTypeLait()
        this.getEntiteCAOptions()
        this.getGroupeFavOptions()
        this.getRecherches()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getTypeLait = async () => {
        const data = await this.request('/references/type_lait', 'GET')
        if (data?.statusCode === 200) {
            const type_lait: any[] = []
            for (let esp of data.especes_collectees) {
                for (let nat of data.natures_lait) {
                    type_lait.push({ label: `${esp.libelle} - ${nat.lib_court}`, value: `${esp.id}-${nat.id}` })
                }
            }
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    type_lait
                }
            }))
        }
    }

    async getEntiteCAOptions(e: any | null = null) {
        let URL: string = '/ent_entite/autocomplete/CEAPP'

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        }

        const data = await this.request(URL, 'GET')
        if (data) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ent_id_ca: data.map((option: any) => ({ label: option.label, value: option.id }))
                }
            }));
        }
    }

    getEntiteCAOptionFromSearchLoad = async (ent_id: string | number) => {
        const data = await this.request(`/ent_entite/${ent_id}`, 'GET');
        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ent_id_ca: [
                        ...prev.options.ent_id_ca,
                        { label: `${data.siret} - ${data.nom_usuel}`, value: data.id }
                    ]
                }
            }))
        }
    }

    getGroupeFavOptions = async () => {
        if (!hasPermission('DRT_ANNU_ENT_GRP_FAVORIS')) return;

        const data = await this.request(`/per_groupe_favori`, 'GET');
        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    groupe_fav: data.groupes_favoris.map((gfa: any) => ({ label: gfa.nom, value: gfa.id })),
                }
            }));
        }
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/RESULTAT_EXPLOITATION_LIST`, 'GET')

        if (recherches && this._isMounted) {
            this.setState({ recherches })
        }
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), this.getProductions)
    }

    getProductions = async (triggeredBySeemore: boolean = false) => {
        if (!checkIfCriteresAreFilled(this.state.filters.criteres))
            return;

        if (this.state.filters.criteres[0].cri_id && (!this.state.filters.date_debut || !this.state.filters.date_fin)) {
            sendToast(<p>Si un critère est renseigné, la ligne période doit l'être aussi.</p>, TOAST_TYPE.ERROR);
            return;
        }

        this.setState({ isLoading: true })

        const params = this.getParams(triggeredBySeemore);

        const data = await Request(`/resultats/production?${params.toString()}`, 'GET')
        this.setState({ isLoading: false })

        if (data?.statusCode === 200) {
            this.setState((prev: any) => ({
                productions: triggeredBySeemore ? [...prev.productions, ...data.productions] : data.productions,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }))
        }
    }

    getParams = (triggeredBySeemore: boolean = false) => {
        const params = new URLSearchParams();
        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        });

        Object.keys(this.state.filters).forEach(key => {
            let val = this.state.filters[key];

            if (key === 'criteres') val = JSON.stringify(val.filter((crit: any) => !!crit.cri_id && !!crit.operator && (!!crit.value || !!crit.app_id)));
            if (val instanceof Date) val = (val as Date).toISOString();
            if (val !== '' && val !== null) params.append(key, val.toString());
        });

        params.set('page', triggeredBySeemore ? this.state.paginate.page + 1 : 0);
        return params;
    }

    async getCitiesByLib(input: string) {
        let cities: { value: string, label: string }[] = [];
        const data = await this.request(`/ref_commune/findByLib/${input}`, 'GET');
        if (data && data.length > 0) {
            data.forEach((city: any) => {
                cities.push({ value: city.lib_commune, label: `${city.lib_commune} (${city.departement.code_departement})` });
            });
            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    villes: cities
                }
            }));
        }
    }

    updateThresholds = (criteres: ICriteres[]) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                criteres
            }
        }))
    }

    onSearchLoad = (filters: any) => {
        this.getCitiesByLib(filters.ville)
        if (!!filters.ent_id_ca) this.getEntiteCAOptionFromSearchLoad(filters.ent_id_ca);
        if (!this.state.options.groupe_fav.map((o: any) => o.value).includes(filters.groupe_fav)) {
            filters = { ...filters, groupe_fav: '' };
        }
        this.setState({
            filters,
            show_more_filters: filters?.criteres?.length > 0 || !!filters.date_debut || !!filters.date_fin
        }, this.getProductions)
    }

    paramsFromPeriode = () => {
        const params = new URLSearchParams();
        if (!!this.state.filters.date_debut) {
            if (this.state.filters.date_debut instanceof Date) params.append('date_d', this.state.filters.date_debut.toISOString());
            else params.append('date_d', this.state.filters.date_debut);
        }
        if (!!this.state.filters.date_fin) {
            if (this.state.filters.date_fin instanceof Date) params.append('date_f', this.state.filters.date_fin.toISOString());
            else params.append('date_f', this.state.filters.date_fin);
        }
        return params.toString();
    }

    render() {
        if (this.props.noLayout) return this.renderContent()

        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    {this.renderContent()}
                </div>
            </DefaultLayout>
        )
    }

    renderContent() {
        return (
            <>
                <ResultatsListHeader onHome={this.props.noLayout} homeTab="production" />
                <FilterDropdown
                    startOpen={true}
                    parent={this}
                    type_recherche='RESULTAT_EXPLOITATION_LIST'
                    filters={this.state.filters}
                    recherches={this.state.recherches}
                    onUpdate={this.getRecherches}
                    onSearchLoad={this.onSearchLoad}
                    onApplyFilters={this.getProductions}
                    onFiltersSearch={this.getProductions}
                    onFiltersReset={() => {
                        this.setState((prev: any) => ({
                            filters: this.default_filters,
                            options: { ...prev.options, villes: [] }
                        }), () => {
                            this.getEntiteCAOptions();
                        })
                    }}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group form-group--inline">
                                <FormInput label="Nom exploitant" name={`nom_exploitant`} value={this.state.filters.nom_exploitant} handle={(e: any) => this.handleInputChange(e, null, 'filters')} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInput label="N° SIRET" name={`siret_exploitant`} value={this.state.filters.siret_exploitant} handle={(e: any) => this.handleInputChange(e, null, 'filters')} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInput label="N° EDE" name={`num_ede`} value={this.state.filters.num_ede} handle={(e: any) => this.handleInputChange(e, null, 'filters')} />
                            </div>
                            {hasPermission('DRT_ANNU_ENT_GRP_FAVORIS') && (
                                <div className="form-group form-group--inline">
                                    <FormInputSelect isClearable label="Groupe de favoris" name={`groupe_fav`} value={this.state.filters.groupe_fav} handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.groupe_fav} />
                                </div>
                            )}
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group form-group--inline">
                                <FormInputSelect isClearable label="Centre d'appartenance" name={`ent_id_ca`} value={this.state.filters.ent_id_ca} handle={(e: any) => this.handleInputChange(e, null, 'filters')} handleInput={(e: any) => this.getEntiteCAOptions(e)} options={this.state.options.ent_id_ca} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInputSelect isClearable label="Type de lait" name={`type_lait`} value={this.state.filters.type_lait} handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.type_lait} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInput label="Code postal" name={`code_postal`} value={this.state.filters.code_postal} handle={(e: any) => this.handleInputChange(e, null, 'filters')} />
                            </div>
                            <div className="form-group form-group--inline">
                                <FormInputSelectCreatable
                                    optionsName="villes"
                                    options={this.state.options.villes.sort((a: any, b: any) => (a.label > b.label) ? 1 : -1).map((ville: any) => ({ value: ville.value, label: ville.label }))}
                                    label="Ville"
                                    name="ville"
                                    id="ville"
                                    displayValueNotLabel
                                    handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                    handleCreateOption={(e: any) => this.setState((prev: any) => ({
                                        filters: { ...prev.filters, ville: e.data.value },
                                        options: {
                                            ...prev.options,
                                            villes: [...prev.options.villes, { label: e.data.value, value: e.data.value }]
                                        }
                                    }))}
                                    handleInput={(e: any) => {
                                        this.setState({ ville: e.data?.value });
                                        this.getCitiesByLib(e.data);
                                    }}
                                    value={this.state.filters.ville}
                                    placeholder=""
                                    isDropdownDisabled={!(this.state.filters?.ville || this.state.options.villes.length || this.state.ville)}
                                    maxLength={100}
                                    isClearable={true}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <CriteriaPanel
                                criteres={this.state.filters.criteres}
                                onChange={this.updateThresholds}
                                min={1}
                                togglable
                                displayLibLong
                                open={this.state.show_more_filters}
                                onToggle={(open: boolean) => this.setState({ show_more_filters: open })}
                            >
                                <div className="form-group-flex">
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="date_debut"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state?.filters?.date_debut)}
                                            isClearable
                                            label="Période du" />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="date_fin"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state?.filters?.date_fin)}
                                            isClearable
                                            label="au" />
                                    </div>
                                </div>
                            </CriteriaPanel>
                        </div>
                    </div>
                </FilterDropdown>

                {!this.state.productions && <TABLE.NO_REQUEST />}
                {this.state.productions && (<>
                    {this.state.productions.length <= 0 ? <TABLE.NO_RESULT /> : (<>
                        <TABLE responsive="card" fixed>
                            <TABLE.THEAD>
                                <TABLE.TR>
                                    <TABLE.TH name='nom_exploitant' fixed={{ left: 0 }}
                                        label="Nom exploitation"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nom_exploitant') }} />
                                    <TABLE.TH name='siret_exploitant'
                                        label="Numéro de SIRET"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('siret_exploitant') }} />
                                    <TABLE.TH name='centre_appartenance'
                                        label="Centre d'appartenance"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('centre_appartenance') }} />
                                    <TABLE.TH name='type_lait'
                                        label="Type de lait"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('type_lait') }} />
                                    <TABLE.TH name='tank_code'
                                        label="N° Tank"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('tank_code') }} />
                                    <TABLE.TH name='laboratoire'
                                        label="Laboratoire"
                                        sort={{ orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('laboratoire') }} />
                                    <TABLE.TH actions>Action</TABLE.TH>
                                </TABLE.TR>
                            </TABLE.THEAD>
                            <TABLE.TBODY>
                                {this.state.productions.map((production: any) => (
                                    <TABLE.TR key={production.id} title={production.nom_exploitant}>
                                        <TABLE.TD fixed={{ left: 0 }} hiddenMobile><strong>{production.nom_exploitant}</strong></TABLE.TD>
                                        <TABLE.TD label="Numéro de SIRET">{production.siret_exploitant}</TABLE.TD>
                                        <TABLE.TD label="Centre d\'appartenance">{production.centre_appartenance}</TABLE.TD>
                                        <TABLE.TD label="Type de lait">
                                            {production.type_lait_espece}
                                            <br />
                                            {production.type_lait_nature}
                                        </TABLE.TD>
                                        <TABLE.TD label="N° Tank">{production.tank_code}</TABLE.TD>
                                        <TABLE.TD label="Laboratoire">{production.laboratoire}</TABLE.TD>
                                        <TABLE.TD actions>
                                            <Link className='action-btn' to={{ pathname: `/resultats/production/${production.prd_id}/${production.id}`, search: this.paramsFromPeriode() }} target="_blank"><i className="icon-eye"></i></Link>
                                        </TABLE.TD>
                                    </TABLE.TR>
                                ))}
                            </TABLE.TBODY>
                        </TABLE>
                        <SeeMoreButton behaviour="scroll-mobile" paginate={this.state.paginate} onClick={() => this.getProductions(true)} disabled={this.state.isLoading} />
                    </>)}
                </>)}
            </>
        )
    }
}

export default withTranslation()(withRouter(ResultatsProductionList));
