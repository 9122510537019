export enum STATUTS_SUIVI_PUSH_DETAIL {
    TRANSMIS = 'TRANSMIS',
    EC = 'EC',
    GENERE = 'GENERE',
    KO = 'KO',
    KO_TRANSMISSION = 'KO_TRANSMISSION',
}

export const STATUTS_SUIVI_PUSH_DETAIL_LABEL: {[key: string]: string} = {
    [STATUTS_SUIVI_PUSH_DETAIL.EC]: 'En cours',
    [STATUTS_SUIVI_PUSH_DETAIL.KO]: 'KO',
    [STATUTS_SUIVI_PUSH_DETAIL.TRANSMIS]: 'OK',
}

export const STATUTS_SUIVI_PUSH_DETAIL_OPTIONS = Object.entries(STATUTS_SUIVI_PUSH_DETAIL_LABEL).map(([value, label]) => ({
    value, label
}))