import SimpleReactValidator from "simple-react-validator"
import luhn from 'luhn'

export const initValidator = () => new SimpleReactValidator({
    locale: 'fr',
    validators,
    messages: customMessages,
    autoForceUpdate: false
})

const validators = {
    siren: {
        message: 'Le n° SIREN n’est pas valide.',
        rule: (val: any) => val.length === 9 ? luhn.validate(val) : true
    },
    nic: {
        message: 'Le nic n’est pas valide.',
        rule: (val: any) => val.length === 5 ? val[0] === '0' : true
    },
    ede: {
        message: 'Le n° EDE doit comporter 8 caractères.',
        rule: (val: any) => val.length === 8
    },
    number: {
        message: 'Le champ :attribute doit être un nombre.',
        rule: (val: any) => /^-?\d+([.,]\d+)?$/.test(val)
    },
    selected: {
        message: 'Le champ :attribute est requis.',
        rule: (val: any) => !!val
    },
    atLeastOne: {
        message: 'Au moins une catégorie de résultats doit être sélectionnée.',
        rule: (val: any) => !!val
    },
    indicatif: {
        message: 'L\'indicatif téléphonique doit être renseigné.',
        rule: (val: any) => {
            const phoneArr = val.split('|');
            const phoneCode = phoneArr[0].trim();
            const phoneNumber = phoneArr[1].trim();
            return !phoneNumber || (!!phoneNumber && !!phoneCode);
        }
    }
}

const customMessages = {
    accepted: 'Le champ :attribute doit être accepté.',
    after: 'Le champ :attribute doit être après :date.',
    after_or_equal: 'Le champ :attribute doit correspondre ou bien être après :date.',
    alpha: 'Le champ :attribute ne peut contenir que des lettres.',
    alpha_space: 'Le champ :attribute ne peut contenir que des lettres et des espaces.',
    alpha_num: 'Le champ :attribute ne peut contenir que des lettres et des chiffres.',
    alpha_num_space: 'Le champ :attribute ne peut contenir que des lettres, chiffres, et espaces.',
    alpha_num_dash: 'Le champ :attribute ne peut contenir que des lettres, chiffres, et tirets.',
    alpha_num_dash_space: 'Le champ :attribute ne peut contenir que des lettres, chiffres, tirets, et espaces.',
    array: 'Le champ :attribute doit êttre un tableau.',
    before: 'Le champ :attribute doit être avant :date.',
    before_or_equal: 'Le champ :attribute doit correspondre ou bien être avant :date.',
    between: 'Le champ :attribute doit comporter entre :min et :max caractères.',
    "boolean": 'Le champ :attribute doit être booléen.',
    card_exp: "Le champ :attribute doit être une date d'expiration valide.",
    card_num: 'Le champ :attribute doit être un numéro valide de carte de crédit .',
    currency: 'Le champ :attribute doit être une devise valide.',
    date: 'Le champ :attribute doit être une date.',
    date_equals: 'Le champ :attribute doit correspondre à :date.',
    email: 'Le champ :attribute doit êre une adresse email valide.',
    "in": 'Le champ selectionné :attribute n\'accepte que les valeurs :values.',
    integer: 'Le champ :attribute doit être un entier.',
    max: 'Le champ :attribute ne doit pas dépasser :max caractères.',
    min: 'Le champ :attribute doit au moins être :min caractères.',
    not_in: 'Le champ selectionné :attribute ne peut pas avoir les valeurs :values.',
    not_regex: 'Le champ :attribute n\'a pas le format attendu.',
    numeric: 'Le champ :attribute doit être un chiffre.',
    phone: 'Le champ :attribute doit être un numéro de téléphone valide.',
    regex: 'Le champ :attribute n\'a pas le format attendu.',
    required: 'Le champ :attribute est requis.',
    size: 'Le champ :attribute doit comporter :size caractères.',
    string: 'Le champ :attribute doit être une chaîne de caractères.',
    "typeof": "Le champ :attribute doit correspondre au type :type.",
    url: 'Le champ :attribute n\'est pas une URL valide.'
}