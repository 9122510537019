export enum STATUTS_SUIVI_EXPORT {
    OK = 'OK',
    EC = 'EC',
    KO = 'KO',
    TRANSMIS = 'TRANSMIS',
    GENERE = 'GENERE',
    KO_TRANSMISSION = 'KO_TRANSMISSION',
}

export enum STATUT_SUIVI_EXPORT_DISPLAY {
    TERMINE = 'Terminé',
    EN_COURS = 'En cours',
    ERREUR = 'Erreur',
}

export const STATUTS_SUIVI_EXPORT_LABEL: {[key: string]: string} = {
    [STATUTS_SUIVI_EXPORT.OK]: STATUT_SUIVI_EXPORT_DISPLAY.TERMINE,
    [STATUTS_SUIVI_EXPORT.GENERE]: STATUT_SUIVI_EXPORT_DISPLAY.TERMINE,
    [STATUTS_SUIVI_EXPORT.EC]: STATUT_SUIVI_EXPORT_DISPLAY.EN_COURS,
    [STATUTS_SUIVI_EXPORT.KO]: STATUT_SUIVI_EXPORT_DISPLAY.ERREUR,
}

export const STATUTS_SUIVI_EXPORT_OPTIONS_ANNU = Object.entries(STATUTS_SUIVI_EXPORT_LABEL)
    .filter(([value, label]) => value !== STATUTS_SUIVI_EXPORT.OK)
    .map(([value, label]) => ({value, label}))

export const STATUTS_SUIVI_EXPORT_OPTIONS_RES = Object.entries(STATUTS_SUIVI_EXPORT_LABEL)
    .filter(([value, label]) => value !== STATUTS_SUIVI_EXPORT.GENERE)
    .map(([value, label]) => ({value, label}))
