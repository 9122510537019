import CustomComponent from "../../../components/CustomComponent";
import CreateAbonnement from "./create";

class DetailsAbonnement extends CustomComponent {
    render () {
        const id = this.props.match.params.id;
        return(<CreateAbonnement abonnementId={id} isDetailsPage={true} title="Consultation d'un abonnement"/>);
    }
}

export default DetailsAbonnement;