import saveAs from "file-saver";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo_infolabo from "../../assets/images/logo_header.svg";
import Modal from "../../components/Modal";
import { Request, updateUnreadAnnonces } from "../../helpers/helpers";

interface AnnonceDetailsModalProps {
    annonceId: any;
    onClose: (event: React.MouseEvent) => void;
}

export const AnnonceDetailsModal = ({ annonceId, onClose }: AnnonceDetailsModalProps) => {
    const { t } = useTranslation();
    const [annonce, setAnnonce] = useState<any>(null);

    useEffect(() => {
        if (annonceId) {
            Request(`/inf_annonce/${annonceId}`, 'GET').then((data) => {
                if (data?.statusCode === 200) {
                    setAnnonce(data.annonce);
                    updateUnreadAnnonces();
                }
            });
        }
    }, [annonceId]);

    const downloadFile = async (event: React.MouseEvent, id: number, fileName: string) => {
        event.preventDefault();

        const blob: Blob = await Request(`/inf_annonce/download/${id}`, 'GET', undefined, false, false, true);
        if (blob instanceof Blob) {
            saveAs(blob, fileName);
        }
    }

    return (
        <Modal open={!!annonceId && !!annonce} customLayout size="lg" variant="data">
            <div className="annonce-details">
                <Modal.Header><h2 className="annonce-details__title">{annonce?.titre ?? ''}</h2></Modal.Header>
                <Modal.Body>
                    <div className="annonce-details-body">
                        <div className="annonce-details-body__files">
                            <img src={annonce?.src_image ?? logo_infolabo} alt={annonce?.nom_image ?? t('annonces.alt_default_img')} />
                            <ul>
                                {annonce?.attachments.map((file: { id: number, nom_fichier: string, src_fichier: string }) => (
                                    <li key={file.id}>
                                        <a
                                            title={file.nom_fichier}
                                            download={file.nom_fichier}
                                            href={file.src_fichier}
                                            target="_blank"
                                            rel="noreferrer"
                                            onAuxClick={(event: React.MouseEvent) => downloadFile(event, file.id, file.nom_fichier)}
                                            onClick={(event: React.MouseEvent) => downloadFile(event, file.id, file.nom_fichier)}
                                        >
                                            {file.nom_fichier}
                                        </a>
                                    </li>

                                ))}
                            </ul>
                        </div>
                        <div
                            className="annonce-details-body__text"
                            dangerouslySetInnerHTML={{ __html: annonce?.details ?? '' }}
                        >
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={onClose}>
                        {t('annonces.details.close')}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    )
}
