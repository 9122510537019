import { dateFormat } from '../../../../helpers/helpers'

interface MensuelTransFormedData {
    tableData: {
        mois_facturation: string[]
        criteres: { name: string, ordre_affichage: number, unite_mesure: string, double?: boolean, entries: { value: any, note: any }[] }[]
    },
    cardData: {
        mois_facturation: string,
        criteres: { name: string, ordre_affichage: number, unite_mesure: string, double?: boolean, entries: { value: any, note: any }[] }[]
    }[]
}
export const transformMensuelData = (resultats_mensuels: any[]): MensuelTransFormedData => {
    if (resultats_mensuels.length === 0) return {
        tableData: {
            mois_facturation: [],
            criteres: []
        },
        cardData: []
    }

    const tableData: any = {
        mois_facturation: [],
        criteres: [
            { name: 'Statut', ordre_affichage: -2, cri_id: -2, entries: [] },
            { name: 'Litrage', ordre_affichage: -1, cri_id: -1, unite_mesure: 'L', entries: [] },
        ]
    }

    const criteresMap: any = {};

    let cardData: any[] = []

    for (let res of resultats_mensuels) {
        const mois_facturation = res.mois_facturation

        let res_cardData: any = {
            mois_facturation: res.mois_facturation,
            fl_has_rej: res.fl_has_rej,
            criteres: [
                { mois_facturation, name: 'Statut', ordre_affichage: -2, cri_id: -2, value: 'M' },
                { mois_facturationname: 'Litrage', ordre_affichage: -1, cri_id: -1, value: '-' },
            ]
        }

        tableData.mois_facturation.push({mois: mois_facturation, fl_has_rej: res.fl_has_rej})

        for (let cri of res.criteres) {
            const { critere, unite_mesure, ordre_affichage, nb_decimales, cri_id } = cri

            criteresMap[cri_id] = {
                ...criteresMap[cri_id],
                name: critere,
                ...(unite_mesure && { unite_mesure: unite_mesure }),
                ...(Number.isInteger(ordre_affichage) && { ordre_affichage: ordre_affichage }),
                cri_id,
                ...(Number.isInteger(nb_decimales) && { nb_decimales: nb_decimales }),
                double: true,
                entries: []
            }

            res_cardData.criteres.push({
                mois_facturation,
                name: critere,
                unite_mesure,
                ordre_affichage,
                cri_id,
                nb_decimales,
                double: true,
                value: cri.valeur,
                hors_paiement: !cri.pour_paiement,
                hors_seuil: cri.hors_seuil,
                signe: res.signe,
                appreciation: cri.appreciation,
                displayBold: ['N', 'P'].includes(cri.visibilite),
                classe_mensuelle_paie: cri.classe_mensuelle_paie,
            })
        }

        cardData.push(res_cardData)
    }

    tableData.criteres = [...tableData.criteres, ...Object.values(criteresMap)]

    for (let res of resultats_mensuels) {
        const mois_facturation = res.mois_facturation

        for (let index in tableData.criteres) {
            const critere_name = tableData.criteres[index].name
            if (critere_name === 'Statut') {
                tableData.criteres[index].entries.push({ mois_facturation, value: 'M', note: '' })
                continue
            }
            if (critere_name === 'Litrage') {
                tableData.criteres[index].entries.push({ mois_facturation, value: '-', note: '' })
                continue
            }

            const cri = res.criteres.find((cri: any) => cri.critere === critere_name)
            tableData.criteres[index].entries.push(cri
                ? {
                    mois_facturation,
                    value: cri.valeur,
                    hors_paiement: !cri.pour_paiement,
                    hors_seuil: cri.hors_seuil,
                    signe: res.signe,
                    appreciation: cri.appreciation,
                    displayBold: ['N', 'P'].includes(cri.visibilite),
                    classe_mensuelle_paie: cri.classe_mensuelle_paie,
                }
                : { mois_facturation, value: '', note: '' }
            )
        }
    }

    tableData.criteres.sort((a: any, b: any) => (a.ordre_affichage - b.ordre_affichage))
    cardData = cardData.map((data => ({ ...data, criteres: data.criteres.sort((a: any, b: any) => a.ordre_affichage - b.ordre_affichage) })))

    return {
        tableData,
        cardData,
    }
}

interface JournalierTransFormedData {
    tableData: {
        dates: { id: number, date: string }[],
        criteres: { name: string, ordre_affichage: number, unite_mesure: string, double?: boolean, entries: { value: any, note: any }[] }[]
    },
    cardData: {
        id: number
        date: string,
        criteres: { name: string, ordre_affichage: number, unite_mesure: string, double?: boolean, entries: { value: any, note: any }[] }[]
    }[]
}
export const transformJournalierData = (echantillons: any[], orderBy?: string, ordering: 'DESC' | 'ASC' = 'DESC'): JournalierTransFormedData => {
    const tableData: any = {
        dates: [],
        criteres: [
            { name: 'Statut', ordre_affichage: -2, cri_id: -2, entries: [] },
            { name: 'Litrage', ordre_affichage: -1, cri_id: -1, unite_mesure: 'L', entries: [] },
        ]
    };

    const criteresMap: any = {};

    let cardData: any[] = [];

    if (orderBy) {
        const order: number = ordering === 'DESC' ? 1 : -1;
        echantillons.sort((a, b) => {
            if (a[orderBy] > b[orderBy]) return -order;
            if (a[orderBy] < b[orderBy]) return order;
            return 0;
        })
    }

    for (let ech of echantillons) {
        const date = dateFormat(ech.date_prelevement, 'DD/MM/YY');

        let ech_cardData: any = {
            id: ech.id,
            date,
            criteres: [
                { name: 'Statut', ordre_affichage: -2, cri_id: -2, value: ech.statut },
                { name: 'Litrage', ordre_affichage: -1, cri_id: -1, value: ech.litrage },
            ]
        };

        tableData.dates.push({ id: ech.id, date });


        for (let res of ech.resultats) {
            const { critere, unite_mesure, ordre_affichage, nb_decimales, cri_id } = res;

            criteresMap[cri_id] = {
                ...criteresMap[cri_id],
                name: critere,
                ...(unite_mesure && { unite_mesure: unite_mesure }),
                ...(Number.isInteger(ordre_affichage) && { ordre_affichage: ordre_affichage }),
                cri_id,
                ...(Number.isInteger(nb_decimales) && { nb_decimales: nb_decimales }),
                double: true,
                entries: []
            };

            ech_cardData.criteres.push({
                rej_id: res.id,
                name: critere,
                unite_mesure,
                ordre_affichage,
                cri_id,
                nb_decimales,
                double: true,
                value: res.valeur,
                note: res.note,
                hors_paiement: res.hors_paiement,
                hors_seuil: res.hors_seuil,
                signe: res.signe,
                appreciation: res.appreciation,
                statut: res.statut,
                displayBold: ['N', 'P'].includes(res.visibilite) || ['N', 'P'].includes(ech.visibilite)
            });
        }

        cardData.push(ech_cardData);
    }

    tableData.criteres = [...tableData.criteres, ...Object.values(criteresMap)];

    for (let ech of echantillons) {
        for (let index in tableData.criteres) {
            const critere_name = tableData.criteres[index].name;
            if (critere_name === 'Statut') {
                tableData.criteres[index].entries.push({ id: ech.id, value: ech.statut, note: '' });
                continue;
            }
            if (critere_name === 'Litrage') {
                tableData.criteres[index].entries.push({ id: ech.id, value: ech.litrage, note: '' });
                continue;
            }
            const res = ech.resultats.find((res: any) => res.critere === critere_name);
            tableData.criteres[index].entries.push(res
                ? {
                    id: ech.id,
                    rej_id: res.id,
                    value: res.valeur,
                    note: res.note,
                    hors_paiement: res.hors_paiement,
                    hors_seuil: res.hors_seuil,
                    signe: res.signe,
                    appreciation: res.appreciation,
                    statut: res.statut,
                    displayBold: ['N', 'P'].includes(res.visibilite) || ['N', 'P'].includes(ech.visibilite)
                }
                : { id: ech.id, value: '', note: '' }
            );
        }
    }

    tableData.criteres.sort((a: any, b: any) => (a.ordre_affichage - b.ordre_affichage));
    cardData = cardData.map((data => ({ ...data, criteres: data.criteres.sort((a: any, b: any) => a.ordre_affichage - b.ordre_affichage) })));

    return {
        tableData,
        cardData,
    };
}