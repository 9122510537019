import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import Panel from "../../../components/Panel";
import { getSession, hasPermission } from "../../../helpers/helpers";
import AddRegroupementModal from "./_addRegroupementModal";

class RegroupementsAssociesPanel extends CustomComponent {
    private _isMounted = false;

    constructor(props: any) {
        super(props);

        this.state = {
            regroupements: [],
            deleteTarget: null,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getRegroupements();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getRegroupements = async () => {
        const data = await this.request(`/ent_regroupement/findByEntity/${this.props.entityId}`, 'GET');
        if (data?.statusCode === 200 && this._isMounted) {
            this.setState({regroupements: data.regroupements});
        }
    }

    handleNewRegroupements = (newRegroupements: any) => {
        this.setState((prev: any) => ({regroupements: [...prev.regroupements, ...newRegroupements]}));
    }

    render() {
        return (
            <Panel title="Regroupements associés" togglableID="panel-rgp" open={true}>
                <div className="row">
                    {(this.props.isVisualization && !this.state.regroupements?.length) && (
                        <div className="d-flex w-100 justify-content-center">
                            <span>Aucun regroupement n'est associé à cette entité</span>
                        </div>
                    )}
                    {this.state.regroupements.map((regroupement: any) => (
                        <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30" key={regroupement.id}>
                            <div className="info-card">
                                <h4 className="info-card__title"><Link target="_blank" to={`/annuaire/regroupement/${regroupement.id}`}>{regroupement.libelle}</Link></h4>
                                <span>{regroupement.maitre.siret}</span>
                                <dl className="info-card__info">
                                    <dt>Nom de l'entité maître :</dt>
                                    <dd>{regroupement.maitre.nom_usuel}</dd>
                                </dl>
                                {(!this.props.isVisualization && getSession().ent_id === regroupement.maitre.id) && (
                                    <Dropdown>
                                        <Dropdown.Toggle bsPrefix="btn-icon" childBsPrefix="btn-icon--primary ml-auto">
                                            <i className="icon-actions"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Link to={`/annuaire/regroupement/${regroupement.id}`} target="_blank" className="dropdown-item">Consulter la fiche regroupement</Link>
                                            {hasPermission('DRT_ANNU_ENT_REGROUP_GERER') && (
                                                <Link to={`/annuaire/regroupement/${regroupement.id}/modifier`} target="_blank" className="dropdown-item">Modifier le regroupement</Link>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </div>
                        </div>
                    ))}
                    {(!this.props.isVisualization && hasPermission('DRT_ANNU_ENT_REGROUP_GERER')) && (
                        <div className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30">
                            <AddRegroupementModal
                                entityId={this.props.entityId}
                                handle={this.handleNewRegroupements}
                            />
                        </div>
                    )}
                </div>
            </Panel>
        )
    }
}

export default withTranslation()(withRouter(RegroupementsAssociesPanel));