export const getCustomTheme = (width: number):any  => {
  let factor: number = 1.2
  if (width > 768) factor = 0.8
  if (width > 992) factor = 0.6
  if (width > 1200) factor = 0.5
  if (width > 1630) factor = 0.4

  // Colors
  const colorCharts1 = "#00AEE7";
  const colorCharts2 = "#0C7FB8";
  const colorGreen = "#6BC159"
  const colorText = "#707070";
  const colorWhite = "#FFFFFF"
  const colorLight = "#f0f0f0";
  const colorDark = "#495057";
  const grey900 = "#212121";

  const colors = [colorCharts1, colorCharts2];

  // Typography
  const fontFamily = "Arial, Helvetica, sans-serif";
  const letterSpacing = "normal";
  const fontSize = 7 * factor;

  // Layout
  const padding = 8 * factor;
  const baseProps = {
    width: 320,
    height: width > 991 ? 120 : 280,
    padding: {
      top: 10 * factor,
      bottom: 40 * factor,
      right: 30 * factor,
      left: 30 * factor,
    },
  };

  // * Labels
  const baseLabelStyles = {
    fontFamily: fontFamily,
    fontSize,
    letterSpacing,
    padding: 0,
    fill: colorText,
    stroke: "transparent",
    strokeWidth: 0,
  };

  const centeredLabelStyles = Object.assign({ textAnchor: "middle" }, baseLabelStyles);

  // Strokes
  const strokeWidth = 0.6;
  const strokeDasharray = "10, 5";
  const strokeLinecap = "round";
  const strokeLinejoin = "round";

  const customTheme = {
    area: Object.assign(
      {
        style: {
          data: {
            fill: "grey900",
          },
          labels: baseLabelStyles,
        },
      },
      baseProps
    ),
    axis: Object.assign(
      {
        tickCount: 10,
        style: {
          axis: {
            fill: "transparent",
            stroke: colorDark,
            strokeWidth: 2,
            strokeLinecap,
            strokeLinejoin,
          },
          axisLabel: Object.assign({}, centeredLabelStyles, {
            padding,
            stroke: "transparent",
          }),
          grid: {
            fill: "none",
            stroke: colorLight,
            strokeDasharray,
            strokeLinecap,
            strokeLinejoin,
            pointerEvents: "painted",
          },
          ticks: {
            fill: "transparent",
            size: 5 * factor,
            stroke: colorDark,
            strokeWidth: 1,
            strokeLinecap,
            strokeLinejoin,
          },
          tickLabels: Object.assign({}, baseLabelStyles, {
            fill: colorText,
          }),
        },
      },
      baseProps
    ),
    polarDependentAxis: Object.assign({
      style: {
        ticks: {
          fill: "transparent",
          size: 1,
          stroke: "transparent",
        },
      },
    }),
    bar: Object.assign(
      {
        style: {
          data: {
            fill: colorText,
            padding,
            strokeWidth: 0,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps
    ),
    boxplot: Object.assign(
      {
        style: {
          max: { padding, stroke: colorText, strokeWidth: 1 },
          maxLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
          median: { padding, stroke: colorText, strokeWidth: 1 },
          medianLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
          min: { padding, stroke: colorText, strokeWidth: 1 },
          minLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
          q1: { padding, fill: colorText },
          q1Labels: Object.assign({}, baseLabelStyles, { padding: 3 }),
          q3: { padding, fill: colorText },
          q3Labels: Object.assign({}, baseLabelStyles, { padding: 3 }),
        },
        boxWidth: 20,
      },
      baseProps
    ),
    candlestick: Object.assign(
      {
        style: {
          data: {
            stroke: colorText,
          },
          labels: Object.assign({}, baseLabelStyles, { padding: 5 }),
        },
        candleColors: {
          positive: "#ffffff",
          negative: colorText,
        },
      },
      baseProps
    ),
    chart: baseProps,
    errorbar: Object.assign(
      {
        borderWidth: 8,
        style: {
          data: {
            fill: "transparent",
            opacity: 1,
            stroke: colorText,
            strokeWidth: 2,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps
    ),
    group: Object.assign(
      {
        colorScale: colors,
      },
      baseProps
    ),
    histogram: Object.assign(
      {
        style: {
          data: {
            fill: colorText,
            stroke: grey900,
            strokeWidth: 2,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps
    ),
    legend: {
      colorScale: colors,
      gutter: 10 * factor,
      orientation: "horizontal",
      titleOrientation: "top",
      style: {
        data: {
          type: "square",
        },
        labels: baseLabelStyles,
        title: Object.assign({}, baseLabelStyles, { padding: 5 }),
      },
    },
    line: Object.assign(
      {
        style: {
          data: {
            fill: "transparent",
            opacity: 1,
            stroke: colorText,
            strokeWidth: strokeWidth,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps
    ),
    pie: Object.assign(
      {
        colorScale: colors,
        style: {
          data: {
            padding,
            stroke: colorLight,
            strokeWidth: 1,
          },
          labels: Object.assign({}, baseLabelStyles, { padding: 20 }),
        },
      },
      baseProps
    ),
    scatter: Object.assign(
      {
        style: {
          data: {
            fill: colorText,
            opacity: 1,
            stroke: "transparent",
            strokeWidth: 0,
          },
          labels: baseLabelStyles,
        },
      },
      baseProps
    ),
    stack: Object.assign(
      {
        colorScale: colors,
      },
      baseProps
    ),
    tooltip: {
      style: Object.assign({}, baseLabelStyles, {
        padding: 0,
        fill: colorWhite,
        pointerEvents: "none"
      }),
      flyoutStyle: {
        strokeWidth: 0,
        fill: colorGreen,
        pointerEvents: "none",
      },
      flyoutPadding: 5 * factor,
      cornerRadius: 1,
      pointerWidth: 10 * factor,
      pointerLength: 5 * factor,
    },
    voronoi: Object.assign(
      {
        style: {
          data: {
            fill: "transparent",
            stroke: "transparent",
            strokeWidth: 0,
          },
          labels: Object.assign({}, baseLabelStyles, { padding: 5, pointerEvents: "none" }),
          flyout: {
            stroke: grey900,
            strokeWidth: 1,
            fill: "#f0f0f0",
            pointerEvents: "none",
          },
        },
      },
      baseProps
    ),
  }

  const axisBottomStyle = {
    tickLabels: {
      fontSize: 6 * factor,
      angle: -45,
      verticalAnchor: 'middle',
      textAnchor: 'end'
    }
  }

  return [customTheme, axisBottomStyle]
}