import {useEffect, useState} from "react";
import { Modal as BootstrapModal } from 'react-bootstrap';
import { FileUpload } from './FormComponents';

export const FileUploadModal = ({ open, title, onClose, handle, validation, types, maxSize, info }: any) => {
    const [file, setFile] = useState <File|null>(null)

    const onFileChange = (f: File|null) => {
        if (f) setFile(f);
    }

    const onFormSubmit = (e: any) => {
        e.preventDefault()

        if (handle) handle(file)
    }

    useEffect(() => {
        if (!open) {
            setFile(null);
        }
    }, [open]);

    return (
        <BootstrapModal show={open} onHide={onClose} className="modal--primary modal--md" centered>
            <form onSubmit={onFormSubmit}>
                <BootstrapModal.Header style={info && {display: "block"}}>
                    <BootstrapModal.Title>{title}</BootstrapModal.Title>
                    {info && <div>{info}</div>}
                </BootstrapModal.Header>
                <BootstrapModal.Body>
                    <FileUpload id="file_upload" name="file_upload" handle={onFileChange} validation={validation} types={types} maxSize={maxSize} />
                </BootstrapModal.Body>
                <BootstrapModal.Footer>
                    <button
                        type="button"
                        onClick={onClose}
                        className="btn btn-white">Annuler</button>
                    <button
                        disabled={!file}
                        type="submit"
                        className="btn btn-secondary">Importer</button>
                </BootstrapModal.Footer>
            </form>
        </BootstrapModal>
    );
}