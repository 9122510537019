import saveAs from "file-saver";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo_infolabo from "../../assets/images/logo_header.svg";
import Modal from "../../components/Modal";
import { Request } from "../../helpers/helpers";

interface AnnoncePreviewModalProps {
    isOpen: boolean;
    onClose: (event: React.MouseEvent) => void;
    annonce?: any;
    annonceId?: string;
}

export const AnnoncePreviewModal = ({ isOpen, onClose, annonce, annonceId }: AnnoncePreviewModalProps) => {
    const { t } = useTranslation();
    const [imageObjectUrl, setImageObjectUrl] = useState<string | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [attachmentFiles, setAttachmentFiles] = useState<{ name: string, url: string | null }[]>([]);

    useEffect(() => {
        // handle image update
        if (annonce.image !== imageFile) {
            if (imageObjectUrl) URL.revokeObjectURL(imageObjectUrl);
            if (annonce.image?.size > 0) {
                setImageObjectUrl(URL.createObjectURL(annonce.image));
                setImageFile(annonce.image);
            }
            else {
                setImageObjectUrl(null);
                setImageFile(null);
            }
        }

        // clean up
        return () => {
            if (imageObjectUrl) URL.revokeObjectURL(imageObjectUrl);
        }
    }, [annonce.image, imageFile, imageObjectUrl]);

    useEffect(() => {
        // handle attachments update
        const attachments = annonce.attachments?.map((attachment: File) => attachment.name);
        const currentAttachments = attachmentFiles?.map((attachment: { name: string, url: string | null }) => attachment.name);

        const removedFiles: { name: string, url: string | null }[] = attachmentFiles?.filter((file) => !attachments?.includes(file.name));
        const addedFiles: File[] = annonce.attachments?.filter((file: File) => !currentAttachments?.includes(file.name));

        for (const removedFile of removedFiles) {
            if (removedFile.url) URL.revokeObjectURL(removedFile.url);
            setAttachmentFiles((prev) => prev.filter((file) => file.name !== removedFile.name));
        }

        for (const addedFile of addedFiles ?? []) {
            const fileUrl = URL.createObjectURL(addedFile);
            setAttachmentFiles((prev) => ([...prev, { name: addedFile.name, url: fileUrl }]));
        }

        // clean up
        return () => {
            for (const file of attachmentFiles) {
                if (file.url) URL.revokeObjectURL(file.url);
            }
        }

    }, [annonce.attachments, attachmentFiles]);

    const downloadFile = async (event: React.MouseEvent, fileName: string) => {
        event.preventDefault();

        const blob: Blob = await Request(`/inf_annonce/download/${annonceId}/${fileName}`, 'GET', undefined, false, false, true);
        if (blob && blob instanceof Blob) {
            saveAs(blob, fileName);
        }

    }

    return (
        <Modal open={isOpen} customLayout size="lg" variant="data">
            <div className="annonce-details">
                <Modal.Header><h2 className="annonce-details__title">{annonce.titre}</h2></Modal.Header>
                <Modal.Body>
                    <div className="annonce-details-body">
                        <div className="annonce-details-body__files">
                            <img src={imageObjectUrl || (annonce.image && annonce.src_image) || logo_infolabo} alt={annonce.image?.name ?? t('annonces.alt_default_img')} />
                            <ul>
                                {attachmentFiles?.map((file: { name: string, url: string | null }) => (
                                    <li key={file.name}>
                                        <a
                                            title={file.name}
                                            download={file.name}
                                            href={file.url ?? ''}
                                            onClick={!file.url ? (event) => downloadFile(event, file.name) : undefined}
                                        >
                                            {file.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div
                            className="annonce-details-body__text"
                            dangerouslySetInnerHTML={{ __html: annonce.details }}
                        >
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={onClose}>
                        {t('annonces.details.close')}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    )
}
