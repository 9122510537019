import Accueil from './Accueil';
import CreateExportPonctuel from './CreateExportPonctuel';
import Imports from './Imports';
import Exports from './Exports';

const ImportExportResultat = {
    Accueil,
    CreateExportPonctuel,
    Imports,
    Exports
}

export default ImportExportResultat;