import { useEffect, useState } from "react"

const useWindowSize = () => {
    const [size, setSize] = useState({
        width: 0,
        height: 0,
        isMobile: false
    });
    const handleResize = () => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobile: window.innerWidth < 768
        });
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return size;
}

export default useWindowSize;