import { default as jwt_decode } from 'jwt-decode';
import { withTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import logo_infolabo from '../../assets/images/logo_infolabo.svg';
import CustomComponent from '../../components/CustomComponent';
import { FormInput } from '../../components/FormComponents';
import { LoginLayout } from '../../components/Layouts';
import Modal from '../../components/Modal';
import { TOAST_TYPE, sendToast } from '../../helpers/helpers';
import { validateEmail, validatePassword, validatePasswordMatch } from '../../helpers/validation';


interface RouteParams { token?: string }

class ResetPassword extends CustomComponent<RouteComponentProps<RouteParams>, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            reset_password: {
                identifiant: localStorage.getItem('identifiant') || "",
                token: this.props.match.params.token || "",
                new_password: "",
                conf_password: "",
            },
            tokenExpired: false,
            isModalVisible: false,
            modalMsg: "",
            modalRoute: "",
            isSubmitted: false
        }
    }

    componentDidMount() {
        const { t } = this.props;
        document.title = 'Infolabo | ' + t("reset_password.title");
        this.checkIsConnectedPWA();
        this.checkTokenValidity();
    }

    checkTokenValidity() {
        const token = this.state.reset_password.token;
        if (!token) return;
        let decoded_token: any = "";
        try {
            decoded_token = jwt_decode(token);
        } catch (error) {
            this.setState({ tokenExpired: true });
            return;
        }
        const timestamp_now = Math.round(Date.now() / 1000);
        if ('exp' in decoded_token && timestamp_now < decoded_token.exp) {
            this.setState({ tokenExpired: false });
        }
        else {
            this.setState({ tokenExpired: true });
        }
    }

    async afterSubmissionPass(event: React.FormEvent) {
        event.preventDefault();
        const { t } = this.props;
        // display input error when first submit
        this.setState({ isSubmitted: true });
        const new_password: string = this.state.reset_password.new_password;
        const conf_password: string = this.state.reset_password.conf_password;
        if (new_password.trim() !== "" && conf_password.trim() !== "") {
            if (new_password === conf_password) {
                let url = "/auth/reset_password";
                let method = "POST";
                let body = { reset_token: this.state.reset_password.token, mdp: new_password };
                const data = await this.request(url, method, body);
                // if (data && data?.statusCode === 200)
                if (data && !('statusCode' in data))
                    this.setState({ modalMsg: data.message, modalRoute: "/login", isModalVisible: true });
                else if (data && data?.statusCode !== 200)
                    this.setState({ modalMsg: data.message, modalRoute: "/password/reset/" + this.state.reset_password.token, isModalVisible: false });
                else
                    this.setState({ modalMsg: t("reset_password.unabl_to_change_password"), modalRoute: "/password/reset/" + this.state.reset_password.token, isModalVisible: false });
            }
            else {
                sendToast(<p>{t('passwords_does_not_match')}</p>, TOAST_TYPE.ERROR);
            }
        }
        else {
            sendToast(<p>{t('new_pass_and_conf')}</p>, TOAST_TYPE.ERROR);
        }
    }

    async afterSubmissionEmail(event: React.FormEvent) {
        event.preventDefault();
        const { t } = this.props;
        // display input error when first submit
        this.setState({ isSubmitted: true });
        const identifiant: string = this.state.reset_password.identifiant;
        if (identifiant.trim() !== "") {
            let url = "/auth/reset_password";
            let method = "POST";
            let body = { mail_identifiant: this.state.reset_password.identifiant };
            let data = await this.request(url, method, body);
            if (data && data.message && !data.statusCode) {
                this.setState({ modalMsg: data.message, modalRoute: "/login", isModalVisible: true });
            }
        }
        else {
            sendToast(<p>{t('reset_password.enter_user_name')}</p>, TOAST_TYPE.ERROR);
        }
    }

    render() {
        const { t } = this.props;
        let validate_pass = validatePassword(this.state.reset_password.new_password, this.state.reset_password.conf_password);
        return (
            <LoginLayout>
                <main className="page-login__form">
                    <h1 className="page-login__title">
                        <span className="sr-only">InfoLabo</span>
                        <img src={logo_infolabo} alt="" />
                    </h1>
                    <p className="page-login__intro">{t("login.form_text")}</p>
                    {(this.props.match.params.token && !this.state.tokenExpired)
                        ? (
                            <>
                                <h2 className="page-login__action-title mb-30">{t("reset_password.title")}</h2>
                                <form onSubmit={(e) => this.afterSubmissionPass(e)}>
                                    <div className="form-group mb-20 mb-md-30">
                                        <FormInput
                                            label={t("reset_password.new_password")}
                                            id="nouveau-mdp"
                                            name="new_password"
                                            type="password"
                                            maxLength={255}
                                            handle={(e: any) => this.handleInputChange(e, null, 'reset_password')}
                                            value={this.state.reset_password.new_password}
                                            className="input-group--password"
                                            validation={() => validate_pass}
                                            isSubmitted={this.state.isSubmitted}
                                        />
                                    </div>
                                    <div className="form-group mb-20 mb-md-30">
                                        <FormInput
                                            label={t("reset_password.conf_password")}
                                            id="confirmation-mdp"
                                            name="conf_password"
                                            type="password"
                                            handle={(e: any) => this.handleInputChange(e, null, 'reset_password')}
                                            value={this.state.reset_password.conf_password}
                                            className="input-group--password"
                                            validation={(e: any) => validatePasswordMatch(e, this.state.reset_password.new_password)}
                                            isSubmitted={this.state.isSubmitted}
                                        />
                                    </div>
                                    <div className="form-text form-text-muted small">
                                        <p className="m-b-5">{t("reset_password.security_text")}</p>
                                        <p className={(validate_pass?.contains?.includes("lower")) ? "m-b-0 text-success" : "m-b-0"}>- {t("reset_password.lower")}</p>
                                        <p className={(validate_pass?.contains?.includes("upper")) ? "m-b-0 text-success" : "m-b-0"}>- {t("reset_password.upper")}</p>
                                        <p className={(validate_pass?.contains?.includes("special_char")) ? "m-b-0 text-success" : "m-b-0"}>- {t("reset_password.special_char")}</p>
                                        <p className={(validate_pass?.contains?.includes("number")) ? "m-b-0 text-success" : "m-b-0"}>- {t("reset_password.number")}</p>
                                    </div>
                                    <div className="mt-5 d-flex justify-content-center align-items-center">
                                        <Link to="/login" className="btn btn-link mx-10">{t("cancel")}</Link>
                                        <input className="btn btn-primary mx-10" type="submit" value={t("reset_password.reset")} />
                                    </div>
                                </form>
                            </>
                        )
                        : ((this.props.match.params.token && this.state.tokenExpired) ? (
                            <>
                                <h2 className="page-login__action-title mb-2">{t("reset_password.expired_link_msg")}</h2>
                                <div className="mt-5 d-flex justify-content-center align-items-center">
                                    <Link to="/password/reset" className="btn btn-link mx-10">{t("back")}</Link>
                                </div>
                            </>

                        )
                            :
                            (
                                <>
                                    <h2 className="page-login__action-title mb-2">{t("reset_password.title")}</h2>
                                    <p className="page-login__action-description mb-30">{t("reset_password.enter_user_name_msg")}</p>
                                    <form onSubmit={(e) => this.afterSubmissionEmail(e)}>
                                        <div className="form-group mb-20 mb-md-30">
                                            <FormInput
                                                label={t('login.login')}
                                                id="identifiant"
                                                name="identifiant"
                                                type="email"
                                                handle={(e: any) => this.handleInputChange(e, null, 'reset_password')}
                                                value={this.state.reset_password.identifiant}
                                                validation={validateEmail}
                                                helper={t('reset_password.info')}
                                                isSubmitted={this.state.isSubmitted}
                                            />
                                        </div>
                                        <div className="mt-5 d-flex justify-content-center align-items-center">
                                            <Link to="/login" className="btn btn-link mx-10">{t('cancel')}</Link>
                                            <input className="btn btn-primary mx-10" type="submit" value={t("reset_password.reset")} />
                                        </div>
                                    </form>
                                </>
                            )
                        )
                    }
                </main>
                <Modal
                    open={this.state.isModalVisible}
                    title="Réinitialiser votre mot de passe"
                    text_button="J'ai compris"
                    handle={() => { this.props.history.push(this.state.modalRoute); }}
                >
                    <p>{this.state.modalMsg}</p>
                </Modal>
            </LoginLayout>
        )
    }
}

export default withTranslation()(withRouter(ResetPassword));
