import { Modal as BootstrapModal } from 'react-bootstrap';
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { FormInputSelect } from '../../../components/FormComponents';
import Pagination from "../../../components/Pagination";
import { SortbyColumnButton } from '../../../components/TableComponents';
import { TYPES_DOMAINES } from "../../../enum/types_domaines";
import { DebounceQueue } from '../../../helpers/debouncer';
import { TOAST_TYPE, sendToast } from '../../../helpers/helpers';
import { initValidator } from "../../../validations/validations";

class AddEntiteModal extends CustomComponent {
    private _isMounted = false;
    private validator;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: 'nom_usuel',
        ordering: 'ASC',
        nom_usuel: '',
        siret: '',
        ville: ''
    }

    constructor(props: any) {
        super(props);
        this.state = {
            filters: this.defaultFilters,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            entites: [],
            selectedEntites: [],
            fonctions: this.props.fonctions.map((fonction: any) => ({ value: fonction.id, label: fonction.lib_fonction })),
            habFonc: {},
            step: 1,
            checked: [],
            submitted: false,
            openModal: false
        }

        this.validator = initValidator()
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== this.state.filters.orderBy || this.state.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getEntites, [], true))
    }

    getEntites = async (triggeredByPagination: boolean = false) => {
        const params = new URLSearchParams()

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key]
            if (val !== '') params.append(key, val)
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (val !== '') params.append(key, val)
        })

        params.append('domain', TYPES_DOMAINES.INTERNE);
        params.append('exclude', this.props.persEntites.map((m: any) => m.id))

        if (!triggeredByPagination) params.set('page', '0')

        const data = await this.request(`/ent_entite?${params.toString()}`, 'GET');

        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                entites: data.entites,
                paginate: {
                    ...prev.paginate,
                    ...data.paginate
                }
            }))
        }

    }

    associateEntites = async () => {
        const { t } = this.props

        this.setState({ submitted: true })
        if (this.validator.allValid()) {
            if (!this.props.personneId) {
                const newHabs = Object.entries(this.state.habFonc).map(([key, values]: any) => ({
                    ent_id: this.state.selectedEntites.find((ent: any) => ent.id === +key),
                    hab_code: this.state.habilitations[key].find((hab: any) => hab.code_hab === values.habilitation),
                    fon_id: this.props.fonctions.find((fon: any) => fon.id === values.fonction)
                }));
                this.props.handle && this.props.handle(newHabs);

                this.setState({
                    checked: [],
                    habFonc: {},
                    habilitations: [],
                    step: 1,
                    openModal: false,
                })
                this.props.handleOpen(false);
                return;
            }

            let BODY = {
                per_id: +this.props.personneId,
                ent_pers_link: this.state.selectedEntites.map((ent: any) => ({
                    ent_id: ent.id,
                    hab_code: this.state.habFonc[ent.id]?.habilitation,
                    fon_id: this.state.habFonc[ent.id]?.fonction,
                }))
            };

            const data = await this.request(`/per_habilitation/createEntitePersLink`, 'POST', BODY);

            if (data && this._isMounted) {
                this.props.handle && this.props.handle();

                this.setState({
                    checked: [],
                    habFonc: {},
                    habilitations: [],
                    step: 1,
                    openModal: false,
                })
                this.props.handleOpen(false);
            }
        }
        else {
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
            this.validator.getErrorMessages()
        }
    }

    onClickStep2 = async () => {
        if (this.state.checked) {
            const body = { ids: this.state.checked };
            const data = await this.request('/ref_habilitation/findByClaID', 'POST', body)
            if (data && this._isMounted) this.setState({ habilitations: data })
        }
        this.validator.hideMessages()
        this.setState({ step: 2 })
    }

    deleteEntite = (id: number) => {
        this.setState((prev: any) => ({
            checked: prev.checked.filter((value: any) => value !== id),
            step: prev.checked.length === 1 ? 1 : prev.step,
            selectedEntites: prev.selectedEntites.filter((ent: any) => ent.id !== id)
        }))
    }

    handleCheck = (event: any) => {
        const { checked, value } = event.target
        const checkedEntites = checked
            ? [...this.state.checked, +value]
            : this.state.checked.filter((n: any) => n !== +value)

        this.setState((prev: any) => ({
            checked: checkedEntites,
            selectedEntites: checked
                ? [...prev.selectedEntites, ...prev.entites.filter((entite: any) => entite.id === +value)]
                : prev.selectedEntites.filter((entite: any) => checkedEntites.includes(entite.id))
        }))
    }


    handleCheckAll = (e: any) => {
        const { checked } = e.target
        const checkedEntites = checked
            ? [...this.state.checked, ...this.state.entites.map((entite: any) => entite.id)]
            : [...this.state.checked.filter((n: any) => !this.state.entites.map((entite: any) => entite.id).includes(n))]

        this.setState((prev: any) => ({
            checked: checkedEntites,
            selectedEntites: checked
                ? [...prev.selectedEntites, ...prev.entites.filter((entite: any) => checkedEntites.includes(entite.id))]
                : prev.selectedEntites.filter((entite: any) => checkedEntites.includes(entite.id))
        }))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getEntites, [true], true))
    }

    openModal = () => {
        this.setState({
            openModal: true,
            filters: this.defaultFilters,
            selectedEntites: []
        }, this.getEntites)
    }

    isAllChecked = () => {
        const visibleEntiteIDs = this.state.entites.map((e: any) => e.id)
        const checkedIDs = this.state.checked
        return visibleEntiteIDs.filter((e: any) => checkedIDs.includes(e)).length === visibleEntiteIDs.length
    }

    render() {
        // const visibleSelectedEntites =

        return <>
            <button
                type="button"
                className="info-card info-card--button"
                data-toggle="modal"
                data-target="#entity-modal"
                onClick={this.openModal}>Ajouter<i className="icon-add-circle"></i></button>
            <BootstrapModal show={this.state.openModal} className="modal--primary modal--lg" centered>
                <BootstrapModal.Body>
                    {(this.state.step === 1) &&
                        <div id="step_1">
                            <div className="modal-step">
                                <h2 className="modal-step__title">Associer une ou plusieurs entité(s)</h2>
                                <div className="modal-step__container">
                                    <ol className="modal-step__steps">
                                        <li className="active">Sélectionner les entités à associer</li>
                                        <li>Définir l’habilitation et la fonction</li>
                                    </ol>


                                    <div className="modal-step__actions">
                                        <button
                                            type="button"
                                            data-dismiss="modal"
                                            className="btn btn-white" onClick={() => this.setState({ openModal: false })}>Annuler</button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            id="btn_next"
                                            disabled={this.state.selectedEntites.length < 1}
                                            onClick={this.onClickStep2}>Suivant</button>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive desktop-only">
                                <table className="table table-custom table-blue">
                                    <thead>
                                        <tr>
                                            <th className="table-check">
                                                <input className="checkbox checkbox--white" type="checkbox" checked={this.isAllChecked()} onChange={this.handleCheckAll} />
                                            </th>
                                            <th>
                                                <div className="d-flex">
                                                    <span className="sr-only">Nom usuel</span>
                                                    <input
                                                        value={this.state.filters.nom_usuel}
                                                        name="nom_usuel"
                                                        type="text"
                                                        className="table-input"
                                                        placeholder='Nom usuel'
                                                        onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))} />
                                                    <SortbyColumnButton active={this.state.filters.orderBy === 'nom_usuel'} direction={this.state.filters.ordering} onClick={() => this.handleSort('nom_usuel')} />
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex">
                                                    <span className="sr-only">N° SIRET</span>
                                                    <input
                                                        value={this.state.filters.siret}
                                                        name="siret"
                                                        type="text"
                                                        className="table-input"
                                                        placeholder='N° SIRET'
                                                        onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))} />
                                                    <SortbyColumnButton active={this.state.filters.orderBy === 'siret'} direction={this.state.filters.ordering} onClick={() => this.handleSort('siret')} />
                                                </div>
                                            </th>
                                            <th>
                                                <div className="d-flex">
                                                    <span className="sr-only">Ville</span>
                                                    <input
                                                        value={this.state.siret}
                                                        name="ville"
                                                        type="text"
                                                        className="table-input"
                                                        placeholder='Ville'
                                                        onChange={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getEntites))} />
                                                    <SortbyColumnButton active={this.state.filters.orderBy === 'ville'} direction={this.state.filters.ordering} onClick={() => this.handleSort('ville')} />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.entites.map((entite: any, index: number) => (
                                            <tr key={index}>
                                                <td className="table-check">
                                                    <input className="checkbox" type="checkbox" checked={this.state.checked.includes(entite.id)} value={entite.id} id={`entite${entite.id}}`} onChange={this.handleCheck} />
                                                </td>
                                                <td>{entite.nom_usuel}</td>
                                                <td>{entite.siret}</td>
                                                <td>{entite.ville}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} secondary />
                        </div>
                    }
                    {(this.state.step === 2) &&
                        <div id="step_2">
                            <div className="modal-step">
                                <h2 className="modal-step__title">Associer une ou plusieurs entité(s)</h2>
                                <div className="modal-step__container">
                                    <ol className="modal-step__steps">
                                        <li onClick={() => this.setState({ step: 1 })}>Sélectionner les entités à associer</li>
                                        <li className="active">Définir l’habilitation et la fonction</li>
                                    </ol>
                                    <div className="modal-step__actions">
                                        <button type="button" className="btn btn-white" id="btn_prev" onClick={() => this.setState({ step: 1 })}>Annuler</button>
                                        <button type="button" className="btn btn-secondary" onClick={this.associateEntites}>Associer</button>
                                    </div>
                                </div>
                            </div>
                            {this.state.selectedEntites.map((entite: any, index: number) => (
                                <div className="relation-card" key={index}>
                                    <div className="relation-card__content">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <h4 className="label">{entite.nom_usuel}</h4>
                                                <ul className="relation-card__info">
                                                    <li>{entite.siret}</li>
                                                    <li>{entite.ville}</li>
                                                </ul>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label htmlFor={`habilitation${entite.id}`}>Habilitation*</label>
                                                    <FormInputSelect
                                                        label={`habilitation`}
                                                        displayLabel={false}
                                                        options={this.state.habilitations[entite.id]?.map((e: any) => ({ label: e.lib_hab, value: e.code_hab })).sort((a: any, b: any) => (a.label > b.label) ? 1 : -1) || []}
                                                        name={`habilitation--${entite.id}`}
                                                        id={`habilitation--${entite.id}`}
                                                        handle={(e: any) => this.setState((prev: any) => ({
                                                            habFonc: {
                                                                ...this.state.habFonc,
                                                                [entite.id]: {
                                                                    ...prev.habFonc[entite.id],
                                                                    habilitation: e.data.value
                                                                }
                                                            }
                                                        }))}
                                                        value={this.state.habFonc[entite.id]?.habilitation || ""}
                                                        isSubmitted={this.state.isSubmitted}
                                                        simpleValidator={this.validator}
                                                        required={true}
                                                        isClearable={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label htmlFor={`fonction${entite.id}`}>Fonction*</label>
                                                    <FormInputSelect
                                                        label={`fonction`}
                                                        displayLabel={false}
                                                        options={this.props.fonctions.map((fonction: any) => ({ value: fonction.id, label: fonction.lib_fonction }))}
                                                        name={`fonction--${entite.id}`}
                                                        id={`fonction--${entite.id}`}
                                                        handle={(e: any) => this.setState((prev: any) => ({
                                                            habFonc: {
                                                                ...this.state.habFonc,
                                                                [entite.id]: {
                                                                    ...prev.habFonc[entite.id],
                                                                    fonction: e.data.value
                                                                }
                                                            }
                                                        }))}
                                                        value={this.state.habFonc[entite.id]?.fonction || ""}
                                                        isSubmitted={this.state.isSubmitted}
                                                        simpleValidator={this.validator}
                                                        required={true}
                                                        isClearable={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relation-card__action">
                                        <button className="btn-icon btn-icon--secondary btn-icon--sm" onClick={() => this.deleteEntite(entite.id)}>
                                            <span className="sr-only">Supprimer</span>
                                            <i className="icon-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                </BootstrapModal.Body>
            </BootstrapModal>
        </>
    }
}

export default withTranslation()(withRouter(AddEntiteModal));
