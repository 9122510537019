import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb";
import CustomComponent from "../../../components/CustomComponent";
import { FormDatePicker, FormInput, FormInputSelect, FormRadioValues } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import Panel from "../../../components/Panel";
import { FORMAT_FICHIER, FORMAT_FICHIER_OPTIONS } from "../../../enum/format_fichier";
import { MEDIA, MEDIA_OPTIONS } from "../../../enum/media";
import { TOAST_TYPE, datePickerFormat, sendToast } from "../../../helpers/helpers";
import { initValidator } from "../../../validations/validations";

const export_ponctuel_default_values = {
    destinataire: '',
    mails: [''],
    email_destinataire: '',
    group: '',
    date_debut: '',
    date_fin: '',
    format: FORMAT_FICHIER.CSV,
    media: MEDIA.EN_LIGNE,
}

class CreateExportPonctuel extends CustomComponent {
    private _isMounted = false;
    private validator

    constructor(props: any) {
        super(props);

        let page_title = "Création d'un export ponctuel"

        this.state = {
            page_title: page_title,
            export_ponctuel: export_ponctuel_default_values,
            options: {
                destinataire: [],
                group: [],
                format: FORMAT_FICHIER_OPTIONS,
                media: MEDIA_OPTIONS,
            },
            display_email_destinataire_2: false,
            isLoading: false,
        }
        this.validator = initValidator()
    }

    componentDidMount(): void {
        this._isMounted = true;
        document.title = `Infolabo | ${this.state.page_title}`;
        this.getEntiteOptions();
        this.getGroupeOptions();
    }

    getEntiteOptions = async (e?: any, index?: number) => {
        let URL: string = `/exports/laiterie/destinataire`

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        }

        const data = await this.request(URL, 'GET')

        if (data && data.length) {
            const new_options = data.map((option: any) => ({ label: option.label, value: option.id, types_entites: option.types_entites, mail_entite: option.mail_entite }))

            this._isMounted && this.setState((prev: any) => ({
                options: {...prev.options, destinataire:new_options}
            }));
        }
    }

    getGroupeOptions = async (e?:any) => {
        let URL: string = `/ent_groupe/autocomplete`
        const params = new URLSearchParams()
        params.append('type_groupe', 'E')

        if (e) {
            if (e.action.action !== 'input-change') return
            params.append('query', e.data)
        }

        const data = await this.request(`${URL}?${params.toString()}`, 'GET')

        if (data && data.statusCode === 200) {
            const new_options = data.groupes.map((option: any) => ({label: option.nom, value: option.id}))

            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    group: new_options
                }
            }))
        }
    }

    handleMailingListChange(e: any) {
        const value = e.target.value
        const index = e.target.name.split('--').pop()

        this.setState((prev: any) => ({
            export_ponctuel: {
                ...prev.export_ponctuel,
                mails: prev.export_ponctuel.mails.map((email: string, i: number) => {
                    if (i === +index) return value;
                    return email
                })
            }
        }))
    }

    addEmailToMailingList = () => {
        this.setState((prev: any) => ({
            export_ponctuel: {
                ...prev.export_ponctuel,
                mails: [...prev.export_ponctuel.mails, '']
            }
        }))
    }

    removeEmailFromMailingList = (index: number) => {
        this.setState((prev: any) => ({
            export_ponctuel: {
                ...prev.export_ponctuel,
                mails: prev.export_ponctuel.mails.length === 1
                    ? ['']
                    : prev.export_ponctuel.mails.filter((_: any, i: number) => i !== +index)
            }
        }))
    }

    setEntiteDestinataireEmail = () => {
        const {destinataire, mails, email_destinataire} = this.state.export_ponctuel
        const firstMailOfMailingList = mails[0]

        const entite_destinataire = this.state.options.destinataire.find((option: any) => option.value === destinataire)
        const mail_entite = entite_destinataire?.mail_entite

        if (firstMailOfMailingList === email_destinataire || firstMailOfMailingList === '') mails.shift()
        if (mail_entite) mails.unshift(mail_entite)
        if (mails.length === 0) mails.push('')

        this.setState((prev: any) => ({
            export_ponctuel: {
                ...prev.export_ponctuel,
                mails,
                email_destinataire: mail_entite
            }
        }))
    }


    afterSubmission = async (e: any) => {
        e.preventDefault()
        const { t } = this.props;

        this._isMounted && this.setState({ isLoading: true, isSubmitted: true });

        if (this.validator.allValid()) {
            await this.createExportPonctuel()
        } else {
            this.validator.showMessages()
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }

        this._isMounted && this.setState({ isLoading: false });
    }

    createExportPonctuel = async () => {
        const URL = "/exports/laiterie/ponctuel";
        const body = this.state.export_ponctuel;

        const data = await this.request(URL, 'POST', body);

        if (data && data.statusCode === 200) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.props.history.push('/flux/imports_exports_resultats/exports');
        }
    }

    render() {
        const MEDIA_IS_MAIL = this.state.export_ponctuel.media === MEDIA.MAIL

        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }, { name: 'Imports et exports de résultats', path: '/flux/imports_exports_resultats/exports'}, {name: this.state.page_title}]} />
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="d-flex justify-content-between flex-wrap">
                            <h1 className="main-title m-r-50">{this.state.page_title}</h1>
                            <div className="m-b-30">
                                <Link to={`/flux/imports_exports_resultats`} className="btn m-r-5">Annuler</Link>
                                <button type="submit" className='btn btn-secondary'>Enregistrer</button>
                            </div>
                        </div>
                        <Panel title="Destinataire" togglableID='panel-1' open>
                            <div className="large-gutters">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormInputSelect
                                                label="Nom de l'entité / SIRET"
                                                name="destinataire"
                                                id="destinataire"
                                                simpleValidator={this.validator}
                                                required
                                                isClearable
                                                value={this.state.export_ponctuel.destinataire}
                                                handleInput={(e: any) => this.getEntiteOptions(e)}
                                                handle={(e: any) => this.handleInputChange(e, null, 'export_ponctuel', this.setEntiteDestinataireEmail)}
                                                options={this.state.options.destinataire}/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {this.state.export_ponctuel.mails.map((email: string, index: number) => (
                                            <div key={index} className={`form-group form-group--inline ${this.state.display_email_destinataire_2 ? 'm-b-30' : ''}`}>
                                                <FormInput
                                                    label={`Email ${index + 1}`}
                                                    name={`mails--${index}`}
                                                    id={`mails--${index}`}
                                                    value={MEDIA_IS_MAIL ? this.state.export_ponctuel.mails[index] : ''}
                                                    handle={(e: any) => this.handleMailingListChange(e)}
                                                    maxLength={70}
                                                    validator_type="email"
                                                    simpleValidator={this.validator}
                                                    required={this.state.has_email_source && index === 0}
                                                    onClear={() => this.removeEmailFromMailingList(index)}
                                                    disabled={!MEDIA_IS_MAIL}>
                                                    {!!email && MEDIA_IS_MAIL && index === this.state.export_ponctuel.mails.length - 1 && (
                                                        <button type="button" onClick={this.addEmailToMailingList} className="action-btn action-btn--light action-btn--large m-l-10">
                                                            <i className="icon-add"></i>
                                                        </button>
                                                    )}
                                                </FormInput>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Panel>
                        <Panel title="Données à transmettre" togglableID='panel-2' open>
                            <div className="large-gutters row">
                                <div className="col-12">
                                    <div className="form-group form-group--inline m-b-30">
                                        <FormInputSelect
                                            label="Groupe"
                                            name={`group`}
                                            id={`group`}
                                            required
                                            isClearable
                                            simpleValidator={this.validator}
                                            value={this.state.export_ponctuel.group}
                                            handleInput={(e: any) => this.getGroupeOptions(e)}
                                            handle={(e: any) => this.handleInputChange(e, null, 'export_ponctuel')}
                                            options={this.state.options.group}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group-flex form-group-flex--large">
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormDatePicker
                                                name="date_debut"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'export_ponctuel')}
                                                value={datePickerFormat(this.state.export_ponctuel.date_debut)}
                                                simpleValidator={this.validator}
                                                required
                                                isClearable
                                                label="Période du" />
                                        </div>
                                        <div className="form-group form-group--inline m-b-30">
                                            <FormDatePicker
                                                name="date_fin"
                                                type="text"
                                                handle={(e: any) => this.handleInputChange(e, null, 'export_ponctuel')}
                                                value={datePickerFormat(this.state.export_ponctuel.date_fin)}
                                                simpleValidator={this.validator}
                                                required
                                                isClearable
                                                label="au" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group form-group--inline form-group--radio m-b-30">
                                        <FormRadioValues
                                            required
                                            label="Format fichier"
                                            id={`format`}
                                            name={`format`}
                                            value={this.state.export_ponctuel.format}
                                            options={this.state.options.format}
                                            handle={(e: any) => this.handleInputChange(e, null, 'export_ponctuel')}
                                            isSubmitted={this.state.isSubmitted}/>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group form-group--inline form-group--radio m-b-30">
                                        <FormRadioValues
                                            required
                                            label="Média"
                                            id={`media`}
                                            name={`media`}
                                            value={this.state.export_ponctuel.media}
                                            options={this.state.options.media}
                                            handle={(e: any) => this.handleInputChange(e, null, 'export_ponctuel')}
                                            isSubmitted={this.state.isSubmitted}/>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </form>
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(CreateExportPonctuel));
