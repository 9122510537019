import classNames from 'classnames';
import moment from "moment/moment";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { CriteriaPanel, ICriteres } from "../../../components/CriteriaPanel";
import CustomComponent from "../../../components/CustomComponent";
import { FilterDropdown } from "../../../components/FilterDropdown";
import { FormDatePicker, FormInput, FormInputSelect } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import { SeeMoreButton } from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { TableDropdown } from "../../../components/TableDropdown";
import { dateFormat, datePickerFormat, getUrlParams, hasPermission, numberFormat, onExportFile } from "../../../helpers/helpers";
import { ResultatsListHeader } from "../_common/ResultatsListHeader";
import { DailyDetailsModal } from "../_common/_DailyDetailsModal";
import { confirm } from 'react-confirm-box';

class ResultatsTourneeList extends CustomComponent {
    private _isMounted = false;
    private filterSaveKey = 'RESULTATS_PAR_TOURNEE';
    private _referer = '';

    private default_filters = {
        ent_id_cc: '',
        type_lait: '',
        ste_id: '',
        cri_id: '',
        tournee: '',
        tour: '',
        date_debut: moment.utc().subtract(7, 'day').startOf('day').toDate(),
        date_fin: moment.utc().startOf('day').toDate(),
        orderBy: '',
        ordering: 'ASC',
        criteres: [],
    }

    constructor(props: any) {
        super(props)
        this.state = {
            filters: { ...this.default_filters, ...this.getParamsAsFilters() },
            show_more_filters: false,
            options: {
                ent_id_cc: [],
                type_lait: [],
                ste_id: [],
                cri_id: [],
            },
            recherches: [],
            criteres: [],
            search_triggered: false,
            tournees: [],
            echantillons: null,
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
            },
            tourneeDropdowns: [],
            dailyDetailsModal: null,
            detailsModal: null,
            isLoading: false,
        }
    }

    componentDidMount() {
        document.title = "Infolabo | Par tournée";
        this._isMounted = true;

        const params = new URLSearchParams(this.props.location.search);
        this._referer = params.get('referer') ?? '';

        this.getReferences()
        this.getRecherches()
        if (!!this.props.location.search) {
            this.onSearchLoad(this.state.filters);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getParamsAsFilters = () => {
        const params = new URLSearchParams(this.props.location.search);
        if (!params.toString()) return {};

        let filters: any = {};

        if (params.has('trn')) {
            filters.tournee = params.get('trn');
        }
        if (params.has('date')) {
            filters.date_debut = params.get('date');
            filters.date_fin = params.get('date');
        }
        if (params.has('cc')) {
            filters.ent_id_cc = parseInt(params.get('cc')!);
        }
        if (params.has('type_lait')) {
            filters.type_lait = params.get('type_lait');
        }
        if (params.has('tou')) {
            filters.tour = params.get('tou');
        }

        return filters;
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/${this.filterSaveKey}`, 'GET')

        if (recherches && this._isMounted) {
            this.setState({ recherches })
        }
    }

    updateThresholds = (criteres: ICriteres[]) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                criteres
            }
        }));
    }

    getReferences = async () => {
        this.getEntiteCCOptions()
        this.getTypeLaitOptions()
        this.getCriteresOptions()
        this.getStatutOptions()
    }

    async getEntiteCCOptions(e: any | null = null, ent_id_cc: number | null = null) {
        let URL: string = '/ent_entite/autocomplete/CECOL'

        if (e) {
            if (e.action.action !== 'input-change') return
            URL += `?query=${e.data}`
        } else if (ent_id_cc) {
            URL += `?id=${ent_id_cc}`
        }

        const data = await this.request(URL, 'GET')
        if (data) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ent_id_cc: data.map((option: any) => ({ label: option.label, value: option.id }))
                }
            }));
        }
    }

    getTypeLaitOptions = async () => {
        const data = await this.request('/references/type_lait', 'GET')
        if (data?.statusCode === 200) {
            const type_lait: any[] = []
            for (let esp of data.especes_collectees) {
                for (let nat of data.natures_lait) {
                    type_lait.push({ label: `${esp.libelle} - ${nat.lib_court}`, value: `${esp.id}-${nat.id}` })
                }
            }
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    type_lait
                }
            }))
        }
    }

    getCriteresOptions = async () => {
        const data = await this.request('/references/critere_analyse', 'GET')
        if (data && data?.length) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    cri_id: data.map((option: any) => ({ label: `${option.libelle}`, value: option.id }))
                }
            }))
        }
    }

    getStatutOptions = async () => {
        const data = await this.request('/res_echantillon/status', 'GET');

        if (data && data.length) {
            this._isMounted && this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    ste_id: data.map((option: any) => ({ label: option.libelle, value: option.id }))
                }
            })
            );
        }
    }

    getTournees = async (triggeredBySeemore: boolean, triggeredBySearch: boolean, confirmAction: boolean) => {
        const URL = `/resultats/tournee`
        let params = getUrlParams(this.state.filters, {
            ...this.state.paginate,
            page: triggeredBySeemore ? this.state.paginate.page + 1 : 0,
            confirm: confirmAction.toString()
        })

        if (triggeredBySearch) this.props.history.push('/resultats/tournee');
        this.setState({ isLoading: true })
        const data = await this.request(`${URL}?${params}`, 'GET')
        this.setState({ isLoading: false, search_triggered: triggeredBySearch })

        // Demande de confirmation en cas de filtres ralentissant la requête
        if (data?.statusCode === 206) {
            const isConfirmed: boolean = await confirm(data.message, { labels: { confirmable: "Confirmer", cancellable: "Annuler" } });

            if (isConfirmed) {
                this.getTournees(triggeredBySeemore, triggeredBySearch, true);
            }
        }

        if (data?.statusCode === 200) {
            const tournees = data.tournees.map((tournee: any) => ({
                ...tournee,
                tours: [],
                criteres: [],
                moyennes_tournee: [],
                moyennes_citerne: [],
                moyennes_ecart: [],
                litrage_producteur: 0,
                litrage_citerne: 0,
                litrage_ecart: 0
            }))

            this.setState((prev: any) => ({
                tournees: triggeredBySeemore ? [...prev.tournees, ...tournees] : tournees,
                paginate: data.paginate
            }), () => {
                const { tournees } = this.state
                if (tournees.length === 1) {
                    this.getTourneeData(0, true)
                }
            })
        }
    }

    getTourneeData = async (index: number, open: boolean) => {
        const tournee = this.state.tournees[index]
        let { tours, criteres, moyennes_tournee, moyennes_citerne, moyennes_ecart, litrage_producteur, litrage_citerne, litrage_ecart } = tournee

        if (open && tours.length === 0) {
            const { ent_cc_id, trn_id, esp_id, nat_id, ech_date_collecte } = tournee

            const URL = `/resultats/tournee/${ent_cc_id}/${trn_id}/${esp_id}/${nat_id}/${dateFormat(ech_date_collecte, 'YYYY-MM-DD', true)}`

            this.setState({ isLoading: true })
            const data = await this.request(URL, 'GET')
            this.setState({ isLoading: false })
            if (data?.statusCode === 200) {
                tours = data.tours
                criteres = data.criteres
                moyennes_tournee = data.moyennes_tournee
                moyennes_citerne = data.moyennes_citerne
                moyennes_ecart = data.moyennes_ecart
                litrage_producteur = data.litrage_producteur
                litrage_citerne = data.litrage_citerne
                litrage_ecart = data.litrage_ecart
            }
        }

        const tournees = this.state.tournees.map((tournee: any, idx: number) => ({
            ...tournee,
            open: index === idx ? open : tournee.open,
            tours: index === idx ? tours : tournee.tours,
            moyennes_tournee: index === idx ? moyennes_tournee : tournee.moyennes_tournee,
            moyennes_citerne: index === idx ? moyennes_citerne : tournee.moyennes_citerne,
            moyennes_ecart: index === idx ? moyennes_ecart : tournee.moyennes_ecart,
            litrage_producteur: index === idx ? litrage_producteur : tournee.litrage_producteur,
            litrage_citerne: index === idx ? litrage_citerne : tournee.litrage_citerne,
            litrage_ecart: index === idx ? litrage_ecart : tournee.litrage_ecart,
            criteres: index === idx ? criteres : tournee.criteres
        }))

        this.setState({ tournees })
    }

    onDailyDetailClick = async (id: any) => {
        this.setState({ isLoading: true })
        this.request(`/res_resultat_journalier/resResultatJournalierDetail/${id}`, 'GET').then((data) => {
            this.setState({ isLoading: false })
            if (data.statusCode === 200) {
                this.setState({
                    dailyDetailsModal: data.data,
                })
            }
        })
    }

    onSearchLoad = (filters: any) => {
        const { ent_id_cc } = filters
        if (!this.state.options.ent_id_cc.find((option: any) => option.value === ent_id_cc)) {
            this.getEntiteCCOptions(null, ent_id_cc)
        }
        this.setState({
            filters,
            show_more_filters: filters?.criteres?.length > 0
        }, () => this.getTournees(false, false, false));
    }

    // onExportPdf = () => console.log('Export format pdf')

    onExportCsv = () => {
        const session = this.getLocalStorageSession() as any;
        const fileName: string = `${session.ent_siret}_DETAIL_TOURNEES_R_${dateFormat(undefined, 'YYYYMMDD')}.csv`;
        const params: string = getUrlParams(this.state?.filters, {});
        const url = `/exports/tournee?${params}`;

        onExportFile(this, fileName, url, 'GET');
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <ResultatsListHeader />
                    <FilterDropdown
                        startOpen={true}
                        onExport={{ csv: this.onExportCsv }}
                        parent={this}
                        type_recherche={this.filterSaveKey}
                        filters={this.state.filters}
                        recherches={this.state.recherches}
                        onUpdate={this.getRecherches}
                        onSearchLoad={this.onSearchLoad}
                        onApplyFilters={() => this.getTournees(false, false, false)}
                        onFiltersSearch={() => this.getTournees(false, false, false)}
                        onFiltersReset={() => {
                            this.setState({ filters: this.default_filters }, () => {
                                this.getEntiteCCOptions();
                            })
                        }}
                    >
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormInputSelect required isClearable label="Centre de collecte" name={`ent_id_cc`} value={this.state.filters.ent_id_cc} handle={(e: any) => this.handleInputChange(e, null, 'filters')} handleInput={(e: any) => this.getEntiteCCOptions(e)} options={this.state.options.ent_id_cc} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect isClearable label="Type de lait" name={`type_lait`} value={this.state.filters.type_lait} handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.type_lait} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInputSelect isClearable label="Statut de l'échantillon" name={`ste_id`} value={this.state.filters.ste_id} handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.ste_id} />
                                </div>
                                <div className="form-group form-group--inline align-items-center">
                                    <FormInputSelect
                                        info={{ placement: 'top', text: "Permet de sélectionner uniquement les tournées avec au moins un échantillon analysé pour le critère sélectionné" }}
                                        isClearable
                                        label="Critère analysé"
                                        name={`cri_id`}
                                        value={this.state.filters.cri_id}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        options={this.state.options.cri_id} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormInput label="Tournée" name={`tournee`} value={this.state.filters.tournee} handle={(e: any) => this.handleInputChange(e, null, 'filters')} />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInput label="Tour" name={`tour`} value={this.state.filters.tour} handle={(e: any) => this.handleInputChange(e, null, 'filters')} />
                                </div>
                                <div className="form-group-flex">
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="date_debut"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.date_debut)}
                                            label="Période du"
                                            required
                                        />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="date_fin"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.date_fin)}
                                            label="au"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <CriteriaPanel
                                    criteres={this.state.filters.criteres}
                                    onChange={this.updateThresholds}
                                    min={1}
                                    open={this.state.show_more_filters}
                                    togglable
                                    onToggle={(open: boolean) => this.setState({ show_more_filters: open })} />
                            </div>
                        </div>
                    </FilterDropdown>

                    {(!this.state.search_triggered && this.state.tournees.length === 0 && !this._referer) && <TABLE.NO_REQUEST />}
                    {(!this.state.search_triggered && this._referer === 'res_citerne' && this.state.tournees.length === 0) && (
                        <TABLE.EMPTY_MESSAGE message="Aucun échantillon producteur pour cette tournée" />
                    )}
                    {(this.state.search_triggered && this.state.tournees.length === 0) && <TABLE.NO_RESULT />}
                    {this.state.tournees.length > 0 && (<>
                        {this.state.tournees?.map((tournee: any, index: number) => (
                            <TableDropdown
                                key={index}
                                open={tournee.open}
                                onToggle={(open: boolean) => this.getTourneeData(index, open)}
                                tourneeNb={tournee.trn_code}
                                collectionCenter={`${tournee.ent_cc_siret} - ${tournee.ent_cc_nom_usuel}`}
                                milkType={`${tournee.esp_libelle} - ${tournee.nat_lib_court}`}
                                collectionDate={dateFormat(tournee.ech_date_collecte, 'DD/MM/YYYY')}
                            >
                                {tournee.tours.length > 0 && (
                                    <TABLE responsive="accordion" fixed bordered>
                                        <TABLE.THEAD>
                                            <TABLE.TR>
                                                <TABLE.TH label="Tour" fixed={{ left: 0, width: 100 }} />
                                                <TABLE.TH label="Producteur" fixed={{ left: 100, width: 200 }} />
                                                <TABLE.TH label="Date de prél." fixed={{ left: 300, width: 150 }} />
                                                <TABLE.TH>
                                                    <div className="d-flex flex-column align-items-center">
                                                        <strong>Litrage</strong>
                                                        <small>(L)</small>
                                                    </div>
                                                </TABLE.TH>
                                                <TABLE.TH label="Statut" />
                                                {tournee.criteres.map((cri: any) => (<React.Fragment key={cri.critere}>
                                                    <TABLE.TH>
                                                        <div className="d-flex flex-column align-items-center">
                                                            <strong>{cri.critere}</strong>
                                                            {cri.unite_mesure && (<small>({cri.unite_mesure})</small>)}
                                                        </div>
                                                    </TABLE.TH>
                                                    <TABLE.TH>
                                                        <div className="d-flex flex-column align-items-center">
                                                            <small>Note</small>
                                                        </div>
                                                    </TABLE.TH>
                                                </React.Fragment>))}
                                            </TABLE.TR>
                                        </TABLE.THEAD>
                                        <TABLE.TBODY>
                                            {tournee.tours.map((tour: any) => (<React.Fragment key={tour.id}>
                                                {[tour.echantillons_producteur, tour.echantillons_citerne].map((echantillons: any, i: any) => (<React.Fragment key={i}>
                                                    {echantillons.map((ech: any) => (
                                                        <TABLE.TR key={ech.id} title={`Tour ${tour.numero}`}>
                                                            <TABLE.TD hiddenMobile fixed={{ left: 0, width: 100 }}>{tour.numero}</TABLE.TD>
                                                            <TABLE.TD label="Producteur" fixed={{ left: 100, width: 200 }}>
                                                                {ech.type === 'PRD' && (<>
                                                                    {hasPermission('DRT_RES_PRODUCTION') ? (
                                                                        <Link
                                                                            className={classNames({ 'link': true, 'link--strong': !ech.cc_is_ca })}
                                                                            data-tip={!ech.cc_is_ca ? `Centre d'appartenance: ${ech?.ca?.siret} - ${ech?.ca?.nom_usuel}` : null}
                                                                            to={`/resultats/production/${ech.prd_id}/${ech.prn_id}`}
                                                                            target="_blank">
                                                                            {ech.prd?.siret}<br />{ech.prd?.nom_usuel}
                                                                        </Link>
                                                                    ) : (
                                                                        <>{ech.prd?.siret}<br />{ech.prd?.nom_usuel}</>
                                                                    )}
                                                                </>)}
                                                                {ech.type === 'CIT' && (<>Échantillon citerne</>)}
                                                            </TABLE.TD>
                                                            <TABLE.TD label="Date de prél." fixed={{ left: 300, width: 150 }}>{dateFormat(ech.date_collecte, 'DD/MM/YYYY')}</TABLE.TD>
                                                            <TABLE.TD label="Litrage (L)"><div className="text-right">{numberFormat(ech.volume_collecte)}</div></TABLE.TD>
                                                            <TABLE.TD label="Statut">{ech.statut}</TABLE.TD>
                                                            {tournee.criteres.map((cri: any) => (<React.Fragment key={cri.critere}>
                                                                {ech.resultats.find((res: any) => res.critere === cri.critere) ? (<>
                                                                    {[ech.resultats.find((res: any) => res.critere === cri.critere)].map((res) => (<React.Fragment key={res.id}>
                                                                        <TABLE.TD label={`${cri.critere}${cri.unite_mesure ? ' (' + cri.unite_mesure + ')' : ''}`} onClick={() => this.onDailyDetailClick(res.id)}>
                                                                            {['ANN', 'SUS'].includes(res.statut_code) ? (
                                                                                <div className="text-right">{res.statut}</div>
                                                                            ) : (
                                                                                <div className={classNames({ 'text-right': true, 'font-weight-bold': ['N', 'P'].includes(res.visibilite) || ['N', 'P'].includes(ech.visibilite) })}>
                                                                                    {!!res.valeur && (<>{res?.signe}{numberFormat(res.valeur, res?.nb_decimales)}</>)}
                                                                                    {!!res.appreciation && (<>{res.appreciation}</>)}
                                                                                    {(!res.valeur && !res.appreciation) && <>-</>}
                                                                                </div>
                                                                            )}
                                                                        </TABLE.TD>
                                                                        <TABLE.TD><div className="text-center">{res.note || '-'}</div></TABLE.TD>
                                                                    </React.Fragment>))}
                                                                </>) : (<>
                                                                    <TABLE.TD><div className="text-center">-</div></TABLE.TD>
                                                                    <TABLE.TD><div className="text-center">-</div></TABLE.TD>
                                                                </>)}
                                                            </React.Fragment>))}
                                                        </TABLE.TR>
                                                    ))}
                                                </React.Fragment>))}
                                                {/* Moyenne pondérée tour */}
                                                {tournee.tours.length > 1 && (
                                                    <TABLE.TR variant="blue-40" title="Moyenne pondérée tour X">
                                                        <TABLE.TD fixed={{ left: 0, width: 450 }} colspan={3}><div className="font-weight-bold">Moyenne pondérée tour {tour.numero}</div></TABLE.TD>
                                                        <TABLE.TD><div className="font-weight-bold text-right">{numberFormat(tour.litrage_tour, 1)}</div></TABLE.TD>
                                                        <TABLE.TD><div className="text-center font-weight-bold">-</div></TABLE.TD>
                                                        {tour.moyennes_tour.map((cri: any) => (<React.Fragment key={cri.critere}>
                                                            <TABLE.TD><div className="text-right">{numberFormat(cri.moyenne, cri.nb_decimales)}</div></TABLE.TD>
                                                            <TABLE.TD><div className="text-center">-</div></TABLE.TD>
                                                        </React.Fragment>))}
                                                    </TABLE.TR>
                                                )}
                                            </React.Fragment>))}
                                            {/* Moyenne pondérée tournee */}
                                            <TABLE.TR variant="blue-100" title="Moyenne pondérée tournée">
                                                <TABLE.TD fixed={{ left: 0, width: 450 }} colspan={3}><div className="font-weight-bold">Moyenne pondérée tournée</div></TABLE.TD>
                                                <TABLE.TD><div className="font-weight-bold text-right">{numberFormat(tournee.litrage_producteur, 1)}</div></TABLE.TD>
                                                <TABLE.TD><div className="text-center font-weight-bold">-</div></TABLE.TD>
                                                {tournee.moyennes_tournee.map((cri: any) => (<React.Fragment key={cri.critere}>
                                                    <TABLE.TD><div className="text-right">{numberFormat(cri.moyenne, cri.nb_decimales)}</div></TABLE.TD>
                                                    <TABLE.TD><div className="text-center">-</div></TABLE.TD>
                                                </React.Fragment>))}
                                            </TABLE.TR>
                                            {/* Moyenne pondérée citernes */}
                                            <TABLE.TR variant="blue-80" title="Moyenne pondérée citernes">
                                                <TABLE.TD fixed={{ left: 0, width: 450 }} colspan={3}><div className="font-weight-bold">Moyenne pondérée citernes</div></TABLE.TD>
                                                <TABLE.TD><div className="font-weight-bold text-right">{numberFormat(tournee.litrage_citerne, 1)}</div></TABLE.TD>
                                                <TABLE.TD><div className="text-center font-weight-bold">-</div></TABLE.TD>
                                                {tournee.moyennes_citerne.map((cri: any) => (<React.Fragment key={cri.critere}>
                                                    <TABLE.TD><div className="text-right">{numberFormat(cri.moyenne, cri.nb_decimales)}</div></TABLE.TD>
                                                    <TABLE.TD><div className="text-center">-</div></TABLE.TD>
                                                </React.Fragment>))}
                                            </TABLE.TR>
                                            {/* Ecart moyenne tournee citernes */}
                                            <TABLE.TR variant="blue-80" title="Ecart moyenne tournée - moyenne citerne">
                                                <TABLE.TD fixed={{ left: 0, width: 450 }} colspan={3}><div className="font-weight-bold">Ecart moyenne tournée - moyenne citerne</div></TABLE.TD>
                                                <TABLE.TD><div className="font-weight-bold text-right">{numberFormat(tournee.litrage_ecart, 1)}</div></TABLE.TD>
                                                <TABLE.TD><div className="text-center font-weight-bold">-</div></TABLE.TD>
                                                {tournee.moyennes_ecart.map((cri: any) => (<React.Fragment key={cri.critere}>
                                                    <TABLE.TD><div className="text-right">{numberFormat(cri.ecart, cri.nb_decimales)}</div></TABLE.TD>
                                                    <TABLE.TD><div className="text-center">-</div></TABLE.TD>
                                                </React.Fragment>))}
                                            </TABLE.TR>
                                        </TABLE.TBODY>
                                    </TABLE>
                                )}
                            </TableDropdown>
                        ))}
                        <SeeMoreButton behaviour="scroll-mobile" paginate={this.state.paginate} onClick={() => this.getTournees(true, false, true)} disabled={this.state.isLoading} />
                    </>)}

                    {this.state.dailyDetailsModal && (
                        <DailyDetailsModal dailyDetails={this.state.dailyDetailsModal} onClose={() => this.setState({ dailyDetailsModal: null })} />
                    )}
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ResultatsTourneeList));
