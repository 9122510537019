import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Request } from "../../helpers/helpers";
import { AnnonceCardMenu } from "./_card_menu";
import { AnnonceDetailsModal } from "./_details_modal";

interface AnnonceSideMenuProps {
    isOpen: boolean;
    onClose: () => void;
    readAnnonceCallBack: (id: number) => void;
}

export const AnnonceSideMenu = ({isOpen, onClose, readAnnonceCallBack}: AnnonceSideMenuProps) => {
    const {t} = useTranslation();
    const [annonces, setAnnonces] = useState<any[]>([]);
    const [openAnnonce, setOpenAnnonce] = useState<number | null>(null);
    const [newlyReadAnnonces, setNewlyReadAnnonces] = useState<number[]>([]);

    useEffect(() => {
        if (isOpen) void getAnnonces();
    }, [isOpen]);

    useEffect(() => {
        if (openAnnonce && !newlyReadAnnonces.includes(openAnnonce)) {
            setNewlyReadAnnonces((prev: number[]) => [...prev, openAnnonce]);

            const annonce = annonces.find((annonce: any) => annonce.id === openAnnonce);
            if (!annonce.is_read) readAnnonceCallBack(openAnnonce);
        }
    }, [annonces, newlyReadAnnonces, openAnnonce, readAnnonceCallBack]);

    const getAnnonces = async () => {
        const data = await Request('/inf_annonce', 'GET');
        if (data?.statusCode) {
            setAnnonces(data.annonces);
        }
    }

    return (<>
        <div className={classNames({'notification-menu': true, 'open': isOpen})}>
            <div className="notification-menu__header">
                <h2 className="notification-menu__title">{t('annonces.name')}</h2>
                <button onClick={() => onClose()} type='button' className='notification-menu__close'><i className="icon-close"></i><span className='sr-only'>Fermer</span></button>
            </div>
            {/* <ul className='notification-menu__links'>
                {hasPermission('DRT_RES_GERER_SEUILS') && (<li><i className="icon-tune"></i><Link to='/notifications/gerer_seuils_alerte'>Gérer mes seuils d'alerte</Link></li>)}
            </ul> */}
            <ul className="small-notification">
                {annonces.map((annonce) => (
                    <AnnonceCardMenu
                        key={annonce.id}
                        annonce={annonce}
                        openAnnonce={setOpenAnnonce}
                        isNewlyRead={newlyReadAnnonces.includes(annonce.id)}
                    />
                ))}
            </ul>
        </div>
        <AnnonceDetailsModal annonceId={openAnnonce} onClose={() => setOpenAnnonce(null)} />
    </>)
}
