export enum MEDIA {
    MAIL = 'MAIL',
    FTP = 'FTP',
    EN_LIGNE = 'EN_LIGNE'
}

export const MEDIA_LABEL: {[key: string]: string} = {
    [MEDIA.MAIL]: 'Mail',
    [MEDIA.FTP]: 'FTP',
    [MEDIA.EN_LIGNE]: 'En ligne',
};

export const MEDIA_OPTIONS = Object.entries(MEDIA_LABEL).map(([value, label]) => ({
    value, label
}))