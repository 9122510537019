import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { FormInput, FormSelect } from "../../../components/FormComponents"
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import Modal from "../../../components/Modal"
import { ToggleSwitch } from "../../../components/ToggleSwitch"
import { Request, TOAST_TYPE, numberUnformat, sendToast } from "../../../helpers/helpers"
import { initValidator } from "../../../validations/validations"

interface CreateModalProps {
    production: any
    seuil?: any
    seuils: any[]
    onClose: Function
}
export const CreateModal = ({production, onClose, seuil, seuils}: CreateModalProps) => {
    const {t} = useTranslation();
    const isUpdate = !!seuil
    const title = isUpdate ? "Modifier un seuil d'alerte": "Créer un seuil d'alerte"

    const qualiOperators = useMemo(() => ['=', '<>'], [])
    const quantiOperators = useMemo(() => ['=', '>', '>=', '<', '<=', '<>'], [])
    const validator = useMemo(initValidator, []);

    let seuil_critere = ''
    if (seuil?.cri_id?.id) seuil_critere += `${seuil?.cri_id?.id}`
    if (seuil?.uni_id?.id) seuil_critere +=  `|${seuil?.uni_id?.id}`
    const isQuali = seuil?.cri_id.quali_quanti === 'A'
    const [optionsCritere, setOptionsCritere] = useState<any[]>([])
    const [quali, setQuali] = useState(isQuali)
    const [optionsOperande, setOptionsOperande] = useState<any[]>([])
    const [critere, setCritere] = useState(seuil_critere)
    const [operande, setOperande] = useState(seuil?.operande || '')
    const [valeur, setValeur] = useState(seuil?.valeur || '')
    const [apr_id, setApr_id] = useState(`${seuil?.apr_id?.id || ''}`)
    const [optionsAppreciation, setOptionsAppreciation] = useState([])
    const [fl_journalier, setFl_journalier] = useState(seuil ? seuil.fl_journalier : true)
    const [fl_mensuel, setFl_mensuel] = useState(seuil ? seuil.fl_mensuel : false)
    const [fl_notification_m, setFl_notification_m] = useState(seuil ? seuil.fl_notification_m : false)
    const [fl_actif, setFl_actif] = useState(seuil ? seuil.fl_actif : true)
    const [loading, setLoading] = useState(false)

    const getOptionsCritere = useCallback(() => {
        const URL = `/references/critere_analyse/${production.id}`
        Request(URL, 'GET').then(data => {
            if (!data) return

            const forbidden_criteres = seuils
                .filter(s => s?.cri_id?.id !== seuil?.cri_id?.id || s?.uni_id?.id !== seuil?.uni_id?.id)
                .map(s => {
                    let value = `${s.cri_id.id}`
                    if (s?.cri_id?.quali_quanti === 'Q') value += `|${s?.uni_id?.id}`
                    return value
                })

            const options = data
                .map((option: any) => {
                    return {
                        value: `${option.cri_id.id}${option?.uni_id?.id ? '|' + option?.uni_id?.id : ''}`,
                        name: `${option.cri_id.lib_long}${option?.uni_id?.lib_long ? ' (' + option?.uni_id?.lib_long + ')' : ''}`,
                        quali: option.cri_id.quali_quanti === "A"
                    }
                })
                .filter((option: any) => !forbidden_criteres.includes(option.value))

            setOptionsCritere(options)
        })
    }, [production, seuil, seuils])

    const getOptionsAppreciation = useCallback(() => {
        const URL = `/references/appreciation`
        Request(URL, 'GET').then(data => {
            setOptionsAppreciation(data.map((o: any) => ({value: o.id, name: o.libelle})))
        })

    }, [])

    useEffect(() => {
        getOptionsCritere()
        getOptionsAppreciation()
    }, [getOptionsCritere, getOptionsAppreciation])

    useEffect(() => {
        if (!optionsCritere || !critere) return
        const selected = optionsCritere.find(o => o.value === critere)
        const isQuali = selected?.quali

        if (isQuali && !qualiOperators.includes(operande)) setOperande('')
        setQuali(isQuali)
    }, [critere, operande, qualiOperators, optionsCritere])

    useEffect(() => {
        setOptionsOperande(quali ? qualiOperators : quantiOperators)
        validator.purgeFields();
    }, [quali, qualiOperators, quantiOperators, validator])

    useEffect(() => {
        validator.showMessageFor('fl_journalier_or_mensuel');
    }, [validator, fl_journalier, fl_mensuel])

    const onSubmit = (e: any) => {
        e.preventDefault()

        if (validator.allValid()) {
            afterSubmit()
        } else {
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }
    }

    const afterSubmit = async () => {
        const URL = seuil ? `/per_seuil_alerte/${seuil.id}` : '/per_seuil_alerte'
        const METHOD = seuil ? 'PATCH' : 'POST'

        const [cri_id, uni_id] = critere.split('|')

        const body = {
            prn_id: production.id,
            cri_id: +cri_id,
            uni_id: +uni_id,
            operande,
            fl_journalier,
            fl_mensuel,
            fl_notification_m,
            fl_actif,
            ...(quali && {apr_id: +apr_id, valeur: null}),
            ...(!quali && {apr_id: null, valeur: +numberUnformat(valeur)}),
        }
        setLoading(true)
        const data = await Request(URL, METHOD, body)
        setLoading(false)
        if (data.statusCode === 201) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            onClose()
        }
    }

    return (<>
        <LoadingIndicator loading={loading} />
        <Modal size="md" customLayout open={true}>
            <form onSubmit={onSubmit}>
                <Modal.Header>
                    <div className="text-left w-100 m-b-30">
                        <h2 className="modal-title text-left">{title}</h2>
                        <p>
                            {production.nature_lait} - {production.espece_collectee}
                            {production.tank && <>(Tank #{production.tank})</>}
                            <br />
                            {production.nom_usuel} - {production.siret}
                        </p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group form-group--white form-group--inline">
                        <FormSelect
                            light
                            required
                            id="critere"
                            name="critere"
                            label="Critère"
                            value={critere}
                            options={optionsCritere}
                            handle={(e: any) => setCritere(e.target.value)}
                            validator_type="selected"
                            simpleValidator={validator}
                        />
                    </div>
                    <div className="form-group form-group--white form-group--inline">
                        <FormSelect
                            light
                            required
                            id="operande"
                            name="operande"
                            label="Opérande"
                            value={operande}
                            options={optionsOperande}
                            handle={(e: any) => setOperande(e.target.value)}
                            validator_type="selected"
                            simpleValidator={validator}
                        />
                    </div>
                    <div className="form-group form-group--white form-group--inline">
                        {quali &&
                            <FormSelect
                                light
                                required
                                id="apr_id"
                                name="apr_id"
                                label="Appréciation"
                                value={apr_id}
                                options={optionsAppreciation}
                                handle={(e: any) => setApr_id(e.target.value)}
                                validator_type="selected"
                                simpleValidator={validator}
                            />}
                        {!quali &&
                            <FormInput
                                light
                                required
                                id="valeur"
                                name="valeur"
                                label="Valeur"
                                type="text"
                                value={valeur}
                                handle={(e:any) => setValeur(e.target.value)}
                                validator_type="number"
                                simpleValidator={validator}
                            />
                        }
                    </div>

                    <div className="form-group form-group--white form-group--inline">
                        <span className="label">À appliquer sur</span>
                        <div className="checkbox__wrp">
                            <input className="checkbox checkbox--white" id="fl_journalier" name="fl_journalier" type="checkbox" checked={fl_journalier} onChange={() =>setFl_journalier(!fl_journalier)}/>
                            <label className="label" htmlFor="">Résultats journaliers</label>
                        </div>
                        <div className="checkbox__wrp">
                            <input className="checkbox checkbox--white" id="fl_mensuel" name="fl_mensuel" type="checkbox" checked={fl_mensuel} onChange={() =>setFl_mensuel(!fl_mensuel)}/>
                            <label className="label" htmlFor="">Résultats mensuels</label>
                        </div>
                        {validator.message('fl_journalier_or_mensuel', fl_journalier || fl_mensuel, 'atLeastOne', {className: 'invalid-feedback light'})}
                    </div>
                    <div className="form-group form-group--white form-group--inline">
                        <div className="toggle-switch__wrp">
                            <label htmlFor="fl_notification_m">Notification Mobile</label>
                            <ToggleSwitch id="fl_notification_m" variant="white" active={fl_notification_m} onChange={() => setFl_notification_m(!fl_notification_m)} labels={{on: 'Activé', off: 'Désactivé'}}/>
                            <i className="icon-info green light-background" data-place={`right`} data-tip={`Cette fonctionnalité est seulement accessible sur l’application mobile`}></i>
                        </div>
                    </div>
                    <div className="form-group form-group--white form-group--inline">
                        <div className="toggle-switch__wrp">
                            <label htmlFor="fl_actif">Statut du seuil</label>
                            <ToggleSwitch id="fl_actif" variant="white" active={fl_actif} onChange={() => setFl_actif(!fl_actif)} labels={{on: 'Activé', off: 'Désactivé'}}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => onClose()} type="button" className="btn btn-white">Annuler</button>
                    <button type="submit" className="btn btn-success">Enregistrer</button>
                </Modal.Footer>
            </form>
        </Modal>
    </>)
}