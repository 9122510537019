const DEBOUNCE_DEFAULT_DELAY = 600;
type DebouncedFunc = (...args: any[]) => void;

/**
 * DebounceQueue is a queue that will execute the last function pushed to it after a delay.
 * If a new function is pushed to the queue before the delay has elapsed, the previous function
 * @usage
 * ```typescript
 * const debouncer = new DebounceQueue(200); // 200ms delay (optional)
 * const hello_world = (custom_message ?: string) => console.log(custom_message || 'hello world');
 * debouncer.push(hello_world); // will execute after 200ms
 * ```
 */
export class DebounceQueue {
    private DEBOUNCE_DELAY: number;
    private delayed_func: DebouncedFunc | undefined;
    private timeout: any;

    /**
     * @param delay delay in milliseconds
     */
    constructor(delay?: number) {
        this.DEBOUNCE_DELAY = delay || DEBOUNCE_DEFAULT_DELAY;
    }

    /**
     * @param func function to be executed after delay
     * @param args arguments to be passed to the function
     * @param immediate if true, the function will be executed immediately
     */
    public push = (func: DebouncedFunc, args: any[] = [], immediate?: boolean) => {
        this.delayed_func = func;
        const delay = immediate ? 0 : this.DEBOUNCE_DELAY;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            if (this.delayed_func) {
                this.delayed_func(...args);
                this.delayed_func = undefined;
            }
        }, delay);
    }
}
