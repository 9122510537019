import classNames from 'classnames'
import moment from "moment"
import 'moment/locale/fr'
import React from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { CriteriaPanel, ICriteres } from "../../../../components/CriteriaPanel"
import CustomComponent from "../../../../components/CustomComponent"
import { FilterDropdown } from "../../../../components/FilterDropdown"
import { FormDatePicker, FormSelect } from "../../../../components/FormComponents"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"
import TABLE from "../../../../components/Table"
import {
    TOAST_TYPE,
    checkIfCriteresAreFilled,
    dateFormat,
    datePickerFormat,
    generateURLSearchParams,
    getUrlParams,
    numberFormat,
    onExportFile,
    sendToast,
    getLaboratoireLogo
} from "../../../../helpers/helpers"
import { DailyDetailsModal } from '../../_common/_DailyDetailsModal'
import { DetailsModal } from '../../_common/_DetailsModal'
import { DetailsModalMensuel } from '../../_common/_DetailsModalMensuel'
import { transformJournalierData, transformMensuelData } from '../helpers/dataTransform'
import { MensuelCharts } from "./_MensuelCharts"

const SAVE_SEARCH_KEY = "RESULTAT_EXPLOITATION_PRODUCTION_MENSUEL"

class ResultatMensuel extends CustomComponent {
    private _isMounted = false;

    private default_filters = {
        date_debut: moment.utc().subtract(13, 'month').toDate(),
        date_fin: moment.utc().toDate(),
        orderBy: 'nom_usuel',
        ordering: 'ASC',
        cri_id_1: '',
        cri_id_2: '',
        criteres: []
    }

    constructor(props: any) {
        super(props);
        this.state = {
            logo_path: '',
            echantillons: [],
            cardData: null,
            tableData: null,
            chartsData: [],
            journalierMois: [],
            journalierCardData: {},
            journalierTableData: {},
            detailsModal: null,
            detailsEchModal: null,
            dailyDetailModal: null,
            filters: { ...this.default_filters, ...this.getParamsAsFilters() },
            options: {
                criteres: [],
            },
            recherches: [],
            displayAsCharts: false,
            paginate: {
                count: 0,
                limit: 15,
                page: 0,
                pageTotal: 0,
            },
            isLoading: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.getLogoPath();
        this.getRecherches()
        this.getCriteresOptions().then(() => {
            this.getResultats()
        })
    }

    componentDidUpdate(prevProps: Readonly<any>): void {
        const productionIDChanged = this.props?.production?.id !== prevProps?.production.id
        const displayAsChartsChanged = this.props?.displayAsCharts !== prevProps?.displayAsCharts

        if (productionIDChanged || displayAsChartsChanged) {
            this.getResultats()
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getParamsAsFilters = () => {
        const params = new URLSearchParams(this.props.location.search);
        let filters: any = {};

        if (params.has('date_d')) Object.assign(filters, { date_debut: params.get('date_d') });
        if (params.has('date_f')) Object.assign(filters, { date_fin: params.get('date_f') });

        return filters;
    }

    getCriteresOptions = async () => {
        const URL = `/references/critere_analyse/${this.props?.production?.id}`
        const data = await this.request(URL, 'GET')
        const criteres_unites = data.filter((cri_uni: any) => cri_uni.cri_id.quali_quanti === "Q" && !!cri_uni.uni_id)

        const critere1 = criteres_unites.find((cri: any) => cri.cri_id.code_critere === 'MAGR')
        const critere2 = criteres_unites.find((cri: any) => cri.cri_id.code_critere === 'MAPR')

        this.setState((prev: any) => ({
            options: {
                ...prev.options,
                criteres: criteres_unites.map((option: any) => ({ name: `${option.cri_id.lib_court}${option.uni_id?.lib_long ? ' (' + option.uni_id?.lib_long + ')' : ''}`, value: option.cri_id.id }))
            },
            filters: {
                ...prev.filters,
                cri_id_1: prev.filters.cri_id_1 || critere1?.cri_id?.id,
                cri_id_2: prev.filters.cri_id_2 || critere2?.cri_id?.id,
            }
        }));
    }

    getResultats = () => {
        if (this.props.displayAsCharts) {
            this.getResultatsForCharts()
        } else {
            this.getResultatsForTable()
        }
    }

    getResultatsForTable = async (triggeredByLoadMore: boolean = false) => {
        if (!this.state.filters.date_debut || !this.state.filters.date_fin) {
            sendToast(<p>Les champs périodes doivent être renseignés pour lancer une recherche.</p>, TOAST_TYPE.ERROR);
            return;
        }

        const URL = `/resultats/production/${this.props.production.id}/mensuel`
        const params = new URLSearchParams()

        if (!checkIfCriteresAreFilled(this.state.filters.criteres))
            return;

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key]
            if (val !== '') params.append(key, val)
        })

        Object.keys(this.state.filters).forEach(key => {
            let val = this.state.filters[key]

            // format Date object to string
            if (val instanceof Date) val = (val as Date).toISOString();

            if (key === 'criteres') val = JSON.stringify(val.filter((crit: any) => !!crit.cri_id && !!crit.operator && (!!crit.value || !!crit.app_id)));
            if (val !== '') params.append(key, val)
        })

        this.setState({ isLoading: true })
        const data = await this.request(`${URL}?${params.toString()}`, 'GET')
        this.setState({ isLoading: false })

        if (data && data.resultats_mensuels) {
            const { cardData, tableData } = data.resultats_mensuels.length > 0
                ? transformMensuelData(data.resultats_mensuels)
                : { cardData: null, tableData: null }

            this.setState({
                tableData,
                cardData
            })
        }
    }

    getResultatsForCharts = async () => {
        const URL = `/resultats/production/${this.props.production.id}/mensuel/charts`
        const params = generateURLSearchParams({}, this.state.filters)

        this.setState({ isLoading: true })
        const data = await this.request(`${URL}?${params.toString()}`, 'GET')

        this.setState({ isLoading: false })

        if (data && data.statusCode === 200) {
            this.setState({ chartsData: data.data })
        }
    }

    onMensuelDetailClick = async (mois_facturation: string) => {
        if (this.state.journalierMois.includes(mois_facturation)) {
            this.setState((prev: any) => ({
                journalierMois: prev.journalierMois.filter((month: any) => month !== mois_facturation),
                journalierCardData: Object.fromEntries(Object.entries(prev.journalierCardData).filter((data: any) => data[0] !== mois_facturation)),
                journalierTableData: Object.fromEntries(Object.entries(prev.journalierTableData).filter((data: any) => data[0] !== mois_facturation)),
            }))
            return;
        }

        const URL = `/resultats/production/${this.props.production.id}/mensuel/${mois_facturation}`
        const params = new URLSearchParams()

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key]
            if (val !== '') params.append(key, val)
        })

        Object.keys(this.state.filters).forEach(key => {
            let val = this.state.filters[key]

            // format Date object to string
            if (val instanceof Date) val = (val as Date).toISOString();

            if (key === 'criteres') val = JSON.stringify(val.filter((crit: any) => !!crit.cri_id && !!crit.operator && (!!crit.value || !!crit.app_id)));
            if (val !== '') params.append(key, val)
        })

        this.setState({ isLoading: true })
        const data = await this.request(URL, 'GET')
        this.setState({ isLoading: false })

        if (data?.statusCode === 200 && data.echantillons) {
            const { cardData, tableData } = transformJournalierData(data.echantillons, 'date_prelevement', 'ASC')

            this.setState((prev: any) => ({
                journalierMois: [...prev.journalierMois, mois_facturation],
                journalierCardData: { ...prev.journalierCardData, [mois_facturation]: cardData },
                journalierTableData: { ...prev.journalierTableData, [mois_facturation]: tableData },
            }))
        }
    }

    onDateClick = async (mois_facturation: any) => {
        this.setState({ isLoading: true })
        const data = await this.request(`/resultats/production/${this.props.production.id}/mensuel/${mois_facturation}/detail`, 'GET')
        this.setState({ isLoading: false })
        if (data.statusCode === 200) {
            this.setState({ detailsModal: data })
        }
    }

    onDailyDateClick = async (id: any) => {
        this.setState({ isLoading: true })
        this.request(`/res_echantillon/resEchantillionDetail/${id}`, 'GET').then((data) => {
            this.setState({ isLoading: false })
            this.setState({
                detailsEchModal: data.data,
            })
        })
    }

    onDailyDetailClick = async (id: any) => {
        this.setState({ isLoading: true })
        this.request(`/res_resultat_journalier/resResultatJournalierDetail/${id}`, 'GET').then((data) => {
            this.setState({ isLoading: false })
            if (data.statusCode === 200) {
                this.setState({
                    dailyDetailModal: data.data,
                })
            }
        })
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/${SAVE_SEARCH_KEY}`, 'GET')

        if (recherches && this._isMounted) {
            this.setState({ recherches })
        }
    }

    updateThresholds = (criteres: ICriteres[]) => {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                criteres
            }
        }))
    }

    onExportCsv = () => {
        const fileName: string = `${this.props.production.pdf_siret_producteur}_MENSUELS_R_${dateFormat(undefined, 'YYYYMMDD')}.csv`;
        const params: string = getUrlParams(this.state.filters, {});
        const url = `/exports/production/${this.props.production.id}/mensuel?${params}`;

        onExportFile(this, fileName, url, 'GET');
    }

    onExportPdf = () => {
        const fileName: string = `${this.props.production.pdf_siret_producteur}_MENSUELS_R_${dateFormat(undefined, 'YYYYMMDD')}.pdf`;
        const params: string = getUrlParams({ ...this.state.filters, journalierMois: this.state.journalierMois }, {});
        const url = `/exports/production/${this.props.production.id}/mensuel/pdf?${params}`;

        onExportFile(this, fileName, url, 'GET');
    }

    onExportPdfDetail = (mois_facturation: string, prd_siret: string, prn_id: number) => {
        const fileName: string = `${prd_siret}_DETAIL_MENSUEL_${mois_facturation}_${dateFormat(undefined, 'YYYYMMDD')}.pdf`;
        const url = `/exports/production/${prn_id}/mensuel/${mois_facturation}/detail/pdf`;

        onExportFile(this, fileName, url, 'GET');
    }

    async getLogoPath() {
        const { lab_id } = this.props.production
        const logo_path = await getLaboratoireLogo(lab_id);
        this.setState({ logo_path });
    }

    render() {
        return !this.props.displayAsCharts ? (<>
            <LoadingIndicator loading={this.state.isLoading} />
            <FilterDropdown
                startOpen={true}
                onExport={{ csv: this.onExportCsv, pdf: this.onExportPdf }}
                parent={this}
                type_recherche={SAVE_SEARCH_KEY}
                filters={this.state.filters}
                recherches={this.state.recherches}
                onUpdate={this.getRecherches}
                onSearchLoad={(filters: any) => this.setState({ filters }, this.getResultatsForTable)}
                onApplyFilters={this.getResultatsForTable}
                onFiltersSearch={this.getResultatsForTable}
                onFiltersReset={() => { this.setState({ filters: this.default_filters }) }}>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group form-group--inline">
                                    <FormDatePicker
                                        month
                                        name="date_debut"
                                        type="text"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        value={datePickerFormat(this.state.filters.date_debut)}
                                        label="Période du" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="form-group form-group--inline">
                                    <FormDatePicker
                                        month
                                        name="date_fin"
                                        type="text"
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                        value={datePickerFormat(this.state.filters.date_fin)}
                                        label="au" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <CriteriaPanel criteres={this.state.filters.criteres} onChange={this.updateThresholds} displayLibLong />
                    </div>
                </div>
            </FilterDropdown>
            {!this.state.tableData ? (
                <TABLE.NO_RESULT />
            ) : (<>
                <div className="desktop-only">
                    <TABLE fixed bordered>
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH fixed={{ left: 0 }} logo>
                                    {this.props.lab_logo ? <img className="table-logo__logo" alt={this.props.production.lab_name} src={this.props.lab_logo} /> : <div className="table-logo__label">{this.props.production.lab_name}</div>}
                                </TABLE.TH>
                                {this.state.tableData.mois_facturation.map(({mois, fl_has_rej}: any) => <React.Fragment key={mois}>
                                    <TABLE.TH>
                                        <div className="d-flex flex-column align-items-center">
                                            <button onClick={() => this.onDateClick(mois)} className="link link--white link--strong" type="button">{dateFormat(mois, 'MMM YY', true)}</button>
                                            <button
                                                type='button'
                                                onClick={() => this.onMensuelDetailClick(mois)}
                                                className={classNames({
                                                    "m-t-10 action-btn action-btn--success": true,
                                                    "active": this.state.journalierMois.includes(mois),
                                                    "disabled hidden": !fl_has_rej
                                                })}
                                            >
                                                <i className="icon-add"></i>
                                            </button>
                                        </div>
                                    </TABLE.TH>
                                    {this.state.journalierMois.includes(mois) && this.state.journalierTableData[mois]?.dates?.map((date: any, index: number) => (
                                        <TABLE.TH light key={index}>
                                            <div className="d-flex justify-content-center">
                                                <button onClick={() => this.onDailyDateClick(date.id)} className="link link--white" type="button">
                                                    {date.date}
                                                </button>
                                            </div>
                                        </TABLE.TH>
                                    ))}
                                </React.Fragment>)}
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.tableData.criteres.map((critere: any) => (
                                <TABLE.TR key={critere.name} dark>
                                    <TABLE.TD fixed={{ left: 0 }} light>
                                        <div className="d-flex justify-content-between">
                                            <strong>{critere.name}</strong>
                                            <div className="text-right">
                                                <div className="m-b-5">{critere?.unite_mesure || '\xa0'}</div>
                                                {critere.double && <div className="small">Note</div>}
                                            </div>
                                        </div>
                                    </TABLE.TD>
                                    {critere.entries.map((entry: any, i: number) => (<React.Fragment key={`${entry.mois_facturation}-${critere.name}-${i}`}>
                                        <TABLE.TD horsSeuil={entry.hors_seuil}>
                                            {critere.double ? (<>
                                                <div className={classNames({ "m-b-5 text-center": true, 'font-weight-bold': entry.displayBold })}>
                                                    {!!entry.value && <>
                                                        {entry.hors_paiement ? (<span data-tip="Résultat hors paiement">({entry?.signe}{numberFormat(entry.value, critere?.nb_decimales)})</span>) : (<>{entry?.signe}{numberFormat(entry.value, critere?.nb_decimales)}</>)}
                                                    </>}
                                                    {!!entry.appreciation && <>
                                                        {entry.hors_paiement ? (<span data-tip="Résultat hors paiement">({entry.appreciation})</span>) : (<>{entry.appreciation}</>)}
                                                    </>}
                                                    {(!entry.value && !entry.appreciation) && <>-</>}
                                                </div>
                                                <div className="text-center small">{entry.classe_mensuelle_paie || '-'}</div>
                                            </>) : (
                                                <div className="text-center">{entry?.signe}{numberFormat(entry.value, critere?.nb_decimales)}</div>
                                            )}
                                        </TABLE.TD>
                                        {this.state.journalierMois.includes(entry.mois_facturation) && (
                                            <JournalierValuesCells test={critere} cri_id={critere.cri_id} critere_name={critere.name} data={this.state.journalierTableData[entry.mois_facturation]} onDailyDetailClick={this.onDailyDetailClick} />
                                        )}
                                    </React.Fragment>))}
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>
                </div>
                <div className="mobile-only">
                    <TABLE responsive="accordion">
                        {/* TODO: exp this table */}
                        <TABLE.TBODY>
                            {this.state.cardData.map((res: any) => (
                                <TABLE.TR key={res.mois_facturation} title={dateFormat(res.mois_facturation, 'MMMM YYYY', true)} onTitleClick={() => this.onDateClick(res.mois_facturation)}>
                                    {res.criteres.map((critere: any, i: number) => (
                                        <TABLE.TD key={`${critere.mois_facturation}-${critere.name}-${i}`} label={critere.name} double horsSeuil={critere.hors_seuil}>
                                            <div className="table-accordion__double">
                                                <span className={classNames({ "font-weight-bold": critere.displayBold })}>
                                                    {critere.unite_mesure && <strong>{critere.unite_mesure || 'Appréciation'}</strong>}
                                                    {!!critere.value && <>
                                                        {critere.hors_paiement ? (<span data-tip="Résultat hors paiement">({critere?.signe}{numberFormat(critere.value, critere?.nb_decimales)})</span>) : (<>{critere?.signe}{numberFormat(critere.value, critere?.nb_decimales)}</>)}
                                                    </>}
                                                    {!!critere.appreciation && <>
                                                        {critere.hors_paiement ? (<span data-tip="Résultat hors paiement">({critere.appreciation})</span>) : (<>{critere.appreciation}</>)}
                                                    </>}
                                                </span>
                                                {critere.double && <span><strong>Note</strong><span>{critere.classe_mensuelle_paie || '-'}</span></span>}
                                            </div>
                                        </TABLE.TD>
                                    ))}
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>
                </div>
            </>)}
            {this.state.dailyDetailModal && (
                <DailyDetailsModal dailyDetails={this.state.dailyDetailModal} onClose={() => this.setState({ dailyDetailModal: null })} />
            )}
            {this.state.detailsModal && (
                <DetailsModalMensuel
                    mensuelDetail={this.state.detailsModal}
                    onExportPdf={this.onExportPdfDetail}
                    onClose={() => this.setState({ detailsModal: null })}
                />
            )}
            {this.state.detailsEchModal && (
                <DetailsModal echDetails={this.state.detailsEchModal} onClose={() => this.setState({ detailsEchModal: null })} />
            )}
        </>) : (<>
            <div className="charts-filters">
                <div className="charts-filters__section">
                    <label className="charts-filters__label">Sélectionner un ou deux critère(s) :</label>
                    <div className="charts-filters__input">
                        <FormSelect value={this.state.filters.cri_id_1} name="cri_id_1" handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.criteres} />
                    </div>
                    <div className="charts-filters__input">
                        <FormSelect value={this.state.filters.cri_id_2} name="cri_id_2" handle={(e: any) => this.handleInputChange(e, null, 'filters')} options={this.state.options.criteres} />
                    </div>
                </div>
                <div className="charts-filters__section charts-filters__dates">
                    <label className="charts-filters__label" htmlFor="date_debut--charts">Période du</label>
                    <div className="charts-filters__input">
                        <FormDatePicker
                            id="date_debut--charts"
                            month
                            name="date_debut"
                            type="text"
                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                            value={datePickerFormat(this.state?.filters?.date_debut)}
                            isClearable />
                    </div>
                    <label className="charts-filters__label" htmlFor="date_fin--charts">au</label>
                    <div className="charts-filters__input">
                        <FormDatePicker
                            id="date_fin--charts"
                            month
                            name="date_fin"
                            type="text"
                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                            value={datePickerFormat(this.state?.filters?.date_fin)}
                            isClearable />
                    </div>
                </div>
                <div className="charts-filters__section charts-filters__submit">
                    <button type="button" onClick={this.getResultatsForCharts} className="btn btn-success">Appliquer</button>
                </div>
            </div>
            {this.state.chartsData.length > 0 ? (
                <MensuelCharts
                    data={this.state.chartsData}
                    production={this.props.production}
                    logo_path={this.state.logo_path}
                    date_debut={this.state.filters.date_debut}
                    date_fin={this.state.filters.date_fin}
                />
            ) : (
                <TABLE.NO_RESULT />
            )}
        </>)
    }
}

const JournalierValuesCells = ({ cri_id, critere_name, data, onDailyDetailClick }: any) => {
    const critere = data.criteres.find((cri: any) => cri.cri_id === cri_id)

    const double = critere?.double
    const entries = critere?.entries
        || data.dates.map((date: any) => ({ id: date.id, value: '' }))

    return <>
        {entries.map((entry: any) => (
            <TABLE.TD light key={`${critere_name}-${entry.id}`} horsSeuil={entry.hors_seuil}>
                <div {...(entry.rej_id && { className: 'table-clickable-cell', onClick: () => onDailyDetailClick(entry.rej_id) })}>
                    {double ? (<>
                        {['ANN', 'SUS'].includes(entry.statut?.code) ? (
                            <div className="m-b-5 text-center">{entry?.statut?.libelle}</div>
                        ) : (<>
                            <div className={classNames({ "m-b-5 text-center": true, 'font-weight-bold': entry.displayBold })}>
                                {!!entry.value && <>
                                    {entry.hors_paiement ? (<span data-tip="Résultat hors paiement">({entry?.signe}{entry.value})</span>) : (<>{entry?.signe}{numberFormat(entry.value, critere.nb_decimales)}</>)}
                                </>}
                                {!!entry.appreciation && <>
                                    {entry.hors_paiement ? (<span data-tip="Résultat hors paiement">({entry.appreciation})</span>) : (<>{entry.appreciation}</>)}
                                </>}
                                {(!entry.value && !entry.appreciation) && <>-</>}
                            </div>
                            <div className="text-center small">{entry.note || '-'}</div>
                        </>)}
                    </>) : (
                        <div className="text-center">{entry?.signe}{entry.value || '-'}</div>
                    )}
                </div>
            </TABLE.TD>
        ))}
    </>
}

export default withTranslation()(withRouter(ResultatMensuel));