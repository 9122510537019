import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { Forbidden } from "../../_error_pages";
import {hasPermission} from "../../../helpers/helpers";

const REDIRECTIONS = [
    {permissions: ['DRT_FLUX_RES_IMPORT'], path: '/flux/imports_exports_resultats/imports'},
    {permissions: ['DRT_FLUX_RES_EXPORT'], path: '/flux/imports_exports_resultats/exports'},
]

class AccueilFluxResultats extends CustomComponent {
    componentDidMount() {
        for (let redirection of REDIRECTIONS) {
            if (hasPermission(redirection.permissions)) return this.props.history.push(redirection.path)
        }
    }

    render () {
        return <Forbidden />
    }
}

export default withRouter(AccueilFluxResultats);