import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { AuditFields } from '../../../components/AuditFields';
import BreadCrumb from '../../../components/BreadCrumb';
import CustomComponent from '../../../components/CustomComponent';
import { FormInput, FormInputSelect, FormMultiCheckBox, FormRadioValues } from '../../../components/FormComponents';
import { DefaultLayout } from '../../../components/Layouts';
import Panel from '../../../components/Panel';
import { FORMAT_FICHIER, FORMAT_FICHIER_OPTIONS } from '../../../enum/format_fichier';
import { MEDIA, MEDIA_OPTIONS } from '../../../enum/media';
import { TYPES_ROUTAGE, TYPES_ROUTAGE_OPTIONS } from '../../../enum/type_routage';
import { TYPES_EXPORT, TYPES_EXPORT_OPTIONS } from '../../../enum/types_export';
import { TYPES_LAITERIE, TYPES_LAITERIE_OPTIONS } from '../../../enum/types_laiterie';
import { TYPES_RESULTATS, TYPES_RESULTATS_OPTIONS } from '../../../enum/types_resultats';
import { TOAST_TYPE, hasPermission, sendToast } from '../../../helpers/helpers';
import { initValidator } from '../../../validations/validations';
import { DeleteModal } from './_deleteModal';

class ExportLaiterieUpdate extends CustomComponent {
    private _isMounted = false;
    private _screen_type
    private _entity_id
    private validator

    constructor(props: any) {
        super(props);

        this._entity_id = this.props.id || this.props.match.params.id;
        this._screen_type = this.props.screen_type || 'UPDATE';

        let page_title = "Modification d'un export laiterie";
        if (this._screen_type === 'DETAILS') page_title = "Consultation d'un export laiterie";

        this.state = {
            page_title: page_title,
            export_laiterie: {
                ent_id_destinataire: '',
                mailing_list: [''],
                email_destinataire: '',
                type_routage: TYPES_ROUTAGE.LAI,
                ent_id_lai: '',
                media: MEDIA.MAIL,
                type_laiterie: [],
                grp_id: '',
                type_resultat: '',
                type_export: '',
                format_fichier: FORMAT_FICHIER.CSV,
            },
            audit: null,
            options: {
                ent_id_destinataire: [],
                ent_id_lai: [],
                grp_id: [],
                type_routage: TYPES_ROUTAGE_OPTIONS,
                type_laiterie: TYPES_LAITERIE_OPTIONS,
                type_resultat: TYPES_RESULTATS_OPTIONS,
                type_export: TYPES_EXPORT_OPTIONS,
                format_fichier: FORMAT_FICHIER_OPTIONS,
                media: MEDIA_OPTIONS,
            },
            has_email_source: true,
            display_email_destinataire_2: false,
            isLoading: true,
        }
        this.validator = initValidator()
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = `Infolabo | ${this.state.page_title}`;
        this.getExportLaiterie()
    }

    getExportLaiterie = async () => {
        const URL = `/exp_export_laiterie/${this._entity_id}`;
        const data = await this.request(URL, 'GET');

        if (data && data.statusCode === 200) {
            const export_laiterie = data.export_laiterie;

            const type_laiterie = [];
            if (export_laiterie.fl_lai_typ_ca) type_laiterie.push(TYPES_LAITERIE.CA);
            if (export_laiterie.fl_lai_typ_cc) type_laiterie.push(TYPES_LAITERIE.CC);
            if (export_laiterie.fl_lai_typ_ud) type_laiterie.push(TYPES_LAITERIE.UD);

            const mailing_list = data.export_laiterie.mailing_list ? JSON.parse(data.export_laiterie.mailing_list) : [''];

            if (mailing_list.length === 0) mailing_list.push('');

            this._isMounted && this.setState({
                export_laiterie: {
                    ...export_laiterie,
                    ent_id_lai: export_laiterie.ent_id_lai || '',
                    grp_id: export_laiterie.grp_id || '',
                    type_laiterie,
                    mailing_list,
                },
                has_email_source: export_laiterie.media === MEDIA.MAIL,
                audit: data.audit,
                isLoading: false
            }, () => {
                this.getEntiteOptions(null, 'DESTINATAIRE', export_laiterie.ent_id_destinataire)
                this.getEntiteOptions(null, 'LAITERIE', export_laiterie.ent_id_lai)
                this.getGroupeOptions(null, export_laiterie.grp_id)
            });
        }
    }

    getEntiteOptions = async (e: any | null = null, type_entite: 'DESTINATAIRE' | 'LAITERIE', id?: number) => {
        const ROUTE = {
            'DESTINATAIRE': 'destinataire',
            'LAITERIE': 'laiterie'
        };

        let URL: string = `/exp_export_laiterie/autocomplete/${ROUTE[type_entite]}`;

        if (e) {
            if (e.action.action !== 'input-change') return;
            URL += `?query=${e.data}`;
        } else if (id) {
            URL += `?id=${id}`;
        }

        const data = await this.request(URL, 'GET');

        if (data && data.length) {
            const new_options = data.map((option: any) => ({ label: option.label, value: option.id, types_entites: option.types_entites, mail_entite: option.mail_entite }));

            switch (type_entite) {
                case 'DESTINATAIRE':
                    this._isMounted && this.setState((prev: any) => ({
                        options: { ...prev.options, ent_id_destinataire: new_options }
                    }));
                    break;
                case 'LAITERIE':
                    this._isMounted && this.setState((prev: any) => ({
                        options: { ...prev.options, ent_id_lai: new_options }
                    }));
                    break;
                default:
                    break;
            }
        }
    }

    getGroupeOptions = async (e: any, id?: number) => {
        let URL: string = `/ent_groupe/autocomplete`;
        const params = new URLSearchParams();
        params.append('type_groupe', 'E');

        if (e) {
            if (e.action.action !== 'input-change') return;
            params.append('query', e.data);
        } else if (id) {
            params.append('id', id.toString());
        }

        const data = await this.request(`${URL}?${params.toString()}`, 'GET');

        if (data && data.statusCode === 200) {
            const new_options = data.groupes.map((option: any) => ({ label: option.nom, value: option.id }));

            this._isMounted && this.setState((prev: any) => ({
                options: { ...prev.options, grp_id: new_options }
            }))
        }
    }

    afterSubmission = async (e: any) => {
        e.preventDefault();
        const { t } = this.props;

        this._isMounted && this.setState({ isLoading: true, isSubmitted: true });
        if (this.validator.allValid()) {
            await this.updateExportLaiterie();
        } else {
            this.validator.showMessages();
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }
        this._isMounted && this.setState({ isLoading: false });
    }

    updateExportLaiterie = async () => {
        const URL = `/exp_export_laiterie/${this._entity_id}`;
        const export_laiterie = this.state.export_laiterie;

        const BODY = {
            ...export_laiterie,
            mailing_list: this.state.has_email_source ? JSON.stringify(export_laiterie.mailing_list) : "",
            fl_lai_typ_cc: export_laiterie.type_laiterie.includes('CC'),
            fl_lai_typ_ca: export_laiterie.type_laiterie.includes('CA'),
            fl_lai_typ_ud: export_laiterie.type_laiterie.includes('UD'),
        };

        this.setState({ isLoading: true });
        const data = await this.request(URL, 'PATCH', BODY);
        this.setState({ isLoading: false });

        if (data && data.statusCode === 201) {
            sendToast(data.message, TOAST_TYPE.SUCCESS);
            this.props.history.push(`/flux/exports_laiterie/${this._entity_id}`);
        }
    }

    renderButtons = () => {
        if (this._screen_type === 'DETAILS') return <>
            {hasPermission('DRT_FLUX_EXPORT_LAITERIE') && <Link to={`/flux/exports_laiterie`} className="btn m-r-5">Annuler</Link>}
            {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <button type="button" className="btn btn-danger m-r-5" onClick={() => this.setState({ deleteModal: this.state.export_laiterie })}>Supprimer</button>}
            {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <Link to={`/flux/exports_laiterie/${this._entity_id}/modifier`} className="btn btn-dark">Modifier</Link>}
        </>

        return <>
            {hasPermission('DRT_FLUX_EXPORT_LAITERIE') && <Link to={`/flux/exports_laiterie/${this._entity_id}`} className="btn m-r-5">Annuler</Link>}
            {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <button type="button" className="btn btn-danger m-r-5" onClick={() => this.setState({ deleteModal: this.state.export_laiterie })}>Supprimer</button>}
            {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <button type="submit" className='btn btn-secondary'>Enregistrer</button>}
        </>
    }

    handleMailingListChange(e: any) {
        const value = e.target.value;
        const index = e.target.name.split('--').pop();

        this.setState((prev: any) => ({
            export_laiterie: {
                ...prev.export_laiterie,
                mailing_list: prev.export_laiterie.mailing_list.map((email: string, i: number) => {
                    if (i === +index) return value;
                    return email
                })
            }
        }));
    }

    addEmailToMailingList = () => {
        this.setState((prev: any) => ({
            export_laiterie: {
                ...prev.export_laiterie,
                mailing_list: [...prev.export_laiterie.mailing_list, '']
            }
        }));
    }

    removeEmailFromMailingList = (index: number) => {
        this.setState((prev: any) => ({
            export_laiterie: {
                ...prev.export_laiterie,
                mailing_list: prev.export_laiterie.mailing_list.length === 1
                    ? ['']
                    : prev.export_laiterie.mailing_list.filter((_: any, i: number) => i !== +index)
            }
        }));
    }

    updateHasEmailSource = () => {
        this.setState({
            has_email_source: this.state.export_laiterie.media === MEDIA.MAIL
        });
    }

    getFilteredOptionsLaiterie = () => {
        const ent_id_lai = this.state.export_laiterie.ent_id_lai;
        const selected = this.state.options.ent_id_lai.find((option: any) => option.value === ent_id_lai);

        return this.state.options.type_laiterie.map((option: any) => {
            let disabled = !selected
            if (option.value === "CA" && !selected?.types_entites.includes('CEAPP')) disabled = true
            if (option.value === "CC" && !selected?.types_entites.includes('CECOL')) disabled = true
            if (option.value === "UD" && !selected?.types_entites.includes('CEDEP')) disabled = true
            return { ...option, disabled }
        });
    }

    getFilteredOptionsTypeResultats = () => {
        const type_routage = this.state.export_laiterie.type_routage;
        const selected = this.state.export_laiterie.type_resultat;

        if (type_routage === TYPES_ROUTAGE.GRP && selected === TYPES_RESULTATS.RJC) {
            this._isMounted && this.setState((prev: any) => ({
                export_laiterie: {
                    ...prev.export_laiterie,
                    type_resultat: ''
                }
            }));
        }

        return this.state.options.type_resultat.filter((option: any) => {
            return !(type_routage === TYPES_ROUTAGE.GRP && option.value === TYPES_RESULTATS.RJC)
        });
    }

    getFilteredOptionsTypeExport = () => {
        const type_resultat = this.state.export_laiterie.type_resultat;
        const selected = this.state.export_laiterie.type_export;

        if (type_resultat === TYPES_RESULTATS.RM && [TYPES_EXPORT.CUMUL_FINJOUR, TYPES_EXPORT.CUMUL_IMMEDIAT].includes(selected)) {
            this._isMounted && this.setState((prev: any) => ({
                export_laiterie: {
                    ...prev.export_laiterie,
                    type_export: ''
                }
            }));
        }

        return this.state.options.type_export.filter((option: any) => {
            return !(type_resultat === TYPES_RESULTATS.RM && [TYPES_EXPORT.CUMUL_FINJOUR, TYPES_EXPORT.CUMUL_IMMEDIAT].includes(option.value))
        });
    }

    handleTypeLaiterieChange = (e: any) => {
        const value = e.target.value;
        this.setState((prev: any) => {
            const values = prev.export_laiterie.type_laiterie
            return {
                export_laiterie: {
                    ...prev.export_laiterie,
                    type_laiterie: values.includes(value) ? values.filter((val: string) => val !== value) : [...values, value]
                }
            }
        });
    }

    resetTypesLaiterie = () => {
        this._isMounted && this.setState((prev: any) => ({
            export_laiterie: {
                ...prev.export_laiterie,
                type_laiterie: []
            }
        }));
    }

    setEntiteDestinataireEmail = () => {
        const { ent_id_destinataire, mailing_list, email_destinataire } = this.state.export_laiterie;
        const firstMailOfMailingList = mailing_list[0];
        const entite_destinataire = this.state.options.ent_id_destinataire.find((option: any) => option.value === ent_id_destinataire);
        const mail_entite = entite_destinataire.mail_entite;

        if (firstMailOfMailingList === email_destinataire || firstMailOfMailingList === '') mailing_list.shift();
        if (mail_entite) mailing_list.unshift(mail_entite);
        if (mailing_list.length === 0) mailing_list.push('');

        this.setState((prev: any) => ({
            export_laiterie: {
                ...prev.export_laiterie,
                mailing_list,
                email_destinataire: mail_entite
            }
        }));
    }

    render() {
        return <DefaultLayout loading={this.state.isLoading}>
            <div className="container">
                <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }, { name: 'Export laiterie', path: '/flux/exports_laiterie' }, { name: this.state.page_title }]} />
                <form onSubmit={(e) => this.afterSubmission(e)}>
                    <div className="d-flex justify-content-between flex-wrap">
                        <h1 className="main-title m-r-50">{this.state.page_title}</h1>
                        <div className="m-b-30">{this.renderButtons()}</div>
                    </div>
                    <Panel title="Destinataire" togglableID='panel-1' open>
                        <div className="large-gutters">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group form-group--inline m-b-30">
                                        <FormInputSelect
                                            label="Nom de l'entité / SIRET"
                                            name="ent_id_destinataire"
                                            id="ent_id_destinataire"
                                            simpleValidator={this.validator}
                                            required
                                            isClearable
                                            value={this.state.export_laiterie.ent_id_destinataire}
                                            handleInput={(e: any) => this.getEntiteOptions(e, 'DESTINATAIRE')}
                                            handle={(e: any) => this.handleInputChange(e, null, 'export_laiterie', this.setEntiteDestinataireEmail)}
                                            options={this.state.options.ent_id_destinataire}
                                            disabled />
                                    </div>
                                </div>
                                <div className="col">
                                    {this.state.export_laiterie.mailing_list.map((email: string, index: number) => (
                                        <div className={`form-group form-group--inline`}>
                                            <FormInput
                                                label={`Email ${index + 1}`}
                                                name={`mailing_list--${index}`}
                                                id={`mailing_list--${index}`}
                                                value={this.state.export_laiterie.mailing_list[index]}
                                                handle={(e: any) => this.handleMailingListChange(e)}
                                                maxLength={70}
                                                validator_type="email"
                                                simpleValidator={this.validator}
                                                required={this.state.has_email_source && index === 0}
                                                onClear={this._screen_type === 'DETAILS' ? undefined : () => this.removeEmailFromMailingList(index)}
                                                disabled={this._screen_type === 'DETAILS' || !this.state.has_email_source}>
                                                {this._screen_type !== 'DETAILS' && !!email && this.state.has_email_source && index === this.state.export_laiterie.mailing_list.length - 1 && (
                                                    <button type="button" onClick={this.addEmailToMailingList} className="action-btn action-btn--light action-btn--large m-l-10">
                                                        <i className="icon-add"></i>
                                                    </button>
                                                )}
                                            </FormInput>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Panel title="Données à transmettre" togglableID='panel-2' open>
                        <div className="large-gutters row">
                            <div className="col-12">
                                <div className="form-group form-group--inline form-group--radio m-b-30">
                                    <FormRadioValues
                                        required
                                        label="Source des données"
                                        id={`type_routage`}
                                        name={`type_routage`}
                                        value={this.state.export_laiterie.type_routage}
                                        options={this.state.options.type_routage}
                                        handle={(e: any) => this.handleInputChange(e, null, 'export_laiterie', () => this.validator.purgeFields())}
                                        isSubmitted={this.state.isSubmitted}
                                        disabled />
                                </div>
                                {this.state.export_laiterie.type_routage === TYPES_ROUTAGE.LAI && (<>
                                    <div className="form-group form-group--inline m-b-30">
                                        <FormInputSelect
                                            label="Nom de l'entité / SIRET"
                                            name={`ent_id_lai`}
                                            id={`ent_id_lai`}
                                            required={this.state.export_laiterie.type_routage === TYPES_ROUTAGE.LAI}
                                            isClearable
                                            simpleValidator={this.validator}
                                            value={this.state.export_laiterie.ent_id_lai}
                                            handleInput={(e: any) => this.getEntiteOptions(e, 'LAITERIE')}
                                            handle={(e: any) => this.handleInputChange(e, null, 'export_laiterie', this.resetTypesLaiterie())}
                                            options={this.state.options.ent_id_lai}
                                            disabled />
                                    </div>
                                    <div className="form-group form-group--inline form-group--radio m-b-30">
                                        <FormMultiCheckBox
                                            label="Type de la laiterie"
                                            name={`type_laiterie`}
                                            id={`type_laiterie`}
                                            handle={this.handleTypeLaiterieChange}
                                            required={this.state.export_laiterie.type_routage === TYPES_ROUTAGE.LAI && !!this.state.export_laiterie.ent_id_lai}
                                            values={this.state.export_laiterie.type_laiterie}
                                            options={this.getFilteredOptionsLaiterie()}
                                            disabled />
                                    </div>
                                </>)}
                                {this.state.export_laiterie.type_routage === TYPES_ROUTAGE.GRP && (
                                    <div className="form-group form-group--inline m-b-30">
                                        <FormInputSelect
                                            label="Groupe"
                                            name={`grp_id`}
                                            id={`grp_id`}
                                            required={this.state.export_laiterie.type_routage === TYPES_ROUTAGE.GRP}
                                            isClearable
                                            simpleValidator={this.validator}
                                            value={this.state.export_laiterie.grp_id}
                                            handleInput={(e: any) => this.getGroupeOptions(e)}
                                            handle={(e: any) => this.handleInputChange(e, null, 'export_laiterie')}
                                            options={this.state.options.grp_id}
                                            disabled />
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline m-b-30">
                                    <FormInputSelect
                                        label="Type de résultats"
                                        name={`type_resultat`}
                                        id={`type_resultat`}
                                        required
                                        simpleValidator={this.validator}
                                        isClearable
                                        value={this.state.export_laiterie.type_resultat}
                                        handle={(e: any) => this.handleInputChange(e, null, 'export_laiterie')}
                                        options={this.getFilteredOptionsTypeResultats()}
                                        disabled={this._screen_type === 'DETAILS'} />
                                </div>
                                <div className="form-group form-group--inline m-b-30">
                                    <FormInputSelect
                                        label="Type d'export"
                                        name={`type_export`}
                                        id={`type_export`}
                                        required
                                        simpleValidator={this.validator}
                                        isClearable
                                        value={this.state.export_laiterie.type_export}
                                        handle={(e: any) => this.handleInputChange(e, null, 'export_laiterie')}
                                        options={this.getFilteredOptionsTypeExport()}
                                        disabled={this._screen_type === 'DETAILS'} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline form-group--radio m-b-30">
                                    <FormRadioValues
                                        required
                                        label="Média"
                                        id={`media`}
                                        name={`media`}
                                        value={this.state.export_laiterie.media}
                                        options={this.state.options.media}
                                        handle={(e: any) => this.handleInputChange(e, null, 'export_laiterie', this.updateHasEmailSource)}
                                        isSubmitted={this.state.isSubmitted}
                                        disabled={this._screen_type === 'DETAILS'} />
                                </div>
                                <div className="form-group form-group--inline form-group--radio m-b-30">
                                    <FormRadioValues
                                        required
                                        label="Format fichier"
                                        id={`format_fichier`}
                                        name={`format_fichier`}
                                        value={this.state.export_laiterie.format_fichier}
                                        options={this.state.options.format_fichier}
                                        handle={(e: any) => this.handleInputChange(e, null, 'export_laiterie')}
                                        isSubmitted={this.state.isSubmitted}
                                        disabled={this._screen_type === 'DETAILS'} />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </form>
            </div>

            {this.state.audit && <AuditFields audit={this.state.audit} />}

            {this.state.deleteModal && (
                <DeleteModal
                    exportLaiterie={this.state.deleteModal}
                    onDelete={() => this.props.history.push(`/flux/exports_laiterie`)}
                    onClose={() => this.setState({ deleteModal: null })} />
            )}
        </DefaultLayout>
    }
}

export default withTranslation()(withRouter(ExportLaiterieUpdate));
