import React, { PropsWithChildren, useState } from "react";
import { Collapse, Dropdown } from "react-bootstrap";
import { TOAST_TYPE, sendToast } from '../helpers/helpers';
import { criteriaFilterIsValid } from './CriteriaPanel';
import CustomToggle from "./CustomToggle";
import { FormInput } from './FormComponents';
import Modal from './Modal';

interface FilterDropdownProps extends PropsWithChildren {
    parent: any,
    type_recherche?: string,
    facets?: string[],
    filters: any,
    recherches?: Object[],
    onUpdate?: Function,
    onSearchLoad: Function,
    onFiltersReset: Function,
    onExport?: {
        pdf?: Function,
        excel?: Function,
        csv?: Function
    },
    onFiltersSearch: Function,
    startOpen?: boolean,
    onApplyFilters?: Function,
    disabledExport?: boolean,
}

export const FilterDropdown = ({ parent, children, type_recherche, facets, onExport, filters, recherches, onUpdate, onFiltersReset, onApplyFilters, onSearchLoad, startOpen, disabledExport = false }: FilterDropdownProps) => {
    const [open, setOpen] = useState(startOpen || false);
    const [saveModalOpen, setSaveModalOpen] = useState(false)
    const [nom_recherche, setNom_recherche] = useState('')
    const [defaultFilters] = useState(filters);

    const isFiltered = Object.keys(filters).some((key: string) => {
        if (['orderBy', 'ordering'].includes(key)) return false

        if (key === 'criteres') {
            return filters[key].filter((critere: any) => criteriaFilterIsValid(critere)).length > 0
        }

        return defaultFilters[key] !== filters[key];
    });

    const onSaveSubmit = async (e: any) => {
        e.preventDefault()

        Object.keys(filters).forEach(key => {
            if (filters[key] instanceof Date) filters[key] = (filters[key] as Date).toISOString();
            if (key === 'criteres') filters[key] = filters[key].filter((critere: any) => criteriaFilterIsValid(critere))
        });

        const data = await parent.request('/inf_recherche', 'POST', {
            type_recherche: type_recherche,
            nom_recherche,
            filtres: JSON.stringify(filters)
        })

        if (data && data.statusCode === 201) {
            sendToast(data.message, TOAST_TYPE.SUCCESS);
            setSaveModalOpen(false)
            setNom_recherche('')
            onUpdate && onUpdate()
        }
    }

    const onDelete = async (e: any) => {
        e.preventDefault()
        const id = e.target.value

        const data = await parent.request(`/inf_recherche/${id}`, 'DELETE')

        if (data && data.statusCode === 201) {
            sendToast(data.message, TOAST_TYPE.SUCCESS);
            onUpdate && onUpdate()
        }
    }

    const handleSaveButton = () => {
        if (recherches && recherches.length >= 5) {
            sendToast(<>Nombre maximum de recherches enregistrées atteint.<br />Veuillez en supprimer une pour pouvoir en enregistrer une nouvelle.</>, TOAST_TYPE.ERROR);
        } else {
            setSaveModalOpen(true);
        }
    }

    const handleExportButton = (e: any, callBack?: Function) => {
        e.preventDefault();
        callBack && callBack();
    }

    const showToast = () => {
        sendToast("Fonctionnalité prochainement disponible.", TOAST_TYPE.INFO);
    }

    return (<>
        <div className="filter-dropdown">
            <div className="filter-dropdown__header">
                <button type="button" className={`filter-dropdown__toggle${open ? ' open' : ''}`} onClick={() => setOpen(!open)}>Filtrer &nbsp; {isFiltered && <i>Filtres appliqués</i>}</button>
                {onExport && (
                    <Dropdown className='filter-dropdown__export'>
                        <Dropdown.Toggle
                            {...(disabledExport && { onClick: () => showToast() })}
                            title={disabledExport ? "Fonctionnalité prochainement disponible." : ""}
                            className={"btn-icon btn-icon--text " + (disabledExport ? "btn-icon--grey" : "btn-icon--green")} as="button">
                            <i className="icon-export"></i>
                            <span>Exporter</span>
                        </Dropdown.Toggle>
                        {
                            disabledExport ?
                                <></>
                                :
                                <Dropdown.Menu>
                                    {onExport.pdf && <Dropdown.Item as="button" onClick={(e: any) => handleExportButton(e, onExport.pdf)}>PDF</Dropdown.Item>}
                                    {onExport.excel && <Dropdown.Item as="button" onClick={(e: any) => handleExportButton(e, onExport.excel)}>Excel</Dropdown.Item>}
                                    {onExport.csv && <Dropdown.Item as="button" onClick={(e: any) => handleExportButton(e, onExport.csv)}>CSV</Dropdown.Item>}
                                </Dropdown.Menu>
                        }
                    </Dropdown>
                )}
            </div>
            <Collapse in={open}>
                <div>
                    <div className="filter-dropdown__content">
                        {(recherches && recherches.length > 0) && (
                            <div className="filter-save">
                                <h4 className="filter-save__title">Mes recherches</h4>
                                <div className="filter-save__items">
                                    {recherches.map((recherche: any) => (
                                        <React.Fragment key={recherche.id}>
                                            <FilterFavorite {...{ recherche, onDelete, onSearchLoad }} />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="large-gutters">
                            {children}
                        </div>
                        <div className="filter-dropdown__footer">

                            <button onClick={() => onFiltersReset()} type="button" disabled={!isFiltered} className="btn btn-danger">Réinitialiser</button>
                            {(!!type_recherche && !!onUpdate && !!recherches) && (<button onClick={handleSaveButton} type="button" className={`btn btn-dark ${recherches.length >= 5 && 'disabled'}`}>Sauvegarder</button>)}
                            {onApplyFilters && (<button type="button" onClick={() => onApplyFilters()} className='btn btn-success'>Rechercher</button>)}
                        </div>
                    </div>
                </div>
            </Collapse>
            {facets && (
                <div className="filter-dropdown__facets">
                    {facets.map(facet => (
                        <span className="facet">{facet}</span>
                    ))}
                </div>
            )}
        </div >
        <Modal title="Sauvegarder une recherche" open={saveModalOpen}>
            <form className="m-t-30" onSubmit={onSaveSubmit}>
                <div className="form-group form-group--white">
                    <FormInput
                        type="text"
                        label="Nom de la recherche"
                        id="nom_recherche"
                        value={nom_recherche}
                        handle={(e: any) => setNom_recherche(e.target.value)}
                    />
                </div>
                <div className="modal-footer justify-content-end">
                    <button type="button" onClick={() => setSaveModalOpen(false)} className="btn btn-white">Annuler</button>
                    <button type="submit" className="btn btn-secondary">Enregistrer</button>
                </div>
            </form>
        </Modal>
    </>)
}

export const FilterFavorite = ({ recherche, onDelete, onSearchLoad }: any) => {
    return (
        <div className="filter-save__item">
            <div data-tip={recherche.nom_recherche} className="filter-save__name" onClick={() => onSearchLoad(JSON.parse(recherche.filtres))}>{recherche.nom_recherche}</div>
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                    <span className="sr-only">Ouvrir les actions</span>
                    <i className="icon-actions"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu flip>
                    <button className="dropdown-item" type="button" value={recherche.id} onClick={onDelete}>Supprimer</button>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}