import {dateFormat, getSession, Request} from "../helpers/helpers";
import saveAs from "file-saver";

type IExportCSVFlux = (
    type: 'EXPORTS_RESULTATS'|'IMPORTS_RESULTATS'|'SUIVI_PUSH'|'IMPORTS_ANNUAIRE',
    filters: {[key: string]: any},
    dateKeys?: string[],
) => Promise<void>;

const URL_MAP = {
    'EXPORTS_RESULTATS': '/csv/export_export_resultat',
    'IMPORTS_RESULTATS': '/csv/export_import_resultat',
    'SUIVI_PUSH': '/csv/export_suivi_push',
    'IMPORTS_ANNUAIRE': '/csv/export_import_annuaire',
}

export const exportCSVFlux: IExportCSVFlux =  async (type, filters, dateKeys = []) => {
    const URL: string = URL_MAP[type];

    const siret: string = getSession().ent_siret;
    const today: string = dateFormat(undefined, 'YYYYMMDD');
    const fileName: string = `${siret}_${type}_R_${today}.csv`;

    const formattedDateFilters = dateKeys.reduce((obj: {[key: string]: string}, key: string) => ({
        ...obj,
        [key]: filters[key] && filters[key].toISOString(),
    }), {});

    const BODY = {
        ...filters,
        ...formattedDateFilters,
    };

    const blob: Blob = await Request(URL, 'POST', BODY, false, false, true);
    if (!!blob && blob instanceof Blob) {
        saveAs(blob, fileName);
    }

}
