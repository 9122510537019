import saveAs from "file-saver";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import { FilterDropdown } from "../../../components/FilterDropdown";
import { FormDatePicker } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import Pagination from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import {
    STATUTS_SUIVI_EXPORT,
    STATUTS_SUIVI_EXPORT_OPTIONS_RES,
    STATUT_SUIVI_EXPORT_DISPLAY
} from "../../../enum/statuts_suivi_export";
import { SUIVI_MAIL_STATUTS, SUIVI_MAIL_STATUTS_DISPLAY, SUIVI_MAIL_STATUTS_LABEL, SUIVI_MAIL_STATUTS_OK } from "../../../enum/suivi_mail_statuts";
import {
    TYPES_FICHIER_SUIVI_EXPORT_RES,
    TYPES_FICHIER_SUIVI_EXPORT_RES_LABEL,
    TYPES_FICHIER_SUIVI_EXPORT_RES_OPTIONS
} from "../../../enum/types_fichier_suivi_export";
import { XLA_MEDIA_SUIVI_EXPORT } from "../../../enum/xla_media_suivi_export";
import { DebounceQueue } from "../../../helpers/debouncer";
import { TOAST_TYPE, dateFormat, datePickerFormat, getFileNameFromPath, getSession, labelizeEntite, sendToast } from '../../../helpers/helpers';
import { exportCSVFlux } from "../../../requests/exportCSVFlux";
import { ImportExportResultatsHeader } from "./common/ImportExportResultatsHeader";

class ExportsFluxResultats extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    private defaultFilters = {
        orderBy: 'date_fin',
        ordering: 'DESC',
        start_date_range_from: moment().utc().subtract(1, 'month').startOf('day').toDate(),
        start_date_range_to: moment().utc().startOf('day').toDate(),
        fichier: '',
        type_fichier: '',
        statut: '',
        date_fin: '',
        destinataire: '',
        emetteur: '',
    }

    private readonly dateKeys: string[] = [
        'date_fin',
        'start_date_range_from',
        'start_date_range_to',
    ];

    constructor(props: any) {
        super(props);
        this.state = {
            filters: this.defaultFilters,
            options: {
                type_fichier: this.getFilteredTypeFichierOptions(),
                statut: STATUTS_SUIVI_EXPORT_OPTIONS_RES,
            },
            exportsSuivisRes: [],
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            isLoading: false
        }
    }

    componentDidMount() {
        document.title = 'Infolabo | Imports et exports annuaire';
        this._isMounted = true;

        this.getExportsSuivisRes();
    }

    getFilteredTypeFichierOptions = (): {value: string, label: string}[] => {
        const options: {value: string, label: string}[] = TYPES_FICHIER_SUIVI_EXPORT_RES_OPTIONS;
        const type_ent = getSession().type_ent;

        if (!type_ent) return [];

        if (type_ent === 'OPNC') {
            return options.filter((option: any) => ['OPNC'].includes(option.value));
        }

        if (type_ent === 'LABO') {
            return options.filter((option: any) => ['SIGAL', 'EXPJ', 'EXPM', 'RJP', 'RJC', 'RM', 'OPNC'].includes(option.value));
        }

        if (['CEAPP', 'CECOL', 'CEDEP', 'CEPAI', 'GRLAI', 'SLCOL', 'SLTRA'].includes(type_ent)) {
            return options.filter((option: any) => ['RJP', 'RJC', 'RM'].includes(option.value));
        }
        return options;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getExportsSuivisRes = async (triggeredByPagination: boolean = false) => {
        this.setState({isLoading: true});

        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach(key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        })

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (this.dateKeys.includes(key) && typeof val !== 'string') {
                !!val && params.append(key, val.toISOString());
            }
            else if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/inf-suivi-export/res?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                exportsSuivisRes: data.suivisExport,
                paginate: {...prev.paginate, ...data.paginate},
            }));
        }
    }

    handlePerPageChange = (newLimit: number) => {
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                limit: newLimit,
                page: 0,
            }
        }), () => this.queue.push(this.getExportsSuivisRes, [], true));
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getExportsSuivisRes))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getExportsSuivisRes, [true], true))
    }

    fileDownload = async (suiviExport: any) => {
        if (!suiviExport.fichier) {
            sendToast(<p>Impossible de télécharger le fichier</p>, TOAST_TYPE.ERROR);
            return;
        }
        const fileName = suiviExport.type_export === TYPES_FICHIER_SUIVI_EXPORT_RES.SIGAL ?
            `${suiviExport.emetteur.siret}_ExportSigal_${moment().utc().format('YYYYMMDDHHmmss')}.zip`
            : getFileNameFromPath(suiviExport.fichier);

        const blob: Blob = await this.request(`/inf-suivi-export/download/${suiviExport.id}`, 'GET', undefined, false, false, true);

        if (!!blob && blob instanceof Blob) {
            saveAs(blob, fileName);
        }
    }

    handleExport = async () => {
        this.setState({isLoading: true});
        const allFilters = {...this.state.paginate, ...this.state.filters};
        await exportCSVFlux('EXPORTS_RESULTATS', allFilters, this.dateKeys);
        this.setState({isLoading: false});
    }

    formatDestinataire = (suiviExport: any) => {
        switch (suiviExport.type_export) {
            case TYPES_FICHIER_SUIVI_EXPORT_RES.LAIT:
            case TYPES_FICHIER_SUIVI_EXPORT_RES.PONC:
            case TYPES_FICHIER_SUIVI_EXPORT_RES.OPNC:
                return labelizeEntite(suiviExport.destinataire?.siret, suiviExport.destinataire?.nom_usuel);
            default:
                return '';
        }
    }

    formatTypeFichier = (suiviExport: any) => {
        if ([TYPES_FICHIER_SUIVI_EXPORT_RES.LAIT, TYPES_FICHIER_SUIVI_EXPORT_RES.PONC].includes(suiviExport.type_export)) {
            return TYPES_FICHIER_SUIVI_EXPORT_RES_LABEL[suiviExport.type_resultat];
        }
        return TYPES_FICHIER_SUIVI_EXPORT_RES_LABEL[suiviExport.type_export];

    }

    renderStatut = (suiviExport: any) => {
        let statut: string;
        let details: string[];

        switch (suiviExport.statut) {
            case STATUTS_SUIVI_EXPORT.EC:
                statut = STATUT_SUIVI_EXPORT_DISPLAY.EN_COURS;
                details = [SUIVI_MAIL_STATUTS_DISPLAY.EN_COURS_GENERATION];
                break;
            case STATUTS_SUIVI_EXPORT.GENERE:
                statut = STATUT_SUIVI_EXPORT_DISPLAY.EN_COURS;
                details = [SUIVI_MAIL_STATUTS_DISPLAY.FICHIER_GENERE];
                break;
            case STATUTS_SUIVI_EXPORT.KO:
                statut = STATUT_SUIVI_EXPORT_DISPLAY.ERREUR;
                details = [SUIVI_MAIL_STATUTS_DISPLAY.ERREUR_GENERATION];
                break;
            case STATUTS_SUIVI_EXPORT.KO_TRANSMISSION:
                statut = STATUT_SUIVI_EXPORT_DISPLAY.ERREUR;
                details = [SUIVI_MAIL_STATUTS_DISPLAY.ERREUR_TRANSMISSION];
                break;
            case STATUTS_SUIVI_EXPORT.TRANSMIS:
                switch (suiviExport.type_export) {
                    case TYPES_FICHIER_SUIVI_EXPORT_RES.SINAPS:
                    case TYPES_FICHIER_SUIVI_EXPORT_RES.GC:
                        statut = STATUT_SUIVI_EXPORT_DISPLAY.TERMINE;
                        details = [SUIVI_MAIL_STATUTS_DISPLAY.FICHIER_DISPOSITION];
                        return null
                    case TYPES_FICHIER_SUIVI_EXPORT_RES.SIGAL:
                        switch (suiviExport.sig_acquittement) {
                            case "OK":
                                statut= STATUT_SUIVI_EXPORT_DISPLAY.TERMINE;
                                details = [SUIVI_MAIL_STATUTS_DISPLAY.FICHIER_ACQUITTE_OK];
                                break;
                            case "KO":
                                statut = STATUT_SUIVI_EXPORT_DISPLAY.ERREUR;
                                details = [SUIVI_MAIL_STATUTS_DISPLAY.FICHIER_ACQUITTE_KO];
                                break;
                            default:
                                statut = "En cours";
                                details = [SUIVI_MAIL_STATUTS_DISPLAY.FICHIER_NON_ACQUITTE];
                                break;
                        }
                        break;
                    case TYPES_FICHIER_SUIVI_EXPORT_RES.LAIT:
                    case TYPES_FICHIER_SUIVI_EXPORT_RES.PONC:
                    case TYPES_FICHIER_SUIVI_EXPORT_RES.OPNC:
                        switch (suiviExport.xla_media) {
                            case XLA_MEDIA_SUIVI_EXPORT.FTP:
                            case XLA_MEDIA_SUIVI_EXPORT.EN_LIGNE:
                                statut = STATUT_SUIVI_EXPORT_DISPLAY.TERMINE;
                                details = [SUIVI_MAIL_STATUTS_DISPLAY.FICHIER_DISPOSITION];
                                break;
                            case XLA_MEDIA_SUIVI_EXPORT.MAIL:
                                statut = STATUT_SUIVI_EXPORT_DISPLAY.TERMINE;
                                details = [];

                                for (const mail of suiviExport.mails) {
                                    const unknow_status = !SUIVI_MAIL_STATUTS_OK.includes(mail.statut);
                                    const not_delivered_by_provider = mail.statut === SUIVI_MAIL_STATUTS.REQUEST && !mail?.message_id;

                                    if (unknow_status || not_delivered_by_provider) {
                                        statut = STATUT_SUIVI_EXPORT_DISPLAY.ERREUR;
                                    }

                                    let statut_email = SUIVI_MAIL_STATUTS_LABEL[mail.statut] || SUIVI_MAIL_STATUTS_DISPLAY.INCONNU;
                                    if (not_delivered_by_provider) {
                                        statut_email = SUIVI_MAIL_STATUTS_DISPLAY.NON_ENVOYE
                                    }

                                    details.push(`${mail.email} - ${statut_email}`);
                                }
                                break;
                            default:
                                statut = suiviExport.statut;
                                details = [];
                        }
                        break;
                    default:
                        statut = suiviExport.statut;
                        details = [];
                }
                break;
            default:
                statut = suiviExport.statut;
                details = [];
        }

        return (
            <span data-tip={details.join('<br>')} data-type="dark">
                {statut}
            </span>
        );
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <ImportExportResultatsHeader
                        paginate={this.state.paginate}
                        onPerPageChange={this.handlePerPageChange}
                        handleRefresh={() => this.queue.push(this.getExportsSuivisRes, [], true)}
                    />

                    <FilterDropdown
                        parent={this}
                        filters={this.state.filters}
                        onSearchLoad={(filters: any) => this.setState((prev: any) => ({filters: {...prev.filters, ...filters}}), () => this.queue.push(this.getExportsSuivisRes, [], true))}
                        onFiltersReset={() => this.setState({filters: this.defaultFilters}, () => this.queue.push(this.getExportsSuivisRes, [], true))}
                        onFiltersSearch={() => this.queue.push(this.getExportsSuivisRes, [], true)}
                        onApplyFilters={() => this.queue.push(this.getExportsSuivisRes, [], true)}
                        onExport={{csv: this.handleExport}}
                    >
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group-flex">
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="start_date_range_from"
                                            id="start_date_range_from"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.start_date_range_from) || ''}
                                            label="Date de début du"
                                            required
                                        />
                                    </div>
                                    <div className="form-group form-group--inline">
                                        <FormDatePicker
                                            name="start_date_range_to"
                                            id="start_date_range_to"
                                            type="text"
                                            handle={(e: any) => this.handleInputChange(e, null, 'filters')}
                                            value={datePickerFormat(this.state.filters.start_date_range_to) || ''}
                                            label="au"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FilterDropdown>

                    <TABLE fixed responsive="accordion">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH fixed={{left: 0, width: 58}}/>
                                <TABLE.TH name="fichier" fixed={{left: 58}}
                                          input={{placeholder: 'Nom du fichier', value: this.state.filters.fichier, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsSuivisRes))}}
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('fichier')}}
                                />
                                <TABLE.TH name="type_fichier"
                                          select={{placeholder: 'Type', value: this.state.filters.type_fichier, options: this.state.options.type_fichier, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsSuivisRes))}}
                                />
                                <TABLE.TH name="statut"
                                          select={{placeholder: 'Statut', value: this.state.filters.statut, options: this.state.options.statut, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsSuivisRes))}}
                                />
                                <TABLE.TH name="date_fin"
                                          datePicker={{value: this.state.filters.date_fin, placeholder: 'Transmission', onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsSuivisRes))}}
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('date_fin')}}
                                />
                                <TABLE.TH name="nb_lignes" label="Nb lignes"
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('nb_lignes')}}
                                />
                                <TABLE.TH name="destinataire"
                                          input={{placeholder: 'Destinataire', value: this.state.filters.destinataire, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsSuivisRes))}}
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('destinataire')}}
                                />
                                <TABLE.TH name="emetteur"
                                          input={{placeholder: 'Emetteur', value: this.state.filters.emetteur, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsSuivisRes))}}
                                          sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('emetteur')}}
                                />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.exportsSuivisRes.map((suivi_export: any) => (
                                <TABLE.TR title={getFileNameFromPath(suivi_export.fichier) || '-'} key={suivi_export.id}>
                                    <TABLE.TD fixed={{left: 0, width: 58}} hiddenMobile>
                                        {suivi_export.fichier && (
                                            <button title="Télécharger fichier ZIP" type="button" className="action-btn"
                                                onClick={() => this.fileDownload(suivi_export)}
                                            >
                                                <i className="icon-file"></i>
                                                <span className="sr-only">Télécharger fichier ZIP</span>
                                            </button>
                                        )}
                                    </TABLE.TD>
                                    <TABLE.TD fixed={{left: 58}} label="Nom du fichier"><strong>{getFileNameFromPath(suivi_export.fichier)}</strong></TABLE.TD>
                                    <TABLE.TD label="Type">{this.formatTypeFichier(suivi_export)}</TABLE.TD>
                                    <TABLE.TD label="Statut">{this.renderStatut(suivi_export)}</TABLE.TD>
                                    <TABLE.TD label="Transmission">{suivi_export.date_fin ? dateFormat(suivi_export.date_fin, 'DD/MM/Y HH:mm:ss') : ''}</TABLE.TD>
                                    <TABLE.TD label="Nb lignes">{suivi_export.nb_lignes_fichier || 0}</TABLE.TD>
                                    <TABLE.TD label="Destinataire">{this.formatDestinataire(suivi_export)}</TABLE.TD>
                                    <TABLE.TD label="Emetteur">{suivi_export.emetteur?.nom_usuel || ''}</TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>

                    {this.state.exportsSuivisRes?.length === 0 && (
                        <TABLE.NO_RESULT/>
                    )}

                    <Pagination
                        handleClick={this.paginationOnClick}
                        pageCurrent={+this.state.paginate.page + 1}
                        pageTotal={this.state.paginate.pageTotal}
                        pageDisplay={3}
                    />
                </div>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ExportsFluxResultats));
