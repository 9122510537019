import HCaptcha from '@hcaptcha/react-hcaptcha';
import React, { RefObject } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import logo_infolabo from '../../assets/images/logo_infolabo.svg';
import CustomComponent from '../../components/CustomComponent';
import { FormInput, FormInputSelectCreatable } from '../../components/FormComponents';
import { LoginLayout } from '../../components/Layouts';
import Modal from '../../components/Modal';
import { HCaptchaSiteKey } from '../../config';
import { TOAST_TYPE, sendToast } from '../../helpers/helpers';
import { validateEmail, validateIsNotEmpty, validateName, validateSiret } from '../../helpers/validation';

class Register extends CustomComponent {
    private captcha;
    private _isMounted: boolean = false;

    constructor(props: any) {
        super(props);
        const { t } = this.props;
        this.state = {
            register: {
                classe: "",
                siret: "",
                company_name: "",
                lastname: "",
                firstname: "",
                email: "",
                phone: "",
                classes: t('register.classes', { returnObjects: true })
            },
            tokenCaptcha: "",
            isLoading: false,
            isVerified: false,
            isSubmitted: false,
            isModalVisible: false,
            modalMsg: "",
            modalRoute: "",
        }

        this.captcha = React.createRef() as RefObject<HCaptcha>;
    }

    componentDidMount() {
        const { t } = this.props;
        document.title = 'Infolabo | ' + t("register.title");
        this.checkIsConnectedPWA();
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async register() {
        const { t } = this.props;
        // display input error when first submit
        this.setState({ isSubmitted: true });
        const siret: string = this.state.register.siret;
        const nom_usuel: string = this.state.register.company_name;
        const nom: string = this.state.register.lastname;
        const prenom: string = this.state.register.firstname;
        const mail_identifiant: string = this.state.register.email;
        const classe: string = this.state.register.classe;
        if (siret.trim() !== "" && nom_usuel.trim() !== "" && nom.trim() !== "" && prenom.trim() !== "" && mail_identifiant.trim() !== "" && classe.trim() !== "") {
            if (this.state.isVerified) {
                let url = "/auth/register";
                let method = "POST";
                let body = {
                    siret: siret,
                    nom_usuel: nom_usuel,
                    nom: nom,
                    prenom: prenom,
                    mail_identifiant: mail_identifiant,
                    ...(this.state.register.phone && { num_tel: this.state.register.phone }),
                    classe: classe,
                    captcha: this.state.tokenCaptcha
                };
                let data = await this.request(url, method, body);
                if (data && !data.statusCode) {
                    this.setState({ modalMsg: data.message, modalRoute: "/login", isModalVisible: true, tokenCaptcha: "", isVerified: false });
                    this.captcha.current && this.captcha.current.resetCaptcha();
                }
                else {
                    this.setState({ tokenCaptcha: "", isVerified: false });
                    this.captcha.current && this.captcha.current.resetCaptcha();
                }
            }
            else {
                sendToast(<p>{t("register.check_captcha")}</p>, TOAST_TYPE.ERROR);
            }
        }
        else {
            sendToast(<p>{t('register.empty_field')}</p>, TOAST_TYPE.ERROR);
        }
    }

    async afterSubmission(event: React.FormEvent) {
        this._isMounted && this.setState({ isLoading: true });
        event.preventDefault();
        await this.register();
        this._isMounted && this.setState({ isLoading: false });
    }

    onVerifyCaptcha(token: any) {
        // console.log(token);
        this.setState({ isVerified: true, tokenCaptcha: token })
    }

    render() {
        const { t } = this.props;
        // const classes: [] = t('register.classes', { returnObjects: true });
        return (
            <LoginLayout>
                <main className="page-login__form">
                    <h1 className="page-login__title">
                        <span className="sr-only">InfoLabo</span>
                        <img src={logo_infolabo} alt="" />
                    </h1>
                    <p className="page-login__intro">{t("login.form_text")}</p>
                    <h2 className="page-login__action-title mb-30">{t("register.title")}</h2>
                    <form onSubmit={(e) => this.afterSubmission(e)}>
                        <div className="form-group mb-20 mb-md-30">
                            <FormInputSelectCreatable
                                required
                                optionsName="classes"
                                options={this.state.register.classes.sort().map((classe: any) => ({ value: classe.value, label: classe.label }))}
                                label={t("register.who_am_i")}
                                name="classe"
                                id="classe"
                                handle={(e: any) => this.handleInputChange(e, null, 'register')}
                                handleCreateOption={(e: any) => this.handleCreateOption(e, 'register')}
                                validation={validateIsNotEmpty}
                                value={this.state.register.classe}
                                isSubmitted={this.state.isSubmitted}
                            />
                        </div>
                        <div className="form-group mb-20 mb-md-30">
                            <FormInput
                                label={t("register.siret")}
                                id="siret"
                                name="siret"
                                type="text"
                                handle={(e: any) => this.handleInputChange(e, null, 'register')}
                                value={this.state.register.siret}
                                validation={(e: any) => validateSiret(e, t("register.empty_siret"), t("register.bad_siret"))}
                                isSubmitted={this.state.isSubmitted}
                            />
                        </div>
                        <div className="form-group mb-20 mb-md-30">
                            <FormInput
                                label={t("register.company_name")}
                                id="company_name"
                                name="company_name"
                                type="text"
                                handle={(e: any) => this.handleInputChange(e, null, 'register')}
                                value={this.state.register.company_name}
                                validation={(e: any) => validateIsNotEmpty(e, t("register.empty_company_name"))}
                                isSubmitted={this.state.isSubmitted}
                            />
                        </div>
                        <div className="form-group mb-20 mb-md-30">
                            <FormInput
                                label={t("register.lastname")}
                                id="lastname"
                                name="lastname"
                                type="text"
                                handle={(e: any) => this.handleInputChange(e, null, 'register')}
                                value={this.state.register.lastname}
                                validation={(e: any) => validateName(e, t("register.empty_lastname"))}
                                isSubmitted={this.state.isSubmitted}
                            />
                        </div>
                        <div className="form-group mb-20 mb-md-30">
                            <FormInput
                                label={t("register.firstname")}
                                id="firstname"
                                name="firstname"
                                type="text"
                                handle={(e: any) => this.handleInputChange(e, null, 'register')}
                                value={this.state.register.firstname}
                                validation={(e: any) => validateName(e, t("register.empty_firstname"))}
                                isSubmitted={this.state.isSubmitted}
                            />
                        </div>
                        <div className="form-group mb-20 mb-md-30">
                            <FormInput
                                label={t("register.email")}
                                id="email"
                                name="email"
                                type="email"
                                handle={(e: any) => this.handleInputChange(e, null, 'register')}
                                value={this.state.register.email}
                                validation={(e: any) => validateEmail(e, t("register.empty_email"))}
                                isSubmitted={this.state.isSubmitted}
                            />
                        </div>
                        <div className="form-group mb-20 mb-md-30">
                            <FormInput
                                label={t("register.phone")}
                                id="phone"
                                name="phone"
                                type="text"
                                handle={(e: any) => this.handleInputChange(e, null, 'register')}
                                value={this.state.register.phone}
                                isSubmitted={this.state.isSubmitted}
                            />
                        </div>
                        <div className="form-group mb-20 mb-md-30">
                            <HCaptcha
                                ref={this.captcha}
                                sitekey={HCaptchaSiteKey}
                                onVerify={(token) => this.onVerifyCaptcha(token)}
                            />
                        </div>
                        <div className="mt-5 d-flex justify-content-center align-items-center">
                            <Link to="/" className="btn btn-link mx-10">{t("cancel")}</Link>
                            <input className="btn btn-primary mx-10" type="submit" value={t("register.confirm")} />
                        </div>
                    </form>
                </main>
                <Modal
                    open={this.state.isModalVisible}
                    title={this.state.modalMsg}
                    text_button="J'ai compris"
                    handle={() => { this.props.history.push(this.state.modalRoute); }}
                >
                </Modal>
            </LoginLayout>
        )
    }
}

export default withTranslation()(withRouter(Register));
