import { PropsWithChildren } from 'react';

interface DATALISTProps extends PropsWithChildren {}
const DATALIST = ({children}: DATALISTProps) => {
    return <dl className="data-list">{children}</dl>
}

interface ITEMProps {
    title: string
    value: string
    hors_paiement?: boolean
    bold?: boolean
}
const ITEM = ({title, value, hors_paiement, bold}: ITEMProps) => {
    const boldClass = bold ? 'font-weight-bold' : ''

    return <>
        <dt>{title}</dt>
        {hors_paiement ? <dd className={boldClass}><span data-tip="Résultat hors paiement">({value})</span></dd> : <dd className={boldClass}>{value}</dd>}
    </>
}

DATALIST.ITEM = ITEM
export default DATALIST