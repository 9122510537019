import { useState } from "react";
import { useTranslation } from "react-i18next";
import { truncateString } from "../../helpers/helpers";
import { AnnonceDetailsModal } from "./_details_modal";

interface BannerUnreadAnnonceProps {
    annonce: {
        id: number,
        titre: string,
        details: string,
    };
    readAnnonceCallBack: (id: number) => void;
    closeModalCallback: () => void;
}

export const BannerUnreadAnnonce = ({ annonce, readAnnonceCallBack, closeModalCallback }: BannerUnreadAnnonceProps) => {
    const {t} = useTranslation();
    const [openAnnonce, setOpenAnnonce] = useState<number | null>(null);

    const handleSeeMoreClick = () => {
        setOpenAnnonce(annonce.id);
        readAnnonceCallBack(annonce.id);
    }

    const onClose = () => {
        setOpenAnnonce(null);
        closeModalCallback();
    }

    return (<>
        <div className="container annonce-banner">
            <div className="annonce-banner__info">
                <h2>{annonce.titre}</h2>
                <p dangerouslySetInnerHTML={{__html: truncateString(annonce.details, 250, '...', true, undefined, true)}}></p>
            </div>
            <div className="annonce-banner__action">
                <button
                    type="button"
                    className="btn btn-dark"
                    onClick={handleSeeMoreClick}
                >
                    {t('annonces.see_more')}
                </button>
            </div>
        </div>
        <AnnonceDetailsModal annonceId={openAnnonce} onClose={onClose} />
    </>)
}
