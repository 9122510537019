import * as qs from 'qs';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import CustomComponent from '../../../components/CustomComponent';
import Modal from '../../../components/Modal';
import { TOAST_TYPE, sendToast } from '../../../helpers/helpers';
import CreateEntite from "./create";
import ListEntite from "./list";


class DeleteEntite extends CustomComponent {
    private _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {
            entite: "",
            backUrl: "",
            previousEntityId: "",
            id: ""
        }
    }

    componentDidMount() {
        const id: number = Number(this.props.match.params.id);
        this._isMounted = true;
        document.title = 'Infolabo';
        const backUrl = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })?.backUrl || "";
        let previousEntityId = backUrl && (backUrl as string).split("/").length > 3 ? Number((backUrl as string).split("/")[3]) : "";
        this.loadEntite(id);
        this.setState({ backUrl, previousEntityId, id });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async loadEntite(id: number) {
        const url = '/ent_entite/' + id;
        const method = "GET";
        const data = await this.request(url, method);
        if (data) {
            this.setState({ entite: data });
        }
    }

    async deleteEntite() {
        const id = this.state.id;
        const url = '/ent_entite/' + id;
        const method = "DELETE";
        const data = await this.request(url, method);
        if (data && data.affected) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            this.props.history.push("/annuaire/entite");
        }
    }

    render() {
        return (
            <>
                {
                    this.state.backUrl && (this.state.backUrl as string)?.includes("modifier") ?
                        <CreateEntite entityId={this.state.previousEntityId} isVisualization={true} />
                        :
                        <ListEntite />
                }
                <Modal
                    open={true}
                >
                    <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                        Etes-vous sûr(e) de vouloir supprimer l’entité <b>{this.state.entite?.ent_pay_id?.code_pays}{this.state.entite?.siren}{this.state.entite?.nic} - {this.state.entite?.nom_usuel}</b>
                    </div>
                    <button onClick={() => this.state.backUrl ? this.props.history.push(this.state.backUrl) : this.props.history.goBack()} className="btn btn-white">Annuler</button>
                    <button onClick={() => this.deleteEntite()} className="btn btn-secondary">Confirmer</button>
                </Modal>
            </>
        )
    }
}

export default withTranslation()(withRouter(DeleteEntite));