import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationFR from './translations/translation_fr.json';
// import translationEN from './translations/translation_en';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    // en: { key: 'en', name:'English', translation: translationEN },
    fr: { key: 'fr', name:'Français', translation: translationFR },
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        detection: {order: ['navigator']},
        resources,
        supportedLngs: Object.keys(resources), // Make sure it doesn't load unsupported language
        fallbackLng: "fr", // use fr if detected lng is not available
        keySeparator: '.', // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
