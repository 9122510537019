import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb";
import CustomComponent from "../../../components/CustomComponent";
import CustomToggle from "../../../components/CustomToggle";
import { DefaultLayout } from "../../../components/Layouts";
import TABLE from '../../../components/Table';
import { ToggleSwitch } from "../../../components/ToggleSwitch";
import { slugify } from "../../../helpers/helpers";
import { CreateModal } from "./_create_modal";
import { DeleteModal } from "./_delete_modal";

class GererSeuilsAlerte extends CustomComponent {
    private _isMounted = false;
    private _title = "Gérer mes seuils d'alerte"

    private default_filters = {
        critere: '',
        orderBy: 'critere',
        ordering: 'ASC'
    }

    constructor(props: any) {
        super(props);
        this.state = {
            filters: this.default_filters,
            productions: [],
            production: null,
            seuils: [],
            createModal: false,
            updateModal: null,
            deleteModal: null,
            isLoading: true
        }
    }

    componentDidMount(): void {
        this._isMounted = true
        document.title = `Infolabo | ${this._title}`

        this.getProductions()
        this.getSeuilsAlerte()
    }

    componentWillUnmount(): void {
        this._isMounted = false
    }

    handleSort(field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), this.getSeuilsAlerte)
    }

    async getProductions () {
        const {prd_id} = this.getLocalStorageSession() as any
        const URL = `/resultats/producteur/${prd_id}`

        const data = await this.request(URL, 'GET')
        this.setState({isLoading: false})

        if (data?.statusCode === 200) {
            let productions = data.producteur.productions.map((p: any) => ({
                id: p.id,
                espece_collectee: p.esp_id.lib_court,
                nature_lait: p.nat_id.libelle,
                tank_code: p.tank_code || '',
                nom_usuel: p.ent_id_ca.nom_usuel,
                siret: p.ent_id_ca.siret
            }))

            let production = productions[0] || null

            this._isMounted && this.setState({
                exploitation_name: `${data.producteur.ent_id.nom_usuel}`,
                lab_gestion: data.producteur.lab_gestion_id,
                productions,
                production,
                // createModal: true
            }, this.getSeuilsAlerte)
        }
    }

    getSeuilsAlerte = async () => {
        const production = this.state.production
        if (!production) return

        const URL = `/per_seuil_alerte/production/${production.id}`
        const params = new URLSearchParams()

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key]
            if (val !== '') params.append(key, val)
        })

        const data = await this.request(`${URL}?${params.toString()}`, 'GET')
        if (data?.statusCode === 200) {
            this._isMounted && this.setState({seuils: data.seuils})
        }
    }

    async onSwitchToggle (seuil: any) {
        await this.request(`/per_seuil_alerte/${seuil.id}`, 'PATCH', {fl_actif: !seuil.fl_actif})
        this.getSeuilsAlerte()
    }

    checkAppliedOn = (journalier: boolean, mensuel: boolean): string => {
        if (journalier) {
            if (mensuel) return 'Résultats journaliers & mensuels';
            return 'Résultats journaliers';
        }
        return 'Résultats mensuels';
    }

    render () {
        return (
            <DefaultLayout>
                <div className="container">
                    <BreadCrumb crumbs={[{name: 'Notifications'}, {name: this._title}]}/>
                    <div className="d-flex align-items-baseline flex-wrap">
                        <h1 className="main-title">{this._title}</h1>
                        <button type="button" onClick={() => this.setState({createModal: true})} className="btn btn-dark ml-auto"><i className="icon-add"></i>Créer</button>
                    </div>
                    <ul className="nav nav-tabs m-t-10 m-t-md-0 m-r-md-15">
                        {this.state.productions.map((production: any) => (
                            <li key={production.id} className="nav-item">
                                <button
                                    className={`nav-link tab-icon ${production.id === this.state?.production?.id ? 'active': ''}`}
                                    onClick={() => this.setState({production}, this.getSeuilsAlerte)}>
                                        <img className="tab-icon__icon" src={`/icons/icon_${slugify(production.nature_lait)}.svg`} alt=""/>
                                        <img className="tab-icon__icon" src={`/icons/icon_${slugify(production.espece_collectee)}.svg`} alt=""/>
                                        <div className="tab-icon__content">
                                            <div className="tab-icon__type">
                                                <strong>{production.espece_collectee}</strong>
                                                <span> {production.nature_lait}</span>
                                                {production.tank_code && (<span> (Tank #{production.tank_code})</span>)}
                                            </div>
                                            <div className="tab-icon__info">{production.nom_usuel} - {production.siret}</div>
                                        </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                    <TABLE responsive="card">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH
                                    name="critere"
                                    input={{value: this.state.filters.critere, placeholder: 'Nom du critère', onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.getSeuilsAlerte)}}
                                    sort={{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('critere')}}/>
                                <TABLE.TH label="Opérateur" />
                                <TABLE.TH label="Valeur" />
                                <TABLE.TH label="Appliqué sur" />
                                <TABLE.TH label="Notification mobile" />
                                <TABLE.TH label="Statut du seuil" />
                                <TABLE.TH actions label="Action" />
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.seuils.map((seuil: any) => (
                                <TABLE.TR key={seuil.id}>
                                    <TABLE.TD label="Nom du critère">{seuil.cri_id.libelle}</TABLE.TD>
                                    <TABLE.TD label="Opérateur">{seuil.operande}</TABLE.TD>
                                    <TABLE.TD label="Valeur">
                                        {seuil?.apr_id?.libelle || seuil?.valeur || '-'}
                                    </TABLE.TD>
                                    <TABLE.TD label="Appliqué sur">
                                        {this.checkAppliedOn(seuil.fl_journalier, seuil.fl_mensuel)}
                                    </TABLE.TD>
                                    <TABLE.TD label="Notification mobile">{seuil.fl_notification_m ? 'Activé' : 'Désactivé'}</TABLE.TD>
                                    <TABLE.TD label="Statut du seuil">
                                        <ToggleSwitch variant="blue" labels={{on: 'Activé', off: 'Désactivé'}} active={seuil.fl_actif} onChange={() => this.onSwitchToggle(seuil)} />
                                    </TABLE.TD>
                                    <TABLE.TD actions>
                                        <div className="d-flex desktop-only">
                                            <button data-tip="Modifier" className='action-btn' onClick={() => this.setState({ updateModal: seuil})}><i className="icon-pen"></i></button>
                                            <button data-tip="Supprimer" className='action-btn' onClick={() => this.setState({ deleteModal: seuil})}><i className="icon-trash"></i></button>
                                        </div>
                                        <Dropdown className="mobile-only">
                                            <Dropdown.Toggle as={CustomToggle}>
                                                <span className="sr-only">Ouvrir les actions</span>
                                                <i className="icon-actions"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <button className='dropdown-item' onClick={() => this.setState({ updateModal: seuil})}>Modifier</button>
                                                <button className='dropdown-item' onClick={() => this.setState({ deleteModal: seuil})}>Supprimer</button>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </TABLE.TD>
                                </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>
                </div>
                {/* Create modale */}
                {this.state.createModal && <CreateModal production={this.state.production} seuils={this.state.seuils} onClose={() => this.setState({createModal: false}, this.getSeuilsAlerte)}/>}
                {/* Update modale */}
                {this.state.updateModal && <CreateModal seuil={this.state.updateModal} seuils={this.state.seuils} production={this.state.production} onClose={() => this.setState({updateModal: null}, this.getSeuilsAlerte)}/>}
                {/* Delete modale */}
                {this.state.deleteModal && <DeleteModal production={this.state.production} seuil={this.state.deleteModal} onClose={() => this.setState({deleteModal: null}, this.getSeuilsAlerte)}/>}
            </DefaultLayout>
        )
    }

}

export default withTranslation()(withRouter(GererSeuilsAlerte));
