import CustomComponent from "../../components/CustomComponent";
import AnnonceCreate from "./create";

class UpdateAnnonce extends CustomComponent {
    render() {
        const id = this.props.match.params.id;
        return(<AnnonceCreate annonceId={id} />);
    }
}

export default UpdateAnnonce;
