import Create from './create';
import List from './list';
import Details from './details';
import Update from './update';

const Groupe = {
  Create,
  List,
  Details,
  Update,
};

export default Groupe;