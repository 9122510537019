import {useTranslation} from "react-i18next";
import {dateFormat, truncateString} from "../../helpers/helpers";
import {Link} from "react-router-dom";
import React from "react";
import classNames from "classnames";
import moment from "moment";
import {Dropdown} from "react-bootstrap";
import CustomToggle from "../../components/CustomToggle";
import logo_infolabo from "../../assets/images/logo_header.svg";

interface AnnonceCardProps {
    annonce: {
        id: number,
        titre: string,
        nom_image: string | null,
        src_image: string | null,
        date_creation: string,
        date_debut: string,
        date_fin: string | null,
        details: string,
        nombre_vues: number,
    },
    onDelete: Function,
}

const DATE_FORMAT = 'DD/MM/Y';

export const AnnonceCard = ({annonce, onDelete}: AnnonceCardProps) => {
    const {id, titre, src_image, nom_image, date_creation, date_debut, date_fin, details, nombre_vues} = annonce;
    const {t} = useTranslation();

    const today = moment.utc().startOf('day').toDate();
    let isActive: boolean = moment.utc(date_debut).isSameOrBefore(today);
    if (date_fin && moment.utc(date_fin).isBefore(today)) isActive = false;

    return (
        <div className="annonce-card">
            <div className="annonce-card__img">
                <img src={src_image ?? logo_infolabo} alt={nom_image ?? t('annonces.alt_default_img')} />
            </div>
            <div className="annonce-card__info">
                <div className="annonce-card__info-header">
                    <div>
                        <div>
                            <strong className="creation-date">{dateFormat(date_creation, DATE_FORMAT)}</strong>
                            <span>{t('annonces.view_count', {nombre_vues})}</span>
                        </div>
                        <span className={classNames({'status': true, 'active': isActive})}>
                            <span>
                                <strong>{t('annonces.period_label')} : </strong>
                                {t('annonces.period_from')} {dateFormat(date_debut, DATE_FORMAT)}
                                {!!date_fin && ` ${t('annonces.period_to')} ${dateFormat(date_fin ?? '', DATE_FORMAT)}`}
                            </span>
                        </span>
                    </div>
                    <div className="d-flex desktop-only">
                            <Link to={`/annonces/${id}/modifier`} className="action-btn"><i className="icon-pen"/></Link>
                            <button type="button" className="action-btn" onClick={() => onDelete(annonce)}><i className="icon-trash"/></button>
                    </div>
                    <Dropdown className="mobile-only">
                        <Dropdown.Toggle as={CustomToggle}>
                            <span className="sr-only">Ouvrir les actions</span>
                            <i className="icon-actions"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Link to={`/annonces/${id}/modifier`} className="dropdown-item">{t('annonces.action_edit')}</Link>
                            <button type="button" className="dropdown-item" onClick={() => onDelete(annonce)}>{t('annonces.action_delete')}</button>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="annonce-card__info-content">
                    <h3>{titre}</h3>
                    <div dangerouslySetInnerHTML={{__html: truncateString(details, 200, '...', true, 5)}}></div>
                </div>
            </div>
        </div>
    );
}
