import classNames from 'classnames';
import { createContext, PropsWithChildren, useContext, useRef } from "react";
import { Accordion } from "react-bootstrap";
import ScrollContainer from 'react-indiana-drag-scroll';
import { ReactSortable } from 'react-sortablejs';
import { datePickerFormat } from "../helpers/helpers";
import useWindowSize from "../hooks/useWindowSize";
import { FormDatePicker } from "./FormComponents";
import { SortbyColumnButton } from "./TableComponents";

const TableContext = createContext<any>({
    responsive: 'default',
})

interface TABLEProps extends PropsWithChildren {
    fixed?: boolean
    bordered?: boolean
    responsive?: 'default' | 'card' | 'accordion'
    variant?: 'blue'
    theadSticky?: boolean
}
const TABLE = ({ variant, children, fixed, bordered, responsive = 'default', theadSticky = false }: TABLEProps) => {
    return (
        <TableContext.Provider value={{ responsive }}>
            <div className={classNames({
                'table-thead-sticky': theadSticky
            })}>
                <ScrollContainer
                    vertical={false}
                    horizontal={true}
                    ignoreElements={'th'}
                    className={classNames({
                        'table-responsive': true,
                        'table-accordion-mobile': responsive === 'accordion',
                        'table-card-mobile': responsive === 'card',
                        'table-blue': variant === 'blue',
                    })}
                >
                    <table className={classNames({
                        'table table-custom': true,
                        'table-fixed': fixed,
                        'table-bordered': bordered,
                    })}>
                        {children}
                    </table>
                </ScrollContainer>
            </div>
        </TableContext.Provider>
    )
}

const THEAD = ({ children }: PropsWithChildren) => {
    const { responsive } = useContext(TableContext)

    return (
        <thead className={classNames({
            'desktop-only': ['card', 'accordion'].includes(responsive)
        })}>{children}</thead>
    )
}

interface THProps extends PropsWithChildren {
    fixed?: { left: number, width?: number }
    logo?: boolean
    actions?: boolean
    name?: string
    label?: string
    sublabel?: string
    input?: {
        value: string
        placeholder?: string
        onChange: any
    }
    select?: {
        value: string
        options: {
            value: string,
            label: string
        }[]
        placeholder?: string
        onChange: any
    }
    sort?: {
        orderBy: string,
        ordering: string
        onSort: any
    },
    datePicker?: {
        value: string | null
        placeholder?: string
        onChange: any
    }
    light?: boolean
    transparent?: boolean,
    className?: string
    colspan?: number
    small?: boolean
}

const TH = ({ fixed, logo, actions = false, name, label, sublabel = '', input, select, sort, light, transparent, colspan = 1, children, className, small, datePicker }: THProps) => {
    const thRef = useRef() as any

    return (
        <th ref={thRef}
            style={fixed ? { left: fixed.left, width: fixed.width || 'unset', minWidth: fixed.width || 'unset' } : {}}
            className={classNames({
                'fixed': fixed,
                'table-actions': actions,
                'light': light,
                'transparent': transparent,
                'table-logo': logo,
                'table-th-small': small,
                ...(className && { [className]: true })
            })}
            colSpan={colspan}>
            <div className="d-flex align-items-center justify-content-between">
                {(input && name) && (<>
                    <span className="sr-only">{input.placeholder}</span>
                    <input
                        value={input.value}
                        onChange={input.onChange}
                        name={name}
                        className="table-input"
                        placeholder={input.placeholder}
                        type="text" />
                </>)}
                {(select && name) && (<>
                    <span className="sr-only">{select.placeholder}</span>
                    <select value={select.value} name={name} onChange={select.onChange} className={classNames({'table-select': true, 'pristine': select.value === ''})}>
                        {select.placeholder && <option value=''>{select.placeholder}</option>}
                        {select.options.map((option: any, index: number) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </select>

                </>)}
                <div>
                    {label && <div className="table-label">{label}</div>}
                    {sublabel && <small>{sublabel}</small>}
                </div>
                {datePicker && name && (
                  <FormDatePicker
                    name={name}
                    type="text"
                    handle={datePicker.onChange}
                    value={datePickerFormat(datePicker.value)}
                    placeholder={datePicker.placeholder}
                    isClearable
                    isTH
                  />
                )}
                {sort && (
                    <SortbyColumnButton onClick={sort.onSort} active={sort.orderBy === name} direction={sort.ordering} />
                )}
            </div>
            {children}
        </th>
    )
}


interface TBODYProps extends PropsWithChildren {
    sortableList?: any[]
    setSortableList?: any
}
const TBODY = ({ children, sortableList, setSortableList }: TBODYProps) => {
    if (sortableList && setSortableList) return (
        <ReactSortable
            direction='vertical'
            forceFallback
            chosenClass='table-sortable__chosen'
            ghostClass='table-sortable__ghost'
            fallbackClass='table-sortable__fallback'
            handle='.table-sortable__handle'
            list={sortableList}
            setList={setSortableList}
            tag="tbody">
            {children}
        </ReactSortable>
    )

    return <tbody>{children}</tbody>
}

interface TRProps extends PropsWithChildren {
    title?: string
    onTitleClick?: Function
    dark?: boolean
    variant?: 'dark' | 'blue-100' | 'blue-80' | 'blue-40'
    allowOpen?: boolean
    paddedLeft?: boolean
}
const TR = ({ children, dark, title, onTitleClick, variant, allowOpen = true, paddedLeft}: TRProps) => {
    const { isMobile } = useWindowSize()
    const { responsive } = useContext(TableContext)

    const variants = {
        'dark': (variant === 'dark') || dark,
        'blue-100': (variant === 'blue-100'),
        'blue-80': (variant === 'blue-80'),
        'blue-40': (variant === 'blue-40')
    }

    const handleClick = (e: any) => {
        e.stopPropagation()
        onTitleClick && onTitleClick()
    }

    if (isMobile) {
        if (responsive === 'accordion') return (
            <tr className={classNames({ "table-accordion": true, ...variants })}>
                {
                    allowOpen ? (
                        <Accordion className="table-accordion__accordion">
                            <Accordion.Header className="table-accordion__header" as='div'>
                                <span
                                    className={classNames({ 'link': onTitleClick })}
                                    {...(onTitleClick && { onClick: handleClick })}>
                                    {title}
                                </span>
                            </Accordion.Header>
                            <Accordion.Body className="table-accordion__body">{children}</Accordion.Body>
                        </Accordion>
                    ) : (
                        <td className="table-accordion__nonOpenable">
                            <div>{title}</div>
                        </td>
                    )
                }
            </tr>
        )

        if (responsive === 'card') return (
            <tr className={classNames({'table-card__item': true, 'table-card__padding': paddedLeft})}>
                {title && (
                    <div
                        {...(onTitleClick && { onClick: () => onTitleClick() })}
                        className={classNames({ "table-card__title": true, "table-card__title--clickable": onTitleClick })}
                    >{title}</div>
                )}
                {children}
            </tr>
        )
    }

    return <tr className={classNames(variants)}>{children}</tr>
}

interface TDProps extends PropsWithChildren {
    fixed?: { left: number, width?: number }
    actions?: boolean
    hiddenMobile?: boolean
    label?: string
    accordionLabelSize?: 'strong' | 'small' | 'medium'
    light?: boolean
    double?: boolean
    className?: string
    colspan?: number
    onClick?: any
    horsSeuil?: boolean
    sortableHandle?: boolean
    sortableDisabled?: boolean
    icon ?:boolean
    rightAlign ?: boolean
    ellipsis ?: boolean
}

const TD = ({ fixed, actions, hiddenMobile = false, label, accordionLabelSize = 'strong', light, double, colspan = 1, onClick, children, className, horsSeuil, sortableHandle, sortableDisabled, icon, rightAlign = false, ellipsis = false }: TDProps) => {
    const { isMobile } = useWindowSize()
    const { responsive } = useContext(TableContext)
    const thRef = useRef() as any;

    if (isMobile) {
        if (hiddenMobile) return <></>

        if (responsive === 'accordion') {
            let stylizedLabel;
            switch (accordionLabelSize) {
                case 'strong':
                    stylizedLabel = <strong>{label}</strong>;
                    break;
                case 'medium':
                    stylizedLabel = label;
                    break;
                case 'small':
                    stylizedLabel = <span className="small">{label}</span>;
                    break;
                default:
                    break;
            }

            return (
                <div onClick={onClick} className={classNames({
                    'table-accordion__line': true,
                    'desktop-only': hiddenMobile,
                    'table-clickable-cell': onClick,
                    'line-warning': horsSeuil,
                })}>
                    {stylizedLabel}<span className={classNames({ 'table-accordion__double': double })}>{children}</span>
                </div>
            )
        }

        if (responsive === 'card' && actions) return (
            <td onClick={onClick} ref={thRef} className={classNames({ 'table-card__actions': true, 'table-clickable-cell': onClick })} colSpan={colspan}>
                {children}
            </td>
        )

        if (responsive === 'card' && icon) return (
            <td onClick={onClick} ref={thRef} className={classNames({ 'table-card__line': true,'table-card__icon': true , 'table-actions': actions, 'table-clickable-cell': onClick })} colSpan={colspan}>
                <div className="table-card__value">{children}</div>
            </td>
        )

        if (responsive === 'card') return (
            <td onClick={onClick} ref={thRef} className={classNames({ 'table-card__line': true, 'table-actions': actions, 'table-clickable-cell': onClick })} colSpan={colspan}>
                {sortableHandle && <div className='table-sortable__handle-wrp table-sortable__handle-wrp--card'>
                    <i className={classNames({'table-sortable__handle icon-move': true, 'disabled': sortableDisabled})}></i>
                </div>}
                <div className="table-card__label">{label}</div>
                <div className="table-card__value">{children}</div>
            </td>
        )
    }

    return (
        <td
            onClick={onClick}
            ref={thRef}
            style={fixed ? { left: fixed.left, width: fixed.width || 'unset', minWidth: fixed.width || 'unset' } : {}}
            className={classNames({
                'fixed': fixed,
                'table-actions': actions,
                'light': light,
                'table-clickable-cell': onClick,
                'cell-warning': horsSeuil,
                'text-right': rightAlign,
                'cell-ellipsis': ellipsis,
                ...(className && { [className]: true })
            })}
            colSpan={colspan}>
            {sortableHandle ? (
                <span className='table-sortable__handle-wrp'>
                    <i className={classNames({'table-sortable__handle icon-move': true, 'disabled': sortableDisabled})}></i>
                    {children}
                </span>
            ) : (
                children
            )}
        </td>
    )
}

interface CHECKBOXProps extends PropsWithChildren  {
    type: 'TH'|'TD'
    checked: boolean
    onChange: any
    fixed?: { left: number, width?: number }
}
const CHECKBOX = ({type, checked, onChange, fixed, children}: CHECKBOXProps) => {
    const ref = useRef() as any

    if (type === 'TH') return (
        <th className={classNames({'table-check': true, 'fixed': 'fixed'})}
            ref={ref}
            style={fixed ? { left: fixed.left, width: fixed.width || 0, minWidth: fixed.width || 'unset' } : {}}>
            <input
                type="checkbox"
                checked={checked}
                className="checkbox checkbox--white"
                onChange={onChange} />
            {children}
        </th>

    )
    return (
        <td className={classNames({'table-check': true, 'fixed': 'fixed'})}
            ref={ref}
            style={fixed ? { left: fixed.left, width: fixed.width || 0, minWidth: fixed.width || 'unset' } : {}}>
            <input
                type="checkbox"
                checked={checked}
                className="checkbox"
                onChange={onChange} />
            {children}
        </td>
    )
}

const EMPTY_MESSAGE = ({ message }: { message: string }) => <div className="table-empty">{message}</div>
const NO_REQUEST = () => <EMPTY_MESSAGE message="Sélectionnez des filtres pour afficher des résultats" />
const NO_RESULT = () => <EMPTY_MESSAGE message="Aucune donnée à afficher" />

TABLE.THEAD = THEAD
TABLE.TH = TH
TABLE.TBODY = TBODY
TABLE.TD = TD
TABLE.TR = TR
TABLE.EMPTY_MESSAGE = EMPTY_MESSAGE
TABLE.NO_REQUEST = NO_REQUEST
TABLE.NO_RESULT = NO_RESULT
TABLE.CHECKBOX = CHECKBOX

export default TABLE