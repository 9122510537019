import moment from 'moment-timezone';
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import CustomComponent from "../../components/CustomComponent";
import Panel from "../../components/Panel";

class PasswordPanel extends CustomComponent {
    private _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { t, personne } = this.props;

        return (
            <Panel
                title={t('mes_informations.password_panel_title')}
                togglableID="panel-mdp"
                open={true}
            >
                <Link to="/profil/changer_mot_de_passe" className="text-decoration-none">
                    <div className="info-card info-card__link info-card__edit-password">
                        <span>**********</span>
                        {
                            !!personne?.dt_der_chg_pwd &&
                            <span className="date"><b>Dernière modification :</b> {moment.tz(personne?.dt_der_chg_pwd, 'Europe/Paris').format('DD/MM/YYYY')}</span>
                        }
                    </div>
                </Link>
            </Panel>
        )
    }
}

export default withTranslation()(withRouter(PasswordPanel));