import { withRouter } from "react-router-dom";
import CustomComponent from "../../components/CustomComponent";
import { hasPermission } from "../../helpers/helpers";
import { Forbidden } from "../_error_pages";

const REDIRECTIONS = [
    { permissions: ['DRT_RES_PAR_PRODUCTION'], path: '/resultats/production' },
    { permissions: ['DRT_RES_PAR_CITERNE'], path: '/resultats/citerne'},
    { permissions: ['DRT_RES_PAR_TOURNEE'], path: '/resultats/tournee'},
    { permissions: ['DRT_RES_PAR_CRITERE'], path: '/resultats/critere' },
]

class AccueilResultats extends CustomComponent {
    componentDidMount() {
        for (let redirection of REDIRECTIONS) {
            if (hasPermission(redirection.permissions)) return this.props.history.push(redirection.path)
        }
    }

    render () {
        return <Forbidden />
    }
}

export default withRouter(AccueilResultats);