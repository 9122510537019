import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import CustomComponent from "../../components/CustomComponent";
import { FormInput, FormInputSelect } from "../../components/FormComponents";
import { DefaultLayout } from "../../components/Layouts";
import { TOAST_TYPE, getSession, hasPermission, sendToast } from "../../helpers/helpers";
import { initValidator } from "../../validations/validations";

class Contacts extends CustomComponent {
    private _isMounted = false;
    private readonly validator;

    private _optionsObjets = [
        {label: 'Demande de renseignements', value: 'contact_info'},
        {label: 'Demande de modification de coordonnées', value: 'contact_edit_data'},
        {label: 'Autre demande', value: 'contact_other'},
    ];

    constructor(props: any) {
        super(props);
        this.state = {
            lab_gestion: null,
            isSubmitted: false,
            contact_form: {
                destinataires: [],
                objet: '',
                message: '',
            },
        }

        this.validator = initValidator();
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Mes contacts';

        const hab_code = getSession().hab_code;
        hab_code === 'PRODUCT' && this.getLaboratoireGestion();
        this.getDestinataires();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getLaboratoireGestion = async () => {
        const data = await this.request(`/ent_entite/getLabGestion`, 'GET');

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState({lab_gestion: data.lab_gestion});
        }
    }

    formatAddress = () => {
        const lab_ent = this.state.lab_gestion?.ent_id;

        const adr_lignes = [lab_ent?.adr_lig_1, lab_ent?.adr_lig_2, lab_ent?.adr_lig_3, lab_ent?.adr_lig_4]
            .filter((adr: any) => !!adr)
            .join(' ');

        const adr_ville = `${lab_ent?.code_postal} ${lab_ent?.ville}`;

        return `${adr_lignes}\n${adr_ville}`;
    }

    getDestinataires = async () => {
        const data = await this.request(`/contact/getMails`, 'GET');

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState((prev: any) => ({
                contact_form: {
                    ...prev.contact_form,
                    destinataires: data.mails,
                }
            }));
        }
    }

    afterSubmission = async (event: React.FormEvent) => {
        event.preventDefault();
        const {t} = this.props;

        if (this.state.contact_form.destinataires?.length < 1) {
            sendToast(<p>{t('error_no_recipient')}</p>, TOAST_TYPE.ERROR);
            return;
        }

        this._isMounted && this.setState({isSubmitted: true});

        if (this.validator.allValid()) {
            const BODY = this.state.contact_form;
            const data = await this.request(`/contact/sendMails`, 'POST', BODY);
            if (data?.statusCode === 201 && this._isMounted) {
                sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            }
        } else {
            sendToast(<p>{t('error_bad_field_value')}</p>, TOAST_TYPE.ERROR);
        }
    }

    render() {
        const {t} = this.props
        return (
            <DefaultLayout>
                <div className="container">
                    <BreadCrumb crumbs={[{name: 'Mes contacts'}]}/>
                    <h1 className="main-title">Mes contacts</h1>

                    <div className="row">
                        {/* Formulaire de contact */}
                        <div className="col-lg-6 m-b-20 form-contact">
                            <form onSubmit={this.afterSubmission}>
                                <h2 className="form-contact__title">Formulaire de contact</h2>
                                <div className="form-group m-b-25">
                                    <FormInput
                                        label="Destinataire du message"
                                        id="destinataires"
                                        name="destinataires"
                                        value={this.state.contact_form.destinataires.join('; ') || ''}
                                        disabled
                                        required
                                    />
                                    {this.state.contact_form.destinataires?.length < 1 && (
                                        <span className="invalid-feedback">{t('error_no_recipient')}</span>
                                    )}
                                </div>
                                <div className="form-group m-b-25">
                                    <FormInputSelect
                                        label="Objet du message"
                                        id="objet"
                                        name="objet"
                                        options={this._optionsObjets}
                                        value={this.state.contact_form.objet || ''}
                                        handle={(e: any) => this.handleInputChange(e, null, 'contact_form')}
                                        required
                                        isSubmitted={this.state.isSubmitted}
                                        simpleValidator={this.validator}
                                    />
                                </div>
                                <div className="form-group">
                                    <FormInput
                                        label="Message"
                                        id="message"
                                        name="message"
                                        isTextArea
                                        handle={(e: any) => this.handleInputChange(e, null, 'contact_form')}
                                        value={this.state.contact_form.message}
                                        required
                                        isSubmitted={this.state.isSubmitted}
                                        simpleValidator={this.validator}
                                    />
                                </div>
                                <div className="form-group d-flex justify-content-end m-0">
                                    <button disabled={this.state.contact_form.destinataires?.length < 1} type="submit" className="btn btn-dark">Envoyer</button>
                                </div>
                            </form>
                        </div>

                        {/* Carte laboratoire de gestion */}
                        {hasPermission('DRT_CONTACTS_COORDONNEES') && (
                            <div className="col-lg-6">
                                <div className="contact-card">
                                    <h2 className="contact-card__title ">{this.state.lab_gestion?.ent_id.nom_usuel}</h2>
                                    <address className="contact-card__info">
                                        <div>
                                            <div>Adresse</div>
                                            <div>{this.formatAddress()}</div>
                                        </div>
                                        <div>
                                            <div>Téléphone</div>
                                            <div>{this.state.lab_gestion?.ent_id.num_tel}</div>
                                        </div>
                                    </address>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </DefaultLayout>
        )
    }

}

export default withTranslation()(withRouter(Contacts));