import { default as jwt_decode } from 'jwt-decode';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import logo_infolabo from '../../assets/images/logo_infolabo.svg';
import CustomComponent from '../../components/CustomComponent';
import { FormInput } from '../../components/FormComponents';
import { LoginLayout } from '../../components/Layouts';
import { TOAST_TYPE, sendToast } from '../../helpers/helpers';
import { validatePassword, validatePasswordMatch } from '../../helpers/validation';
import { UPDATE_SESSION } from '../../redux/types';
import { initValidator } from '../../validations/validations';

class Login extends CustomComponent {
    private _isMounted: boolean = false;
    private _timeout: any;
    private validator;

    constructor(props: any) {
        super(props);
        this.state = {
            login: {
                identifiant: "",
                password: "",
                remember_me: true,
            },
            should_update_credentials: false,
            credentials: {
                login: '',
                password: '',
                conf_password: '',
            },
            formIsValid: false,
            isLoading: false,
            isSubmitted: false,
            tooManyAttemptsTimeout: localStorage.getItem('tooManyAttemptsTimeout'),
            disabledSubmit: false
        }

        this.validator = initValidator();
    }

    componentDidMount() {
        const { t } = this.props;
        document.title = 'Infolabo | ' + t("login.title");
        this.checkIsConnectedPWA();
        this._isMounted = true;
        this.tooManyAttemptsTimeout();
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(this._timeout);
    }

    async login() {
        const { t } = this.props;
        if (!this.validator.allValid()) return;
        // display input error when first submit
        this.setState({ isSubmitted: true });
        const identifiant: string = this.state.login.identifiant;
        const password: string = this.state.login.password;
        if (identifiant.trim() !== "" && password.trim() !== "") {
            const url = "/auth/login";
            const method = "POST";
            const body = { identifiant: identifiant, mdp: password };
            const data = await this.request(url, method, body);
            if (data) {
                if ('access_token' in data) {
                    const session: any = jwt_decode(data.access_token);
                    localStorage.setItem('session', JSON.stringify(session));
                    localStorage.setItem('access_token', data.access_token);
                    localStorage.setItem('refresh_token', data.refresh_token);
                    localStorage.setItem('per_habilitations', JSON.stringify(data.per_habilitations));
                    localStorage.setItem('per_personne', JSON.stringify(data.per_personne));

                    if (data.per_habilitations?.length > 1) {
                        // go to habilitations page to select habilitation
                        this.props.history.push('/habilitations');
                    }
                    else if (data.per_habilitations?.length === 1) {
                        // select the only habilitation & redirect to home page
                        await this.select_habilitation(data.per_habilitations[0].id);
                        this.props.dispatch({ type: UPDATE_SESSION })
                    }
                    else {
                        sendToast(<p>{t('login.empty_habilitations')}</p>, TOAST_TYPE.ERROR);
                        localStorage.clear();
                    }
                }
                else if (data.error === "TooManyAttempts" && 'dt_fin_tempo' in data) {
                    this.setState({ tooManyAttemptsTimeout: data.dt_fin_tempo });
                    localStorage.setItem('tooManyAttemptsTimeout', data.dt_fin_tempo);
                    this.tooManyAttemptsTimeout();
                }
            }
        }
        else {
            sendToast(<p>{t('login.empty_field')}</p>, TOAST_TYPE.ERROR);
        }
    }

    async onLoginSubmission(event: React.FormEvent) {
        this._isMounted && this.setState({ isLoading: true });
        event.preventDefault();
        await this.login();
        this._isMounted && this.setState({ isLoading: false });
    }

    onUpdateCredentialsSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const { t } = this.props;
        this.setState({ isSubmitted: true });

        const { identifiant: old_login, password: old_password } = this.state.login;
        const { login, password, conf_password } = this.state.credentials;

        if (!old_login || !old_password || !login || !password) {
            return;
        }

        if (password.length === 0 || validatePassword(password, conf_password)?.type === 'erreur') {
            sendToast(<p>{t('update_credentials.error_password_security')}</p>, TOAST_TYPE.ERROR);
            return;
        }
        if (validatePasswordMatch(conf_password, password)?.type === 'erreur') {
            sendToast(<p>{t('update_credentials.error_password_match')}</p>, TOAST_TYPE.ERROR);
            return;
        }

        this.setState({ isLoading: true });

        const BODY = {
            old_login: old_login,
            old_password: old_password,
            login: login,
            password: password,
        };

        const data = await this.request(`/auth/update_credentials_request`, 'POST', BODY);
        this.setState({ isLoading: false });

        if (data?.statusCode === 200 && this._isMounted) {
            sendToast(<p>{data?.message}</p>, TOAST_TYPE.SUCCESS);
            this.setState({
                login: {
                    identifiant: "",
                    password: "",
                    remember_me: true,
                },
                should_update_credentials: false,
                credentials: {
                    login: '',
                    password: '',
                    conf_password: '',
                },
                isSubmitted: false,
            });
        }
    }

    tooManyAttemptsTimeout() {
        const timestamp_now = Date.now();
        if (timestamp_now < this.state.tooManyAttemptsTimeout) {
            this.setState({ disabledSubmit: true });
            this._timeout = setTimeout(() => {
                localStorage.removeItem('tooManyAttemptsTimeout');
                this.setState({ tooManyAttemptsTimeout: 0, disabledSubmit: false });
            }, this.state.tooManyAttemptsTimeout - timestamp_now);
        }
        else {
            localStorage.removeItem('tooManyAttemptsTimeout');
            this.setState({ tooManyAttemptsTimeout: 0, disabledSubmit: false });
        }
    }

    render() {
        const { t } = this.props;
        localStorage.setItem('identifiant', this.state.login.identifiant);

        // ECRAN DE LOGIN
        return (
            <LoginLayout>
                <main className="page-login__form">
                    <h1 className="page-login__title">
                        <span className="sr-only">InfoLabo</span>
                        <img src={logo_infolabo} alt="" />
                    </h1>
                    <p className="page-login__intro">{t('login.form_text')}</p>
                    <form onSubmit={(e) => this.onLoginSubmission(e)}>
                        <div className="form-group mb-20 mb-md-30">
                            <FormInput
                                label={t('login.login')}
                                id="identifiant"
                                name="identifiant"
                                type="email"
                                handle={(e: any) => this.handleInputChange(e, null, 'login')}
                                value={this.state.login.identifiant}
                                helper={t('login.info')}
                                isSubmitted={this.state.isSubmitted}
                                simpleValidator={this.validator}
                                validator_type="email"
                            />
                        </div>
                        <div className="form-group mb-20 mb-md-30">
                            <FormInput
                                label={t('login.password')}
                                id="motdepasse"
                                name="password"
                                type="password"
                                handle={(e: any) => this.handleInputChange(e, null, 'login')}
                                value={this.state.login.password}
                                className="input-group--password"
                                isSubmitted={this.state.isSubmitted}
                                simpleValidator={this.validator}
                                validator_type="string"
                            />
                        </div>
                        <Link to="/password/reset">{t('login.forgot_password')}</Link>
                        <div className="mt-5 d-flex flex-column align-items-center">
                            <input
                                className="btn btn-primary mb-3"
                                type="submit"
                                value={t('login.tologin')}
                                disabled={this.state.disabledSubmit}
                            />
                            <Link to="/register" className="btn btn-link">{t('login.register')}</Link>
                        </div>
                    </form>
                </main>
            </LoginLayout>
        );
    }
}

export default (connect((state: any) => ({ sessionUpdatedAt: state.sessionUpdatedAt }))(withTranslation()(withRouter(Login))));
