import { withRouter } from 'react-router-dom';
import CustomComponent from '../../components/CustomComponent';
import {default as jwt_decode} from 'jwt-decode';

class LogoutSimulated extends CustomComponent {

    componentDidMount() {
        if (this.checkIsConnectedPWA()) {
            this.logout_simulated();
        }
    }

    async logout_simulated() {
        let url = "/auth/logout_simulated";
        let method = "POST";
        let data = await this.request(url, method);
        if (data && 'access_token' in data) {
            const session: any = jwt_decode(data.access_token);
            localStorage.setItem('session', JSON.stringify(session));
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('per_habilitations', JSON.stringify(data.per_habilitations));
            localStorage.setItem('per_personne', JSON.stringify(data.per_personne));
            localStorage.setItem('habilitation_id', JSON.stringify(data.habilitation_id));
            localStorage.setItem('habDroit', JSON.stringify(data.habDroit));
        }
        this.props.history.push('/');
        this.props.history.go(0);
    }

    render() {
        return (
            <>
            </>
        )
    }
}

export default withRouter(LogoutSimulated);
