import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useEffect, useRef, useState } from "react";
import { createContainer, VictoryAxis, VictoryBrushContainer, VictoryChart, VictoryGroup, VictoryLine, VictoryTooltip } from 'victory';
import { getCustomTheme } from '../../../../assets/styles/victory.theme';
import { dateFormat } from '../../../../helpers/helpers';
import useWindowSize from '../../../../hooks/useWindowSize';
import { formatDateTick, formatTickValuesY, getChartsData, getTickValuesY, normalizeTickValuesY } from '../helpers/charts';
import logo_cniel from '../../../../assets/images/pdf-logo-cniel.png';
import logo_infolabo from '../../../../assets/images/pdf-logo-infolabo.png';

interface JournalierChartsProps {
    data: any;
    production: any;
    logo_path: string;
    date_debut: Date;
    date_fin: Date;
}
export const JournalierCharts = ({ data, production, logo_path, date_debut, date_fin }: JournalierChartsProps) => {
    const charts_ref = useRef<HTMLDivElement>(null);

    const { width } = useWindowSize();
    const [customTheme, axisBottomStyle] = getCustomTheme(width);
    const [pdfTheme, pdfAxisBottomStyle] = getCustomTheme(9999);
    const CustomContainer = createContainer('voronoi', 'zoom') as any;

    const [charts, setCharts] = useState<any>(null);
    const [scrollPosition, setScrollPosition] = useState<any>(null);

    useEffect(() => {
        const newcharts = getChartsData(data, width);
        setCharts(newcharts);
        setScrollPosition(newcharts.zoomDomain);
    }, [width, data])

    const handleChartsScroll = (domain: any) => {
        setScrollPosition({ x: domain.x, y: charts.zoomDomain.y });
    }

    const export_to_pdf = () => {
        const FILENAME = `${production.pdf_siret_producteur}_RESULTATS_JOURNALIERS_GRAPHE.pdf`;

        const chart_element = charts_ref.current;
        if (!chart_element) return;

        chart_element.classList.add('print');

        html2canvas(chart_element, {
            scale: 4,
            useCORS: true,
        }).then(chart_canvas => {
            const dataUrl = chart_canvas.toDataURL('image/jpeg', 0.8);
            const doc = new jsPDF({ orientation: 'landscape', format: "A4" });

            doc.addImage(
                dataUrl,
                "JPEG",
                0,
                0,
                doc.internal.pageSize.getWidth(),
                chart_canvas.height * (doc.internal.pageSize.getWidth() / chart_canvas.width),
                'chart',
                'NONE',
                0
            );
            doc.save(FILENAME);
        });

        chart_element.classList.remove('print');
    }

    if (!charts) return null

    return (<div className="charts-pdf" ref={charts_ref}>
        <div className="charts-pdf__header">
            <img className='charts-pdf__logo' src={logo_path} alt={`Logo ${production.lab_name}`} />
            <div className="charts-pdf__title">
                <div><strong>{production.pdf_nom_production}</strong></div>
                <div><strong>Centre d’appartenance : </strong>{production.pdf_centre_appartenance}</div>
                <div>Résultats journaliers pour la période du {dateFormat(date_debut, 'DD/MM/YYYY')} au {dateFormat(date_fin, 'DD/MM/YYYY')}</div>
            </div>
        </div>
        <div className="charts-container charts-container--scrollable charts-pdf__content">
            <ul className="charts-errors">
                {charts.errorMessages.map((error: string, index: number) => (
                    <li key={index}><i className="icon-info"></i>{error}</li>
                ))}
            </ul>
            <div>
                <button className='ml-auto btn btn-small btn-success float-right m-b-30' onClick={export_to_pdf}>Exporter</button>
                <ul className="charts-legend">
                    {data.map((entry: any, index: number) => (
                        <li className="charts-legend__item" key={index}>
                            <span className="charts-legend__color" style={{ backgroundColor: customTheme?.stack?.colorScale[index] || 'grey' }}></span>
                            <span className="charts-legend__text">{entry.label}</span>
                        </li>
                    ))}
                </ul>
            </div>
            <ul className="charts-axis charts-axis--top">
                {data.filter((entry: any) => entry.is_valid).map((entry: any, index: number) => (
                    <li key={index} style={{ color: customTheme?.stack?.colorScale[index] || 'grey' }}>{entry.label}</li>
                ))}
            </ul>

            {data.length > 0 && (<>
                <div className="charts-content">
                    <VictoryChart domain={charts.domain} theme={customTheme} containerComponent={<CustomContainer zoomDomain={scrollPosition} allowZoom={false} onZoomDomainChange={handleChartsScroll} />}>
                        {/* AXIS */}
                        <VictoryAxis tickCount={charts.tickCount} tickValues={charts.tickValuesX} tickFormat={(value) => formatDateTick(value)} style={axisBottomStyle} />
                        {charts.data.map((entry: any, index: number) => (
                            <VictoryAxis
                                key={`axis-${index}`}
                                domain={charts.domain}
                                dependentAxis
                                tickValues={getTickValuesY()}
                                tickFormat={v => formatTickValuesY(v, charts.minMax[index])}
                                orientation={index === 1 ? 'right' : 'left'} />
                        ))}
                        {/* CONTENT */}
                        <VictoryGroup domain={charts.domain}>
                            {/* VALEURS */}
                            {charts.data.map((entry: any, index: number) => (
                                <VictoryLine
                                    domain={charts.domain}
                                    key={`line-${index}`}
                                    data={entry.data}
                                    y={datum => normalizeTickValuesY(datum, charts.minMax, index)}
                                    labelComponent={<VictoryTooltip />} />
                            ))}
                            {/* SEUILS */}
                            {charts.seuils.map((seuil: any, index: number) => !!seuil && (
                                <VictoryLine
                                    key={`seuil-${index}`}
                                    domain={charts.domain}
                                    colorScale={['#ff26b2']}
                                    data={seuil}
                                    y={datum => normalizeTickValuesY(datum, charts.minMax, index)}
                                    labelComponent={<VictoryTooltip />} />
                            ))}
                        </VictoryGroup>
                    </VictoryChart>
                    <ul className="charts-axis charts-axis--bottom"><li>Date de prélèvement</li></ul>
                    {charts.scrollable && (
                        <VictoryChart
                            height={5}
                            domainPadding={{ x: [0, 0] }}
                            padding={{ top: 0, left: 10, right: 10, bottom: 0 }}
                            style={{ background: { fill: "#f0f0f0" } }}
                            containerComponent={
                                <VictoryBrushContainer
                                    brushDimension="x"
                                    brushDomain={{ x: scrollPosition.x, y: [0, 1] }}
                                    allowDrag
                                    allowDraw={false}
                                    allowResize={false}
                                    onBrushDomainChange={handleChartsScroll}
                                    brushStyle={{ stroke: "transparent", fill: "#707070", fillOpacity: 1 }}
                                    brushComponent={<rect width="5" />}
                                />
                            }>
                            <VictoryAxis domain={charts.domain} style={{ axis: { display: 'none' }, tickLabels: { display: 'none' }, ticks: { display: 'none' } }} tickFormat={(x) => null} />
                        </VictoryChart>
                    )}
                </div>
                <div className="charts-pdf-content">
                    <VictoryChart domain={charts.domain} theme={pdfTheme}>
                        {/* AXIS */}
                        <VictoryAxis tickCount={charts.tickCount} tickValues={charts.tickValuesX} tickFormat={(value) => formatDateTick(value)} style={pdfAxisBottomStyle} />
                        {charts.data.map((entry: any, index: number) => (
                            <VictoryAxis
                                key={`axis-${index}`}
                                domain={charts.domain}
                                dependentAxis
                                tickValues={getTickValuesY()}
                                tickFormat={v => formatTickValuesY(v, charts.minMax[index])}
                                orientation={index === 1 ? 'right' : 'left'} />
                        ))}
                        {/* CONTENT */}
                        <VictoryGroup domain={charts.domain}>
                            {/* VALEURS */}
                            {charts.data.map((entry: any, index: number) => (
                                <VictoryLine
                                    domain={charts.domain}
                                    key={`line-${index}`}
                                    data={entry.data}
                                    y={datum => normalizeTickValuesY(datum, charts.minMax, index)}
                                    labelComponent={<VictoryTooltip />} />
                            ))}
                            {/* SEUILS */}
                            {charts.seuils.map((seuil: any, index: number) => !!seuil && (
                                <VictoryLine
                                    key={`seuil-${index}`}
                                    domain={charts.domain}
                                    colorScale={['#ff26b2']}
                                    data={seuil}
                                    y={datum => normalizeTickValuesY(datum, charts.minMax, index)}
                                    labelComponent={<VictoryTooltip />} />
                            ))}
                        </VictoryGroup>
                    </VictoryChart>
                    <ul className="charts-axis charts-axis--bottom"><li>Date de prélèvement</li></ul>
                </div>
            </>)}

        </div>
        <div className="charts-pdf__footer">
            <img className='charts-pdf__logo' src={logo_cniel} alt="logo CNIEL" />
            <img className='charts-pdf__logo' src={logo_infolabo} alt="logo Infolabo" />
        </div>
    </div>)
}
