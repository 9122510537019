import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import BreadCrumb from '../../../components/BreadCrumb';
import CustomComponent from '../../../components/CustomComponent';
import CustomToggle from '../../../components/CustomToggle';
import { DefaultLayout } from '../../../components/Layouts';
import Pagination from '../../../components/Pagination';
import TABLE from '../../../components/Table';
import { LimitValuesSelect } from '../../../components/TableComponents';
import { ToggleSwitch, ToggleSwitchAll } from '../../../components/ToggleSwitch';
import { FORMAT_FICHIER_OPTIONS } from '../../../enum/format_fichier';
import { MEDIA_LABEL, MEDIA_OPTIONS } from '../../../enum/media';
import { TYPES_EXPORT_LABEL, TYPES_EXPORT_OPTIONS } from '../../../enum/types_export';
import { TYPES_LAITERIE_LIST_OPTIONS } from '../../../enum/types_laiterie';
import { TYPES_RESULTATS_OPTIONS } from '../../../enum/types_resultats';
import {dateFormat, generateURLSearchParams, getUrlParams, hasPermission} from '../../../helpers/helpers';
import { DeleteModal } from './_deleteModal';
import saveAs from "file-saver";
import { DebounceQueue } from '../../../helpers/debouncer';

class ExportLaiterie extends CustomComponent {
    private _isMounted = false;
    private queue = new DebounceQueue();

    constructor(props: any) {
        super(props);
        this.state = {
            filters: {
                destinataire: '',
                laiterie: '',
                type_laiterie: '',
                groupe: '',
                type_resultat: '',
                media: '',
                format_fichier: '',
                type_export: '',
                laboratoire: '',
                ordering: 'ASC',
                orderBy: 'destinataire'
            },
            exports_laiterie: [],
            checked_exports: [],
            options: {
                type_laiterie: TYPES_LAITERIE_LIST_OPTIONS,
                format_fichier: FORMAT_FICHIER_OPTIONS,
                type_resultat: TYPES_RESULTATS_OPTIONS,
                type_export: TYPES_EXPORT_OPTIONS,
                media: MEDIA_OPTIONS,
            },
            deleteModal: null,
            paginate: {
                count: 0,
                limit: 100,
                page: 0,
                pageTotal: 0,
            },
            isLoading: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Paramétrage des exports laiterie et OP';
        this.getExportsLaiterie()
    }

    getExportsLaiterie = async (triggeredByPagination: boolean = false) => {
        const URL = `/exp_export_laiterie`
        const params = generateURLSearchParams(this.state.filters, this.state.paginate)

        if (!triggeredByPagination) params.set('page', '0')

        this.setState({isLoading: true})
        const data = await this.request(`${URL}?${params.toString()}`, 'GET')
        this.setState({isLoading: false})

        if (data.statusCode === 200) {
            this._isMounted && this.setState({
                exports_laiterie: data.exports_laiterie,
                paginate: data.paginate
            })
        }
    }

    onExportRoutageLaiterie = async () => {
        this.setState({isLoading: true});

        const fileName: string = `Liste_ExportsLaiteriesOP_${dateFormat(undefined, 'YYYYMMDD')}.csv`;
        const params: string = getUrlParams(this.state.filters, {});

        const blob: Blob = await this.request(`/exports/routage_laiterie?${params}`, 'POST', {}, false, false, true);
        this.setState({isLoading: false});

        if (!!blob && blob instanceof Blob) {
            saveAs(blob, fileName);
        }
    }

    toggleExportsLaiterie = async (ids: number[], fl_actif: boolean) => {
        const URL = `/exp_export_laiterie/activate?ids=${ids.toString()}&fl_actif=${fl_actif}`

        this.setState({isLoading: true})
        const data = await this.request(URL, 'PATCH')
        this.setState({isLoading: false})

        if (data.statusCode === 201) {
            this.queue.push(this.getExportsLaiterie, [], true);
        }
    }

    toggleSelectedExportsLaiterie = (value: boolean) => {
        this.toggleExportsLaiterie(this.state.checked_exports, value)
    }

    handleSort(field: string) {
        this._isMounted && this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getExportsLaiterie, [], true))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getExportsLaiterie, [true], true))
    }

    handleCheckAll = (e: any) => {
        const {checked} = e.target
        const checked_exports = checked
            ? [...this.state.checked_exports, ...this.state.exports_laiterie.map((entry: any) => entry.id)]
            : [...this.state.checked_exports.filter((n: any) => !this.state.exports_laiterie.map((entry: any) => entry.id).includes(n))]
        this._isMounted && this.setState((prev: any) => ({
            checked_exports
        }))
    }

    handleCheck = (e: any, id: number) => {
        const {checked} = e.target
        const checked_exports = checked
            ? [...this.state.checked_exports, id]
            : this.state.checked_exports.filter((n: any) => n !== id)
        this._isMounted && this.setState({checked_exports}, () => console.log(this.state.checked_exports))
    }

    isAllChecked = () => {
        const visibleEntiteIDs = this.state.exports_laiterie.map((e: any) => e.id)
        const checkedIDs = this.state.checked_exports
        return visibleEntiteIDs.filter((e: any) => checkedIDs.includes(e)).length === visibleEntiteIDs.length
    }

    getLaiterieTypes = (export_laiterie: any) => {
        const types = []
        if (export_laiterie.fl_lai_typ_ca) types.push('CA');
        if (export_laiterie.fl_lai_typ_cc) types.push('CC');
        if (export_laiterie.fl_lai_typ_ud) types.push('UD');
        if (export_laiterie.type_routage === 'OPNC') types.push('OPNC');

        return types.join(' - ')
    }

    render() {
        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    <BreadCrumb crumbs={[{ name: 'Flux', path: '/flux' }, { name: 'Export laiterie'}]} />
                    <div className="d-flex justify-content-between align-items-start flex-wrap m-b-15">
                        <h1 className="main-title m-b-15">Export laiterie et OP</h1>
                        <div className='d-flex flex-wrap m-b-5'>
                            {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <Link to="/flux/exports_laiterie/creer" className="btn btn-dark m-r-10 m-b-10"><i className="icon-add"></i>Créer un export laiterie</Link>}
                            {hasPermission('DRT_FLUX_EXPORT_LAITERIE') && (
                                <button
                                    type="button"
                                    className="btn btn-dark m-r-10 m-b-10"
                                    onClick={this.onExportRoutageLaiterie}
                                >
                                    <i className="icon-export"></i>
                                    Exporter le tableau
                                </button>
                            )}
                        </div>
                    </div>
                    <LimitValuesSelect
                        default_value={this.state.paginate.limit}
                        steps={[10, 20, 50, 100]}
                        onChange={(e: any) => this.setState((prev: any) => ({ paginate: {...prev.paginate, limit: +e.target.value, page: 0 }}), this.getExportsLaiterie)}/>
                    {this.state.checked_exports.length > 0 && (
                        <ToggleSwitchAll onToggle={(value: boolean) => this.toggleSelectedExportsLaiterie(value)}/>
                    )}

                    <TABLE fixed responsive='accordion'>
                        <TABLE.THEAD>
                            <TABLE.TR>
                                {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && (<>
                                    <TABLE.CHECKBOX type='TH' fixed={{left: 0, width: 60}}
                                        checked={this.isAllChecked()}
                                        onChange={(e: any) => this.handleCheckAll(e)}>
                                    </TABLE.CHECKBOX>
                                    <TABLE.TH fixed={{left: 60, width: 70}}><strong>On/Off</strong></TABLE.TH>
                                </>)}
                                <TABLE.TH name = 'destinataire'
                                    fixed={hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') ? {left: 130} : undefined}
                                    input = {{placeholder: 'Entité destinataire', value: this.state.filters.destinataire, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsLaiterie))}}
                                    sort = {{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('destinataire')}}/>
                                <TABLE.TH name = 'laiterie'
                                    input = {{placeholder: 'Source des données', value: this.state.filters.laiterie, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsLaiterie))}}
                                    sort = {{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('laiterie')}}/>
                                <TABLE.TH name = 'type_laiterie' small
                                    select = {{placeholder:'Type', value: this.state.filters.type_laiterie, options: this.state.options.type_laiterie, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsLaiterie))}}/>
                                <TABLE.TH name = 'groupe' small
                                    input = {{placeholder: 'Source des données groupe', value: this.state.filters.groupe, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsLaiterie))}}
                                    sort = {{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('groupe')}}/>
                                <TABLE.TH name = 'type_resultat' small
                                    select = {{placeholder:'Type de résultats', value: this.state.filters.type_resultat, options: this.state.options.type_resultat, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsLaiterie))}}
                                    sort = {{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('type_resultat')}}/>
                                <TABLE.TH name = 'format_fichier' small
                                    select = {{placeholder:'Format fichier', value: this.state.filters.format_fichier, options: this.state.options.format_fichier, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsLaiterie))}}
                                    sort = {{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('format_fichier')}}/>
                                <TABLE.TH name = 'type_export' small
                                    select = {{placeholder:'Type d\'export', value: this.state.filters.type_export, options: this.state.options.type_export, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsLaiterie))}}
                                    sort = {{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('type_export')}}/>
                                <TABLE.TH name = 'media' small
                                    select = {{placeholder:'Média', value: this.state.filters.media, options: this.state.options.media, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsLaiterie))}}
                                    sort = {{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('media')}}/>
                                <TABLE.TH name = 'laboratoire'
                                    input = {{placeholder: 'Laboratoire', value: this.state.filters.laboratoire, onChange: (e: any) => this.handleInputChange(e, null, 'filters', this.queue.push(this.getExportsLaiterie))}}
                                    sort = {{orderBy: this.state.filters.orderBy, ordering: this.state.filters.ordering, onSort: () => this.handleSort('laboratoire')}}/>
                                    <TABLE.TH actions><strong>Action</strong></TABLE.TH>
                            </TABLE.TR>
                        </TABLE.THEAD>
                        {this.state.exports_laiterie.length > 0 && (
                        <TABLE.TBODY>
                            {this.state.exports_laiterie.map((export_laiterie: any) => (
                            <TABLE.TR title={`${export_laiterie.destinataire.siret} - ${export_laiterie.destinataire.nom_usuel}`} key={export_laiterie.id}>
                                {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && (<>
                                    <TABLE.CHECKBOX type='TD' fixed={{left: 0, width: 60}}
                                        checked={this.state.checked_exports.includes(export_laiterie.id)}
                                        onChange={(e: any) => this.handleCheck(e, export_laiterie.id)} />
                                    <TABLE.TD fixed={{left: 60, width: 70}}>
                                        <ToggleSwitch variant="blue" active={export_laiterie.fl_actif} onChange={() => this.toggleExportsLaiterie([export_laiterie.id], !export_laiterie.fl_actif)}/>
                                    </TABLE.TD>
                                </>)}
                                <TABLE.TD
                                    hiddenMobile
                                    fixed={hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') ? {left: 130} : undefined}>
                                    <strong>{export_laiterie.destinataire.siret}<br/>{export_laiterie.destinataire.nom_usuel}</strong>
                                </TABLE.TD>
                                <TABLE.TD label='Source de donnée entité'>
                                    {export_laiterie.laiterie ? <>{export_laiterie?.laiterie?.siret}<br/>{export_laiterie?.laiterie?.nom_usuel}</> : <>-</>}
                                </TABLE.TD>
                                <TABLE.TD label='Type'>{this.getLaiterieTypes(export_laiterie) || '-'}</TABLE.TD>
                                <TABLE.TD label='Groupe'>
                                    {export_laiterie.groupe ? <>
                                        {hasPermission('DRT_ANNU_GROUPE') ? (
                                            <Link to={`/annuaire/groupe/${export_laiterie?.groupe?.id}`} target="_blank">{export_laiterie?.groupe?.nom}</Link>
                                        ) : (
                                            <>{export_laiterie?.groupe?.nom}</>
                                        )}
                                    </> : <>-</>}
                                </TABLE.TD>
                                <TABLE.TD label='Résultat'>{export_laiterie.type_resultat || '-'}</TABLE.TD>
                                <TABLE.TD label='Format fichier'>{export_laiterie.format_fichier || '-'}</TABLE.TD>
                                <TABLE.TD label='Type export'>{TYPES_EXPORT_LABEL[export_laiterie.type_export] || '-'}</TABLE.TD>
                                <TABLE.TD label='Média'>{MEDIA_LABEL[export_laiterie.media] || '-'}</TABLE.TD>
                                <TABLE.TD label='Laboratoire'>{export_laiterie.laboratoire.siret}<br/>{export_laiterie.laboratoire.nom_usuel}</TABLE.TD>
                                <TABLE.TD actions>
                                    <div className="d-flex desktop-only">
                                        {hasPermission('DRT_FLUX_EXPORT_LAITERIE') && <Link to={`/flux/exports_laiterie/${export_laiterie.id}`} className='action-btn'><i className="icon-eye"></i></Link>}
                                        {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <Link to={`/flux/exports_laiterie/${export_laiterie.id}/modifier`} className='action-btn'><i className="icon-pen"></i></Link>}
                                        {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <button type='button' onClick={() => this.setState({deleteModal: export_laiterie})} className='action-btn'><i className="icon-trash"></i></button>}
                                    </div>
                                    <Dropdown className="mobile-only">
                                        <Dropdown.Toggle as={CustomToggle}>
                                            <span className="sr-only">Ouvrir les actions</span>
                                            <i className="icon-actions"></i>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {hasPermission('DRT_FLUX_EXPORT_LAITERIE') && <Link to={`/flux/exports_laiterie/${export_laiterie.id}`} className='dropdown-item'>Consulter</Link>}
                                            {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <Link to={`/flux/exports_laiterie/${export_laiterie.id}/modifier`} className='dropdown-item'>Editer</Link>}
                                            {hasPermission('DRT_FLUX_EXPORT_LAITERIE_GERER') && <button type='button' onClick={() => this.setState({deleteModal: export_laiterie})} className='dropdown-item'>Supprimer</button>}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </TABLE.TD>
                            </TABLE.TR>
                            ))}
                        </TABLE.TBODY>
                        )}
                    </TABLE>
                    {this.state.exports_laiterie.length === 0 && (
                        <TABLE.NO_RESULT />
                    )}
                    <Pagination handleClick={this.paginationOnClick} pageCurrent={(this.state.paginate.page + 1)} pageTotal={this.state.paginate.pageTotal} pageDisplay={3} />
                </div>

                {this.state.deleteModal && (
                    <DeleteModal
                        exportLaiterie={this.state.deleteModal}
                        onDelete={() => this.setState({deleteModal: null}, this.getExportsLaiterie)}
                        onClose={() => this.setState({deleteModal: null})}/>
                )}
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ExportLaiterie));
