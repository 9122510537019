import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import CustomComponent from "../../components/CustomComponent";
import Panel from "../../components/Panel";
import { getSession, hasPermission } from "../../helpers/helpers";

class SubscriptionPanel extends CustomComponent {
    private _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {
            abonnements: [],
        }
    }

    componentDidMount() {
        this._isMounted = true;

        this.getAbonnements();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getAbonnements = async () => {
        const per_id = this.props.personneId ?? getSession().per_id;

        const data = await this.request(`/exp_abonnement/findByPerson/${per_id}`, 'GET');

        if (data?.statusCode === 200 && this._isMounted) {
            this.setState({abonnements: data.abonnements});
        }
    }

    render() {
        const {t} = this.props;
        return (
            <Panel
                title={this.props.isUserInformations ? t('mes_informations.subscription_panel_title') : 'Abonnements associés'}
                togglableID="panel-abo"
                open={true}
            >
                <div className="row">
                    {this.state.abonnements?.length < 1 && (
                        this.props.isUserInformations ? (
                            <p className="w-100 text-center">Vous ne disposez d'aucun abonnement</p>
                        ) : (
                            <p className="w-100 text-center">Cette personne ne dispose d'aucun abonnements</p>
                        )
                    )}
                    {this.state.abonnements?.map((abo: any) => abo.rapports.map((rap: any) => (
                        <div key={`${abo.id}_${rap.id}`} className="col-sm-6 col-lg-4 col-xl-3 m-b-15 m-b-md-30">
                            <div className="info-card">
                                <h4 className="info-card__title">
                                    {hasPermission('DRT_FLUX_ABONNEMENT') ? (
                                        <Link target="_blank" to={`/flux/abonnements/${abo.id}`}>{abo.libelle}</Link>
                                    ) : (abo.libelle)}
                                </h4>
                                {abo.proprietaire}
                                <dl className="info-card__info">
                                    <dt>Média :</dt>
                                    <dd>{abo.media}</dd>
                                    <dt>Rapport :</dt>
                                    <dd>{rap.nom}</dd>
                                    <dd>{rap.description}</dd>
                                </dl>
                            </div>
                        </div>
                    )))}
                </div>
            </Panel>
        )
    }
}

export default withTranslation()(withRouter(SubscriptionPanel));