import { default as jwt_decode } from 'jwt-decode';
import { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { API_DEBUG } from '../config';
import { RefreshJWT, Request, TOAST_TYPE, checkIsJSON, checker, getHabilitation, getJWT, getRefreshJWT, sendToast, updateUnreadAnnonces } from '../helpers/helpers';

class CustomComponent<P = {}, S = {}> extends Component<P & any, S & any> {

    checkIsConnectedPWA() {
        const { t } = this.props;
        let session = localStorage.getItem('session');
        let habilitation = localStorage.getItem('habilitation_id');

        if (localStorage.getItem('passwordNeedsChange') === "true") {
            this.props.history.push("/password/edit");
        }
        else if (session && !habilitation && !this.checker(window.location.pathname, ["/habilitations", "/logout", "/update_credentials"])) {
            this.props.history.push("/habilitations");
            return false;
        }
        else if (!session) {
            if (!this.checker(window.location.pathname, ["/login", "/password/reset", "/register", "/account/activate/"]))
                this.props.history.push("/login");
            return false;
        }
        else if (this.checker(window.location.pathname, ["/login", "/password/reset", "/register", "/account/activate", "/update_credentials"]) && session) {
            sendToast(<p>{t("already_connected")}</p>, TOAST_TYPE.SUCCESS);
            this.props.history.push("/");
        }
        return true;
    }

    async checkIsConnected() {
        let url = "/login";
        let method = "GET";
        let data = await this.request(url, method);

        if (data && data.status === "ok" && data.loggedIn)
            return true;
        return false;
    }

    checker(value: any, arr: any) {
        return checker(value, arr);
    }

    contains(target: any, pattern: any) {
        var value = 0;
        pattern.forEach(function (word: any) {
            value = value + target.includes(word);
        });
        return (value >= 1)
    }

    handleCreateOption(event: any, lsName: any, callback: any = null) {
        let value = event.data.value;
        let name = event.action.name;
        let options = event.action.optionsName;

        const createOption = (label: string) => ({
            label,
            value: label,
        });

        let newOption = createOption(value);

        this.setState((prevState: any) => {
            let cp = null;
            if (Array.isArray(lsName)) {
                cp = Object.assign({}, prevState[lsName[0]]);
                let obj = cp;
                for (let i = 1; lsName.length > i; i++) {
                    obj = obj[lsName[i]];
                }
                obj[options] = [...obj[options], newOption];
                obj[name] = value;
                return { [lsName[0]]: cp };
            }
            else {
                cp = Object.assign({}, prevState[lsName]);
                cp[options] = [...cp[options], newOption];
                cp[name] = value;
                return { [lsName]: cp };
            }
        }, () => { callback && callback() });
    }

    handleInputChange(event: any, val: any, lsName: any, callback: any = null, trim: boolean = false) {
        let value: any = "";
        let name = "";
        if (event.data) {
            value = event.data.value;
            name = event.action.name;
        }
        else {
            const target = event.target;
            name = target.name || '';
            if (target.parentNode) {
                name = event.target.parentNode.classList.contains('MuiSlider-root') ? event.target.parentNode.querySelector('input').name : event.target.name;
            }

            if (target.type !== undefined) {
                switch (target.type) {
                    case 'multicheckbox':
                        value = target.value
                        break;
                    case 'checkbox':
                        value = target.checked;
                        break;
                    case 'submit':
                        if (target.id === 'step-up')
                            value = Number(this.state[lsName][name]) + (target.step ? Number(target.step) : 1);
                        if (target.id === 'step-down')
                            value = Number(this.state[lsName][name]) - (target.step ? Number(target.step) : 1);
                        break;
                    default:
                        value = trim ? target.value.trim() : target.value;
                        break;
                }
            }
            else {
                value = val;
            }
        }

        this.setState((prevState: any) => {
            let cp = null;
            if (Array.isArray(lsName)) {
                cp = Object.assign({}, prevState[lsName[0]]);
                let obj = cp;
                for (let i = 1; lsName.length > i; i++) {
                    obj = obj[lsName[i]];
                }
                obj[name] = value;
                return { [lsName[0]]: cp };
            }
            else {
                cp = Object.assign({}, prevState[lsName]);
                cp[name] = value;
                return { [lsName]: cp };
            }
        }, () => { callback && callback(); this.forceUpdate(); });
    }

    async request(url: string, method: string, body: any = null, isUploadForm = false, first_request = false, expect_download = false): Promise<any> {
        return Request(url, method, body, isUploadForm, first_request, expect_download);
    }

    debug(value: any) {
        API_DEBUG && console.log(value);
    }

    loadStorageValues(actualStateNameArray: any, actualStateArray: any, callback: any = null) {

        if (actualStateNameArray.length === actualStateArray.length) {
            actualStateNameArray.forEach((key: any, index: any) => {
                let storageValue: any = {};
                let value: any;

                if (this.checkIsJSON(localStorage.getItem(key) ?? ""))
                    storageValue = JSON.parse(localStorage.getItem(key) ?? "");

                if (Array.isArray(actualStateArray[index])) {
                    value = storageValue ? storageValue : [];
                }
                else {
                    value = {};
                    Object.keys(actualStateArray[index]).forEach((setting) => {
                        value[setting] = (storageValue !== null && storageValue[setting] !== undefined) ? storageValue[setting] : actualStateArray[index][setting];
                    });
                }
                if (JSON.stringify(value) !== JSON.stringify(actualStateArray[index]))
                    this.setState({
                        [key]: value
                    }, () => { callback && callback() });
            });
        }
    }

    checkIsJSON(text: string) {
        return checkIsJSON(text);
    }

    getLocalStorageSession() {
        let session_str: string | null = localStorage.getItem('session');
        if (session_str && this.checkIsJSON(session_str)) {
            let session: {} = JSON.parse(session_str) || {};
            return session;
        }
        return null;
    }

    getJWT() {
        return getJWT();
    }

    getRefreshJWT() {
        return getRefreshJWT();
    }


    async refreshJWT() {
        await RefreshJWT();
    }

    async select_habilitation(habilitation_id: number) {
        let url = "/auth/select_habilitation";
        let method = "POST";
        let body = { per_habilitation: habilitation_id };
        let data = await this.request(url, method, body);
        if (data) {
            if ('access_token' in data) {
                // update all session data
                const session: any = jwt_decode(data.access_token);
                localStorage.setItem('session', JSON.stringify(session));
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                localStorage.setItem('per_habilitations', JSON.stringify(data.per_habilitations));
                localStorage.setItem('habilitation_id', JSON.stringify(data.habilitation_id));
                localStorage.setItem('habDroit', JSON.stringify(data.habDroit));
                // check for annonce notifications
                await updateUnreadAnnonces();
                // go to home page
                this.props.history.push('/home');
            }
        }
    }

    getHabilitation() {
        return getHabilitation();
    }
}

export default CustomComponent;
