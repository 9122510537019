import { useState } from 'react';
import { LoadingIndicator } from "../../../components/LoadingIndicator";
import Modal from "../../../components/Modal";
import { numberFormat, Request, sendToast, TOAST_TYPE } from "../../../helpers/helpers";

interface DeleteModalProps {
    production: any
    seuil: any
    onClose: Function
}
export const DeleteModal = ({production, onClose, seuil}: DeleteModalProps) => {
    const [loading, setLoading] = useState(false)

    const onConfirm = (e: any) => {
        e.preventDefault()

        setLoading(true)
        Request(`/per_seuil_alerte/${seuil.id}`, 'DELETE').then(data => {
            setLoading(false)
            if (data.statusCode === 201) {
                sendToast(data?.message, TOAST_TYPE.SUCCESS);
                onClose();
            }
        })
    }

    return (<>
        <LoadingIndicator loading={loading} />
        <Modal size="md" customLayout open={true}>
            <Modal.Header>
                <div className="w-100 m-b-30">
                    <h2 className="modal-title">Êtes vous sûr(e) de vouloir surpprimer le seuil suivant?</h2>
                    <p className="lead">
                        <strong>{seuil?.cri_id?.libelle}</strong> <strong>{seuil?.operande}</strong> <strong>{seuil?.apr_id?.libelle || numberFormat(seuil?.valeur, seuil.cri_id.nb_decimales)}</strong>
                    </p>
                </div>
            </Modal.Header>
            <Modal.Footer>
                <button onClick={() => onClose()} type="button" className="btn btn-white">Annuler</button>
                <button onClick={onConfirm} type="button" className="btn btn-success">Supprimer</button>
            </Modal.Footer>
        </Modal>
    </>)
}