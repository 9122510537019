import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CustomComponent from "../../components/CustomComponent";
import { DefaultLayout } from '../../components/Layouts';
import { TOAST_TYPE, sendToast } from "../../helpers/helpers";
import { AideListHeader } from "./AideListHeader";
import {AideCard} from "./_card";

class Aide extends CustomComponent {
    private _isMounted = false;
    private activeTab: string = "producteur";

    constructor(props: any) {
        super(props)
        this.state = {
            isLoading: false,
            listMedia: [],
        }
    }

    componentDidMount() {
        document.title = "Infolabo | Aide en ligne";
        this._isMounted = true;
        this.activeTab = this.getActiveTab();
        this.setState({ isLoading: true });
        this.getListMedia();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getActiveTab() {
        const href = window.location.pathname;
        return href.substring(href.lastIndexOf('/') + 1);
    }

    async getListMedia() {
        const data = await this.request('/inf_aide/media', 'GET');
        if (data?.statusCode === 200) {
            this.setState({ listMedia: data.medias, isLoading: false });
        } else {
            sendToast(<p>{data.message}</p>, TOAST_TYPE.ERROR);
            this.setState({ isLoading: false });
        }
    }

    render() {
        if (this.props.noLayout) return this.renderContent()

        return (
            <DefaultLayout loading={this.state.isLoading}>
                <div className="container">
                    {this.renderContent()}
                </div>
            </DefaultLayout>
        )
    }

    renderContent() {
        return (
            <>
                <AideListHeader onHome={this.props.noLayout} homeTab={this.activeTab} />
                <div className="row">
                    {
                        this.state.listMedia.filter((e: any) => e.rubrique === this.activeTab.toUpperCase()).sort((a: any, b: any) => a.ordre_affichage - b.ordre_affichage).map((media: any, index: number) =>
                            <div key={media.id} className="col-sm-6 col-md-4 col-lg-3 m-b-20">
                                <AideCard media={media} context={this}/>
                            </div>
                        )
                    }
                </div>
            </>
        )
    }
}

export default withTranslation()(withRouter(Aide));

