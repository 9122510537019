import classNames from "classnames";
import { useEffect, useState } from 'react';
import { Collapse, Dropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import login_foreground from '../assets/images/login_foreground.svg';
import logo_cniel from '../assets/images/logo_cniel.svg';
import logo_header from '../assets/images/logo_header.svg';
import logo_infolabo from '../assets/images/logo_infolabo.svg';
import { APP_VERSION } from '../config';
import { Request, getHabilitation, getSession, getUser, hasPermission, updateUnreadAnnonces } from '../helpers/helpers';
import { BannerUnreadAnnonce } from "../views/annonces/_banner_unread";
import { AnnonceSideMenu } from "../views/annonces/_side_menu";
import { InformationBanner } from './InformationBanner';
import { LoadingIndicator } from './LoadingIndicator';
import { LoginChecker } from './LoginChecker';
import { InProgress } from './Todo';

type Props = {
    children: JSX.Element | JSX.Element[]
    loading?: boolean
    wip?: boolean
    displayAnnonceBanner?: boolean
};


export const DefaultLayout = ({ children, loading, wip, displayAnnonceBanner }: Props) => {
    const [openMenu, setOpen] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const unreadAnnonces: number[] = JSON.parse(localStorage.getItem('unread_annonces') ?? '[]');
    const [hasNotifications, setHasNotifications] = useState<number[]>(unreadAnnonces);
    const [bannerAnnonce, setBannerAnnonce] = useState(null);
    const [isDisplayAnnonceBanner, setDisplayAnnonceBanner] = useState(displayAnnonceBanner ? true : false);

    const habilitation = getHabilitation();
    const user = getUser();
    const session = getSession();
    const showSimulateProfil = hasPermission('DRT_SIMULER_PROFIL') && !session.original_user
    const isSimulating = session.original_user

    let firstLetter: string = "";
    if (!!user?.prenom) firstLetter = user.prenom.charAt(0).toUpperCase();
    else if (!!user?.nom) firstLetter = user.nom.charAt(0).toUpperCase();

    const getAnnonceBanner = async (session: any) => {
        if (!!session && Object.values(session).length > 0) {
            const data = await Request(`/inf_annonce/banner`, 'GET');
            if (data?.statusCode === 200) {
                setBannerAnnonce(data.annonce);
                setHasNotifications(data.unreadAnnonces);
            }
            else if (data?.statusCode === 204) {
                setBannerAnnonce(null);
                setHasNotifications([]);
            }
        }
    }

    useEffect(() => {
        if (isDisplayAnnonceBanner) {
            getAnnonceBanner(session);
            updateUnreadAnnonces(hasNotifications);
            setDisplayAnnonceBanner(false);
            setHasNotifications(JSON.parse(localStorage.getItem('unread_annonces') ?? '[]'));
        }
    }, [isDisplayAnnonceBanner, hasNotifications, session]);

    const handleReadAnnonce = (id: number) => {
        setHasNotifications((prev: number[]) => prev.filter((anno_id: number) => anno_id !== id));
    }

    return (
        <>
            <LoginChecker />
            {!!wip && <InProgress />}
            <LoadingIndicator loading={!!loading}></LoadingIndicator>
            <header className="header">
                <nav className="navbar navbar-expand-lg">
                    <Link className="navbar-brand" to="/home">
                        <img className="header__logo" src={logo_header} alt="Infolabo" />
                    </Link>
                    {habilitation?.hab_code.code_hab !== "PRODUCT" && (<>
                        <button className="navbar-toggler" type="button"
                            data-toggle="collapse"
                            data-target="#header_menu"
                            aria-controls="navbarSupportedContent"
                            onClick={() => setOpen(!openMenu)}
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="icon"></span>
                            <span className="sr-only">Ouvrir le menu</span>
                        </button>

                        <Collapse in={openMenu}>
                            <div className="navbar-collapse" id="header_menu">
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item"><NavLink exact className="nav-link" to="/">Accueil <span className="sr-only">(current)</span></NavLink></li>
                                    {hasPermission(['DRT_RESULTATS']) && <li className="nav-item"><NavLink className="nav-link" to="/resultats">Résultats</NavLink></li>}
                                    {hasPermission(['DRT_ANNUAIRE']) && <li className="nav-item"><NavLink className="nav-link" to="/annuaire">Annuaire</NavLink></li>}
                                    {hasPermission(['DRT_FLUX']) && <li className="nav-item"><NavLink className="nav-link" to="/flux">Flux</NavLink></li>}
                                    {hasPermission('DRT_ANNONCE_GERER') && <li className="nav-item"><NavLink className="nav-link" to="/annonces">Annonces</NavLink></li>}
                                </ul>
                            </div>
                        </Collapse>
                    </>)}

                    <ul className="header__actions ml-auto">
                        <li>
                            <button type="button" onClick={() => setOpenNotifications(!openNotifications)} className={classNames({
                                'nav-link': true, 'btn-icon': true, 'has-notifications': hasNotifications.length > 0,
                            })}>
                                <i className="icon-bell"></i>
                                {hasNotifications.length > 0 && <span className="sr-only">Vous avez des notifications</span>}
                            </button>
                        </li>
                        <li>
                            <Link className="btn-icon btn-icon--green" to="/aide" title="Aide en ligne" data-toggle="tooltip" data-custom-class="green">?</Link>
                        </li>
                        <Dropdown>
                            <Dropdown.Toggle className="header__account" as="a">
                                <span className="btn-icon btn-icon--blue">{firstLetter}</span>
                                <strong className="d-none d-lg-block">{habilitation?.ent_id.nom_usuel} </strong>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <div className="dropdown-title">
                                    <div className="mb-1">{user?.prenom} {user?.nom}</div>
                                    <div><strong>{habilitation?.ent_id.nom_usuel}</strong></div>
                                </div>
                                <Link className='dropdown-item' to='/profil'>Mes informations</Link>
                                {hasPermission('DRT_CONTACTS') && <Link className='dropdown-item' to='/contacts'>Mes contacts</Link>}
                                {hasPermission('DRT_RES_GERER_SEUILS') && <Link className='dropdown-item' to='/parametres'>Mon paramétrage</Link>}
                                {showSimulateProfil && <Link className='dropdown-item' to='/simulate_profil'>Simuler un profil</Link>}
                                {isSimulating && <Link className='dropdown-item' to='/logout_simulated'>Sortir du profil</Link>}
                                {!isSimulating && <Link className='dropdown-item' to='/change_entite'>Changer d'entité</Link>}
                                <Link className='dropdown-item' to='/logout'>Déconnexion</Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ul>
                </nav>
            </header>

            {/* Notifications menu */}
            <AnnonceSideMenu
                isOpen={openNotifications}
                onClose={() => setOpenNotifications(false)}
                readAnnonceCallBack={handleReadAnnonce}
            />

            {/* Latest unread annonce banner */}
            {bannerAnnonce && (
                <BannerUnreadAnnonce
                    annonce={bannerAnnonce}
                    readAnnonceCallBack={handleReadAnnonce}
                    closeModalCallback={() => getAnnonceBanner(session)}
                />
            )}

            {children}
            <footer className="footer">
                <div className="container footer__container">
                    <a href="https://www.filiere-laitiere.fr/fr" title="Liens vers le site du CNIEL  (ouvre une nouvelle fenêtre)">
                        <img className="footer__logo" src={logo_cniel} alt="Logo du CNIEL" />
                    </a>
                    <ul className="footer__links">
                        <li><Link className="btn btn-link" to="/mentions_legales">Mentions légales</Link></li>
                        <li><Link className="btn btn-link" to="/donnees_personnelles">Données personnelles</Link></li>
                    </ul>
                    {APP_VERSION && <span className='app-version app-version__variant'>Version {APP_VERSION}</span>}
                </div>
            </footer>
        </>
    )
}

export const HabilitationLayout = ({ children, loading, wip }: Props) => {
    return (
        <>
            {!!wip && <InProgress />}
            <LoadingIndicator loading={!!loading} />
            <header className="header-disconnect">
                <img className="header-disconnect__logo" src={logo_infolabo} alt="Logo InfoLabo" />
                <Link to="/logout" className="btn btn-primary">Déconnexion</Link>
            </header>
            {children}
            <footer className="footer">
                <div className="container footer__container">
                    <a href="https://www.filiere-laitiere.fr/fr" title="Liens vers le site du CNIEL  (ouvre une nouvelle fenêtre)">
                        <img className="footer__logo" src={logo_cniel} alt="Logo du CNIEL" />
                    </a>
                    <ul className="footer__links">
                        <li><Link className="btn btn-link" to="/mentions_legales">Mentions légales</Link></li>
                        <li><Link className="btn btn-link" to="/donnees_personnelles">Données personnelles</Link></li>
                    </ul>
                    {APP_VERSION && <span className='app-version app-version__variant'>Version {APP_VERSION}</span>}
                </div>
            </footer>
        </>
    )
}

export const LoginLayout = ({ children, loading, wip }: Props) => {
    return (
        <>
            {!!wip && <InProgress />}
            <LoadingIndicator loading={!!loading} />
            <InformationBanner />
            <div className="page-login">
                <header className="page-login__header">
                    <a href="https://www.filiere-laitiere.fr/fr" rel="noreferrer" target="_blank" title="Liens vers le site du CNIEL  (ouvre une nouvelle fenêtre)">
                        <img className="page-login__logo" src={logo_cniel} alt="Logo du CNIEL" />
                    </a>
                </header>
                {children}
                <img src={login_foreground} alt="" className="page-login__foreground" />
                <footer className="page-login__footer">
                    <ul>
                        <li><Link to="/mentions_legales">Mentions légales</Link></li>
                        <li><Link to="/donnees_personnelles">Données personnelles</Link></li>
                    </ul>
                    {APP_VERSION && <span className='app-version'>Version {APP_VERSION}</span>}
                </footer>
            </div>
        </>
    )
}
