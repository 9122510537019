export enum TYPES_EXPORT {
    IMMEDIAT = 'IMMEDIAT',
    FINJOUR = 'FINJOUR',
    CUMUL_IMMEDIAT = 'CUMUL_IMMEDIAT',
    CUMUL_FINJOUR = 'CUMUL_FINJOUR'
}

export const TYPES_EXPORT_LABEL: {[key: string]: string} = {
    [TYPES_EXPORT.IMMEDIAT]: 'Immédiat',
    [TYPES_EXPORT.FINJOUR]: 'Fin de journée',
    [TYPES_EXPORT.CUMUL_IMMEDIAT]: 'Cumul mois - Immédiat',
    [TYPES_EXPORT.CUMUL_FINJOUR]: 'Cumul mois - Fin de journée'
};

export const TYPES_EXPORT_OPTIONS = Object.entries(TYPES_EXPORT_LABEL).map(([value, label]) => ({
    value, label
}))