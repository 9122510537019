import CustomComponent from "../../../components/CustomComponent";
import CreateGroupe from "./create";

class DetailsGroupe extends CustomComponent {
    render () {
        const id = this.props.match.params.id;
        return(<CreateGroupe groupeId={id} isDetailsPage={true} title="Consultation d'un groupe" />);
    }
}

export default DetailsGroupe;