import React from "react";
import { Dropdown } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import CustomComponent from "../../../components/CustomComponent";
import CustomToggle from "../../../components/CustomToggle";
import { FilterDropdown } from "../../../components/FilterDropdown";
import { FormInput, FormInputSelect } from "../../../components/FormComponents";
import { DefaultLayout } from "../../../components/Layouts";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import TABLE from "../../../components/Table";
import { LimitValuesSelect, TableNested } from "../../../components/TableComponents";
import { DebounceQueue } from "../../../helpers/debouncer";
import { TOAST_TYPE, hasPermission, sendToast } from "../../../helpers/helpers";
import { exportCSVAnnuaire } from "../../../requests/exportCSVAnnuaire";
import { AnnuaireHeader } from "../_common/AnnuaireHeader";
import { AnnuaireTabs } from "../_common/AnnuaireTabs";

class ListGroupe extends CustomComponent {
    private _isMounted = false;
    private filterSaveKey = 'ANNUAIRE_LISTE_GROUPE';
    private queue = new DebounceQueue();

    private default_filters = {
        per_nom: '',
        per_prenom: '',
        per_email: '',
        per_telephone: '',
        ent_id: '',
        orderBy: 'grp_nom',
        ordering: 'ASC',
        grp_nom: '',
        grp_proprietaire: '',
        grp_type: '',
        grp_description: '',
    };

    constructor(props: any) {
        super(props);
        this.state = {
            groupes: [],
            filters: this.default_filters,
            options: {
                entites: [],
            },
            recherches: [],
            paginate: {
                count: 0,
                limit: 10,
                page: 0,
                pageTotal: 0,
            },
            deleteTarget: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        document.title = 'Infolabo | Liste des groupes';

        this.getRecherches();
        this.getEntiteOptions();
        this.getGroupes();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getGroupes = async (triggeredByPagination: boolean = false) => {
        this.setState({isLoading: true});

        const params = new URLSearchParams();

        Object.keys(this.state.paginate).forEach((key => {
            const val = this.state.paginate[key];
            if (val !== '') params.append(key, val);
        }));

        Object.keys(this.state.filters).forEach(key => {
            const val = this.state.filters[key];
            if (val !== '') params.append(key, val);
        });

        if (!triggeredByPagination) params.set('page', '0');

        const data = await this.request(`/ent_groupe?${params.toString()}`, 'GET');
        this.setState({isLoading: false});

        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                groupes: data.groupes,
                paginate: {...prev.paginate, ...data.paginate}
            }));
        }
    }

    getEntiteOptions = async (e?: any) => {
        let URL: string = '/ent_entite/autocomplete';

        if (e) {
            if (e.action.action !== 'input-change') return;
            URL += `?query=${e.data}`;
        }

        const data = await this.request(URL, 'GET');
        if (data && this._isMounted) {
            this.setState((prev: any) => ({
                options: {
                    ...prev.options,
                    entites: data.map((option: any) => ({label: option.label, value: option.id}))
                }
            }));
        }
    }

    getRecherches = async () => {
        const recherches = await this.request(`/inf_recherche/${this.filterSaveKey}`, 'GET');

        if (recherches) {
            this.setState({recherches});
        }
    }

    onFiltersSearch () {
        return;
    }

    handleSort (field: string) {
        this.setState((prev: any) => ({
            filters: {
                ...prev.filters,
                orderBy: field,
                ordering: (field !== prev.filters.orderBy || prev.filters.ordering === 'DESC') ? 'ASC' : 'DESC'
            }
        }), () => this.queue.push(this.getGroupes, [], true))
    }

    toggleChildren = (id: number) => {
        this.setState((prevState: any) => ({
            ...prevState,
            groupes: prevState.groupes.map((groupe: any) => {
                if (groupe.id === id) {
                    return {
                        ...groupe,
                        showChildren: !groupe.showChildren
                    }
                }
                return groupe
            })
        }))
    }

    paginationOnClick = (e: any) => {
        const value = e.currentTarget.value;
        this.setState((prev: any) => ({
            paginate: {
                ...prev.paginate,
                page: (value - 1),
            }
        }), () => this.queue.push(this.getGroupes, [true], true))
    }

    deleteGroupe = async () => {
        const data = await this.request(`/ent_groupe/${this.state.deleteTarget.id}`, 'DELETE');
        if (data && data.statusCode === 200) {
            sendToast(data?.message, TOAST_TYPE.SUCCESS);
            this.getGroupes();
            this.setState({deleteTarget: null});
        }
    }

    exportToCSV = async () => {
        this.setState({isLoading: true})
        await exportCSVAnnuaire('GROU', this.state.filters)
        this.setState({isLoading: false})
    }

    render() {
        return (
            <DefaultLayout>
                <div className="container">
                    <AnnuaireHeader />
                    <div className="row align-items-end">
                        <div className="col-lg-9 order-1 order-lg-0">
                            <AnnuaireTabs />
                        </div>
                        <div className="col-lg-3 order-0 order-lg-1">
                            <LimitValuesSelect
                              default_value={this.state.paginate.limit}
                              steps={[10,20,50]}
                              onChange={(e: any) => this.setState((prev: any) => ({ paginate: {...prev.paginate, limit: +e.target.value, page: 0 }}), () => this.queue.push(this.getGroupes, [], true))}
                            />
                        </div>
                    </div>

                    <FilterDropdown
                        startOpen
                        parent={this}
                        type_recherche={this.filterSaveKey}
                        onExport={hasPermission('DRT_ANNU_GROUPE') ? {csv: this.exportToCSV} : undefined}
                        filters={this.state.filters}
                        recherches={this.state.recherches}
                        onUpdate={this.getRecherches}
                        onSearchLoad={(filters: any) => this.setState({filters: filters}, () => this.queue.push(this.getGroupes, [], true))}
                        onFiltersReset={() => {this.setState({filters: this.default_filters}, () => {
                            this.queue.push(this.getGroupes, [], true);
                            this.getEntiteOptions();
                        })}}
                        onFiltersSearch={this.onFiltersSearch}
                    >
                        <div className="row">
                            <h5 className="col-lg-12 filter-dropdown__content__title">Personne(s) destinataire(s)</h5>
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormInput
                                        label="Nom"
                                        name="per_nom"
                                        value={this.state.filters.per_nom}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))}
                                    />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInput
                                        label="Prénom"
                                        name="per_prenom"
                                        value={this.state.filters.per_prenom}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormInput
                                        label="E-mail"
                                        name="per_email"
                                        value={this.state.filters.per_email}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))}
                                        info={{placement: 'top', text: 'La recherche portera sur l’intégralité des adresses e-mail connues pour la personne.'}}
                                    />
                                </div>
                                <div className="form-group form-group--inline">
                                    <FormInput
                                        label="Téléphone"
                                        name="per_telephone"
                                        value={this.state.filters.per_telephone}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))}
                                        info={{placement: 'top', text: 'La recherche portera sur l’ensemble des numéros de téléphone connus pour la personne.'}}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <h5 className="col-lg-12 filter-dropdown__content__title">Entité(s) destinataire(s)</h5>
                            <div className="col-lg-6">
                                <div className="form-group form-group--inline">
                                    <FormInputSelect
                                        isCLearable
                                        label="Entité"
                                        name="ent_id"
                                        value={this.state.filters.ent_id}
                                        handleInput={(e: any) => this.queue.push(this.getEntiteOptions, [e])}
                                        handle={(e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))}
                                        options={this.state.options.entites}
                                    />
                                </div>
                            </div>
                        </div>
                    </FilterDropdown>

                    <TABLE responsive="card">
                        <TABLE.THEAD>
                            <TABLE.TR>
                                <TABLE.TH></TABLE.TH>
                                <TABLE.TH
                                    name="grp_nom"
                                    input={{
                                        value: this.state.filters.grp_nom,
                                        placeholder: 'Nom du groupe',
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('grp_nom')
                                    }}
                                />
                                <TABLE.TH
                                    name="grp_proprietaire"
                                    input={{
                                        value: this.state.filters.grp_proprietaire,
                                        placeholder: 'Propriétaire',
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('grp_proprietaire')
                                    }}
                                />
                                <TABLE.TH
                                    name="grp_description"
                                    input={{
                                        value: this.state.filters.grp_description,
                                        placeholder: 'Description',
                                        onChange: (e: any) => this.handleInputChange(e, null, 'filters', () => this.queue.push(this.getGroupes))
                                    }}
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('grp_description')
                                    }}
                                />
                                <TABLE.TH
                                    label="Type du groupe"
                                    name="grp_type"
                                    sort={{
                                        orderBy: this.state.filters.orderBy,
                                        ordering: this.state.filters.ordering,
                                        onSort: () => this.handleSort('grp_type')
                                    }}
                                />
                                <TABLE.TH actions label="Actions"/>
                            </TABLE.TR>
                        </TABLE.THEAD>
                        <TABLE.TBODY>
                            {this.state.groupes.map((groupe: any) => (
                                <React.Fragment key={groupe.id}>
                                    <TABLE.TR paddedLeft>
                                        <TABLE.TD icon>
                                            {groupe.nombreMembres > 0 ? (
                                                <button
                                                    onClick={() => this.toggleChildren(groupe.id)}
                                                    aria-expanded={groupe.showChildren}
                                                    className="action-btn action-btn--collapse">
                                                    <i className="icon-group"></i>
                                                </button>
                                            ) : (
                                                <span className="action-btn"><i className="icon-group"></i></span>
                                            )}
                                        </TABLE.TD>
                                        <TABLE.TD ellipsis label="Nom du groupe">{groupe.nom}</TABLE.TD>
                                        <TABLE.TD ellipsis label="Propriétaire">{`${groupe.proprietaire.siret} - ${groupe.proprietaire.nom_usuel}`}</TABLE.TD>
                                        <TABLE.TD ellipsis label="Description">{groupe.description}</TABLE.TD>
                                        <TABLE.TD label="Type de groupe">{groupe.type === 'E' ? 'Entité' : 'Personne'}</TABLE.TD>
                                        <TABLE.TD actions>
                                            <div className="d-flex desktop-only">
                                                {hasPermission('DRT_ANNU_GROUPE') && <Link className='action-btn' to={`/annuaire/groupe/${groupe.id}`} target="_blank"><i className="icon-eye"></i></Link>}
                                                {hasPermission('DRT_ANNU_GROUPE') && <Link className='action-btn' to={`/annuaire/groupe/${groupe.id}/modifier`} target="_blank"><i className="icon-pen"></i></Link>}
                                                {hasPermission('DRT_ANNU_GROUPE') && <button className='action-btn' type='button' onClick={() => this.setState({deleteTarget: groupe})}><i className="icon-trash"></i></button>}
                                            </div>
                                            <Dropdown className="mobile-only">
                                                <Dropdown.Toggle as={CustomToggle}>
                                                    <span className="sr-only">Ouvrir les actions</span>
                                                    <i className="icon-actions"></i>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {hasPermission('DRT_ANNU_GROUPE') && <Link className='dropdown-item' to={`/annuaire/groupe/${groupe.id}`} target="_blank">Consulter</Link>}
                                                    {hasPermission('DRT_ANNU_GROUPE') && <Link className='dropdown-item' to={`/annuaire/groupe/${groupe.id}/modifier`} target="_blank">Modifier</Link>}
                                                    {hasPermission('DRT_ANNU_GROUPE') && <button className='dropdown-item' type='button' onClick={() => this.setState({deleteTarget: groupe})}>Supprimer</button>}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </TABLE.TD>
                                    </TABLE.TR>
                                    {groupe.nombreMembres > 0 && (
                                        groupe.type === 'E' ? (
                                            <TableNested
                                                colspan={6}
                                                limit={groupe.limitMembres}
                                                data={groupe.membres}
                                                total={groupe.nombreMembres}
                                                seeAllLink={`/annuaire/groupe/${groupe.id}`}
                                                table="ent_entite"
                                                show={groupe.showChildren}
                                                canShow
                                                canUpdate
                                            />
                                        ) : (
                                            <TableNested
                                                colspan={6}
                                                limit={groupe.limitMembres}
                                                data={groupe.membres}
                                                total={groupe.nombreMembres}
                                                seeAllLink={`/annuaire/groupe/${groupe.id}`}
                                                table="per_personne"
                                                show={groupe.showChildren}
                                                canShow
                                                canUpdate
                                            />
                                        )
                                    )}
                                </React.Fragment>
                            ))}
                        </TABLE.TBODY>
                    </TABLE>
                    {!this.state.groupes.length && <div className="table-empty">Aucune donnée groupe à afficher</div>}
                    <Pagination
                        handleClick={this.paginationOnClick}
                        pageCurrent={(this.state.paginate.page + 1)}
                        pageTotal={this.state.paginate.pageTotal}
                        pageDisplay={3}
                    />
                </div>
                <Modal open={this.state.deleteTarget}>
                    <div className="modal-body">
                        {this.state.deleteTarget && (
                            <p>Etes-vous sûr(e) de vouloir supprimer le groupe <b>{this.state.deleteTarget.nom}</b> ?</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button onClick={() => this.setState({ deleteTarget: null })} className="btn btn-white">Annuler</button>
                        <button onClick={() => this.deleteGroupe()} className="btn btn-secondary">Confirmer</button>
                    </div>
                </Modal>
            </DefaultLayout>
        )
    }
}

export default withTranslation()(withRouter(ListGroupe));