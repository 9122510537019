export enum TYPES_FICHIER_FLUX_RES {
    REJO = 'REJO',
    REME = 'REME',
}

export const TYPES_FICHIER_FLUX_RES_LABEL: {[key: string]: string} = {
    [TYPES_FICHIER_FLUX_RES.REJO]: 'REJO',
    [TYPES_FICHIER_FLUX_RES.REME]: 'REME',
}

export const TYPES_FICHIER_FLUX_RES_OPTIONS = Object.entries(TYPES_FICHIER_FLUX_RES_LABEL).map(([value, label]) => ({
    value, label
}))